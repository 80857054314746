import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  IconButton,
  TextareaAutosize,
  Typography,
  Button,
  TextField
} from "@mui/material";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import Loader from "src/components/Loader";
import { useLocation } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import TipperProfile from "src/assets/images/tipper-profile.png";
import Avatar from "src/assets/images/user-avatar.png";
import "./ui.scss";
import { Rating } from '@mui/material';

const TipperRatingModal = ({
  handleClose,
  open,
  title,
  closeIcon,
  tippperRatingInfo
}) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [popUpData, setPopUpData] = useState([]);
  const [tipperRating, setTipperRating] = useState(0);
  const [tipperRatingError, setTipperRatingError] = useState("");
  const [loyaltyRating, setLoyaltyRating] = useState(0);
  const [loyaltyRatingError, setLoyaltyRatingError] = useState("");

  useEffect(() => {
    setTipperRatingError("");
    setLoyaltyRatingError("");
  }, []);

  const handleValidation = () => {
    let flag = true;
    if (tipperRating === 0) {
      setTipperRatingError("Please select a rating.");
      flag = false;
    }
    // else {
    //   setTipperRatingError("");
    // }

    if (loyaltyRating === 0) {
      setLoyaltyRatingError("Please select a rating.");
      flag = false;
    }
    // else {
    //   setLoyaltyRatingError("");
    // }
    return flag;
  };

  const handleTipperRate = async () => {
    let payload = {
      toUserId: 702,
      loyaltyRate: Number(loyaltyRating),
      tipperRate: Number(tipperRating)
    };
    if (handleValidation()) {
      try {
        const { status, data } = await axiosInstance.post(
          `/user/rate`,
          payload
        );
        if (status === 200) {
          toast.success(data?.message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored"
          });
          onModalClose();
        } else {
        }
      } catch (err) {
        console.log("error", err);
        toast.error(err?.response?.data?.message, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
      }
    }
  };

  const onModalClose = () => {
    handleClose();
    setTipperRating(0);
    setLoyaltyRating(0);
  };

  return (
    (<Dialog
      className="blackBook-dialog w570 border-radius-chat"
      // maxWidth="md"
      onClose={() => onModalClose()}
      open={open}
      TransitionComponent={Grow}
      scroll={"paper"}
    >
      <Box className="dialog-content-box br-8">
        {title && closeIcon && (
          <DialogTitle>
            <Box className="custom-dialog-title">
              <Box className="dialog-title">
                Rate {title}
                {closeIcon !== false && (
                  <IconButton
                    className="dialog-close"
                    onClick={() => {
                      onModalClose();
                    }}
                    size="large">
                    <CloseIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
          </DialogTitle>
        )}
        <Box className="tipper-rating-wrap">
          {isLoading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : (
            <>
              <Box className="tipper-rating-modal-sec">
                <Box className="avatar-sec">
                  <img src={TipperProfile} alt="avatar" className="avatar" />
                </Box>

                <Typography className="tipper-name">
                  {tippperRatingInfo?.firstName} {tippperRatingInfo?.lastName}
                </Typography>

                <Box className="rate-sec">
                  <Typography className="title">
                    Rate {tippperRatingInfo?.firstName}{" "}
                    {tippperRatingInfo?.lastName}
                  </Typography>
                  <Box className="rating-sec">
                    <Rating
                      value={tipperRating}
                      className="rating"
                      name="tipper-rating"
                      // emptyIcon={
                      //   <BlankStar />
                      // }
                      onChange={e => setTipperRating(e?.target?.value)}
                    />
                  </Box>
                  {tipperRatingError && !tipperRating && (
                    <div className="text-danger ml-4">{tipperRatingError}</div>
                  )}
                </Box>

                <Box className="rate-sec">
                  <Typography className="title">
                    Tipper's loyalty rating
                  </Typography>
                  <Box className="rating-sec">
                    <Rating
                      value={loyaltyRating}
                      className="rating"
                      name="loyalty-rating"
                      // emptyIcon={
                      //   <BlankStar />
                      // }
                      onChange={e => setLoyaltyRating(e?.target?.value)}
                    />
                  </Box>
                  {loyaltyRatingError && !loyaltyRating && (
                    <div className="text-danger ml-4">{loyaltyRatingError}</div>
                  )}
                </Box>

                <Box className="submit-btn-wrap">
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="submit-button"
                    onClick={() => {
                      // isEdit ? handleUpdateBlackBook() : handleAddBlackBook();
                      handleTipperRate();
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
      {/* <Box>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Box> */}
    </Dialog>)
  );
};

export default TipperRatingModal;
