import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as Reset } from "../../../assets/images/reset.svg";
import { IntlContext } from "src/App";
import moment from "moment-timezone";
import Select, { components } from "react-select";
// import "./blackbook.scss";
import "./blackbookV2.scss";
import UpcomingRunner from "./upcomingRunner";
import Results from "./results";
import BlackBook from "./blackBook";
import _ from "lodash";
import BBSearch from "./blackbookSearchModal";
import BlackBookModal from "../UI/blackBookModal";
import UpcomingRunnerV2 from "./upcomingRunner1";

const newTabData = [
  {
    id: 0,
    name: "Upcoming",
  },
  { id: 1, name: "Results" },
  {
    id: 2,
    name: "Blackbook",
  },
];
let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const day = [
  {
    id: 1,
    name: "Today",
    date: moment.tz(moment(), timezone).format("YYYY-MM-DD"),
    dayName: moment.tz(moment(), timezone).format("dddd"),
  },
  {
    id: 2,
    name: "Tomorrow",
    date: moment.tz(moment(), timezone).add(1, "days").format("YYYY-MM-DD"),
    dayName: moment.tz(moment(), timezone).add(1, "days").format("dddd"),
  },
  {
    id: 3,
    name: "Wednesday",
    date: moment.tz(moment(), timezone).add(2, "days").format("YYYY-MM-DD"),
    dayName: moment.tz(moment(), timezone).add(2, "days").format("dddd"),
  },
  {
    id: 4,
    name: "Thursday",
    date: moment.tz(moment(), timezone).add(3, "days").format("YYYY-MM-DD"),
    dayName: moment.tz(moment(), timezone).add(3, "days").format("dddd"),
  },
  {
    id: 0,
    name: "All",
  },
  // {
  //   id: 5,
  //   name: "Friday",
  //   date: moment
  //     .tz(moment(), timezone)
  //     .add(4, "days")
  //     .format("YYYY-MM-DD"),
  //   dayName: moment
  //     .tz(moment(), timezone)
  //     .add(4, "days")
  //     .format("dddd")
  // },
  // {
  //   id: 6,
  //   name: "Saturday",
  //   date: moment
  //     .tz(moment(), timezone)
  //     .add(5, "days")
  //     .format("YYYY-MM-DD"),
  //   dayName: moment
  //     .tz(moment(), timezone)
  //     .add(5, "days")
  //     .format("dddd")
  // }
];

const SortData = [
  { value: 0, label: "Date (Newest to Oldest)" },
  { value: 1, label: "Date (Oldest to Newest)" },
  { value: 2, label: "Alphabetical A-Z" },
  { value: 3, label: "Alphabetical Z-A" },
];
const PeriodData = [
  { value: 1, label: "last 30 days" },
  { value: 2, label: "July 2023" },
  { value: 3, label: "June 2023" },
  { value: 4, label: "May 2023" },
];
const EntryData = [
  { value: 0, label: "All" },
  { value: 1, label: "Runner" },
  { value: 2, label: "Jockey" },
  { value: 3, label: "Trainer" },
  { value: 4, label: "Tracks" },
];
const StateData = [
  { value: 1, label: "All" },
  { value: 2, label: "ACT" },
  { value: 3, label: "VIC" },
  { value: 4, label: "QLD" },
  { value: 5, label: "SA" },
  { value: 5, label: "WA" },
  { value: 5, label: "TAS" },
];

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const BlackbookPage = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [tabvalue, setTabValue] = useState(0);
  const [value, setValue] = useState(1);
  const [selectedSort, setselectedSort] = useState(0);
  const [selectedEntry, setselectedEntry] = useState(0);
  const [selectedPeriod, setselectedPeriod] = useState(0);
  // const [selectedCountry, setselectedCountry] = useState(0);
  // const [selectedState, setselectedState] = useState(0);
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [stateId, setStateId] = useState("");
  const [state, setState] = useState([]);
  const [count, setcount] = useState(0);
  const [pageCountry, setpageCountry] = useState(0);
  const [statecount, setstatecount] = useState(0);
  const [pageState, setpageState] = useState(0);
  const [searchCountryCount, setsearchCountryCount] = useState("");
  const [searchCountry, setsearchCountry] = useState([]);
  const [isCountrySearch, setisCountrySearch] = useState("");
  const [SearchCountrypage, setSearchCountrypage] = useState(0);
  const [searchStateCount, setsearchStateCount] = useState("");
  const [searchState, setsearchState] = useState([]);
  const [isStateSearch, setisStateSearch] = useState("");
  const [searchStatePage, setsearchStatePage] = useState(0);
  const [isCountrySelectOpen, setisCountrySelectOpen] = useState(false);
  const [countryApiCount, setCountryApiCount] = useState(0);
  const [isStateSelectOpen, setisStateSelectOpen] = useState(false);
  const [stateApiCount, setStateApiCount] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [upcomingData, setUpcomingData] = useState([]);
  const [isUpcomingLoading, setUpcomingLoading] = useState(false);
  const [upcomingCount, setUpcomingCount] = useState(0);
  const [upcomingPage, setUpcomingPage] = useState(1);
  const [resultData, setResultData] = useState([]);
  const [isresultLoading, setResultLoading] = useState(false);
  const [resultCount, setResultCount] = useState(0);
  const [resultPage, setResultPage] = useState(1);
  const [allBBData, setAllBBData] = useState([]);
  const [isAllBBLoading, setAllBBLoading] = useState(false);
  const [allBBCount, setAllBBCount] = useState(0);
  const [allBBPage, setAllBBPage] = useState(1);
  const [dateRanges, setDateRanges] = useState([]);
  const [isToggle, setisToggle] = useState(true);
  const [tabDate, setTabDate] = useState({
    startDate: moment.tz(moment(), timezone).format("YYYY-MM-DD"),
    endDate: moment.tz(moment(), timezone).format("YYYY-MM-DD"),
  });
  const [sortChange, setSortChange] = useState({
    key: "startDate",
    value: "desc",
  });
  const [BBtype, setBBtype] = useState("");
  const [periodChange, setPeriodChange] = useState({
    startDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [BBSearchopen, setBBSearchOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [upcomingTabCount, setUpcomingTabCount] = useState({});

  const handleBBSearchModal = () => {
    setBBSearchOpen(true);
  };

  const handleBBSearchClose = () => {
    setBBSearchOpen(false);
  };

  const handleBlackBookModal = (title, id, type) => {
    setSelectedTitle(title);
    setSelectedType(type);
    setSelectedId(id);
    handleModal();
  };

  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (event, value) => {
    setTabValue(value);
    if (value === 2) {
      setSortChange({
        key: "updatedAt",
        value: "desc",
      });
    }
  };
  const handleChange = (event, value) => {
    setValue(value);
    switch (value) {
      case 0:
        setTabDate({
          startDate: moment.tz(moment(), timezone).format("YYYY-MM-DD"),
          endDate: null,
        });
        break;
      case 1:
        setTabDate({
          startDate: moment.tz(moment(), timezone).format("YYYY-MM-DD"),
          endDate: moment.tz(moment(), timezone).format("YYYY-MM-DD"),
        });
        break;
      case 2:
        setTabDate({
          startDate: moment
            .tz(moment(), timezone)
            .add(1, "days")
            .format("YYYY-MM-DD"),
          endDate: moment
            .tz(moment(), timezone)
            .add(1, "days")
            .format("YYYY-MM-DD"),
        });
        break;
      case 3:
        setTabDate({
          startDate: moment
            .tz(moment(), timezone)
            .add(2, "days")
            .format("YYYY-MM-DD"),
          endDate: moment
            .tz(moment(), timezone)
            .add(2, "days")
            .format("YYYY-MM-DD"),
        });
        break;
      case 4:
        setTabDate({
          startDate: moment
            .tz(moment(), timezone)
            .add(3, "days")
            .format("YYYY-MM-DD"),
          endDate: moment
            .tz(moment(), timezone)
            .add(3, "days")
            .format("YYYY-MM-DD"),
        });
        break;
      default:
        setTabDate({
          startDate: moment.tz(moment(), timezone).format("YYYY-MM-DD"),
          endDate: moment.tz(moment(), timezone).format("YYYY-MM-DD"),
        });
    }
  };
  const handleSortchange = (e) => {
    setselectedSort(e?.value);
    switch (e?.value) {
      case 0:
        setSortChange({
          key: tabvalue === 2 ? "updatedAt" : "startDate",
          value: "desc",
        });
        break;
      case 1:
        setSortChange({
          key: tabvalue === 2 ? "updatedAt" : "startDate",
          value: "asc",
        });
        break;
      case 2:
        setSortChange({
          key: tabvalue === 2 ? "name" : "raceName",
          value: "asc",
        });
        break;
      case 3:
        setSortChange({
          key: tabvalue === 2 ? "name" : "raceName",
          value: "desc",
        });
        break;
      default:
        setSortChange({
          key: tabvalue === 2 ? "updatedAt" : "startDate",
          value: "desc",
        });
    }
  };
  const handleTypechange = (e) => {
    setselectedEntry(e?.value);
    switch (e?.value) {
      case 0:
        setBBtype("");
        break;
      case 1:
        setBBtype("Runner");
        break;
      case 2:
        setBBtype("Jockey");
        break;
      case 3:
        setBBtype("Trainer");
        break;
      case 4:
        setBBtype("Track");
        break;
      default:
        setBBtype("");
    }
  };
  const handlePeriodChange = (e) => {
    setselectedPeriod(e?.value);
    setPeriodChange(e);
  };
  const handleResetAll = () => {
    setselectedSort(0);
    setSortChange({
      key: tabvalue === 2 ? "updatedAt" : "startDate",
      value: "desc",
    });
    setselectedEntry(0);
    setBBtype("");
    setCountryId(0);
    setStateId("");
    setPeriodChange({
      startDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    });
    setselectedPeriod(0);
    setisToggle(true);
  };
  const Today = moment.tz(moment(), timezone).format("YYYY-MM-DD");
  const Tomorrow = moment
    .tz(moment(), timezone)
    .add(1, "days")
    .format("YYYY-MM-DD");
  const nextDay = moment
    .tz(moment(), timezone)
    .add(2, "days")
    .format("YYYY-MM-DD");
  const secondNextDay = moment
    .tz(moment(), timezone)
    .add(3, "days")
    .format("YYYY-MM-DD");

  const fetchCountry = async (page) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/country?limit=20&offset=${page}`,
      );
      if (status === 200) {
        setCountryApiCount(countryApiCount + 1);
        setcount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map((item) => {
          newdata.push({
            label: item?.country,
            value: item?.id,
          });
        });
        let alldatas = newdata?.unshift({
          label: "All Countries",
          value: 0,
        });
        let unionData = _.unionBy(country, newdata);
        let finalData = _.uniqBy(unionData, function (e) {
          return e.value;
        });
        setCountry(finalData);
      }
    } catch (err) {}
  };
  const handleOnScrollBottomCountry = () => {
    if (
      isCountrySearch !== "" &&
      searchCountryCount !== Math.ceil(SearchCountrypage / 20)
    ) {
      handleCountryInputChange(SearchCountrypage + 20, isCountrySearch);

      setSearchCountrypage(SearchCountrypage + 20);
    } else {
      if (count !== Math.ceil(pageCountry / 20)) {
        fetchCountry(pageCountry + 20);
        setpageCountry(pageCountry + 20);
      }
    }
  };
  const fetchState = async (id, page, type, statecount) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/state/country/${id}?limit=20&offset=${page}`,
      );
      if (status === 200) {
        setpageState(page);
        setStateApiCount(statecount + 1);
        setstatecount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map((item) => {
          newdata.push({
            label: item?.state,
            value: item?.id,
          });
        });
        // setState(_.unionBy(state, newdata))
        if (type) {
          setState(
            _.unionBy(newdata)?.sort((a, b) => {
              return a?.label.localeCompare(b?.label);
            }),
          );
        } else {
          setState(
            _.unionBy(state, newdata)?.sort((a, b) => {
              return a?.label.localeCompare(b?.label);
            }),
          );
        }
      }
    } catch (err) {}
  };
  const handleOnScrollBottomState = () => {
    // if (statecount !== Math.ceil(pageState / 20)) {
    //   fetchState(countryId, pageState + 20, false);
    //   setpageState(pageState + 20);
    // }
    if (
      isStateSearch !== "" &&
      searchStateCount !== Math.ceil(searchStatePage / 20)
    ) {
      handleStateInputChange(searchStatePage + 20, isStateSearch);

      setsearchStatePage(searchStatePage + 20);
    } else {
      if (statecount !== Math.ceil(pageState / 20)) {
        fetchState(countryId, pageState + 20, false, stateApiCount);
        setpageState(pageState + 20);
      }
    }
  };
  const handleCountryInputChange = (page, value) => {
    axiosInstance
      .get(`public/country?limit=20&offset=${page}&search=${value}`)
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchCountryCount(res?.data?.result?.count / 20);

          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.country,
              value: item?.id,
            });
          });
          let filterData = _.unionBy(searchCountry, newdata);
          setsearchCountry(
            _.uniqBy(filterData, function (e) {
              return e.value;
            }),
          );
          setisCountrySearch(value);
        }
      });
  };
  const handleStateInputChange = (page, value) => {
    axiosInstance
      .get(
        `public/state/country/${countryId}?limit=20&offset=${page}&search=${value}`,
      )
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchStateCount(res?.data?.result?.count / 20);

          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.state,
              value: item?.id,
            });
          });
          const finalStateData = newdata.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          let filterData = _.unionBy(searchState, finalStateData);
          setsearchState(
            _.uniqBy(filterData, function (e) {
              return e.value;
            }),
          );
          setisStateSearch(value);
        }
      });
  };

  const fetchUpcomingBlackBook = async (country, state, page) => {
    try {
      setUpcomingLoading(true);
      const { status, data } = await axiosInstance.get(
        `blackbook/raceData?startDate=${tabDate?.startDate}&endDate=${
          tabDate?.endDate ? tabDate?.endDate : ""
        }&sort=${sortChange?.key}&orderBy=${
          sortChange?.value
        }&type=${BBtype}&countryId=${country ? country : ""}&stateId=${
          state ? state : ""
        }&comment=${isToggle}&limit=10&page=${page}&timeZone=${timezone}&isResult=false`,
      );

      if (status === 200) {
        const count = data?.count;
        setUpcomingCount(count);

        setUpcomingData(data?.result);
        setUpcomingLoading(false);
      } else {
        setUpcomingLoading(false);
      }
    } catch (err) {
      setUpcomingLoading(false);
    }
  };

  const fetchUpcomingBlackBookFilterCount = async (country, state) => {
    try {
      setUpcomingLoading(true);
      const { status, data } = await axiosInstance.get(
        `blackbook/race/count?sort=${sortChange?.key}&orderBy=${
          sortChange?.value
        }&type=${BBtype}&countryId=${country ? country : ""}&stateId=${
          state ? state : ""
        }&timeZone=${timezone}`,
      );
      if (status === 200) {
        setUpcomingTabCount(data?.count);
        setUpcomingLoading(false);
      } else {
        setUpcomingLoading(false);
      }
    } catch (err) {
      setUpcomingLoading(false);
    }
  };
  const fetchResultBlackBook = async (country, state, page) => {
    try {
      setResultLoading(true);
      const { status, data } = await axiosInstance.get(
        `blackbook/raceData?startDate=${periodChange?.startDate}&endDate=${
          periodChange?.endDate
        }&sort=${sortChange?.key}&orderBy=${
          sortChange?.value
        }&type=${BBtype}&countryId=${country ? country : ""}&stateId=${
          state ? state : ""
        }&comment=${isToggle}&limit=10&page=${page}&timeZone=${timezone}&isResult=true`,
      );

      if (status === 200) {
        const count = data?.count;
        setResultCount(count);
        setResultData(data?.result);
        setResultLoading(false);
      } else {
        setResultLoading(false);
      }
    } catch (err) {
      setResultLoading(false);
    }
  };
  const fetchAllBlackBook = async (page) => {
    try {
      setAllBBLoading(true);
      const { status, data } = await axiosInstance.get(
        `blackbook/favoriteData?sort=${sortChange?.key}&orderBy=${sortChange?.value}&type=${BBtype}&comment=${isToggle}&limit=10&page=${page}`,
      );

      if (status === 200) {
        const count = data?.count;
        setAllBBCount(count);

        setAllBBData(data?.result);
        setAllBBLoading(false);
      } else {
        setAllBBLoading(false);
      }
    } catch (err) {
      setAllBBLoading(false);
    }
  };
  const fetchLast12Month = () => {
    const currentDate = moment();
    const last12MonthsWithDates = [];

    for (let i = 0; i < 12; i++) {
      const startOfMonth = currentDate
        .clone()
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth =
        i === 0
          ? moment().format("YYYY-MM-DD")
          : currentDate.clone().endOf("month").format("YYYY-MM-DD");
      const Month = currentDate.format("MMM YYYY");

      last12MonthsWithDates.push({
        label: Month,
        startDate: startOfMonth,
        endDate: endOfMonth,
        value: i,
      });

      currentDate.subtract(1, "month");
    }
    setDateRanges(last12MonthsWithDates);
  };
  const handlePaginationClick = (event, page) => {
    setUpcomingPage(Number(page));
    fetchUpcomingBlackBook(countryId, stateId, Number(page));
  };

  const pageNumbers = [];

  if (upcomingCount > 0) {
    for (let i = 1; i <= Math.ceil(upcomingCount / 10); i++) {
      pageNumbers.push(i);
    }
  }
  const handleResultPaginationClick = (event, page) => {
    setResultPage(Number(page));
    fetchResultBlackBook(countryId, stateId, Number(page));
  };

  const resultpageNumbers = [];

  if (resultCount > 0) {
    for (let i = 1; i <= Math.ceil(resultCount / 10); i++) {
      resultpageNumbers.push(i);
    }
  }
  const handleAllBBPaginationClick = (event, page) => {
    setAllBBPage(Number(page));
    fetchAllBlackBook(Number(page));
  };

  const allBBpageNumbers = [];

  if (allBBCount > 0) {
    for (let i = 1; i <= Math.ceil(allBBCount / 10); i++) {
      allBBpageNumbers.push(i);
    }
  }
  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isCountrySelectOpen) {
          if (isCountrySearch === "" && countryApiCount !== count) {
            fetchCountry(pageCountry + 20);
            setpageCountry(pageCountry + 20);
          }
        }
      }, 2000);
    }
  }, [isCountrySelectOpen, countryApiCount, isCountrySearch]);
  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isStateSelectOpen) {
          if (isStateSearch === "" && stateApiCount !== statecount) {
            fetchState(countryId, pageState + 20, false, stateApiCount);
            setpageState(pageState + 20);
          }
        }
      }, 2000);
    }
  }, [isStateSelectOpen, stateApiCount, isStateSearch]);
  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
    fetchCountry(0);
    fetchLast12Month();
    // fetchUpcomingBlackBook();
  }, []);

  useEffect(() => {
    if (tabvalue === 0) {
      setUpcomingPage(1);
      fetchUpcomingBlackBook(countryId, stateId, 1);
      fetchUpcomingBlackBookFilterCount(countryId, stateId);
    } else if (tabvalue === 1) {
      setResultPage(1);
      fetchResultBlackBook(countryId, stateId, 1);
    } else if (tabvalue == 2) {
      setAllBBPage(1);
      fetchAllBlackBook(1);
    }
  }, [tabDate, sortChange, BBtype, isToggle, periodChange]);
  useEffect(() => {
    handleResetAll();
  }, [tabvalue]);
  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };
  return (
    <>
      <Box className="content-wrap blackbook-content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="blackbook-wrap">
          <Box className="blackbook-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  {localesData?.MENU?.HOME}
                </Link>

                <Typography>{localesData?.BLACKBOOK?.BLACKBOOK}</Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">
              {localesData?.BLACKBOOK?.BLACKBOOK}
            </Typography>
          </Box>
          <Box className="tab-search-section">
            <Box>
              <TextField
                placeholder={localesData?.BLACKBOOK?.SEARCH_PLACEHOLDER}
                variant="outlined"
                type="text"
                name="search"
                className="search_input"
                onKeyPress={() => handleBBSearchModal()}
                onClick={() => handleBBSearchModal()}
                // onChange={e => {
                //   setSearchInput(e.target.value);
                //   fetchTrackList(countryId, stateId, e.target.value);
                // }}
                // values={searchInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box className="team-sports-tab">
              <Tabs
                value={tabvalue}
                variant="scrollable"
                scrollButtons={false}
                indicatorColor="primary"
                textColor="primary"
                className="teamsport-tab-detail"
              >
                {newTabData?.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Tab
                        label={item?.name}
                        value={item?.id}
                        className={item?.id === tabvalue ? "active " : ""}
                        onChange={(event, newValue) =>
                          handleTabChange(event, item?.id)
                        }
                      />
                    </Box>
                  );
                })}
              </Tabs>
            </Box>
            {tabvalue === 0 ? (
              <>
                <Tabs
                  value={value}
                  variant="scrollable"
                  scrollButtons={false}
                  indicatorColor="primary"
                  textColor="primary"
                  className="day-tab-details"
                >
                  {day?.map((item, index) => {
                    return (
                      <Box key={index}>
                        <Tab
                          disableRipple
                          disableFocusRipple
                          label={
                            item?.name === "All"
                              ? `All ${upcomingTabCount?.allCount ? "(" + upcomingTabCount?.allCount + ")" : ""}`
                              : item?.date === Today
                                ? `Today ${upcomingTabCount?.todayCount ? "(" + upcomingTabCount?.todayCount + ")" : ""}`
                                : item?.date === Tomorrow
                                  ? `Tomorrow ${upcomingTabCount?.tomorrowCount ? "(" + upcomingTabCount?.tomorrowCount + ")" : ""}`
                                  : item?.date === nextDay
                                    ? `${item?.dayName + " " + (upcomingTabCount?.nextDayCount ? "(" + upcomingTabCount?.nextDayCount + ")" : "")}`
                                    : item?.date === secondNextDay
                                      ? `${
                                          item?.dayName +
                                          " " +
                                          (upcomingTabCount?.secondNextCount
                                            ? "(" +
                                              upcomingTabCount?.secondNextCount +
                                              ")"
                                            : "")
                                        }`
                                      : item?.dayName
                          }
                          value={item?.id}
                          className={item?.id == value ? "active" : ""}
                          onChange={(event, newValue) =>
                            handleChange(event, item?.id)
                          }
                        />
                      </Box>
                    );
                  })}
                </Tabs>
              </>
            ) : (
              <></>
            )}
          </Box>
          <Box className="page-deatils-wrap">
            <Box className="filter-section">
              <Select
                className="React sort-select"
                value={SortData?.find((item) => {
                  return item?.value === selectedSort;
                })}
                onChange={(e) => handleSortchange(e)}
                options={SortData}
                classNamePrefix="select"
                placeholder="Sort: Date (Newest to Oldest)"
                components={{ DropdownIndicator }}
              />
              {tabvalue === 0 || tabvalue === 2 ? (
                <></>
              ) : (
                <Select
                  className="React Period-select"
                  value={dateRanges?.find((item) => {
                    return item?.value === selectedPeriod;
                  })}
                  onChange={(e) => {
                    handlePeriodChange(e);
                  }}
                  options={dateRanges}
                  classNamePrefix="select"
                  placeholder="Period: Last 30 days"
                  // isSearchable={false}
                  components={{ DropdownIndicator }}
                />
              )}
              <Select
                className="React Entry-select"
                value={EntryData?.find((item) => {
                  return item?.value === selectedEntry;
                })}
                onChange={(e) => handleTypechange(e)}
                options={EntryData}
                classNamePrefix="select"
                placeholder="Entry: All"
                // isSearchable={false}
                components={{ DropdownIndicator }}
              />
              {tabvalue === 2 ? (
                <></>
              ) : (
                <>
                  <Select
                    className="React country-select"
                    onMenuScrollToBottom={(e) => handleOnScrollBottomCountry(e)}
                    onInputChange={(e) => handleCountryInputChange(0, e)}
                    value={
                      isCountrySearch
                        ? searchCountry?.find((item) => {
                            return item?.value == countryId;
                          })
                        : country?.find((item) => {
                            return item?.value == countryId;
                          })
                    }
                    onChange={(e) => {
                      fetchState(e?.value, 0, true, 0);
                      setCountryId(e?.value);
                      setState([]);
                      setStateId("");
                      setStateApiCount(0);
                      setpageState(0);
                      setisCountrySelectOpen(false);
                      // setstateValue("");
                      if (tabvalue === 0) {
                        fetchUpcomingBlackBook(e?.value, 0, upcomingPage);
                        fetchUpcomingBlackBookFilterCount(e?.value, 0);
                      } else if (tabvalue === 1) {
                        fetchResultBlackBook(e?.value, 0, resultPage);
                      }
                    }}
                    onFocus={() => setisCountrySelectOpen(true)}
                    onBlur={() => setisCountrySelectOpen(false)}
                    options={isCountrySearch ? searchCountry : country}
                    classNamePrefix="select"
                    placeholder="Country: All"
                    // isSearchable={false}
                    components={{ DropdownIndicator }}
                  />

                  <Select
                    className={
                      countryId
                        ? `React State-select `
                        : `React State-select disable-state`
                    }
                    onMenuScrollToBottom={(e) => handleOnScrollBottomState(e)}
                    onInputChange={(e) => handleStateInputChange(0, e)}
                    value={
                      isStateSearch
                        ? searchState?.find((item) => {
                            return item?.value == stateId;
                          })
                        : stateId !== "" &&
                          state?.find((item) => {
                            return item?.value == stateId;
                          })
                    }
                    onChange={(e) => {
                      setStateId(e?.value);
                      setisStateSelectOpen(false);
                      if (tabvalue === 0) {
                        fetchUpcomingBlackBook(
                          countryId,
                          e?.value,
                          upcomingPage,
                        );
                        fetchUpcomingBlackBookFilterCount(countryId, e?.value);
                      } else if (tabvalue === 1) {
                        fetchResultBlackBook(countryId, e?.value, resultPage);
                      }
                      // setStateApiCount(0);
                      // setstateValue(e?.value);
                      // fetchTrackList(countryId, e?.value, "", collepseRace);
                    }}
                    onFocus={() => setisStateSelectOpen(true)}
                    onBlur={() => setisStateSelectOpen(false)}
                    options={isStateSearch ? searchState : state}
                    classNamePrefix="select"
                    placeholder="State: All"
                    isDisabled={countryId ? false : true}
                    // isSearchable={false}
                    components={{ DropdownIndicator }}
                  />
                </>
              )}
              <Box className="switch-box">
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      color="primary"
                      checked={isToggle}
                      onChange={(e) => setisToggle(e.target.checked)}
                    />
                  }
                  label="Comments"
                  labelPlacement="start"
                />
              </Box>
              <Box
                className="reset-all cursor-pointer"
                onClick={() => handleResetAll()}
              >
                <Reset />
                <Typography className="reset-all-text">Reset All</Typography>
              </Box>
            </Box>
            {/* <Box className="page-content-wrap"> */}
            {tabvalue === 0 ? (
              <>
                {/* <UpcomingRunner
                upcomingData={upcomingData}
                isUpcomingLoading={isUpcomingLoading}
                fetchUpcomingBlackBook={fetchUpcomingBlackBook}
                countryId={countryId}
                stateId={stateId}
                handlePaginationClick={handlePaginationClick}
                upcomingCount={upcomingCount}
                upcomingPage={upcomingPage}
                pageNumbers={pageNumbers}
                isToggle={isToggle}
              /> */}
                <UpcomingRunnerV2
                  upcomingData={upcomingData}
                  isUpcomingLoading={isUpcomingLoading}
                  fetchUpcomingBlackBook={fetchUpcomingBlackBook}
                  countryId={countryId}
                  stateId={stateId}
                  handlePaginationClick={handlePaginationClick}
                  upcomingCount={upcomingCount}
                  upcomingPage={upcomingPage}
                  pageNumbers={pageNumbers}
                  isToggle={isToggle}
                />
              </>
            ) : tabvalue === 1 ? (
              <Results
                resultData={resultData}
                isresultLoading={isresultLoading}
                fetchResultBlackBook={fetchResultBlackBook}
                countryId={countryId}
                stateId={stateId}
                handlePaginationClick={handleResultPaginationClick}
                resultCount={resultCount}
                resultPage={resultPage}
                pageNumbers={resultpageNumbers}
                isToggle={isToggle}
              />
            ) : (
              <BlackBook
                allBBData={allBBData}
                isAllBBLoading={isAllBBLoading}
                fetchAllBlackBook={fetchAllBlackBook}
                handlePaginationClick={handleAllBBPaginationClick}
                allBBCount={allBBCount}
                allBBPage={allBBPage}
                pageNumbers={allBBpageNumbers}
                isToggle={isToggle}
              />
            )}
            {/* </Box> */}
          </Box>
        </Box>
      </Box>
      <BBSearch
        open={BBSearchopen}
        handleClose={handleBBSearchClose}
        handleBlackBookModal={handleBlackBookModal}
        closeIcon={true}
      />
      <BlackBookModal
        open={open}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        typeId={selectedId}
      />
    </>
  );
};

export default BlackbookPage;
