import React, { useState, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as CheckedRadioIcon } from "src/assets/images/icons/CheckedRadioIcon.svg";
import { ReactComponent as UnCheckedRadioIcon } from "src/assets/images/icons/UnCheckedRadioIcon.svg";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import Select, { components } from "react-select";
import Pagination from '@mui/material/Pagination';
import TipsCard from "../tipsCard/tipsCard";
import OurPartner from "../../ourPartners";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import moment from "moment-timezone";
import "./sportsTips.scss";
import NoDataComp from "../../UI/NoData";
import Loader from "src/components/Loader";

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const SportsTipsData = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
  {
    id: 4
  },
  {
    id: 5
  },
  {
    id: 6
  }
];
let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const SportsTipsPage = () => {
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [count, setcount] = useState(0);
  const limit = 20;
  const [filter, setFilter] = useState("day");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [sports, setSports] = useState([]);
  const [selectedSports, setSelectedSports] = useState("");
  const [teamsAll, setTeamsAll] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState("");
  const [VenAll, setVenAll] = useState([]);
  const [selectedVenues, setSelectedVenues] = useState("");
  const [OrgAll, setOrgAll] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [eventLoader, setEventLoader] = useState(false);

  const [isTeamSearch, setIsTeamSearch] = useState("");
  const [isTeamSelectOpen, setisTeamSelectOpen] = useState(false);
  const [searchTeam, setsearchTeam] = useState([]);
  const [searchTeamCount, setsearchTeamCount] = useState(0);
  const [SearchTeampage, setSearchTeampage] = useState(0);
  const [pageTeam, setpageTeam] = useState(0);
  const [teamApiCount, setTeamApiCount] = useState(0);

  const [isOrgSearch, setIsOrgSearch] = useState("");
  const [isOrgSelectOpen, setisOrgSelectOpen] = useState(false);
  const [searchOrg, setsearchOrg] = useState([]);
  const [searchOrgCount, setsearchOrgCount] = useState(0);
  const [SearchOrgpage, setSearchOrgpage] = useState(0);
  const [pageOrg, setpageOrg] = useState(0);
  const [OrgApiCount, setOrgApiCount] = useState(0);
  const [countOrg, setcountOrg] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [racingTippersData, setRacingTippersData] = useState([]);
  const [tipperCount, setTipperCount] = useState(0);
  const [tipperPage, setTipperPage] = useState(1);
  const [isTipperLoading, setIsTipperLoading] = useState(false);
  const rowPerPage = 10;

  const [selectedStartDate, setselectedStartDate] = useState(
    moment(Date()).format("YYYY-MM-DD")
    // .startOf("week")
  );

  const [isLoading, setIsLoading] = useState(false);
  const endDate = moment(Date())
    .add(6, "days")
    .format("YYYY-MM-DD");
  const [selectedEndDate, setselectedEndDate] = useState(endDate);
  const [selectedStartDateDay, setselectedStartDateDay] = useState();
  const [selectedMonth, setSelectedMonth] = useState(null);

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds?.[0]?.pageAdLists);
    }
  }, [ReduxAdsData]);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    setselectedStartDateDay(
      moment(Date())
        .tz(timezone)
        .format("YYYY-MM-DD")
    );
    setCurrentPage(0);
    fetchSportData();
    fetchSportTipperList(1, null);
  }, []);
  const fetchSportTipperList = async (page, sportId) => {
    setIsTipperLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/sport/tippers?timezone=${timezone}&startDate=2024-01-09&limit=${rowPerPage}&page=${page}&SportId=${
          sportId ? sportId : ""
        }`
      );
      if (status === 200) {
        setRacingTippersData(data?.result);
        setTipperCount(data?.count);
        setIsTipperLoading(false);
        setTipperPage(page);
      } else {
        setIsTipperLoading(false);
      }
    } catch (err) {
      setIsTipperLoading(false);
      console.log("error", err);
    }
  };
  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const getFields = (list, field) => {
    return list.reduce(function(carry, item) {
      if (typeof item === "object" && field in item) {
        carry.push(item[field]);
      }
      return carry;
    }, []);
  };

  const fetchSportData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/sport/?sportTypeId=${2}`
      );
      if (status === 200) {
        var sportsdata = data?.result.map(s => {
          return { ...s, label: s?.sportName, value: s?.id };
        });
        var filterSports = getFields(sportsdata, "id");
        setSelectedSports(filterSports);
        var sdata = _.orderBy(sportsdata, ["label"], ["asc"]);
        var mergedArray = _.concat(
          [{ label: "ALL", value: filterSports, isAll: true }],
          sdata
        );
        setSports(mergedArray);
      }
    } catch (err) {}
  };

  const handleOrgInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/tournament?SportId=${sid}&limit=${limit}&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchOrgCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const mergeData = _.unionBy(searchOrg, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchOrg(filterData);
          setIsOrgSearch(value);
        }
      });
  };
  const handleOnScrollBottomOrg = () => {
    if (
      isOrgSearch !== "" &&
      searchOrgCount !== Math.ceil(SearchOrgpage / 20)
    ) {
      handleOrgInputChange(SearchOrgpage + 20, isOrgSearch, selectedSports);

      setSearchOrgpage(SearchOrgpage + 20);
    } else {
      if (countOrg !== 0 && countOrg !== Math.ceil(pageOrg / 20)) {
        fetchOrgData(pageOrg + 20, selectedSports, OrgAll);
        setpageOrg(pageOrg + 20);
      }
    }
  };

  const fetchOrgData = async (page, sID, OrgAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/tournament?SportId=${sID}&offset=${page}&limit=${limit}`
      );
      if (status === 200) {
        setOrgApiCount(teamApiCount + 1);
        setcountOrg(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.name,
            value: item?.id
          });
        });
        let filterData = _.unionBy(OrgAll, newdata);
        setOrgAll(
          _.uniqBy(filterData, function(e) {
            return e.value;
          })
        );
      }
    } catch (err) {}
  };

  const fetchTeamData = async (page, sID, teamsAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/team?SportId=${sID}&offset=${page}&limit=${limit}`
      );
      if (status === 200) {
        setTeamApiCount(teamApiCount + 1);
        setcount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.name,
            value: item?.id
          });
        });
        let filterData = _.unionBy(teamsAll, newdata);
        setTeamsAll(
          _.uniqBy(filterData, function(e) {
            return e.value;
          })
        );
      }
    } catch (err) {}
  };

  const handleOnScrollBottomTeams = () => {
    if (
      isTeamSearch !== "" &&
      searchTeamCount !== Math.ceil(SearchTeampage / 20)
    ) {
      handleTeamInputChange(SearchTeampage + 20, isTeamSearch, selectedSports);

      setSearchTeampage(SearchTeampage + 20);
    } else {
      if (count !== 0 && count !== Math.ceil(pageTeam / 20 + 1)) {
        fetchTeamData(pageTeam + 20, selectedSports, teamsAll);
        setpageTeam(pageTeam + 20);
      }
    }
  };

  const handleTeamInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/team?SportId=${sid}&limit=${limit}&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchTeamCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const mergeData = _.unionBy(searchTeam, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchTeam(filterData);
          // setsearchTeam(
          //   _.uniqBy(filterData, function(e) {
          //     return e.value;
          //   })
          // );
          setIsTeamSearch(value);
        }
      });
  };
  const handlePaginationClick = (event, page) => {
    fetchSportTipperList(Number(page));
  };
  const pageNumbers = [];

  if (tipperCount > 0) {
    for (let i = 1; i <= Math.ceil(tipperCount / 10); i++) {
      pageNumbers.push(i);
    }
  }
  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="marketplace-tips-wrap">
          <Box className="marketplace-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  {/* {localesData?.MENU?.HOME} */}
                  HOME
                </Link>
                <Link underline="hover" color="inherit" to="#">
                  {/* {localesData?.MENU?.HOME} */}
                  Tips Marketplace
                </Link>
                <Typography>Sports Tips</Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">Sports Tips</Typography>
          </Box>
          <Box className="Marketplace-select-wrap">
            <Box className="filter-type-select">
              {/* <Select
                className="React horse-select"
                // value={SortData?.find(item => {
                //   return item?.value === selectedSort;
                // })}
                // onChange={e => handleSortchange(e)}
                // options={SortData}
                classNamePrefix="select"
                placeholder="All Horse racing"
                components={{ DropdownIndicator }}
              /> */}
              <Select
                className="React horse-select"
                value={sports?.find(item => {
                  return item?.value === selectedSports;
                })}
                onChange={e => {
                  setSelectedSports(e?.value == 0 ? "" : e?.value);
                  setSelectedTeams("");
                  setSelectedOrg("");
                  setTeamsAll([]);
                  setOrgAll([]);
                  fetchSportTipperList(1, e?.value);
                  if (!e?.isAll) {
                    fetchOrgData(0, e?.value, []);
                    fetchTeamData(0, e?.value, []);
                  }
                }}
                options={sports}
                classNamePrefix="select"
                placeholder="Sports"
                components={{ DropdownIndicator }}
              />
              <Select
                className="React horse-select"
                // className="React period-select"
                // value={SortData?.find(item => {
                //   return item?.value === selectedSort;
                // })}
                // onChange={e => handleSortchange(e)}
                // options={SortData}
                classNamePrefix="select"
                placeholder="Tipping Period:"
                components={{ DropdownIndicator }}
              />

              <Select
                className={
                  selectedSports && selectedSports.length > 0
                    ? `React period-select disable-state`
                    : `React period-select`
                }
                onMenuScrollToBottom={e => handleOnScrollBottomOrg(e)}
                onInputChange={e => handleOrgInputChange(0, e, selectedSports)}
                onChange={e => {
                  setSelectedOrg(e?.value);
                  fetchOrgData(e?.value, selectedSports, OrgAll);
                  setOrgApiCount(0);
                  setpageOrg(0);
                  setisOrgSelectOpen(false);
                }}
                onFocus={() => setisOrgSelectOpen(true)}
                onBlur={() => setisOrgSelectOpen(false)}
                value={
                  selectedOrg !== "" &&
                  (isOrgSearch
                    ? searchOrg?.find(item => {
                        return item?.value == selectedOrg;
                      })
                    : OrgAll?.find(item => {
                        return item?.value == selectedOrg;
                      }))
                }
                options={isOrgSearch ? searchOrg : OrgAll}
                classNamePrefix="select"
                placeholder="Tournament: All"
                isDisabled={
                  selectedSports && selectedSports.length > 0 ? true : false
                }
                components={{ DropdownIndicator }}
              />
              <Select
                className={
                  selectedSports && selectedSports.length > 0
                    ? `React bet-type-select disable-state`
                    : `React bet-type-select`
                }
                // options={teamsAll}
                onMenuScrollToBottom={e => handleOnScrollBottomTeams(e)}
                onInputChange={e => handleTeamInputChange(0, e, selectedSports)}
                onChange={e => {
                  setSelectedTeams(e?.value);
                  fetchTeamData(e?.value, selectedSports, teamsAll);
                  setTeamApiCount(0);
                  setpageTeam(0);
                  setisTeamSelectOpen(false);
                }}
                onFocus={() => setisTeamSelectOpen(true)}
                onBlur={() => setisTeamSelectOpen(false)}
                value={
                  selectedTeams !== "" &&
                  (isTeamSearch
                    ? searchTeam?.find(item => {
                        return item?.value == selectedTeams;
                      })
                    : teamsAll?.find(item => {
                        return item?.value == selectedTeams;
                      }))
                }
                options={isTeamSearch ? searchTeam : teamsAll}
                classNamePrefix="select"
                placeholder="Teams: All"
                isDisabled={
                  selectedSports && selectedSports.length > 0 ? true : false
                }
                components={{ DropdownIndicator }}
              />

              {/* <Box className="period-select-1 period-select-2"></Box> */}

              {/* <Select
                className="React bet-type-select"
                // value={SortData?.find(item => {
                //   return item?.value === selectedSort;
                // })}
                // onChange={e => handleSortchange(e)}
                // options={SortData}
                classNamePrefix="select"
                placeholder="Bet type:"
                components={{ DropdownIndicator }}
              /> */}
              <Select
                className="React rank-by-select"
                // value={SortData?.find(item => {
                //   return item?.value === selectedSort;
                // })}
                // onChange={e => handleSortchange(e)}
                // options={SortData}
                classNamePrefix="select"
                placeholder="Rank by:"
                components={{ DropdownIndicator }}
              />
              <Select
                className="React show-select"
                // value={SortData?.find(item => {
                //   return item?.value === selectedSort;
                // })}
                // onChange={e => handleSortchange(e)}
                // options={SortData}
                classNamePrefix="select"
                placeholder="Show:"
                components={{ DropdownIndicator }}
              />
              <Box className="period-select-1">
                {screenWidth > 1023 ? (
                  <FormControlLabel
                    value="selling"
                    className="selling-checkbox"
                    control={
                      <Checkbox
                        size="small"
                        icon={<UnCheckedRadioIcon className="radio-icon" />}
                        checkedIcon={
                          <CheckedRadioIcon className="radio-icon" />
                        }
                        disableRipple
                      />
                    }
                    label="Selling"
                  />
                ) : (
                  <></>
                )}
              </Box>
            </Box>
            {screenWidth < 1023 ? (
              <Box>
                <FormControlLabel
                  value="selling"
                  className="selling-checkbox"
                  control={
                    <Checkbox
                      size="small"
                      icon={<UnCheckedRadioIcon className="radio-icon" />}
                      checkedIcon={<CheckedRadioIcon className="radio-icon" />}
                      disableRipple
                    />
                  }
                  label="Selling"
                />
              </Box>
            ) : (
              <></>
            )}
            <Box className="tip-text">
              <Typography className="text">
                Showing 100 tippers that are selling. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. Praesent dictum finibus ipsum
                id tincidunt.{" "}
              </Typography>
            </Box>
          </Box>
          {isTipperLoading ? (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          ) : (
            <Box className="tips-wrap">
              {racingTippersData?.length > 0 ? (
                <>
                  <Box className="tips-wrap-grid">
                    {racingTippersData?.map((item, index) => {
                      return (
                        <>
                          <TipsCard
                            tipsData={item}
                            index={index}
                            page="sports"
                          />
                        </>
                      );
                    })}
                  </Box>
                  <Box className="tips-Pagination">
                    {/* {params?.categoryId != 1000 ? ( */}
                    <Pagination
                      // hideNextButton
                      // hidePrevButton
                      disabled={tipperCount / rowPerPage > 1 ? false : true}
                      page={tipperPage}
                      onChange={(e, value) => handlePaginationClick(e, value)}
                      count={pageNumbers[pageNumbers?.length - 1]}
                      siblingCount={2}
                      boundaryCount={1}
                      // count={10}
                      size="small"
                    />
                    {/* ) : (
                <></>
              )} */}
                  </Box>
                </>
              ) : (
                <NoDataComp />
              )}
              <OurPartner />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SportsTipsPage;
