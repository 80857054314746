import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Breadcrumbs, Tabs, Tab } from "@mui/material";
import AdvertisingBannner from "../../../../../advertisingBanner";
import OurPartner from "src/views/component/ourPartners";
import banner from "../../../../../../../assets/images/banner/smart-b-banner-secodary.webp";
import "./coachData.scss";
import CoachSummaryDetails from "./coachSummaryDetails";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import AdBannner from "../../../../../AdBanner/AdBanner";
import moment from "moment";

const newsTitle = [
  { value: 0, label: "Summary" }
  // { value: 1, label: "Games" },
  // { value: 2, label: "Opposite coaches" },
  // { value: 3, label: "Seasons" }
];

const CoachDataPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const SeasonId = searchParams.get("seasonId");
  const tabsRef = useRef(null);
  const [teamTabvalue, setTeamTabValue] = useState(0);
  const [headerData, setHeaderData] = useState({});
  const [summaryData, setSummaryData] = useState({});
  const [coachTournamentData, setCoachTournamentData] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleChange = (id, value) => {
    setTeamTabValue(id);
  };

  const routeName =
    params?.sportId === "4"
      ? "cricket"
      : params?.sportId === "12"
      ? "rugbyleague"
      : params?.sportId === "13"
      ? "rugbyunion"
      : params?.sportId === "10"
      ? "basketball"
      : params?.sportId === "15"
      ? "americanfootball"
      : params?.sportId === "9"
      ? "australianrules"
      : params?.sportId === "16"
      ? "golf"
      : params?.sportId === "7"
      ? "tennis"
      : params?.sportId === "11"
      ? "baseball"
      : params?.sportId === "17"
      ? "icehockey"
      : params?.sportId === "6"
      ? "boxing"
      : params?.sportId === "5"
      ? "mma"
      : params?.sportId === "8"
      ? "soccer"
      : "";

  useEffect(() => {
    fetchBreacrumnData();
    fetchSummaryTournamentDetails();
  }, [params, SeasonId]);

  const fetchBreacrumnData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/header/?SportId=${params?.sportId}&tournamentId=${
          params?.tournamentId
        }&teamId=${params?.teamId}&playerId=${params?.playerId}&coachId=${
          params?.coachId
        }&seasonId=${SeasonId ? SeasonId : ""}`
      );
      if (status === 200) {
        setHeaderData(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const fetchSummaryTournamentDetails = async () => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/coach/summary/${params?.coachId}?SportId=${
          params?.sportId
        }&tournamentId=${params?.tournamentId}&seasonId=${
          SeasonId ? SeasonId : ""
        }`
      );
      if (status === 200) {
        setLoader(false);
        setSummaryData(data?.result?.coach);
        setCoachTournamentData(data?.result?.tournament);
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  };
  return (
    <>
      <Box className="content-wrap">
        {/* <AdvertisingBannner src={banner} /> */}
        <Box className="mobile-banner">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="league-data-wrap">
          <Box className="teamsport-data-header">
            <Box className="bredcrumn-deatils">
              <Box className="bredcrumn-wrap">
                <Breadcrumbs
                  separator="/"
                  aria-label="breadcrumb"
                  className="breadcrumb"
                >
                  <Link underline="hover" color="inherit" to="/">
                    Home
                  </Link>
                  <Link underline="hover" color="inherit" to="/">
                    Sports
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    to={`/teamsports/${routeName}/odds/0/false`}
                  >
                    {headerData?.sport?.sportName}
                  </Link>
                  {(SeasonId || headerData?.tournament) && (
                    <Link
                      underline="hover"
                      color="inherit"
                      to={`/sport/statistics/${params?.sportId}/${
                        params?.tournamentId
                      }/league-data?seasonId=${SeasonId ? SeasonId : ""}`}
                    >
                      {SeasonId
                        ? headerData?.season?.name
                        : headerData?.tournament?.name}
                    </Link>
                  )}
                  {headerData?.team && (
                    <Link
                      underline="hover"
                      color="inherit"
                      to={`/sport/statistics/${params?.sportId}/${
                        params?.tournamentId
                      }/league-data/${params?.teamId}/team-data?seasonId=${
                        SeasonId ? SeasonId : ""
                      }`}
                    >
                      {headerData?.team?.name}
                    </Link>
                  )}

                  {headerData?.player && (
                    <Link
                      underline="hover"
                      color="inherit"
                      to={`/sport/statistics/${params?.sportId}/${
                        params?.tournamentId
                      }/league-data/${params?.teamId}/team-data/${
                        params?.playerId
                      }/player-data?seasonId=${SeasonId ? SeasonId : ""}`}
                    >
                      {headerData?.player?.name}
                    </Link>
                  )}
                  <Typography>{headerData?.coach?.name}</Typography>
                </Breadcrumbs>
              </Box>
            </Box>
            <Typography variant="h1">{headerData?.coach?.name}</Typography>
          </Box>
          <Box className="teamsport-data-tab">
            <Tabs
              value={teamTabvalue}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              className="teamsport-data-tab-details player-tab-details"
              // disableRipple
              // disableFocusRipple
              id="news-tab"
              ref={tabsRef}
            >
              {newsTitle.map((item, index) => {
                return (
                  <Box key={index}>
                    <Tab
                      label={item?.label}
                      value={item?.value}
                      onChange={() => handleChange(item?.value, item?.label)}
                      className={item?.value == teamTabvalue ? "active" : ""}
                    />
                  </Box>
                );
              })}
            </Tabs>
            {teamTabvalue === 0 ? (
              <>
                <Box className="tab-deatils">
                  <Box className="tab-details-grid">
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Full Name:
                      </Typography>
                      <Typography className="details-text">
                        {summaryData?.name ? summaryData?.name : "-"}
                      </Typography>
                    </Box>
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Born:
                      </Typography>
                      <Typography className="details-text">
                        {summaryData?.dob
                          ? moment(summaryData?.dob).format(
                              "dddd, Do MMMM, YYYY"
                            )
                          : "-"}
                      </Typography>
                    </Box>
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Current Age:
                      </Typography>
                      <Typography className="details-text">
                        {summaryData?.dob
                          ? moment().diff(moment(summaryData?.dob), "years")
                          : "-"}
                      </Typography>
                    </Box>
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Place of Birth:
                      </Typography>
                      <Typography className="details-text">-</Typography>
                    </Box>
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Awards & Accolades:
                      </Typography>
                      <Typography className="details-text">-</Typography>
                    </Box>
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Known Family Links:
                      </Typography>
                      <Typography className="details-text">
                        Brother -<span className="light-blue">-</span>
                      </Typography>
                      <Typography className="details-text">
                        Son - <span className="light-blue">-</span>
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box className="team-deatils-wrap">
                    <Typography className="coach-personal-details mb-18">
                      <span className="bold">Biography:</span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Cras vestibulum fermentum sem vel tempus. In elementum
                      pretium nisl, et dignissim augue posuere in. Etiam
                      convallis tortor eget nulla ullamcorper auctor. Integer eu
                      nisi et felis fermentum faucibus vel quis magna. Quisque
                      molestie dapibus justo vel mattis. Donec id leo mauris.
                      Vestibulum tincidunt velit nisl, at eleifend lacus pretium
                      sit amet. Sed eget nunc leo. Etiam at erat ut est interdum
                      lobortis. Sed bibendum tortor felis, nec vulputate sapien
                      dignissim ac. Suspendisse ac feugiat nisl, rhoncus
                      facilisis urna. Vestibulum vel enim nulla. Ut et est
                      scelerisque, laoreet nibh nec, aliquam enim. Nullam
                      sollicitudin consectetur tortor, ac eleifend sapien
                      convallis eu. In augue libero, faucibus sed lectus id,
                      facilisis tempor libero. Vestibulum finibus erat eget
                      commodo maximus.
                    </Typography>
                    <Typography className="coach-personal-details">
                      Nullam laoreet porttitor blandit. Aenean eu feugiat odio.
                      Sed accumsan diam ac rhoncus vehicula. Sed pellentesque
                      tortor non orci imperdiet, ac porttitor nunc hendrerit.
                      Integer tincidunt bibendum sodales. Nam porttitor finibus
                      purus. Ut tempor, ipsum quis suscipit malesuada, erat
                      nulla fermentum erat, consectetur sagittis enim urna ut
                      nunc. Sed et sem in arcu aliquam ultrices rutrum sit amet
                      nibh.
                    </Typography>
                  </Box> */}
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>

          {/* All tab deatils add here condition add like teamTabvalue === 0 ? "" : "" */}

          {teamTabvalue === 0 ? (
            <CoachSummaryDetails
              headerData={headerData}
              loader={loader}
              coachTournamentData={coachTournamentData}
            />
          ) : (
            <Box className="no-data-wrap">
              <Typography className="text-align-center no-data">
                No data Available
              </Typography>
            </Box>
          )}
        </Box>
        <OurPartner />
      </Box>
    </>
  );
};

export default CoachDataPage;
