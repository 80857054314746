import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Breadcrumbs,
  Tab,
  Tabs,
  Typography,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Button,
  Tooltip,
  Collapse,
  Switch,
  FormControlLabel,
  ClickAwayListener,
  IconButton,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import AdBannner from "src/views/component/AdBanner/AdBanner";
import { useDispatch, useSelector } from "react-redux";
import Loader from "src/components/Loader";
import teamLogo from "src/assets/images/bookmakers/BlueBet-thumb.png";
import { IntlContext } from "src/App";
// import { fetchInterval } from "../../../../../helpers/store/Actions/interval";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import { ReactComponent as WhiteLeftArrow } from "src/assets/images/icons/right-arrow-white.svg";
import { ReactComponent as TriLeft } from "src/assets/images/icons/tri-left.svg";
import moment from "moment-timezone";
import "./purchasedTips.scss";
import RaceCountdown from "../raceCountdown";
import OurPartner from "../../ourPartners";

const tableData = [
  {
    id: 0,
    date: "2023-12-27T07:08:00.000Z",
    event: "R4 Mornington",
    Runner: "16 Ellenmac",
    result: "5th",
    win: "$4.40",
    place: "$1.80",
    invoice: "Invoice",
    cost: "Cost",
    tipperName: "Chloe Royle"
  },
  {
    id: 1,
    date: "2023-12-27T07:08:00.000Z",
    event: "R4 Mornington",
    Runner: "16 Ellenmac",
    result: "5th",
    win: "$4.40",
    place: "$1.80",
    invoice: "Invoice",
    cost: "Cost",
    tipperName: "Carlos Vinicius"
  },
  {
    id: 2,
    date: "2023-12-27T07:08:00.000Z",
    event: "R4 Mornington",
    Runner: "16 Ellenmac",
    result: "5th",
    win: "$4.40",
    place: "$1.80",
    invoice: "Invoice",
    cost: "Cost",
    tipperName: "Sasa Kalajdzic"
  }
];
const collapseData = [
  {
    id: 0,
    date: "2023-12-27T07:08:00.000Z",
    event: "R4 Mornington",
    runner: "16 Ellenmac",
    result: "5th",
    track: "R4 Mornington",
    jockey: "Logan Bates",
    trainer: "Chloe Royle",
    win: "$4.40",
    place: "$1.80",
    invoice: "Invoice",
    cost: "Cost",
    tournament: "NFL Divisions",
    team: "Brisbane Lions",
    event: "Brisbane Lions VS Melbourne"
  },
  {
    id: 1,
    date: "2023-12-27T07:08:00.000Z",
    event: "R4 Mornington",
    runner: "16 Ellenmac",
    result: "5th",
    track: "R4 Mornington",
    jockey: "Logan Bates",
    trainer: "Carlos Vinicius",
    win: "$4.40",
    place: "$1.80",
    invoice: "Invoice",
    cost: "Cost",
    tournament: "AFL",
    team: "Melbourne Demons",
    event: "Sydney Swans VS Melbourne Demons"
  },
  {
    id: 2,
    date: "2023-12-27T07:08:00.000Z",
    event: "R4 Mornington",
    runner: "16 Ellenmac",
    result: "5th",
    track: "R4 Mornington",
    jockey: "Logan Bates",
    trainer: "Sasa Kalajdzic",
    win: "$4.40",
    place: "$1.80",
    invoice: "Invoice",
    cost: "Cost",
    tournament: "NFL Divisions",
    team: "GWS Giants",
    event: "GWS Giants VS Fermantle"
  }
];

const PurchasedTipsPage = () => {
  const params = useParams();
  const [visibleAds, setVisibleAds] = useState([]);
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds?.[0]?.pageAdLists);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="tips-marketplace-tips-wrap">
          <Box className="marketplace-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  HOME
                </Link>
                <Link underline="hover" color="inherit" to="#">
                  Tips Marketplace
                </Link>
                <Typography>Purchased Tips</Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">Purchased Tips</Typography>
          </Box>
          <Box>
            <Box className="purchased-tip-table-sec">
              <Box className="top-info">
                <Typography className="top-head">
                  Your Purchased Tips
                </Typography>
                <Typography className="top-des">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  fermentum consequat est, rhoncus scelerisque urna cursus ut.
                  Morbi pharetra mi ac ex mollis tristique. Sed fermentum
                  consequat est, rhoncus scelerisque urna cursus ut. Morbi
                  pharetra mi ac ex mollis tristique.{" "}
                </Typography>
              </Box>
              {/* <Box className="header-sec">
                <Typography className="header">Your Recent Result</Typography>
              </Box> */}
              <Box className="table-content-sec">
                {screenWidth > 1130 ? (
                  <Table className="table-content">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="table-title"
                          style={{ width: "12%" }}
                        >
                          DATE
                        </TableCell>
                        <TableCell className="table-title">INVOICE</TableCell>
                        <TableCell
                          className="table-title"
                          style={{ width: "25%" }}
                        >
                          COST
                        </TableCell>
                        <TableCell
                          className="table-title"
                          style={{ width: "46%" }}
                        >
                          DESCRIPTION
                        </TableCell>
                        <TableCell className="table-title"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData?.map((item, index) => {
                        return (
                          <>
                            <TableRow>
                              <TableCell className="table-detail">
                                {moment
                                  .utc(item?.date)
                                  .local()
                                  .format("DD/MM/YYYY")}
                              </TableCell>
                              <TableCell className="table-detail">
                                {item?.invoice}
                              </TableCell>
                              <TableCell className="table-detail">
                                {item?.cost}
                              </TableCell>
                              <TableCell className="table-detail">
                                Tips from <span>{item?.tipperName}</span>
                              </TableCell>
                              <TableCell>
                                <Box className="continue-btn-sec">
                                  <Button
                                    //   variant="contained"
                                    className="continue-btn"
                                    endIcon={<WhiteLeftArrow />}
                                    onClick={() => {
                                      // setHoveredIndex(index);
                                      // // handleCollapseOpen( id,index);
                                      // handleOutsideClick(index);
                                      handleToggle(item?.id);
                                    }}
                                  >
                                    View
                                  </Button>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={6}
                              >
                                <Collapse
                                  in={checked.includes(item?.id)}
                                  timeout="auto"
                                  unmountOnExit
                                  // style={{ display: "flex" }}
                                  className="collapse"
                                >
                                  <Table
                                    className="inner-table-content blue-border"
                                    style={{ width: "90%" }}
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          className="table-title"
                                          style={{ width: "33%" }}
                                        >
                                          Date/Time
                                        </TableCell>

                                        {params?.page === "sports" ? (
                                          <>
                                            <TableCell className="table-title">
                                              Tournament
                                            </TableCell>
                                            <TableCell
                                              className="table-title"
                                              style={{ width: "25%" }}
                                            >
                                              Team
                                            </TableCell>
                                            <TableCell
                                              className="table-title"
                                              style={{ width: "20%" }}
                                            >
                                              Event
                                            </TableCell>
                                          </>
                                        ) : (
                                          <>
                                            <TableCell className="table-title">
                                              Tracks
                                            </TableCell>
                                            <TableCell
                                              className="table-title"
                                              style={{ width: "20%" }}
                                            >
                                              Runner
                                            </TableCell>
                                            <TableCell
                                              className="table-title"
                                              style={{ width: "15%" }}
                                            >
                                              Jockey
                                            </TableCell>
                                            <TableCell
                                              className="table-title"
                                              style={{ width: "15%" }}
                                            >
                                              Trainer
                                            </TableCell>
                                          </>
                                        )}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {collapseData?.map((item, index) => {
                                        return (
                                          <>
                                            <TableRow>
                                              <TableCell className="table-detail">
                                                <Box className="time-count-txt">
                                                  <Box>
                                                    <span>
                                                      {item?.date
                                                        ? moment
                                                            .utc(item?.date)
                                                            .local()
                                                            .format(
                                                              "DD/MM/YYYY"
                                                            )
                                                        : ""}{" "}
                                                    </span>
                                                    |{" "}
                                                    <span>
                                                      {item?.date
                                                        ? moment
                                                            .utc(item?.date)
                                                            .local()
                                                            .format("hh:mm A")
                                                        : ""}{" "}
                                                    </span>
                                                  </Box>
                                                  <Box>
                                                    {/* <Typography>
                                                      <RaceCountdown
                                                        expiryTimestamp={new Date(
                                                          item?.date
                                                        ).getTime()}
                                                      />
                                                    </Typography> */}
                                                  </Box>
                                                </Box>
                                                <Box className="learn-more-box">
                                                  <Typography
                                                    className="learn-more"
                                                    // onClick={() => learnMore(item?.url)}
                                                  >
                                                    See odds
                                                  </Typography>
                                                </Box>
                                              </TableCell>
                                              {params?.page === "sports" ? (
                                                <>
                                                  <TableCell className="table-detail ">
                                                    <Box className="chip-sec">
                                                      <Typography className="chip-txt">
                                                        {item?.tournament}
                                                      </Typography>
                                                    </Box>
                                                  </TableCell>
                                                  <TableCell className="table-detail">
                                                    {item?.team}
                                                  </TableCell>
                                                  <TableCell className="table-detail">
                                                    {item?.event}
                                                  </TableCell>
                                                </>
                                              ) : (
                                                <>
                                                  <TableCell className="table-detail">
                                                    {item?.track}
                                                  </TableCell>
                                                  <TableCell className="table-detail">
                                                    {item?.runner}{" "}
                                                    <span>(2)</span>
                                                  </TableCell>
                                                  <TableCell className="table-detail">
                                                    {item?.jockey}
                                                  </TableCell>
                                                  <TableCell className="table-detail">
                                                    {item?.trainer}
                                                  </TableCell>
                                                </>
                                              )}

                                              {/* <TableCell className="table-detail">
                                                {item?.result}
                                              </TableCell> */}
                                            </TableRow>
                                          </>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                  <Table
                                    className="inner-table-content dashed-border"
                                    style={{ width: "10%" }}
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          className="table-title"
                                          style={{ width: "10%" }}
                                        >
                                          Result
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {collapseData?.map((item, index) => {
                                        return (
                                          <TableRow>
                                            {params?.page === "sports" ? (
                                              <>
                                                <TableCell className="table-detail text-center">
                                                  {/* <Box className="chip-sec"> */}
                                                  <Typography>
                                                    4{" "}
                                                    <span>
                                                      <TriLeft />
                                                    </span>
                                                  </Typography>
                                                  <Typography>
                                                    3{" "}
                                                    <span>
                                                      <TriLeft />
                                                    </span>
                                                  </Typography>
                                                  {/* </Box> */}
                                                </TableCell>
                                              </>
                                            ) : (
                                              <>
                                                <TableCell className="table-detail text-center">
                                                  {item?.result}
                                                </TableCell>
                                              </>
                                            )}
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : (
                  <>
                    {tableData?.map((item, index) => {
                      return (
                        <>
                          <Box className="left-section-details" key={index}>
                            <Accordion>
                              <AccordionSummary
                                // expandIcon={<AccordionExpand />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Box className="event-details">
                                  <Box className="title">
                                    <Typography className="title-txt">
                                      Tips from <span>{item?.tipperName}</span>
                                    </Typography>
                                  </Box>
                                  <Box className="profit-wrap">
                                    <Box className="profit-section">
                                      <Typography className="profit-text">
                                        Date:
                                      </Typography>
                                      <Typography className="profit-text profit-text-bold">
                                        {moment
                                          .utc(item?.date)
                                          .local()
                                          .format("DD MMM YYYY")}
                                      </Typography>
                                    </Box>
                                    <Box className="profit-section">
                                      <Typography className="profit-text">
                                        Runner:
                                      </Typography>
                                      <Typography className="profit-text profit-text-bold">
                                        3%
                                      </Typography>
                                    </Box>
                                    <Box className="profit-section">
                                      <Typography className="profit-text">
                                        Result:
                                      </Typography>
                                      <Typography className="profit-text profit-text-bold">
                                        22%
                                      </Typography>
                                    </Box>
                                    <Box className="continue-btn-sec">
                                      <Button
                                        //   variant="contained"
                                        className="continue-btn"
                                        endIcon={<WhiteLeftArrow />}
                                        onClick={() => {
                                          // setHoveredIndex(index);
                                          // // handleCollapseOpen( id,index);
                                          // handleOutsideClick(index);
                                          handleToggle(item?.id);
                                        }}
                                      >
                                        View
                                      </Button>
                                    </Box>
                                  </Box>
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                {/* {collapseData?.map((item, index) => {
                                  return ( */}
                                <Box className="accordion-details">
                                  <Box className="first-sec">
                                    <Typography className="detail">
                                      Date/Time
                                    </Typography>
                                    <Typography className="detail fw-500">
                                      {item?.date
                                        ? moment
                                            .utc(item?.date)
                                            .local()
                                            .format("DD/MM/YYYY")
                                        : ""}{" "}
                                    </Typography>
                                    <Typography className="detail fw-400">
                                      {item?.date
                                        ? moment
                                            .utc(item?.date)
                                            .local()
                                            .format("hh:mm A")
                                        : ""}{" "}
                                    </Typography>
                                    <Box className="learn-more-box">
                                      <Typography
                                        className="learn-more"
                                        // onClick={() => learnMore(item?.url)}
                                      >
                                        See odds
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box className="first-sec">
                                    {params?.page === "sports" ? (
                                      <>
                                        <Typography className="detail">
                                          Tournament
                                        </Typography>
                                        <Typography className="detail blue-txt fw-500">
                                          Brisbane Lions
                                        </Typography>
                                        <Box className="chip-sec">
                                          <Typography className="chip-txt">
                                            NFL Divisions
                                          </Typography>
                                        </Box>
                                      </>
                                    ) : (
                                      <>
                                        <Typography className="detail">
                                          Track
                                        </Typography>
                                        <Typography className="detail fw-500">
                                          DFdsafdsf
                                        </Typography>
                                        <Typography>
                                          {/* <Typography>
                                                      <RaceCountdown
                                                        expiryTimestamp={new Date(
                                                          item?.date
                                                        ).getTime()}
                                                      />
                                                    </Typography> */}
                                        </Typography>
                                        <Box className="result-chip-sec">
                                          <Typography className="detail fw-400">
                                            Result -
                                          </Typography>
                                          <Box className="chip">
                                            <Typography className="detail txt-white">
                                              1st
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </>
                                    )}
                                  </Box>
                                  <Box
                                    className="first-sec"
                                    style={
                                      screenWidth > 460
                                        ? { width: "30%" }
                                        : {
                                            width: "35%"
                                          }
                                    }
                                  >
                                    {params?.page === "sports" ? (
                                      <>
                                        <Typography className="detail">
                                          Event
                                        </Typography>
                                        <Box className="basic-flex">
                                          <Typography className="detail fw-500 mw-150">
                                            Brisbane Lions VS Melbourne
                                          </Typography>
                                          <Box>
                                            <Typography className="detail basic-flex">
                                              4{" "}
                                              <span>
                                                <TriLeft />
                                              </span>
                                            </Typography>
                                            <Typography className="detail basic-flex">
                                              3{" "}
                                              <span>
                                                <TriLeft />
                                              </span>
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </>
                                    ) : (
                                      <>
                                        <Typography className="detail">
                                          Runner
                                        </Typography>
                                        <Typography className="detail fw-500">
                                          asdfasdfasd <span>(2)</span>
                                        </Typography>
                                        <Typography className="detail blue-txt fw-400">
                                          [Jockey]
                                        </Typography>
                                        <Typography className="detail blue-txt fw-400">
                                          [Trainer]
                                        </Typography>
                                      </>
                                    )}
                                  </Box>
                                </Box>
                                {/* );
                                })} */}
                              </AccordionDetails>
                            </Accordion>
                          </Box>
                        </>
                      );
                    })}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <OurPartner />
      </Box>
    </>
  );
};

export default PurchasedTipsPage;
