import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Button,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./runnerResultTable.scss";
import { IntlContext } from "src/App";
import Tooltip from "@mui/material/Tooltip";
import Select from "react-select";

// import { RunnerTable } from "./runnerTable";
import { RunnerTable } from "./runnerTableV2";
import Bet365 from "../../../../../assets/images/bookmakers/bet365-thumb.svg";
import UniBet from "../../../../../assets/images/bookmakers/unibet-thumb.svg";
import BookMaker from "../../../../../assets/images/bookmakers/bookmakers-thumb.svg";
import Neds from "../../../../../assets/images/bookmakers/neds-thumb.svg";
import PlayUp from "../../../../../assets/images/bookmakers/playup-thumb.svg";
import BetStar from "../../../../../assets/images/bookmakers/betstar-thumb.svg";
// import ViceBet from "../../../../../assets/images/bookmakers/vice-bet-thumb.svg";
import BlueBet from "../../../../../assets/images/bookmakers/BlueBet-thumb.png";
import TopSport from "../../../../../assets/images/bookmakers/top-sport-thumb.svg";
import betFair from "../../../../../assets/images/bookmakers/betfair-thumb.svg";
import BoomBet from "../../../../../assets/images/bookmakers/BoomBet.png";
import LadBrokes from "../../../../../assets/images/bookmakers/Ladbrokes-thumb.png";
import Draftstars from "../../../../../assets/images/bookmakers/DraftStars.png";
import SouthernCrossBet from "../../../../../assets/images/bookmakers/SouthernCrossBet.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Config, fetchFromStorage } from "src/helpers/context";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import Loader from "src/components/Loader";
import { split } from "lodash";
import NoDataComp from "src/views/component/UI/NoData";
import DefaultImg from "src/assets/images/smartb_default.png";
import { useSelector } from "react-redux";

const resultTabData = [
  {
    id: 0,
    name: "Results",
  },
  {
    id: 1,
    name: "Exotics",
  },
  {
    id: 2,
    name: "Dividends",
  },
  // {
  //   id: 3,
  //   name: "Multiples",
  // },
];

const options = [
  // { value: "", label: "ODDS TYPE", disabled: true },
  { value: "1", label: "Win Fixed" },
  { value: "2", label: "Place Fixed" },
  // { value: "3", label: "Win Tote" },
  // { value: "4", label: "Place Tote" },
  // { value: "4", label: "Starting Price" },
];
const RunnerResultTable = ({
  raceId,
  runnerInfo,
  isrunnnerLoading,
  isResult,
  RaceResultData,
  raceTrackdata,
  fetchRaceRunner,
}) => {
  const params = useParams();
  let sportId = params?.sportId;
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const BookkeeperData = useSelector(
    (state) => state?.reduxData?.BookkeeperData
  );
  const [resultTabValue, setResultTabValue] = useState(0);
  const [Summary, setSummary] = useState([]);
  const [sponsoredId, setSponsoredId] = useState([]);
  const [oddsLoader, setOddsLoader] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedOption, setSelectedOption] = useState("1");
  const [SelectedDividend, setSelectedDividend] = useState("1");
  const [oddsType, setOddsType] = useState({ marketId: 1, oddKey: 1 });
  const [ExoticsData, setExoticsData] = useState([]);
  const [MultipleData, setMultipleData] = useState([]);
  const [ToteWinData, setToteWinData] = useState([]);
  const [TotePlaceData, setTotePlaceData] = useState([]);
  const [DividendData, setDividendData] = useState([]);
  const [topBookMakersData, setTopBookMakersData] = useState([]);
  const [topBookmakerLoading, setTopBookmakerLoading] = useState(false);
  const [stateTabValue, setStateTabValue] = useState(0);
  const localAuth = fetchFromStorage("auth_token");
  const fetchRank = (rank) => {
    let raceRank = rank === 1 ? "winner" : "not-winner";
    return raceRank;
  };
  const oddsTypeChange = (e) => {
    setSelectedOption(e?.value);
    switch (e?.value) {
      case "1":
        setOddsType({ marketId: 1, oddKey: 1 });
        break;
      case "2":
        setOddsType({ marketId: 1, oddKey: 2 });
        break;
      case "3":
        setOddsType({ marketId: 2, oddKey: 1 });
        break;
      case "4":
        setOddsType({ marketId: 2, oddKey: 2 });
        break;
      default:
        setOddsType({ marketId: 1, oddKey: 1 });
    }
  };
  // const oddsicon = (BookKeeperId, type) => {
  //   const newData = raceTrackdata?.ApiRaceIdentifiers?.map((obj) => {
  //     const BookkeeperObj = obj?.provider?.BookKeeperProviders?.filter(
  //       (item) => {
  //         return item?.BookKeeperId === BookKeeperId;
  //       }
  //     );
  //     return {
  //       ...obj,
  //       BookKeeperId:
  //         BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null,
  //     };
  //   });

  //   const filteredData = newData?.filter?.(
  //     (obj) => obj?.BookKeeperId === BookKeeperId
  //   );
  //   let icon = BookkeeperData?.filter(
  //     (obj) => obj?.BookKeeperId === BookKeeperId
  //   );
  //   let iconData = icon?.[0]?.BookKeeper;
  //   if (BookKeeperId == 13) {

  //     const fetchUrlArray = filteredData?.[0]?.url?.split("/")
  //     const fetchMarketId = fetchUrlArray?.[fetchUrlArray?.length - 1]
  //     const isMobile = () => {
  //       const userAgent = navigator.userAgent;
  //       return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  //     }
  //     const betFairUrl = isMobile() ? filteredData?.[0]?.url ? filteredData?.[0]?.url + `?Referrer=SmartB` : iconData?.affiliate_link : filteredData?.[0]?.url ? `https://www.betfair.com.au/exchange/plus/${params?.sportId == 3 ? "greyhound-racing" : "horse-racing"}/market/${fetchMarketId}` : iconData?.affiliate_link
  //     return (
  //       <a
  //         href={betFairUrl}
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         <img className="bookmaker-thumb" src={iconData?.small_logo?.includes("uploads") ? Config.mediaURL + iconData?.small_logo : iconData?.small_logo} alt="Odds Icon" onClick={() => handleBookkeeperCounter(BookKeeperId, type)} />
  //       </a>
  //     );
  //   } else {
  //     return (
  //       <a
  //         href={filteredData?.length > 0 && filteredData?.[0]?.url ? filteredData?.[0]?.url + `?Referrer=SmartB` : iconData?.affiliate_link}
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         <img className="bookmaker-thumb" src={iconData?.small_logo?.includes("uploads") ? Config.mediaURL + iconData?.small_logo : iconData?.small_logo} alt="Odds Icon" onClick={() => handleBookkeeperCounter(BookKeeperId, type)} />
  //       </a>
  //     );
  //   }

  // };
  const oddsicon = (BookKeeperId, type, item, oddsType) => {
    const newData = raceTrackdata?.ApiRaceIdentifiers?.map((obj) => {
      const BookkeeperObj = obj?.provider?.BookKeeperProviders?.filter(
        (item) => {
          return item?.BookKeeperId === BookKeeperId;
        }
      );
      return {
        ...obj,
        BookKeeperId:
          BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null,
      };
    });

    const filteredData = newData?.filter?.(
      (obj) => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter((obj) => obj?.id === BookKeeperId);
    let iconData = icon?.[0];
    let imageUrl =
      oddsType == "currentbest"
        ? iconData?.currentBest_logo
        : iconData?.long_logo;
    if (item && BookKeeperId === 8) {
      const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.[0]?.RaceOdds?.[0];
      return Bet365Data && Bet365Data?.length > 0 ? (
        <a
          href={
            Odds?.providerMarketId && Odds?.providerParticipantId
              ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId}~${Odds?.intValue}~1&bet=1`
              : `${iconData?.affiliate_link}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link"
        >
          {oddsType == "currentbest" ? (
            <img
              className="currentbest-bookmaker-thumb"
              src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
              alt="Odds Icon"
              onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
            />
          ) : (
            <Box className="odd-img">
              <img
                src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                alt="Odds Icon"
                onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
              />
            </Box>
          )}
        </a>
      ) : (
        <></>
      );
    } else if (BookKeeperId == 13) {
      const fetchUrlArray = filteredData?.[0]?.url?.split("/");
      const fetchMarketId = fetchUrlArray?.[fetchUrlArray?.length - 1];
      const isMobile = () => {
        const userAgent = navigator.userAgent;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        );
      };
      const betFairUrl = isMobile()
        ? filteredData?.[0]?.url
          ? filteredData?.[0]?.url + `?Referrer=SmartB`
          : iconData?.affiliate_link
        : filteredData?.[0]?.url
          ? `https://www.betfair.com.au/exchange/plus/${params?.sportId == 3 ? "greyhound-racing" : "horse-racing"
          }/market/${fetchMarketId}`
          : iconData?.affiliate_link;
      return (
        <Box className="current-best-odds-icon">
          <a href={betFairUrl} target="_blank" rel="noopener noreferrer">
            {oddsType == "currentbest" ? (
              <img
                className="currentbest-bookmaker-thumb"
                src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                alt="Odds Icon"
                onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
              />
            ) : (
              <Box className="odd-img">
                <img
                  src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                  alt="Odds Icon"
                  onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
                />
              </Box>
            )}
          </a>
        </Box>
      );
    } else {
      return (
        <Box className="current-best-odds-icon">
          <a
            href={
              localAuth
                ? filteredData?.length > 0 && filteredData?.[0]?.url
                  ? filteredData?.[0]?.url + `?Referrer=SmartB`
                  : iconData?.affiliate_link
                : "#"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {oddsType == "currentbest" ? (
              <img
                className="currentbest-bookmaker-thumb"
                src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                alt="Odds Icon"
                onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
              />
            ) : (
              <Box className="odd-img">
                <img
                  src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                  alt="Odds Icon"
                  onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
                />
              </Box>
            )}
          </a>
        </Box>
      );
    }
  };
  const handleBookkeeperCounter = async (BookKeeperId, type) => {
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(sportId),
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  // const fetchClickableOdds = (odds, BookKeeperId, type, item) => {
  //   let icon = BookkeeperData?.filter(
  //     (obj) => obj?.BookKeeperId === BookKeeperId
  //   );
  //   let iconData = icon?.[0]?.BookKeeper;
  //   // if (BookKeeperId === 8) {
  //   //   const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8);

  //   //   const Odds = Bet365Data?.[0]?.RaceOdds?.[0];
  //   //   return Bet365Data && Bet365Data?.length > 0 ? (
  //   //     <a
  //   //       href={
  //   //         Odds?.providerMarketId && Odds?.providerParticipantId
  //   //           ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId}~${Odds?.intValue}~1&bet=1`
  //   //           : `${iconData?.affiliate_link}`
  //   //       }
  //   //       target='_blank'
  //   //       rel='noopener noreferrer'
  //   //       className='odds-link'
  //   //     >
  //   //       {' '}
  //   //       {odds === 'SP' ? 'SP' : Number(odds).toFixed(2)}{' '}
  //   //     </a>
  //   //   ) : (
  //   //     <></>
  //   //   );
  //   // } else {
  //   return (
  //     // <a
  //     //   href={iconData?.affiliate_link}
  //     //   target='_blank'
  //     //   rel='noopener noreferrer'
  //     //   className='odds-link'
  //     // >
  //     <span
  //       className="odds-link"
  //       onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
  //     >
  //       {Number(odds).toFixed(2)}
  //     </span>
  //     // </a>
  //   );
  //   // }
  // };
  const fetchClickableOdds = (odds, BookKeeperId, type, item, oddsType) => {
    const BookKeeperData = item?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter((obj) => obj?.id === BookKeeperId);
    let iconData = icon?.[0];
    return oddsType == "currentbest" ? (
      <Box className={`current-best-odds-value`}>
        {localAuth ? (
          <span
            className={"odds-link"}
            onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
          >
            {odds === "SP" ? "SP" : Number(odds).toFixed(2)}
          </span>
        ) : (
          <span></span>
        )}
      </Box>
    ) : (
      <Box
        className={`odds-style`}
        onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
      >
        {odds === "SP" ? "SP" : Number(odds).toFixed(2)}
      </Box>
    );
    // </a>
    // }
  };
  const fetchBestOpenValue = (data) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    // let newOdds = data?.filter((odds) => {
    //     return odds.ApiProviderId > 5;
    // });
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno == 0) {
        let newmaxno = data?.reduce((max, obj) => {
          obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
            ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
            : (max = max);
          return max;
        }, -1);
        if (newmaxno !== -1) {
          let providerid = data
            ?.map((obj) => {
              if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue === maxno) {
                return obj?.BookKeeperId;
              }
            })
            ?.filter((x) => x !== undefined);
          return fetchClickableOdds(maxno, providerid?.[0], "header", data);
        } else {
          return "-";
        }
      } else {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter((x) => x !== undefined);
        return fetchClickableOdds(maxno, providerid?.[0], "header", data);
      }
      // return maxno;
    } else {
      return (
        <Tooltip
          title="No odds available"
          className="odds-tooltip"
          placement="top"
        >
          <span className="no-odds">NOA</span>
        </Tooltip>
      );
    }
  };
  const fetchBestOpenIcon = (data) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    // let newOdds = data?.filter((odds) => {
    //     return odds.ApiProviderId > 5;
    // });
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        return oddsicon(providerid?.[0], "header");
      } else {
        let newmaxno = data?.reduce((max, obj) => {
          obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
            ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
            : (max = max);
          return max;
        }, -1);
        if (newmaxno !== -1) {
          let providerid = data
            ?.map((obj) => {
              if (
                obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue === newmaxno
              ) {
                return obj?.BookKeeperId;
              }
            })
            .filter((x) => x !== undefined);
          return oddsicon(providerid?.[0], "header");
        } else {
          return "";
        }
      }
    } else {
      return "";
    }
  };
  // const fetchCurrentBestValue = (data, oddsType) => {
  //   // let marketData = data?.map((obj) => {
  //   //   return {
  //   //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
  //   //       return item?.RaceOddKeyId == selectedOption
  //   //     })
  //   //   }
  //   // })
  //   // let newOdds = data?.filter((odds) => {
  //   //     return odds.BookKeeperId > 5;
  //   // });
  //   let maxno = data?.reduce((max, obj) => {
  //     obj?.RaceOdds?.[oddsType]?.intValue > max
  //       ? (max = obj?.RaceOdds?.[oddsType]?.intValue)
  //       : (max = max);
  //     return max;
  //   }, -1);
  //   if (maxno !== -1) {
  //     if (maxno !== 0) {
  //       let providerid = data
  //         ?.map((obj) => {
  //           if (obj?.RaceOdds?.[oddsType]?.intValue === maxno) {
  //             return obj?.BookKeeperId;
  //           }
  //         })
  //         .filter((x) => x !== undefined);
  //       return fetchClickableOdds(maxno, providerid?.[0], "header", data);
  //     } else {
  //       return "SP";
  //     }
  //   } else {
  //     return "SP";
  //   }
  // };
  const fetchCurrentBestValue = (data, fulldata) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        return fetchClickableOdds(
          maxno,
          providerid?.[0],
          "header",
          data,
          "currentbest"
        );
      } else {
        return "SP";
      }
    } else {
      return "SP";
    }
  };
  // const fetchCurrentBestIcon = (data, oddsType) => {
  //   // let newOdds = data?.filter((odds) => {
  //   //     return odds.BookKeeperId > 5;
  //   // });
  //   let maxno = data?.reduce((max, obj) => {
  //     obj?.RaceOdds?.[oddsType]?.intValue > max
  //       ? (max = obj?.RaceOdds?.[oddsType]?.intValue)
  //       : (max = max);
  //     return max;
  //   }, -1);
  //   if (maxno !== -1) {
  //     if (maxno !== 0) {
  //       let providerid = data
  //         ?.map((obj) => {
  //           if (obj?.RaceOdds?.[oddsType]?.intValue === maxno) {
  //             return obj?.BookKeeperId;
  //           }
  //         })
  //         .filter((x) => x !== undefined);
  //       return oddsicon(providerid?.[0], "header");
  //     } else {
  //       return "";
  //     }
  //   } else {
  //     return "";
  //   }
  // };
  const fetchCurrentBestIcon = (data) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        return oddsicon(providerid?.[0], "header", data, "currentbest");
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  const fetchFeaturedOdds = (item, BookKeeperId, fulldata) => {
    // let marketData = item?.oddsData?.[0]?.data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let datas = item
      ?.map((obj) => {
        if (obj?.BookKeeperId === BookKeeperId) {
          return {
            odds:
              obj?.RaceOdds?.[0]?.intValue !== 0
                ? obj?.RaceOdds?.[0]?.intValue
                : "SP",
            provider: obj?.BookKeeperId,
          };
        }
      })
      .filter((x) => x !== undefined);
    if (datas?.length > 0) {
      // return fetchClickableOdds(datas?.[0]?.odds, datas?.[0]?.provider, "header", null, null, "betslip")
      return (
        <Box className="odds-box-style">
          {fetchClickableOdds(
            datas?.[0]?.odds,
            BookKeeperId,
            "header",
            item,
            "featuredOdds"
          )}
          {oddsicon(BookKeeperId, "header", item, "featuredOdds")}
          {/* <Box className="odd-img">
            <img
              src={
                item?.long_logo
                  ? Config.mediaURL + item?.long_logo
                  : DefaultImg
              }
              alt="bk"
            />
          </Box> */}
        </Box>
      );
    } else {
      return null;
    }
  };
  const handleResultTabChange = (event, value) => {
    setResultTabValue(value);
  };
  const GetSponsoredOdds = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/sponsor?timeZone=${timezone}&SportId=${sportId}`
      );
      if (status === 200) {
        let providerIDs = [];
        let Ids = data?.result?.map((item) =>
          providerIDs?.push(item?.bookKeepersId)
        );
        setSponsoredId(providerIDs);
      } else {
        setSponsoredId([]);
      }
    } catch (err) {
      setSponsoredId([]);
    }
  };
  const fetchSponsoredOdds = (data) => {
    let newOdds = data
      ?.filter((odds) => {
        return sponsoredId?.includes(odds.BookKeeperId);
      })
      ?.slice(0, 2);
    let firstSponsored = newOdds?.filter(
      (item) => item?.BookKeeperId === sponsoredId?.[0]
    );
    let secondSponsored = newOdds?.filter(
      (item) => item?.BookKeeperId === sponsoredId?.[1]
    );
    let SponsoredOdds =
      sponsoredId?.length > 0 ? (
        <>
          <Box>
            {firstSponsored?.length > 0 ? (
              <>
                <Box>
                  {fetchClickableOdds(
                    firstSponsored?.[0]?.RaceOdds?.[0]?.intValue !== 0
                      ? firstSponsored?.[0]?.RaceOdds?.[0]?.intValue
                      : "SP",
                    firstSponsored?.[0]?.BookKeeperId,
                    "sponsored",
                    data
                  )}
                </Box>
              </>
            ) : (
              <>
                <Box style={{ height: "22.88px" }}></Box>
              </>
            )}
            {oddsicon(sponsoredId?.[0], "sponsored")}
          </Box>
          {sponsoredId?.[1] ? (
            <Box>
              {secondSponsored?.length > 0 ? (
                <>
                  <Box>
                    {fetchClickableOdds(
                      secondSponsored?.[0]?.RaceOdds?.[0]?.intValue !== 0
                        ? secondSponsored?.[0]?.RaceOdds?.[0]?.intValue
                        : "SP",
                      secondSponsored?.[0]?.BookKeeperId,
                      "sponsored",
                      data
                    )}
                  </Box>
                </>
              ) : (
                <>
                  <Box style={{ height: "22.88px" }}></Box>
                </>
              )}
              {oddsicon(sponsoredId?.[1], "sponsored")}
            </Box>
          ) : (
            <></>
          )}
        </>
      ) : (
        <TableCell colSpan={2}>
          {" "}
          <Tooltip
            title="No odds available"
            className="odds-tooltip"
            placement="top"
          >
            <span className="no-odds">NOA</span>
          </Tooltip>
        </TableCell>
      );
    return SponsoredOdds;
  };
  const fetchTopBookmakers = async () => {
    setTopBookmakerLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `apiProviders/bookKeepers`
      );
      if (status === 200) {
        setTopBookmakerLoading(false);

        const bookMakersData = data?.result;
        const isFeaturedData = bookMakersData?.filter(
          (item) => item?.isFeatured === true
        );
        const recommendedBookmaker = bookMakersData?.filter(
          (item) => item?.isFeatured === false
        );
        const sortedData = isFeaturedData?.sort(
          (a, b) => a.featured_order - b.featured_order
        );
        const recommendedSortedData = recommendedBookmaker?.sort(
          (a, b) => a.featured_order - b.featured_order
        );
        setTopBookMakersData(sortedData);
      }
    } catch (error) {
      setTopBookmakerLoading(false);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
    // if (raceId) {
    //   fetchOddsData(raceId);
    //   fetchExoticsData();
    // }
    // fetchBookKeeper();
    GetSponsoredOdds();
    fetchTopBookmakers();
  }, []);

  useEffect(() => {
    if (raceId) {
      fetchOddsData(raceId);
      fetchExoticsData();
    }
    setSelectedDividend("1");
  }, [raceId, runnerInfo]);
  useEffect(() => {
    if (raceId) {
      fetchOddsData(raceId);
    }
  }, [selectedOption]);

  const fetchOddsData = async (raceId, isFirstTime) => {
    setOddsLoader(true);
    if (RaceResultData && runnerInfo) {
      let parsedResult = RaceResultData?.summary
        ? JSON.parse(RaceResultData?.summary)
        : [];

      let standingData = parsedResult?.map((item) => {
        return {
          ...item,
          RunnerDetails: runnerInfo?.filter((runner) => {
            return item?.runner_number
              ? runner?.runnerNumber === Number(item?.runner_number)
              : runner?.runnerNumber === Number(item?.RunnerNumber);
          }),
        };
      });
      try {
        const { status, data } = await axiosInstance.get(
          `events/getOddsByrace/${raceId}?marketId=${oddsType?.marketId}&oddKey=${oddsType?.oddKey}`
        );
        if (status === 200) {
          let newData = standingData?.map((obj) => {
            return {
              ...obj,
              oddsData: data?.marketRelation
                ?.map((item) => {
                  return item?.RacingParticipantId ==
                    obj?.RunnerDetails?.[0]?.id
                    ? item
                    : [];
                })
                ?.filter((x) => {
                  return x?.data?.length > 0;
                }),
            };
          });
          setSummary(newData);

          setTimeout(() => {
            setOddsLoader(false);
          }, 1000);
        }
      } catch (err) { }
    }
  };
  const fetchExoticsData = () => {
    if (RaceResultData) {
      let parsedExotics = RaceResultData?.dividends
        ? RaceResultData?.dividends === '""'
          ? []
          : JSON.parse(RaceResultData?.dividends)
        : [];
      let Exacta = parsedExotics?.filter((item) =>
        item?.product_name
          ? item?.product_name === "Exacta"
          : item?.ProductName === "Exacta"
      );
      let Trifecta = parsedExotics?.filter((item) =>
        item?.product_name
          ? item?.product_name === "Trifecta"
          : item?.ProductName === "Trifecta"
      );

      let ToteWin = parsedExotics?.filter((item) =>
        item?.product_name
          ? item?.product_name === "Tote Win"
          : item?.ProductName === "Tote Win"
      );
      let TotePlace = parsedExotics?.filter((item) =>
        item?.product_name
          ? item?.product_name === "Tote Place"
          : item?.ProductName === "Tote Place"
      );
      // let FirstFour = parsedExotics?.filter((item) => item?.ProductName === "First Four")
      // let Quinella = parsedExotics?.filter((item) => item?.ProductName === "Quinella")
      // let RunningDouble = parsedExotics?.filter((item) => item?.ProductName === "Running Double")
      let restArray = parsedExotics?.filter((item) =>
        item?.product_name
          ? item?.product_name !== "Exacta" &&
          item?.product_name !== "Trifecta" &&
          item?.product_name !== "Tote Win" &&
          item?.product_name !== "Tote Place"
          : item?.ProductName !== "Exacta" &&
          item?.ProductName !== "Trifecta" &&
          item?.ProductName !== "Tote Win" &&
          item?.ProductName !== "Tote Place"
      );
      const sortedData = restArray?.sort((a, b) => {
        return a?.product_name
          ? a?.product_name.localeCompare(b?.product_name)
          : a?.ProductName.localeCompare(b?.ProductName);
      });
      let Exotics = [...Exacta, ...Trifecta, ...sortedData]?.filter(
        (item) => item?.dividend != 0
      );
      setExoticsData(Exotics);
      // setMultipleData(sortedData);

      setToteWinData(ToteWin);
      setTotePlaceData(TotePlace);
      fetchDividendData("1", ToteWin, TotePlace);
    }
  };

  const fetchDividendData = (SelectedDividend, ToteWin, TotePlace) => {
    if (RaceResultData && runnerInfo?.length > 0) {
      let parsedResult = RaceResultData?.summary
        ? JSON.parse(RaceResultData?.summary)
        : [];
      let dividend = SelectedDividend === "1" ? ToteWin : TotePlace;
      let standingData = parsedResult?.map((item) => {
        return {
          ...item,
          RunnerDetails: runnerInfo?.filter((runner) => {
            return item?.runner_number
              ? runner?.runnerNumber === Number(item?.runner_number)
              : runner?.runnerNumber === Number(item?.RunnerNumber);
          }),
        };
      });

      let newData = standingData?.map((obj) => {
        let runnerDividend = dividend?.filter((runner) =>
          runner?.positions?.[0]?.runner_number
            ? Number(runner?.positions?.[0]?.runner_number) ===
            Number(obj?.runner_number)
            : Number(runner?.Positions?.[0]?.RunnerNumber) ===
            Number(obj?.RunnerNumber)
        );

        return {
          ...obj,
          DividendData: runnerDividend ? runnerDividend : [],
        };
      });
      setDividendData(newData);
    }
  };
  const fetchDividendOddsValue = (item, tote) => {
    if (item?.DividendData?.length > 0) {
      let oddsValue = item?.DividendData?.filter((obj) => {
        if (obj?.tote ? obj?.tote === tote : obj?.Tote === tote) {
          return obj?.dividend ? obj?.dividend : obj?.Dividend;
        }
      });
      if (
        oddsValue?.[0]?.dividend
          ? oddsValue?.[0]?.dividend
          : oddsValue?.[0]?.Dividend
      ) {
        return (
          <>
            <Box className="odds-box-style">
              {fetchClickableOdds(
                oddsValue?.[0]?.dividend
                  ? oddsValue?.[0]?.dividend
                  : oddsValue?.[0]?.Dividend,
                3,
                "header",
                null,
                "featuredOdds"
              )}

              {oddsicon(3, "header", null, "featuredOdds")}
            </Box>
          </>
        );
      } else {
        return (
          // <Tooltip
          //   title="No odds available"
          //   className="odds-tooltip"
          //   placement="top"
          // >
          //   <span className="no-odds">NOA</span>
          // </Tooltip>
          (null)
        );
      }
    } else {
      return (
        // <Tooltip
        //   title="No odds available"
        //   className="odds-tooltip"
        //   placement="top"
        // >
        //   <span className="no-odds">NOA</span>
        // </Tooltip>
        (null)
      );
    }
  };

  const checkRaceOdds = (data) => {
    for (let entry of data?.data) {
      for (let raceOdds of entry?.RaceOdds) {
        if (raceOdds?.RaceOddFlucs?.[0]?.intValue === 0) {
          continue;
        }
        return entry;
      }
    }
    return null;
  };

  const fetchSPOddsValue = (item) => {
    const result = checkRaceOdds(item?.oddsData?.[0]);
    return (
      <>
        <Box className="odds-box-style">
          {fetchClickableOdds(
            result?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue,
            result?.BookKeeperId,
            "header",
            null,
            "featuredOdds"
          )}

          {oddsicon(result?.BookKeeperId, "header", null, "featuredOdds")}
        </Box>
      </>
    );
  };

  const getMaxIntValue = (data) => {
    if (data?.length === 0) return null;

    const raceOdds = data?.[0]?.RaceOdds;
    if (raceOdds?.length === 0) return null;

    const raceOddFlucs = raceOdds?.[0]?.RaceOddFlucs;
    if (raceOddFlucs?.length === 0) return null;

    const maxIntValue = raceOddFlucs.reduce((max, fluc) => {
      return fluc?.intValue > max ? fluc?.intValue : max;
    }, raceOddFlucs?.[0]?.intValue);

    return maxIntValue;
  };

  const fecthTOPFLUCOddsValue = (data) => {
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj;
            }
          })
          .filter((x) => x !== undefined);
        return (
          <Box className="odds-box-style">
            {fetchClickableOdds(
              getMaxIntValue(providerid),
              providerid?.[0]?.BookKeeperId,
              "header",
              null,
              "featuredOdds"
            )}

            {oddsicon(
              providerid?.[0]?.BookKeeperId,
              "header",
              null,
              "featuredOdds"
            )}
          </Box>
        );
      } else {
        return "SP";
      }
    } else {
      return "-";
    }
  };

  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let Exotics_Arr =
    resultTabValue === 1
      ? ExoticsData
      : // :
      //  resultTabValue === 3
      //   ? MultipleData
      [];

  const mergeData = (data1, data2) => {
    return data2?.map((entry2) => {
      const matchingEntry = data1?.find(
        (entry1) => entry1?.RunnerDetails?.[0]?.id === entry2?.RunnerDetails?.[0]?.id
      );
      if (matchingEntry) {
        return {
          ...entry2,
          oddsData: matchingEntry?.oddsData,
        };
      }
      return entry2;
    });
  };
  const finalDividendData = mergeData(Summary, DividendData);
  let Divedend_Arr =
    resultTabValue === 0
      ? Summary
      : resultTabValue === 2
        ? finalDividendData
        : [];

  const handleResultInfoTabChange = (id, value) => {
    setStateTabValue(id);
  };
  return (
    <Box className="race-result-wrap">
      {/* <Box className="result-tab">
        <Tabs
          value={resultTabValue}
          variant="scrollable"
          scrollButtons="off"
          indicatorColor="primary"
          textColor="primary"
          className="result-tab-detail"
          disableRipple
          disableFocusRipple
        >
          {resultTabData?.map((item, index) => {
            return (
              <Box key={index}>
                <Tab
                  disableRipple
                  disableFocusRipple
                  label={item?.name}
                  value={item?.id}
                  className={item?.id === resultTabValue ? "active " : ""}
                  onChange={(event, newValue) =>
                    handleResultTabChange(event, item?.id)
                  }
                />
              </Box>
            );
          })}
        </Tabs>
      </Box> */}
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="race-card-header"
        >
          <Typography variant="h4">Final race results</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {resultTabValue === 0 || resultTabValue === 2 ? (
            screenWidth > 799 ? (
              <>
                <Box className="final-race-result">
                  <Box className="final-result-time">
                    <span>
                      {resultTabValue === 0 ? (
                        <Box className="select-wrap series-select">
                          <Select
                            className="React"
                            value={options?.find((item) => {
                              return item?.value == selectedOption;
                            })}
                            onChange={(e) => oddsTypeChange(e)}
                            options={options}
                            isOptionDisabled={(option) => option.disabled}
                            classNamePrefix="select"
                            isSearchable={false}
                          />
                        </Box>
                      ) : (
                        <Box className="select-wrap series-select">
                          <Select
                            className="React"
                            value={options?.find((item) => {
                              return item?.value == SelectedDividend;
                            })}
                            onChange={(e) => {
                              setSelectedDividend(e?.value);
                              fetchDividendData(
                                e?.value,
                                ToteWinData,
                                TotePlaceData
                              );
                            }}
                            options={options}
                            isOptionDisabled={(option) => option.disabled}
                            classNamePrefix="select"
                            isSearchable={false}
                          />
                        </Box>
                      )}
                    </span>
                    <Box>
                      <Tabs
                        value={resultTabValue}
                        variant="scrollable"
                        scrollButtons="auto"
                        indicatorColor="primary"
                        textColor="primary"
                        className="state-tab-details"
                        // disableRipple
                        // disableFocusRipple
                        id="state-tab"
                      >
                        {resultTabData.map((item, index) => {
                          return (
                            <Box key={index}>
                              <Tab
                                label={item?.name}
                                value={item?.id}
                                onChange={(event, newValue) =>
                                  handleResultTabChange(event, item?.id)
                                }
                                className={
                                  item?.id === resultTabValue ? "active " : ""
                                }
                              />
                            </Box>
                          );
                        })}
                      </Tabs>
                    </Box>
                  </Box>
                  <TableContainer className="race-bookmakersodd">
                    {/* {(isrunnnerLoading || oddsLoader) ? (
                    <div className="allsport-loader-center">
                        <Loader />
                    </div>
                ) : ( */}
                    {oddsLoader ? (
                      <div className="allsport-loader-center">
                        <Loader />
                      </div>
                    ) : (
                      <>
                        <Table
                          className="racing-data featured-table"
                          aria-label="customized table"
                          style={{ minWidth: 700, borderRadius: 5 }}
                        >
                          <TableHead className="racing-track-table-head">
                            <TableRow
                              style={{ fontSize: 12 }}
                              className="head-row"
                            >
                              <TableCell
                                className="track-table-th  table-left-header result-track-table-th"
                                style={{ textAlign: "left" }}
                              >
                                {RaceResultData
                                  ? moment(RaceResultData?.updatedAt)
                                    .tz(timezone)
                                    .format("dddd DD/MM/YYYY, hh:mmA")
                                  : ""}
                              </TableCell>
                              {resultTabValue === 0 ? (
                                <>
                                  <TableCell
                                    className="track-tabel-odds-th table-head current-best"
                                    style={{
                                      width: "134px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {localesData?.RUNNER_TABLE?.BEST_AT_CLOSE}
                                  </TableCell>
                                  <TableCell
                                    className="track-tabel-odds-th table-head sponsor-odds-header"
                                    style={{
                                      minWidth: "554px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Featured bookmaker Odds
                                  </TableCell>
                                </>
                              ) : (
                                <>
                                  <TableCell
                                    className="track-tabel-odds-th table-head sponsor-odds-header border-right-result"
                                    style={{
                                      width: "180px",
                                      textAlign: "center",
                                    }}
                                  >
                                    SP
                                  </TableCell>
                                  <TableCell
                                    className="track-tabel-odds-th table-head sponsor-odds-header border-right-result"
                                    style={{
                                      width: "180px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Top Fluc
                                  </TableCell>
                                  <TableCell
                                    className="track-tabel-odds-th table-head sponsor-odds-header border-right-result"
                                    style={{
                                      width: "180px",
                                      textAlign: "center",
                                    }}
                                  >
                                    VIC
                                  </TableCell>
                                  <TableCell
                                    className="track-tabel-odds-th table-head sponsor-odds-header border-right-result"
                                    style={{
                                      width: "180px",
                                      textAlign: "center",
                                    }}
                                  >
                                    NSW
                                  </TableCell>
                                  <TableCell
                                    className="track-tabel-odds-th table-head sponsor-odds-header"
                                    style={{
                                      width: "180px",
                                      textAlign: "center",
                                    }}
                                  >
                                    QLD
                                  </TableCell>
                                </>
                              )}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {Divedend_Arr?.length > 0 ? (
                              <>
                                {Divedend_Arr?.slice(0, 4)?.map(
                                  (obj, index) => {
                                    let item = obj?.RunnerDetails?.[0];
                                    return (
                                      <>
                                        <TableRow className="racing-track-data-row dividend-row">
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            className="result-racer-detail border-right"
                                          >
                                            {obj?.RunnerDetails?.length > 0 ? (
                                              <Box className="racer-detail-wrap">
                                                <Typography variant="h6">
                                                  {item?.runnerNumber}
                                                  {"."} {item?.animal?.name} (
                                                  {item?.barrierNumber})
                                                  <span
                                                    className={`rank-badge ${fetchRank(
                                                      obj?.position
                                                        ? Number(obj?.position)
                                                        : Number(obj?.Position)
                                                    )}`}
                                                  >
                                                    {" "}
                                                    {obj?.position
                                                      ? Number(
                                                        obj?.position
                                                      ) === 1
                                                        ? "1st"
                                                        : Number(
                                                          obj?.position
                                                        ) === 2
                                                          ? "2nd"
                                                          : Number(
                                                            obj?.position
                                                          ) === 3
                                                            ? "3rd"
                                                            : `${Number(
                                                              obj?.position
                                                            )}th`
                                                      : Number(
                                                        obj?.Position
                                                      ) === 1
                                                        ? "1st"
                                                        : Number(
                                                          obj?.Position
                                                        ) === 2
                                                          ? "2nd"
                                                          : Number(
                                                            obj?.Position
                                                          ) === 3
                                                            ? "3rd"
                                                            : `${Number(
                                                              obj?.Position
                                                            )}th`}
                                                  </span>
                                                </Typography>
                                                <Box className="player-wrap">
                                                  <Box className="player-detail">
                                                    {item?.Jockey ? (
                                                      <>
                                                        <span className="text-semibold">
                                                          {sportId === "2"
                                                            ? `D: `
                                                            : `J: `}
                                                        </span>
                                                        <span>
                                                          {item?.Jockey?.name}
                                                        </span>
                                                      </>
                                                    ) : item?.Trainer ? (
                                                      <>
                                                        <span className="text-semibold">
                                                          T:{" "}
                                                        </span>
                                                        <span>
                                                          {item?.Trainer?.name}
                                                        </span>
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </Box>
                                                  {sportId === "1" ? (
                                                    <Box className="player-detail">
                                                      <span className="text-semibold">
                                                        W:{" "}
                                                      </span>
                                                      <span>
                                                        {Number(
                                                          item?.JockeyWeight
                                                        ).toFixed(2) + "Kg"}
                                                      </span>
                                                    </Box>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Box>
                                                <Box className="player-wrap">
                                                  {/* <Box className="player-detail">
                                    <span>Form:</span>
                                    <span> {}</span>
                                  </Box> */}
                                                  {sportId === "1" ||
                                                    sportId === "2" ? (
                                                    <Box className="player-detail">
                                                      {item?.Trainer ? (
                                                        <>
                                                          <span className="text-semibold">
                                                            T:{" "}
                                                          </span>
                                                          <span>
                                                            {
                                                              item?.Trainer
                                                                ?.name
                                                            }
                                                          </span>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </Box>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Box>
                                              </Box>
                                            ) : (
                                              <>-</>
                                            )}
                                          </TableCell>
                                          {resultTabValue === 0 ? (
                                            <>
                                              <TableCell className="border-right current-best-odds-column">
                                                {obj?.oddsData?.[0]?.data ? (
                                                  <>
                                                    <Box
                                                      className={`current-best-odds-wrap  ${localAuth
                                                          ? ""
                                                          : "blur-comp"
                                                        }`}
                                                    >
                                                      {fetchCurrentBestValue(
                                                        obj?.oddsData?.[0]?.data
                                                      )}
                                                      {/* </Box> */}
                                                      {/* <Box className="current-best-odds-icon"> */}
                                                      {fetchCurrentBestIcon(
                                                        obj?.oddsData?.[0]?.data
                                                      )}
                                                      {/* </Box> */}
                                                    </Box>
                                                  </>
                                                ) : (
                                                  <Tooltip
                                                    title="No odds available"
                                                    className="odds-tooltip"
                                                    placement="top"
                                                  >
                                                    <span className="no-odds">
                                                      NOA
                                                    </span>
                                                  </Tooltip>
                                                )}
                                              </TableCell>
                                              <TableCell>
                                                {!topBookmakerLoading &&
                                                  topBookMakersData?.length ===
                                                  0 && (
                                                    <p
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      No Data Available
                                                    </p>
                                                  )}
                                                {!topBookmakerLoading &&
                                                  topBookMakersData?.length >
                                                  0 && (
                                                    <Box className="hide-scroll max-w">
                                                      <Box className="odds-flex">
                                                        {topBookMakersData
                                                          ?.slice(0, 6)
                                                          ?.map(
                                                            (
                                                              bookkeper,
                                                              index
                                                            ) => {
                                                              return fetchFeaturedOdds(
                                                                obj
                                                                  ?.oddsData?.[0]
                                                                  ?.data,
                                                                bookkeper?.id,
                                                                obj
                                                              );
                                                            }
                                                          )}
                                                      </Box>
                                                    </Box>
                                                  )}
                                              </TableCell>
                                            </>
                                          ) : (
                                            <>
                                              <TableCell className="border-right">
                                                {fetchSPOddsValue(obj)}
                                              </TableCell>
                                              <TableCell className="border-right">
                                                {fecthTOPFLUCOddsValue(
                                                  obj?.oddsData?.[0]?.data
                                                )}
                                              </TableCell>
                                              <TableCell className="border-right">
                                                {fetchDividendOddsValue(
                                                  obj,
                                                  "VIC"
                                                )}
                                              </TableCell>
                                              <TableCell className="border-right">
                                                {fetchDividendOddsValue(
                                                  obj,
                                                  "NSW"
                                                )}
                                              </TableCell>
                                              <TableCell className="border-right">
                                                {fetchDividendOddsValue(
                                                  obj,
                                                  "QLD"
                                                )}
                                              </TableCell>
                                            </>
                                          )}
                                        </TableRow>
                                      </>
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <TableRow style={{ border: "transparent" }}>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                    border: "transparent",
                                  }}
                                  colSpan={21}
                                >
                                  {/* {localesData?.sport?.NO_DATA} */}
                                  <NoDataComp />
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </>
                    )}
                  </TableContainer>
                </Box>
              </>
            ) : (
              <>
                <Box className="final-race-result mobile-race-result featured-table">
                  {oddsLoader ? (
                    <div className="allsport-loader-center">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <Box className="final-result-time">
                        <span>
                          {resultTabValue === 0 ? (
                            <Box className="select-wrap series-select">
                              <Select
                                className="React"
                                value={options?.find((item) => {
                                  return item?.value == selectedOption;
                                })}
                                onChange={(e) => oddsTypeChange(e)}
                                options={options}
                                isOptionDisabled={(option) => option.disabled}
                                classNamePrefix="select"
                                isSearchable={false}
                              />
                            </Box>
                          ) : (
                            <Box className="select-wrap series-select">
                              <Select
                                className="React"
                                value={options?.find((item) => {
                                  return item?.value == SelectedDividend;
                                })}
                                onChange={(e) => {
                                  setSelectedDividend(e?.value);
                                  fetchDividendData(
                                    e?.value,
                                    ToteWinData,
                                    TotePlaceData
                                  );
                                }}
                                options={options}
                                isOptionDisabled={(option) => option.disabled}
                                classNamePrefix="select"
                                isSearchable={false}
                              />
                            </Box>
                          )}
                        </span>
                        <Box>
                          <Tabs
                            value={resultTabValue}
                            variant="scrollable"
                            scrollButtons="auto"
                            indicatorColor="primary"
                            textColor="primary"
                            className="state-tab-details"
                            // disableRipple
                            // disableFocusRipple
                            id="state-tab"
                          >
                            {resultTabData.map((item, index) => {
                              return (
                                <Box key={index}>
                                  <Tab
                                    label={item?.name}
                                    value={item?.id}
                                    onChange={(event, newValue) =>
                                      handleResultTabChange(event, item?.id)
                                    }
                                    className={
                                      item?.id === resultTabValue
                                        ? "active "
                                        : ""
                                    }
                                  />
                                </Box>
                              );
                            })}
                          </Tabs>
                        </Box>
                      </Box>
                      <Typography className="race-result-time">
                        {RaceResultData
                          ? moment(RaceResultData?.updatedAt)
                            .tz(timezone)
                            .format("dddd DD/MM/YYYY, hh:mmA")
                          : ""}
                      </Typography>

                      <Box className="race-bookmakersodd">
                        {/* {(isrunnnerLoading || oddsLoader) ? (
                                        <div className="allsport-loader-center">
                                            <Loader />
                                        </div>
                                    ) : ( */}
                        <>
                          <Box className="racing-data exotic-racing-data">
                            {Divedend_Arr?.length > 0 ? (
                              <>
                                {Divedend_Arr?.slice(0, 4)?.map(
                                  (obj, index) => {
                                    let item = obj?.RunnerDetails?.[0];
                                    return (
                                      <Box key={index} className="exotics-row">
                                        <Box className="racer-detail-wrap">
                                          {obj?.RunnerDetails?.length > 0 ? (
                                            <>
                                              <Box>
                                                <Typography
                                                  variant="h6"
                                                  className="mobile-racer-info"
                                                >
                                                  {item?.runnerNumber}
                                                  {"."} {item?.animal?.name} (
                                                  {item?.barrierNumber})
                                                  <span
                                                    className={`rank-badge ${fetchRank(
                                                      obj?.position
                                                        ? Number(obj?.position)
                                                        : Number(obj?.Position)
                                                    )}`}
                                                  >
                                                    {" "}
                                                    {obj?.position
                                                      ? Number(
                                                        obj?.position
                                                      ) === 1
                                                        ? "1st"
                                                        : Number(
                                                          obj?.position
                                                        ) === 2
                                                          ? "2nd"
                                                          : Number(
                                                            obj?.position
                                                          ) === 3
                                                            ? "3rd"
                                                            : `${Number(
                                                              obj?.position
                                                            )}th`
                                                      : Number(
                                                        obj?.Position
                                                      ) === 1
                                                        ? "1st"
                                                        : Number(
                                                          obj?.Position
                                                        ) === 2
                                                          ? "2nd"
                                                          : Number(
                                                            obj?.Position
                                                          ) === 3
                                                            ? "3rd"
                                                            : `${Number(
                                                              obj?.Position
                                                            )}th`}
                                                  </span>
                                                </Typography>
                                                <Box className="player-detail-container">
                                                  <Box className="player-wrap">
                                                    <Box className="player-detail">
                                                      {item?.Jockey ? (
                                                        <>
                                                          <span className="text-semibold">
                                                            {sportId === "2"
                                                              ? `D: `
                                                              : `J: `}
                                                          </span>
                                                          <span>
                                                            {item?.Jockey?.name}
                                                          </span>
                                                        </>
                                                      ) : item?.Trainer ? (
                                                        <>
                                                          <span className="text-semibold">
                                                            T:{" "}
                                                          </span>
                                                          <span>
                                                            {
                                                              item?.Trainer
                                                                ?.name
                                                            }
                                                          </span>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </Box>
                                                    {sportId === "1" ? (
                                                      <Box className="player-detail">
                                                        <span className="text-semibold">
                                                          W:{" "}
                                                        </span>
                                                        <span>
                                                          {Number(
                                                            item?.JockeyWeight
                                                          ).toFixed(2) + "Kg"}
                                                        </span>
                                                      </Box>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </Box>
                                                  <Box className="player-wrap">
                                                    {/* <Box className="player-detail">
                                    <span>Form:</span>
                                    <span> {}</span>
                                  </Box> */}
                                                    {sportId === "1" ||
                                                      sportId === "2" ? (
                                                      <Box className="player-detail">
                                                        {item?.Trainer ? (
                                                          <>
                                                            <span className="text-semibold">
                                                              T:{" "}
                                                            </span>
                                                            <span>
                                                              {
                                                                item?.Trainer
                                                                  ?.name
                                                              }
                                                            </span>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </Box>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {resultTabValue === 0 && (
                                            <Box
                                              className={`odds-value-wrap align-right  current-best-odds-wrap ${localAuth ? "" : "blur-comp"
                                                }`}
                                            >
                                              {/* <Box className="current-best-odds-value"> */}{" "}
                                              {fetchCurrentBestValue(
                                                obj?.oddsData?.[0]?.data
                                              )}
                                              {/* </Box> */}
                                              {/* <Box className="current-best-odds-icon"> */}
                                              {fetchCurrentBestIcon(
                                                obj?.oddsData?.[0]?.data
                                              )}
                                              {/* </Box> */}
                                            </Box>
                                          )}
                                        </Box>

                                        {/* <TableContainer className="race-result-table-container">
                                    <Table>
                                      <TableHead className="mobile-data-head">
                                        {resultTabValue === 0 ? (
                                          <>
                                            <TableCell
                                              className="mobile-table-data "
                                              width="33.33%"
                                            >
                                              {
                                                localesData?.RUNNER_TABLE
                                                  ?.BEST_AT_OPEN
                                              }
                                            </TableCell>
                                            <TableCell
                                              className="mobile-table-data current-best"
                                              width="33.33%"
                                            >
                                              {
                                                localesData?.RUNNER_TABLE
                                                  ?.BEST_AT_CLOSE
                                              }
                                            </TableCell>
                                            {sponsoredId?.length > 0 ? (
                                              <TableCell
                                                className="mobile-table-data sponsored-header"
                                                width="33.33%"
                                              >
                                                <span className="sponsored">
                                                  {
                                                    localesData?.RUNNER_TABLE
                                                      ?.SPONSORED
                                                  }
                                                </span>
                                              </TableCell>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <TableCell
                                              className="mobile-table-data "
                                              width="33.33%"
                                            >
                                              VIC
                                            </TableCell>
                                            <TableCell
                                              className="mobile-table-data "
                                              width="33.33%"
                                            >
                                              NSW
                                            </TableCell>
                                            <TableCell
                                              className="mobile-table-data "
                                              width="33.33%"
                                            >
                                              QLD
                                            </TableCell>
                                          </>
                                        )}
                                      </TableHead>
                                      <TableBody className="mobile-table-body">
                                        {resultTabValue === 0 ? (
                                          <>
                                            <TableCell className="mobile-table-data odds-column">
                                              {obj?.oddsData?.[0]?.data ? (
                                                <>
                                                  <Box>
                                                    {fetchBestOpenValue(
                                                      obj?.oddsData?.[0]?.data
                                                    )}
                                                  </Box>
                                                  {fetchBestOpenIcon(
                                                    obj?.oddsData?.[0]?.data
                                                  )}
                                                </>
                                              ) : (
                                                <Tooltip
                                                  title="No odds available"
                                                  className="odds-tooltip"
                                                  placement="top"
                                                >
                                                  <span className="no-odds">
                                                    NOA
                                                  </span>
                                                </Tooltip>
                                              )}
                                            </TableCell>
                                            <TableCell className="mobile-table-data current-best-odds-column">
                                              {obj?.oddsData?.[0]?.data ? (
                                                <>
                                                  <Box>
                                                    {fetchCurrentBestValue(
                                                      obj?.oddsData?.[0]?.data,
                                                      0
                                                    )}
                                                  </Box>
                                                  {fetchCurrentBestIcon(
                                                    obj?.oddsData?.[0]?.data,
                                                    0
                                                  )}
                                                </>
                                              ) : (
                                                <Tooltip
                                                  title="No odds available"
                                                  className="odds-tooltip"
                                                  placement="top"
                                                >
                                                  <span className="no-odds">
                                                    NOA
                                                  </span>
                                                </Tooltip>
                                              )}
                                            </TableCell>
                                            {sponsoredId?.length > 0 ? (
                                              <>
                                                <TableCell className="next5odds-icon odds-column">
                                                  <Box className="sponsored-odds">
                                                    {fetchSponsoredOdds(
                                                      obj?.oddsData?.[0]?.data
                                                    )}
                                                  </Box>
                                                </TableCell>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <TableCell className="mobile-table-data">
                                              {fetchDividendOddsValue(
                                                obj,
                                                "VIC"
                                              )}
                                            </TableCell>
                                            <TableCell className="mobile-table-data">
                                              {fetchDividendOddsValue(
                                                obj,
                                                "NSW"
                                              )}
                                            </TableCell>
                                            <TableCell className="mobile-table-data">
                                              {fetchDividendOddsValue(
                                                obj,
                                                "QLD"
                                              )}
                                            </TableCell>
                                          </>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer> */}
                                        {resultTabValue === 0 ? (
                                          <Box className="mobile-table-container">
                                            <TableContainer>
                                              <Table>
                                                <TableHead className="mobile-data-head">
                                                  <TableCell
                                                    className="mobile-table-data sponsor-odds-header"
                                                    width="33.33%"
                                                  >
                                                    {" "}
                                                    Featured bookmaker Odds
                                                  </TableCell>
                                                </TableHead>
                                                <TableBody className="mobile-table-body">
                                                  <TableCell className="mobile-table-data current-best-odds-column gray-bg">
                                                    {!topBookmakerLoading &&
                                                      topBookMakersData?.length ===
                                                      0 && (
                                                        <p
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          No Data Available
                                                        </p>
                                                      )}
                                                    {!topBookmakerLoading &&
                                                      topBookMakersData?.length >
                                                      0 && (
                                                        <Box className="hide-scroll max-w">
                                                          <Box className="odds-flex">
                                                            {topBookMakersData
                                                              ?.slice(0, 6)
                                                              ?.map(
                                                                (
                                                                  bookkeper,
                                                                  index
                                                                ) => {
                                                                  return fetchFeaturedOdds(
                                                                    obj
                                                                      ?.oddsData?.[0]
                                                                      ?.data,
                                                                    bookkeper?.id,
                                                                    obj
                                                                  );
                                                                }
                                                              )}
                                                          </Box>
                                                        </Box>
                                                      )}
                                                  </TableCell>
                                                  {sponsoredId?.length > 0 ? (
                                                    <>
                                                      <TableCell className="next5odds-icon odds-column">
                                                        <Box className="sponsored-odds">
                                                          {fetchSponsoredOdds(
                                                            item?.oddsData?.[0]
                                                              ?.data,
                                                            item
                                                          )}
                                                        </Box>
                                                      </TableCell>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {/* {isResult ? (
                                          <>
                                            {fetchTop5Odds(item?.oddsData?.[0]?.data, isResult)}
                                          </>
                                        ) : (<></>)
                                        } */}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Box>
                                        ) : (
                                          <TableContainer className="exotic-table-container">
                                            <Table
                                              className={
                                                fetchDividendOddsValue(
                                                  obj,
                                                  "VIC"
                                                ) &&
                                                fetchDividendOddsValue(
                                                  obj,
                                                  "NSW"
                                                ) &&
                                                fetchDividendOddsValue(
                                                  obj,
                                                  "QLD"
                                                ) &&
                                                "mobile-min-width"
                                              }
                                            >
                                              <TableHead className="mobile-data-head">
                                                <TableCell
                                                  className="mobile-table-data"
                                                // width="auto"
                                                >
                                                  SP
                                                </TableCell>
                                                <TableCell
                                                  className="mobile-table-data"
                                                // width="auto"
                                                >
                                                  Top Fluc
                                                </TableCell>
                                                {fetchDividendOddsValue(
                                                  obj,
                                                  "VIC"
                                                ) &&
                                                  fetchDividendOddsValue(
                                                    obj,
                                                    "NSW"
                                                  ) &&
                                                  fetchDividendOddsValue(
                                                    obj,
                                                    "QLD"
                                                  ) && (
                                                    <>
                                                      <TableCell
                                                        className="mobile-table-data"
                                                      // width="auto"
                                                      >
                                                        VIC
                                                      </TableCell>
                                                      <TableCell
                                                        className="mobile-table-data"
                                                      // width="auto"
                                                      >
                                                        NSW
                                                      </TableCell>
                                                      <TableCell
                                                        className="mobile-table-data"
                                                      // width="auto"
                                                      >
                                                        QLD
                                                      </TableCell>
                                                    </>
                                                  )}
                                              </TableHead>
                                              <TableBody className="mobile-table-body">
                                                <TableCell className="mobile-table-data border-right-result">
                                                  <Box>
                                                    {fetchSPOddsValue(obj)}
                                                  </Box>
                                                </TableCell>
                                                <TableCell className="mobile-table-data border-right-result">
                                                  <Box>
                                                    {fecthTOPFLUCOddsValue(
                                                      obj?.oddsData?.[0]?.data
                                                    )}
                                                  </Box>
                                                </TableCell>
                                                {fetchDividendOddsValue(
                                                  obj,
                                                  "VIC"
                                                ) &&
                                                  fetchDividendOddsValue(
                                                    obj,
                                                    "NSW"
                                                  ) &&
                                                  fetchDividendOddsValue(
                                                    obj,
                                                    "QLD"
                                                  ) && (
                                                    <>
                                                      <TableCell className="mobile-table-data border-right-result">
                                                        <Box>
                                                          {fetchDividendOddsValue(
                                                            obj,
                                                            "VIC"
                                                          )}
                                                        </Box>
                                                      </TableCell>
                                                      <TableCell className="mobile-table-data border-right-result">
                                                        <Box>
                                                          {fetchDividendOddsValue(
                                                            obj,
                                                            "NSW"
                                                          )}
                                                        </Box>
                                                      </TableCell>
                                                      <TableCell className="mobile-table-data">
                                                        <Box>
                                                          {fetchDividendOddsValue(
                                                            obj,
                                                            "QLD"
                                                          )}
                                                        </Box>
                                                      </TableCell>
                                                    </>
                                                  )}
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        )}
                                      </Box>
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <Box style={{ border: "transparent" }}>
                                <Box
                                  style={{
                                    textAlign: "center",
                                    border: "transparent",
                                  }}
                                  colSpan={21}
                                >
                                  {/* {localesData?.sport?.NO_DATA} */}
                                  <NoDataComp />
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </>
                        {/* )} */}
                      </Box>
                    </>
                  )}
                </Box>
              </>
            )
          ) : screenWidth > 799 ? (
            <>
              <Box className="final-race-result exotics-race-result">
                <Box className="final-result-time">
                  <span></span>
                  <Box>
                    <Tabs
                      value={resultTabValue}
                      variant="scrollable"
                      scrollButtons="auto"
                      indicatorColor="primary"
                      textColor="primary"
                      className="state-tab-details"
                      // disableRipple
                      // disableFocusRipple
                      id="state-tab"
                    >
                      {resultTabData.map((item, index) => {
                        return (
                          <Box key={index}>
                            <Tab
                              label={item?.name}
                              value={item?.id}
                              onChange={(event, newValue) =>
                                handleResultTabChange(event, item?.id)
                              }
                              className={
                                item?.id === resultTabValue ? "active " : ""
                              }
                            />
                          </Box>
                        );
                      })}
                    </Tabs>
                  </Box>
                </Box>
                <TableContainer className="race-bookmakersodd">
                  {/* {(isrunnnerLoading || oddsLoader) ? (
                <div className="allsport-loader-center">
                    <Loader />
                </div>
            ) : ( */}
                  <Table
                    className="racing-data featured-table"
                    aria-label="customized table"
                    style={{ minWidth: 700, borderRadius: 5 }}
                  >
                    <TableHead className="racing-track-table-head">
                      <TableRow style={{ fontSize: 12 }} className="head-row">
                        <TableCell
                          className="track-table-th  table-left-header result-track-table-th border-right-result"
                          style={{ textAlign: "left" }}
                        >
                          {RaceResultData
                            ? moment(RaceResultData?.updatedAt)
                              .tz(timezone)
                              .format("dddd DD/MM/YYYY, hh:mmA")
                            : ""}
                        </TableCell>
                        <TableCell
                          className="track-tabel-odds-th table-head sponsor-odds-header border-right-result"
                          style={{ width: "20%", textAlign: "center" }}
                        >
                          {localesData?.sport?.BET_TYPE}
                        </TableCell>
                        <TableCell
                          className="track-tabel-odds-th table-head sponsor-odds-header"
                          style={{ width: "20%", textAlign: "center" }}
                        >
                          {localesData?.sport?.DIVIDEND}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* {runnerInfoData?.length > 0 ? (
                                        <>
                                            {runnerInfoData?.map((item, index) => {
                                                return (
                                                    <> */}

                      {Exotics_Arr?.length > 0 ? (
                        Exotics_Arr?.map((item) => {
                          const positionsArray = item?.positions
                            ? Array.isArray(item?.positions)
                              ? item?.positions
                              : [item?.Positions]
                            : Array.isArray(item?.Positions)
                              ? item?.Positions
                              : [item?.Positions];
                          return (
                            <TableRow className="racing-track-data-row">
                              <TableCell
                                component="th"
                                scope="row"
                                align="center"
                                className="result-racer-detail exotics-cell border-right"
                              >
                                <Box className="racer-detail-wrap exotic-racer-detail-wrap">
                                  {positionsArray?.map((obj) => {
                                    return (
                                      <Box className="rank-card-wrap">
                                        <span className="rank-card">
                                          {obj?.runner_number
                                            ? obj?.runner_number
                                            : obj?.RunnerNumber}
                                        </span>

                                        <span
                                          className={`rank-badge ${fetchRank(
                                            obj?.position
                                              ? Number(obj?.position)
                                              : Number(obj?.Position)
                                          )}`}
                                        >
                                          {" "}
                                          {obj?.position
                                            ? Number(obj?.position) === 1
                                              ? "1st"
                                              : Number(obj?.position) === 2
                                                ? "2nd"
                                                : Number(obj?.position) === 3
                                                  ? "3rd"
                                                  : `${Number(obj?.position)}th`
                                            : Number(obj?.Position) === 1
                                              ? "1st"
                                              : Number(obj?.Position) === 2
                                                ? "2nd"
                                                : Number(obj?.Position) === 3
                                                  ? "3rd"
                                                  : `${Number(obj?.Position)}th`}
                                        </span>
                                      </Box>
                                    );
                                  })}
                                </Box>
                              </TableCell>
                              <TableCell className="border-right">
                                {/* {item?.oddsData?.[0]?.data ? (
                                                <> */}
                                <Box className="semi-bold">
                                  {item?.product_name
                                    ? item?.product_name
                                    : item?.ProductName}{" "}
                                  ({item?.tote ? item?.tote : item?.Tote})
                                </Box>
                                {/* {oddsicon(3, "header")} */}
                              </TableCell>
                              <TableCell className="border-right">
                                <Box className="semi-bold">
                                  {fetchClickableOdds(
                                    item?.dividend
                                      ? item?.dividend
                                      : item?.Dividend,
                                    3,
                                    "header"
                                  )}
                                </Box>
                                {/* {oddsicon(3, "header")} */}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <>
                          <TableRow style={{ border: "transparent" }}>
                            <TableCell
                              style={{
                                textAlign: "center",
                                border: "transparent",
                              }}
                              colSpan={21}
                            >
                              {/* {localesData?.sport?.NO_DATA} */}
                              <NoDataComp />
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                  {/* )} */}
                </TableContainer>
              </Box>
            </>
          ) : (
            <>
              <Box className="final-race-result mobile-race-result">
                <Box className="final-result-time">
                  <span></span>
                  <Box>
                    <Tabs
                      value={resultTabValue}
                      variant="scrollable"
                      scrollButtons="auto"
                      indicatorColor="primary"
                      textColor="primary"
                      className="state-tab-details"
                      // disableRipple
                      // disableFocusRipple
                      id="state-tab"
                    >
                      {resultTabData.map((item, index) => {
                        return (
                          <Box key={index}>
                            <Tab
                              label={item?.name}
                              value={item?.id}
                              onChange={(event, newValue) =>
                                handleResultTabChange(event, item?.id)
                              }
                              className={
                                item?.id === resultTabValue ? "active " : ""
                              }
                            />
                          </Box>
                        );
                      })}
                    </Tabs>
                  </Box>
                </Box>
                <Typography className="race-result-time exotic-race-result-time">
                  {RaceResultData
                    ? moment(RaceResultData?.updatedAt)
                      .tz(timezone)
                      .format("dddd DD/MM/YYYY, hh:mmA")
                    : ""}
                </Typography>
                <Box className="race-bookmakersodd">
                  {/* {(isrunnnerLoading || oddsLoader) ? (
                                    <div className="allsport-loader-center">
                                        <Loader />
                                    </div>
                                ) : ( */}
                  <>
                    <Box className="racing-data exotic-racing-data">
                      {/* {runnerInfoData?.length > 0 ? (
                                                    <>
                                                        {runnerInfoData?.map((item, index) => {
                                                            return ( */}
                      {Exotics_Arr?.length > 0 ? (
                        Exotics_Arr?.map((item) => {
                          const positionsArray = item?.positions
                            ? Array.isArray(item?.positions)
                              ? item?.positions
                              : [item?.Positions]
                            : Array.isArray(item?.Positions)
                              ? item?.Positions
                              : [item?.Positions];
                          return (
                            <Box className="exotics-row">
                              <Box className="rank-card-container">
                                {positionsArray?.map((obj) => {
                                  return (
                                    <Box className="rank-card-wrap">
                                      <span
                                        className={`rank-badge ${fetchRank(
                                          obj?.position
                                            ? Number(obj?.position)
                                            : Number(obj?.Position)
                                        )}`}
                                      >
                                        {" "}
                                        {obj?.position
                                          ? Number(obj?.position) === 1
                                            ? "1st"
                                            : Number(obj?.position) === 2
                                              ? "2nd"
                                              : Number(obj?.position) === 3
                                                ? "3rd"
                                                : `${Number(obj?.position)}th`
                                          : Number(obj?.Position) === 1
                                            ? "1st"
                                            : Number(obj?.Position) === 2
                                              ? "2nd"
                                              : Number(obj?.Position) === 3
                                                ? "3rd"
                                                : `${Number(obj?.Position)}th`}
                                      </span>
                                      <Box className="rank-card">
                                        {" "}
                                        {obj?.runner_number
                                          ? obj?.runner_number
                                          : obj?.RunnerNumber}
                                      </Box>
                                    </Box>
                                  );
                                })}
                              </Box>
                              <TableContainer className="exotic-table-container">
                                <Table>
                                  <TableHead className="mobile-data-head">
                                    <TableCell
                                      className="mobile-table-data"
                                      width="50%"
                                    >
                                      {" "}
                                      {localesData?.sport?.BET_TYPE}
                                    </TableCell>
                                    <TableCell
                                      className="mobile-table-data"
                                      width="50%"
                                    >
                                      {" "}
                                      {localesData?.sport?.DIVIDEND}
                                    </TableCell>
                                  </TableHead>
                                  <TableBody className="mobile-table-body">
                                    <TableCell className="mobile-table-data border-right-result">
                                      <Box className="semi-bold">
                                        {" "}
                                        {item?.product_name
                                          ? item?.product_name
                                          : item?.ProductName}{" "}
                                        ({item?.tote ? item?.tote : item?.Tote})
                                      </Box>
                                      {/* {oddsicon(3, "header")} */}
                                    </TableCell>
                                    <TableCell className="mobile-table-data">
                                      <Box className="semi-bold">
                                        {fetchClickableOdds(
                                          item?.dividend
                                            ? item?.dividend
                                            : item?.Dividend,
                                          3,
                                          "header"
                                        )}
                                      </Box>
                                      {/* {oddsicon(3, "header")} */}
                                    </TableCell>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Box>
                          );
                        })
                      ) : (
                        <>
                          <Box style={{ border: "transparent" }}>
                            <Box
                              style={{
                                textAlign: "center",
                                border: "transparent",
                              }}
                            >
                              {/* {localesData?.sport?.NO_DATA} */}
                              <NoDataComp />
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  </>
                  {/* )} */}
                </Box>
              </Box>
            </>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className="race-card-wrap">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="race-card-header"
        >
          <Typography variant="h4">Race Card</Typography>
        </AccordionSummary>
        {/* <Box className="race-player-detail-wrap side-space"> */}
        <AccordionDetails>
          <RunnerTable
            raceId={raceId}
            runnerInfo={runnerInfo}
            isrunnnerLoading={isrunnnerLoading}
            isResult={isResult}
            raceTrackdata={raceTrackdata}
            fetchRaceRunner={fetchRaceRunner}
          />
        </AccordionDetails>
        {/* </Box> */}
      </Accordion>
    </Box>
  );
};

export default RunnerResultTable;
