import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from "@mui/material";
import { Config } from "../../../../../helpers/context/config";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import { useParams } from "react-router";

const H2HInfoData = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
  {
    id: 4
  },
  {
    id: 5
  }
];

const InfoH2H = ({ fixtureInfoData }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Brisbane} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Brisbane} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Melbourne} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Melbourne} alt="Odds Icon" />;
      }
    }
  };

  return (
    <>
      {H2HInfoData?.map((item, index) => {
        return (
          <>
            <Box className="h2h-sport-table-wrap" key={index}>
              <Box className="table-header-wrap">
                <Box className="table-indicator-wrap">
                  <span>NFL Divisions</span>
                </Box>
              </Box>
              {screenWidth > 1023 ? (
                <Table className="h2h-sport-table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Box className="h2h-sport-table-indicator">
                          <Box>
                            <span className="start-time-date">
                              {/* {fetchDayName(item?.startTime)}{" "}
                        {moment
                          .utc(item?.startTime)
                          .local()
                          .format("DD/MM/YYYY")}{" "}
                        |{" "}
                        {moment
                          .utc(item?.startTime)
                          .local()
                          .format("hh:mm A")}{" "} */}
                              Friday 19/08/2022 | 07:50PM
                            </span>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className="table-head">Full Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow className="teamsports-tablerow hometeam-row">
                      <TableCell className="team-wrap">
                        <Box className="d-flex align-center team-wrap-box">
                          <span className="team-img-wrap">
                            {fetchTeamlogo(item, "hometeam")}
                          </span>
                          <span className="team-name">Brisbane Lions</span>
                        </Box>
                      </TableCell>

                      <TableCell className="td-cell border-bottom">
                        <Box className="odds-wrap-info d-flex align-center">
                          <Box className="points">
                            <span>19</span>
                          </Box>
                          <Box className="win-loss win">
                            <span>W</span>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow className="awayteam-row">
                      <TableCell className="team-wrap">
                        <Box className="d-flex align-center team-wrap-box">
                          <span className="team-img-wrap">
                            {fetchTeamlogo(item, "awayteam")}
                          </span>
                          <span className="team-name mr-6">Melbourne</span>
                        </Box>
                      </TableCell>
                      <TableCell className="td-cell">
                        <Box className="odds-wrap-info d-flex align-center">
                          <Box className="points">
                            <span>19</span>
                          </Box>
                          <Box className="win-loss loss">
                            <span>L</span>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ) : (
                <Box className="mobileview-h2h-table-wrap">
                  <Box className="match-time-date">
                    <>
                      <Typography className="h2h-des-label">
                        {/* {fetchDayName(item?.startTime)}{" "}
                          {moment
                            .utc(item?.startTime)
                            .local()
                            .format("DD/MM/YYYY")}{" "}
                          |{" "}
                          {moment
                            .utc(item?.startTime)
                            .local()
                            .format("hh:mm A")}{" "} */}
                        Friday 19/08/2022 | 07:50PM
                      </Typography>
                    </>
                  </Box>
                  <Box className="teams-details d-flex align-center">
                    <Box className="team-wrap">
                      <span className="team-img-wrap">
                        {fetchTeamlogo(item, "hometeam")}
                      </span>
                      <Box className="teams-details-title">
                        <span className="team-name">Brisbane Lions</span>
                      </Box>
                    </Box>
                    <Box className="vs"> V/S </Box>
                    <Box className="team-wrap">
                      <span className="team-img-wrap">
                        {fetchTeamlogo(item, "awayteam")}
                      </span>
                      <Box className="teams-details-title">
                        <span className="team-name">Melbourne</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="h2h-full-time-wrap">
                    <Box className="full-time-wrap">
                      <span>Full time</span>
                    </Box>
                    <Box className="d-flex align-center mobile-h2h-odds">
                      <Box className="d-flex align-center h2h-odds-left">
                        <Box className="points">
                          <span>19</span>
                        </Box>
                        <Box className="win-loss win">
                          <span>W</span>
                        </Box>
                      </Box>
                      <Box className="d-flex align-center h2h-odds-right">
                        <Box className="points">
                          <span>19</span>
                        </Box>
                        <Box className="win-loss loss">
                          <span>L</span>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </>
        );
      })}
    </>
  );
};

export default InfoH2H;
