import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import withStyles from '@mui/styles/withStyles';
import LinearProgress from "@mui/material/LinearProgress";
import { Rating } from '@mui/material';
import { ReactComponent as Star1 } from "src/assets/images/icons/star1.svg";
import { ReactComponent as Star2 } from "src/assets/images/icons/star2.svg";
import { ReactComponent as Star3 } from "src/assets/images/icons/star3.svg";
import { ReactComponent as Star4 } from "src/assets/images/icons/star4.svg";
import { ReactComponent as Star5 } from "src/assets/images/icons/star5.svg";
import DefaultImg from "src/assets/images/smartb_default.png";

const BookmakerInfo = ({
  bookkeeperData,
  showLogo,
  handleReviewSection,
  reviewTabData
}) => {
  const params = useParams();

  const BorderLinearProgress = withStyles(theme => ({
    root: {
      height: 10,
      width: 116,
      borderRadius: 0
    },
    colorPrimary: {
      backgroundColor: "#ffffff",
      borderRadius: 7
    },
    bar: {
      borderRadius: 0,
      backgroundColor: "#4455C7",
      borderRadius: 7
    }
  }))(LinearProgress);

  const reviewEmoji = value => {
    if (value <= 1) {
      return <Star1 />;
    }
    if (value <= 2) {
      return <Star2 />;
    }
    if (value <= 3) {
      return <Star3 />;
    }
    if (value <= 4) {
      return <Star4 />;
    }
    if (value <= 5) {
      return <Star5 />;
    }
  };

  const joinBtn = () => (
    <>
      <Button
        className="bookmaker-header-info-join-btn"
        onClick={() => window.open(bookkeeperData?.affiliate_link, "_blank")}
      >
        Join
      </Button>
      <Button
        className="bookmaker-header-info-add-review-btn"
        onClick={() => {
          handleReviewSection(params?.id);
        }}
      >
        Add a Review
      </Button>
    </>
  );

  return (
    <>
      <Box className="bookmaker-header-info-container">
        <Box className="bookmaker-header-info-details">
          <Box className="img-box">
            <img
              src={
                showLogo(bookkeeperData) ? showLogo(bookkeeperData) : DefaultImg
              }
              onError={e => {
                e.target.src = DefaultImg;
              }}
              alt="bookmaker-image"
              className="bookmaker-image"
            />
          </Box>
          <Box className="bookmaker-header-info-data">
            <Typography className="bookmaker-header-info-title">
              {bookkeeperData?.name}
            </Typography>
            <Typography className="bookmaker-header-info-desc">
              {bookkeeperData?.sort_descriptions}
            </Typography>
            <Box className="desktop-join-btn-view">{joinBtn()}</Box>
          </Box>
        </Box>
        <Box className="show-1180 mobile-join-btn-view">{joinBtn()}</Box>

        <Box className="bookmaker-header-info-review">
          <Box className="rating-review-box bookmaker-header-info-rating">
            <Box className="rating-box">
              <Typography className="rr-title">Ratings & Reviews</Typography>

              {reviewTabData &&
              reviewTabData.summary &&
              reviewTabData.summary.averageRating ? (
                <Box className="d-flex text-align content-space-betwen">
                  <Box className="bookmaker-total-review-emoji">
                    {reviewEmoji(parseInt(reviewTabData.summary.averageRating))}
                    <Rating
                      name="customized-10"
                      defaultValue={reviewTabData?.summary?.averageRating}
                      precision={0.5}
                      className="rating"
                      readOnly
                    />
                  </Box>
                  <Typography>
                    {reviewTabData?.summary?.averageRating?.toFixed(1)}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
              <Box className="rating-info-box">
                {reviewTabData &&
                  reviewTabData?.summary &&
                  reviewTabData?.summary?.ratingCounts &&
                  Object.entries(reviewTabData?.summary?.ratingCounts)
                    ?.reverse()
                    ?.map(([key, value]) => {
                      return (
                        <Box className="rating-info" key={value}>
                          <Box className="d-flex">
                            {reviewEmoji(parseInt(key))}
                            <Typography className="stars">
                              {key + " Stars"}
                            </Typography>
                          </Box>
                          <BorderLinearProgress
                            variant="determinate"
                            value={value}
                            className="progress-bar"
                            // value={Math.ceil(
                            //   (reviewTabData?.summary?.totalRatings / value) * 100
                            // )}
                          />
                          <Typography className="stars">{value}</Typography>
                        </Box>
                      );
                    })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BookmakerInfo;
