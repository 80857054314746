export default {
  h1: {
    fontWeight: "normal",
    fontSize: "43.9px",
    lineHeight: "65px",
    fontFamily: "VeneerClean-Soft",
    letterSpacing: "0",
    "@media (max-width:768px)": {
      fontSize: "35px",
      lineHeight: "40px"
    }
  },
  // h2: {
  //   fontWeight: 500,
  //   fontSize: 29,
  //   letterSpacing: "-0.24px",
  //   fontFamily: "Inter, sans-serif"
  // },
  // h3: {
  //   fontWeight: 500,
  //   fontSize: 24,
  //   letterSpacing: "-0.06px",
  //   fontFamily: "Inter, sans-serif"
  // },
  // h4: {
  //   fontWeight: 500,
  //   fontSize: 20,
  //   letterSpacing: "-0.06px",
  //   fontFamily: "Inter, sans-serif"
  // },
  // h5: {
  //   fontWeight: 500,
  //   fontSize: 16,
  //   letterSpacing: "-0.05px",
  //   fontFamily: "Inter, sans-serif"
  // },
  // h6: {
  //   fontWeight: 500,
  //   fontSize: 14,
  //   letterSpacing: "-0.05px",
  //   fontFamily: "Inter, sans-serif"
  // },
  overline: {
    fontWeight: 500
  }
};
