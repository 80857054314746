import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Breadcrumbs,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableContainer,
  FormControl,
  Checkbox,
  FormControlLabel,
  Switch,
  TextField,
  ClickAwayListener,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import banner from "../../../assets/images/banner/smart-b-banner-primary.png";
import bannerPageHeader from "../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import OurPartner from "../ourPartners";
import { useParams } from "react-router-dom";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import Select, { components } from "react-select";
import { ReactComponent as LeftArrow } from "src/assets/images/left-arrow.svg";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import playerAvtar from "../../../assets/images/player-avatar.png";
import AdBannner from "../AdBanner/AdBanner";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import Bet365 from "src/assets/images/bet365.png";
import moment from "moment-timezone";
import { ReactComponent as Uncheck } from "src/assets/images/icons/Checkbox.svg";
import { ReactComponent as Check } from "src/assets/images/icons/check.svg";
import { ReactComponent as Minus } from "src/assets/images/icons/counter-minus.svg";
import { ReactComponent as Plus } from "src/assets/images/icons/counter-plus.svg";
import { ReactComponent as Notcheck } from "src/assets/images/icons/notcheck.svg";
import { ReactComponent as BlackbookRight } from "src/assets/images/icons/blackbookRight.svg";
import { ReactComponent as BlackbookPlus } from "src/assets/images/icons/blackbookPlus.svg";
import { ReactComponent as RightBlack } from "src/assets/images/icons/rightblack.svg";
import { ReactComponent as PlusBlack } from "src/assets/images/icons/plusblack.svg";
import { ReactComponent as AddBookIcon } from "src/assets/images/add-blackbook.svg";
import { ReactComponent as TooltipArrow } from "src/assets/images/icons/tooltip_arrow.svg";
import { ReactComponent as Share } from "src/assets/images/icons/share.svg";
import tippingAbove from "src/assets/images/ad-placeholder/tipping1.png";
import tippingBelow from "src/assets/images/ad-placeholder/tipping2.png";
import BannerIndia from "src/assets/images/ad-placeholder/IPL_Page_Banner_894x218_4.jpg";
import { ReactComponent as BlueCheck } from "src/assets/images/icons/blue-checkbox.svg";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton
} from "react-share";
import DefaultImg from "src/assets/images/smartb_default.png";
import NotepadBig from "src/assets/images/notepadBig.png";
import Facebook from "src/assets/images/social/fb-link.svg";
import Linkedin from "src/assets/images/social/li-link.svg";
import Twitter_X from "src/assets/images/social/x-link.svg";
import Snapchat from "src/assets/images/social/sc-link.svg";
import Copy from "src/assets/images/social/copy-link.svg";
import Messenger from "src/assets/images/social/mess-link.svg";
import Wp from "src/assets/images/social/wp-link.svg";
import Message from "src/assets/images/social/messages.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import NoDataComp from "../UI/NoData";
import { useDispatch, useSelector } from "react-redux";
import "./compTips.scss";
import { Config } from "src/helpers/context";
import Countdown from "react-countdown";
import { release } from "src/helpers/context/release";
import SmartBookModal from "../UI/smartbookModal";
import SportsOdds from "../sportsOdds/SportsOdds";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

function CompTipsPage() {
  const params = useParams();
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const redux_userData = useSelector(
    state => state?.reduxData?.SubscripitionData
  );
  const navigate = useNavigate();
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [stepperCount, setStepperCount] = useState(null);
  const [rounds, setRounds] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const limit = 20;
  const [teamApiCount, setTeamApiCount] = useState(0);
  const [sports, setSports] = useState([]);
  const [selectedSports, setSelectedSports] = useState(null);
  const [teamsAll, setTeamsAll] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState(null);
  const [OrgAll, setOrgAll] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [OrgApiCount, setOrgApiCount] = useState(0);
  const [isOrgSearch, setIsOrgSearch] = useState("");
  const [countOrg, setcountOrg] = useState(0);
  const [isOrgSelectOpen, setisOrgSelectOpen] = useState(false);
  const [searchOrg, setsearchOrg] = useState([]);
  const [searchOrgCount, setsearchOrgCount] = useState(0);
  const [SearchOrgpage, setSearchOrgpage] = useState(0);
  const [pageOrg, setpageOrg] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [eventLength, setEventLength] = useState(0);
  const [eventByIdData, setEventByIdData] = useState({});
  const [allEventData, setAllEventData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [BookkeeperData, setBookKeeperData] = useState([]);
  const [eventsData, setEventsData] = useState(allEventData);
  const [groupedEventData, setGroupedData] = useState({});
  const [counts, setCounts] = useState(0);
  const [isJoker, setIsJoker] = useState(false);
  const [jokerRound, setJockerRound] = useState(null);
  const [completedRound, setCompletedRound] = useState([]);
  const [cutoffDate, setcutoffDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [recallAPI, setRecallAPI] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredParentIndex, setHoveredParentIndex] = useState(null);
  const [clickToolTipTeamId, setClickToolTipTeamId] = useState(null);
  const [SBopen, setSBOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedSportId, setSelectedSportId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [shareTooltipOpen, setShareTooltipOpen] = useState(false);
  const [shareTipTooltipOpen, setShareTipTooltipOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [lastEventDate, setLastEventDate] = useState(null);
  const [atLeastOneError, setAtLeastOneError] = useState("");

  const convetToGroupData = eventdata => {
    const groupedData = _.groupBy(eventdata, event =>
      moment(event?.startTime).format("YYYY-MM-DD")
    );
    setGroupedData(groupedData);
  };

  useEffect(() => {
    setCurrentPage(0);
    fetchSportData();
    fetchBookKeeper();
    // getEventByID("");
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchAdsData();
  }, []);

  useEffect(() => {
    fetchRoundData(
      Number(params?.tournamentId),
      Number(params?.SportId),
      Number(params?.compsId)
    );
  }, []);
  useEffect(() => {
    if (cutoffDate && eventByIdData?.cutOffTime) {
      const time = moment
        .utc(
          `${eventByIdData?.cutOffWeek} ${eventByIdData?.cutOffTime}`,
          "dddd HH:mm:ss"
        )
        .tz(timezone)
        .format("HH:mm:ss");
      const dateTime = moment(`${cutoffDate} ${time}`, "YYYY-MM-DD HH:mm:ss");
      setLastDate(dateTime);
    }
  }, [cutoffDate, eventByIdData?.cutOffTime]);

  useEffect(() => {
    if (lastDate && lastDate > moment()) {
      const updateCurrentTime = () => {
        const currentTimePassed = lastDate < moment();
        if (currentTimePassed) {
          setRecallAPI(true);
          clearInterval(intervalId);
        }
      };

      const intervalId = setInterval(updateCurrentTime, 1000);

      return () => clearInterval(intervalId);
    }
  }, [lastDate]);

  useEffect(() => {
    if (recallAPI) {
      getEventByID(stepperCount);
      // setRecallAPI(false);
    }
  }, [recallAPI]);
  useEffect(() => {
    if (allEventData?.length > 0) {
      const modifiedEventData = allEventData?.map((item, index) =>
        index === 0 ? { ...item, margin: true } : item
      );

      convetToGroupData(modifiedEventData);
      setEventsData(modifiedEventData);
      const margin = allEventData?.[0]?.roundData?.margin;

      // setCounts(margin ? margin : counts);
    }
    setAtLeastOneError("");
  }, [allEventData]);

  useEffect(() => {
    if (stepperCount !== null) {
      const abortController = new AbortController();
      const { signal } = abortController;

      getEventByID(stepperCount, signal);

      return () => {
        abortController.abort();
      };
    }
  }, [stepperCount]);

  const fetchRoundData = async (tID, sID, cID) => {
    const date = moment(Date()).format("YYYY-MM-DD");
    // &startDate=${date}
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/rounds?timezone=${timezone}&tournamentId=${tID}&SportId=${sID}&competitionId=${cID}`
      );
      if (status === 200) {
        let newdata = [];
        let track = data?.result?.map(item => {
          newdata.push({
            label: `${sID == 4 ? `Day` : `Round`} ${Number(item)}`,
            value: item
          });
        });

        // data?.result?.length > 0 &&
        //   getEventByID(data?.current ? data?.current : data?.result?.[0]);
        setCompletedRound(data?.completedRound);
        setRounds(data?.result);
        setStepperCount(data?.current ? data?.current : data?.result?.[0]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleIncrement = () => {
    setCounts(Number(counts) + 1);
  };

  const handleDecrement = (tipId, index) => {
    // const newCounts = { ...counts };
    // if (newCounts[tipId] > 0) {
    //   newCounts[tipId]--;
    //   setCounts(newCounts);
    // }
    if (counts > 0) {
      setCounts(Number(counts) - 1);
    }
  };

  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };
  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchAdsData = async () => {
    try {
      const passPageId =
        params?.SportId == 4 ? 41 : params?.SportId == 12 ? 42 : 43;
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=${passPageId}&type=web&timeZone=${timezone}`
      );
      if (status === 200) {
        setAdData(data?.result);
        // let ids = [];
        // let listAds = data?.data?.[0]?.pageAdLists?.map(item => {
        //   ids.push(item?.id);
        // });
        // if (ids.length > 0) {
        //   handleAdsImression(ids);
        // }
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    // let ad = AdData?.filter((item) => {
    //   return item?.page_id === position;
    // });
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const getEventByID = async (round, signal) => {
    setLoading(true);
    const compId = Number(params?.compsId);
    // const compId = Number(params?.compId);
    try {
      const {
        status,
        data
      } = await axiosInstance.get(
        `tipping/get/${compId}?timezone=${timezone}&round=${round}`,
        { signal }
      );
      if (status === 200) {
        setEventByIdData(data?.result);
        setEventLength(data?.events?.length);
        setIsJoker(round == data?.result?.joker);
        setJockerRound(data?.result?.joker);
        if (data?.events?.length === 0) {
          getAllEvents(round);
        } else {
          setLoading(false);
          setAllEventData(data?.events);
          setCounts(
            data?.events?.[0]?.roundData?.margin
              ? data?.events?.[0]?.roundData?.margin
              : counts
          );
          setcutoffDate(data?.result?.date);
          convetToGroupData(data?.events);
          setLastEventDate(
            moment
              .utc(data?.events?.[data?.events?.length - 1]?.startTime)
              .tz(timezone)
              .format("YYYY-MM-DD HH:mm:ss")
          );
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      // setLoading(false);
    }
  };

  const getAllEvents = async round => {
    const sId = Number(params?.SportId);
    const tId = Number(params?.tournamentId);
    const compId = Number(params?.compsId);
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `tipping/events?timezone=${timezone}&tournamentId=${tId}&SportId=${sId}&startDate=${moment()
          .tz(timezone)
          .format("YYYY-MM-DD")}&round=${round}&competitionId=${compId}`
      );
      if (status === 200) {
        setLoading(false);
        setAllEventData(data?.result);
        setCounts(0);
        convetToGroupData(data?.result);
        setcutoffDate(data?.competition?.date);
        setLastEventDate(
          moment
            .utc(data?.result?.[data?.result?.length - 1]?.startTime)
            .tz(timezone)
            .format("YYYY-MM-DD HH:mm:ss")
        );
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const MyMinusNutton = () => {
    return (
      <Button>
        <Minus />
      </Button>
    );
  };
  const MyPLusNutton = () => {
    return (
      <Button>
        <Plus />
      </Button>
    );
  };

  const getFields = (list, field) => {
    return list.reduce(function(carry, item) {
      if (typeof item === "object" && field in item) {
        carry.push(item[field]);
      }
      return carry;
    }, []);
  };

  const fetchSportData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/sport/?sportTypeId=${2}`
      );
      if (status === 200) {
        var sportsdata = data?.result.map(s => {
          return { ...s, label: s?.sportName, value: s?.id };
        });
        var filterSports = getFields(sportsdata, "id");
        setSelectedSports(filterSports);
        var sdata = _.orderBy(sportsdata, ["label"], ["asc"]);
        var mergedArray = _.concat(
          [{ label: "ALL", value: filterSports, isAll: true }],
          sdata
        );
        setSports(mergedArray);
      }
    } catch (err) {}
  };

  const fetchOrgData = async (page, sID, OrgAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/tournament?SportId=${sID}&offset=${page}&limit=${limit}`
      );
      if (status === 200) {
        setOrgApiCount(OrgApiCount + 1);
        setcountOrg(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.name,
            value: item?.id
          });
        });
        let filterData = _.unionBy(OrgAll, newdata);
        let allteamdatas = filterData?.unshift({
          label: "All Tournaments",
          value: 0
        });
        setOrgAll(
          _.uniqBy(filterData, function(e) {
            return e.value;
          })
        );
      }
    } catch (err) {}
  };

  const handleOrgInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/tournament?SportId=${sid}&limit=${limit}&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchOrgCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const mergeData = _.unionBy(searchOrg, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchOrg(filterData);
          setIsOrgSearch(value);
        }
      });
  };

  const handleOnScrollBottomOrg = () => {
    if (
      isOrgSearch !== "" &&
      searchOrgCount !== Math.ceil(SearchOrgpage / 20)
    ) {
      handleOrgInputChange(SearchOrgpage + 20, isOrgSearch, selectedSports);

      setSearchOrgpage(SearchOrgpage + 20);
    } else {
      if (countOrg !== 0 && countOrg !== Math.ceil(pageOrg / 20 + 1)) {
        fetchOrgData(pageOrg + 20, selectedSports, OrgAll);
        setpageOrg(pageOrg + 20);
      }
    }
  };
  const handleBookkeeperCounter = async (BookKeeperId, type, item) => {
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(item?.SportId)
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const oddsicon = (BookKeeperId, type, item, fullData) => {
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;

    return (
      <a
        href={iconData?.affiliate_link}
        target="_blank"
        rel="noopener noreferrer"
        className="odds-icon-anchor"
      >
        <img
          className="bookmaker-thumb odd-img"
          src={
            iconData?.small_logo?.includes("uploads")
              ? Config.mediaURL + iconData?.small_logo
              : iconData?.small_logo
          }
          alt="Odds Icon"
          onClick={() => handleBookkeeperCounter(BookKeeperId, type, fullData)}
        />
      </a>
    );
  };
  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip,
    Isscore
  ) => {
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;
    const oddsInfo = BookKeeperData?.[0];
    // let isAdded = BetslipData?.some(el => {
    //   if (
    //     el?.BookKeeperId == BookKeeperId &&
    //     el?.betOfferId == oddsInfo?.id &&
    //     el?.eventId == fulldata?.id
    //   )
    //     return true;
    //   else return false;
    // });
    return (
      // <a
      //   href={iconData?.affiliate_link}
      //   target="_blank"
      //   rel="noopener noreferrer"
      //   className="odds-link"
      // >
      // {/* <span
      //   className="odds-link"
      //   onClick={() => handleAddToBetSlip(BookKeeperId, type, item, fulldata)}
      //   //  onClick={() => { IsBetslip === "nobetslip" ? handleBookkeeperCounter(BookKeeperId, type) : handleAddToBetSlip(BookKeeperId, type, item, fulldata) }}
      // >
      //   {Number(odds).toFixed(2)}
      // </span> */}
      // </a>
      (<>
        {odds ? (
          <span
            className="odds-btn cursor-pointer"
            onClick={e => {
              handleBookkeeperCounter(BookKeeperId, type, e);
              // IsBetslip === "nobetslip"
              //   ? handleBookkeeperCounter(BookKeeperId, type, e)
              //   : isAdded
              //   ? handleDeleteBetslip(BookKeeperId, item, fulldata, e)
              //   : handleAddToBetSlip(
              //       BookKeeperId,
              //       type,
              //       item,
              //       fulldata,
              //       Isscore,
              //       e
              //     );
            }}
          >
            <span
              className={
                "odds-link odds"
                // isAdded && IsBetslip === "betslip"
                //   ? "betslip-added odds-link"
                //   : "odds-link"
              }
            >
              {IsBetslip === "betslip" && !Isscore ? (
                <Tooltip title="Bet" className="bet-tooltip" placement="top">
                  <span className="bet-now">
                    {" "}
                    {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
                  </span>
                </Tooltip>
              ) : (
                <>{odds === "SP" ? "SP" : Number(odds).toFixed(2)}</>
              )}
            </span>
          </span>
        ) : (
          <span className="odds-btn">NOA</span>
        )}
      </>)
    );
    // }
  };
  const fetchCurrentBestOdds = (data, type, team, teamid, Isscore) => {
    let allTeamOdds =
      data?.SportId === 4
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : data?.SportId === 10
        ? data?.NBABetOffers?.[0]?.NBAOdds
        : data?.SportId === 15
        ? data?.AFLBetOffers?.[0]?.AFLOdds
        : data?.SportId === 9
        ? data?.ARBetOffers?.[0]?.AROdds
        : data?.SportId === 16
        ? data?.GolfBetOffers?.[0]?.GolfOdds
        : data?.SportId === 7
        ? data?.TennisBetOffers?.[0]?.TennisOdds
        : data?.SportId === 11
        ? data?.BaseballBetOffers?.[0]?.BaseballOdds
        : data?.SportId === 17
        ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
        : data?.SportId === 6
        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
        : data?.SportId === 5
        ? data?.MMABetOffers?.[0]?.MMAOdds
        : data?.SportId === 8
        ? data?.SoccerBetOffers?.[0]?.SoccerOdds
        : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter(item => {
      return data?.SportId === 4
        ? item?.CricketTeamId == data?.homeTeamId
        : data?.SportId === 10
        ? item?.NBATeamId == data?.homeTeamId
        : data?.SportId === 15
        ? item?.AFLTeamId == data?.homeTeamId
        : data?.SportId === 9
        ? item?.ARTeamId == data?.homeTeamId
        : data?.SportId === 16
        ? item?.GolfTeamId == data?.homeTeamId
        : data?.SportId === 7
        ? item?.TennisTeamId == data?.homeTeamId
        : data?.SportId === 11
        ? item?.BaseballTeamId == data?.homeTeamId
        : data?.SportId === 17
        ? item?.IceHockeyTeamId == data?.homeTeamId
        : data?.SportId === 6
        ? item?.BoxingTeamId == data?.homeTeamId
        : data?.SportId === 5
        ? item?.MMATeamId == data?.homeTeamId
        : data?.SportId === 8
        ? item?.SoccerTeamId == data?.homeTeamId
        : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter(item => {
      return data?.SportId === 4
        ? item?.CricketTeamId == data?.awayTeamId
        : data?.SportId === 10
        ? item?.NBATeamId == data?.awayTeamId
        : data?.SportId === 15
        ? item?.AFLTeamId == data?.awayTeamId
        : data?.SportId === 9
        ? item?.ARTeamId == data?.awayTeamId
        : data?.SportId === 16
        ? item?.GolfTeamId == data?.awayTeamId
        : data?.SportId === 7
        ? item?.TennisTeamId == data?.awayTeamId
        : data?.SportId === 11
        ? item?.BaseballTeamId == data?.awayTeamId
        : data?.SportId === 17
        ? item?.IceHockeyTeamId == data?.awayTeamId
        : data?.SportId === 6
        ? item?.BoxingTeamId == data?.awayTeamId
        : data?.SportId === 5
        ? item?.MMATeamId == data?.awayTeamId
        : data?.SportId === 8
        ? item?.SoccerTeamId == data?.awayTeamId
        : item?.RLTeamId == data?.awayTeamId;
    });

    let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;
    let teamData = teamInfo;

    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);

    if (maxno !== -1) {
      if (maxno !== 0) {
        let bookkeeperid = teamData
          ?.map(obj => {
            if (obj?.odd === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter(x => x !== undefined);
        return fetchClickableOdds(
          maxno,
          bookkeeperid?.[0],
          "header",
          teamData,
          data,
          "betslip",
          Isscore
        );
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };
  const fetchCurrentBestsOddsIcon = (data, type, team, teamid) => {
    let allTeamOdds =
      data?.SportId === 4
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : data?.SportId === 10
        ? data?.NBABetOffers?.[0]?.NBAOdds
        : data?.SportId === 15
        ? data?.AFLBetOffers?.[0]?.AFLOdds
        : data?.SportId === 9
        ? data?.ARBetOffers?.[0]?.AROdds
        : data?.SportId === 16
        ? data?.GolfBetOffers?.[0]?.GolfOdds
        : data?.SportId === 7
        ? data?.TennisBetOffers?.[0]?.TennisOdds
        : data?.SportId === 11
        ? data?.BaseballBetOffers?.[0]?.BaseballOdds
        : data?.SportId === 17
        ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
        : data?.SportId === 6
        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
        : data?.SportId === 5
        ? data?.MMABetOffers?.[0]?.MMAOdds
        : data?.SportId === 8
        ? data?.SoccerBetOffers?.[0]?.SoccerOdds
        : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter(item => {
      return data?.SportId === 4
        ? item?.CricketTeamId == data?.homeTeamId
        : data?.SportId === 10
        ? item?.NBATeamId == data?.homeTeamId
        : data?.SportId === 15
        ? item?.AFLTeamId == data?.homeTeamId
        : data?.SportId === 9
        ? item?.ARTeamId == data?.homeTeamId
        : data?.SportId === 16
        ? item?.GolfTeamId == data?.homeTeamId
        : data?.SportId === 7
        ? item?.TennisTeamId == data?.homeTeamId
        : data?.SportId === 11
        ? item?.BaseballTeamId == data?.homeTeamId
        : data?.SportId === 17
        ? item?.IceHockeyTeamId == data?.homeTeamId
        : data?.SportId === 6
        ? item?.BoxingTeamId == data?.homeTeamId
        : data?.SportId === 5
        ? item?.MMATeamId == data?.homeTeamId
        : data?.SportId === 8
        ? item?.SoccerTeamId == data?.homeTeamId
        : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter(item => {
      return data?.SportId === 4
        ? item?.CricketTeamId == data?.awayTeamId
        : data?.SportId === 10
        ? item?.NBATeamId == data?.awayTeamId
        : data?.SportId === 15
        ? item?.AFLTeamId == data?.awayTeamId
        : data?.SportId === 9
        ? item?.ARTeamId == data?.awayTeamId
        : data?.SportId === 16
        ? item?.GolfTeamId == data?.awayTeamId
        : data?.SportId === 7
        ? item?.TennisTeamId == data?.awayTeamId
        : data?.SportId === 11
        ? item?.BaseballTeamId == data?.awayTeamId
        : data?.SportId === 17
        ? item?.IceHockeyTeamId == data?.awayTeamId
        : data?.SportId === 6
        ? item?.BoxingTeamId == data?.awayTeamId
        : data?.SportId === 5
        ? item?.MMATeamId == data?.awayTeamId
        : data?.SportId === 8
        ? item?.SoccerTeamId == data?.awayTeamId
        : item?.RLTeamId == data?.awayTeamId;
    });

    let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;

    let teamData = teamInfo;

    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno && maxno !== 0) {
        let providerid = teamData
          ?.map(obj => {
            let oddsType = obj?.odd;
            if (oddsType === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter(x => x !== undefined);

        return oddsicon(providerid?.[0], "header", teamData, data);
      } else {
        return "";
        // <span className="odds"> - </span>
      }
    } else {
      return "";
      // <span className="odds"> - </span>
    }
  };

  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`
      );
      if (status === 200) {
        setBookKeeperData(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const handleTooltipContentClick = (event, teamId, objindex, parentIndex) => {
    event.stopPropagation();
    setTooltipOpen(!tooltipOpen);
    setHoveredIndex(objindex);
    setClickToolTipTeamId(teamId);
    setHoveredParentIndex(parentIndex);
  };

  const handleOutsideClick = (item, teamId, index, parentIndex) => {
    if (
      hoveredIndex === index &&
      clickToolTipTeamId === teamId &&
      hoveredParentIndex === parentIndex
    ) {
      if (tooltipOpen) {
        setTooltipOpen(false);
      }
    }
  };

  const handleOpenTooltip = (item, teamId, index, teamType, parentIndex) => {
    let tournamentName =
      item?.SportId === 4 && item?.CricketTournament?.name
        ? item?.CricketTournament?.name
        : item?.SportId === 10 && item?.NBATournament?.name
        ? item?.NBATournament?.name
        : item?.SportId === 15 && item?.AFLTournament?.name
        ? item?.AFLTournament?.name
        : item?.SportId === 9 && item?.ARTournament?.name
        ? item?.ARTournament?.name
        : item?.SportId === 16 && item?.GolfTournament?.name
        ? item?.GolfTournament?.name
        : item?.SportId === 7 && item?.TennisTournament?.name
        ? item?.TennisTournament?.name
        : item?.SportId === 11 && item?.BaseballTournament?.name
        ? item?.BaseballTournament?.name
        : item?.SportId === 17 && item?.IceHockeyTournament?.name
        ? item?.IceHockeyTournament?.name
        : item?.SportId === 6 && item?.BoxingTournament?.name
        ? item?.BoxingTournament?.name
        : item?.SportId === 5 && item?.MMATournament?.name
        ? item?.MMATournament?.name
        : item?.SportId === 8 && item?.SoccerTournament?.name
        ? item?.SoccerTournament?.name
        : item?.RLTournament?.name;

    let tournamentId =
      item?.SportId === 4 && item?.CricketTournamentId
        ? item?.CricketTournamentId
        : item?.SportId === 10 && item?.NBATournamentId
        ? item?.NBATournamentId
        : item?.SportId === 15 && item?.AFLTournamentId
        ? item?.AFLTournamentId
        : item?.SportId === 9 && item?.ARTournamentId
        ? item?.ARTournamentId
        : item?.SportId === 16 && item?.GolfTournamentId
        ? item?.GolfTournamentId
        : item?.SportId === 7 && item?.TennisTournamentId
        ? item?.TennisTournamentId
        : item?.SportId === 11 && item?.BaseballTournamentId
        ? item?.BaseballTournamentId
        : item?.SportId === 17 && item?.IceHockeyTournamentId
        ? item?.IceHockeyTournamentId
        : item?.SportId === 6 && item?.BoxingTournamentId
        ? item?.BoxingTournamentId
        : item?.SportId === 5 && item?.MMATournamentId
        ? item?.MMATournamentId
        : item?.SportId === 8 && item?.SoccerTournamentId
        ? item?.SoccerTournamentId
        : item?.RLTournamentId;

    return (
      <Box
        className="custom-tooltip"
        onClick={e => handleTooltipContentClick(e, teamId, index, parentIndex)}
      >
        <Box
          // className={showOdds ? "smartboook-icon-box" : "smartboook-icon-box"}
          className="smartboook-icon-box"
        >
          {(teamType === "hometeam"
            ? Boolean(item?.isSmartBookHomeTeam)
            : Boolean(item?.isSmartBookAwayTeam)) &&
          Boolean(item?.isSmartBookTournament) ? (
            <BlackbookRight className="book-icon" />
          ) : (
            <BlackbookPlus className="book-icon" />
          )}
        </Box>

        {hoveredIndex === index &&
        hoveredParentIndex === parentIndex &&
        clickToolTipTeamId === teamId ? (
          tooltipOpen && (
            <Box className="tooltip comp-smartbook-tooltip">
              <TooltipArrow className="tooltip-arrow comp-smartbook-tooltip-arrow" />
              <Typography className="add-smartbook">
                <AddBookIcon /> Add To My Smartbook
              </Typography>
              <Box className="black-details">
                <Box className="tooltip-details">
                  <Typography
                    className="tooltip-txt"
                    onClick={() =>
                      handleBlackBookModal(
                        teamType === "hometeam"
                          ? item?.homeTeam?.name
                          : item?.awayTeam?.name,
                        teamType === "hometeam"
                          ? item?.homeTeam?.id
                          : item?.awayTeam?.id,
                        "Team",
                        item
                      )
                    }
                  >
                    <span>
                      {" "}
                      Team:{" "}
                      {teamType === "hometeam"
                        ? item?.homeTeam?.name
                        : item?.awayTeam?.name}
                    </span>
                    {(teamType === "hometeam" ? (
                      Boolean(item?.isSmartBookHomeTeam)
                    ) : (
                      Boolean(item?.isSmartBookAwayTeam)
                    )) ? (
                      <RightBlack />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )}
                    {/* {(teamType === "hometeam" &&
                      item?.isSmartBookAwayTeam === true) ||
                    (teamType === "awayteam" &&
                      item?.isSmartBookHomeTeam === true) ? (
                      <RightBlack className="icon-blackbook" />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )} */}
                  </Typography>
                </Box>

                <Box className="tooltip-details">
                  <Typography
                    className="tooltip-txt"
                    onClick={() =>
                      handleBlackBookModal(
                        tournamentName,
                        tournamentId,
                        "Tournament",
                        item
                      )
                    }
                  >
                    <span>
                      League: {tournamentName ? tournamentName : "N/A"}
                    </span>
                    {Boolean(item?.isSmartBookTournament) ? (
                      <RightBlack />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const handleShareTooltipContentClick = e => {
    e.stopPropagation();
    setShareTooltipOpen(!shareTooltipOpen);
  };

  const handleShareOutsideClick = () => {
    if (shareTooltipOpen) {
      setShareTooltipOpen(false);
    }
  };

  const handleShareOpenTooltip = () => {
    const link =
      Config.baseURL.replace("/api", "") +
      `tipscompetition/private-comps?code=${eventByIdData?.code}`;
    return (
      <Box
        className="comp-custom-tooltip right-sec"
        onClick={e => handleShareTooltipContentClick(e)}
      >
        {/* <Box> */}
        <Button className="share-btn" endIcon={<Share />}>
          Share
        </Button>
        {/* </Box> */}
        {shareTooltipOpen && (
          <Box className="tooltip share-tooltip">
            <Box style={{ position: "relative" }}>
              <TooltipArrow className="tooltip-arrow share-tooltip-arrow" />
            </Box>
            <Box className="share-option">
              <Box className="social-option-box">
                <Box className="option">
                  <CopyToClipboard text={link} onCopy={handleShareCopy}>
                    <Box className="cursor-pointer">
                      <img src={Copy} alt="Copy" />
                      <Typography className="option-name">Copy Link</Typography>
                    </Box>
                  </CopyToClipboard>
                </Box>
                <Box className="option">
                  <WhatsappShareButton url={link}>
                    <img src={Wp} alt="Whatsapp" />
                  </WhatsappShareButton>
                  <Typography className="option-name">Whatsapp</Typography>
                </Box>
                <Box className="option">
                  <FacebookShareButton url={link}>
                    <img src={Facebook} alt="Facebook" />
                  </FacebookShareButton>
                  <Typography className="option-name">Facebook</Typography>
                </Box>
                <Box className="option cursor-pointer">
                  <img
                    src={Messenger}
                    alt="Messenger"
                    className="social-icon"
                    onClick={() => shareOnMessenger(link)}
                  />
                  <Typography className="option-name">Messenger</Typography>
                </Box>
                <Box className="option">
                  <TwitterShareButton url={link}>
                    <img src={Twitter_X} alt="Twitter_X" />
                  </TwitterShareButton>
                  <Typography className="option-name">Twitter</Typography>
                </Box>
                <Box className="option">
                  <LinkedinShareButton url={link}>
                    <img src={Linkedin} alt="Linkedin" />
                  </LinkedinShareButton>
                  <Typography className="option-name">Linkedin</Typography>
                </Box>
                {/* <Box className="option">
                    <img src={Snapchat} alt="Snapchat" />
                    <Typography className="option-name">Snapchat</Typography>
                  </Box>
                  <Box className="option">
                    {" "}
                    <img src={Message} alt="Message" />
                    <Typography className="option-name">Message</Typography>
                  </Box> */}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const handleShareCopy = () => {
    toast.success("Link Copied Successfully", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored"
    });
  };

  const handleShareTipTooltipContentClick = e => {
    e.stopPropagation();
    setShareTipTooltipOpen(!shareTipTooltipOpen);
  };

  const handleShareTipOutsideClick = () => {
    if (shareTipTooltipOpen) {
      setShareTipTooltipOpen(false);
    }
  };

  const handleOpenShareTooltip = () => {
    const link =
      Config.baseURL.replace("/api", "") +
      `tipscompetition/share-tips?round=${stepperCount}&compId=${Number(
        params?.compsId
      )}&referral_type=tippingComp&referral=${
        redux_userData?.referralCode
      }&sportId=${Number(params?.SportId)}&tournamentId=${Number(
        params?.tournamentId
      )}`;
    return (
      <Box
        className="comp-custom-tooltip right-sec"
        onClick={e => handleShareTipTooltipContentClick(e)}
      >
        {/* <Box> */}
        <Button className="share-btn mt-0" endIcon={<Share />}>
          Share
        </Button>
        {/* </Box> */}
        {shareTipTooltipOpen && (
          <Box className="tooltip">
            <Box style={{ position: "relative" }}>
              <TooltipArrow className="tooltip-arrow" />
            </Box>
            <Box className="share-option">
              <Box className="social-option-box">
                <Box className="option">
                  <CopyToClipboard text={link} onCopy={handleShareCopy}>
                    <Box className="cursor-pointer">
                      <img src={Copy} alt="Copy" />
                      <Typography className="option-name">Copy Link</Typography>
                    </Box>
                  </CopyToClipboard>
                </Box>
                <Box className="option">
                  <WhatsappShareButton url={link}>
                    <img src={Wp} alt="Whatsapp" />
                  </WhatsappShareButton>
                  <Typography className="option-name">Whatsapp</Typography>
                </Box>
                <Box className="option">
                  <FacebookShareButton url={link}>
                    <img src={Facebook} alt="Facebook" />
                  </FacebookShareButton>
                  <Typography className="option-name">Facebook</Typography>
                </Box>
                <Box className="option cursor-pointer">
                  <img
                    src={Messenger}
                    alt="Messenger"
                    className="social-icon"
                    onClick={() => shareOnMessenger(link)}
                  />
                  <Typography className="option-name">Messenger</Typography>
                </Box>
                <Box className="option">
                  <TwitterShareButton url={link}>
                    <img src={Twitter_X} alt="Twitter_X" />
                  </TwitterShareButton>
                  <Typography className="option-name">Twitter</Typography>
                </Box>
                <Box className="option">
                  <LinkedinShareButton url={link}>
                    <img src={Linkedin} alt="Linkedin" />
                  </LinkedinShareButton>
                  <Typography className="option-name">Linkedin</Typography>
                </Box>
                {/* <Box className="option">
                    <img src={Snapchat} alt="Snapchat" />
                    <Typography className="option-name">Snapchat</Typography>
                  </Box>
                  <Box className="option">
                    {" "}
                    <img src={Message} alt="Message" />
                    <Typography className="option-name">Message</Typography>
                  </Box> */}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const handleShareTipsTooltipContentClick = e => {
    e.stopPropagation();
    setShareTooltipOpen(!shareTooltipOpen);
  };

  const handleShareTipsOutsideClick = () => {
    if (shareTooltipOpen) {
      setShareTooltipOpen(false);
    }
  };

  const handleOpenShareTipsTooltip = () => {
    const link =
      Config.baseURL.replace("/api", "") +
      `tipscompetition/share-tips?round=${stepperCount}&compId=${Number(
        params?.compsId
      )}&referral_type=tippingComp&referral=${
        redux_userData?.referralCode
      }&sportId=${Number(params?.SportId)}&tournamentId=${Number(
        params?.tournamentId
      )}`;
    return (
      <Box
        className="comp-custom-tooltip right-sec"
        onClick={e => handleShareTipsTooltipContentClick(e)}
      >
        {/* <Box> */}
        <Button className="share-btn" endIcon={<Share />}>
          Share
        </Button>
        {/* </Box> */}
        {shareTooltipOpen && (
          <Box className="tooltip">
            <Box style={{ position: "relative" }}>
              <TooltipArrow className="tooltip-arrow" />
            </Box>
            <Box className="share-option">
              <Box className="social-option-box">
                <Box className="option">
                  <CopyToClipboard text={link} onCopy={handleShareCopy}>
                    <Box className="cursor-pointer">
                      <img src={Copy} alt="Copy" />
                      <Typography className="option-name">Copy Link</Typography>
                    </Box>
                  </CopyToClipboard>
                </Box>
                <Box className="option">
                  <WhatsappShareButton url={link}>
                    <img src={Wp} alt="Whatsapp" />
                  </WhatsappShareButton>
                  <Typography className="option-name">Whatsapp</Typography>
                </Box>
                <Box className="option">
                  <FacebookShareButton url={link}>
                    <img src={Facebook} alt="Facebook" />
                  </FacebookShareButton>
                  <Typography className="option-name">Facebook</Typography>
                </Box>
                <Box className="option cursor-pointer">
                  <img
                    src={Messenger}
                    alt="Messenger"
                    className="social-icon"
                    onClick={() => shareOnMessenger(link)}
                  />
                  <Typography className="option-name">Messenger</Typography>
                </Box>
                <Box className="option">
                  <TwitterShareButton url={link}>
                    <img src={Twitter_X} alt="Twitter_X" />
                  </TwitterShareButton>
                  <Typography className="option-name">Twitter</Typography>
                </Box>
                <Box className="option">
                  <LinkedinShareButton url={link}>
                    <img src={Linkedin} alt="Linkedin" />
                  </LinkedinShareButton>
                  <Typography className="option-name">Linkedin</Typography>
                </Box>
                {/* <Box className="option">
                    <img src={Snapchat} alt="Snapchat" />
                    <Typography className="option-name">Snapchat</Typography>
                  </Box>
                  <Box className="option">
                    {" "}
                    <img src={Message} alt="Message" />
                    <Typography className="option-name">Message</Typography>
                  </Box> */}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const shareOnMessenger = link => {
    if (window.FB) {
      window.FB.ui({
        method: "send", // share in facebook post and send in facebook messenger
        link: link // link in facebook messenger and href in facebook post
      });
    } else {
      console.error("Facebook SDK not loaded.");
    }
  };

  const handleModal = () => {
    setSBOpen(true);
  };

  const handleClose = () => {
    setSBOpen(false);
  };
  const handleBlackBookModal = (title, id, type, item) => {
    setSelectedTitle(title);
    setSelectedType(type);
    setSelectedId(id);
    setSelectedSportId(item?.SportId);
    handleModal();
  };

  const handleCheckboxClick = (event, item, teamType) => {
    const eventList = allEventData?.map(obj => {
      if (obj?.id == item?.id) {
        if (teamType === "hometeam") {
          return {
            ...obj,
            awayTeam: {
              ...obj?.awayTeam,
              isTip: event.target.checked ? 0 : obj?.awayTeam?.isTip
            },
            homeTeam: {
              ...obj?.homeTeam,
              isTip: event.target.checked ? 1 : 0
            }
          };
        } else {
          return {
            ...obj,
            awayTeam: {
              ...obj?.awayTeam,
              isTip: event.target.checked ? 1 : 0
            },
            homeTeam: {
              ...obj?.homeTeam,
              isTip: event.target.checked ? 0 : obj?.homeTeam?.isTip
            }
          };
        }
      } else {
        return obj;
      }
    });
    if (handleValidate(eventList)) {
      setAtLeastOneError("");
    }
    convetToGroupData(eventList);
    setAllEventData(eventList);
  };
  const handleValidate = tipdata => {
    const isTipSelected = tipdata?.some(
      item => item?.awayTeam?.isTip || item?.homeTeam?.isTip
    );
    return isTipSelected;
  };
  const handleSubmit = async () => {
    if (handleValidate(eventsData)) {
      setAtLeastOneError("");
      let payload = {};
      const finalPayload = allEventData?.map((item, index) => {
        payload = {
          round: stepperCount,
          teamId:
            item?.awayTeam?.isTip == 1
              ? item?.awayTeamId
              : item?.homeTeam?.isTip == 1
              ? item?.homeTeamId
              : null,
          eventId: item?.id,
          SportId: item?.SportId
        };
        payload = index === 0 ? { ...payload, margin: counts } : payload;
        return payload;
      });
      const passPayload = {
        jocker: jokerRound ? jokerRound : isJoker ? stepperCount : null,
        events: finalPayload
      };
      try {
        const { status, data } = await axiosInstance.put(
          `tipping/addEvent/${Number(params?.compsId)}`,
          passPayload
        );
        if (status === 200) {
          toast.success(data?.message, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored"
          });
          // navigate("/tipscompetition/public/tips");
          getEventByID(stepperCount);
          setIsSubmit(true);
        } else {
        }
      } catch (err) {
        toast.success(err?.response?.data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        console.log("error", err);
      }
    } else {
      setAtLeastOneError("Please select atleast one tip");
    }
  };

  const handleReset = () => {
    const eventList = allEventData?.map(obj => {
      return {
        ...obj,
        awayTeam: {
          ...obj?.awayTeam,
          isTip: 0
        },
        homeTeam: {
          ...obj?.homeTeam,
          isTip: 0
        }
      };
    });
    convetToGroupData(eventList);
    setAllEventData(eventList);
    setCounts(0);
  };
  const fetchDayandTime = () => {
    if (eventByIdData?.cutOffTime) {
      const dayWithTime = moment
        .utc(
          `${eventByIdData?.cutOffWeek} ${eventByIdData?.cutOffTime}`,
          "dddd HH:mm:ss"
        )
        .tz(timezone)
        .format("h:mm A");
      return (
        <span>
          {" "}
          {eventByIdData?.cutOffWeek +
            " " +
            moment(cutoffDate).format("DD/MM/YYYY") +
            " | " +
            dayWithTime}
        </span>
      );
    }
  };
  const Renderer = ({ days, hours, minutes, seconds }) => {
    return null;
  };

  const fetchjokerBox = () => {
    if ((jokerRound !== 0 && !jokerRound) || jokerRound == stepperCount) {
      if (
        (lastDate > moment() && eventByIdData?.tipDeadline === "round") ||
        (moment(lastEventDate) > moment() &&
          eventByIdData?.tipDeadline === "game") ||
        !completedRound?.includes(Number(stepperCount)) ||
        jokerRound == stepperCount
      ) {
        return (
          <Box className="joker-box">
            <Box className="top-sec">
              <Typography className="joker-txt">Joker Round</Typography>
            </Box>
            <Box className="bottom-sec">
              <Box>
                <FormControl>
                  <label>
                    <Checkbox
                      icon={<Uncheck />}
                      checkedIcon={<BlueCheck />}
                      checked={isJoker}
                      onChange={e => setIsJoker(e?.target?.checked)}
                      disabled={
                        (lastDate < moment() &&
                          eventByIdData?.tipDeadline === "round") ||
                        (moment(lastEventDate) < moment().tz(timezone) &&
                          eventByIdData?.tipDeadline === "game") ||
                        jokerRound ||
                        jokerRound == 0 ||
                        completedRound?.includes(Number(stepperCount))
                      }
                    />
                    {/* {filter?.icon} */}
                  </label>
                </FormControl>
              </Box>
              <Box>
                <Typography className="select-txt">
                  Select Joker Round
                </Typography>
                <Typography className="detail-txt">
                  You are allowed to select Joker Round once per season.
                  Selecting a Joker will double your round points.
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      }
    } else {
      return null;
    }
  };

  const aboutLines = eventByIdData?.about?.split("\n");
  return (<>
    <Box className="content-wrap">
      <Box className="mobile-banner">
        {fetchPageHeaderAds(
          "218px",
          Config?.release == "IN" ? bannerPageHeader : banner
        )}
      </Box>

      <Box className="comp-tips-wrap">
        <Box className="comp-tips-header">
          <Box className="bredcrumn-deatils">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  HOME
                </Link>

                <Link
                  underline="hover"
                  color="inherit"
                  to=""
                  className="cursor-default"
                >
                  Tipping Competition
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  to="/tipscompetition/public/tips"
                  className="cursor-default"
                >
                  Public Comps
                </Link>

                <Typography>
                  {eventByIdData && eventByIdData?.competitionName}
                </Typography>
              </Breadcrumbs>
            </Box>
          </Box>
          <Box className="comp-details-wrap">
            <Box className="title-about-wrap">
              <Typography variant="h1">
                {eventByIdData && eventByIdData?.competitionName}
              </Typography>
              {screenWidth < 800 && (
                <Box className="comp-share-btn-wrap">
                  <ClickAwayListener
                    onClickAway={() => handleShareOutsideClick()}
                  >
                    <Box className="share-tooltip-icon">
                      {handleShareOpenTooltip()}
                    </Box>
                  </ClickAwayListener>
                  {/* <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="share-btn"
                    endIcon={<Share />}
                  >
                    Share
                  </Button> */}
                </Box>
              )}
              {eventByIdData?.about && (
                <Box className="about-wrap">
                  <span className="about-label">About This Comp:</span>
                  <span className="about-des">
                    {/* {eventByIdData && eventByIdData?.about} */}
                    {aboutLines?.map((line, index) => (
                      <p key={index} className="about-line">
                        {line} <br />
                      </p>
                    ))}
                  </span>
                </Box>
              )}
            </Box>
            {screenWidth < 800 && (
              <Box className="comp-img-wrap">
                <img
                  src={
                    eventByIdData?.Media?.filePath
                      ? Config.countryMediaURL +
                        eventByIdData?.Media?.filePath
                      : DefaultImg
                  }
                  alt="comp-image"
                />
              </Box>
            )}
            {screenWidth > 799 && (
              <Box className="comp-img-share-wrap">
                <Box className="comp-img-wrap">
                  <img
                    src={
                      eventByIdData?.Media?.filePath
                        ? Config.countryMediaURL +
                          eventByIdData?.Media?.filePath
                        : DefaultImg
                    }
                    alt="comp-image"
                  />
                </Box>
                <Box className="comp-share-btn-wrap">
                  <ClickAwayListener
                    onClickAway={() => handleShareOutsideClick()}
                  >
                    <Box className="share-tooltip-icon">
                      {handleShareOpenTooltip()}
                    </Box>
                  </ClickAwayListener>
                  {/* <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="share-btn"
                  endIcon={<Share />}
                >
                  Share
                </Button> */}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box className="tab-search-section">
          {/* <Box className="filter-section">
            <Select
              className="React sort-select"
              value={sports?.find(item => {
                return item?.value === selectedSports;
              })}
              onChange={e => {
                setSelectedSports(e?.value == 0 ? "" : e?.value);
                setCurrentPage(0);
                setSelectedTeams(null);
                setSelectedOrg(null);
                setTeamsAll([]);
                setOrgAll([]);
                if (!e?.isAll) {
                  fetchOrgData(0, e?.value, []);
                  // fetchTeamData(0, e?.value, []);
                }
              }}
              // onMenuScrollToBottom={e => handleOnScrollBottomSports(e)}
              options={sports}
              classNamePrefix="select"
              placeholder="Select sport"
              // isSearchable={false}
              // onFocus={() => setTournamentSelect(true)}
              // onBlur={() => setTournamentSelect(false)}
              components={{ DropdownIndicator }}
            />
            <Select
              className="React sort-select"
              onMenuScrollToBottom={e => handleOnScrollBottomOrg(e)}
              onInputChange={e => handleOrgInputChange(0, e, selectedSports)}
              onChange={e => {
                setSelectedOrg(e?.value);
                // fetchOrgData(e?.value, selectedSports, OrgAll);
                setSelectedTeams(null);
                setOrgApiCount(0);
                setpageOrg(0);
                setisOrgSelectOpen(false);
                setCurrentPage(0);
              }}
              onFocus={() => setisOrgSelectOpen(true)}
              onBlur={() => setisOrgSelectOpen(false)}
              value={
                selectedOrg &&
                (isOrgSearch
                  ? searchOrg?.find(item => {
                      return item?.value == selectedOrg;
                    })
                  : OrgAll?.find(item => {
                      return item?.value == selectedOrg;
                    }))
              }
              options={isOrgSearch ? searchOrg : OrgAll}
              classNamePrefix="select"
              placeholder="Select league"
              isDisabled={
                selectedSports && selectedSports.length > 0 ? true : false
              }
              components={{ DropdownIndicator }}
            />
          </Box> */}
          <Box className="stepper-score-sec">
            <Box className="stepper-input">
              <Button
                className="stepper-input__button"
                onClick={e => {
                  e.preventDefault();
                  const index = rounds?.indexOf(stepperCount);
                  setStepperCount(rounds[index - 1]);
                  // getEventByID(rounds[index - 1]);
                  setCounts(0);
                }}
                disabled={!stepperCount || stepperCount == rounds[0]}
                startIcon={<LeftArrow />}
              >
                Previous
              </Button>

              <div className="stepper-input__content">
                {eventByIdData?.SportId == 4
                  ? stepperCount
                    ? `Day ${Number(stepperCount)}`
                    : `Day 0`
                  : stepperCount
                  ? `Round ${Number(stepperCount)}`
                  : `Round 0`}
              </div>

              <Button
                className="stepper-input__button Next-btn"
                onClick={e => {
                  e.preventDefault();
                  const index = rounds?.indexOf(stepperCount);
                  e.preventDefault();
                  setStepperCount(rounds[index + 1]);
                  // getEventByID(rounds[index + 1]);
                  setCounts(0);
                }}
                disabled={
                  stepperCount == null ||
                  stepperCount == rounds[rounds?.length - 1]
                }
                endIcon={<RightArrow />}
              >
                Next
              </Button>
            </Box>

            <Box className="score-share-sec">
              <Box className="score-sec">
                {/* <Box className="round-score-sec">
                  <Box className="round-score">
                    <Typography className="score">Last round:</Typography>
                    <Typography className="score">0/6</Typography>
                  </Box>
                  <Box className="round-score">
                    <Typography className="score">Total score:</Typography>
                    <Typography className="score">0/0</Typography>
                  </Box>
                </Box> */}
                {!Boolean(isLoading) && (
                  <Box className="bottom-line-sec">
                    <Typography className="final-txt">
                      {eventByIdData?.SportId == 4 ? "Day" : "Round"}{" "}
                      {Number(stepperCount)}
                      {eventByIdData && eventByIdData?.tipDeadline == "game"
                        ? " Game Based"
                        : ` tipping closes at:`}
                      {eventByIdData &&
                        eventByIdData?.tipDeadline == "round" && (
                          <span>{fetchDayandTime()}</span>
                        )}
                    </Typography>
                    {/* {eventByIdData?.jokerRound == 1 && (
                    <Box className="joker-switch-box">
                      <FormControlLabel
                        value="start"
                        control={
                          <Switch
                            color="primary"
                            checked={isJoker}
                            onChange={e => setIsJoker(e.target.checked)}
                          />
                        }
                        disabled={jokerRound}
                        label="Joker"
                        labelPlacement="start"
                      />
                    </Box>
                  )} */}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="page-deatils-wrap padding-container">
          {fetchAds(
            1,
            "100%",
            Config?.release == "IN" ? BannerIndia : tippingAbove
          )}
          {isLoading ? (
            <div className="allsport-loader-center app-loader">
              <Loader />
            </div>
          ) : (
            <>
              {allEventData && allEventData?.length > 0 ? (
                <>
                  {Object.entries(groupedEventData)?.map(
                    ([date, tips], parentIndex) => {
                      return (
                        (<Box className="tips-sec" key={date}>
                          <Box className="title-date-sec">
                            <Typography className="title-date">
                              {date ? fetchDayName(date) : ""}{" "}
                              {date
                                ? moment
                                    .utc(date)
                                    .local()
                                    .format("DD/MM/YYYY")
                                : ""}
                            </Typography>
                          </Box>
                          {tips?.map((tip, index) => {
                            const dateTocheck =
                              eventByIdData?.tipDeadline == "game"
                                ? moment(tip?.startTime)
                                : lastDate;
                            const IsScore =
                              eventLength === 0
                                ? tip?.awayScore && tip?.homeScore
                                  ? true
                                  : false
                                : Number(params?.SportId) != 4
                                ? Boolean(tip?.homeScore ?? false)
                                : tip?.ScoreBoard
                                ? Object.keys(tip?.ScoreBoard)?.length > 0 &&
                                  Boolean(tip?.ScoreBoard?.Epr == 2)
                                : false;
                            return (<>
                              <Box className="comp-tip-data-sec">
                                <Typography className="time-venue-txt">
                                  {tip?.startTime
                                    ? moment
                                        .utc(tip?.startTime)
                                        .local()
                                        .format("hh:mm A")
                                    : ""}{" "}
                                  <span>
                                    {tip?.venue ? "|" + tip?.venue : ""}
                                  </span>
                                </Typography>
                                <Box
                                  className={
                                    eventLength === 0
                                      ? `comp-odds-tips-sec`
                                      : tip?.homeScore ||
                                        (tip?.ScoreBoard &&
                                          Object.keys(tip?.ScoreBoard)
                                            ?.length > 0 &&
                                          tip?.ScoreBoard?.Epr == 2)
                                      ? `comp-odds-tips-sec `
                                      : tip?.winnerCode == 3 ||
                                        (tip?.winnerCode === null &&
                                          tip?.status === null &&
                                          moment
                                            .utc(tip?.startTime)
                                            .tz(timezone) <
                                            moment().tz(timezone))
                                      ? `comp-odds-tips-sec indefinite-result no-result`
                                      : `comp-odds-tips-sec no-result`
                                  }
                                >
                                  <Box className="left-sec">
                                    <Box className="team-wrap">
                                      <Box className="team-img-wrap">
                                        <img
                                          className="team-img"
                                          src={
                                            tip?.homeTeam?.flag?.includes(
                                              "uploads"
                                            )
                                              ? Config.mediaURL +
                                                tip?.homeTeam?.flag
                                              : Melbourne
                                          }
                                          alt="team"
                                        />
                                      </Box>
                                      <Box className="comp-name-tip-wrap">
                                        <Typography className="team-name">
                                          {tip?.homeTeam?.name}
                                        </Typography>
                                        <ClickAwayListener
                                          onClickAway={() =>
                                            handleOutsideClick(
                                              tip,
                                              tip?.homeTeamId,
                                              index,
                                              parentIndex
                                            )
                                          }
                                        >
                                          <Box className="blackbook-icon">
                                            {handleOpenTooltip(
                                              tip,
                                              tip?.homeTeamId,
                                              index,
                                              "hometeam",
                                              parentIndex
                                            )}
                                          </Box>
                                        </ClickAwayListener>
                                      </Box>
                                    </Box>

                                    {release[Config.release]?.oddsCheck ? (
                                      <SportsOdds
                                        data={tip}
                                        type={"odds"}
                                        team={"hometeam"}
                                        teamId={tip?.homeTeamId}
                                        isScore={IsScore}
                                        BookkeeperData={BookkeeperData}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    {/* // ) : ( */}
                                    {eventLength !== 0 && (
                                      <>
                                        {Number(params?.SportId) != 4 ? (
                                          tip?.homeScore ? (
                                            <Box className="blue-score">
                                              <Typography className="score">
                                                {tip?.homeScore?.current ??
                                                  0}
                                              </Typography>
                                            </Box>
                                          ) : (
                                            <></>
                                          )
                                        ) : tip?.ScoreBoard &&
                                          Object.keys(tip?.ScoreBoard)
                                            ?.length > 0 &&
                                          tip?.ScoreBoard?.Epr == 2 ? (
                                          <Box className="blue-score">
                                            {tip?.ScoreBoard?.Exd > "1" ? (
                                              <Typography className="score">
                                                {tip?.ScoreBoard?.Tr1C1 ??
                                                  "-"}
                                                {tip?.ScoreBoard?.Tr1CW1 &&
                                                tip?.ScoreBoard?.Tr1CW1 ===
                                                  10
                                                  ? ""
                                                  : (tip?.ScoreBoard
                                                      ?.Tr1CW1 ||
                                                      tip?.ScoreBoard
                                                        ?.Tr1CW1 === 0) &&
                                                    `/${
                                                      tip?.ScoreBoard
                                                        ?.Tr1CW1
                                                    }${
                                                      tip?.ScoreBoard
                                                        ?.Tr1CD1 &&
                                                      tip?.ScoreBoard
                                                        ?.Tr1CD1 === 1
                                                        ? "d"
                                                        : ""
                                                    }`}{" "}
                                                {(tip?.ScoreBoard?.Tr1C2 ||
                                                  tip?.ScoreBoard?.Tr1C2 ===
                                                    0) &&
                                                  `& ${tip?.ScoreBoard?.Tr1C2}`}
                                                {tip?.ScoreBoard?.Tr1CW2 &&
                                                tip?.ScoreBoard?.Tr1CW2 ===
                                                  10
                                                  ? ""
                                                  : (tip?.ScoreBoard
                                                      ?.Tr1CW2 ||
                                                      tip?.ScoreBoard
                                                        ?.Tr1CW2 === 0) &&
                                                    `/${
                                                      tip?.ScoreBoard
                                                        ?.Tr1CW2
                                                    }${
                                                      tip?.ScoreBoard
                                                        ?.Tr1CD2 &&
                                                      tip?.ScoreBoard
                                                        ?.Tr1CD2 === 1
                                                        ? "d"
                                                        : ""
                                                    }`}{" "}
                                              </Typography>
                                            ) : (
                                              <Typography className="score">
                                                {tip?.ScoreBoard?.Tr1C1 ??
                                                  "-"}
                                                {tip?.ScoreBoard?.Tr1CW1 &&
                                                tip?.ScoreBoard?.Tr1CW1 ===
                                                  10
                                                  ? ""
                                                  : (tip?.ScoreBoard
                                                      ?.Tr1CW1 ||
                                                      tip?.ScoreBoard
                                                        ?.Tr1CW1 === 0) &&
                                                    `/${tip?.ScoreBoard?.Tr1CW1}`}{" "}
                                              </Typography>
                                            )}
                                          </Box>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                    <Box className="check-box-sec">
                                      <FormControl>
                                        <label>
                                          <Checkbox
                                            icon={
                                              tip?.winnerCode == 3 ? (
                                                <Notcheck />
                                              ) : dateTocheck < moment() &&
                                                tip?.homeTeam?.isTip !==
                                                  1 &&
                                                tip?.awayTeam?.isTip !==
                                                  1 ? (
                                                <Notcheck />
                                              ) : (
                                                <Uncheck />
                                              )
                                            }
                                            checkedIcon={
                                              tip?.winnerCode ? (
                                                tip?.winnerCode == 1 ? (
                                                  <Check />
                                                ) : (
                                                  <Notcheck />
                                                )
                                              ) : (
                                                <Check />
                                              )
                                            }
                                            disabled={
                                              dateTocheck < moment() ||
                                              Boolean(tip?.winnerCode)
                                            }
                                            // name="filter"
                                            value="homeTeamId"
                                            onChange={event => {
                                              // setSelectedIds({
                                              //   ...selectedIds,
                                              //   [tip?.id]:
                                              //     selectedIds[tip?.id] ===
                                              //     tip?.homeTeamId
                                              //       ? null
                                              //       : tip?.homeTeamId
                                              // });
                                              handleCheckboxClick(
                                                event,
                                                tip,
                                                "hometeam"
                                              );
                                            }}
                                            checked={
                                              tip?.homeTeam?.isTip == 1
                                            }
                                          />
                                          {/* {filter?.icon} */}
                                        </label>
                                      </FormControl>
                                    </Box>
                                  </Box>
                                  <Box className="vs">VS</Box>
                                  <Box className="left-sec flex-rr">
                                    <Box className="team-wrap">
                                      <Box className="team-img-wrap">
                                        <img
                                          className="team-img"
                                          src={
                                            tip?.awayTeam?.flag?.includes(
                                              "uploads"
                                            )
                                              ? Config.mediaURL +
                                                tip?.awayTeam?.flag
                                              : Brisbane
                                          }
                                          alt="team"
                                        />
                                      </Box>
                                      <Box className="comp-name-tip-wrap">
                                        <Typography className="team-name">
                                          {tip?.awayTeam?.name}
                                        </Typography>
                                        <ClickAwayListener
                                          onClickAway={() =>
                                            handleOutsideClick(
                                              tip,
                                              tip?.awayTeamId,
                                              index,
                                              parentIndex
                                            )
                                          }
                                        >
                                          <Box className="blackbook-icon">
                                            {handleOpenTooltip(
                                              tip,
                                              tip?.awayTeamId,
                                              index,
                                              "awayteam",
                                              parentIndex
                                            )}
                                          </Box>
                                        </ClickAwayListener>
                                      </Box>
                                    </Box>
                                    {/* {eventLength === 0 ? ( */}
                                    {release[Config.release]?.oddsCheck ? (
                                      // <Box className="odds-wrap odd-rr">
                                      //   {fetchCurrentBestOdds(
                                      //     tip,
                                      //     null,
                                      //     "awayteam",
                                      //     tip?.awayTeamId,
                                      //     true
                                      //   )}

                                      //   {fetchCurrentBestsOddsIcon(
                                      //     tip,
                                      //     null,
                                      //     "awayteam",
                                      //     tip?.awayTeamId
                                      //   )}
                                      // </Box>
                                      (<SportsOdds
                                        data={tip}
                                        type={"odds"}
                                        team={"awayteam"}
                                        teamId={tip?.awayTeamId}
                                        isScore={IsScore}
                                      />)
                                    ) : (
                                      <></>
                                    )}
                                    {/* ) : ( */}
                                    {eventLength !== 0 && (
                                      <>
                                        {Number(params?.SportId) != 4 ? (
                                          tip?.awayScore ? (
                                            <Box className="blue-score">
                                              <Typography className="score">
                                                {tip?.awayScore?.current ??
                                                  0}
                                              </Typography>
                                            </Box>
                                          ) : (
                                            <></>
                                          )
                                        ) : tip?.ScoreBoard &&
                                          Object.keys(tip?.ScoreBoard)
                                            ?.length > 0 &&
                                          tip?.ScoreBoard?.Epr == 2 ? (
                                          <Box className="blue-score">
                                            {tip?.ScoreBoard?.Exd > "1" ? (
                                              <Typography className="score">
                                                {tip?.ScoreBoard?.Tr2C1 ??
                                                  "-"}
                                                {tip?.ScoreBoard?.Tr2CW1 &&
                                                tip?.ScoreBoard?.Tr2CW1 ===
                                                  10
                                                  ? ""
                                                  : (tip?.ScoreBoard
                                                      ?.Tr2CW1 ||
                                                      tip?.ScoreBoard
                                                        ?.Tr2CW1 === 0) &&
                                                    `/${
                                                      tip?.ScoreBoard
                                                        ?.Tr2CW1
                                                    }${
                                                      tip?.ScoreBoard
                                                        ?.Tr2CD1 &&
                                                      tip?.ScoreBoard
                                                        ?.Tr2CD1 === 1
                                                        ? "d"
                                                        : ""
                                                    }`}{" "}
                                                {(tip?.ScoreBoard?.Tr2C2 ||
                                                  tip?.ScoreBoard?.Tr2C2 ===
                                                    0) &&
                                                  `& ${tip?.ScoreBoard?.Tr2C2}`}
                                                {tip?.ScoreBoard?.Tr2CW2 &&
                                                tip?.ScoreBoard?.Tr2CW2 ===
                                                  10
                                                  ? ""
                                                  : (tip?.ScoreBoard
                                                      ?.Tr2CW2 ||
                                                      tip?.ScoreBoard
                                                        ?.Tr2CW2 === 0) &&
                                                    `/${
                                                      tip?.ScoreBoard
                                                        ?.Tr2CW2
                                                    }${
                                                      tip?.ScoreBoard
                                                        ?.Tr2CD2 &&
                                                      tip?.ScoreBoard
                                                        ?.Tr2CD2 === 1
                                                        ? "d"
                                                        : ""
                                                    }`}{" "}
                                              </Typography>
                                            ) : (
                                              <Typography className="score">
                                                {tip?.ScoreBoard?.Tr2C1 ??
                                                  "-"}
                                                {tip?.ScoreBoard?.Tr2CW1 &&
                                                tip?.ScoreBoard?.Tr2CW1 ===
                                                  10
                                                  ? ""
                                                  : (tip?.ScoreBoard
                                                      ?.Tr2CW1 ||
                                                      tip?.ScoreBoard
                                                        ?.Tr2CW1 === 0) &&
                                                    `/ ${tip?.ScoreBoard?.Tr2CW1}`}{" "}
                                              </Typography>
                                            )}
                                          </Box>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                    <Box className="check-box-sec">
                                      <FormControl>
                                        <label>
                                          <Checkbox
                                            icon={
                                              tip?.winnerCode == 3 ? (
                                                <Notcheck />
                                              ) : dateTocheck < moment() &&
                                                tip?.awayTeam?.isTip !==
                                                  1 &&
                                                tip?.homeTeam?.isTip !==
                                                  1 ? (
                                                <Notcheck />
                                              ) : (
                                                <Uncheck />
                                              )
                                            }
                                            checkedIcon={
                                              tip?.winnerCode ? (
                                                tip?.winnerCode == 2 ? (
                                                  <Check />
                                                ) : (
                                                  <Notcheck />
                                                )
                                              ) : (
                                                <Check />
                                              )
                                            }
                                            disabled={
                                              dateTocheck < moment() ||
                                              Boolean(tip?.winnerCode)
                                            }
                                            value="awayTeamId"
                                            onChange={event => {
                                              handleCheckboxClick(
                                                event,
                                                tip,
                                                "awayteam"
                                              );
                                            }}
                                            checked={
                                              tip?.awayTeam?.isTip == 1
                                            }
                                          />
                                          {/* {filter?.icon} */}
                                        </label>
                                      </FormControl>
                                    </Box>
                                  </Box>
                                </Box>
                                {parentIndex == 0 &&
                                  index == 0 &&
                                  eventByIdData?.marginRound == 1 && (
                                    <>
                                      <Box className="margin-sec">
                                        <Typography className="margin-txt">
                                          {params?.sports == "cricket"
                                            ? "Number of 6s"
                                            : "Margin"}
                                        </Typography>
                                      </Box>
                                      <Box className="counter-score-sec">
                                        <Box className="counter-input">
                                          <Button
                                            disableFocusRipple
                                            disableRipple
                                            className="counter-input__button"
                                            onClick={e => {
                                              e.preventDefault();
                                              handleDecrement(
                                                tip?.id,
                                                index
                                              );
                                            }}
                                            disabled={
                                              counts == 0 ||
                                              dateTocheck < moment()
                                            }
                                            // startIcon={<LeftArrow />}
                                          >
                                            {/* <Minus /> */}
                                            <MyMinusNutton />
                                          </Button>
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            className="margin-textfield"
                                            name="compName"
                                            onChange={e => {
                                              const input =
                                                e?.target?.value;
                                              const numericInput = input?.replace(
                                                /[^0-9]/g,
                                                ""
                                              );
                                              const removeLeadingZeros = numericInput => {
                                                if (
                                                  numericInput.length >= 2
                                                ) {
                                                  const result = parseInt(
                                                    numericInput,
                                                    10
                                                  ).toString();
                                                  return result;
                                                }
                                                return numericInput;
                                              };
                                              const finalMargin = removeLeadingZeros(
                                                numericInput
                                              );

                                              setCounts(
                                                finalMargin
                                                  ? Number(finalMargin)
                                                  : 0
                                              );
                                            }}
                                            onFocus={e => e.target.select()}
                                            value={counts}
                                            disabled={
                                              dateTocheck < moment()
                                            }
                                          />
                                          {/* <div className="counter-input__content">
                                        {counts}
                                      </div> */}

                                          <Button
                                            disableFocusRipple
                                            disableRipple
                                            className="counter-input__button plus-btn"
                                            onClick={e => {
                                              e.preventDefault();
                                              handleIncrement(
                                                tip?.id,
                                                index
                                              );
                                            }}
                                            disabled={
                                              dateTocheck < moment()
                                            }
                                            // endIcon={<RightArrow />}
                                          >
                                            {/* <Plus /> */}
                                            <MyPLusNutton />
                                          </Button>
                                        </Box>
                                      </Box>
                                    </>
                                  )}
                              </Box>
                              {eventByIdData?.tipDeadline == "game" && (
                                <Countdown
                                  date={moment.utc(tip?.startTime).toDate()}
                                  renderer={Renderer}
                                  onComplete={() =>
                                    setTimeout(
                                      () => getEventByID(stepperCount),
                                      1000
                                    )
                                  }
                                  key={moment.utc(tip?.startTime).format()}
                                />
                              )}
                            </>);
                          })}
                        </Box>)
                      );
                    }
                  )}
                  {eventByIdData?.jokerRound === 1 && fetchjokerBox()}
                  {(eventByIdData?.tipDeadline == "round" ? (
                    lastDate > moment()
                  ) : (
                    !completedRound?.includes(stepperCount) ||
                    Boolean(moment(lastEventDate) > moment().tz(timezone))
                  )) ? (
                    <>
                      <Box className="btn-flex">
                        <>
                          <Box className="submit-tips-btn-sec">
                            <Button
                              disableElevation
                              disableFocusRipple
                              disableRipple
                              fullWidth
                              className="submit-tips-btn"
                              onClick={() => {
                                handleSubmit();
                              }}
                              disabled={
                                eventByIdData?.marginRound == 1 &&
                                Boolean(
                                  eventsData?.[0]?.awayTeam?.isTip ||
                                    eventsData?.[0]?.homeTeam?.isTip
                                ) &&
                                counts === 0
                              }
                            >
                              {eventLength == 0 ? "Submit" : "Update"} tips
                            </Button>
                          </Box>
                          <Button
                            className="share-btn mt-0"
                            endIcon={<Share />}
                            onClick={() =>
                              navigate(
                                `/tipscompetition/share-tips?round=${stepperCount}&compId=${Number(
                                  params?.compsId
                                )}&referral_type=tippingComp&referral=${
                                  redux_userData?.referralCode
                                }&sportId=${Number(
                                  params?.SportId
                                )}&tournamentId=${Number(
                                  params?.tournamentId
                                )}
  `
                              )
                            }
                          >
                            Share tips
                          </Button>
                        </>
                      </Box>
                      {eventByIdData?.marginRound == 1 &&
                        Boolean(
                          eventsData?.[0]?.awayTeam?.isTip ||
                            eventsData?.[0]?.homeTeam?.isTip
                        ) &&
                        counts === 0 && (
                          <Box className="margin-error">
                            <Typography className="error">
                              Please select{" "}
                              {params?.sports == "cricket"
                                ? "Number of 6s"
                                : "Margin"}{" "}
                              at least 1
                            </Typography>
                          </Box>
                        )}

                      {atLeastOneError ? (
                        <Box className="margin-error">
                          <Typography className="error">
                            {atLeastOneError}
                          </Typography>
                        </Box>
                      ) : (
                        <></>
                      )}
                      <Box className="clear-all-box">
                        <Typography
                          className="clear-all"
                          onClick={() => {
                            handleReset();
                          }}
                        >
                          Clear all
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <Box className="btn-flex">
                      <Button
                        className="share-btn mt-0"
                        endIcon={<Share />}
                        onClick={() =>
                          navigate(
                            `/tipscompetition/share-tips?round=${stepperCount}&compId=${Number(
                              params?.compsId
                            )}&referral_type=tippingComp&referral=${
                              redux_userData?.referralCode
                            }&sportId=${Number(
                              params?.SportId
                            )}}&tournamentId=${Number(params?.tournamentId)}
  `
                          )
                        }
                      >
                        Share tips
                      </Button>
                    </Box>
                  )}
                </>
              ) : (
                <Box
                  style={{
                    textAlign: "center"
                  }}
                >
                  <NoDataComp />
                </Box>
              )}
            </>
          )}
          {fetchAds(
            2,
            "100%",
            Config?.release == "IN" ? BannerIndia : tippingBelow
          )}
        </Box>

        <OurPartner />
      </Box>
      <SmartBookModal
        open={SBopen}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        typeId={selectedId}
        dataId={selectedId}
        sportId={selectedSportId}
        listingFunction={() =>
          setTimeout(() => {
            getEventByID(stepperCount);
          }, 3000)
        }
      />
    </Box>
  </>);
}

export default CompTipsPage;
