const EventEmitter = {
  events: {},
  dispatch: function(event, data) {
    if (!this.events[event]) return;
    this.events[event].forEach(callback => callback(data));
  },
  subscribe: function(event, callback) {
    if (!this.events[event]) this.events[event] = [];
    this.events[event].push(callback);
  },
  unsubscribe: function(event, callback) {
    if (!this.events[event]) return;

    const index = this.events[event].indexOf(callback);

    if (index !== -1) {
      this.events[event].splice(index, 1);
    }
  }
};
module.exports = { EventEmitter };
