import React, { useEffect } from "react";

const ScriptLoader = ({ scriptCode }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = scriptCode;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [scriptCode]);

  return null;
};

export default ScriptLoader;
