import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Breadcrumbs,
  Tab,
  Tabs,
  Typography,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Button,
  Tooltip,
  Collapse,
  Switch,
  FormControlLabel,
  ClickAwayListener,
  IconButton,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import AdBannner from "src/views/component/AdBanner/AdBanner";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
// import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import { ReactComponent as LockIcon } from "src/assets/images/icons/lock.svg";
import { ReactComponent as TickIcon } from "src/assets/images/icons/tick.svg";
import AusFlag from "src/assets/images/aus-flag.png";
// import { ReactComponent as Reset } from "../../../assets/images/reset.svg";
import moment from "moment-timezone";
import Select, { components } from "react-select";
import _ from "lodash";
import Loader from "src/components/Loader";
import teamLogo from "src/assets/images/bookmakers/BlueBet-thumb.png";

import "./enterRacingTips.scss";
import { IntlContext } from "src/App";
// import { fetchInterval } from "../../../../../helpers/store/Actions/interval";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { Config } from "../../../../helpers/context/config";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { fetchFromStorage } from "src/helpers/context";
import { ToastContainer, toast } from "react-toastify";
import Weather from "../../../../assets/images/icons/weather/w-good.svg";
import Cloudy from "../../../../assets/images/icons/weather/cloudy.svg";
import Rain from "../../../../assets/images/icons/weather/rain.svg";
import Showers from "../../../../assets/images/icons/weather/showers.svg";
import "react-lazy-load-image-component/src/effects/blur.css";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataComp from "src/views/component/UI/NoData";
import EnterTipsGraph from "../../UI/graph/enterTipsGraph";
import OurPartner from "../../ourPartners";
import RaceCountdown from "../raceCountdown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SportsTipModal from "../../UI/sportsTipModal";
import useAutoSaveStore from "src/helpers/zustand/useAutoSaveStore";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const racingTipData = [
  {
    id: 1,
    name: "Incredible Pinto",
    racingId: "31711163321",
    weight: "00",
    odd: 6.1,
    jockey: "Alamn B.",
    form: "Good",
    trainer: "Dan"
  },
  {
    id: 2,
    name: "Incredible Pinto",
    racingId: "31711163321",
    weight: "00",
    odd: 6.1,
    jockey: "Alamn B.",
    form: "Good",
    trainer: "Dan"
  },
  {
    id: 3,
    name: "Incredible Pinto",
    racingId: "31711163321",
    weight: "00",
    odd: 6.1,
    jockey: "Alamn B.",
    form: "Good",
    trainer: "Dan"
  }
];

const tableData = [
  {
    id: 1,
    date: "13 Jul, 2022",
    event: "R4 Mornington",
    Runner: "16 Ellenmac",
    result: "5th",
    win: "$4.40",
    place: "$1.80"
  },
  {
    id: 2,
    date: "13 Jul, 2022",
    event: "R4 Mornington",
    Runner: "16 Ellenmac",
    result: "5th",
    win: "$4.40",
    place: "$1.80"
  },
  {
    id: 3,
    date: "13 Jul, 2022",
    event: "R4 Mornington",
    Runner: "16 Ellenmac",
    result: "5th",
    win: "$4.40",
    place: "$1.80"
  }
];

const day = [
  // {
  //   id: 0,
  //   name: "Yesterday",
  //   date: moment
  //     .tz(moment(), timezone)
  //     .subtract(1, "days")
  //     .format("YYYY-MM-DD"),
  //   dayName: moment
  //     .tz(moment(), timezone)
  //     .subtract(1, "days")
  //     .format("dddd")
  // },
  {
    id: 0,
    name: "Today",
    date: moment.tz(moment(), timezone).format("YYYY-MM-DD"),
    dayName: moment.tz(moment(), timezone).format("dddd")
  },
  {
    id: 1,
    name: "Tomorrow",
    date: moment
      .tz(moment(), timezone)
      .add(1, "days")
      .format("YYYY-MM-DD"),
    dayName: moment
      .tz(moment(), timezone)
      .add(1, "days")
      .format("dddd")
  },
  {
    id: 2,
    name: "Wednesday",
    date: moment
      .tz(moment(), timezone)
      .add(2, "days")
      .format("YYYY-MM-DD"),
    dayName: moment
      .tz(moment(), timezone)
      .add(2, "days")
      .format("dddd")
  },
  {
    id: 3,
    name: "Thursday",
    date: moment
      .tz(moment(), timezone)
      .add(3, "days")
      .format("YYYY-MM-DD"),
    dayName: moment
      .tz(moment(), timezone)
      .add(3, "days")
      .format("dddd")
  },
  {
    id: 4,
    name: "Friday",
    date: moment
      .tz(moment(), timezone)
      .add(4, "days")
      .format("YYYY-MM-DD"),
    dayName: moment
      .tz(moment(), timezone)
      .add(4, "days")
      .format("dddd")
  },
  {
    id: 5,
    name: "Saturday",
    date: moment
      .tz(moment(), timezone)
      .add(5, "days")
      .format("YYYY-MM-DD"),
    dayName: moment
      .tz(moment(), timezone)
      .add(5, "days")
      .format("dddd")
  },
  {
    id: 6,
    name: "Sunday",
    date: moment
      .tz(moment(), timezone)
      .add(6, "days")
      .format("YYYY-MM-DD"),
    dayName: moment
      .tz(moment(), timezone)
      .add(6, "days")
      .format("dddd")
  }
  //   {
  //     id: 7,
  //     name: "Futures",
  //     // dayName: "Futures",
  //     date: moment
  //       .tz(moment(), timezone)
  //       .add(1, "days")
  //       .format("YYYY-MM-DD")
  //   },
  //   {
  //     id: 8,
  //     name: "Archive",
  //     // dayName: "Archive",
  //     date: moment
  //       .tz(moment(), timezone)
  //       .subtract(1, "days")
  //       .format("YYYY-MM-DD")
  //   }
];

const EnterRacingTipsPage = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { setApiInfo } = useAutoSaveStore();
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const BetslipData = useSelector(state => state?.reduxData?.BetSlipData);
  const [visibleAds, setVisibleAds] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [value, setValue] = useState(0);
  const [selectedDate, setselectedDate] = useState(
    moment.tz(moment(), timezone).format("YYYY-MM-DD")
  );
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  const [selectedOption, setSelectedOption] = useState(
    state?.CurrentData?.track?.id
  );
  const [eventFullList, setEventFullList] = useState([]);
  const [trackListData, settrackListData] = useState([]);
  const [selectedTrack, setselectedTrack] = useState("");
  const [raceTrackListData, setraceTrackListData] = useState([]);
  const [eventLocation, setEventLocation] = useState([]);
  const [raceTrackdata, setraceTrackdata] = useState({});
  const [runnerData, setRunnerData] = useState([]);
  const [isrunnnerLoading, setisrunnnerLoading] = useState(false);
  const [eventData, seteventData] = useState([]);
  const [runnerInfoData, setrunnerInfoData] = useState([]);
  const [oddsLoader, setOddsLoader] = useState(false);
  const [isEventLoading, setisEventLoading] = useState(false);
  const [selectedEvent, setselectedEvent] = useState("");
  const [selectedRaceId, setselectedRaceId] = useState("");
  const [raceSportId, setRaceSportId] = useState(null);
  const [CommentsOpen, setCommentsOpen] = useState(-1);
  const [isTipped, setIsTipped] = useState(false);
  const [BookkeeperData, setBookKeeperData] = useState([]);
  const [isResult, setIsResult] = useState(false);
  const [openTipsModal, setOpenTipsModal] = useState(false);
  const [passModalData, setPassModalData] = useState([]);
  const [tipsResultTable, setTipsResultTable] = useState([]);
  const [isTipsResultLoading, setIsTipsResultLoading] = useState(false);

  let currentDate = moment().format("YYYY-MM-DD");
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchTrackList();
    fetchBookKeeper();
    fetchTipResult();
  }, []);
  useEffect(() => {
    let eventData = trackListData?.filter(item => {
      return item?.id == selectedEvent;
    });
    setEventLocation(eventData);
  }, [trackListData, selectedEvent]);
  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds?.[0]?.pageAdLists);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const Today = moment.tz(moment(), timezone).format("YYYY-MM-DD");
  const Tomorrow = moment
    .tz(moment(), timezone)
    .add(1, "days")
    .format("YYYY-MM-DD");
  const yesterDay = moment
    .tz(moment(), timezone)
    .subtract(1, "days")
    .format("YYYY-MM-DD");

  const handleDateChange = date => {
    setselectedDate(date ? moment(date).format("YYYY-MM-DD") : null);
  };

  const handleChange = (event, value) => {
    // if (value == "7") {
    //   // navigate("/");
    // } else {
    setValue(value);
    let SelectDate = day?.filter(item => {
      return item.id === value;
    });
    const date = SelectDate?.map(item => {
      return item?.date;
    })?.[0];
    setselectedDate(date);
    fetchTrackList(null, date);
    // }
  };
  const handleClose = () => {
    setOpenTipsModal(false);
  };

  const fetchFluctuationGraph = data => {
    let selectedId = null;

    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map(obj => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter(x => x !== undefined);
        selectedId = providerid?.[0];
      } else {
        selectedId = null;
      }
    } else {
      selectedId = null;
    }

    if (selectedId) {
      const selectedFilterData = data?.filter(item => {
        return item?.BookKeeperId === selectedId;
      });
      let FlucData = [];
      const selectedGraphIdData = selectedFilterData?.[0]?.RaceOdds?.[0]?.RaceOddFlucs?.map(
        odds => {
          return FlucData?.push({
            point: odds?.intValue
          });
        }
      );
      return <EnterTipsGraph FlucData={FlucData} />;
    } else {
      return <Box>No Data Available</Box>;
    }
  };
  const WeatherIcon = type => {
    if (type === "showers") {
      return Showers;
    } else if (type === "Rain") {
      return Rain;
    } else if (type === "fine") {
      return Weather;
    } else if (type === "FINE") {
      return Weather;
    } else if (type === "Good") {
      return Weather;
    } else if (type === "Cloudy") {
      return Cloudy;
    } else {
      return Weather;
    }
  };
  const fetchRaceRunner = async id => {
    setisrunnnerLoading(true);
    try {
      const { status, data } = await axiosInstance.get(`/events/runner/${id}`);
      if (status === 200) {
        setRaceSportId(data?.data?.race?.sportId);
        setraceTrackdata(data?.data?.race);
        setRunnerData(data?.data?.runners);
        setisrunnnerLoading(false);
        fetchOddsData(id, data?.data?.runners);
        setIsResult(
          data?.data?.race?.resultDisplay &&
            data?.data?.race?.resultDisplay !== ""
            ? true
            : false
        );
        let RunnerExtraInfoData = data?.data?.runners?.map(item => {
          return item?.RunnerInfos?.[0];
        });

        let finalData = RunnerExtraInfoData?.map(item => {
          return JSON.parse(item?.info);
        });
        // let runnerExtraInfoDatas =JSON.parse(finalData)
      }
    } catch (err) {
      setisrunnnerLoading(false);
    }
  };
  const fetchTrackList = async (isRunnersChange, date) => {
    setisEventLoading(true);
    let date_to_pass = date
      ? date
      : // selectedDate !== ""
        //   ? moment(selectedDate)
        //       .tz(timezone)
        //       .format("YYYY-MM-DD")
        //   :
        moment(new Date())
          .tz(timezone)
          .format("YYYY-MM-DD");
    // let id = params.sportId;
    let id = "1,2,3";
    // let meetingState = new URLSearchParams(window.location.search).get("intl");

    // let meetingStateValue = meetingState == "true" ? "Intl" : "Aus/NZ,Intl";

    try {
      const { status, data } = await axiosInstance.get(
        `events/trackList/?sportId=${id}&MeetingState=&todate=${date_to_pass}&countryId=${""}&stateId=${""}&timezone=${timezone}`
      );
      if (status === 200) {
        let data_pass =
          data?.events?.length > 0
            ? data?.events?.map(obj => {
                // remove raceNumber === 0 and duplicate raceNumber's races from tracklist
                return {
                  ...obj,
                  race: Array.from(new Set(obj?.race.map(a => a?.raceNumber)))
                    .map(id => {
                      return obj?.race.find(a => a?.raceNumber === id);
                    })
                    .filter(race => race?.raceNumber != 0)
                };
              })
            : [];
        setEventFullList(data_pass);
        let sportId =
          data_pass?.length > 0 ? data_pass?.[0]?.sportId : Number(id);
        let race =
          sportId === 1 ? "horse" : sportId === 2 ? "harness" : "greyhounds";
        // Race details change that time call
        if (isRunnersChange) {
          settrackListData(data_pass);
          setselectedTrack(
            data_pass?.length > 0 ? data_pass?.[0]?.trackId : null
          );
          setraceTrackListData(data_pass?.length > 0 ? data_pass[0]?.race : []);
          const raceId =
            data_pass?.length > 0
              ? data_pass?.[0]?.race?.length > 0
                ? data_pass?.[0]?.race[0]?.id !== undefined
                  ? data_pass?.[0]?.race[0]?.id
                  : null
                : null
              : null;
          fetchRaceRunner(raceId);
          setselectedRaceId(raceId);
          const newData = [];
          let track = data_pass?.map(item => {
            newData.push({
              label: item?.eventName + "," + item?.state?.stateCode,
              value: item?.id
            });
          });
          seteventData(newData);
          setisEventLoading(false);
        } else {
          let all_data = data_pass;
          // find selected races from tracklist data
          let selected_track_races = all_data?.find(
            obj => obj?.id === data_pass?.[0]?.id
          );

          settrackListData(data_pass);
          setraceTrackListData(selected_track_races?.race);
          const raceId =
            selected_track_races?.race?.length > 0
              ? selected_track_races?.race[0]?.id !== undefined
                ? selected_track_races?.race[0]?.id
                : null
              : null;
          fetchRaceRunner(raceId);
          setselectedRaceId(raceId);
          const newData = [];
          let track = data_pass?.map(item => {
            newData.push({
              label: item?.state?.stateCode
                ? item?.eventName + "," + item?.state?.stateCode
                : item?.eventName,
              value: item?.id
            });
          });

          seteventData(newData);
          setisEventLoading(false);
          setselectedEvent(newData?.[0]?.value);
        }
      }
    } catch (err) {
      setisEventLoading(false);
    }
  };
  const handleEvent = e => {
    // setselectedTrack(e?.value);
    let selected_obj = trackListData?.find(obj => obj?.id === e?.value);

    let haveRace = selected_obj?.race?.filter(
      data => data?.startTimeDate !== null
    );
    let upnextRaces = selected_obj?.race?.filter(
      item =>
        item?.startTimeDate !== null &&
        moment(new Date()).isBefore(new Date(item?.startTimeDate))
    );
    let RaceId = upnextRaces?.length > 0 ? upnextRaces[0].id : haveRace[0].id;

    setselectedEvent(e?.value);
    setselectedRaceId(RaceId);
    fetchRaceRunner(RaceId);

    // navigate(
    //   Routes.RunnerDetails(
    //     params?.race,
    //     params?.sportId,
    //     RaceId,
    //     selected_obj?.trackId,
    //     e?.value,
    //     head_date,
    //     isIntl
    //   )
    // );
  };

  const handleChangeTrack = (e, newValue) => {
    fetchRaceRunner(newValue);
    setselectedRaceId(newValue);
  };
  const fetchOddsData = async (raceId, runnerlistData) => {
    setOddsLoader(true);
    // if (isFirstTime) {
    //
    // }
    let runnerInfoData = runnerlistData?.map(obj => {
      return {
        ...obj,
        RunnerInfos: obj?.RunnerInfos?.map(item => {
          return {
            ...item,
            info: item?.info ? JSON.parse(item?.info) : ""
          };
        })
      };
    });
    try {
      const { status, data } = await axiosInstance.get(
        `events/getOddsByrace/${raceId}?marketId=${1}&oddKey=${1}`
      );
      if (status === 200) {
        setOddsLoader(false);
        // hideSeeAllOddsColumn(data?.marketRelation)
        let newData = runnerInfoData?.map(obj => {
          return {
            ...obj,
            oddsData: data?.marketRelation
              ?.map(item => {
                return item?.RacingParticipantId == obj?.id ? item : [];
              })
              ?.filter(x => {
                return x?.data?.length > 0;
              })
          };
        });

        const IsTipped = newData?.some(item => item?.isMarketTip == 1);
        setIsTipped(IsTipped);
        setrunnerInfoData(newData);
      }
    } catch (err) {}
  };
  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`
      );
      if (status === 200) {
        setBookKeeperData(data?.result);
      } else {
      }
    } catch (err) {}
  };
  const handleBookkeeperCounter = async (BookKeeperId, type) => {
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(raceSportId)
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const oddsicon = (BookKeeperId, type, item) => {
    const newData = raceTrackdata?.ApiRaceIdentifiers?.map(obj => {
      const BookkeeperObj = obj?.provider?.BookKeeperProviders?.filter(item => {
        return item?.BookKeeperId === BookKeeperId;
      });
      return {
        ...obj,
        BookKeeperId:
          BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null
      };
    });

    const filteredData = newData?.filter?.(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;

    if (item && BookKeeperId === 8) {
      const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.[0]?.RaceOdds?.[0];
      return Bet365Data && Bet365Data?.length > 0 ? (
        <a
          href={
            Odds?.providerMarketId && Odds?.providerParticipantId
              ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId}~${Odds?.intValue}~1&bet=1`
              : `${iconData?.affiliate_link}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link"
        >
          <img
            className="bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            alt="bookmaker"
            onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
          />
        </a>
      ) : (
        <></>
      );
    }
    // else if (BookKeeperId == 13) {
    //   const fetchUrlArray = filteredData?.[0]?.url?.split("/");
    //   const fetchMarketId = fetchUrlArray?.[fetchUrlArray?.length - 1];
    //   const isMobile = () => {
    //     const userAgent = navigator.userAgent;
    //     return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //       userAgent
    //     );
    //   };
    //   const betFairUrl = isMobile()
    //     ? filteredData?.[0]?.url
    //       ? filteredData?.[0]?.url + `?Referrer=SmartB`
    //       : iconData?.affiliate_link
    //     : filteredData?.[0]?.url
    //     ? `https://www.betfair.com.au/exchange/plus/${
    //         params?.sportId == 3 ? "greyhound-racing" : "horse-racing"
    //       }/market/${fetchMarketId}`
    //     : iconData?.affiliate_link;
    //   return (
    //     <a href={betFairUrl} target="_blank" rel="noopener noreferrer">
    //       <img
    //         className="bookmaker-thumb"
    //         src={
    //           iconData?.small_logo?.includes("uploads")
    //             ? Config.mediaURL + iconData?.small_logo
    //             : iconData?.small_logo
    //         }
    //         alt="Odds Icon"
    //         onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
    //       />
    //     </a>
    //   );
    // }
    else {
      return (
        <a
          href={
            filteredData?.length > 0 && filteredData?.[0]?.url
              ? filteredData?.[0]?.url + `?Referrer=SmartB`
              : iconData?.affiliate_link
          }
          // href={iconData?.affiliate_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            alt="bookmaker"
            onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
          />
        </a>
      );
    }
  };

  const fetchCurrentBestValue = (data, fulldata) => {
    if (data && data?.length > 0) {
      let maxno = data?.reduce((max, obj) => {
        obj?.RaceOdds?.[0]?.intValue > max
          ? (max = obj?.RaceOdds?.[0]?.intValue)
          : (max = max);
        return max;
      }, -1);
      if (maxno !== -1) {
        if (maxno !== 0) {
          let providerid = data
            ?.map(obj => {
              if (obj?.RaceOdds?.[0]?.intValue === maxno) {
                return obj?.BookKeeperId;
              }
            })
            .filter(x => x !== undefined);
          return fetchClickableOdds(
            maxno,
            providerid?.[0],
            "header",
            data,
            fulldata,
            "betslip"
          );
        } else {
          return "SP";
        }
      } else {
        return "SP";
      }
    } else {
      return (
        <Tooltip
          title="No odds available"
          className="odds-tooltip"
          placement="top"
        >
          <span className="no-odds">NOA</span>
        </Tooltip>
      );
    }
  };
  const fetchCurrentBestIcon = data => {
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map(obj => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter(x => x !== undefined);
        return oddsicon(providerid?.[0], "header", data);
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip
  ) => {
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;

    let isAdded = BetslipData?.some(el => {
      if (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId ==
          BookKeeperData?.[0]?.RaceOdds?.[0]?.MarketRelationId &&
        el?.eventId == Number(selectedRaceId)
      )
        return true;
      else return false;
    });

    // if (BookKeeperId === 8) {
    //   const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8)

    //   const Odds = Bet365Data?.[0]?.RaceOdds?.[0]
    //   return (
    //     Bet365Data && Bet365Data?.length > 0 ?
    //       (<a href={Odds?.providerMarketId && Odds?.providerParticipantId ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId
    //         }~${Odds?.intValue}~1&bet=1` : `${iconData?.affiliate_link}`} target="_blank" rel="noopener noreferrer" className="odds-link" >  {odds === "SP" ? "SP" : Number(odds).toFixed(2)} </a>
    //       ) : (<></>)
    //   )
    // } else {

    return (
      // <a
      //   href={iconData?.affiliate_link}
      //   target="_blank"
      //   rel="noopener noreferrer"
      //   className="odds-link"
      // >
      // </a>
      (<span
        className={
          isAdded && IsBetslip === "betslip"
            ? "betslip-added odds-link"
            : "odds-link"
        }
        onClick={() => {
          IsBetslip === "nobetslip"
            ? handleBookkeeperCounter(BookKeeperId, type)
            : isAdded
            ? handleDeleteBetslip(BookKeeperId, item)
            : handleAddToBetSlip(BookKeeperId, type, item, fulldata);
        }}
      >
        {IsBetslip === "betslip" && !isResult ? (
          <Tooltip title="Bet" className="bet-tooltip" placement="top">
            <span className="bet-now">
              {" "}
              {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
            </span>
          </Tooltip>
        ) : (
          <>{odds === "SP" ? "SP" : Number(odds).toFixed(2)}</>
        )}
      </span>)
    );
    // }
  };

  const handleAddToBetSlip = async (BookKeeperId, type, item, fulldata) => {
    handleBookkeeperCounter(BookKeeperId, type);
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );

    let payload = {
      sportId: Number(raceSportId),
      eventId: Number(selectedRaceId),
      teamId: BookKeeperData?.[0]?.RacingParticipantId,
      bookKeeperId: BookKeeperId,
      betOfferId: BookKeeperData?.[0]?.RaceOdds?.[0]?.MarketRelationId
    };
    if (isLogin) {
      if (!isResult) {
        try {
          const { status, data } = await axiosInstance.post(
            `betSlipCard/BetSlipCard`,
            payload
          );
          if (status === 200) {
            toast.success(data?.message, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored"
            });
            const addedData = [...BetslipData, data?.result];
            dispatch(fetchBetSlipData(addedData));
            dispatch(fetchBetSlipCount(addedData?.length));
          } else {
          }
        } catch (err) {
          console.log("error", err);
        }
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
      if (!isResult) {
        setApiInfo({
          api: `betSlipCard/BetSlipCard`,
          payload: payload,
          method: "post"
        });
      }
    }
  };
  const handleDeleteBetslip = async (BookKeeperId, item) => {
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let delslip = BetslipData?.filter(el => {
      return (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId ==
          BookKeeperData?.[0]?.RaceOdds?.[0]?.MarketRelationId &&
        el?.eventId == Number(selectedRaceId)
      );
    })?.[0];

    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?id=${delslip?.id}`
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        let chageData = BetslipData?.filter(obj => obj?.id !== delslip?.id);

        dispatch(fetchBetSlipData(chageData));
        dispatch(fetchBetSlipCount(chageData?.length));
        // setBetSlipData(chageData);
      } else {
      }
    } catch (err) {}
  };
  const fetchTipResult = async () => {
    setIsTipsResultLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/race/tip/result?timezone=${timezone}&UserId=&startDate=2024-01-09&marketType=win`
      );
      if (status === 200) {
        setTipsResultTable(data?.result);
        setIsTipsResultLoading(false);
      }
    } catch (err) {
      setIsTipsResultLoading(false);
    }
  };
  return (<>
    <Box className="content-wrap">
      <Box className="banner-img-fix">
        {fetchPageHeaderAds("218px", banner)}
      </Box>
      <Box className="tips-marketplace-tips-wrap">
        <Box className="marketplace-header">
          <Box className="bredcrumn-wrap">
            <Breadcrumbs
              separator="/"
              aria-label="breadcrumb"
              className="breadcrumb"
            >
              <Link underline="hover" color="inherit" to="/">
                HOME
              </Link>
              <Link underline="hover" color="inherit" to="#">
                Tips Marketplace
              </Link>
              <Typography>Racing Tips</Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="h1">Enter Racing Tips</Typography>
        </Box>
        {/* <Box className="Marketplace-select-wrap">
          <Box className="filter-type-select">
            <Box className="Filteritemlist-datepicker">
            </Box>
          </Box>
        </Box> */}
        <Box className="days-tab">
          <Tabs
            value={value}
            variant="scrollable"
            scrollButtons={false}
            indicatorColor="primary"
            textColor="primary"
            className="racing-tab-detail"
          >
            {day?.map((item, index) => {
              return (
                <Box key={index}>
                  <Tab
                    disableRipple
                    disableFocusRipple
                    label={
                      item?.date == Today
                        ? "Today"
                        : item?.name == "Archive"
                        ? "Archive"
                        : item?.name == "Futures"
                        ? "Futures"
                        : item?.date == Tomorrow
                        ? "Tomorrow"
                        : item?.date == yesterDay
                        ? "Yesterday"
                        : item?.dayName
                    }
                    value={item?.id}
                    className={item?.id == value ? "active" : ""}
                    onChange={(event, newValue) =>
                      handleChange(event, item?.id)
                    }
                  />
                </Box>
              );
            })}
          </Tabs>
        </Box>
        {isEventLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : screenWidth > 799 ? (
          <Box className="sport-tab mt-18">
            <Box className="racing-tab">
              {/* Racing DropDown */}
              <Box className="select-wrap">
                <Select
                  className="React"
                  value={eventData?.find(item => {
                    return item?.value == selectedEvent;
                  })}
                  onChange={e => handleEvent(e)}
                  options={eventData}
                  classNamePrefix="select"
                  isSearchable={false}
                />
              </Box>
              {/* Racing Location */}
              {eventLocation?.length > 0 ? (
                <Box className="racing-location">
                  <ul>
                    {eventLocation[0]?.country ? (
                      <li>
                        {eventLocation[0]?.country?.country_flag?.includes(
                          "uploads"
                        ) ? (
                          <img
                            src={
                              Config.mediaURL +
                              eventLocation[0]?.country?.country_flag
                            }
                            width="22px"
                            alt="Race Country"
                          />
                        ) : (
                          <img
                            src={eventLocation[0]?.country?.country_flag}
                            width="22px"
                            alt="Race Country"
                          />
                        )}

                        <span className="country-name">
                          {eventLocation[0]?.country?.country}
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {eventLocation[0]?.state ? (
                      <li>
                        <span>{eventLocation[0]?.state?.state}</span>
                      </li>
                    ) : (
                      ""
                    )}
                    <li>
                      <span>{eventLocation[0]?.eventName}</span>
                    </li>
                  </ul>
                </Box>
              ) : (
                ""
              )}
              {/* Racing Track */}
              <Box className="racing-track">
                <span>{localesData?.RUNNER_TABLE?.TRACK}:</span>
                {/* <Rating name="customized-10" value={5} readOnly /> */}
                <span>
                  {raceTrackdata?.trackCondition?.replace(/"/g, "")}
                </span>
              </Box>
              {/* Racing Wether */}
              <Box className="racing-Weather">
                <span>{localesData?.RUNNER_TABLE?.WEATHER}:</span>
                <Box className="weather">
                  {raceTrackdata?.Weather &&
                  raceTrackdata?.Weather?.weatherType ? (
                    <>
                      <img
                        src={WeatherIcon(raceTrackdata?.Weather?.weatherType)}
                        alt="weather"
                      />
                      <span>{raceTrackdata?.Weather?.weatherType}</span>{" "}
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </Box>

            {/* Tracklist TabBar */}
            <Box className="race-track-blackbook">
              <Box className="race-track-list">
                <Tabs
                  value={selectedRaceId}
                  onChange={handleChangeTrack}
                  className="race-track"
                >
                  {eventLocation[0]?.race.length > 0 &&
                    eventLocation[0]?.race?.map((obj, i) => (
                      <Tab
                        disableRipple
                        disableFocusRipple
                        label={obj?.raceNumber}
                        key={i}
                        value={obj?.id}
                        disabled={obj?.id === undefined ? true : false}
                      />
                    ))}
                </Tabs>
              </Box>
            </Box>
            {/* Racing Detail */}

            <Box className="racing-detail-head">
              <Box>
                <h6>{raceTrackdata?.raceName}</h6>
              </Box>
              <Box className="race-track-info-right">
                <span>{localesData?.RUNNER_TABLE?.DISTANCE}: </span>
                <span>
                  {raceTrackdata?.Distance?.name}
                  {"m"}
                </span>
              </Box>
              <Box>
                <span>{localesData?.RUNNER_TABLE?.JUMP_TIME}: </span>
                <span>
                  {moment(currentDate).isSame(
                    moment(raceTrackdata?.startTimeDate).format("YYYY-MM-DD")
                  )
                    ? moment
                        .utc(raceTrackdata?.startTimeDate)
                        .local()
                        .format("hh:mm a")
                    : moment
                        .utc(raceTrackdata?.startTimeDate)
                        .local()
                        .format("YYYY/MM/DD hh:mm a")}
                </span>
              </Box>
              <Box className="race-track-info-right">
                <span>{localesData?.RUNNER_TABLE?.TIME_TILL_JUMP}: </span>
                <span>
                  <RaceCountdown
                    expiryTimestamp={new Date(
                      raceTrackdata?.startTimeDate
                    ).getTime()}
                  />
                </span>
              </Box>
            </Box>

            {/* Racing Comment */}
            <Box className="race-comment">
              <Box
                className="race-comment-box"
                onClick={() => setCommentsOpen(CommentsOpen === 0 ? -1 : 0)}
              >
                <h6>{localesData?.RUNNER_TABLE?.RACE_COMMENTS}</h6>
                <IconButton aria-label="expand row" size="small">
                  {CommentsOpen === 0 ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </Box>
              <Collapse in={CommentsOpen === 0} timeout="auto" unmountOnExit>
                <Typography variant="body2">
                  {raceTrackdata?.comment == "{}"
                    ? "No Comment Available"
                    : raceTrackdata?.comment
                    ? raceTrackdata?.comment
                    : "No Comment Available"}
                </Typography>
              </Collapse>
            </Box>
          </Box>
        ) : (
          <Box className="sport-tab mt-18">
            <Box className="racing-tab">
              {/* Racing DropDown */}
              <Box className="select-wrap">
                <Select
                  className="React"
                  value={eventData?.find(item => {
                    return item?.value == selectedEvent;
                  })}
                  onChange={e => handleEvent(e)}
                  options={eventData}
                  classNamePrefix="select"
                  isSearchable={false}
                />
              </Box>

              {/* Tracklist TabBar */}
              <Box className="race-track-list">
                <Tabs
                  value={selectedRaceId}
                  onChange={handleChangeTrack}
                  className="race-track"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {eventLocation[0]?.race.length > 0 &&
                    eventLocation[0]?.race?.map((obj, i) => (
                      <Tab
                        disableRipple
                        disableFocusRipple
                        label={obj?.raceNumber}
                        key={i}
                        value={obj?.id}
                        disabled={obj?.id === undefined ? true : false}
                      />
                    ))}
                </Tabs>
              </Box>
              {/* Racing Location */}
              {eventLocation?.length > 0 ? (
                <Box className="racing-location">
                  <ul>
                    {eventLocation[0]?.country ? (
                      <li>
                        {eventLocation[0]?.country?.country_flag?.includes(
                          "uploads"
                        ) ? (
                          <img
                            src={
                              Config.mediaURL +
                              eventLocation[0]?.country?.country_flag
                            }
                            width="22px"
                            alt="Race Country"
                          />
                        ) : (
                          <img
                            src={eventLocation[0]?.country?.country_flag}
                            width="22px"
                            alt="Race Country"
                          />
                        )}
                        <span className="country-name">
                          {eventLocation[0]?.country?.country}
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {eventLocation[0]?.state ? (
                      <li>
                        <span>{eventLocation[0]?.state?.state}</span>
                      </li>
                    ) : (
                      ""
                    )}
                    <li>
                      <span>{eventLocation[0]?.eventName}</span>
                    </li>
                  </ul>
                </Box>
              ) : (
                ""
              )}

              {/* Racing Track */}
              <Box className="track-weather-wrap">
                <Box className="racing-track">
                  <span>{localesData?.RUNNER_TABLE?.TRACK}:</span>
                  {/* <Rating name="customized-10" value={5} readOnly /> */}
                  <span>
                    {raceTrackdata?.trackCondition?.replace(/"/g, "")}
                  </span>
                </Box>
                {/* Racing Wether */}
                <Box className="racing-Weather">
                  <span>{localesData?.RUNNER_TABLE?.WEATHER}:</span>
                  <Box className="weather">
                    {raceTrackdata?.Weather &&
                    raceTrackdata?.Weather?.weatherType ? (
                      <>
                        <img
                          src={WeatherIcon(
                            raceTrackdata?.Weather?.weatherType
                          )}
                          alt="weather"
                        />
                        <span>{raceTrackdata?.Weather?.weatherType}</span>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Racing Detail */}

            <Box className="racing-detail-head">
              <Box>
                <h6>{raceTrackdata?.raceName}</h6>
              </Box>
              <Box className="race-track-info-right">
                <span>{localesData?.RUNNER_TABLE?.DISTANCE}: </span>
                <span>
                  {raceTrackdata?.Distance?.name}
                  {"m"}
                </span>
              </Box>
              <Box>
                <span>{localesData?.RUNNER_TABLE?.JUMP_TIME}: </span>
                <span>
                  {moment(currentDate).isSame(
                    moment(raceTrackdata?.startTimeDate).format("YYYY-MM-DD")
                  )
                    ? moment
                        .utc(raceTrackdata?.startTimeDate)
                        .local()
                        .format("hh:mm a")
                    : moment
                        .utc(raceTrackdata?.startTimeDate)
                        .local()
                        .format("YYYY/MM/DD hh:mm a")}
                </span>
              </Box>
              <Box className="race-track-info-right">
                <span>{localesData?.RUNNER_TABLE?.TIME_TILL_JUMP}: </span>
                <span>
                  <RaceCountdown
                    expiryTimestamp={new Date(
                      raceTrackdata?.startTimeDate
                    ).getTime()}
                  />
                </span>
              </Box>
            </Box>

            {/* Racing Comment */}
            <Box className="race-comment">
              <Box
                className="race-comment-box"
                onClick={() => setCommentsOpen(CommentsOpen === 0 ? -1 : 0)}
              >
                <h6>{localesData?.RUNNER_TABLE?.RACE_COMMENTS}</h6>
                <IconButton aria-label="expand row" size="small">
                  {CommentsOpen === 0 ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </Box>

              <Collapse in={CommentsOpen === 0} timeout="auto" unmountOnExit>
                <Typography variant="body2">
                  {raceTrackdata?.comment == "{}"
                    ? "No Comment Available"
                    : raceTrackdata?.comment
                    ? raceTrackdata?.comment
                    : "No Comment Available"}
                </Typography>
              </Collapse>
            </Box>
          </Box>
        )}
        {!isEventLoading && !isrunnnerLoading && !oddsLoader ? (
          <Box className="tip-data-wrap">
            <Box className="header-sec">
              <Typography className="header">Enter Tips</Typography>
            </Box>
            {/* {
  id: 1,
  name: "Incredible Pinto",
  racingId: "31711163321",
  weight: "00",
  odd: 6.1,
  jockey: "Alamn B.",
  form: "Good",
  trainer: "Dan"
}, */}
            <Box className="tip-data-sec">
              {runnerInfoData?.map(item => {
                return (
                  <>
                    {item?.isScratched === "false" ||
                    item?.isScratched === null ||
                    item?.isScratched === "0" ? (
                      <Box className="tip-data">
                        <Box className="left-sec">
                          <Box className="title-sec">
                            <Typography className="title">
                              {item?.runnerNumber}
                              {"."} {item?.animal?.name} (
                              {item?.barrierNumber})
                            </Typography>
                            {/* <Typography className="racing-id">
                        {item?.racingId}
                      </Typography> */}
                          </Box>
                          <Box className="player-wrap">
                            {raceSportId == 1 ? (
                              <Box className="player-detail">
                                <span>
                                  {localesData?.RUNNER_TABLE?.WEIGHT}:
                                </span>
                                <span>
                                  {Number(item?.JockeyWeight).toFixed(2) +
                                    "Kg"}
                                </span>
                              </Box>
                            ) : (
                              ""
                            )}
                            <Box className="player-detail">
                              {item?.Jockey ? (
                                <>
                                  <span>
                                    {raceSportId == 2
                                      ? `${localesData?.RUNNER_TABLE?.DRIVER}: `
                                      : `${localesData?.RUNNER_TABLE?.JOCKEY}: `}
                                  </span>
                                  <span>{item?.Jockey?.name}</span>
                                </>
                              ) : item?.Trainer ? (
                                <>
                                  <span>
                                    {localesData?.RUNNER_TABLE?.TRAINER}:{" "}
                                  </span>
                                  <span>{item?.Trainer?.name}</span>
                                </>
                              ) : (
                                ""
                              )}
                            </Box>
                          </Box>
                          <Box className="player-wrap">
                            {/* <Box className="player-detail">
                                  <span>Form:</span>
                                  <span> {}</span>
                                </Box> */}
                            {raceSportId == 1 || raceSportId == 2 ? (
                              <Box className="player-detail">
                                {item?.Trainer ? (
                                  <>
                                    <span>
                                      {localesData?.RUNNER_TABLE?.TRAINER}:{" "}
                                    </span>
                                    <span>{item?.Trainer?.name}</span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Box>
                        <Box className="right-sec">
                          <Box className="graph">
                            {fetchFluctuationGraph(item?.oddsData?.[0]?.data)}
                          </Box>
                          <Box className="odd-btn">
                            <Box className="odd-data">
                              <Box className="odds-wrap">
                                <span className="odds-left">
                                  {fetchCurrentBestValue(
                                    item?.oddsData?.[0]?.data,
                                    item
                                  )}
                                </span>

                                {fetchCurrentBestIcon(
                                  item?.oddsData?.[0]?.data
                                )}
                              </Box>
                            </Box>
                            <Box className="tip-btn">
                              {isTipped ? (
                                <Button
                                  className={
                                    item?.isMarketTip == 1
                                      ? `tipped-btn`
                                      : `tipped-disable`
                                  }
                                  // onClick={handleTippedClick}
                                  startIcon={
                                    item?.isMarketTip == 1 ? (
                                      <TickIcon />
                                    ) : (
                                      <LockIcon />
                                    )
                                  }
                                >
                                  Tipped
                                </Button>
                              ) : (
                                <Button
                                  className="add-tip-btn"
                                  onClick={() => {
                                    setOpenTipsModal(true);
                                    setPassModalData(item);
                                  }}
                                >
                                  Add Tip
                                </Button>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box className="tip-data">
                        <Box className="racer-detail-wrap">
                          <Typography variant="h6">
                            <Box className="scratched">
                              <span className="scratched-runner-name">
                                {item?.runnerNumber}
                                {"."} {item?.animal?.name} (
                                {item?.barrierNumber})
                              </span>
                            </Box>
                            <Box className="scratched-data">
                              <span className="scratched-runner-text">
                                {localesData?.RUNNER_TABLE?.SCRATCHED}{" "}
                              </span>{" "}
                              @{" "}
                              <span className="scratched-runner-time">
                                {" "}
                                {moment(item?.updatedAt).format("hh:mm A")}
                              </span>
                              <span className="scratched-runner-date">
                                {" "}
                                {moment(item?.updatedAt).format("DD/MM/YYYY")}
                              </span>
                            </Box>
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </>
                );
              })}
            </Box>
          </Box>
        ) : (
          <>
            <div className="allsport-loader-center">
              <Loader />
            </div>
          </>
        )}
        <Box className="tip-table-sec">
          <Box className="header-sec">
            <Typography className="header">Your Recent Result</Typography>
          </Box>
          {isTipsResultLoading ? (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          ) : (
            <Box className="table-content-sec">
              {screenWidth > 1130 ? (
                <Table className="table-content">
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-title">Date</TableCell>
                      <TableCell
                        className="table-title"
                        style={{ width: "32%" }}
                      >
                        Event
                      </TableCell>
                      <TableCell
                        className="table-title"
                        style={{ width: "25%" }}
                      >
                        Runner
                      </TableCell>
                      <TableCell className="table-title">Result</TableCell>
                      <TableCell className="table-title">Win</TableCell>
                      <TableCell className="table-title">Place</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tipsResultTable?.map(item => {
                      return (
                        <TableRow>
                          <TableCell className="table-detail">
                            {item?.startDate
                              ? moment
                                  .utc(item?.startDate)
                                  .local()
                                  .format("DD MMM YYYY")
                              : ""}
                          </TableCell>
                          <TableCell className="table-detail">
                            {item?.raceNumber ? "R" + item?.raceNumber : ""}{" "}
                            {item?.trackName}
                          </TableCell>
                          <TableCell className="table-detail">
                            {item?.runnerName ?? "-"}
                          </TableCell>
                          <TableCell className="table-detail">
                            {item?.resultDisplay ?? "-"}
                          </TableCell>
                          <TableCell className="table-detail">
                            {item?.win ?? "-"}
                          </TableCell>
                          <TableCell className="table-detail">
                            {item?.place ?? "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <>
                  {tipsResultTable?.map(item => {
                    return (
                      <Box className="event-details">
                        <Box className="title">
                          <Typography className="title-txt">
                            {item?.raceNumber ? "R" + item?.raceNumber : ""}{" "}
                            {item?.trackName}
                          </Typography>
                          <Typography className="date-txt">
                            {item?.startDate
                              ? moment
                                  .utc(item?.startDate)
                                  .local()
                                  .format("DD MMM YYYY")
                              : ""}
                          </Typography>
                        </Box>
                        <Box className="profit-wrap">
                          <Box className="profit-section">
                            <Typography className="profit-text">
                              Runner:
                            </Typography>
                            <Typography className="profit-text profit-text-bold">
                              {item?.runnerName ?? "-"}
                            </Typography>
                          </Box>
                          <Box className="profit-section">
                            <Typography className="profit-text">
                              Result:
                            </Typography>
                            <Typography className="profit-text profit-text-bold">
                              {item?.resultDisplay ?? "-"}
                            </Typography>
                          </Box>
                          <Box className="profit-section">
                            <Typography className="profit-text">
                              Place:
                            </Typography>
                            <Typography className="profit-text profit-text-bold">
                              {item?.place ?? "-"}
                            </Typography>
                          </Box>
                          <Box className="profit-section">
                            <Typography className="profit-text">
                              Win:
                            </Typography>
                            <Typography className="profit-text profit-text-bold">
                              {item?.win ?? "-"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </>
              )}
            </Box>
          )}
        </Box>
        {/* {isLoading ? (
          <div className="allsport-loader-center ">
            <Loader />
          </div>
        ) : sportsTipData?.length > 0 ? (
          <>
            <Box
              id="scrollableDiv"
              style={{ maxHeight: 1200, overflow: "auto" }}
            >
              <InfiniteScroll
                dataLength={neweventListData?.length}
                next={() =>
                  handleOnScrollBottomEvents(EventPage + 20, selectedOption)
                }
                hasMore={EventCount !== Math.ceil(EventPage / 20 + 1)}
                loader={
                  <div className="allsport-loader-center ">
                    <Loader />
                  </div>
                }
                scrollableTarget="scrollableDiv"
              >
              {sportsTipData?.map((item, index) => {
                return (
                  <>
                    <Box className="team-sport-table-wrap" key={index}>
                      <Box className="table-header-wrap">
                        <Box>
                          <Typography variant="h1">
                            {fetchDayName(item?.date)}{" "}
                            {moment
                              .utc(item?.date)
                              .local()
                              .format("DD/MM/YYYY")}
                          </Typography>
                        </Box>
                        <Box className="team-sport-table-indicator">
                          <Box className="team-time-market">
                            {moment
                              .utc(item?.date)
                              .local()
                              .format("hh:mm A")}{" "}
                            |{" "}
                            {`${
                              item?.marketLength ? item?.marketLength : 0
                            } Markets`}
                          </Box>
                          <span className="sport-indicator-btn">
                            ABCDABCDABCDABCD
                          </span>
                        </Box>
                        
                      </Box>
                      {screenWidth > 1025 ? (
                        <Table className="team-sport-table">
                          <TableHead>
                            <TableRow>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <>
                              <TableRow className="teamsports-tablerow hometeam-row">
                                <TableCell className="team-wrap">
                                  <span className="team-img-wrap">
                                    <Box className="team-img-wrap">
                                      <img
                                        className="team-img"
                                        src={item?.image1}
                                      />
                                    </Box>
                                  </span>
                                  <span className="team-name">
                                    {item?.team1}
                                  </span>
                                </TableCell>
                                <TableCell className="hight-chart-cell">
                                  {fetchFluctuationGraph(item, "awayteam")}
                                </TableCell>
                                <TableCell className="td-cell bg-white">
                                  <Box className="odds-wrap">
                                    <span className="odds odds-left">
                                      {" "}
                                      {item?.bestAtOpen1}
                                      
                                    </span>
                                    <img
                                      style={{
                                        maxWidth: "33px",
                                        borderRadius: "6px",
                                        cursor: "pointer",
                                        height: "33px",
                                        objectFit: "contain"
                                      }}
                                      src={teamLogo}
                                     
                                    />
                                  </Box>
                                </TableCell>

                                <TableCell className="current-best w160 padding28">
                                  {" "}
                                  <Box className="odds-wrap">
                                    {tipAdded && isTipped === item?.id ? (
                                      <Button
                                        className="tipped-btn"
                                        onClick={handleTippedClick}
                                        startIcon={<TickIcon />}
                                      >
                                        Tipped
                                      </Button>
                                    ) : (
                                      <Button
                                        className="add-tip-btn"
                                        onClick={() => {
                                          setOpenTipsModal(true);
                                          setIsTipped(item?.id);
                                        }}
                                      >
                                        Add Tip
                                      </Button>
                                    )}
                                  </Box>
                                </TableCell>
                              </TableRow>
                              <TableRow className="awayteam-row">
                                <TableCell className="team-wrap">
                                  <span className="team-img-wrap">
                                    {fetchTeamlogo(item, "awayteam")}
                                  </span>
                                  <span className="team-name">
                                    {" "}
                                    {item?.team2}
                                  </span>
                                </TableCell>

                                <TableCell className="hight-chart-cell">
                                  {fetchFluctuationGraph(item, "awayteam")}
                                </TableCell>
                                <TableCell className="td-cell bg-white">
                                  <Box className="odds-wrap">
                                    <span className="odds odds-left">
                                      {" "}
                                      {item?.bestAtOpen2}
                                    </span>
                                    <img
                                      style={{
                                        maxWidth: "33px",
                                        borderRadius: "6px",
                                        cursor: "pointer",
                                        height: "33px",
                                        objectFit: "contain"
                                      }}
                                      src={teamLogo}
                                      alt="bet"
                                    />
                                  </Box>
                                </TableCell>

                                <TableCell className="current-best w160 padding28">
                                  <Box className="odds-wrap">
                                    {isTipLocked && isTipped === item?.id ? (
                                      <Button
                                        className="tipped-disable"
                                        disabled
                                        startIcon={<LockIcon />}
                                      >
                                        Tipped
                                      </Button>
                                    ) : (
                                      <Button
                                        className="add-tip-btn"
                                        onClick={() => setOpenTipsModal(true)}
                                      >
                                        Add Tip
                                      </Button>
                                    )}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            </>
                          </TableBody>
                        </Table>
                      ) : (
                        <Box className="mobileview-teamsport-table-wrap">
                          <Box className="team-match-wrap">
                            <Box className="time-market-team-wrap">

                              <Box className="teams-details">
                                <Box className="team-wrap">
                                  <span className="team-img-wrap">
                                    {fetchTeamlogo(item, "hometeam")}
                                  </span>
                                  <Box className="smartbook-title">
                                    <span className="team-name">
                                      {item?.team1}
                                    </span>
                                  </Box>
                                </Box>
                                <Box className="vs"> V/S </Box>
                                <Box className="team-wrap">
                                  <span className="team-img-wrap">
                                    {fetchTeamlogo(item, "awayteam")}
                                  </span>
                                  <Box className="smartbook-title">
                                    <span className="team-name">
                                      {" "}
                                      {item?.team2}{" "}
                                    </span>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            <Box className="mobile-chart-deatils">
                              <Box className="mobile-hight-chart">
                                <Box className="ptb-10 mobile-chart">
                                  {fetchFluctuationGraph(item, "hometeam")}
                                </Box>
                                <Box className="ptb-10 mobile-chart">
                                  {fetchFluctuationGraph(item, "awayteam")}
                                </Box>
                              </Box>
                            </Box>
                            <Box className="odds-detail odds-details-best blue-btn-flex">
                              <Box className="odds-body">
                                <Box>
                                  <Box className="odds-wrap">
                                    {tipAdded && isTipped === item?.id ? (
                                      <Button
                                        className="tipped-btn"
                                        onClick={handleTippedClick}
                                        startIcon={<TickIcon />}
                                      >
                                        Tipped
                                      </Button>
                                    ) : (
                                      <Button
                                        className="add-tip-btn"
                                        onClick={() => {
                                          console.log("inside1");
                                          setOpenTipsModal(true);
                                          setIsTipped(item?.id);
                                        }}
                                      >
                                        Add Tip
                                      </Button>
                                    )}
                                  </Box>
                                </Box>
                                <Box>
                                  <Box className="odds-wrap">
                                    {isTipLocked && isTipped === item?.id ? (
                                      <Button
                                        className="tipped-disable"
                                        disabled
                                        startIcon={<LockIcon />}
                                      >
                                        Tipped
                                      </Button>
                                    ) : (
                                      <Button
                                        className="add-tip-btn"
                                        onClick={() => setOpenTipsModal(true)}
                                      >
                                        Add Tip
                                      </Button>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </>
                );
              })}
              </InfiniteScroll>
            </Box>
          </>
        ) : (
          <Box style={{ textAlign: "center" }}>
            <NoDataComp />
          </Box>
        )} */}
      </Box>
      <OurPartner />
    </Box>
    <SportsTipModal
      open={openTipsModal}
      handleClose={handleClose}
      title={passModalData?.animal?.name}
      SportId={raceSportId}
      raceId={selectedRaceId}
      data={passModalData}
      type={"race"}
      closeIcon={true}
      isEdit={false}
      listingFunction={() => fetchRaceRunner(selectedRaceId)}
    />
  </>);
};

export default EnterRacingTipsPage;
