import React from "react";
import Page from "src/components/Page";
import TippingPrizePage from "src/views/component/tippingPrize";

const TippingPrize = () => {
  return (
    <Page title="Tipping FAQs">
      <TippingPrizePage />
    </Page>
  );
};

export default TippingPrize;
