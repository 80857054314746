import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Breadcrumbs, Tabs, Tab } from "@mui/material";
import _, { result } from "lodash";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import AdvertisingBannner from "../../advertisingBanner";
import OurPartner from "src/views/component/ourPartners";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import AdBannner from "../../AdBanner/AdBanner";
import OverviewDetails from "./overviewDetails";
import MatchDetails from "./matchDetails";
import TableDetails from "./tableDetails";
import "../leagueData/leagueData.scss";
import "./soccerSportData.scss";

const soccerTabData = [
  { value: 0, label: "Overview" },
  { value: 1, label: "Matches" },
  { value: 2, label: "Table" }
];

const SoccerSportDataPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const tabsRef = useRef(null);
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [value, setValue] = useState(0);
  const [headerData, setHeaderData] = useState("");
  const [soccerTabData, setSoccerTabData] = useState([
    { value: 0, label: "Overview" },
    { value: 1, label: "Matches" },
    { value: 2, label: "Table" }
  ]);

  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleChange = (id, value) => {
    setValue(id);
  };

  useEffect(() => {
    fetchBreacrumnData();
  }, [params]);

  const fetchBreacrumnData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/header?SportId=${params?.sportId}&tournamentId=${params?.tournamentId}`
      );
      if (status === 200) {
        setHeaderData(data?.result);
      } else {
      }
    } catch (err) {}
  };
  const fetchTableTab = (upcomingTable, resultTable) => {
    const IsTableData =
      upcomingTable || resultTable
        ? soccerTabData
        : soccerTabData?.filter(item => item?.label !== "Table");
    setSoccerTabData(IsTableData);
  };
  return (
    <>
      <Box className="content-wrap">
        {/* <AdvertisingBannner src={banner} /> */}
        <Box className="mobile-banner">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="league-data-wrap">
          <Box className="teamsport-data-header">
            <Box className="bredcrumn-deatils">
              <Box className="bredcrumn-wrap">
                <Breadcrumbs
                  separator="/"
                  aria-label="breadcrumb"
                  className="breadcrumb"
                >
                  <Link underline="hover" color="inherit" to="/">
                    Home
                  </Link>
                  <Link underline="hover" color="inherit" to="#">
                    Sports
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    to={`/teamsports/soccer/odds/0/false`}
                  >
                    {headerData?.sport?.sportName}
                  </Link>

                  <Typography>{headerData?.tournament?.name}</Typography>
                </Breadcrumbs>
              </Box>
            </Box>
            <Typography variant="h1">{headerData?.tournament?.name}</Typography>
          </Box>
          <Box className="teamsport-data-tab">
            <Tabs
              value={value}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              className="teamsport-data-tab-details"
              // disableRipple
              // disableFocusRipple
              id="news-tab"
              ref={tabsRef}
            >
              {soccerTabData?.map((item, index) => {
                return (
                  <Box key={index}>
                    <Tab
                      label={item?.label}
                      value={item?.value}
                      onChange={() => handleChange(item?.value, item?.label)}
                      className={item?.value == value ? "active" : ""}
                    />
                  </Box>
                );
              })}
            </Tabs>
          </Box>
          <Box className="sport-data-wrap">
            {value === 0 ? (
              <OverviewDetails fetchTableTab={fetchTableTab} />
            ) : value === 1 ? (
              <MatchDetails />
            ) : (
              <TableDetails />
            )}
          </Box>
        </Box>
        <OurPartner />
      </Box>
    </>
  );
};

export default SoccerSportDataPage;
