import React from "react";
import Page from "src/components/Page";
import NewsPage from "src/views/component/newsPage";

const News = () => {
  return (
    <Page title="News">
      <NewsPage />
    </Page>
  );
};

export default News;
