import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Box, Typography } from "@mui/material";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import { Config } from "../../../../../helpers/context/config";
import { IntlContext } from "src/App";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import SoccerCard from "../soccerCard";
import Loader from "src/components/Loader";
import moment from "moment";

const TeamOverviewPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [overviewLoading, setOverViewLoading] = useState(false);
  const [overviewUpcomingData, setOverviewUpcomingData] = useState([]);
  const [overviewResultData, setOverviewResultData] = useState([]);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    fetchOverviewData();
  }, [params]);

  const fetchOverviewData = async () => {
    setOverViewLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/soccer/overview/team/${params?.teamId}`
      );
      if (status === 200) {
        setOverviewUpcomingData(data?.result?.upcoming);
        setOverviewResultData(data?.result?.past);
        setOverViewLoading(false);
      } else {
        setOverViewLoading(false);
      }
    } catch (err) {
      setOverViewLoading(false);
    }
  };
  const handleNavigate = data => {
    const eventSlug = data?.eventName
      ? data?.eventName
          .trim()
          .toLowerCase()
          .replaceAll(" ", "-")
      : "event";
    navigate(
      `/teamsports/soccer/${
        data?.SoccerTournament?.Scd ? data?.SoccerTournament?.Scd : "tournament"
      }/${eventSlug}/info/${data?.SportId}/${data?.SoccerTournamentId}/${
        data?.id
      }`
    );
  };
  return (
    <>
      <Box className="overview-details-wrap">
        {overviewLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : (
          <>
            {overviewUpcomingData?.length > 0 && (
              <>
                <Box className="details-wrap mb-18">
                  <Typography className="overview-title">NEXT MATCH</Typography>
                  {overviewUpcomingData?.map((items, index) => {
                    return (
                      <>
                        {/* <Box
                          className={`light-theme match-details-wrap cursor-pointer`}
                          key={index}
                          onClick={() => handleNavigate(items)}
                        >
                          <Box className="card-wrap">
                            <Box className="d-flex align-center justify-space-around">
                              <Box className="sport-team-name d-flex align-center flex-direction">
                                <Box className="sport-team-img">
                                  <img
                                    src={
                                      items?.homeTeam?.flag
                                        ? Config.baseURL + items?.homeTeam?.flag
                                        : Brisbane
                                    }
                                    alt="img"
                                  />
                                </Box>
                                <Box>
                                  <Typography className="sport-team-name-text fs-16">
                                    {items?.homeTeam?.name}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="sport-score-wrap">
                                <Box>
                                  <Typography className="fs-18">
                                    {items?.ScoreBoard &&
                                    items?.ScoreBoard?.Epr === 0
                                      ? items?.ScoreBoard?.Esd &&
                                        moment(items?.ScoreBoard?.Esd)?.format(
                                          "HH:mm"
                                        )
                                      : moment(items?.startTime)?.format(
                                          "HH:mm"
                                        )}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography className="fs-16">
                                    {items?.ScoreBoard &&
                                    items?.ScoreBoard?.Epr === 0
                                      ? items?.ScoreBoard?.Esd &&
                                        moment(items?.ScoreBoard?.Esd)?.format(
                                          "DD MMM"
                                        )
                                      : moment(items?.startTime)?.format(
                                          "DD MMM"
                                        )}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="sport-team-name d-flex align-center flex-direction">
                                <Box className="sport-team-img">
                                  <img
                                    src={
                                      items?.awayTeam?.flag
                                        ? Config.baseURL + items?.awayTeam?.flag
                                        : Melbourne
                                    }
                                    alt="img"
                                  />
                                </Box>
                                <Box>
                                  <Typography className="sport-team-name-text fs-16">
                                    {items?.awayTeam?.name}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box> */}
                        <SoccerCard
                          theme="light-theme"
                          type={
                            items?.ScoreBoard?.Epr == 0
                              ? "upcoming"
                              : items?.ScoreBoard?.Epr == 2
                              ? "past"
                              : items?.ScoreBoard?.Epr == 1
                              ? "live"
                              : "upcoming"
                          }
                          cardData={items}
                          index={index}
                        />
                      </>
                    );
                  })}
                </Box>
              </>
            )}
            <Box className="details-wrap mb-18">
              <Typography className="overview-title">FORM</Typography>
              {overviewResultData?.map((data, index) => {
                return (
                  <>
                    <SoccerCard
                      theme="light-theme"
                      type={
                        data?.ScoreBoard?.Epr == 0
                          ? "upcoming"
                          : data?.ScoreBoard?.Epr == 2
                          ? "past"
                          : data?.ScoreBoard?.Epr == 1
                          ? "live"
                          : "upcoming"
                      }
                      cardData={data}
                      index={index}
                    />
                  </>
                );
              })}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default TeamOverviewPage;
