import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Tab, Tabs } from "@mui/material";
import banner from "../../../../../assets/images/banner/smart-b-banner-secodary.webp";
import Brisbane from "src/assets/images/brisbane.png";
import { Config } from "../../../../../helpers/context/config";
import { useSelector } from "react-redux";
import OurPartner from "src/views/component/ourPartners";
import { IntlContext } from "src/App";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import TeamOverviewPage from "./teamOverview";
import TeamMatchesPage from "./teamMatches";
import TeamTablesPage from "./teamTables";
import TeamNewsPage from "./teamNews";
import TeamPlayerStatsPage from "./teamPlayerStats";
import "./soccerTeam.scss";

const SoccerTeamDataPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [tabvalue, setTabValue] = useState(0);
  const [teamDetails, setTeamDetails] = useState({});

  const teamData = [
    {
      id: 0,
      name: "Overview"
    },
    {
      id: 1,
      name: "Matches"
    },
    {
      id: 2,
      name: "Tables"
    },
    {
      id: 3,
      name: "News"
    }
    // {
    //   id: 4,
    //   name: "Player Stats"
    // }
  ];

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    fetchTeamData();
  }, [params]);

  const fetchTeamData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/soccer/team/${params?.teamId}`
      );
      if (status === 200) {
        setTeamDetails(data?.result);
      } else {
      }
    } catch (err) {}
  };
  return (<>
    <Box className="content-wrap">
      <Box className="banner-img-fix">
        {fetchPageHeaderAds("218px", banner)}
      </Box>
      <Box className="teamsport-team-data-wrap">
        <Box className="team-info-header">
          <Box className="team-info-img-wrap">
            <img
              src={
                teamDetails?.flag
                  ? Config.mediaURL + teamDetails?.flag
                  : Brisbane
              }
              alt="img"
            />
          </Box>
          <Box className="team-info-details">
            <Typography className="team-info-name">
              {teamDetails?.name}
            </Typography>
            <Typography className="team-country-name"></Typography>
          </Box>
        </Box>
        <Box className="team-sports-tab">
          <Tabs
            value={tabvalue}
            variant="scrollable"
            scrollButtons={false}
            indicatorColor="primary"
            textColor="primary"
            className="teamsport-tab-detail"
          >
            {teamData?.map((item, index) => {
              return (
                <Box key={index}>
                  <Tab
                    label={
                      <Box className="tab-label">
                        <span className="label-name">{item?.name}</span>
                      </Box>
                    }
                    value={item?.id}
                    className={item?.id === tabvalue ? "active " : ""}
                    onChange={(event, newValue) =>
                      handleTabChange(event, item?.id)
                    }
                  />
                </Box>
              );
            })}
          </Tabs>
        </Box>
        <Box className="team-data-details-wrap">
          {tabvalue === 0 ? (
            <TeamOverviewPage />
          ) : tabvalue === 1 ? (
            <TeamMatchesPage />
          ) : tabvalue === 2 ? (
            <TeamTablesPage setHomeTabValue={setTabValue} />
          ) : tabvalue === 3 ? (
            <TeamNewsPage teamDetails={teamDetails} />
          ) : tabvalue === 4 ? (
            <TeamPlayerStatsPage />
          ) : (
            ""
          )}
        </Box>
      </Box>
      <OurPartner />
    </Box>
  </>);
};

export default SoccerTeamDataPage;
