import React, { useEffect, useRef } from "react";
import HTMLReactParser from "html-react-parser";
import { Helmet } from "react-helmet";
import postscribe from "postscribe";
import { useState } from "react";

const DynamicScript = ({
  dynamicscript,
  addetails,
  handleClick,
  margin,
  adRef
}) => {
  const advRef = useRef(null);

  useEffect(() => {
    if (advRef) {
      postscribe(advRef.current, dynamicscript, {
        autoFix: false
      });
    }
  }, []);

  return (
    <div
      onClick={() => handleClick(addetails?.id)}
      style={{ margin: margin ? "0px" : "" }}
      id={`advt_${addetails?.id}`}
      ref={adRef}
      className="ad-banner"
    >
      <div id={`adv_${addetails?.id}`} ref={advRef}></div>
    </div>
  );
};

export default DynamicScript;
