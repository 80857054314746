import { create } from "zustand";

const useAutoSaveStore = create((set, get) => ({
  apiInfo: {},
  setApiInfo: info => {
    set({ apiInfo: info });
  }
}));

export default useAutoSaveStore;
