import React from "react";
import Page from "src/components/Page";
import EnterRacingTipsPage from "src/views/component/TipsMarketplace/enterRacingTips";

const EnterRacingTips = () => {
  return (
    <Page title="Enter Racing Tips">
      <EnterRacingTipsPage />
    </Page>
  );
};

export default EnterRacingTips;
