import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  IconButton,
  Collapse
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ReactComponent as Bat } from "../../../../assets/images/SportIcon/bat.svg";
import { ReactComponent as Ball } from "../../../../assets/images/SportIcon/ball.svg";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { IntlContext } from "src/App";
import NoDataComp from "src/views/component/UI/NoData";
import Loader from "../../../../components/Loader";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import _ from "lodash";

const OverviewDetails = ({ fetchTableTab }) => {
  const params = useParams();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [collapseMenu, setcollapseMenu] = useState(-1);
  const [categoryMenu, setcategoryMenu] = useState(-1);
  const [overviewUpcomingData, setOverviewUpcomingData] = useState([]);
  const [overviewResultData, setOverviewResultData] = useState([]);
  const [overviewLoading, setOverViewLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [tablePointData, setTablePointData] = useState({});

  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    fetchOverviewData();
    fetchTeamTableData();
  }, [params]);

  const fetchOverviewData = async () => {
    setOverViewLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/cricket/overview/tournament/${params?.tournamentId}?timeZone=${timezone}`
      );
      if (status === 200) {
        setOverviewUpcomingData(data?.result?.upcoming);
        setOverviewResultData(data?.result?.past);
        setOverViewLoading(false);
        fetchTableTab(
          data?.result?.upcoming?.[0]?.tableExists,
          data?.result?.past?.[0]?.tableExists
        );
      } else {
        setOverViewLoading(false);
      }
    } catch (err) {
      setOverViewLoading(false);
    }
  };
  const fetchTeamTableData = async () => {
    setTableLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/cricket/table/tournament/${params?.tournamentId}`
      );
      if (status === 200) {
        const groupedData = _.groupBy(data?.result?.result, "name");
        setTablePointData(groupedData);
        setTableLoading(false);
      } else {
        setTableLoading(false);
      }
    } catch (err) {
      setTableLoading(false);
    }
  };

  const getFooterText = (data, toss, choose) => {
    // if (toss == 0 && choose == 0 && data?.ScoreBoard?.Epr === 8) {
    //   return "";
    // } else
    if (toss == 0 && choose == 0) {
      return (
        <Typography className="match-status">
          {data?.ScoreBoard?.ECo && data?.ScoreBoard?.ECo}
        </Typography>
      );
    } else if (toss == 1 && choose == 1) {
      return (
        <Typography className="match-status">
          {`${data?.homeTeam?.name} win the Toss and choose to bat`}
        </Typography>
      );
    } else if (toss == 1 && choose == 2) {
      return (
        <Typography className="match-status">
          {`${data?.homeTeam?.name} win the Toss and choose to bowling`}
        </Typography>
      );
    } else if (toss == 2 && choose == 1) {
      return (
        <Typography className="match-status">
          {`${data?.awayTeam?.name} win the Toss and choose to bat`}
        </Typography>
      );
    } else if (toss == 2 && choose == 2) {
      return (
        <Typography className="match-status">
          {`${data?.awayTeam?.name} win the Toss and choose to bowling`}
        </Typography>
      );
    }
  };

  const handleNavigate = data => {
    const eventSlug = data?.eventName
      ? data?.eventName
          .trim()
          .toLowerCase()
          .replaceAll(" ", "-")
      : "event";
    navigate(
      `/teamsports/cricket/${
        data?.CricketTournament?.Scd
          ? data?.CricketTournament?.Scd
          : "tournament"
      }/${eventSlug}/info/${data?.SportId}/${data?.CricketTournamentId}/${
        data?.id
      }`
    );
  };

  return (
    <>
      <Box className="overview-details-wrap">
        {overviewLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : (
          <>
            {overviewUpcomingData?.length > 0 && (
              <Box className="details-wrap mb-18">
                <Typography className="overview-title">FIXTURES</Typography>
                {overviewUpcomingData?.map(data => {
                  return (
                    <>
                      <Box
                        className="match-details-wrap cursor-pointer"
                        onClick={() => handleNavigate(data)}
                      >
                        {data?.ScoreBoard?.Epr === 1 && (
                          <Box className="divider-indicator"></Box>
                        )}
                        <Box className="d-flex align-center content-space-between">
                          <Typography className="match-title">
                            {data?.ScoreBoard?.EtTx && data?.ScoreBoard?.EtTx}{" "}
                            {data?.ScoreBoard?.ErnInf?.trim() &&
                              `(${data?.ScoreBoard?.ErnInf})`}
                          </Typography>
                          <Typography className="match-title">
                            {" "}
                            {data?.ScoreBoard?.Esd &&
                            data?.ScoreBoard?.Exd > "1"
                              ? data?.ScoreBoard?.Esd &&
                                `${moment(data?.ScoreBoard?.Esd).format(
                                  "DD MMM"
                                )} - ${moment(data?.ScoreBoard?.Esd)
                                  .add(
                                    Number(data?.ScoreBoard?.Exd) - 1,
                                    "days"
                                  )
                                  .format("DD MMM")}`
                              : data?.ScoreBoard?.Esd &&
                                moment(data?.ScoreBoard?.Esd).format("DD MMM")}
                          </Typography>
                        </Box>
                        <Typography className="match-status">
                          {data?.ScoreBoard?.Epr === 1
                            ? ""
                            : data?.ScoreBoard?.Esd &&
                              `${moment(data?.ScoreBoard?.Esd).format(
                                "HH:mm"
                              )} -`}{" "}
                          {data?.ScoreBoard?.EpsL &&
                            `${data?.ScoreBoard?.EpsL}`}
                        </Typography>
                        <Box className="d-flex align-center content-space-between">
                          <Typography className="team-name d-flex col-gap-5">
                            {data?.ScoreBoard?.Epr === 1 ? (
                              <span>
                                {data?.ScoreBoard?.Ebat === 1 ? (
                                  <Bat />
                                ) : (
                                  <Ball />
                                )}
                              </span>
                            ) : (
                              ""
                            )}
                            {data?.homeTeam?.name}
                          </Typography>
                          {data?.ScoreBoard?.Epr === 1 &&
                            (data?.ScoreBoard?.Exd > "1" ? (
                              <Typography className="team-score">
                                {data?.ScoreBoard?.Tr1C1 ?? "-"}
                                {data?.ScoreBoard?.Tr1CW1 &&
                                data?.ScoreBoard?.Tr1CW1 === 10
                                  ? ""
                                  : (data?.ScoreBoard?.Tr1CW1 ||
                                      data?.ScoreBoard?.Tr1CW1 === 0) &&
                                    `/${data?.ScoreBoard?.Tr1CW1}${
                                      data?.ScoreBoard?.Tr1CD1 &&
                                      data?.ScoreBoard?.Tr1CD1 === 1
                                        ? "d"
                                        : ""
                                    }`}{" "}
                                {(data?.ScoreBoard?.Tr1C2 ||
                                  data?.ScoreBoard?.Tr1C2 === 0) &&
                                  `& ${data?.ScoreBoard?.Tr1C2}`}
                                {data?.ScoreBoard?.Tr1CW2 &&
                                data?.ScoreBoard?.Tr1CW2 === 10
                                  ? ""
                                  : (data?.ScoreBoard?.Tr1CW2 ||
                                      data?.ScoreBoard?.Tr1CW2 === 0) &&
                                    `/${data?.ScoreBoard?.Tr1CW2}${
                                      data?.ScoreBoard?.Tr1CD2 &&
                                      data?.ScoreBoard?.Tr1CD2 === 1
                                        ? "d"
                                        : ""
                                    }`}{" "}
                              </Typography>
                            ) : (
                              <Typography className="team-score">
                                {data?.ScoreBoard?.Tr1C1 ?? "-"}
                                {data?.ScoreBoard?.Tr1CW1 &&
                                data?.ScoreBoard?.Tr1CW1 === 10
                                  ? ""
                                  : (data?.ScoreBoard?.Tr1CW1 ||
                                      data?.ScoreBoard?.Tr1CW1 === 0) &&
                                    `/ ${data?.ScoreBoard?.Tr1CW1}`}{" "}
                                <span>
                                  {" "}
                                  {data?.ScoreBoard?.Tr1CO1
                                    ? `(${data?.ScoreBoard?.Tr1CO1})`
                                    : ""}
                                </span>{" "}
                              </Typography>
                            ))}
                        </Box>
                        <Box className="d-flex align-center content-space-between">
                          <Typography className="team-name d-flex col-gap-5">
                            {data?.ScoreBoard?.Epr === 1 ? (
                              <span>
                                {data?.ScoreBoard?.Ebat === 1 ? (
                                  <Ball />
                                ) : (
                                  <Bat />
                                )}
                              </span>
                            ) : (
                              ""
                            )}
                            {data?.awayTeam?.name}
                          </Typography>
                          {data?.ScoreBoard?.Epr === 1 &&
                            (data?.ScoreBoard?.Exd > "1" ? (
                              <Typography className="team-score">
                                {data?.ScoreBoard?.Tr2C1 ?? "-"}
                                {data?.ScoreBoard?.Tr2CW1 &&
                                data?.ScoreBoard?.Tr2CW1 === 10
                                  ? ""
                                  : (data?.ScoreBoard?.Tr2CW1 ||
                                      data?.ScoreBoard?.Tr2CW1 === 0) &&
                                    `/${data?.ScoreBoard?.Tr2CW1}${
                                      data?.ScoreBoard?.Tr2CD1 &&
                                      data?.ScoreBoard?.Tr2CD1 === 1
                                        ? "d"
                                        : ""
                                    }`}{" "}
                                {(data?.ScoreBoard?.Tr2C2 ||
                                  data?.ScoreBoard?.Tr2C2 === 0) &&
                                  `& ${data?.ScoreBoard?.Tr2C2}`}
                                {data?.ScoreBoard?.Tr2CW2 &&
                                data?.ScoreBoard?.Tr2CW2 === 10
                                  ? ""
                                  : (data?.ScoreBoard?.Tr2CW2 ||
                                      data?.ScoreBoard?.Tr2CW2 === 0) &&
                                    `/${data?.ScoreBoard?.Tr2CW2}${
                                      data?.ScoreBoard?.Tr2CD2 &&
                                      data?.ScoreBoard?.Tr2CD2 === 1
                                        ? "d"
                                        : ""
                                    }`}{" "}
                              </Typography>
                            ) : (
                              <Typography className="team-score">
                                {data?.ScoreBoard?.Tr2C1 ?? "-"}
                                {data?.ScoreBoard?.Tr2CW1 &&
                                data?.ScoreBoard?.Tr2CW1 === 10
                                  ? ""
                                  : (data?.ScoreBoard?.Tr2CW1 ||
                                      data?.ScoreBoard?.Tr2CW1 === 0) &&
                                    `/ ${data?.ScoreBoard?.Tr2CW1}`}{" "}
                                <span>
                                  {data?.ScoreBoard?.Tr2CO1
                                    ? `(${data?.ScoreBoard?.Tr2CO1})`
                                    : ""}
                                </span>
                              </Typography>
                            ))}
                        </Box>
                        {data?.ScoreBoard?.Epr === 1 ? (
                          <Typography className="match-status">
                            {data?.ScoreBoard?.ECo && data?.ScoreBoard?.ECo}
                          </Typography>
                        ) : (
                          getFooterText(
                            data,
                            data?.ScoreBoard?.TPa,
                            data?.ScoreBoard?.TCho
                          )
                        )}
                      </Box>
                    </>
                  );
                })}
              </Box>
            )}
            {overviewResultData?.length > 0 && (
              <Box className="details-wrap mb-18">
                <Typography className="overview-title">RESULTS</Typography>
                {overviewResultData?.map(data => {
                  return (
                    <>
                      <Box
                        className="match-details-wrap cursor-pointer"
                        onClick={() => handleNavigate(data)}
                      >
                        <Box className="d-flex align-center content-space-between">
                          <Typography className="match-title">
                            {data?.ScoreBoard?.EtTx && data?.ScoreBoard?.EtTx}{" "}
                            {data?.ScoreBoard?.ErnInf?.trim() &&
                              `(${data?.ScoreBoard?.ErnInf})`}
                          </Typography>
                          <Typography className="match-title">
                            {data?.ScoreBoard?.Esd &&
                            data?.ScoreBoard?.Exd > "1"
                              ? data?.ScoreBoard?.Esd &&
                                `${moment(data?.ScoreBoard?.Esd).format(
                                  "DD MMM"
                                )} - ${moment(data?.ScoreBoard?.Esd)
                                  .add(
                                    Number(data?.ScoreBoard?.Exd) - 1,
                                    "days"
                                  )
                                  .format("DD MMM")}`
                              : data?.ScoreBoard?.Esd &&
                                moment(data?.ScoreBoard?.Esd).format("DD MMM")}
                          </Typography>
                        </Box>
                        <Typography className="match-status">
                          {data?.ScoreBoard?.EpsL && data?.ScoreBoard?.EpsL}
                        </Typography>
                        <Box className="d-flex align-center content-space-between">
                          <Typography className="team-name">
                            {data?.homeTeam?.name}
                          </Typography>
                          {data?.ScoreBoard?.Exd > "1" ? (
                            <Typography className="team-score">
                              {data?.ScoreBoard?.Tr1C1 ?? "-"}
                              {data?.ScoreBoard?.Tr1CW1 &&
                              data?.ScoreBoard?.Tr1CW1 === 10
                                ? ""
                                : (data?.ScoreBoard?.Tr1CW1 ||
                                    data?.ScoreBoard?.Tr1CW1 === 0) &&
                                  `/${data?.ScoreBoard?.Tr1CW1}${
                                    data?.ScoreBoard?.Tr1CD1 &&
                                    data?.ScoreBoard?.Tr1CD1 === 1
                                      ? "d"
                                      : ""
                                  }`}{" "}
                              {(data?.ScoreBoard?.Tr1C2 ||
                                data?.ScoreBoard?.Tr1C2 === 0) &&
                                `& ${data?.ScoreBoard?.Tr1C2}`}
                              {data?.ScoreBoard?.Tr1CW2 &&
                              data?.ScoreBoard?.Tr1CW2 === 10
                                ? ""
                                : (data?.ScoreBoard?.Tr1CW2 ||
                                    data?.ScoreBoard?.Tr1CW2 === 0) &&
                                  `/${data?.ScoreBoard?.Tr1CW2}${
                                    data?.ScoreBoard?.Tr1CD2 &&
                                    data?.ScoreBoard?.Tr1CD2 === 1
                                      ? "d"
                                      : ""
                                  }`}{" "}
                            </Typography>
                          ) : (
                            <Typography className="team-score">
                              {data?.ScoreBoard?.Tr1C1 ?? "-"}
                              {data?.ScoreBoard?.Tr1CW1 &&
                              data?.ScoreBoard?.Tr1CW1 === 10
                                ? ""
                                : (data?.ScoreBoard?.Tr1CW1 ||
                                    data?.ScoreBoard?.Tr1CW1 === 0) &&
                                  `/ ${data?.ScoreBoard?.Tr1CW1}`}{" "}
                              <span>
                                {" "}
                                {data?.ScoreBoard?.Tr1CO1
                                  ? `(${data?.ScoreBoard?.Tr1CO1})`
                                  : ""}
                              </span>{" "}
                            </Typography>
                          )}
                        </Box>
                        <Box className="d-flex align-center content-space-between">
                          <Typography className="team-name">
                            {data?.awayTeam?.name}
                          </Typography>
                          {data?.ScoreBoard?.Exd > "1" ? (
                            <Typography className="team-score">
                              {data?.ScoreBoard?.Tr2C1 ?? "-"}
                              {data?.ScoreBoard?.Tr2CW1 &&
                              data?.ScoreBoard?.Tr2CW1 === 10
                                ? ""
                                : (data?.ScoreBoard?.Tr2CW1 ||
                                    data?.ScoreBoard?.Tr2CW1 === 0) &&
                                  `/${data?.ScoreBoard?.Tr2CW1}${
                                    data?.ScoreBoard?.Tr2CD1 &&
                                    data?.ScoreBoard?.Tr2CD1 === 1
                                      ? "d"
                                      : ""
                                  }`}{" "}
                              {(data?.ScoreBoard?.Tr2C2 ||
                                data?.ScoreBoard?.Tr2C2 === 0) &&
                                `& ${data?.ScoreBoard?.Tr2C2}`}
                              {data?.ScoreBoard?.Tr2CW2 &&
                              data?.ScoreBoard?.Tr2CW2 === 10
                                ? ""
                                : (data?.ScoreBoard?.Tr2CW2 ||
                                    data?.ScoreBoard?.Tr2CW2 === 0) &&
                                  `/${data?.ScoreBoard?.Tr2CW2}${
                                    data?.ScoreBoard?.Tr2CD2 &&
                                    data?.ScoreBoard?.Tr2CD2 === 1
                                      ? "d"
                                      : ""
                                  }`}{" "}
                            </Typography>
                          ) : (
                            <Typography className="team-score">
                              {data?.ScoreBoard?.Tr2C1 ?? "-"}
                              {data?.ScoreBoard?.Tr2CW1 &&
                              data?.ScoreBoard?.Tr2CW1 === 10
                                ? ""
                                : (data?.ScoreBoard?.Tr2CW1 ||
                                    data?.ScoreBoard?.Tr2CW1 === 0) &&
                                  `/ ${data?.ScoreBoard?.Tr2CW1}`}{" "}
                              <span>
                                {data?.ScoreBoard?.Tr2CO1
                                  ? `(${data?.ScoreBoard?.Tr2CO1})`
                                  : ""}
                              </span>
                            </Typography>
                          )}
                        </Box>
                        <Typography className="match-status">
                          {data?.ScoreBoard?.ECo && data?.ScoreBoard?.ECo}
                        </Typography>
                      </Box>
                    </>
                  );
                })}
              </Box>
            )}
          </>
        )}
        <Box className="details-wrap mb-18">
          {tableLoading ? (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          ) : (
            <>
              <>
                {Object.keys(tablePointData)?.length > 0 && (
                  <>
                    <Typography className="overview-title">TABLE</Typography>
                    <Box className="short-name-details">
                      <Typography className="short-name">
                        M - <span>Played</span> , W - <span>Win</span>, L -{" "}
                        <span>Loss</span>, T - <span>Ties</span>, N/R -{" "}
                        <span>No Results</span>, PTS - <span>Points</span>, NRR
                        - <span>Net Run Rate</span>
                      </Typography>
                    </Box>
                    {screenWidth >= 1023
                      ? Object.entries(tablePointData)?.map(
                          ([category, items]) => (
                            <TableContainer
                              id="details-table"
                              className="info-table-cont-wrap"
                            >
                              <Table
                                className="data-table"
                                style={{
                                  minWidth: "max-content",
                                  height: "100%"
                                }}
                              >
                                <TableHead className="table-header">
                                  <TableRow className="table-head-row ">
                                    <TableCell>#</TableCell>
                                    <TableCell>{category}</TableCell>
                                    <TableCell>M</TableCell>
                                    <TableCell>W</TableCell>
                                    <TableCell>L</TableCell>
                                    <TableCell>T</TableCell>
                                    <TableCell>N/R</TableCell>
                                    <TableCell>PTS</TableCell>
                                    <TableCell>NRR</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody className="table-body">
                                  {items?.map((item, i) => {
                                    return (
                                      <>
                                        <TableRow
                                          key={i}
                                          className="table-body-row "
                                        >
                                          <TableCell>{item?.rnk}</TableCell>
                                          <TableCell>{item?.Tnm}</TableCell>
                                          <TableCell>{item?.pld}</TableCell>
                                          <TableCell>{item?.win}</TableCell>
                                          <TableCell>{item?.lstn}</TableCell>
                                          <TableCell>{item?.drw}</TableCell>
                                          <TableCell>{item?.nr}</TableCell>
                                          <TableCell>{item?.pts}</TableCell>
                                          <TableCell>{item?.nrr}</TableCell>
                                        </TableRow>
                                      </>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )
                        )
                      : Object.entries(tablePointData)?.map(
                          ([category, items], categoryIndex) => {
                            return (
                              <>
                                <Box className="mobile-info-table">
                                  <Box className="d-flex align-center mobile-table-heading">
                                    <Typography
                                      variant="h6"
                                      className="table-heading heading-pos"
                                    >
                                      #
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      className="table-heading"
                                    >
                                      {category}
                                    </Typography>
                                  </Box>
                                  {items?.map((item, index) => (
                                    <>
                                      <Box
                                        className={
                                          collapseMenu !== index ||
                                          categoryMenu !== categoryIndex
                                            ? "info-table-border-bottom info-table-deatils"
                                            : "info-table-deatils"
                                        }
                                        onClick={() => {
                                          setcollapseMenu(
                                            collapseMenu === index &&
                                              categoryMenu === categoryIndex
                                              ? -1
                                              : index
                                          );
                                          setcategoryMenu(
                                            collapseMenu === index &&
                                              categoryMenu === categoryIndex
                                              ? -1
                                              : categoryIndex
                                          );
                                        }}
                                      >
                                        <Box className="d-flex align-center info-table-mobile-header">
                                          <Typography className="details-name">
                                            {item?.rnk}
                                          </Typography>
                                          <Box className="table-body-column">
                                            <Box className="d-flex align-center team-wrap-body">
                                              <Typography className="team-name">
                                                {item?.Tnm}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className="arrow-details">
                                          <IconButton
                                            aria-label="expand row"
                                            size="small"
                                          >
                                            {collapseMenu === index &&
                                            categoryMenu === categoryIndex ? (
                                              <KeyboardArrowUpIcon />
                                            ) : (
                                              <KeyboardArrowDownIcon />
                                            )}
                                          </IconButton>
                                        </Box>
                                      </Box>
                                      <Collapse
                                        in={
                                          collapseMenu === index &&
                                          categoryMenu === categoryIndex
                                        }
                                        timeout="auto"
                                        unmountOnExit
                                        className="collapse-box collapse-box-bottom"
                                      >
                                        <Box className="collapse-deatils">
                                          <Table className="collapse-table">
                                            <TableRow className="collapse-table-row">
                                              <TableCell className="td-left">
                                                M
                                              </TableCell>
                                              <TableCell className="td-right">
                                                {item?.pld}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow className="collapse-table-row">
                                              <TableCell className="td-left">
                                                W
                                              </TableCell>
                                              <TableCell className="td-right">
                                                {item?.win}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow className="collapse-table-row">
                                              <TableCell className="td-left">
                                                L
                                              </TableCell>
                                              <TableCell className="td-right">
                                                {item?.lstn}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow className="collapse-table-row">
                                              <TableCell className="td-left">
                                                T
                                              </TableCell>
                                              <TableCell className="td-right">
                                                {item?.drw}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow className="collapse-table-row">
                                              <TableCell className="td-left">
                                                N/R
                                              </TableCell>
                                              <TableCell className="td-right">
                                                {item?.nr}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow className="collapse-table-row">
                                              <TableCell className="td-left">
                                                PTS
                                              </TableCell>
                                              <TableCell className="td-right">
                                                {item?.pts}
                                              </TableCell>
                                            </TableRow>

                                            <TableRow className="collapse-table-row">
                                              <TableCell className="td-left">
                                                NRR
                                              </TableCell>
                                              <TableCell className="td-right">
                                                {item?.nrr}
                                              </TableCell>
                                            </TableRow>
                                          </Table>
                                        </Box>
                                      </Collapse>
                                    </>
                                  ))}
                                </Box>
                              </>
                            );
                          }
                        )}
                  </>
                )}
              </>
            </>
          )}
        </Box>
      </Box>
      {overviewLoading && tableLoading
        ? ""
        : overviewUpcomingData?.length === 0 &&
          overviewResultData?.length === 0 &&
          Object.keys(tablePointData)?.length === 0 && (
            <Box className="no-match no-match-boxshadow">
              {localesData?.NO_DATA?.NO_OVERVIEW}
            </Box>
          )}
    </>
  );
};

export default OverviewDetails;
