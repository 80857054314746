import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Tab, Tabs } from "@mui/material";
import { useParams } from "react-router";
import { IntlContext } from "src/App";
import { ReactComponent as Soccer } from "../../../../../assets/images/SportIcon/blueSportIcon/Soccer.svg";
import { ReactComponent as YellowCard } from "../../../../../assets/images/SportIcon/footballIcon/yellowCard.svg";
import { ReactComponent as FootballGoalPen } from "../../../../../assets/images/SportIcon/footballIcon/FootballGoalPen.svg";
import { ReactComponent as RedCard } from "../../../../../assets/images/SportIcon/footballIcon/redCard.svg";
import { ReactComponent as FootballOwnGoal } from "../../../../../assets/images/SportIcon/footballIcon/FootballOwnGoal.svg";
import { ReactComponent as FootballGoalPenMiss } from "../../../../../assets/images/SportIcon/footballIcon/FootballGoalPenMiss.svg";

// import { commentaryData } from "./livescoreSummary";
// import { liveData } from "./livescoreData";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { commentary, eventData } from "src/helpers/jsondata";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

const summaryTabData = [
  {
    id: 1,
    name: "Events"
  },
  {
    id: 2,
    name: "Commentary"
  }
];

const InfoSummary = ({ singleMatchData }) => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [tabvalue, setTabValue] = useState(1);
  const [wicketTabvalue, setWicketTabvalue] = useState(1);
  const [commentaryData, setCommentaryData] = useState([]);
  const [commentaryLoading, setCommentaryLoading] = useState(false);
  const [matchEventData, setMatchEventData] = useState({});
  const [eventLoading, setEventLoading] = useState(false);
  const [scoreBoard, setScoreBoard] = useState({});
  const [commentaryCount, setCommentaryCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const rowPerPage = 100;

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    fetchMatchEvent();
    fetchMatchCommentary(0);
  }, [params]);

  const fetchMatchEvent = async () => {
    setEventLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `sports/statistics/soccer/event/Incidents/${params?.id}`
      );
      if (status === 200) {
        const responseData = data?.result?.result?.[0]?.SoccerIncidents;
        const ScoreBoard = data?.result?.result?.[0]?.ScoreBoard;
        if (responseData?.length > 0) {
          const groupedData = _.groupBy(
            responseData,
            // "Min"
            // "group"
            item =>
              item?.Min +
              (item?.MinEx ? "." + item?.MinEx.toString().padStart(2, "0") : "")
          );
          const organized_data = {};
          for (const key in groupedData) {
            organized_data[key] = [{ HOME: [], AWAY: [] }];

            const currentData = groupedData[key];
            for (const item of currentData) {
              if (item?.Nm === 1) {
                organized_data[key][0].HOME.push(item);
              } else if (item?.Nm === 2) {
                organized_data[key][0].AWAY.push(item);
              }
            }
          }
          const transitionIndex = _.findIndex(
            _.keys(organized_data).sort((a, b) => Number(a) - Number(b)),
            key => Number(key) >= Number(46)
          );
          let objArray = _.keys(organized_data)
            .sort((a, b) => Number(a) - Number(b))
            ?.map(key => {
              let myObj = { key, value: organized_data[key] };
              return myObj;
            });

          // const HTSocre = ScoreBoard?.Trh1 + " - " + ScoreBoard?.Trh2;
          // const objtoInsert = { key: "HT", value: [{ HTSocre }] };
          // objArray.splice(transitionIndex, 0, objtoInsert);

          if (transitionIndex !== -1) {
            const HTSocre = ScoreBoard?.Trh1 + " - " + ScoreBoard?.Trh2;
            const objtoInsert = { key: "HT", value: [{ HTSocre }] };
            objArray.splice(transitionIndex, 0, objtoInsert);
          }

          const newArray = objArray?.map(item => {
            const key = "-" + item?.key;
            const newValue = item?.value;
            return { [key]: newValue?.length ? newValue : [{}] };
          });
          const newObj = Object.assign({}, ...newArray);
          setMatchEventData(newObj);
        }
        setEventLoading(false);
        setScoreBoard(ScoreBoard);
      } else {
        setEventLoading(false);
      }
    } catch (err) {
      setEventLoading(false);
    }
  };

  const fetchMatchCommentary = async offset => {
    setCommentaryLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/soccer/event/commentary/${params?.id}?limit=${rowPerPage}&offset=${offset}`
      );
      if (status === 200) {
        let count = data?.result?.count / 100;
        setCommentaryCount(Math.ceil(count));
        setCommentaryData(data?.result?.result);
        setCommentaryLoading(false);
      } else {
        setCommentaryLoading(false);
      }
    } catch (err) {
      setCommentaryLoading(false);
    }
  };

  const handleOnScrollBottomMatchCommentary = async offset => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/soccer/event/commentary/${params?.id}?limit=${rowPerPage}&offset=${offset}`
      );
      if (status === 200) {
        setOffset(offset);
        setCommentaryData([...commentaryData, ...data?.result?.result]);
      } else {
      }
    } catch (err) {}
  };

  // const commentaryData = [];

  const footballType = type => {
    if (type === 36) {
      return <Soccer />;
    } else if (type === 37) {
      return <FootballGoalPen />;
    } else if (type === 43) {
      return <YellowCard />;
    } else if (type === 45) {
      return <RedCard />;
    } else if (type === 39) {
      return <FootballOwnGoal />;
    } else if (type === 38) {
      return <FootballGoalPenMiss />;
    } else {
      return "";
    }
  };
  return (<>
    {Object.keys(matchEventData)?.length > 0 || commentaryData?.length > 0 ? (
      <Box className="fixture-summary-wrap scorecard-wrap">
        <Tabs
          value={tabvalue}
          variant="scrollable"
          scrollButtons={false}
          indicatorColor="primary"
          textColor="primary"
          className="scorecard-tab-detail"
        >
          {summaryTabData?.map((item, index) => {
            return (
              <Box key={index}>
                <Tab
                  label={
                    <Box className="d-flex align-center team-wrap">
                      <span>{item?.name}</span>
                    </Box>
                  }
                  value={item?.id}
                  className={item?.id === tabvalue ? "active " : ""}
                  onChange={(event, newValue) =>
                    handleTabChange(event, item?.id)
                  }
                />
              </Box>
            );
          })}
        </Tabs>
        {tabvalue === 1 ? (
          eventLoading ? (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          ) : Object.keys(matchEventData)?.length > 0 ? (
            <Box className="summary-commentary-wrap">
              <Box className="commentary-section">
                {Object.entries(matchEventData)?.map(
                  ([category, items], index) => {
                    return (
                      <>
                        <Box
                          className={
                            category === "-HT"
                              ? "d-flex commentary-wrap break-time"
                              : "d-flex commentary-wrap"
                          }
                          key={index}
                        >
                          <Box
                            className={
                              category === "-HT" ? "w-50" : "w-25 w-10"
                            }
                          >
                            <Typography className="fs-14">
                              {/* {category} */}
                              {category
                                ?.replace("-", "")
                                ?.split(".")
                                ?.map((part, index) =>
                                  index === 0
                                    ? part
                                    : ` + ${parseInt(
                                        part.replace("-", ""),
                                        10
                                      )}`
                                )
                                .join(" ") ?? ""}
                              {category === "-HT" ? "" : "'"}
                            </Typography>
                          </Box>
                          {category === "-HT" ? (
                            <Box className="w-50">{items?.[0]?.HTSocre}</Box>
                          ) : (
                            <>
                              <Box className="w-25 w-35">
                                {items?.[0]?.HOME?.length > 0 &&
                                  items?.[0]?.HOME?.map((home, index) => {
                                    return (
                                      <>
                                        <Box
                                          className="d-flex align-center col-gap-5"
                                          key={index}
                                        >
                                          <Box>
                                            <Typography className="fs-14">
                                              {home?.Fn && home?.Ln
                                                ? `${home?.Fn.charAt(0)}${
                                                    home?.Fn && home?.Ln
                                                      ? "."
                                                      : ""
                                                  } ${home?.Ln}`
                                                : home?.SoccerPlayer?.name}
                                            </Typography>
                                            {/* {home?.assist && (
                                      <Typography className="fs-14">
                                        {home?.assist?.[0]?.shortName}
                                      </Typography>
                                    )} */}
                                          </Box>
                                          <Box className="d-flex align-center football-icon">
                                            {home.IT == 37 ||
                                            home.IT == 38 ? (
                                              <span>PEN</span>
                                            ) : home.IT == 39 ? (
                                              <span>OG</span>
                                            ) : (
                                              <></>
                                            )}
                                            {footballType(home?.IT)}
                                          </Box>
                                        </Box>
                                      </>
                                    );
                                  })}
                              </Box>
                              <Box className="w-25 w-20">
                                {items?.[0]?.HOME?.length > 0
                                  ? items?.[0]?.HOME?.[0]?.Sc && (
                                      <Box>
                                        <Typography className="fs-14">
                                          {JSON.parse(
                                            items?.[0]?.HOME?.[0]?.Sc
                                          )?.join("-")}
                                        </Typography>
                                      </Box>
                                    )
                                  : items?.[0]?.AWAY?.length > 0
                                  ? items?.[0]?.AWAY?.[0]?.Sc && (
                                      <Box>
                                        <Typography className="fs-14">
                                          {JSON.parse(
                                            items?.[0]?.AWAY?.[0]?.Sc
                                          )?.join("-")}
                                        </Typography>
                                      </Box>
                                    )
                                  : ""}
                              </Box>
                              <Box className="w-25 w-35">
                                {items?.[0]?.AWAY?.length > 0 &&
                                  items?.[0]?.AWAY?.map((away, index) => {
                                    return (
                                      <>
                                        <Box
                                          className="d-flex align-center col-gap-5"
                                          key={index}
                                        >
                                          <Box className="d-flex align-center football-icon">
                                            {away.IT == 37 ||
                                            away.IT == 38 ? (
                                              <span>PEN</span>
                                            ) : away.IT == 39 ? (
                                              <span>OG</span>
                                            ) : (
                                              <></>
                                            )}
                                            {footballType(away?.IT)}
                                          </Box>
                                          <Box>
                                            <Typography className="fs-14">
                                              {away?.Fn && away?.Ln
                                                ? `${away?.Fn.charAt(0)}${
                                                    away?.Fn && away?.Ln
                                                      ? "."
                                                      : ""
                                                  } ${away?.Ln}`
                                                : away?.SoccerPlayer?.name}
                                            </Typography>
                                            {/* {away?.assist && (
                                      <Typography className="fs-14">
                                        {away?.assist?.[0]?.shortName}
                                      </Typography>
                                    )} */}
                                          </Box>
                                        </Box>
                                      </>
                                    );
                                  })}
                              </Box>
                            </>
                          )}
                        </Box>
                      </>
                    );
                  }
                )}
                {/* <Box className="d-flex commentary-wrap break-time">
              <Box className="w-50">
                <Typography className="fs-14">HT</Typography>
              </Box>
              <Box className="w-50">
                <Typography className="fs-14">2-0</Typography>
              </Box>
            </Box> */}
                {/* {Object.entries(eventData?.incs?.football2)?.map(
                  ([category, items]) => {
                    return (
                      <>
                        <Box className="d-flex commentary-wrap">
                          <Box className="w-25 w-10">
                            <Typography className="fs-14">
                              {category}'
                            </Typography>
                          </Box>
                          <Box className="w-25 w-35">
                            {items?.[0]?.HOME?.length > 0 &&
                              items?.[0]?.HOME?.map((home, index) => {
                                return (
                                  <>
                                    <Box
                                      className="d-flex align-center col-gap-5"
                                      key={index}
                                    >
                                      <Box>
                                        <Typography className="fs-14">
                                          {home?.shortName}
                                        </Typography>
                                        {home?.assist && (
                                          <Typography className="fs-14">
                                            {home?.assist?.[0]?.shortName}
                                          </Typography>
                                        )}
                                      </Box>
                                      <Box className="d-flex">
                                        {footballType(home?.type)}
                                      </Box>
                                    </Box>
                                  </>
                                );
                              })}
                          </Box>
                          <Box className="w-25 w-20">
                            {items?.[0]?.HOME?.length > 0
                              ? items?.[0]?.HOME?.map((home, index) => {
                                  return (
                                    <>
                                      {home?.score && (
                                        <Box>
                                          <Typography className="fs-14">
                                            {home?.score?.home}-
                                            {home?.score?.away}
                                          </Typography>
                                        </Box>
                                      )}
                                    </>
                                  );
                                })
                              : items?.[0]?.AWAY?.length > 0
                              ? items?.[0]?.AWAY?.map((away, index) => {
                                  return (
                                    <>
                                      {away?.score && (
                                        <Box>
                                          <Typography className="fs-14">
                                            {away?.score?.home}-
                                            {away?.score?.away}
                                          </Typography>
                                        </Box>
                                      )}
                                    </>
                                  );
                                })
                              : ""}
                          </Box>
                          <Box className="w-25 w-35">
                            {items?.[0]?.AWAY?.length > 0 &&
                              items?.[0]?.AWAY?.map((away, index) => {
                                return (
                                  <>
                                    <Box
                                      className="d-flex align-center col-gap-5"
                                      key={index}
                                    >
                                      <Box className="d-flex">
                                        {footballType(away?.type)}
                                      </Box>
                                      <Box>
                                        <Typography className="fs-14">
                                          {away?.shortName}
                                        </Typography>
                                        {away?.assist && (
                                          <Typography className="fs-14">
                                            {away?.assist?.[0]?.shortName}
                                          </Typography>
                                        )}
                                      </Box>
                                    </Box>
                                  </>
                                );
                              })}
                          </Box>
                        </Box>
                      </>
                    );
                  }
                )} */}
                {scoreBoard?.Epr === 2 ? (
                  <Box className="d-flex commentary-wrap break-time">
                    <Box className="w-50">
                      <Typography className="fs-14">FT</Typography>
                    </Box>
                    <Box className="w-50">
                      <Typography className="fs-14">
                        {scoreBoard?.Tr1 + " - " + scoreBoard?.Tr2}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          ) : (
            <Box className="no-match"> {localesData?.NO_DATA?.NO_EVENT}</Box>
          )
        ) : commentaryLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={commentaryData?.length}
            next={() => handleOnScrollBottomMatchCommentary(offset + 100)}
            hasMore={
              commentaryCount !== 0 &&
              commentaryCount !== Math.ceil(offset / 100 + 1)
            }
            loader={
              <div className="allsport-loader-center ">
                <Loader />
              </div>
            }
          >
            {commentaryData?.length > 0 ? (
              <Box className="summary-commentary-wrap">
                <Box className="commentary-section">
                  {commentaryData?.map((item, index) => {
                    return (
                      <>
                        <Box
                          className="d-flex commentary-wrap football-commentary-wrap"
                          key={index}
                        >
                          <Box className="width-15">
                            <Typography className="fs-14">
                              {item?.Min
                                ? item?.Min + (item?.MinEx ? "" : "'")
                                : ""}
                              {item?.MinEx ? "+" + item?.MinEx + "'" : ""}
                            </Typography>
                          </Box>
                          <Box className="width-85 d-flex align-center col-gap-5">
                            {item?.IT == 36 ||
                            item?.IT == 43 ||
                            item?.IT == 45 ||
                            item?.IT == 37 ||
                            item?.IT == 39 ||
                            item?.IT == 38 ? (
                              <Box className="d-flex align-center football-icon">
                                {item.IT == 37 || item.IT == 38 ? (
                                  <span>PEN</span>
                                ) : item.IT == 39 ? (
                                  <span>OG</span>
                                ) : (
                                  <></>
                                )}
                                {footballType(item?.IT)}
                              </Box>
                            ) : (
                              <></>
                            )}
                            <Typography
                              className={
                                item?.IT == 36 ||
                                item?.IT == 43 ||
                                item?.IT == 45 ||
                                item?.IT == 37 ||
                                item?.IT == 39 ||
                                item?.IT == 38
                                  ? "fs-14 incident"
                                  : "fs-14"
                              }
                            >
                              {item?.Txt}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    );
                  })}
                </Box>
              </Box>
            ) : (
              <Box className="no-match">
                {" "}
                {localesData?.NO_DATA?.NO_COMMENTARY}
              </Box>
            )}{" "}
          </InfiniteScroll>
        )}
      </Box>
    ) : (
      <Box className="no-match no-match-boxshadow">
        {" "}
        {localesData?.NO_DATA?.NO_SUMMARY}
      </Box>
    )}
  </>);
};

export default InfoSummary;
