import React from "react";
import { Box } from "@mui/material";
import OurPartner from "../ourPartners";
import FAQsTippingPage from "./faqsTipping";
import "./tippingFAQs.scss";

const TippingFAQsPage = () => {
  return (
    <>
      <Box className="content-wrap FAQs-content">
        <FAQsTippingPage />
        <OurPartner />
      </Box>
    </>
  );
};

export default TippingFAQsPage;
