import React from "react";
import Page from "src/components/Page";
import Maintenance from "src/views/component/Maintenance";

const MaintenancePage = () => {
  return (
    <Page title="Issue page">
      <Maintenance />
    </Page>
  );
};

export default MaintenancePage;
