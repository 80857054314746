import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Config } from "src/helpers/context";
import "./details.scss";
import { useState } from "react";
import TipperChatModal from "../../UI/tipperChatModal";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";

function DetailsTab({ typevalue, params }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  return (
    <>
      <Box className="about-sec">
        <Typography className="joined-txt">Joined SmartB: July 2023</Typography>

        <Box className="details-sec">
          <Typography className="detail-title">Sports</Typography>
          <Box className="details">
            <Typography className="left">Location</Typography>
            <Typography className="right">Australia</Typography>
          </Box>
          <Box className="details">
            <Typography className="left">Favourite Sports</Typography>
            <Typography className="right">[Favourite Sports]</Typography>
          </Box>
          <Box className="details">
            <Typography className="left">Tipping Strategy</Typography>
            <Typography className="right">[Tipping Strategy]</Typography>
          </Box>
          <Box className="details">
            <Typography className="left">Betting Strategy</Typography>
            <Typography className="right">[Betting Strategy]</Typography>
          </Box>
          <Box className="details">
            <Typography className="left">Average Bet</Typography>
            <Typography className="right">[Average Bet]</Typography>
          </Box>
          <Box className="details">
            <Typography className="left">Biggest Bet</Typography>
            <Typography className="right">[Biggest Bet]</Typography>
          </Box>
          <Box className="details">
            <Typography className="left">Biggest Win</Typography>
            <Typography className="right">[Biggest Win]</Typography>
          </Box>
        </Box>
        <Box className="details-sec">
          <Typography className="detail-title">Racing</Typography>
          <Box className="details">
            <Typography className="left">Favourite Horse</Typography>
            <Typography className="right">[Favourite Horse]</Typography>
          </Box>
          <Box className="details">
            <Typography className="left">Favourite Jockey</Typography>
            <Typography className="right">[Favourite Jockey]</Typography>
          </Box>
          <Box className="details">
            <Typography className="left">Favourite Trainer</Typography>
            <Typography className="right">[Favourite Trainer]</Typography>
          </Box>
          <Box className="details">
            <Typography className="left">Tipping Strategy</Typography>
            <Typography className="right">[Tipping Strategy]</Typography>
          </Box>
          <Box className="details">
            <Typography className="left">Betting Strategy</Typography>
            <Typography className="right">[Betting Strategy]</Typography>
          </Box>
          <Box className="details">
            <Typography className="left">Average Bet</Typography>
            <Typography className="right">[Average Bet]</Typography>
          </Box>
          <Box className="details">
            <Typography className="left">Biggest Bet</Typography>
            <Typography className="right">[Biggest Bet]</Typography>
          </Box>
          <Box className="details">
            <Typography className="left">Biggest Win</Typography>
            <Typography className="right">[Biggest Win]</Typography>
          </Box>
        </Box>
      </Box>
      {/* <TipperChatModal
        open={openChatModal}
        handleClose={handleClose}
        title={"Chloe Royle"}
        closeIcon={true}
      /> */}
    </>
  );
}

export default DetailsTab;
