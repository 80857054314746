import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Checkbox,
} from "@mui/material";
import { ReactComponent as Checked } from "src/assets/images/billedChecked.svg";
import { ReactComponent as UnChecked } from "src/assets/images/billedUnChecked.svg";
import { ReactComponent as CheckBoxChecked } from "src/assets/images/checkBoxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/checkboxUnChecked.svg";
import Cvvimage from "../../../../assets/images/cvvimage.png";
import MasterCard from "../../../../assets/images/mastercard.png";
import Visa from "../../../../assets/images/visa.png";
import AmericanExpress from "../../../../assets/images/americanExpress.png";
import { ToastContainer, toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import "./paymentAddEditModal.scss";
import { setApiMessage } from "src/helpers/commonFunctions";

const PaymentAddEditModal = ({ handleCardAddEditModalClose }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [CardSaveLoading, setCardSaveLoading] = useState(false);
  const [cardsave, setCardSave] = useState(false);
  const [cardTypeSave, setCardTypeSave] = useState(false);

  const handleCardSave = (event) => {
    setCardSave(event?.target?.checked);
  };

  const handleCardTypeSave = (event) => {
    setCardTypeSave(event?.target?.checked);
  };

  const validationSchema = Yup.object().shape({
    cardname: Yup.string().required(localesData?.validation?.required_message),
    cardNumber: Yup.string()
      .min(19, "Card number must be at most 16 characters")
      .max(19, "Card number must be at most 16 characters")
      .required("Card number is required"),
    expirationDate: Yup.string()
      .required("Expiration date is required")
      .typeError("Not a valid expiration date. Example: MM/YYYY")
      .max(7, "Not a valid expiration date. Example: MM/YYYY")
      .matches(
        /([0-9]{2})\/([0-9]{4})/,
        "Not a valid expiration date. Example: MM/YYYY",
      )
      .test(
        "is-future",
        "Card expiration date should be in the future",
        function (value) {
          if (!value) return false;
          const currentDate = new Date();
          const [month, year] = value.split("/");
          const cardExpirationDate = new Date(
            parseInt(year),
            parseInt(month) - 1,
          );
          return cardExpirationDate > currentDate;
        },
      )
      .test(
        "not-over-100",
        "Card expiration date should not exceed 100 years in the future",
        function (value) {
          if (!value) return false;
          const currentDate = new Date();
          const [month, year] = value.split("/");
          const cardExpirationDate = new Date(
            parseInt(year),
            parseInt(month) - 1,
          );
          return (
            cardExpirationDate.getFullYear() <= currentDate.getFullYear() + 100
          );
        },
      ),
    cvv: Yup.string().min(3).max(3).required("CVV number is required"),
  });

  return (
    <>
      <Box>
        <Box className="card-checkBox-wrap">
          <FormControlLabel
            className="documentsRead-check"
            control={
              <Checkbox
                className="documentsRead-checkbox"
                checked={cardTypeSave}
                icon={<UnChecked className="radio-icon" />}
                checkedIcon={<Checked className="radio-icon" />}
                onChange={(e) => handleCardTypeSave(e)}
                color="primary"
              />
            }
            label={
              <Box className="left-card-details">
                <Typography className="card-text">
                  Credit or debit card
                </Typography>
                <Box className="card-section">
                  <img src={MasterCard} alt="card" />
                  <img src={Visa} alt="card" />
                  <img src={AmericanExpress} alt="card" />
                </Box>
              </Box>
            }
          />
        </Box>
        <Box className="card-details">
          <Formik
            initialValues={{
              cardname: "",
              cardNumber: "",
              expirationDate: "",
              cvv: "",
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={async (requestData, { resetForm }) => {
              const expirationDate = requestData?.expirationDate;
              const parts = expirationDate.split("/");
              let formattedDate = "";
              if (parts.length === 2) {
                const month = parts[0];
                const year = parts[1];
                formattedDate = `${year}-${month}`;
              }
              let cardPayload = {
                card: {
                  card_holder_name: requestData?.cardname,
                  card_number: requestData?.cardNumber?.replace(/\s/g, ""),
                  card_exp: formattedDate,
                  cvv: requestData?.cvv,
                },
              };

              setCardSaveLoading(true);
              try {
                const { status, data } = await axiosInstance.post(
                  "/subscription/create-card",
                  cardPayload,
                );
                if (status === 200) {
                  setApiMessage("success", data?.message);
                  handleCardAddEditModalClose();
                  setCardSaveLoading(false);
                  setCardSave(false);
                } else {
                  setApiMessage("error", data?.message);
                  setCardSaveLoading(false);
                  setCardSave(false);
                }
              } catch (error) {
                setApiMessage("error", error?.response?.data?.message);
                setCardSaveLoading(false);
                setCardSave(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box className="card-form">
                  <Box className="card-info mb-19">
                    <Typography className="textfield-text">
                      Card Number<span className="star">*</span>
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      className="details-textfield"
                      name="cardNumber"
                      error={Boolean(touched?.cardNumber && errors?.cardNumber)}
                      helperText={touched?.cardNumber && errors?.cardNumber}
                      inputProps={{ maxLength: 19 }}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue(
                          "cardNumber",
                          e?.target?.value
                            .replace(/[^\dA-Z*]/g, "")
                            .replace(/(.{4})/g, "$1 ")
                            .trim(),
                        );
                      }}
                      value={values?.cardNumber}
                      onKeyPress={(e) => {
                        if (!/[0-9 ]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                    />
                  </Box>
                  <Box className="date-cvv-wrap mb-19">
                    <Box className="card-info date-card-info">
                      <Typography className="textfield-text">
                        Expiry Date<span className="star">*</span>
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="details-textfield"
                        placeholder="mm/yyyy"
                        error={Boolean(
                          touched?.expirationDate && errors?.expirationDate,
                        )}
                        helperText={
                          touched?.expirationDate && errors?.expirationDate
                        }
                        name="expirationDate"
                        value={values?.expirationDate}
                        onChange={(e) => {
                          setFieldValue(
                            "expirationDate",
                            e?.target?.value
                              .replace(/[^0-9]/g, "") // To allow only numbers
                              .replace(/^([2-9])$/g, "0$1") // To handle 3 > 03
                              .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2") // 13 > 01/3
                              .replace(/^0{1,}/g, "0") // To handle 00 > 0
                              .replace(
                                /^([0-1]{1}[0-9]{1})([0-9]{1,4}).*/g,
                                "$1/$2",
                              ), // To handle 113 > 11/3
                          );
                        }}
                        inputProps={{ maxLength: 7 }}
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                      />
                    </Box>
                    <Box className="card-info cvv-card-info">
                      <Typography className="textfield-text cvv-text">
                        CVV <span className="star">*</span>
                        <img src={Cvvimage} alt="cvv" />
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="details-textfield"
                        name="cvv"
                        type="password"
                        error={Boolean(touched?.cvv && errors?.cvv)}
                        helperText={touched?.cvv && errors?.cvv}
                        value={values.cvv}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            handleChange(e);
                          }
                        }}
                        inputProps={{ maxLength: 3 }}
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                      />
                    </Box>
                  </Box>
                  <Box className="card-info mb-19">
                    <Typography className="textfield-text">
                      Name on Card<span className="star">*</span>
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      className="details-textfield"
                      error={Boolean(touched.cardname && errors.cardname)}
                      helperText={touched.cardname && errors.cardname}
                      name="cardname"
                      value={values.cardname}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                    />
                  </Box>
                  <Box className="checkBox-wrap">
                    <FormControlLabel
                      className="documentsRead-check"
                      control={
                        <Checkbox
                          className="documentsRead-checkbox"
                          checked={cardsave}
                          icon={<CheckboxUnChecked className="radio-icon" />}
                          checkedIcon={
                            <CheckBoxChecked className="radio-icon" />
                          }
                          onChange={(e) => handleCardSave(e)}
                          color="primary"
                        />
                      }
                      label={
                        <Typography>Save the card for future use</Typography>
                      }
                    />
                  </Box>

                  <Box className="subscribe-wrap-btn">
                    <Button
                      variant="contained"
                      type="submit"
                      className="subscribe-btn"
                      disabled={CardSaveLoading}
                    >
                      {CardSaveLoading ? "Loading..." : "Add card details "}
                    </Button>
                  </Box>

                  <Box className="subscribe-note">
                    <span onClick={() => handleCardAddEditModalClose()}>
                      Close
                    </span>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default PaymentAddEditModal;
