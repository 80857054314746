import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead
} from "@mui/material";
import "./bookmakersinfo.scss";
import { Link, useNavigate } from "react-router-dom";
import DynamicBookkeeperScript from "./DynamicBookkeeperScript";
import { Config } from "src/helpers/context";

// const bulletData = [
//   {
//     id: 0,
//     des:
//       "Nunc fringilla nisl elit, non ornare odio dictum et. Nulla volutpat auctor nibh, sit amet faucibus lorem bibendum eget. Aliquam turpis orci, aliquam at est id, tristique sodales sem."
//   },
//   {
//     id: 1,
//     des: "Curabitur vitae elit a ligula fermentum auctor."
//   },
//   {
//     id: 2,
//     des:
//       "Quisque tortor augue, pretium in mauris id, mattis dapibus nunc. Morbi posuere vel est sit amet iaculis. Integer semper nulla ornare enim blandit rhoncus sit amet bibendum neque."
//   }
// ];

// const tableData1 = [
//   {
//     id: 0,
//     des: "Mauris quis eros cursus, pellentesque nisi vel, facilisisorci."
//   },
//   {
//     id: 1,
//     des: "Mauris quis eros cursus, pellentesque nisi vel, facilisisorci."
//   },
//   {
//     id: 2,
//     des: "Mauris quis eros cursus, pellentesque nisi vel, facilisisorci."
//   },
//   {
//     id: 3,
//     des: "Mauris quis eros cursus, pellentesque nisi vel, facilisisorci."
//   },
//   {
//     id: 4,
//     des: "Mauris quis eros cursus, pellentesque nisi vel, facilisisorci."
//   }
// ];
// const tableData2 = [
//   {
//     id: 0,
//     des1: "Etiam ac hendrerit.",
//     des2: "Mauris quis eros cursus, pellentesque nisi vel, facilisisorci."
//   },
//   {
//     id: 1,
//     des1: "Etiam ac hendrerit.",
//     des2: "Mauris quis eros cursus, pellentesque nisi vel, facilisisorci."
//   },
//   {
//     id: 2,
//     des1: "Etiam ac hendrerit.",
//     des2: "Mauris quis eros cursus, pellentesque nisi vel, facilisisorci."
//   },
//   {
//     id: 3,
//     des1: "Etiam ac hendrerit.",
//     des2: "Mauris quis eros cursus, pellentesque nisi vel, facilisisorci."
//   },
//   {
//     id: 4,
//     des1: "Etiam ac hendrerit.",
//     des2: "Mauris quis eros cursus, pellentesque nisi vel, facilisisorci."
//   }
// ];

const InformationTab = ({ infoTabData, bookkeeperData, showLogo }) => {
  const navigate = useNavigate();
  return (
    <Box className="tab-panel">
      {/* <Box className="img-btn-box">
        <Box className="img-box">
          <img
            src={showLogo(bookkeeperData)}
            alt="bookmaker-image"
            className="bookmaker-image"
          />
        </Box>
        <Box className="join-btn-box">
          <Button
            className="join-btn"
            disableElevation
            disableFocusRipple
            disableRipple
            onClick={() =>
              window.open(bookkeeperData?.BookKeeper?.affiliate_link, "_blank")
            }
          >
            {"Join " +
              (bookkeeperData?.BookKeeper?.name &&
              bookkeeperData?.BookKeeper?.name
                ? bookkeeperData?.BookKeeper?.name
                : "")}
          </Button>
        </Box>
      </Box> */}

      {/* <Typography className="description mt-18">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac
        hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id
        finibus. Fusce dolor nibh, aliquet id rhoncus at, ultrices in velit.
      </Typography> */}

      <Box className="heading-box">
        {infoTabData && Object.entries(infoTabData).length !== 0 && (
          <DynamicBookkeeperScript
            scriptContent={infoTabData?.details?.details}
            className="description mt-15"
          />
        )}
        {(!infoTabData || Object.entries(infoTabData).length === 0) && (
          <Box className="no-data-wrap-bookmakers" style={{ width: "100%" }}>
            <Typography className="text-align no-data">
              No Data Available
            </Typography>
          </Box>
        )}
        {/* <Typography className="heading">Heading1</Typography>
        <Typography className="description mt-15">
          Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue
          id finibus. Fusce dolor nibh, aliquet id rhoncus at, ultrices in
          velit. Aenean id neque a nisi sollicitudin rhoncus a at eros. Cras vel
          justo varius risus facilisis pretium vel quis nunc. Phasellus nisi
          neque, venenatis eget mauris et, ornare feugiat est. Pellentesque
          facilisis libero eu sem porttitor, eget tincidunt diam mollis. Fusce
          ante dolor, dictum non convallis vel, lacinia eget dolor. Morbi tempus
          vehicula nulla, venenatis consectetur est finibus quis. Phasellus ex
          tortor, rhoncus non molestie id, pulvinar vel sapien. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac
          turpis egestas. Nullam fringilla venenatis commodo. Sed lobortis
          scelerisque auctor. Nam at finibus libero. Sed varius lacinia ipsum,
          sed accumsan libero vulputate sit amet.
        </Typography> */}
      </Box>
      {/* <Box className="subheading-box">
        <Typography className="description">SubHeading</Typography>
        <Typography className="description mt-17">
          Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue
          id finibus. Fusce dolor nibh, aliquet id rhoncus at, ultrices in
          velit. Aenean id neque a nisi sollicitudin rhoncus a at eros. Cras vel
          justo varius risus facilisis pretium vel quis nunc.{" "}
        </Typography>
      </Box>

      <Box className="table-box">
        <Typography className="heading">Tabel 1</Typography>
        <TableContainer className="table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell className="table-header-title border-right width65">
                  Lorem Ipsum
                </TableCell>
                <TableCell className="table-header-title text-center">
                  Lorem Ipsum
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData1?.map(row => (
                <TableRow key={row} className="table-row">
                  <TableCell className="table-cell text-left">
                    {row?.des}
                  </TableCell>
                  <TableCell className="table-cell text-center">
                    <Link className="link" to="">
                      Join
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box className="heading-box">
        <Typography className="heading">Heading2</Typography>
        <Typography className="description mt-15">
          Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue
          id finibus. Fusce dolor nibh, aliquet id rhoncus at, ultrices in
          velit. Aenean id neque a nisi sollicitudin rhoncus a at eros. Cras vel
          justo varius risus facilisis pretium vel quis nunc.
        </Typography>

        <ul className="h2-list">
          {bulletData?.map((item, index) => (
            <li className="description" key={index}>
              {item?.des}
            </li>
          ))}
        </ul>
      </Box>

      <Box className="table-box2">
        <Typography className="heading">Tabel 2</Typography>
        <TableContainer className="table-container">
          <Table>
            <TableBody>
              {tableData2?.map(row => (
                <TableRow key={row} className="table-row">
                  <TableCell className="table-cell text-left width23 border-right">
                    {row?.des1}
                  </TableCell>
                  <TableCell className="table-cell">
                    {row?.des2}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box> */}
    </Box>
  );
};

export default InformationTab;
