import React from "react";
import Page from "src/components/Page";
import CartAndPaymentPage from "src/views/component/cartAndPayment";

const CartAndPayment = () => {
  return (
    <Page title="Cart and Payment">
      <CartAndPaymentPage />
    </Page>
  );
};

export default CartAndPayment;
