import React, { useEffect } from "react";
import { Chip } from "@mui/material";
import { useTimer } from "react-timer-hook";
// import { EventEmitter } from "../../../../services/event";

const RaceCountdown = ({ expiryTimestamp, onComplete }) => {
  // EventEmitter.subscribe("Race_time_restart", (time) => {
  //   RefreshTime(time);
  // });
  const { days, seconds, minutes, hours, restart } = useTimer({
    expiryTimestamp,
    // onExpire: () => onComplete(),
  });
  const RefreshTime = (time) => {
    restart(time);
  };

  useEffect(() => {
    RefreshTime(expiryTimestamp);
    // eslint-disable-next-line
  }, [expiryTimestamp]);

  return (
    <>
      {/* RACE START DATE TIME COUNTDOWN */}
      <Chip
        className={"singlerace-count-chip"}
        style={{
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
        size="small"
        label={
          <span>
            {days > 0 ? days + "d" : ""} {hours > 0 ? hours + "h" : "0h"}{" "}
            {minutes > 0 ? minutes + "m" : "0m"}{" "}
            {days > 0 ? "" : seconds > 0 ? seconds + "s" : "0s"}
          </span>
        }
      />
    </>
  );
};
export default RaceCountdown;
