import React, { useState, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as CheckedRadioIcon } from "src/assets/images/icons/CheckedRadioIcon.svg";
import { ReactComponent as UnCheckedRadioIcon } from "src/assets/images/icons/UnCheckedRadioIcon.svg";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import Select, { components } from "react-select";
import Pagination from '@mui/material/Pagination';
import TipsCard from "../tipsCard/tipsCard";
import "./racingTips.scss";
import OurPartner from "../../ourPartners";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import NoDataComp from "../../UI/NoData";
import Loader from "src/components/Loader";

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const RacingTipsData = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
  {
    id: 4
  },
  {
    id: 5
  },
  {
    id: 6
  }
];

const RaceingTipsPage = () => {
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [racingTippersData, setRacingTippersData] = useState([]);
  const [tipperCount, setTipperCount] = useState(0);
  const [tipperPage, setTipperPage] = useState(1);
  const [isTipperLoading, setIsTipperLoading] = useState(false);
  const rowPerPage = 10;

  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds?.[0]?.pageAdLists);
    }
  }, [ReduxAdsData]);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchRacingTipperList(1);
  }, []);

  const fetchRacingTipperList = async page => {
    setIsTipperLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/race/tippers?timezone=${timezone}&startDate=2024-01-09&limit=${rowPerPage}&page=${page}`
      );
      if (status === 200) {
        setRacingTippersData(data?.result);
        setTipperCount(data?.count);
        setIsTipperLoading(false);
        setTipperPage(page);
      } else {
        setIsTipperLoading(false);
      }
    } catch (err) {
      setIsTipperLoading(false);
      console.log("error", err);
    }
  };
  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const handlePaginationClick = (event, page) => {
    fetchRacingTipperList(Number(page));
  };
  const pageNumbers = [];

  if (tipperCount > 0) {
    for (let i = 1; i <= Math.ceil(tipperCount / 10); i++) {
      pageNumbers.push(i);
    }
  }
  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="marketplace-tips-wrap">
          <Box className="marketplace-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  HOME
                </Link>
                <Link underline="hover" color="inherit" to="#">
                  Tips Marketplace
                </Link>
                <Typography>Racing Tips</Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">Racing Tips</Typography>
          </Box>
          <Box className="Marketplace-select-wrap">
            <Box className="filter-type-select">
              <Select
                className="React horse-select"
                // value={SortData?.find(item => {
                //   return item?.value === selectedSort;
                // })}
                // onChange={e => handleSortchange(e)}
                // options={SortData}
                classNamePrefix="select"
                placeholder="All Horse racing"
                components={{ DropdownIndicator }}
              />
              <Select
                className="React period-select"
                // value={SortData?.find(item => {
                //   return item?.value === selectedSort;
                // })}
                // onChange={e => handleSortchange(e)}
                // options={SortData}
                classNamePrefix="select"
                placeholder="Tipping Period:"
                components={{ DropdownIndicator }}
              />
              <Box className="period-select-1">
                {screenWidth > 1023 ? (
                  <FormControlLabel
                    value="selling"
                    className="selling-checkbox"
                    control={
                      <Checkbox
                        size="small"
                        icon={<UnCheckedRadioIcon className="radio-icon" />}
                        checkedIcon={
                          <CheckedRadioIcon className="radio-icon" />
                        }
                        disableRipple
                      />
                    }
                    label="Selling"
                  />
                ) : (
                  <></>
                )}
              </Box>
              <Box className="period-select-1 period-select-2"></Box>

              <Select
                className="React bet-type-select"
                // value={SortData?.find(item => {
                //   return item?.value === selectedSort;
                // })}
                // onChange={e => handleSortchange(e)}
                // options={SortData}
                classNamePrefix="select"
                placeholder="Bet type:"
                components={{ DropdownIndicator }}
              />
              <Select
                className="React rank-by-select"
                // value={SortData?.find(item => {
                //   return item?.value === selectedSort;
                // })}
                // onChange={e => handleSortchange(e)}
                // options={SortData}
                classNamePrefix="select"
                placeholder="Rank by:"
                components={{ DropdownIndicator }}
              />
              <Select
                className="React show-select"
                // value={SortData?.find(item => {
                //   return item?.value === selectedSort;
                // })}
                // onChange={e => handleSortchange(e)}
                // options={SortData}
                classNamePrefix="select"
                placeholder="Show:"
                components={{ DropdownIndicator }}
              />
            </Box>
            {screenWidth < 1023 ? (
              <Box>
                <FormControlLabel
                  value="selling"
                  className="selling-checkbox"
                  control={
                    <Checkbox
                      size="small"
                      icon={<UnCheckedRadioIcon className="radio-icon" />}
                      checkedIcon={<CheckedRadioIcon className="radio-icon" />}
                      disableRipple
                    />
                  }
                  label="Selling"
                />
              </Box>
            ) : (
              <></>
            )}
            <Box className="tip-text">
              <Typography className="text">
                Showing 100 tippers that are selling. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. Praesent dictum finibus ipsum
                id tincidunt.{" "}
              </Typography>
            </Box>
          </Box>
          {isTipperLoading ? (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          ) : (
            <Box className="tips-wrap">
              {racingTippersData?.length > 0 ? (
                <>
                  <Box className="tips-wrap-grid">
                    {racingTippersData?.map((item, index) => {
                      return (
                        <>
                          <TipsCard
                            tipsData={item}
                            index={index}
                            page="racing"
                          />
                        </>
                      );
                    })}
                  </Box>
                  <Box className="tips-Pagination">
                    {/* {params?.categoryId != 1000 ? ( */}
                    <Pagination
                      // hideNextButton
                      // hidePrevButton
                      disabled={tipperCount / rowPerPage > 1 ? false : true}
                      page={tipperPage}
                      onChange={(e, value) => handlePaginationClick(e, value)}
                      count={pageNumbers[pageNumbers?.length - 1]}
                      siblingCount={2}
                      boundaryCount={1}
                      // count={10}
                      size="small"
                    />
                    {/* ) : (
                <></>
              )} */}
                  </Box>
                </>
              ) : (
                <NoDataComp />
              )}
              <OurPartner />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default RaceingTipsPage;
