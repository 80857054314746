import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import SOCBannerScreen from "../../../assets/images/socSignupPage/bannerScreen.png";

const SOCSignupHeader = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/membership");
  };
  return (
    <>
      <Box className="soc-signup-header-wrap">
        <Box className="header-signup-container">
          <Box className="left-header-wrap">
            <Typography className="header-text">
              <span className="highlight-text">Smart</span> Odds
            </Typography>
            <Typography className="header-text header-text-bottom">
              Comparison (SOC)
            </Typography>
            <Typography className="header-details">
              Smart Odds Comparison lets you easily compare real-time odds from
              nearly 100 top bookmakers for horse, harness, and greyhound racing
              with a customizable layout.
            </Typography>
            <Button
              variant="contained"
              className="soc-sign-up-button"
              onClick={() => handleNavigate()}
            >
              Get Better Odds
            </Button>
          </Box>
          <Box className="right-header-wrap">
            <img src={SOCBannerScreen} alt="banner" />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SOCSignupHeader;
