import React from "react";
import Page from "src/components/Page";
import PublicCompsList from "src/views/component/tippingComps/publicComps/PublicCompsList";

const TippingCompsList = () => {
  return (
    <Page title="Public Comps">
      <PublicCompsList />
    </Page>
  );
};

export default TippingCompsList;
