import React from "react";
import Page from "src/components/Page";
import CombatSports from "src/views/component/allsports/combatSports/combatSports";

const CombatSportsPage = () => {
  return (
    <Page title="Combat Sports">
      <CombatSports />
    </Page>
  );
};

export default CombatSportsPage;
