import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  IconButton,
  Collapse,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "../../../../../components/Loader";
import AboveTable from "../../../../../assets/images/ad-placeholder/sports1.webp";
import { useNavigate, useParams } from "react-router-dom";
import { IntlContext } from "src/App";
import NoDataComp from "src/views/component/UI/NoData";

const StickyTableCell = withStyles(theme => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    width: "250px",
    "@media(max-width: 799px)": {
      maxWidth: "222px",
      boxShadow: "inset -2px 0px 0px 0px #ebebeb"
    }
  },
  body: {
    left: 0,
    position: "sticky",
    background: "#FFFFFF",
    "@media(max-width: 799px)": {
      boxShadow: "inset -2px 0px 0px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));
function TrainerStatsDetails({
  name,
  trackName,
  fetchAds,
  trackType,
  trackIdModal
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [collapseMenu, setCollapseMenu] = useState(-1);
  const [collapseMenuSecond, setCollapseMenuSecond] = useState(-1);
  const [AllTimeData, setAllTimeData] = useState([]);
  const [Last12MonthData, setLast12MonthData] = useState([]);
  const [IsAllTimeLoading, setIsAllTimeLoading] = useState(false);
  const [IsLast12MonthLoading, setIsLast12MonthLoading] = useState(false);
  const [MonthOffset, setMonthOffset] = useState(0);
  const [AllOffset, setAllOffset] = useState(0);
  const [MonthAddedDataCount, setMonthAddedDataCount] = useState(0);
  const [AllTimeAddedDataCount, setAllTimeAddedDataCount] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    fetchAllTrainerStatsDetails(0, []);
    fetchMonthTrainerStatsDetails(0, []);
  }, [params?.trackId || trackIdModal]);

  const handlenavigation = data => {
    if (trackType !== "modal") {
      navigate(
        `/statistics/trackprofiles/${
          trackType == "modal" ? trackIdModal : params?.trackId
        }/trainer/${data?.id}`,
        {
          state: {
            data: data,
            name: name,
            trackName: trackName
          }
        }
      );
    }
  };

  const fetchAllTrainerStatsDetails = async (AllOffset, info) => {
    setIsAllTimeLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/getTrainerlist/${
          trackType == "modal" ? trackIdModal : params?.trackId
        }?limit=5&offset=${AllOffset}&duration=`
      );
      if (status === 200) {
        setIsAllTimeLoading(false);
        setAllTimeAddedDataCount(data?.result?.length);
        const JockeyData = [...info, ...data?.result];
        setAllTimeData(JockeyData);
      } else {
        setIsAllTimeLoading(false);
      }
    } catch (error) {
      setIsAllTimeLoading(false);
    }
  };
  const fetchMonthTrainerStatsDetails = async (offset, info) => {
    setIsLast12MonthLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/getTrainerlist/${
          trackType == "modal" ? trackIdModal : params?.trackId
        }?limit=5&offset=${offset}&duration=12`
      );
      if (status === 200) {
        setIsLast12MonthLoading(false);
        setMonthAddedDataCount(data?.result?.length);
        const JockeyMonthData = [...info, ...data?.result];
        setLast12MonthData(JockeyMonthData);
      } else {
        setIsLast12MonthLoading(false);
      }
    } catch (error) {
      setIsLast12MonthLoading(false);
    }
  };

  const handleLast12MoreData = () => {
    setMonthOffset(MonthOffset + 5);
    fetchMonthTrainerStatsDetails(MonthOffset + 5, Last12MonthData);
  };
  const handleLast12LessData = () => {
    setMonthOffset(MonthOffset - 5);
    if (Last12MonthData?.length % 5 === 0) {
      setLast12MonthData(prevArray => prevArray.slice(0, -5));
    } else {
      setLast12MonthData(prevArray => prevArray.slice(0, -MonthAddedDataCount));
    }
  };

  const handleAllMoreData = () => {
    setAllOffset(AllOffset + 5);
    fetchAllTrainerStatsDetails(AllOffset + 5, AllTimeData);
  };
  const handleAllLessData = () => {
    setAllOffset(AllOffset - 5);
    if (AllTimeData?.length % 5 === 0) {
      setAllTimeData(prevArray => prevArray.slice(0, -5));
    } else {
      setAllTimeData(prevArray => prevArray.slice(0, -AllTimeAddedDataCount));
    }
  };

  return (
    <>
      {trackType == "modal" ? "" : fetchAds(1, "102px", AboveTable)}
      <Box className="tariner-details-wrap">
        <Box
          className={
            trackType == "modal"
              ? "tariner-details-wrap-1 modal-tariner-details-wrap-1"
              : "tariner-details-wrap-1"
          }
        >
          {screenWidth > 799 ? (
            <>
              {IsLast12MonthLoading && (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              )}
              {!IsLast12MonthLoading && (
                <>
                  <TableContainer id="details-table" className="details-table">
                    <Table className="table-wrap" style={{ minWidth: 940 }}>
                      <TableHead>
                        <TableRow className="table-head-row ">
                          <StickyTableCell className={classes.head}>
                            {localesData?.sport?.LAST_12_MONTHS}
                          </StickyTableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.STARTS}
                          </TableCell>
                          <TableCell className={classes.head}>1st</TableCell>
                          <TableCell className={classes.head}>2nd</TableCell>
                          <TableCell className={classes.head}>3rd</TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.WIN}%
                          </TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.PLACE}%
                          </TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.AVG_WIN_ODDS}
                          </TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.ROI}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Last12MonthData?.length > 0 ? (
                          Last12MonthData?.map((item, i) => (
                            <TableRow key={i} className="table-body-row ">
                              <StickyTableCell
                                className={`${classes.cell} body-row-name clickable`}
                                onClick={() => handlenavigation(item)}
                              >
                                {item?.name}
                              </StickyTableCell>
                              <TableCell className={classes.cell}>
                                {item?.starts}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {item?.first}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {item?.second}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {item?.third}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {item?.win
                                  ? Number(item?.win).toFixed(2) + "%"
                                  : 0}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {item?.place
                                  ? Number(item?.place).toFixed(2) + "%"
                                  : 0}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {item?.avgWinOdd
                                  ? "$" + Number(item?.avgWinOdd).toFixed(2)
                                  : 0}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {item?.roi}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={12} className="no-data-td">
                              {/* No Data Available */}
                              <NoDataComp />
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {Last12MonthData?.length > 0 ? (
                    <Box className="view-more">
                      {MonthOffset + 5 === Last12MonthData?.length ? (
                        <span
                          className="view-more-text"
                          onClick={() => handleLast12MoreData()}
                        >
                          View More
                        </span>
                      ) : (
                        <></>
                      )}
                      {MonthOffset >= 5 && Last12MonthData?.length > 5 ? (
                        <span
                          className="view-more-text view-lese-text"
                          onClick={() => handleLast12LessData()}
                        >
                          View Less
                        </span>
                      ) : (
                        <></>
                      )}
                    </Box>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Box className="trackprofile-mobile-details">
                <Typography variant="h6" className="trackprofile-heading">
                  Overall Stats
                </Typography>
                {IsLast12MonthLoading && (
                  <div className="allsport-loader-center">
                    <Loader />
                  </div>
                )}
                {!IsLast12MonthLoading &&
                  (Last12MonthData?.length > 0 ? (
                    Last12MonthData?.map((item, index) => (
                      <>
                        <Box
                          className={
                            collapseMenu !== index
                              ? "trackprofile-border-bottom trackprofile-deatils"
                              : "trackprofile-deatils"
                          }
                          onClick={() => {
                            return setCollapseMenu(
                              collapseMenu === index ? -1 : index
                            );
                          }}
                        >
                          <Typography className="details-name">
                            {item?.name}
                          </Typography>
                          <Box className="arrow-details">
                            <IconButton aria-label="expand row" size="small">
                              {collapseMenu === index ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </Box>
                        </Box>
                        <Collapse
                          in={collapseMenu === index}
                          timeout="auto"
                          unmountOnExit
                          className="collapse-box"
                        >
                          <Box className="collapse-deatils">
                            <Table className="collapse-table">
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {localesData?.sport?.STARTS}
                                </TableCell>
                                <TableCell className="td-right">
                                  {" "}
                                  {item?.starts}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">1st</TableCell>
                                <TableCell className="td-right">
                                  {item?.first}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">2nd</TableCell>
                                <TableCell className="td-right">
                                  {item?.second}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">3rd</TableCell>
                                <TableCell className="td-right">
                                  {item?.third}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {localesData?.sport?.WIN}%
                                </TableCell>
                                <TableCell className="td-right">
                                  {item?.win
                                    ? Number(item?.win).toFixed(2) + "%"
                                    : 0}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {localesData?.sport?.PLACE}%
                                </TableCell>
                                <TableCell className="td-right">
                                  {item?.place
                                    ? Number(item?.place).toFixed(2) + "%"
                                    : 0}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {localesData?.sport?.AVG_WIN_ODDS}
                                </TableCell>
                                <TableCell className="td-right">
                                  {item?.avgWinOdd
                                    ? "$" + Number(item?.avgWinOdd).toFixed(2)
                                    : 0}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {" "}
                                  {localesData?.sport?.ROI}
                                </TableCell>
                                <TableCell className="td-right">
                                  {item?.roi}
                                </TableCell>
                              </TableRow>
                            </Table>
                          </Box>
                          <Box className="view-profile-box">
                            <Typography
                              variant="h6"
                              className="view-profile"
                              onClick={() => handlenavigation(item)}
                            >
                              View Profile
                            </Typography>
                          </Box>
                        </Collapse>
                      </>
                    ))
                  ) : (
                    <Box className="no-data-mobile">
                      {/* No Data Available */}
                      <NoDataComp />
                    </Box>
                  ))}
                {Last12MonthData?.length > 0 ? (
                  <Box className="view-more">
                    {MonthOffset + 5 === Last12MonthData?.length ? (
                      <span
                        className="view-more-text"
                        onClick={() => handleLast12MoreData()}
                      >
                        View More
                      </span>
                    ) : (
                      <></>
                    )}
                    {MonthOffset >= 5 && Last12MonthData?.length > 5 ? (
                      <span
                        className="view-more-text view-lese-text"
                        onClick={() => handleLast12LessData()}
                      >
                        View Less
                      </span>
                    ) : (
                      <></>
                    )}
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </>
          )}
        </Box>
        <Box
          className={
            trackType == "modal"
              ? "tariner-details-wrap-2 modal-tariner-details-wrap-2"
              : "tariner-details-wrap-2"
          }
        >
          {screenWidth > 799 ? (
            <>
              {IsAllTimeLoading && (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              )}
              {!IsAllTimeLoading && (
                <>
                  <TableContainer id="details-table" className="details-table">
                    <Table className="table-wrap" style={{ minWidth: 940 }}>
                      <TableHead>
                        <TableRow className="table-head-row">
                          <StickyTableCell className={classes.head}>
                            All Time
                          </StickyTableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.STARTS}
                          </TableCell>
                          <TableCell className={classes.head}>1st</TableCell>
                          <TableCell className={classes.head}>2nd</TableCell>
                          <TableCell className={classes.head}>3rd</TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.WIN}%
                          </TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.PLACE}%
                          </TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.AVG_WIN_ODDS}
                          </TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.ROI}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {AllTimeData?.length > 0 ? (
                          AllTimeData?.map((item, i) => (
                            <TableRow key={i} className="table-body-row">
                              <StickyTableCell
                                className={`${classes.cell} body-row-name clickable`}
                                onClick={() => handlenavigation(item)}
                              >
                                {item?.name}
                              </StickyTableCell>
                              <TableCell className={classes.cell}>
                                {item?.starts}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {item?.first}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {item?.second}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {item?.third}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {item?.win
                                  ? Number(item?.win).toFixed(2) + "%"
                                  : 0}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {item?.place
                                  ? Number(item?.place).toFixed(2) + "%"
                                  : 0}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {item?.avgWinOdd
                                  ? "$" + Number(item?.avgWinOdd).toFixed(2)
                                  : 0}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {item?.roi}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={12} className="no-data-td">
                              {/* No Data Available */}
                              <NoDataComp />
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {AllTimeData?.length > 0 ? (
                    <Box className="view-more">
                      {AllOffset + 5 === AllTimeData?.length ? (
                        <span
                          className="view-more-text"
                          onClick={() => handleAllMoreData()}
                        >
                          View More
                        </span>
                      ) : (
                        <></>
                      )}
                      {AllOffset >= 5 && AllTimeData?.length > 5 ? (
                        <span
                          className="view-more-text view-lese-text"
                          onClick={() => handleAllLessData()}
                        >
                          View Less
                        </span>
                      ) : (
                        <></>
                      )}
                    </Box>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Box className="trackprofile-mobile-details">
                <Typography variant="h6" className="trackprofile-heading">
                  All Time
                </Typography>
                {IsAllTimeLoading && (
                  <div className="allsport-loader-center">
                    <Loader />
                  </div>
                )}
                {!IsAllTimeLoading &&
                  (AllTimeData?.length > 0 ? (
                    AllTimeData?.map((item, index) => (
                      <>
                        <Box
                          className={
                            collapseMenuSecond !== index
                              ? "trackprofile-border-bottom trackprofile-deatils"
                              : "trackprofile-deatils"
                          }
                          onClick={() => {
                            return setCollapseMenuSecond(
                              collapseMenuSecond === index ? -1 : index
                            );
                          }}
                        >
                          <Typography className="details-name">
                            {item?.name}
                          </Typography>
                          <Box className="arrow-details">
                            <IconButton aria-label="expand row" size="small">
                              {collapseMenuSecond === index ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </Box>
                        </Box>
                        <Collapse
                          in={collapseMenuSecond === index}
                          timeout="auto"
                          unmountOnExit
                          className="collapse-box"
                        >
                          <Box className="collapse-deatils">
                            <Table className="collapse-table">
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {localesData?.sport?.STARTS}
                                </TableCell>
                                <TableCell className="td-right">
                                  {" "}
                                  {item?.starts}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">1st</TableCell>
                                <TableCell className="td-right">
                                  {item?.first}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">2nd</TableCell>
                                <TableCell className="td-right">
                                  {item?.second}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">3rd</TableCell>
                                <TableCell className="td-right">
                                  {item?.third}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {localesData?.sport?.WIN}%
                                </TableCell>
                                <TableCell className="td-right">
                                  {item?.win
                                    ? Number(item?.win).toFixed(2) + "%"
                                    : 0}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {localesData?.sport?.PLACE}%
                                </TableCell>
                                <TableCell className="td-right">
                                  {item?.place
                                    ? Number(item?.place).toFixed(2) + "%"
                                    : 0}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {localesData?.sport?.AVG_WIN_ODDS}
                                </TableCell>
                                <TableCell className="td-right">
                                  {item?.avgWinOdd
                                    ? "$" + Number(item?.avgWinOdd).toFixed(2)
                                    : 0}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {" "}
                                  {localesData?.sport?.ROI}
                                </TableCell>
                                <TableCell className="td-right">
                                  {item?.roi}
                                </TableCell>
                              </TableRow>
                            </Table>
                          </Box>
                          <Box className="view-profile-box">
                            <Typography
                              variant="h6"
                              className="view-profile"
                              onClick={() => handlenavigation(item)}
                            >
                              View Profile
                            </Typography>
                          </Box>
                        </Collapse>
                      </>
                    ))
                  ) : (
                    <Box className="no-data-mobile">
                      {/* No Data Available */}
                      <NoDataComp />
                    </Box>
                  ))}
                {AllTimeData?.length > 0 ? (
                  <Box className="view-more">
                    {AllOffset + 5 === AllTimeData?.length ? (
                      <span
                        className="view-more-text"
                        onClick={() => handleAllMoreData()}
                      >
                        View More
                      </span>
                    ) : (
                      <></>
                    )}
                    {AllOffset >= 5 && AllTimeData?.length > 5 ? (
                      <span
                        className="view-more-text view-lese-text"
                        onClick={() => handleAllLessData()}
                      >
                        View Less
                      </span>
                    ) : (
                      <></>
                    )}
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default TrainerStatsDetails;
