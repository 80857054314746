import React from "react";
import Page from "src/components/Page";
import CheckoutPage from "src/views/component/checkout";

const Checkout = () => {
  return (
    <Page title="Checkout">
      <CheckoutPage />
    </Page>
  );
};

export default Checkout;
