import React from "react";
import Page from "src/components/Page";
import GetTips from "src/views/component/getTips/getTips";

function GetTipsPage() {
  return (
    <Page
    //   title="SmartB | Your GetTipsPage"
    //   description="SmartB is a leading online sports news service and an aggregator of odds comparison from the most reliable bookmakers around the globe"
    >
      <GetTips />
    </Page>
  );
}

export default GetTipsPage;
