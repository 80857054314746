import React from "react";
import Page from "src/components/Page";
import BookMakersPage from "src/views/component/bookmakers";

const BookMakers = () => {
  return (
    <Page title="Bookmakers">
      <BookMakersPage />
    </Page>
  );
};

export default BookMakers;
