import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Tab, Tabs } from "@mui/material";
import { IntlContext } from "src/App";
import { Config } from "../../../../../helpers/context/config";
import { useParams } from "react-router-dom";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import NoDataComp from "src/views/component/UI/NoData";

const TeamData = [
  {
    id: 1,
    team: "hometeam",
    keeper: [
      {
        id: 1,
        keeperName: "Raul Jimenez",
        score: 1
      }
    ],
    defenders: [
      {
        id: 1,
        name: "Andreas Pereira",
        score: 18
      },
      {
        id: 2,
        name: "Harry Wilson",
        score: 5
      },
      {
        id: 3,
        name: "Willian",
        score: 4
      },
      {
        id: 4,
        name: "Tosin Adarabioyo",
        score: 13
      }
    ],
    substitutes: [
      {
        id: 1,
        name: "Andreas Pereira",
        score: 18
      },
      {
        id: 2,
        name: "Harry Wilson",
        score: 5
      },
      {
        id: 3,
        name: "Willian",
        score: 4
      },
      {
        id: 4,
        name: "Tosin Adarabioyo",
        score: 13
      }
    ]
  },
  {
    id: 2,
    team: "awayteam",
    keeper: [
      {
        id: 1,
        keeperName: "Carlos Vinicius",
        score: 1
      }
    ],
    defenders: [
      {
        id: 1,
        name: "Jean-Ricner Bellegarde",
        score: 18
      },
      {
        id: 2,
        name: "Sasa Kalajdzic",
        score: 5
      },
      {
        id: 3,
        name: "Matheus Cunha",
        score: 4
      },
      {
        id: 4,
        name: "Tommy Doyle",
        score: 13
      }
    ],
    substitutes: [
      {
        id: 1,
        name: "Jean-Ricner Bellegarde",
        score: 18
      },
      {
        id: 2,
        name: "Sasa Kalajdzic",
        score: 5
      },
      {
        id: 3,
        name: "Matheus Cunha",
        score: 4
      },
      {
        id: 4,
        name: "Tommy Doyle",
        score: 13
      }
    ]
  }
];

const LineUpInfo = ({ fixtureInfoData }) => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [tabvalue, setTabValue] = useState(1);
  const [lineupInfo, setLineupInfo] = useState([]);
  const [isLineupLoading, setIsLineupLoading] = useState(false);

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchLineupInfo();
  }, []);

  const fetchLineupInfo = async () => {
    setIsLineupLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `sports/statistics/cricket/lineups/${params?.id}`
      );
      if (status === 200) {
        setLineupInfo(data?.result);
        setIsLineupLoading(false);
      } else {
        setIsLineupLoading(false);
      }
    } catch (err) {
      setIsLineupLoading(false);
    }
  };

  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Brisbane} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Brisbane} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Melbourne} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Melbourne} alt="Odds Icon" />;
      }
    }
  };
  const homeTeamId = lineupInfo && lineupInfo?.event?.homeTeamId;
  const awayTeamId = lineupInfo && lineupInfo?.event?.awayTeamId;
  const homeTeamData =
    lineupInfo &&
    lineupInfo?.result?.filter(item => item?.teamId === homeTeamId);
  const awayTeamData =
    lineupInfo &&
    lineupInfo?.result?.filter(item => item?.teamId === awayTeamId);

  const cricketTeamData = [
    {
      id: 1,
      team: "hometeam",
      players: homeTeamData,
      teamName: lineupInfo?.event?.homeTeam?.name
    },
    {
      id: 2,
      team: "awayteam",
      players: awayTeamData,
      teamName: lineupInfo?.event?.awayTeam?.name
    }
  ];
  const fetchPlayerDesignation = pos => {
    if (pos === 123) {
      return `(C)`;
    } else if (pos === 124) {
      return `(WK)`;
    } else if (pos === 125) {
      return `(C)(WK)`;
    } else {
      return "";
    }
  };
  return (<>
    {isLineupLoading ? (
      <div className="allsport-loader-center">
        <Loader />
      </div>
    ) : (
      <>
        {lineupInfo?.result?.length > 0 ? (
          <>
            {Number(params?.SportId) !== 4 ? (
              screenWidth >= 1023 ? (
                <Box className="lineup-info-wrap">
                  {TeamData?.map((item, index) => (
                    <>
                      <Box className="team-card" key={index}>
                        <Box className="card-heading">
                          <Box className="d-flex align-center team-wrap">
                            <span className="team-img-wrap">
                              {fetchTeamlogo(fixtureInfoData, item?.team)}
                            </span>
                            <span className="team-name medium lineup-team ">
                              {item?.team === "hometeam"
                                ? fixtureInfoData?.homeTeam?.name
                                  ? fixtureInfoData?.homeTeam?.name
                                  : ""
                                : fixtureInfoData?.awayTeam?.name
                                ? fixtureInfoData?.awayTeam?.name
                                : ""}
                            </span>
                          </Box>
                          <Box className="team-score">
                            <Typography>4-2-2-1</Typography>
                          </Box>
                        </Box>
                        <Box className="player-details-wrap">
                          <Typography className="player-postion">
                            Goalkeeper
                          </Typography>
                          {item?.keeper?.map((keeper, keeperIndex) => (
                            <>
                              <Box
                                className="d-flex align-center player-details"
                                key={keeperIndex}
                              >
                                <Typography className="player-name">
                                  {keeper?.keeperName}
                                </Typography>
                                <Typography className="player-score">
                                  {keeper?.score}
                                </Typography>
                              </Box>
                            </>
                          ))}
                        </Box>
                        <Box className="player-details-wrap">
                          <Typography className="player-postion">
                            Defenders
                          </Typography>
                          {item?.defenders?.map(
                            (defenders, defendersIndex) => (
                              <>
                                <Box
                                  className="d-flex align-center player-details"
                                  key={defendersIndex}
                                >
                                  <Typography className="player-name">
                                    {defenders?.name}
                                  </Typography>
                                  <Typography className="player-score">
                                    {defenders?.score}
                                  </Typography>
                                </Box>
                              </>
                            )
                          )}
                        </Box>
                        <Box className="player-details-wrap">
                          <Typography className="player-postion">
                            Substitutes
                          </Typography>
                          {item?.substitutes?.map(
                            (substitutes, substitutesIndex) => (
                              <>
                                <Box
                                  className="d-flex align-center player-details"
                                  key={substitutesIndex}
                                >
                                  <Typography className="player-name">
                                    {substitutes?.name}
                                  </Typography>
                                  <Typography className="player-score">
                                    {substitutes?.score}
                                  </Typography>
                                </Box>
                              </>
                            )
                          )}
                        </Box>
                      </Box>
                    </>
                  ))}
                </Box>
              ) : (
                <Box className="lineup-info-wrap">
                  <Tabs
                    value={tabvalue}
                    variant="scrollable"
                    scrollButtons={false}
                    indicatorColor="primary"
                    textColor="primary"
                    className="lineup-tab-detail"
                  >
                    {TeamData?.map((item, index) => {
                      return (
                        <Box key={index}>
                          <Tab
                            label={
                              <Box className="d-flex align-center team-wrap">
                                <span className="team-img-wrap">
                                  {fetchTeamlogo(fixtureInfoData, item?.team)}
                                </span>
                                <span className="team-name medium lineup-team ">
                                  {item?.team === "hometeam"
                                    ? fixtureInfoData?.homeTeam?.name
                                      ? fixtureInfoData?.homeTeam?.name
                                      : ""
                                    : fixtureInfoData?.awayTeam?.name
                                    ? fixtureInfoData?.awayTeam?.name
                                    : ""}
                                </span>
                              </Box>
                            }
                            value={item?.id}
                            className={item?.id === tabvalue ? "active " : ""}
                            onChange={(event, newValue) =>
                              handleTabChange(event, item?.id)
                            }
                          />
                        </Box>
                      );
                    })}
                  </Tabs>
                  {TeamData?.filter(item => item?.id === tabvalue)?.map(
                    (item, index) => (
                      <>
                        <Box className="team-score">
                          <Typography>4-2-2-1</Typography>
                        </Box>
                        <Box className="player-details-wrap">
                          <Typography className="player-postion">
                            Goalkeeper
                          </Typography>
                          {item?.keeper?.map((keeper, keeperIndex) => (
                            <>
                              <Box
                                className="d-flex align-center player-details"
                                key={keeperIndex}
                              >
                                <Typography className="player-name">
                                  {keeper?.keeperName}
                                </Typography>
                                <Typography className="player-score">
                                  {keeper?.score}
                                </Typography>
                              </Box>
                            </>
                          ))}
                        </Box>
                        <Box className="player-details-wrap">
                          <Typography className="player-postion">
                            Defenders
                          </Typography>
                          {item?.defenders?.map(
                            (defenders, defendersIndex) => (
                              <>
                                <Box
                                  className="d-flex align-center player-details"
                                  key={defendersIndex}
                                >
                                  <Typography className="player-name">
                                    {defenders?.name}
                                  </Typography>
                                  <Typography className="player-score">
                                    {defenders?.score}
                                  </Typography>
                                </Box>
                              </>
                            )
                          )}
                        </Box>
                        <Box className="player-details-wrap">
                          <Typography className="player-postion">
                            Substitutes
                          </Typography>
                          {item?.substitutes?.map(
                            (substitutes, substitutesIndex) => (
                              <>
                                <Box
                                  className="d-flex align-center player-details"
                                  key={substitutesIndex}
                                >
                                  <Typography className="player-name">
                                    {substitutes?.name}
                                  </Typography>
                                  <Typography className="player-score">
                                    {substitutes?.score}
                                  </Typography>
                                </Box>
                              </>
                            )
                          )}
                        </Box>
                      </>
                    )
                  )}
                </Box>
              )
            ) : (
              <>
                {screenWidth >= 1023 ? (
                  <Box className="lineup-info-wrap">
                    {cricketTeamData?.map((item, index) => (
                      <>
                        <Box className="team-card" key={index}>
                          <Box className="card-heading">
                            <Box className="d-flex align-center team-wrap">
                              <span className="team-name medium lineup-team ">
                                {item?.teamName}
                              </span>
                            </Box>
                          </Box>
                          <Box className="player-details-wrap cricket-player-details-wrap">
                            {item?.players?.map((player, keeperIndex) => (
                              <>
                                <Box
                                  className="d-flex align-center player-details cricket-player-details"
                                  key={keeperIndex}
                                >
                                  <Typography className="player-name">
                                    {player?.CricketPlayer?.name
                                      ? player?.CricketPlayer?.name
                                      : ""}
                                    {fetchPlayerDesignation(player?.Pos)}
                                  </Typography>
                                </Box>
                              </>
                            ))}
                          </Box>
                        </Box>
                      </>
                    ))}
                  </Box>
                ) : (
                  <Box className="lineup-info-wrap">
                    <Tabs
                      value={tabvalue}
                      variant="scrollable"
                      scrollButtons={false}
                      indicatorColor="primary"
                      textColor="primary"
                      className="lineup-tab-detail"
                    >
                      {cricketTeamData?.map((item, index) => {
                        return (
                          <Box key={index}>
                            <Tab
                              label={
                                <Box className="d-flex align-center team-wrap">
                                  <span className="team-name medium lineup-team ">
                                    {item?.teamName}
                                  </span>
                                </Box>
                              }
                              value={item?.id}
                              className={
                                item?.id === tabvalue ? "active " : ""
                              }
                              onChange={(event, newValue) =>
                                handleTabChange(event, item?.id)
                              }
                            />
                          </Box>
                        );
                      })}
                    </Tabs>
                    {cricketTeamData
                      ?.filter(item => item?.id === tabvalue)
                      ?.map((item, index) => (
                        <>
                          <Box className="player-details-wrap cricket-player-details-wrap">
                            {item?.players?.map((player, keeperIndex) => (
                              <>
                                <Box
                                  className="d-flex align-center player-details cricket-player-details"
                                  key={keeperIndex}
                                >
                                  <Typography className="player-name">
                                    {player?.CricketPlayer?.name
                                      ? player?.CricketPlayer?.name
                                      : ""}
                                    {fetchPlayerDesignation(player?.Pos)}
                                  </Typography>
                                </Box>
                              </>
                            ))}
                          </Box>
                        </>
                      ))}
                  </Box>
                )}
              </>
            )}
          </>
        ) : (
          // <NoDataComp />
          (<Box className="no-match no-match-boxshadow">
            {" "}
            {localesData?.NO_DATA?.NO_LINEUP}
          </Box>)
        )}
      </>
    )}
  </>);
};

export default LineUpInfo;
