import { useEffect } from "react";
import { Modal, Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useParams } from "react-router-dom";
import { IntlContext } from "src/App";
import triangle from "../../../../../assets/images/icons/triangle.svg";
import "./runnerModal.scss";
const RunnerModal = ({
  runnerDatas,
  open,
  onClose,
  runnerInfomation,
  runnerMoney,
  runnerPastPerfomance,
  runnerPrvRuns,
  ModalDataId,
}) => {
  const params = useParams();
  let sportId = params.sportId;
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  //   useEffect(() => {
  //     setrunnerModalData(runnerDatas);
  //   }, [runnerDatas]);
  useEffect(() => {
    setTimeout(() => {
      if (ModalDataId) {
        var section = document.getElementById(ModalDataId);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 1000);
  }, [ModalDataId]);

  return (
    <>
      <Modal className="modal modal-input" open={open} onClose={onClose}>
        <div className="modal-background">
          <div className="close-icon">
            <Typography className="title">Full Form</Typography>
            <CancelIcon className="admin-close-icon" onClick={onClose} />
          </div>
          {runnerDatas.map((item, index) => {
            return (
              <>
                <Box className="Modal-data-container" id={item?.id}>
                  <div className="modal-contanier">
                    <Box className="racer-detail-wrap">
                      <Box className="name-number">
                        <Box className="racer-number">
                          {item?.runnerNumber}.
                        </Box>
                        <Box>
                          <Typography>
                            {item?.animal?.name} ({item?.barrierNumber})
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="racer-List">
                        <Box className="left-sec">
                          {sportId === "1" ? (
                            <Box className="player-detail">
                              <span>Weight:</span>
                              <span>
                                {" "}
                                {Number(item?.JockeyWeight).toFixed(2) +
                                  "Kg"}{" "}
                              </span>
                            </Box>
                          ) : (
                            ""
                          )}
                          <Box className="player-detail">
                            <span>Form:</span>
                            <span></span>
                          </Box>
                        </Box>

                        <Box className="right-sec">
                          <Box className="player-detail">
                            {item?.Jockey ? (
                              <>
                                <span>
                                  {sportId === "2"
                                    ? `${localesData?.RUNNER_TABLE?.DRIVER}: `
                                    : `${localesData?.RUNNER_TABLE?.JOCKEY}: `}
                                </span>
                                <span>{item?.Jockey?.name}</span>
                              </>
                            ) : item?.Trainer ? (
                              <>
                                <span>
                                  {localesData?.RUNNER_TABLE?.TRAINER}:{" "}
                                </span>
                                <span>{item?.Trainer?.name}</span>
                              </>
                            ) : (
                              ""
                            )}
                          </Box>
                          <Box className="player-wrap">
                            {sportId === "1" || sportId === "2" ? (
                              <Box className="player-detail">
                                {item?.Trainer ? (
                                  <>
                                    <span>
                                      {localesData?.RUNNER_TABLE?.TRAINER}:{" "}
                                    </span>
                                    <span>{item?.Trainer?.name}</span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                  <Box className="info-box">
                    <Box className="runner-ul">
                      <img src={triangle} className="triangle" alt="tri" />
                      <ul>
                        <li>
                          <span>
                            {localesData?.RUNNER_TABLE?.COLLAPSE?.AGE}:
                          </span>
                          <span className="title">
                            {item?.RunnerInfos?.[0]?.info?.runner_info?.age
                              ? " " +
                                item?.RunnerInfos?.[0]?.info?.runner_info?.age +
                                "yo"
                              : "-"}
                          </span>
                        </li>
                        <li>
                          <span>
                            {localesData?.RUNNER_TABLE?.COLLAPSE?.TYPE}:
                          </span>
                          <span className="title">
                            {" "}
                            {item?.RunnerInfos?.[0]?.info?.runner_info?.colour
                              ? item?.RunnerInfos?.[0]?.info?.runner_info
                                  ?.colour
                              : "-"}{" "}
                            /{" "}
                            {item?.RunnerInfos?.[0]?.info?.runner_info?.sex
                              ? item?.RunnerInfos?.[0]?.info?.runner_info?.sex
                              : "-"}
                          </span>
                        </li>
                        <li>
                          <span>
                            {" "}
                            {localesData?.RUNNER_TABLE?.COLLAPSE?.SIRE}:
                          </span>
                          <span className="title">
                            {item?.RunnerInfos?.[0]?.info?.sire?.name
                              ? " " + item?.RunnerInfos?.[0]?.info?.sire?.name
                              : "-"}
                          </span>
                        </li>
                        <li>
                          <span>
                            {localesData?.RUNNER_TABLE?.COLLAPSE?.DAM}:
                          </span>
                          <span className="title">
                            {item?.RunnerInfos?.[0]?.info?.dam?.name
                              ? " " + item?.RunnerInfos?.[0]?.info?.dam?.name
                              : "-"}
                          </span>
                        </li>
                      </ul>
                    </Box>
                    <Box className="runner-info-wrap">
                      <Box className="advance-race-detail-wrap">
                        <ul>
                          <li>
                            <span>
                              {" "}
                              {localesData?.RUNNER_TABLE?.COLLAPSE?.PRIZE_MONEY}
                              :
                            </span>
                            <span className="title">
                              {item?.RunnerInfos?.[0]?.info?.runner_info
                                ?.prize_money
                                ? formatter.format(
                                    item?.RunnerInfos?.[0]?.info?.runner_info
                                      ?.prize_money
                                  )
                                : "-"}
                            </span>
                          </li>
                          {/* <li><span>AVG $:</span></li> */}
                          <li>
                            <span>Win %</span>
                            <span className="title">-</span>
                          </li>
                          <li>
                            <span>Place %</span>
                            <span className="title">-</span>
                          </li>
                          <li>
                            <span>Career:</span>
                            <span className="title">
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.overall?.starts
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.overall?.starts
                                : "0"}{" "}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.overall?.wins
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.overall?.wins
                                : "0"}
                              -
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.overall?.seconds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.overall?.seconds
                                : "0"}
                              -
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.overall?.thirds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.overall?.thirds
                                : "0"}
                            </span>
                          </li>
                          {/* </ul> */}
                          {/* <ul> */}
                          <li>
                            <span>{localesData?.RUNNER_TABLE?.TRACK}:</span>
                            <span className="title">
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.track?.starts
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.track?.starts
                                : "0"}{" "}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.track?.wins
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.track?.wins
                                : "0"}
                              -
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.track?.seconds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.track?.seconds
                                : "0"}
                              -
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.track?.thirds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.track?.thirds
                                : "0"}
                            </span>
                          </li>
                          <li>
                            <span>{localesData?.RUNNER_TABLE?.DISTANCE}:</span>
                            <span className="title">
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.distance?.starts
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.distance?.starts
                                : "0"}{" "}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.distance?.wins
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.distance?.wins
                                : "0"}
                              -
                              {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.distance?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.distance?.placings
                                                    : "0"}
                                                  - */}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.distance?.seconds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.distance
                                    ?.seconds
                                : "0"}
                              -
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.distance?.thirds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.distance?.thirds
                                : "0"}
                            </span>
                          </li>
                          <li>
                            <span>Trk/Dst:</span>
                            <span className="title">
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.track_distance
                                ?.starts
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.track_distance
                                    ?.starts
                                : "0"}{" "}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.track_distance?.wins
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.track_distance
                                    ?.wins
                                : "0"}
                              -
                              {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.track_distance?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.track_distance
                                                        ?.placings
                                                    : "0"}
                                                  - */}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.track_distance
                                ?.seconds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.track_distance
                                    ?.seconds
                                : "0"}
                              -
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.track_distance
                                ?.thirds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.track_distance
                                    ?.thirds
                                : "0"}
                            </span>
                          </li>
                          <li>
                            <span>1st Up:</span>
                            <span className="title">
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.first_up?.starts
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.first_up?.starts
                                : "0"}{" "}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.first_up?.wins
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.first_up?.wins
                                : "0"}
                              -
                              {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.first_up?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.first_up?.placings
                                                    : "0"}
                                                  - */}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.first_up?.seconds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.first_up
                                    ?.seconds
                                : "0"}
                              -
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.first_up?.thirds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.first_up?.thirds
                                : "0"}
                            </span>
                          </li>
                          <li>
                            <span>2nd Up:</span>
                            <span className="title">
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.second_up?.starts
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.second_up
                                    ?.starts
                                : "0"}{" "}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.second_up?.wins
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.second_up?.wins
                                : "0"}
                              -
                              {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.second_up?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.second_up?.placings
                                                    : "0"}
                                                  - */}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.second_up?.seconds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.second_up
                                    ?.seconds
                                : "0"}
                              -
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.second_up?.thirds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.second_up
                                    ?.thirds
                                : "0"}
                            </span>
                          </li>
                          {/* </ul> */}
                          {/* <ul> */}
                          <li>
                            <span>Firm:</span>
                            <span className="title">
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.firm?.starts
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.firm?.starts
                                : "0"}{" "}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.firm?.wins
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.firm?.wins
                                : "0"}
                              -
                              {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                                                    ?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                                                        ?.placings
                                                    : "0"}
                                                  - */}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.firm?.seconds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.firm?.seconds
                                : "0"}
                              -
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.firm?.thirds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.firm?.thirds
                                : "0"}
                            </span>
                          </li>
                          <li>
                            <span>Good:</span>
                            <span className="title">
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.good?.starts
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.good?.starts
                                : "0"}{" "}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.good?.wins
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.good?.wins
                                : "0"}
                              -
                              {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                                                    ?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                                                        ?.placings
                                                    : "0"}
                                                  - */}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.good?.seconds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.good?.seconds
                                : "0"}
                              -
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.good?.thirds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.good?.thirds
                                : "0"}
                            </span>
                          </li>
                          <li>
                            <span>Soft:</span>
                            <span className="title">
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.soft?.starts
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.soft?.starts
                                : "0"}{" "}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.soft?.wins
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.soft?.wins
                                : "0"}
                              -
                              {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                                                    ?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                                                        ?.placings
                                                    : "0"}
                                                  - */}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.soft?.seconds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.soft?.seconds
                                : "0"}
                              -
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.soft?.thirds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.soft?.thirds
                                : "0"}
                            </span>
                          </li>
                          <li>
                            <span>Heavy:</span>
                            <span className="title">
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.heavy?.starts
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.heavy?.starts
                                : "0"}{" "}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.heavy?.wins
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.heavy?.wins
                                : "0"}
                              -
                              {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.heavy
                                                    ?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.heavy?.placings
                                                    : "0"}
                                                  - */}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.heavy?.seconds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.heavy?.seconds
                                : "0"}
                              -
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.heavy?.thirds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.heavy?.thirds
                                : "0"}
                            </span>
                          </li>
                          <li>
                            <span>Synthetic:</span>
                            <span className="title">
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.synthetic?.starts
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.synthetic
                                    ?.starts
                                : "0"}{" "}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.synthetic?.wins
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.synthetic?.wins
                                : "0"}
                              -
                              {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.synthetic?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.synthetic?.placings
                                                    : "0"}
                                                  - */}
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.synthetic?.seconds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.synthetic
                                    ?.seconds
                                : "0"}
                              -
                              {item?.RunnerInfos?.[0]?.info
                                ?.past_runner_performances?.synthetic?.thirds
                                ? item?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.synthetic
                                    ?.thirds
                                : "0"}
                            </span>
                          </li>
                        </ul>
                        {/* <ul>
               
               
                <li>
                  <span>Age:</span>
                  <span className="title">
                    {runnerInfomation?.age ? runnerInfomation?.age + "yo" : "-"}
                  </span>
                </li>
                <li>
                  <span>Age:</span>
                  <span className="title">
                    {runnerInfomation?.age ? runnerInfomation?.age + "yo" : "-"}
                  </span>
                </li>
              </ul> */}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            );
          })}{" "}
        </div>
      </Modal>
    </>
  );
};

export default RunnerModal;
