import FeaturedNewsPH from "../../../../../assets/images/drag_drop_placeholder/news_ph.jpg";
import TOD_PH from "../../../../../assets/images/drag_drop_placeholder/tod_ph.jpg";
import FeaturedRacePH from "../../../../../assets/images/drag_drop_placeholder/featured_racing_ph.jpg";
import FeaturedSportPH from "../../../../../assets/images/drag_drop_placeholder/featured_sports_ph.jpg";
import AllRacingPH from "../../../../../assets/images/drag_drop_placeholder/all_races_ph.jpg";
import SportsFixturePH from "../../../../../assets/images/drag_drop_placeholder/sports_fixture_ph.jpg";
import ExpertTipsPH from "../../../../../assets/images/drag_drop_placeholder/expert_tips_ph.jpg";
import RacingResultPH from "../../../../../assets/images/drag_drop_placeholder/racing_result_ph.jpg";
import SportsResultPH from "../../../../../assets/images/drag_drop_placeholder/sports_result_ph.jpg";
import TippingCompPH from "../../../../../assets/images/drag_drop_placeholder/tipping_comp_ph.jpg";
import RankingPH from "../../../../../assets/images/drag_drop_placeholder/rankings_ph.jpg";
import PodcastPH from "../../../../../assets/images/drag_drop_placeholder/podcast_ph.jpg";
import RacingNewsPH from "../../../../../assets/images/drag_drop_placeholder/racing_news_ph.jpg";

export const HomePageListing = [
  {
    id: 1,
    key: "featured-news",
    title: "Featured News SPORTS & RACING",
    isAdded: false,
    ph: FeaturedNewsPH,
  },
  {
    id: 2,
    title: "SmartB's Tip of the Day RACING",
    key: "smartb-tipoftheday-racing",
    isAdded: false,
    ph: TOD_PH,
  },
  {
    id: 7,
    title: "Featured Races RACING",
    key: "featured-races-racing",
    isAdded: false,
    ph: FeaturedRacePH,
  },
  {
    id: 6,
    title: "Featured Fixtures SPORTS",
    key: "featured-fixtures-sports",
    isAdded: false,
    ph: FeaturedSportPH,
  },
  {
    id: 3,
    title: "All Races (Next to Jump) RACING",
    key: "upcoming-races",
    isAdded: false,
    ph: AllRacingPH,
  },
  {
    id: 4,
    title: "All Fixtures SPORTS",
    key: "upcoming-fixtures-sports",
    isAdded: false,
    ph: SportsFixturePH,
  },
  {
    id: 8,
    key: "free-expert-tips-racing",
    title: "Free Experts Tips RACING",
    isAdded: false,
    ph: ExpertTipsPH,
  },
  {
    id: 81,
    title: "Free Experts Tips SPORTS",
    key: "free-expert-tips-sport",
    isAdded: false,
    ph: ExpertTipsPH,
  },
  {
    id: 12,
    title: "Racing Latest Results RACING",
    key: "racing-latest-results",
    isAdded: false,
    ph: RacingResultPH,
  },
  {
    id: 5,
    title: "Latest Results SPORTS",
    key: "latest-results-sports",
    isAdded: false,
    ph: SportsResultPH,
  },
  {
    id: 10,
    title: "Tipping Comps SPORTS",
    key: "tipping-comps-sports",
    isAdded: false,
    ph: TippingCompPH,
  },
  {
    id: 9,
    title: "Tipping Comps Rankings SPORTS",
    key: "tipping-comps-rankings-sports",
    isAdded: false,
    ph: RankingPH,
  },

  {
    id: 11,
    key: "featured-podcasts",
    title: "Featured Podcasts RACING & SPORTS",
    isAdded: false,
    ph: PodcastPH,
  },
];

export const SportPageListing = [
  {
    id: 1,
    title: "Sports News",
    key: "sports-news",
    isAdded: false,
    ph: FeaturedNewsPH,
  },
  {
    id: 16,
    title: "Featured Fixtures SPORTS",
    key: "featured-fixtures-sports",
    isAdded: false,
    ph: FeaturedSportPH,
  },
  {
    id: 17,
    title: "Free Experts Tips SPORTS",
    key: "free-expert-tips-sport",
    isAdded: false,
    ph: ExpertTipsPH,
  },
  {
    id: 2,
    title: "All Fixtures AMERICAN FOOTBALL",
    key: "upcoming-fixtures-american-football",
    isAdded: false,
    ph: SportsFixturePH,
  },
  {
    id: 3,
    title: "All Fixtures AUSTRALIAN RULES",
    key: "upcoming-fixtures-australian-rules",
    isAdded: false,
    ph: SportsFixturePH,
  },
  {
    id: 4,
    title: "All Fixtures BASKETBALL",
    key: "upcoming-fixtures-basketball",
    isAdded: false,
    ph: SportsFixturePH,
  },
  {
    id: 5,
    title: "All Fixtures BASEBALL",
    key: "upcoming-fixtures-Baseball",
    isAdded: false,
    ph: SportsFixturePH,
  },
  {
    id: 6,
    title: "All Fixtures BOXING",
    key: "upcoming-fixtures-boxing",
    isAdded: false,
    ph: SportsFixturePH,
  },

  {
    id: 7,
    title: "All Fixtures CRICKET",
    key: "upcoming-fixtures-cricket",
    isAdded: false,
    ph: SportsFixturePH,
  },
  {
    id: 8,
    title: "All Fixtures GOLF",
    key: "upcoming-fixtures-golf",
    isAdded: false,
    ph: SportsFixturePH,
  },
  {
    id: 9,
    title: "All Fixtures ICE HOCKEY",
    key: "upcoming-fixtures-ice-hockey",
    isAdded: false,
    ph: SportsFixturePH,
  },
  {
    id: 10,
    title: "All Fixtures MIXED MARTIAL ARTS",
    key: "upcoming-fixtures-mixed-martial-arts",
    isAdded: false,
    ph: SportsFixturePH,
  },
  {
    id: 11,
    title: "All Fixtures RUGBY LEAGUE",
    key: "upcoming-fixtures-rugby-league",
    isAdded: false,
    ph: SportsFixturePH,
  },
  {
    id: 12,
    title: "All Fixtures RUGBY UNION",
    key: "upcoming-fixtures-rugby-union",
    isAdded: false,
    ph: SportsFixturePH,
  },
  {
    id: 13,
    title: "All Fixtures SOCCER",
    key: "upcoming-fixtures-soccer",
    isAdded: false,
    ph: SportsFixturePH,
  },
  {
    id: 14,
    title: "All Fixtures TENNIS",
    key: "upcoming-fixtures-tennis",
    isAdded: false,
    ph: SportsFixturePH,
  },
  {
    id: 15,
    title: "Latest Results SPORTS",
    key: "latest-results-sports",
    isAdded: false,
    ph: SportsResultPH,
  },

  {
    id: 18,
    title: "Tipping Comps SPORTS",
    key: "tipping-comps-sports",
    isAdded: false,
    ph: TippingCompPH,
  },
  {
    id: 19,
    title: "Tipping Comps Rankings SPORTS",
    key: "tipping-comps-rankings-sports",
    isAdded: false,
    ph: RankingPH,
  },
  {
    id: 20,
    title: "Featured Podcasts SPORTS",
    key: "featured-podcasts-sports",
    isAdded: false,
    ph: PodcastPH,
  },
];

export const RacingPageListing = [
  {
    id: 1,
    title: "Racing News",
    isAdded: false,
    key: "featured-news-racing",
    ph: FeaturedNewsPH,
  },
  {
    id: 2,
    title: "SmartB's Tip of the Day RACING",
    key: "smartb-tipoftheday-racing",
    isAdded: false,
    ph: TOD_PH,
  },
  {
    id: 4,
    title: "Featured Races RACING",
    key: "featured-races-racing",
    isAdded: false,
    ph: FeaturedRacePH,
  },
  {
    id: 5,
    title: "Free Expert Tips RACING",
    key: "free-expert-tips-racing",
    isAdded: false,
    ph: ExpertTipsPH,
  },
  {
    id: 3,
    title: "All Races - Next to Jump RACING",
    key: "upcoming-races",
    isAdded: false,
    ph: AllRacingPH,
  },
  {
    id: 10,
    title: "Racing Latest Results",
    key: "racing-latest-results",
    isAdded: false,
    ph: RacingResultPH,
  },

  {
    id: 6,
    title: "Horse Racing News",
    key: "horse-racing-news",
    isAdded: false,
    ph: RacingNewsPH,
  },
  {
    id: 7,
    title: "Greyhound Racing News",
    key: "greyhound-racing-news",
    isAdded: false,
    ph: RacingNewsPH,
  },
  {
    id: 8,
    title: "Harness Racing News",
    key: "harness-racing-news",
    isAdded: false,
    ph: RacingNewsPH,
  },
  {
    id: 9,
    title: "Featured Podcasts RACING",
    isAdded: false,
    key: "featured-podcasts-racing",
    ph: PodcastPH,
  },
];
