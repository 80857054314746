import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  IconButton,
  Collapse,
  Tab,
  Tabs
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Brisbane from "src/assets/images/brisbane.png";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import Select, { components } from "react-select";
import { Config } from "src/helpers/context";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { IntlContext } from "src/App";
import NoDataComp from "src/views/component/UI/NoData";
import Loader from "../../../../../components/Loader";
import { useNavigate, useParams } from "react-router";
import _ from "lodash";

const tableTabData = [
  {
    id: 1,
    name: "All"
  },
  {
    id: 2,
    name: "Home"
  },
  {
    id: 3,
    name: "Away"
  }
];

const TeamTablesPage = ({ setHomeTabValue }) => {
  const params = useParams();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [tabvalue, setTabValue] = useState(1);
  const [tableLoading, setTableLoading] = useState(false);
  const [tablePointData, setTablePointData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [collapseMenu, setcollapseMenu] = useState(-1);
  const [categoryMenu, setcategoryMenu] = useState(-1);
  const [tournamentData, setTournamentData] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState(
    params?.tournamentId ? Number(params?.tournamentId) : null
  );

  const handleTabChange = (event, value) => {
    setTabValue(value);
    fetchTeamTableData(selectedTournament, value);
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectIndicator />
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    fetchTournamentDropDownData();
    setTabValue(1);
    fetchTeamTableData(params?.tournamentId, 1);
  }, [params]);

  const fetchTeamTableData = async (id, value) => {
    setTableLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/soccer/table/tournament/${id}?isAll=${
          value === 1 ? true : ""
        }&isHome=${value === 2 ? true : ""}&isAway=${value === 3 ? true : ""}`
      );
      if (status === 200) {
        const groupedData = _.groupBy(data?.result?.result, "name");
        setTablePointData(groupedData);
        setTableLoading(false);
        setSelectedTournament(id);
      } else {
        setTableLoading(false);
      }
    } catch (err) {
      setTableLoading(false);
    }
  };

  const fetchTournamentDropDownData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/soccer/tournament/dropdowns?teamId=${params?.teamId}`
      );
      if (status === 200) {
        let response = data?.result?.result;
        if (response?.length > 0) {
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const sortedData = newdata?.sort((a, b) => {
            return a?.label.localeCompare(b?.label);
          });

          setTournamentData(sortedData);
        } else setTournamentData([]);
      } else {
      }
    } catch (err) {}
  };

  const handleNavigate = data => {
    const teamSlug = data?.Tnm
      ? data?.Tnm.trim()
          .toLowerCase()
          .replaceAll(" ", "-")
          .replaceAll("/", "-")
      : "team";
    navigate(
      `/teamsports/${params?.type}/statistics/team/${teamSlug}/${params?.sportId}/${params?.tournamentId}/${data?.teamId}`
    );
    setHomeTabValue(0);
  };
  return (<>
    <Box className="details-wrap mb-18">
      {tournamentData?.length > 0 ? (
        <Box className="team-tournament-wrap pb-15">
          <Select
            className="React tournament-select"
            value={
              selectedTournament &&
              tournamentData?.find(item => {
                return item?.value === selectedTournament;
              })
            }
            onChange={e => {
              setSelectedTournament(e?.value);
              setTabValue(1);
              fetchTeamTableData(e?.value, 1);
            }}
            options={tournamentData}
            classNamePrefix="select"
            placeholder="All Competitions"
            isSearchable={true}
            components={{ DropdownIndicator }}
          />
        </Box>
      ) : (
        <></>
      )}
      <Tabs
        value={tabvalue}
        variant="scrollable"
        scrollButtons={false}
        indicatorColor="primary"
        textColor="primary"
        className="soccer-details-tab soccer-team-tab-table"
      >
        {tableTabData?.map((item, index) => {
          return (
            <Box key={index}>
              <Tab
                label={
                  <Box className="d-flex align-center match-tab">
                    <span>{item?.name}</span>
                  </Box>
                }
                value={item?.id}
                className={item?.id === tabvalue ? "active " : ""}
                onChange={(event, newValue) =>
                  handleTabChange(event, item?.id)
                }
              />
            </Box>
          );
        })}
      </Tabs>
      {tableLoading ? (
        <div className="allsport-loader-center">
          <Loader />
        </div>
      ) : (
        <>
          {Object.keys(tablePointData)?.length > 0 ? (
            <>
              <Box className="short-name-details">
                <Typography className="short-name">
                  M - <span>Played</span> , W - <span>Win</span>, L -{" "}
                  <span>Loss</span>, T - <span>Ties</span>, N/R -{" "}
                  <span>No Results</span>, PTS - <span>Points</span>, NRR -{" "}
                  <span>Net Run Rate</span>
                </Typography>
              </Box>
              {/* <Box className="d-flex align-center league-name-indicator">
                <Box className="d-flex align-center col-gap-5">
                  <Box className="league-name-color light-blue"></Box>
                  <Box className="league">Champions League</Box>
                </Box>
                <Box className="d-flex align-center col-gap-5">
                  <Box className="league-name-color medium-orange"></Box>
                  <Box className="league">Europa League</Box>
                </Box>
                <Box className="d-flex align-center col-gap-5">
                  <Box className="league-name-color dark-red"></Box>
                  <Box className="league">Relegation</Box>
                </Box>
              </Box> */}
              {screenWidth >= 1023
                ? Object.entries(tablePointData)?.map(([category, items]) => (
                    <TableContainer
                      id="details-table"
                      className="info-table-cont-wrap"
                    >
                      <Table
                        className="data-table"
                        style={{
                          minWidth: "max-content",
                          height: "100%"
                        }}
                      >
                        <TableHead className="table-header">
                          <TableRow className="table-head-row ">
                            <TableCell className="text-align-center">
                              #
                            </TableCell>
                            <TableCell>{category}</TableCell>
                            <TableCell>P</TableCell>
                            <TableCell>W</TableCell>
                            <TableCell>D</TableCell>
                            <TableCell>L</TableCell>
                            <TableCell>F</TableCell>
                            <TableCell>A</TableCell>
                            <TableCell>GD</TableCell>
                            <TableCell>PTS</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="table-body">
                          {items?.map((item, i) => {
                            return (
                              <>
                                <TableRow key={i} className="table-body-row ">
                                  <TableCell className="body-cell-indicator text-align-center">
                                    {/* <Box
                                      className={
                                        item?.phr?.[0] == 20
                                          ? "light-blue league-indicator"
                                          : item?.phr?.[0] == 25
                                          ? "medium-orange league-indicator"
                                          : item?.phr?.[0] == 15
                                          ? "dark-red league-indicator"
                                          : ""
                                      }
                                    ></Box> */}
                                    {item?.rnk}
                                  </TableCell>
                                  <TableCell>
                                    <Box className="d-flex align-center col-gap-5 table-team-logo">
                                      <Box className="logo-wrap">
                                        <img
                                          src={
                                            item?.SoccerTeam?.flag
                                              ? Config.mediaURL +
                                                item?.SoccerTeam?.flag
                                              : Brisbane
                                          }
                                          alt="logo"
                                        />
                                      </Box>
                                      <Typography
                                        className="soccer-tema-name cursor-pointer"
                                        onClick={() => handleNavigate(item)}
                                      >
                                        {item?.Tnm}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell>{item?.pld}</TableCell>
                                  <TableCell>{item?.win}</TableCell>
                                  <TableCell>{item?.drw}</TableCell>
                                  <TableCell>{item?.lst}</TableCell>
                                  <TableCell>{item?.gf}</TableCell>
                                  <TableCell>{item?.ga}</TableCell>
                                  <TableCell>{item?.gd}</TableCell>
                                  <TableCell>{item?.pts}</TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ))
                : Object.entries(tablePointData)?.map(
                    ([category, items], categoryIndex) => {
                      return (
                        <>
                          <Box className="mobile-info-table">
                            <Box className="d-flex align-center mobile-table-heading">
                              <Typography
                                variant="h6"
                                className="table-heading heading-pos"
                              >
                                #
                              </Typography>
                              <Typography
                                variant="h6"
                                className="table-heading"
                              >
                                {category}
                              </Typography>
                            </Box>
                            {items?.map((item, index) => (
                              <>
                                <Box
                                  className={
                                    collapseMenu !== index ||
                                    categoryMenu !== categoryIndex
                                      ? "info-table-border-bottom info-table-deatils"
                                      : "info-table-deatils"
                                  }
                                  onClick={() => {
                                    setcollapseMenu(
                                      collapseMenu === index &&
                                        categoryMenu === categoryIndex
                                        ? -1
                                        : index
                                    );
                                    setcategoryMenu(
                                      collapseMenu === index &&
                                        categoryMenu === categoryIndex
                                        ? -1
                                        : categoryIndex
                                    );
                                  }}
                                >
                                  <Box className="d-flex align-center info-table-mobile-header">
                                    <Typography className="details-name">
                                      {/* <Box
                                        className={
                                          item?.phr?.[0] == 20
                                            ? "light-blue league-indicator"
                                            : item?.phr?.[0] == 25
                                            ? "medium-orange league-indicator"
                                            : item?.phr?.[0] == 15
                                            ? "dark-red league-indicator"
                                            : ""
                                        }
                                      ></Box> */}
                                      {item?.rnk}
                                    </Typography>
                                    <Box className="table-body-column">
                                      <Box className="d-flex align-center team-wrap-body">
                                        <Box className="img-wrap">
                                          <img
                                            src={
                                              item?.SoccerTeam?.flag
                                                ? Config.mediaURL +
                                                  item?.SoccerTeam?.flag
                                                : Brisbane
                                            }
                                            alt="logo"
                                          />
                                        </Box>
                                        <Typography
                                          className="team-name cursor-pointer"
                                          onClick={() => handleNavigate(item)}
                                        >
                                          {item?.Tnm}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box className="arrow-details">
                                    <IconButton
                                      aria-label="expand row"
                                      size="small"
                                    >
                                      {collapseMenu === index &&
                                      categoryMenu === categoryIndex ? (
                                        <KeyboardArrowUpIcon />
                                      ) : (
                                        <KeyboardArrowDownIcon />
                                      )}
                                    </IconButton>
                                  </Box>
                                </Box>
                                <Collapse
                                  in={
                                    collapseMenu === index &&
                                    categoryMenu === categoryIndex
                                  }
                                  timeout="auto"
                                  unmountOnExit
                                  className="collapse-box collapse-box-bottom"
                                >
                                  <Box className="collapse-deatils">
                                    <Table className="collapse-table">
                                      <TableRow className="collapse-table-row">
                                        <TableCell className="td-left">
                                          P
                                        </TableCell>
                                        <TableCell className="td-right">
                                          {item?.pld}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className="collapse-table-row">
                                        <TableCell className="td-left">
                                          W
                                        </TableCell>
                                        <TableCell className="td-right">
                                          {item?.win}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className="collapse-table-row">
                                        <TableCell className="td-left">
                                          D
                                        </TableCell>
                                        <TableCell className="td-right">
                                          {item?.drw}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className="collapse-table-row">
                                        <TableCell className="td-left">
                                          L
                                        </TableCell>
                                        <TableCell className="td-right">
                                          {item?.lstn}
                                        </TableCell>
                                      </TableRow>

                                      <TableRow className="collapse-table-row">
                                        <TableCell className="td-left">
                                          F
                                        </TableCell>
                                        <TableCell className="td-right">
                                          {item?.gf}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className="collapse-table-row">
                                        <TableCell className="td-left">
                                          A
                                        </TableCell>
                                        <TableCell className="td-right">
                                          {item?.ga}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className="collapse-table-row">
                                        <TableCell className="td-left">
                                          GD
                                        </TableCell>
                                        <TableCell className="td-right">
                                          {item?.gd}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className="collapse-table-row">
                                        <TableCell className="td-left">
                                          PTS
                                        </TableCell>
                                        <TableCell className="td-right">
                                          {item?.pts}
                                        </TableCell>
                                      </TableRow>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </>
                            ))}
                          </Box>
                        </>
                      );
                    }
                  )}
            </>
          ) : (
            <>
              {/* <NoDataComp /> */}
              <Box className="no-match no-match-boxshadow">
                {" "}
                {localesData?.NO_DATA?.NO_TABLE}
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  </>);
};

export default TeamTablesPage;
