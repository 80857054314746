import React from "react";
import Page from "src/components/Page";
import PodcastsPage from "src/views/component/podcasts/Podcasts";

const Podcasts = () => {
  return (
    <Page title="Podcasts">
      <PodcastsPage />
    </Page>
  );
};

export default Podcasts;
