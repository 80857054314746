import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Table
} from "@mui/material";
import { ReactComponent as PreviousIcon } from "src/assets/images/eventcalendar/previous.svg";
import { ReactComponent as NextIcon } from "src/assets/images/eventcalendar/next.svg";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment-timezone";
import Loader from "src/components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Pagination } from '@mui/material';
// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';
// import '@fullcalendar/daygrid/main.css';
let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const EventMonth = ({
  eventData,
  selectedMonth,
  fetchEventData,
  currentPage,
  selectedTeams,
  selectedVenues,
  selectedOrg,
  selectedSports,
  searchValue,
  onchangeSelectedMonth,
  eventLoader,
  filter,
  hasMore,
  setHasMore
}) => {
  const calendarRef = useRef();
  const [currentDate, setCurrentDate] = useState(Date());

  const navigate = useNavigate();
  const params = useParams();

  const [events, setEvents] = useState([]);
  const [dayEvent, setDayEvent] = useState([]);
  const [dayEventDate, setDayEventDate] = useState();
  const [dayEventLoader, setDayEventLoader] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [dayCount, setDayCount] = useState(0);
  const [dayPage, setDayPage] = useState(0);

  const handleSelectedDayList = async (date, curPage) => {
    setDayEventLoader(true);
    setHasMore(true);
    setDayEventDate(
      moment(date)
        .tz(timezone)
        .format("YYYY-MM-DD")
    );
    try {
      const { status, data } = await axiosInstance.get(
        `/eventCalendar/eventCalender?startDate=${
          date
            ? date
            : moment(Date())
                .startOf("month")
                .format("YYYY-MM-DD")
        }&endDate=${
          date
            ? date
            : moment(Date())
                .startOf("month")
                .format("YYYY-MM-DD")
        }&timezone=${timezone}&teamId=${selectedTeams}&stadiumId=${selectedVenues}&tournamentid=${selectedOrg}&limit=20&page=${curPage}&search=${searchValue}&sportId=${selectedSports}&duration=true`
      );
      if (status === 200) {
        setDayEvent(data?.result);
        setDayCount(data?.count);
        // data && setEventData(data?.result);
        // data && setEventListData(data);
        setDayEventLoader(false);
      }
    } catch (err) {
      setDayEventLoader(false);
    }
  };

  const handlePaginationClick = (event, page) => {
    setDayPage(Number(page) - 1);
    handleSelectedDayList(dayEventDate, Number(page) - 1);
  };
  const handleMoreButtonClick = info => {
    handleSelectedDayList(
      moment(info)
        .tz(timezone)
        .format("YYYY-MM-DD"),
      0
    );
    // setHasMore(true);
    // setDayEventDate(
    //   moment(info?.date)
    //     .tz(timezone)
    //     .format("YYYY-MM-DD")
    // );
    // var filterEvent =
    //   events &&
    //   events?.length > 0 &&
    //   events?.filter(
    //     mevent =>
    //       moment(mevent?.eventDate)
    //         .tz(timezone)
    //         .format("YYYY-MM-DD") ===
    //       moment(info?.date)
    //         .tz(timezone)
    //         .format("YYYY-MM-DD")
    //   );
    // setDayEvent(filterEvent);
  };

  function renderEventContent(eventInfo) {
    return (
      <Box
        className="event-fullcalendar-day"
        onClick={() =>
          handleMoreButtonClick(eventInfo?.event?.extendedProps?.eventDate)
        }
      >
        {eventInfo?.event &&
          eventInfo?.event?.extendedProps &&
          eventInfo?.event?.extendedProps?.event1Time && (
            <Typography className="event-fullcalendar-date">
              {eventInfo?.event?.extendedProps?.event1Time &&
                moment(eventInfo?.event?.extendedProps?.event1Time)
                  .tz(timezone)
                  .format("h:mm A")}
            </Typography>
          )}
        <Typography className="event-fullcalendar-title">
          {eventInfo?.event?.extendedProps?.event1name}
        </Typography>
        {eventInfo?.event &&
          eventInfo?.event?.extendedProps &&
          eventInfo?.event?.extendedProps?.event2Time && (
            <Typography className="event-fullcalendar-date">
              {eventInfo?.event?.extendedProps?.event2Time &&
                moment(eventInfo?.event?.extendedProps?.event2Time)
                  .tz(timezone)
                  .format("h:mm A")}
            </Typography>
          )}
        {eventInfo?.event?.extendedProps?.event2name && (
          <Typography className="event-fullcalendar-title">
            {eventInfo?.event?.extendedProps?.event2name}
          </Typography>
        )}
        <Typography className="event-calender-more">
          {"+" + eventInfo?.event?.extendedProps?.eventCount + "more"}
        </Typography>
      </Box>
    );
  }
  function renderResponsiveEventContent(eventInfo) {
    return (
      <Box
        className="event-fullcalendar-day"
        onClick={() =>
          handleMoreButtonClick(eventInfo?.event?.extendedProps?.event1Time)
        }
      >
        {eventInfo?.event?.extendedProps?.eventCount > 0 && (
          <Box
            style={{
              height: "10px",
              width: "10px",
              background: "#FC4714",
              borderRadius: "50%",
              margin: "0 auto"
            }}
          ></Box>
        )}
      </Box>
    );
  }
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    // handleSelectedDayList(
    //   moment(Date())
    //     .startOf("month")
    //     .format("YYYY-MM-DD")
    // );
  }, []);
  // const customButtons = {
  //   customPrevButton: {
  //     text: "Custom Prev",
  //     click: () => {
  //       // Customize the behavior of the previous month button
  //       // You can access the FullCalendar instance using calendarRef
  //       calendarRef.current.getApi().prev();
  //     }
  //   },
  //   customNextButton: {
  //     text: "Custom Next",
  //     click: () => {
  //       // Customize the behavior of the next month button
  //       // You can access the FullCalendar instance using calendarRef
  //       calendarRef.current.getApi().next();
  //     }
  //   }
  //   // Add more custom buttons as needed
  // };

  const headerToolbar = {
    left: "",
    center: "",
    right: ""
  };
  // const handlePrevMonth = () => {
  //   const newDate = new Date(currentDate);
  //   newDate.setMonth(newDate.getMonth() - 1);
  //   setCurrentDate(newDate);
  // };

  // const handleNextMonth = () => {
  //   const newDate = new Date(currentDate);
  //   newDate.setMonth(newDate.getMonth() + 1);
  //   setCurrentDate(newDate);
  // };
  const clickOnNext = () => {
    const nextMonth = moment(selectedMonth)
      .add(1, "months")
      .format("YYYY-MM");
    setCurrentDate(
      moment(nextMonth)
        .startOf("month")
        .format("YYYY-MM-DD")
    );
    setDayEventDate();
    setHasMore(false);

    onchangeSelectedMonth(nextMonth);
    fetchEventData(
      currentPage,
      searchValue,
      selectedTeams,
      selectedVenues,
      selectedOrg,
      moment(nextMonth)
        .startOf("month")
        .format("YYYY-MM-DD"),
      moment(nextMonth)
        .endOf("month")
        .format("YYYY-MM-DD"),
      selectedSports,
      true
    );
  };
  const clickOnPrev = () => {
    const previousMonth = moment(selectedMonth)
      .subtract(1, "months")
      .format("YYYY-MM");
    setCurrentDate(
      moment(previousMonth)
        .startOf("month")
        .format("YYYY-MM-DD")
    );
    setHasMore(false);
    setDayEventDate();

    onchangeSelectedMonth(previousMonth);
    fetchEventData(
      currentPage,
      searchValue,
      selectedTeams,
      selectedVenues,
      selectedOrg,
      moment(previousMonth)
        .startOf("month")
        .format("YYYY-MM-DD"),
      moment(previousMonth)
        .endOf("month")
        .format("YYYY-MM-DD"),
      selectedSports,
      true
    );
  };
  const navigateToPrevMonth = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().prev();
    }
    clickOnPrev();
  };

  // Function to navigate to the next month
  const navigateToNextMonth = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().next();
    }
    clickOnNext();
  };
  const navigateToPrevDay = () => {
    const previousDay = moment(dayEventDate)
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    handleSelectedDayList(previousDay, 0);
  };

  const navigateToNextDay = () => {
    const nextDay = moment(dayEventDate)
      .add(1, "days")
      .format("YYYY-MM-DD");

    handleSelectedDayList(nextDay, 0);
  };
  // const handleMoreClick = arg => {};
  // const eventContent = arg => {
  //   console.log(
  //     arg,
  //     arg.event._def.extendedProps.more,
  //     "eventContenteventContent"
  //   );
  //   if (arg.event._def.extendedProps.more) {
  //     return (
  //       <div className="fc-more">
  //         <a onClick={() => handleMoreClick(arg)}>More</a>
  //       </div>
  //     );
  //   }

  //   // Render regular events
  //   return <div className="fc-content">{arg.event.title}</div>;
  // };

  useEffect(() => {
    if (eventData && eventData.length > 0) {
      var eveData = eventData?.map((edata, index) => {
        const eventStrings = edata?.eventName?.split(",");

        const events = eventStrings?.map((eventString, index) => {
          // Split each event string by #
          const [eventTime, eventName] = eventString.split("#");

          // Create an object for each event
          return {
            [`event${index + 1}name`]: eventName?.trim(),
            [`event${index + 1}Time`]: eventTime?.trim()
          };
        });

        return {
          ...edata,
          event1Time: events?.[0]?.event1Time,
          event1name: events?.[0]?.event1name,
          event2Time: events?.[1]?.event2Time,
          event2name: events?.[1]?.event2name,
          eventDate: edata?.startTime,
          title: edata?.eventName,
          date: edata?.startTime
            ? moment(edata?.startTime)
                .tz(timezone)
                .format("YYYY-MM-DD")
            : ""
          // date: edata?.eventDate ? edata?.eventDate : ""
        };
      });
      setEvents(eveData);
    } else {
      setEvents([]);
    }
  }, [eventData, filter]);
  // screenWidth > 800
  return (
    <Box className="event-date-time">
      <Box className="event-date-header">
        <Typography className="date-text">
          {hasMore
            ? moment(dayEventDate).format("DD/MM/YYYY")
            : moment(selectedMonth)
                .startOf("month")
                .format("MM/YYYY")
          //.format("YYYY-MM")
          }
          {hasMore && screenWidth < 559 && (
            <Box>
              <Button
                startIcon={<PreviousIcon />}
                onClick={() => {
                  setHasMore(false);
                }}
                className="previous-button"
                style={{ borderRadius: "5px", marginRight: "5px" }}
              >
                Back to Calender View
              </Button>
            </Box>
          )}
        </Typography>

        <Box className="button-section">
          {hasMore && screenWidth > 559 && (
            <Button
              startIcon={<PreviousIcon />}
              onClick={() => {
                setHasMore(false);
              }}
              className="previous-button"
              style={{ borderRadius: "5px", marginRight: "5px" }}
            >
              Back to Calender View
            </Button>
          )}
          <Button
            startIcon={<PreviousIcon />}
            onClick={() => {
              !hasMore ? navigateToPrevMonth() : navigateToPrevDay();
            }}
            className="previous-button"
          >
            {!hasMore ? "Previous Month" : "Previous Day"}
          </Button>
          <Button
            endIcon={<NextIcon />}
            onClick={() => {
              !hasMore ? navigateToNextMonth() : navigateToNextDay();
            }}
            className="next-button"
          >
            {!hasMore ? "Next Month" : "Next Day"}
          </Button>
        </Box>
      </Box>
      {eventLoader ? (
        <Box style={{ textAlign: "center" }}>
          <Loader />
        </Box>
      ) : (
        !hasMore &&
        currentDate && (
          <Box className="full-calendar calendar-row">
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              weekends={true}
              firstDay="1"
              initialDate={selectedMonth}
              headerToolbar={headerToolbar}
              // date={currentDate}
              // events={[
              //   { title: "event 1", date: "2023-09-15" },
              //   { title: "event 2", date: "2023-09-15" },
              //   {
              //     title: "event 3",
              //     date: "2023-09-15",
              //     extendedProps: {
              //       more: true // This event will trigger the "more" link
              //     }
              //   },
              //   {
              //     title: "event 4",
              //     date: "2023-09-15",
              //     extendedProps: {
              //       more: true // This event will trigger the "more" link
              //     }
              //   }
              // ]}
              // eventClick={handleEventClick}
              // dateClick={() => handleMoreButtonClick()}
              events={events}
              dayMaxEvents={screenWidth < 800 ? 1 : 2}
              // dayMaxEvents={screenWidth < 800 ? 1 : undefined}
              // moreLinkClick={handleMoreButtonClick}
              eventContent={
                screenWidth < 800
                  ? renderResponsiveEventContent
                  : renderEventContent
              }
              // dayMaxEventRows={screenWidth < 800 ? false : true}
              // eventLimit={screenWidth < 800 ? false : true}
              // eventRender={eventContent}
              //  customButtons={customButtons}
              // options={calendarOptions}
            />
          </Box>
        )
      )}

      {/* {window.innerWidth < 560 && (
        <Box className="event-calendar-table-design">
          <Table aria-label="simple table" className="event-table">
            <TableHead className="event-table-head">
              <TableRow className="event-table-row">
                <TableCell className="event-table-cell">Event</TableCell>
                <TableCell className="event-table-cell">time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="event-table-body">
              {tabledata &&
                tabledata.length > 0 &&
                tabledata.map(t => {
                  return (
                    <TableRow className="event-table-row">
                      <TableCell className="event-table-cell">
                        <Typography className="cell-text">
                          Brisbane Lions V/s{" "}
                        </Typography>
                        <Typography className="cell-text">Melbourne</Typography>
                        <Typography className="cell-text">
                          Venue - Sunshine Coast Stadium
                        </Typography>
                      </TableCell>
                      <TableCell className="event-table-cell">
                        <Typography className="cell-text">
                          07:50 PM - 10:00 PM
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      )} */}

      {hasMore &&
        (dayEventLoader ? (
          <div className="allsport-loader-center ">
            <Loader />
          </div>
        ) : (
          <>
            <Box className="event-calendar-table-design">
              <Table aria-label="simple table" className="event-table">
                <TableHead className="event-table-head">
                  <TableRow className="event-table-row">
                    <TableCell className="event-table-cell">Event</TableCell>
                    <TableCell className="event-table-cell">time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="event-table-body">
                  {dayEvent &&
                    dayEvent.length > 0 &&
                    dayEvent.map(item => {
                      return (
                        <TableRow className="event-table-row">
                          <TableCell className="event-table-cell">
                            <Typography
                              className="cell-text cursor-pointer"
                              onClick={() =>
                                navigate(
                                  `/${params?.sportType}/event-calendar/details/${item?.eventId}/${item?.sportId}/${item?.tournamentId}`
                                )
                              }
                            >
                              {item?.eventName}
                            </Typography>
                            {/* <Typography className="cell-text">
                            Melbourne
                          </Typography> */}
                            {item?.venues ? (
                              <Typography className="cell-text">
                                {"Venue -" + item?.venues}
                              </Typography>
                            ) : (
                              <Typography className="cell-text"></Typography>
                            )}
                          </TableCell>
                          <TableCell className="event-table-cell">
                            {item?.eventDate && (
                              <Typography className="cell-text">
                                {moment(item?.eventDate)
                                  .tz(timezone)
                                  .format("h:mm A")}
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>

            <Box className="news-Pagination">
              <Pagination
                // hideNextButton
                // hidePrevButton
                // disabled={categoryCount / rowPerPage > 1 ? false : true}
                // count={pageNumbers[pageNumbers?.length - 1]}
                // count={20}
                disabled={dayCount / 20 > 1 ? false : true}
                page={dayPage + 1}
                onChange={(e, value) => handlePaginationClick(e, value)}
                count={Math.ceil(dayCount / 20)}
                siblingCount={2}
                boundaryCount={1}
                // count={10}
                size="small"
              />
            </Box>
          </>
        ))}
    </Box>
  );
};

export default EventMonth;
