import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { IntlContext } from "src/App";
import { Config } from "../../../../../helpers/context/config";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import { ReactComponent as Bat } from "../../../../../assets/images/SportIcon/bat.svg";
import { ReactComponent as Ball } from "../../../../../assets/images/SportIcon/ball.svg";
import { useParams } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";

const matchStatsData = [
  {
    id: 1,
    homeStats: 8,
    stats: "Corner Kicks",
    awayStats: 8
  },
  {
    id: 2,
    homeStats: 1,
    stats: "Yellow Cards",
    awayStats: 2
  },
  {
    id: 3,
    homeStats: 1,
    stats: "Yellow Red Cards",
    awayStats: 8
  },
  {
    id: 4,
    homeStats: 1,
    stats: "Red Cards",
    awayStats: 5
  }
];

const StickyTableCell = withStyles(theme => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    minWidth: "45px",
    boxShadow: "inset 1px 0px 6px 0px #ebebeb",
    "@media(max-width: 799px)": {
      maxWidth: "45px",
      boxShadow: "inset 1px 0px 6px 0px #ebebeb"
    }
  },
  body: {
    left: 0,
    position: "sticky",
    background: "#FFFFFF",
    boxShadow: "inset 1px 0px 6px 0px #ebebeb",
    "@media(max-width: 799px)": {
      boxShadow: "inset 1px 0px 6px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const MatchStats = ({ fixtureInfoData }) => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const classes = useStyles();
  const localesData = intlContext?.messages;
  const [tabvalue, setTabValue] = useState(1);
  const [scoreboardData, setscoreboardData] = useState([]);
  const [scoreboardLoading, setscoreboardLoading] = useState(false);

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Brisbane} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Brisbane} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Melbourne} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Melbourne} alt="Odds Icon" />;
      }
    }
  };

  const TeamStats = (data, type) => {
    const total = data?.homeStats + data?.awayStats;
    const width = (
      ((type === "homeTeam" ? data?.homeStats : data?.awayStats) / total) *
      100
    ).toFixed(2);
    return (
      <>
        <Box
          className="progress-bar"
          style={{
            width: `${width}%`,
            background: width > 50 ? "#003764" : "#D4D6D8"
          }}
        ></Box>
      </>
    );
  };

  useEffect(() => {
    fetchTeamTableData();
  }, [params]);

  const fetchTeamTableData = async () => {
    setscoreboardLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/cricket/scoreboard/${params?.id}`
      );
      if (status === 200) {
        const cricketMatchInnings = data?.result?.CricketMatchInnings?.map(
          (item, index) => {
            return {
              ...item,
              dataId: index + 1
            };
          }
        );
        setscoreboardData(cricketMatchInnings);
        setscoreboardLoading(false);
      } else {
        setscoreboardLoading(false);
      }
    } catch (err) {
      setscoreboardLoading(false);
    }
  };

  const batsmenStauts = data => {
    return (
      <>
        <Box>
          {data?.LpTx?.replace(
            "[F]",
            data?.Fplayer?.name?.split(" ")?.pop()
          )?.replace("[B]", data?.Bplayer?.name?.split(" ")?.pop())}
        </Box>
      </>
    );
  };

  const optimizeName = fullName => {
    if (fullName) {
      const names = fullName?.split(" ");
      const optimizedName = names?.[0]?.[0] + " " + names?.[names?.length - 1];
      return optimizedName;
    } else return "";
  };

  const battingInningsData = data => {
    const newDataYetToBat = data?.filter(item => item?.LpTx === "yet to bat");
    const newDataNotYetToBat = data?.filter(
      item => item?.LpTx !== "yet to bat"
    );
    const newData = [...newDataNotYetToBat, ...newDataYetToBat];
    return (
      <>
        {newData?.map((batsmen, i) => {
          return (
            <>
              <TableRow key={i}>
                <StickyTableCell className={`${classes.cell} first-cell`}>
                  {batsmen?.Lp === 40 ? (
                    <Box className="divider-indicator"></Box>
                  ) : (
                    <></>
                  )}
                  <Box className="d-flex batsmen-name">
                    <Box>
                      {" "}
                      <Box className="player-name">
                        {optimizeName(batsmen?.CricketPlayer?.name)}
                      </Box>
                      {batsmenStauts(batsmen)}
                    </Box>
                    {batsmen?.A === 1 && (
                      <Box className="bat-icon">
                        <Bat />
                      </Box>
                    )}
                  </Box>
                </StickyTableCell>
                <TableCell className={`${classes.cell} bold`}>
                  {batsmen?.R && batsmen?.R}
                </TableCell>
                <TableCell className={classes.cell}>
                  {batsmen?.B && batsmen?.B}
                </TableCell>
                <TableCell className={classes.cell}>
                  {batsmen?.four && batsmen?.four}
                </TableCell>
                <TableCell className={classes.cell}>
                  {batsmen?.six && batsmen?.six}
                </TableCell>
                <TableCell className={classes.cell}>
                  {batsmen?.Sr && batsmen?.Sr}
                </TableCell>
              </TableRow>
            </>
          );
        })}
      </>
    );
  };
  return (<>
    <Box className="fixture-wrap">
      {scoreboardData?.length > 0 && (
        <Typography variant="h4" className="fixture-heading">
          {localesData?.FIXTURE_INFO?.MATCH_STATS}
        </Typography>
      )}
      {Number(params?.SportId) !== 4 ? (
        <Box className="match-stats-wrap">
          <Box className="d-flex align-center match-team-heading">
            <Box className="d-flex align-center team-wrap">
              <span className="team-name bold">
                {fixtureInfoData?.homeTeam?.name
                  ? fixtureInfoData?.homeTeam?.name
                  : ""}
              </span>
              <span className="team-img-wrap">
                {fetchTeamlogo(fixtureInfoData, "hometeam")}
              </span>
            </Box>
            <Box className="bold divider">
              <span></span>
            </Box>
            <Box className="d-flex align-center team-wrap">
              <span className="team-img-wrap ">
                {fetchTeamlogo(fixtureInfoData, "awayteam")}
              </span>
              <span className="team-name bold">
                {fixtureInfoData?.awayTeam?.name
                  ? fixtureInfoData?.awayTeam?.name
                  : ""}
              </span>
            </Box>
          </Box>
          {matchStatsData?.map((data, index) => (
            <>
              <Box className="match-stats-details" key={index}>
                <Box className="d-flex align-center stats-value-wrap">
                  <Typography className="details-value">
                    {data?.homeStats}
                  </Typography>
                  <Typography className="details-name">
                    {data?.stats}
                  </Typography>
                  <Typography className="details-value">
                    {data?.awayStats}
                  </Typography>
                </Box>
                <Box className="stats-progress-bar">
                  {TeamStats(data, "homeTeam")}
                  {TeamStats(data, "awayTeam")}
                </Box>
              </Box>
            </>
          ))}
        </Box>
      ) : (
        <>
          {scoreboardLoading ? (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          ) : scoreboardData?.length > 0 ? (
            <Box className="scorecard-wrap">
              <Tabs
                value={tabvalue}
                variant="scrollable"
                scrollButtons={false}
                indicatorColor="primary"
                textColor="primary"
                className="scorecard-tab-detail"
              >
                {scoreboardData?.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Tab
                        label={
                          <Box className="d-flex align-center team-wrap">
                            <span>{item?.Ti}</span>
                          </Box>
                        }
                        value={item?.dataId}
                        className={item?.dataId === tabvalue ? "active " : ""}
                        onChange={(event, newValue) =>
                          handleTabChange(event, item?.dataId)
                        }
                      />
                    </Box>
                  );
                })}
              </Tabs>
              {scoreboardData
                ?.filter(item => item?.dataId === tabvalue)
                ?.map((data, index) => {
                  return (
                    <>
                      <Box className="scorecard-details-wrap" key={index}>
                        <TableContainer
                          id="details-table"
                          className="scorecard-table"
                        >
                          <Table
                            className="data-table"
                            style={{ minWidth: "max-content" }}
                          >
                            <TableHead className="table-header">
                              <TableRow className="table-head-row ">
                                <StickyTableCell
                                  className={`${classes.head} first-cell`}
                                  style={{ width: "30%" }}
                                >
                                  BATTERS
                                </StickyTableCell>
                                <TableCell className={classes.head}>
                                  R
                                </TableCell>
                                <TableCell className={classes.head}>
                                  B
                                </TableCell>
                                <TableCell className={classes.head}>
                                  4s
                                </TableCell>
                                <TableCell className={classes.head}>
                                  6s
                                </TableCell>
                                <TableCell className={classes.head}>
                                  S/R
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody className="table-body">
                              {battingInningsData(data?.CricketBatInnings)}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <Box className="stats-wrap">
                          <Typography className="stats-text">
                            STATS
                          </Typography>
                          <Box className="stats-details">
                            <Box className="d-flex align-center details-warp">
                              <Typography className="details-head">
                                Total:
                              </Typography>
                              <Typography className="details-text">
                                {data?.Pt && data?.Pt}/{data?.Wk && data?.Wk}{" "}
                                {data?.Ov && `(${data?.Ov})`}
                              </Typography>
                            </Box>
                            <Box className="d-flex align-center details-warp">
                              <Typography className="details-head">
                                Run Rate:
                              </Typography>
                              <Typography className="details-text">
                                {data?.Rr && data?.Rr}
                              </Typography>
                            </Box>
                            <Box className="d-flex align-center details-warp">
                              <Typography className="details-head">
                                Extras:
                              </Typography>
                              <Typography className="details-text extra-wrap">
                                {data?.Ex && data?.Ex}
                                {"   "}
                                <span>
                                  <span className="bold-right">B:</span>
                                  <span>{data?.B && data?.B}</span>
                                </span>
                                {"   "}
                                <span>
                                  <span className="bold-right">LB:</span>
                                  <span>{data?.LB && data?.LB}</span>
                                </span>
                                {"   "}
                                <span>
                                  <span className="bold-right">NB:</span>
                                  <span>{data?.NB && data?.NB}</span>
                                </span>
                                {"   "}
                                <span>
                                  <span className="bold-right">WD:</span>
                                  <span>{data?.WB && data?.WB}</span>
                                </span>
                                {"   "}
                                <span>
                                  <span className="bold-right">P:</span>
                                  <span>{data?.Pen && data?.Pen}</span>
                                </span>
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="bowling-wrap">
                          <TableContainer
                            id="details-table"
                            className="scorecard-table"
                          >
                            <Table
                              className="data-table"
                              style={{ minWidth: "max-content" }}
                            >
                              <TableHead className="table-header">
                                <TableRow className="table-head-row ">
                                  <StickyTableCell
                                    className={`${classes.head} first-cell`}
                                    style={{ width: "30%" }}
                                  >
                                    BOWLING
                                  </StickyTableCell>
                                  <TableCell className={classes.head}>
                                    O
                                  </TableCell>
                                  <TableCell className={classes.head}>
                                    M
                                  </TableCell>
                                  <TableCell className={classes.head}>
                                    R
                                  </TableCell>
                                  <TableCell className={classes.head}>
                                    W
                                  </TableCell>
                                  <TableCell className={classes.head}>
                                    NB
                                  </TableCell>
                                  <TableCell className={classes.head}>
                                    WD
                                  </TableCell>
                                  <TableCell className={classes.head}>
                                    E/R
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody className="table-body">
                                {data?.CricketBowlInnings?.map(
                                  (bowlers, i) => {
                                    return (
                                      <>
                                        <TableRow key={i}>
                                          <StickyTableCell
                                            className={`${classes.cell} first-cell`}
                                          >
                                            <Box className="d-flex batsmen-name">
                                              <Box className="player-name">
                                                {optimizeName(
                                                  bowlers?.CricketPlayer?.name
                                                )}
                                              </Box>
                                              {bowlers?.A === 1 && (
                                                <Box className="ball-icon">
                                                  <Ball />
                                                </Box>
                                              )}
                                            </Box>
                                          </StickyTableCell>
                                          <TableCell className={classes.cell}>
                                            {bowlers?.Ov && bowlers?.Ov}
                                          </TableCell>
                                          <TableCell className={classes.cell}>
                                            {bowlers?.Md && bowlers?.Md}
                                          </TableCell>
                                          <TableCell className={classes.cell}>
                                            {bowlers?.R && bowlers?.R}
                                          </TableCell>
                                          <TableCell
                                            className={`${classes.cell} bold`}
                                          >
                                            {bowlers?.Wk && bowlers?.Wk}
                                          </TableCell>
                                          <TableCell className={classes.cell}>
                                            {bowlers?.NB && bowlers?.NB}
                                          </TableCell>
                                          <TableCell className={classes.cell}>
                                            {bowlers?.WB && bowlers?.WB}
                                          </TableCell>
                                          <TableCell className={classes.cell}>
                                            {bowlers?.Er && bowlers?.Er}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    );
                                  }
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Box>
                    </>
                  );
                })}
            </Box>
          ) : (
            <Box className="no-match"> No Scores are Available </Box>
          )}
        </>
      )}
    </Box>
  </>);
};

export default MatchStats;
