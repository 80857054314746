import React, { useContext } from "react";
import { IntlContext } from "src/App";
import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from "@mui/material";

import { ReactComponent as CheckBoxChecked } from "src/assets/images/checkBoxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/checkboxUnChecked.svg";
import { ReactComponent as RadioChecked } from "src/assets/images/icons/smartb-check.svg";
import { ReactComponent as RadioUnChecked } from "src/assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Indeterminent } from "src/assets/images/icons/indeterminent-icon.svg";
import { ReactComponent as Football } from "src/assets/images/Sport/football.svg";
import { ReactComponent as Baseball } from "src/assets/images/Sport/baseballLight.svg";
import { ReactComponent as Basketball } from "src/assets/images/Sport/basketballLight.svg";
import { ReactComponent as Boxing } from "src/assets/images/Sport/boxingLight.svg";
import { ReactComponent as Cricket } from "src/assets/images/Sport/cricketLight.svg";
import { ReactComponent as Golf } from "src/assets/images/Sport/golfLight.svg";
import { ReactComponent as IceHockey } from "src/assets/images/Sport/iceHockey-new.svg";
import { ReactComponent as MMA } from "src/assets/images/Sport/mmaIcon-new.svg";
import { ReactComponent as Rugby } from "src/assets/images/Sport/rugby.svg";
import { ReactComponent as Soccer } from "src/assets/images/Sport/soccerIcon-new.svg";
import { ReactComponent as Tennis } from "src/assets/images/Sport/Tennis.svg";
import { ReactComponent as AR } from "src/assets/images/Sport/arIcon-new.svg";
import { ReactComponent as RU } from "src/assets/images/Sport/rugbyUnionIcon-new.svg";
import { ReactComponent as RaceHorses } from "src/assets/images/Sport/horseRacingIcon.svg";
import { useState, useEffect } from "react";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "src/helpers/context";
import { release } from "src/helpers/context/release";
import Loader from "src/components/Loader";
const ProfileNotification = ({
  formvalues,
  setformvalues,
  fetchEditProfile,
  nextStep,
  fetchSportData,
  sports,
  handleSportChange,
  handleSubscriptionChange,
}) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [selectedOffering, setselectedOffering] = useState([]);
  const [errorOfferingOther, seterrorOfferingOther] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchSportData();
  }, []);
  // const fetchSportData = async () => {
  //     setIsLoading(true)
  //     try {
  //         const { status, data } = await axiosInstance.get(
  //             `/sports/sport/?sportTypeId=${2}`
  //         );
  //         if (status === 200) {
  //             const releaseFilterSportData = data?.result?.filter((item) =>
  //                 release[Config.release]?.sportId?.includes(item?.id)
  //             );
  //             const SportsData = await releaseFilterSportData?.map((item) => {
  //                 return {
  //                     ...item,
  //                     notificationData: [
  //                         { id: 1, subName: "Tips" },
  //                         { id: 2, subName: "News" },
  //                         { id: 3, subName: "Fixtures & Results" },
  //                     ],
  //                 };
  //             });
  //             const sortedData = SportsData?.sort((a, b) => {
  //                 return a?.sportName.localeCompare(b?.sportName);
  //             });
  //             const mergeData =
  //                 release[Config.release]?.raceSportId?.length > 0
  //                     ? [
  //                         ...sortedData,
  //                         {
  //                             id: 1,
  //                             sportName: "Racing",
  //                             SportId: 1,
  //                             isChecked: true,
  //                             isIndeterminate: true,
  //                             notificationData: [
  //                                 { id: 1, subName: "Tip of the Day", isChecked: true },
  //                                 { id: 4, subName: "Daily Best Bet", isChecked: true },
  //                                 { id: 2, subName: "News" },
  //                                 { id: 3, subName: "Fixtures & Results" },
  //                             ],
  //                         },
  //                     ]
  //                     : [...sortedData];

  //             setSports(sports?.length > 0 ? sports : mergeData);
  //             setIsLoading(false)
  //         }
  //     } catch (err) {
  //         setIsLoading(false)
  //     }
  // };
  const fetchSportIcon = (sportId) => {
    if (sportId === 1) {
      return <RaceHorses />;
    } else if (sportId === 15) {
      return <Football />;
    } else if (sportId === 9) {
      return <AR />;
    } else if (sportId === 11) {
      return <Baseball />;
    } else if (sportId === 10) {
      return <Basketball />;
    } else if (sportId === 6) {
      return <Boxing />;
    } else if (sportId === 4) {
      return <Cricket />;
    } else if (sportId === 16) {
      return <Golf />;
    } else if (sportId === 17) {
      return <IceHockey />;
    } else if (sportId === 5) {
      return <MMA />;
    } else if (sportId === 12) {
      return <Rugby />;
    } else if (sportId === 13) {
      return <RU />;
    } else if (sportId === 8) {
      return <Soccer />;
    } else if (sportId === 7) {
      return <Tennis />;
    }
  };
  // const handleSportChange = (sportId) => {
  //     const updatedSports = sports?.map((sport) => {
  //         if (Array.isArray(sport?.id) && sport?.id.includes(sportId)) {
  //             const updatedSubscriptions = sport?.notificationData?.map((sub) => ({
  //                 ...sub,
  //                 isChecked: !sub?.isChecked,
  //             }));
  //             return {
  //                 ...sport,
  //                 notificationData: updatedSubscriptions,
  //             };
  //         } else if (sport?.id === sportId) {
  //             return {
  //                 ...sport,
  //                 isChecked: !sport?.isChecked,
  //                 isIndeterminate: false,
  //                 notificationData: sport?.notificationData?.map((sub) => ({
  //                     ...sub,
  //                     isChecked: !sport?.isChecked,
  //                 })),
  //             };
  //         }
  //         return sport;
  //     });

  //     setSports(updatedSports);
  // };

  // const handleSubscriptionChange = (sportId, subId) => {
  //     const updatedSports = sports?.map((sport) => {
  //         if (sport?.id === sportId) {
  //             const updatedSubscriptions = sport?.notificationData?.map((sub) => {
  //                 if (sub?.id === subId) {
  //                     return {
  //                         ...sub,
  //                         isChecked: !sub?.isChecked,
  //                     };
  //                 }
  //                 return sub;
  //             });

  //             const allChecked = updatedSubscriptions?.every((sub) => sub?.isChecked);
  //             const someChecked = updatedSubscriptions?.some((sub) => sub?.isChecked);
  //             const isChecked = allChecked || (!allChecked && someChecked);

  //             return {
  //                 ...sport,
  //                 notificationData: updatedSubscriptions,
  //                 isChecked,
  //                 isIndeterminate: !allChecked && someChecked,
  //             };
  //         }
  //         return sport;
  //     });
  //     setSports(updatedSports);
  // };
  const ValidateOfferingOther = () => {
    let flag = true;
    if (
      selectedOffering?.includes("Other - Please specify") &&
      (formvalues?.offeringsOther
        ? formvalues?.offeringsOther?.[0] === ""
        : true)
    ) {
      flag = false;
      seterrorOfferingOther("Please Add Offering");
    } else {
      seterrorOfferingOther("");
    }
    return flag;
  };
  return (
    <>
      <Box className="step5-container">
        <>
          {isLoading ? (
            <div className="auth-loader">
              <Loader />
            </div>
          ) : (
            <></>
          )}
          <Typography Typography className="textfield-text">
            {localesData?.register?.HABITS_AND_INTERESTS}
          </Typography>
          <Box className="otherOffering-wrap">
            <FormLabel
              component="legend"
              className="otheroffering-label signup-que"
            >
              {localesData?.register?.OTHER_OFFERING}
            </FormLabel>

            <Box className="notification-wrap">
              {sports?.map((sport) => {
                return (
                  <Box key={sport?.id} className="parent-checkbox">
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<CheckboxUnChecked className="radio-icon" />}
                          checkedIcon={
                            <CheckBoxChecked className="radio-icon" />
                          }
                          indeterminateIcon={<Indeterminent />}
                          checked={
                            sport?.isChecked == true &&
                            sport?.isIndeterminate == false
                          }
                          indeterminate={sport?.isIndeterminate}
                          onChange={() => handleSportChange(sport?.id)}
                        />
                      }
                      label={
                        <Box className="sport-icon-wrap">
                          <span className="sports-name">
                            {sport?.sportName}
                          </span>
                          <span className="sport-icon">
                            {fetchSportIcon(sport?.id)}
                          </span>
                        </Box>
                      }
                    />
                    <Box className="child-wrap">
                      {sport?.notificationData?.map((sub) => (
                        <span
                          key={sub?.id}
                          className={`child-checkbox p-25 ${
                            sub?.isChecked === true ? "active-label" : ""
                          }`}
                          onClick={() =>
                            handleSubscriptionChange(sport?.id, sub?.id)
                          }
                        >
                          {/* <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={<RadioUnChecked className="radio-icon" />}
                                                            checkedIcon={
                                                                <RadioChecked className="radio-icon" />
                                                            }
                                                            checked={sub?.isChecked === true}
                                                            onChange={() =>
                                                                handleSubscriptionChange(sport?.id, sub?.id)
                                                            }
                                                        />
                                                    }
                                                    label={sub?.subName}
                                                /> */}
                          {sub?.subName}
                        </span>
                      ))}
                    </Box>
                  </Box>
                );
              })}
            </Box>
            {/* {screenWidth > 1279 && ( */}
            {/* <Box className="signup-btn-wrap">
                            <Box className="prev-next-btn">
                                <Button
                                    disableElevation
                                    disableFocusRipple
                                    disableRipple
                                    // type="submit"
                                    className="submit-btn"
                                // onClick={() => prevStep()}
                                >
                                    {localesData?.register?.auth_btn_prev}
                                </Button>
                                <Button
                                    disableElevation
                                    disableFocusRipple
                                    disableRipple
                                    // type="submit"
                                    className="submit-btn"
                                    disabled={isLoading}
                                    onClick={() => {
                                        // if (ValidateOfferingOther()) {

                                        // }
                                    }}
                                >
                                    {localesData?.register?.auth_btn_finish}
                                </Button>
                            </Box>
                        </Box> */}
            {/* )} */}
          </Box>
        </>
      </Box>
      {/* {screenWidth < 1280 && ( */}
      <Box className="signup-btn-wrap step5-signup-btn-wrap">
        {/* <Button
                        disableElevation
                        disableFocusRipple
                        disableRipple
                        // type="submit"
                        className="submit-btn"
                    // onClick={() => prevStep()}
                    >
                        {localesData?.register?.auth_btn_prev}
                    </Button> */}
        <Button
          disableElevation
          disableFocusRipple
          disableRipple
          // type="submit"
          className="submit-btn"
          disabled={isLoading}
          onClick={() => {
            // if (ValidateOfferingOther()) {
            nextStep();
            // }
          }}
        >
          {localesData?.register?.NEXT}
        </Button>

        <Button className="finish-btn" onClick={() => fetchEditProfile("skip")}>
          {" "}
          <a>{localesData?.register?.FINISH_MSG_STEPPER}</a>
        </Button>
      </Box>

      {/* )} */}
    </>
  );
};

export default ProfileNotification;
