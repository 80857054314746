import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { ReactComponent as NextSlide } from "../../../assets/images/icons/slide-next.svg";
import { ReactComponent as PrvSlide } from "../../../assets/images/icons/slide-prv.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const howItWorkData = [
  {
    id: 1,
    url: "https://www.youtube.com/embed/PeaV3nm0-bo",
    title:
      "Join the Smart Odds Comparison (SOC) now to increase your earning potential.",
  },
  {
    id: 2,
    url: "https://www.youtube.com/embed/26-ATbOTjdk",
    title:
      "Join the Smart Odds Comparison (SOC) now to increase your earning potential.",
  },
  {
    id: 3,
    url: "https://www.youtube.com/embed/NleM6xSAglk",
    title:
      "Join the Smart Odds Comparison (SOC) now to increase your earning potential.",
  },
  {
    id: 4,
    url: "https://www.youtube.com/embed/-dr5xuBoVwA",
    title:
      "Join the Smart Odds Comparison (SOC) now to increase your earning potential.",
  },
  // {
  //   id: 5,
  //   url: "https://www.youtube.com/embed/videoseries?si=7Cq_Y2N3vU23nxYw&list=PLJGv8nkG93WnR08ZVu_jMOLk332l8s4rB&showinfo=0&controls=0&modestbranding=1",
  //   title:
  //     "Join the Smart Odds Comparison (SOC) now to increase your earning potential.",
  // },
];

const SOCHowItworks = () => {
  const navigate = useNavigate();
  const carouselRef = useRef(null);
  const sliderRef = useRef(null);
  const [centerSlideWidth, setCenterSlideWidth] = useState(0);

  useEffect(() => {
    const updateCenterSlideWidth = () => {
      if (sliderRef.current) {
        const slickSlides = sliderRef.current.querySelectorAll(".slick-slide");
        if (slickSlides.length > 0) {
          const centerIndex = Math.floor(slickSlides.length / 2);
          const centerSlide = slickSlides[centerIndex];
          if (centerSlide) {
            setCenterSlideWidth(centerSlide.offsetWidth);
          }
        }
      }
    };
    updateCenterSlideWidth();
    window.addEventListener("resize", updateCenterSlideWidth);
    return () => {
      window.removeEventListener("resize", updateCenterSlideWidth);
    };
  }, []);
  const setting = {
    className: "center",
    centerMode: true,
    centerPadding: "0px",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: false,
    draggable: false,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handlePrevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.slickPrev();
    }
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.slickNext();
    }
  };

  const handleNavigate = () => {
    navigate("/membership");
  };
  return (
    <>
      <Box className="how-it-work-wrap">
        <Typography className="soc-signup-section-header">
          How <span className="highlight-text">it works</span>
        </Typography>
        <Typography className="soc-signup-header-details">
          Compare odds from almost 100 bookmakers, understand market
          percentages, and set price notifications.
        </Typography>
        <Box className="how-it-work-btn-wrap">
          <Button
            variant="contained"
            className="soc-sign-up-button"
            onClick={() => handleNavigate()}
          >
            Get better odds on Smart odds comparison
          </Button>
        </Box>
        <Box className="how-it-work-slider" ref={sliderRef}>
          <Slider ref={carouselRef} {...setting}>
            {howItWorkData?.map((item, index) => {
              return (
                <Box key={index} className="slider-video-wrap">
                  <iframe
                    // width="560"
                    height="415"
                    src={`${item?.url}`}
                    title="YouTube video player"
                    frameborder="0"
                    allowfullscreen
                    className="video-iframe"
                  ></iframe>
                </Box>
              );
            })}
          </Slider>
        </Box>
        <Box
          className="how-it-bottom-section"
          style={{ width: centerSlideWidth }}
        >
          <Box className="text-details-wrap">
            <Typography className="text-details">
              Join the Smart Odds Comparison (SOC) now to increase your earning
              potential.
            </Typography>
          </Box>
          <Box className="arrow-section">
            <Button
              onClick={handlePrevClick}
              className="slide-button prev-slide-btn"
            >
              {" "}
              <PrvSlide height="28px" width="28px" />
            </Button>
            <Button
              onClick={handleNextClick}
              className="slide-button next-slide-btn"
            >
              {" "}
              <NextSlide height="28px" width="28px" />
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SOCHowItworks;
