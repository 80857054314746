import React from "react";
import Page from "src/components/Page";
import EnterSportsTipsPage from "src/views/component/TipsMarketplace/enterSportsTips";

const EnterSportsTips = () => {
  return (
    <Page title="Enter Sports Tips">
      <EnterSportsTipsPage />
    </Page>
  );
};

export default EnterSportsTips;
