import React from "react";
import Page from "src/components/Page";
import AdvertisingEnquiriesPage from "src/views/component/advertisingEnquiriesPage";

const AdvertisingEnquiries = () => {
  return (
    <Page title="Advertising Enquiries">
      <AdvertisingEnquiriesPage />
    </Page>
  );
};

export default AdvertisingEnquiries;
