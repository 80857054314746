import React from "react";
import Page from "src/components/Page";
import SubscriptionAndPaymentPage from "src/views/component/subscriptionAndPayment";

const SubscriptionAndPayment = () => {
  return (
    <Page title="Subscription and Payment">
      <SubscriptionAndPaymentPage />
    </Page>
  );
};

export default SubscriptionAndPayment;
