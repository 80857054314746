import {
  AF_DATA,
  AR_DATA,
  BASEBALL_DATA,
  BASKETBALL_DATA,
  BOXING_DATA,
  CRICKET_DATA,
  GOLF_DATA,
  ICEHOCKEY_DATA,
  MMA_DATA,
  RL_DATA,
  RU_DATA,
  SOCCER_DATA,
  TENNIS_DATA
} from "../constants";

export const fetchAFData = data => {
  return {
    type: AF_DATA,
    data: data
  };
};

export const fetchARData = data => {
  return {
    type: AR_DATA,
    data: data
  };
};

export const fetchBaseballData = data => {
  return {
    type: BASEBALL_DATA,
    data: data
  };
};

export const fetchBasketballData = data => {
  return {
    type: BASKETBALL_DATA,
    data: data
  };
};

export const fetchBoxingData = data => {
  return {
    type: BOXING_DATA,
    data: data
  };
};

export const fetchCricketData = data => {
  return {
    type: CRICKET_DATA,
    data: data
  };
};

export const fetchGolfData = data => {
  return {
    type: GOLF_DATA,
    data: data
  };
};
export const fetchIceHockeyData = data => {
  return {
    type: ICEHOCKEY_DATA,
    data: data
  };
};

export const fetchMMAData = data => {
  return {
    type: MMA_DATA,
    data: data
  };
};

export const fetchRLData = data => {
  return {
    type: RL_DATA,
    data: data
  };
};

export const fetchRUData = data => {
  return {
    type: RU_DATA,
    data: data
  };
};

export const fetchSoccerData = data => {
  return {
    type: SOCCER_DATA,
    data: data
  };
};

export const fetchTennisData = data => {
  return {
    type: TENNIS_DATA,
    data: data
  };
};
