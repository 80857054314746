import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RaceHorses from "../../../assets/images/Sport/race_hourses.svg";
import RaceGreyhounds from "../../../assets/images/Sport/races_greyhounds.svg";
import RaceHarness from "../../../assets/images/Sport/race_harness.svg";
import { IntlContext } from "src/App";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import Loader from "src/components/Loader";
import { number } from "prop-types";
import RaceOdds from "../raceOdds/RaceOdds";
import { useSelector } from "react-redux";
import axios from "axios";
import CustomTab from "./HomeComponents/CustomTab";
import { fetchFromStorage } from "src/helpers/context";

const FeaturedRace = ({ availableSport }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const abortControllerRef = useRef(null);
  const sportData = useSelector((state) => state?.reduxData?.SportData);
  const layoutData = useSelector((state) => state?.reduxData?.LayoutData);
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [featureRaceData, setFeatureRaceData] = useState([]);
  const [featureRaceLoader, setFeatureRaceLoader] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [tabvalue, setTabValue] = useState(0);
  const [selectedSport, setSelectedSport] = useState(0);
  const [allSportData, setAllSportData] = useState([]);
  const [countryIds, setCountryIds] = useState([]);
  const [stateIds, setStateIds] = useState([]);
  const localAuth = fetchFromStorage("auth_token");
  let isLogin = localAuth ? true : false;
  const settingsRace = {
    infinite: featureRaceData?.length > 1,
    speed: 500,
    slidesToShow: featureRaceData?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    dots: false,
    arrows: featureRaceData?.length > 1,
    // adaptiveHeight: true,
    beforeChange: (current, next) => {
      handleBeforeChange();
    },
    prevArrow: (
      <Box className="slick-prev">
        <ChevronLeftIcon />
      </Box>
    ),
    nextArrow: (
      <Box className="slick-next">
        <ChevronRightIcon />
      </Box>
    ),
    responsive: [
      {
        breakpoint: 1119,
        settings: {
          // autoplay: true,
          // autoplaySpeed: 2500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  // useEffect(() => {
  //   if (isLogin) {
  //     if (layoutData && Object.entries(layoutData)?.length > 0) {
  //       if (availableSport && availableSport?.length > 0) {
  //         const teamSportData = availableSport
  //           ?.filter(item => item?.sportTypeId == 1)
  //           ?.filter(item => item?.racingFeatured === true);
  //         const newData = [];
  //         let sportList = (teamSportData || [])?.map((item, index) => {
  //           newData.push({
  //             label: item?.sportName,
  //             value: index,
  //             name: item?.sportName,
  //             id: item?.id
  //           });
  //         });
  //         const idOrder = { 1: 0, 2: 2, 3: 1 };
  //         const SortedData = newData?.sort((a, b) => {
  //           return (idOrder[a?.id] || 0) - (idOrder[b?.id] || 0);
  //         });
  //         setAllSportData(SortedData);
  //         setSelectedSport(SortedData?.[0]?.id);
  //         setCountryIds(layoutData?.racingCountryId);
  //         setStateIds(layoutData?.racingStateId);
  //         if (SortedData?.[0]?.id) {
  //           fetchFeaturedRace(
  //             SortedData?.[0]?.id,
  //             layoutData?.racingCountryId,
  //             layoutData?.racingStateId
  //           );
  //         }
  //       }
  //     }
  //   } else {
  //     if (availableSport && availableSport?.length > 0) {
  //       const teamSportData = availableSport
  //         ?.filter(item => item?.sportTypeId == 1)
  //         ?.filter(item => item?.racingFeatured === true);
  //       const newData = [];
  //       let sportList = (teamSportData || [])?.map((item, index) => {
  //         newData.push({
  //           label: item?.sportName,
  //           value: index,
  //           name: item?.sportName,
  //           id: item?.id
  //         });
  //       });
  //       const idOrder = { 1: 0, 2: 2, 3: 1 };
  //       const SortedData = newData?.sort((a, b) => {
  //         return (idOrder[a?.id] || 0) - (idOrder[b?.id] || 0);
  //       });
  //       setAllSportData(SortedData);
  //       setSelectedSport(SortedData?.[0]?.id);
  //       if (SortedData?.[0]?.id) {
  //         fetchFeaturedRace(SortedData?.[0]?.id, "", "");
  //       }
  //     }
  //   }
  // }, [availableSport, layoutData]);

  const memoizedSportData = useMemo(() => {
    if (!availableSport || availableSport.length === 0) {
      return [];
    }

    const teamSportData = availableSport
      ?.filter((item) => item?.sportTypeId == 1)
      ?.filter((item) => item?.racingFeatured === true);

    const idOrder = { 1: 0, 2: 2, 3: 1 };
    const SortedData = teamSportData?.sort((a, b) => {
      return (idOrder[a?.id] || 0) - (idOrder[b?.id] || 0);
    });

    const addAll = SortedData?.unshift({
      sportName: "All",
      id:
        layoutData?.racingFeaturedFixtures &&
        layoutData?.racingFeaturedFixtures?.length > 0
          ? layoutData?.racingFeaturedFixtures
          : 0,
    });

    const newData =
      SortedData?.map((item, index) => ({
        label: item?.sportName,
        value: index,
        name: item?.sportName,
        id: item?.id,
      })) || [];

    setAllSportData(newData);
    setSelectedSport(newData?.[0]?.id);

    return newData;
  }, [availableSport]);

  useEffect(() => {
    const SortedData = memoizedSportData;

    if (isLogin) {
      if (
        (layoutData && Object.entries(layoutData)?.length > 0) ||
        layoutData === null
      ) {
        if (SortedData.length > 0) {
          setCountryIds(layoutData?.racingCountryId);
          setStateIds(layoutData?.racingStateId);

          if (SortedData[0]?.id || SortedData[0]?.id === 0) {
            fetchFeaturedRace(
              SortedData[0]?.id,
              layoutData?.racingCountryId,
              layoutData?.racingStateId,
            );
          }
        }
      }
    } else {
      if (SortedData.length > 0) {
        if (SortedData[0]?.id || SortedData[0]?.id === 0) {
          fetchFeaturedRace(SortedData[0]?.id, "", "");
        }
      }
    }
  }, [isLogin, layoutData, memoizedSportData]);

  const fetchFeaturedRace = async (sportId, countryIds, stateIds) => {
    setFeatureRaceLoader(true);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const newAbortController = new AbortController();
    abortControllerRef.current = newAbortController;
    try {
      const { status, data } = await axiosInstance.get(
        `v2/racingFeatured?RaceOddKeyId=1&SportId=${sportId ? sportId : ""}`,
        {
          signal: newAbortController.signal,
        },
      );
      if (status === 200) {
        setFeatureRaceLoader(false);
        // setFeatureRaceData(data?.result);
        const raceinfo = data?.result?.map((race) => {
          let newdata = race?.runner?.map((ele) => {
            const maxno = ele?.MarketRelations?.reduce((max, obj) => {
              obj?.RaceOdds?.[0]?.intValue > max
                ? (max = obj?.RaceOdds?.[0]?.intValue)
                : (max = max);
              return max;
            }, 0);

            return {
              ...ele,
              currentBest: maxno === 0 ? "SP" : Number(maxno),
            };
            // let bestodds = fetchCurrentBestValue(item);
          });
          let filteredData = newdata?.filter(
            (item) =>
              item.isScratched != "1" &&
              item.isScratched != 1 &&
              item.isScratched != "true" &&
              item.isScratched != null &&
              item.isScratched != undefined,
          );

          const numbers = filteredData?.filter(
            (item) => typeof item?.currentBest == "number",
          );
          const allSP = filteredData?.every(
            (item) => item?.currentBest == "SP",
          );
          if (allSP) {
            return {
              ...race,
              runner: filteredData?.slice(0, 3),
            };
          }
          if (numbers.length > 0) {
            numbers.sort(
              (a, b) => Number(a?.currentBest) - Number(b?.currentBest),
            );
            return {
              ...race,
              runner: numbers.slice(0, 3),
            };
          }
          // let sortedData = newdata?.sort(
          //   (a, b) => a?.currentBest - b?.currentBest
          // );
        });
        setFeatureRaceData(raceinfo);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        setFeatureRaceLoader(false);
      }
    }
  };
  const fetchCurrentBestValue = (data) => {
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        return maxno;
      } else {
        return "SP";
      }
    } else {
      return "SP";
    }
  };

  const handleNavigate = useCallback(
    (data) => {
      if (!dragging) {
        const race =
          data?.sportId === 1
            ? "horse"
            : data?.sportId === 2
              ? "harness"
              : "greyhounds";
        const intl =
          data?.track?.Country?.id == 13 || data?.track?.Country?.id == 157
            ? false
            : true;
        navigate(
          `/racing/${race}/${data?.sportId}/${data?.id}/${data?.trackId}/${
            data?.id
          }?date=${moment(data?.startTimeDate)
            .tz(timezone)
            .format("YYYY-MM-DD")}&intl=${intl}`,
        );
      }
    },
    [dragging],
  );

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  const handleTabChange = (event, item) => {
    setTabValue(item?.value);
    setSelectedSport(item?.id);
    fetchFeaturedRace(item?.id, countryIds, stateIds);
    setFeatureRaceData([]);
  };

  const currentDate = moment().format("YYYY-MM-DD");
  return (
    <>
      {allSportData?.length > 0 && (
        <Box className="racing-slider-wrap featured-racing-slider">
          <Box>
            <Typography variant="h3" className="home-slider-header">
              {localesData?.HOME?.FEATURED_RACES}
            </Typography>
            <CustomTab
              mapData={allSportData}
              tabvalue={tabvalue}
              handleTabChange={handleTabChange}
              mode={"dark"}
            />
            <Box className="slider-data-container">
              {featureRaceLoader ? (
                <div className="allsport-loader-center featured-loader">
                  <Loader />
                </div>
              ) : featureRaceData?.length > 0 ? (
                <Slider
                  {...settingsRace}
                  afterChange={handleAfterChange}
                  className="racing-slick-slider"
                >
                  {featureRaceData?.map((race) => {
                    return (
                      <>
                        <Box
                          className="racing-slider-details racing-slider cursor-pointer"
                          onClick={() => {
                            handleNavigate(race);
                          }}
                        >
                          <Box className="slider-header-wrap d-flex text-align content-space-betwen">
                            <Box className="event-name-deatils d-flex text-align">
                              <Box className="d-flex text-align">
                                <span className="race-img">
                                  <img
                                    src={
                                      race?.sportId === 1
                                        ? RaceHorses
                                        : race?.sportId === 2
                                          ? RaceHarness
                                          : RaceGreyhounds
                                    }
                                    alt="horserace"
                                  />
                                </span>
                                <span className="event-name">
                                  {race?.track?.name}
                                </span>
                                <span className="event-name country-name">
                                  {race?.track?.Country
                                    ? "(" +
                                      race?.track?.Country?.countryCode +
                                      ")"
                                    : ""}
                                </span>
                              </Box>

                              {race?.raceNumber ? (
                                <span className="runner-name">
                                  {" "}
                                  {"R" + race?.raceNumber}{" "}
                                </span>
                              ) : (
                                <></>
                              )}
                            </Box>
                            <Box className="event-time-distance">
                              <span className="time-distance">
                                Jump time:{" "}
                                {moment(currentDate).isSame(
                                  moment(race?.startTimeDate).format(
                                    "YYYY-MM-DD",
                                  ),
                                )
                                  ? moment
                                      .utc(race?.startTimeDate)
                                      .local()
                                      .format("hh:mm a")
                                  : moment
                                      .utc(race?.startTimeDate)
                                      .local()
                                      .format("YYYY/MM/DD hh:mm a")}
                              </span>{" "}
                              <span className="seperator">| </span>
                              <span className="time-distance">
                                Distance:{" "}
                                {race?.Distance
                                  ? race?.Distance?.name + "m"
                                  : ""}
                              </span>
                            </Box>
                          </Box>
                          {race?.runner?.map((item) => {
                            return (
                              <>
                                {(item?.isScratched === "false" ||
                                  item?.isScratched === null ||
                                  item?.isScratched === "0") && (
                                  <Box className="slider-event-race-deatils d-flex text-align content-space-betwen">
                                    <Box className="details-race">
                                      <Typography className="race-name">
                                        {item?.runnerNumber}
                                        {"."} {item?.animal?.name} (
                                        {item?.barrierNumber})
                                      </Typography>
                                      <Box className="d-flex text-align deatils-1">
                                        <Typography className="race-deatils">
                                          <span className="text-semibold">
                                            W:
                                          </span>{" "}
                                          {Number(item?.JockeyWeight).toFixed(
                                            2,
                                          ) + "Kg"}
                                        </Typography>
                                        <Typography className="race-deatils">
                                          {item?.Jockey ? (
                                            race?.sportId === "2" ? (
                                              <>
                                                <span className="text-semibold">
                                                  D:{" "}
                                                </span>
                                                <span>
                                                  {item?.Jockey?.name}
                                                </span>
                                              </>
                                            ) : (
                                              <>
                                                <span className="text-semibold">
                                                  J:{" "}
                                                </span>
                                                <span>
                                                  {item?.Jockey?.name}
                                                </span>
                                              </>
                                            )
                                          ) : item?.Trainer ? (
                                            <>
                                              <span className="text-semibold">
                                                T:{" "}
                                              </span>
                                              <span>{item?.Trainer?.name}</span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      </Box>
                                      <Box className="d-flex text-align deatils-1">
                                        {race?.sportId === 1 ||
                                        race?.sportId === 2 ? (
                                          <Typography className="race-deatils">
                                            <span className="text-semibold">
                                              T:{" "}
                                            </span>{" "}
                                            {item?.Trainer?.name}
                                          </Typography>
                                        ) : (
                                          ""
                                        )}
                                      </Box>
                                    </Box>
                                    {/* <Box className="best-value">
                                  <span className="best">Best</span>
                                  <span className="best-odds">
                                    {fetchCurrentBestValue(
                                      item?.MarketRelations
                                    )}
                                  </span>
                                </Box> */}
                                    <RaceOdds
                                      data={item?.MarketRelations}
                                      sportId={race?.sportId}
                                      isResult={
                                        race?.RaceResultSummary ? true : false
                                      }
                                      raceId={race?.id}
                                    />
                                  </Box>
                                )}
                              </>
                            );
                          })}
                        </Box>
                      </>
                    );
                  })}
                </Slider>
              ) : (
                <Box className="nodata-feature f-16">No data Available</Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default FeaturedRace;
