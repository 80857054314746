import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import moment from "moment";
import { Config } from "src/helpers/context";

const StickyTableCell = withStyles(theme => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    // minWidth: "165px",
    boxShadow: "inset -2px 0px 0px 0px #ebebeb",
    "@media(max-width: 799px)": {
      //   maxWidth: "133px",
      boxShadow: "inset -2px 0px 0px 0px #ebebeb"
    }
  },
  body: {
    left: 0,
    position: "sticky",
    background: "#FFFFFF",
    boxShadow: "inset -2px 0px 0px 0px #ebebeb",
    "@media(max-width: 799px)": {
      boxShadow: "inset -2px 0px 0px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const data = [
  {
    id: 1,
    label: "Countries",
    subMenu: [
      {
        parentId: 1,
        name: "India"
      },
      {
        parentId: 1,
        name: "gujart"
      }
    ]
  },
  {
    id: 2,
    label: "States",
    subMenu: [
      {
        parentId: 1,
        name: "India"
      },
      {
        parentId: 1,
        name: "gujart"
      }
    ]
  },
  {
    id: 3,
    label: "Tracks",
    subMenu: [
      {
        parentId: 1,
        name: "India"
      },
      {
        parentId: 1,
        name: "gujart"
      }
    ]
  }
];

const LeagueSeasonDetails = ({ headerData, setSeasonActive }) => {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const SeasonId = searchParams.get("seasonId");
  const [loader, setLoader] = useState(false);
  const [seasonDetails, setSeasonDetails] = useState([]);
  const [offset, setOffset] = useState(0);
  const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    fetchSeasonDetails(0, []);
  }, [params]);

  const fetchSeasonDetails = async (offset, teamData) => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/season/?SportId=${params?.sportId}&tournamentId=${
          params?.tournamentId
        }&limit=10&offset=${offset}&seasonId=${SeasonId ? SeasonId : ""}`
      );
      if (status === 200) {
        setLoader(false);
        setDataCount(data?.result?.rows?.length);
        const teamDeatilsData = [...teamData, ...data?.result?.rows];
        setSeasonDetails(teamDeatilsData);
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  };

  const handleMoreData = () => {
    setOffset(offset + 10);
    fetchSeasonDetails(offset + 10, seasonDetails);
  };
  const handleLessData = () => {
    setOffset(offset - 10);
    if (seasonDetails?.length % 10 === 0) {
      setSeasonDetails(prevArray => prevArray.slice(0, -10));
    } else {
      setSeasonDetails(prevArray => prevArray.slice(0, -dataCount));
    }
  };

  const handleNavigate = data => {
    setSeasonActive(true);
    navigate(
      `/sport/statistics/${params?.sportId}/${params?.tournamentId}/league-data?seasonId=${data?.id}`
    );
  };

  const getTournamentName = data => {
    const sportName =
      Number(params?.sportId) === 4
        ? data?.CricketTournament
        : Number(params?.sportId) === 10
        ? data?.NBATournament
        : Number(params?.sportId) === 12 ||
          Number(params?.sportId) === 13 ||
          Number(params?.sportId) === 14
        ? data?.RLTournament
        : Number(params?.sportId) === 15
        ? data?.AFLTournament
        : Number(params?.sportId) === 9
        ? data?.ARTournament
        : Number(params?.sportId) === 16
        ? data?.GolfTournament
        : Number(params?.sportId) === 7
        ? data?.TennisTournament
        : Number(params?.sportId) === 11
        ? data?.BaseballTournament
        : Number(params?.sportId) === 17
        ? data?.IceHockeyTournament
        : Number(params?.sportId) === 6
        ? data?.BoxingTournament
        : Number(params?.sportId) === 5
        ? data?.MMATournament
        : Number(params?.sportId) === 8
        ? data?.SoccerTournament
        : "-";

    return sportName ? sportName?.name : "-";
  };

  return (
    <>
      <Box className="teamsport-data-accordian league-data-details">
        <Box className="teamsport-section-details">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                {SeasonId
                  ? headerData?.season?.name
                  : headerData?.tournament?.name}{" "}
                - Season
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="league-accordion-details">
              {/* <Box className="short-name-details">
                <Typography className="short-name">
                  P - <span>Played</span> , W - <span>Win</span>, L -{" "}
                  <span>Loss</span>, D - <span>Draw</span>, F - , A -{" "}
                  <span>Away</span>, PD - <span>Point Differential</span>, FPG -{" "}
                  <span>Fantasy Points per Game</span>, APG -{" "}
                  <span>Assists Points per Game</span>, Att% -{" "}
                  <span>Attempts</span> , Def% - <span>Defeated</span>{" "}
                </Typography>
              </Box> */}
              <Box className="teamsport-header-details">
                <Typography className="heading-title">Season</Typography>
              </Box>
              <Box className="table-overlay">
                {loader && (
                  <div className="allsport-loader-center">
                    <Loader />
                  </div>
                )}
                {!loader && (
                  <>
                    <TableContainer
                      id="details-table"
                      className="teamsport-data-table team-sport-tables"
                    >
                      {/* <Box className="overlay"></Box> */}
                      <Table className="data-table" style={{ minWidth: 940 }}>
                        <TableHead className="table-header">
                          <TableRow className="table-head-row ">
                            <TableCell className={`${classes.head} first-cell`}>
                              Season
                            </TableCell>
                            <TableCell className={classes.head}>Year</TableCell>
                            <TableCell className={classes.head}>
                              Tournament
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="table-body">
                          {seasonDetails?.length > 0 ? (
                            <>
                              {seasonDetails?.map((item, i) => {
                                return (
                                  <>
                                    <TableRow
                                      key={i}
                                      className="table-body-row "
                                    >
                                      <TableCell
                                        className={`${classes.cell} first-cell light-blue cursor-pointer`}
                                        onClick={() => handleNavigate(item)}
                                      >
                                        {item?.name}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.year ? item?.year : "-"}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        onClick={() =>
                                          navigate(
                                            `/sport/statistics/${
                                              params?.sportId
                                            }/${
                                              Number(params?.sportId) === 4
                                                ? item?.CricketTournament?.id
                                                : Number(params?.sportId) === 10
                                                ? item?.NBATournament?.id
                                                : Number(params?.sportId) ===
                                                    12 ||
                                                  Number(params?.sportId) ===
                                                    13 ||
                                                  Number(params?.sportId) === 14
                                                ? item?.RLTournament?.id
                                                : Number(params?.sportId) === 15
                                                ? item?.AFLTournament?.id
                                                : Number(params?.sportId) === 9
                                                ? item?.ARTournament?.id
                                                : Number(params?.sportId) === 16
                                                ? item?.GolfTournament?.id
                                                : Number(params?.sportId) === 7
                                                ? item?.TennisTournament?.id
                                                : Number(params?.sportId) === 11
                                                ? item?.BaseballTournament?.id
                                                : Number(params?.sportId) === 17
                                                ? item?.IceHockeyTournament?.id
                                                : Number(params?.sportId) === 6
                                                ? item?.BoxingTournament?.id
                                                : Number(params?.sportId) === 5
                                                ? item?.MMATournament?.id
                                                : Number(params?.sportId) === 8
                                                ? item?.SoccerTournament?.id
                                                : "-"
                                            }/league-data`
                                          )
                                        }
                                      >
                                        {getTournamentName(item)}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <TableRow>
                              <TableCell colSpan={100} className="no-data-td">
                                No Data Available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {seasonDetails?.length > 0 ? (
                      <Box className="view-more">
                        {offset + 10 === seasonDetails?.length ? (
                          <span
                            className="view-more-text"
                            onClick={() => handleMoreData()}
                          >
                            View More
                          </span>
                        ) : (
                          <></>
                        )}
                        {offset >= 10 && seasonDetails?.length > 10 ? (
                          <span
                            className="view-more-text view-lese-text"
                            onClick={() => handleLessData()}
                          >
                            View Less
                          </span>
                        ) : (
                          <></>
                        )}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </>
  );
};

export default LeagueSeasonDetails;
