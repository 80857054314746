import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { IntlContext } from "src/App";
import { ReactComponent as RightIcon } from "src/assets/images/icons/right.svg";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { setApiMessage } from "src/helpers/commonFunctions";
import { identifiers } from "src/helpers/constants/identifier";
import CustomiseFilterModal from "./customiseModal/customiseFilterModal";
import CloseIcon from "@mui/icons-material/Close";
import DragAndDrop from "./customiseModal/dragAndDrop";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubscriptionData } from "src/helpers/store/Actions/SubscriptionData";
import { fetchLayoutData } from "src/helpers/store/Actions/LayoutData";
import { useNavigate } from "react-router";

const CustomiseHomePage = ({ userData, isLoading }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redux_userData = useSelector(
    (state) => state?.reduxData?.SubscripitionData,
  );
  const [homeArray, setHomeArray] = useState([]);
  const [filterToogleModal, setFilterToogleModal] = useState(false);
  const [customiseTabValue, setCustomiseTabValue] = useState(0);
  const [customiseModalVal, setCustomiseModalVal] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const customiseData = [
    { value: 0, label: "Select your Favourites" },
    { value: 1, label: "Drag/move Cards" },
  ];

  const handleChange = (id, value) => {
    setCustomiseTabValue(id);
  };

  const handleToogleModal = () => {
    setFilterToogleModal(!filterToogleModal);
    setCustomiseTabValue(0);
  };

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    const userDetails = userData?.data;
    if (userData?.data) {
      const updatedArray = identifiers?.customizeHome?.map((obj) => ({
        ...obj,
        isSelected:
          obj?.id === 1
            ? userDetails?.isDefaultHomePage
            : obj?.id === 2
              ? userDetails?.isDefaultSportPage
              : obj?.id === 3
                ? userDetails?.isDefaultRacingPage
                : false,
      }));
      setHomeArray(updatedArray);
    }
  }, [userData.data]);

  const handleChangeSelect = (item) => {
    if (!item?.isSelected) {
      const updatedArray = homeArray?.map((obj) => ({
        ...obj,
        isSelected: obj?.id === item?.id,
      }));
      setHomeArray(updatedArray);
    }
  };
  const fetchLayout = async (type) => {
    try {
      const { status, data } = await axiosInstance.get(
        `user/customise/UserPagesGet?type=${type}`,
      );
      if (status === 200) {
        dispatch(fetchLayoutData(data?.result));
      } else {
        dispatch(fetchLayoutData(null));
      }
    } catch (err) {
      dispatch(fetchLayoutData(null));
    }
  };
  const editProfileDetails = async () => {
    let payload = {
      isDefaultHomePage: homeArray[0]?.isSelected,
      isDefaultSportPage: homeArray[1]?.isSelected,
      isDefaultRacingPage: homeArray[2]?.isSelected,
    };

    try {
      const { status, data } = await axiosInstance.put(
        "user/editProfile",
        payload,
      );
      if (status === 200) {
        setApiMessage("success", data?.message);
        let tempUserData = { ...redux_userData };
        tempUserData.isDefaultHomePage = homeArray[0]?.isSelected;
        tempUserData.isDefaultSportPage = homeArray[1]?.isSelected;
        tempUserData.isDefaultRacingPage = homeArray[2]?.isSelected;
        dispatch(fetchSubscriptionData(tempUserData));

        fetchLayout(
          homeArray[0]?.isSelected
            ? "homepage"
            : homeArray[1]?.isSelected
              ? "sportpage"
              : "racingpage",
        );
      } else {
        setApiMessage("success", "An Error Occurred");
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
    }
  };

  const CustomiseTabsComponent = () => {
    return (
      <Box className="customise-tab-section">
        <Tabs
          value={customiseTabValue}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          className="customise-tab-details"
          id="customise-tab"
        >
          {customiseData?.map((item, index) => {
            return (
              <Box key={index}>
                <Tab
                  label={item?.label}
                  value={item?.value}
                  onChange={() => handleChange(item?.value, item?.label)}
                  className={item?.value === customiseTabValue ? "active" : ""}
                />
              </Box>
            );
          })}
        </Tabs>
      </Box>
    );
  };

  const subscriptionDetails = userData?.data?.subscription;
  const isSubscriptionActive =
    subscriptionDetails?.status === "active" &&
    subscriptionDetails?.SubscriptionPlan?.key === "odds"
      ? true
      : false;

  return (
    <Box className="customise-wrapper">
      <Box className="useredit-details">
        <h4>{localesData?.EDITPROFILE?.SELECT_AND_CUSTOMISE_YOUR_HOMEPAGE}</h4>
      </Box>
      <Box>
        {isLoading && (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        )}
        {!isLoading &&
          homeArray?.map((item, index) => {
            return (
              <Box className="customise-container" key={index}>
                <Box className="customise-screen-name-container">
                  <Typography className="customise-screen-name">
                    {item?.title}
                    <span className="customise-screen-name-default">
                      {item?.type}
                    </span>
                  </Typography>
                </Box>
                <Box className="customise-screen-button-container">
                  {item?.isSelected ? (
                    <Button
                      className="customise-button customise-selected-btn"
                      disableRipple
                    >
                      <RightIcon className="right-icon" /> Selected
                    </Button>
                  ) : (
                    <Button
                      className="customise-button customise-set-as-btn"
                      onClick={() => handleChangeSelect(item)}
                      disableRipple
                    >
                      Set as Hompage
                    </Button>
                  )}
                  <Button
                    className="customise-button customise-btn"
                    onClick={() => {
                      handleToogleModal();
                      setCustomiseModalVal(item);
                    }}
                    disabled={!item?.isSelected || !isSubscriptionActive}
                  >
                    Customise
                  </Button>
                </Box>
              </Box>
            );
          })}
        {!isLoading && !isSubscriptionActive && (
          <>
            <Box className="subs-note-container">
              <span
                className="subs-link"
                onClick={() => navigate("/membership")}
              >
                membership now{" "}
              </span>
              <span>
                to fully customize your homepage based on your interests and
                preferences.
              </span>
            </Box>
          </>
        )}
        {!isLoading && homeArray?.length !== 0 && (
          <Box className="customise-save-btn-wrapper">
            <Button
              className="save-btn"
              disableRipple
              onClick={() => editProfileDetails()}
            >
              Save changes
            </Button>
          </Box>
        )}
        {!isLoading && homeArray?.length === 0 && (
          <div className="allsport-loader-center">
            <Typography>No data Available</Typography>
          </div>
        )}
      </Box>
      <Dialog
        onClose={() => handleToogleModal()}
        aria-labelledby="customized-dialog-title"
        open={filterToogleModal}
        className="customise-home-modal filter-modal"
      >
        <DialogTitle className="modal-title">
          <Box className="modal-wrap">
            <Typography variant="h6" className="title">
              Customise {customiseModalVal?.title}
            </Typography>

            {screenWidth > 768 && <CustomiseTabsComponent />}
          </Box>
          <IconButton
            aria-label="close"
            onClick={() => handleToogleModal()}
            className="close-icon"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="customise-home-modal-details filter-modal-details">
          {screenWidth <= 768 && <CustomiseTabsComponent />}
          {/* {customiseTabValue === 0 ? ( */}
          <CustomiseFilterModal
            layoutType={customiseModalVal}
            customiseTabValue={customiseTabValue}
          />
          {/* ) : (
            <DragAndDrop
              layoutType={customiseModalVal}
              customiseTabValue={customiseTabValue}
            />
          )} */}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CustomiseHomePage;
