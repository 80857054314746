import React from "react";
import { Box, Button, Typography } from "@mui/material";
import noData from "../../../../assets/images/no-data.svg";
import { useNavigate } from "react-router-dom";
import "./nodata.scss";
import { Config } from "src/helpers/context";
import { release } from "src/helpers/context/release";

const NoDataComp = ({ intRaceType }) => {
  const navigate = useNavigate();
  const handleNavigate = route => {
    navigate(route);
  };
  return (
    <Box className="no-data-container">
      <Box className="no-data-img-box">
        <img src={noData} alt="noData" />
      </Box>
      {intRaceType === "intRaceType" ? (
        <>
          <Typography className="bold-font-22">Coming Soon!</Typography>
          <Typography className="no-data-txt">
            The information will be coming soon. Would you like to explore other
            racing?
          </Typography>
        </>
      ) : (
        <Typography className="no-data-txt">
          {Config?.release == "IN"
            ? "There is no information available here."
            : "There is no information available here. Would you like to explore other sports or racing? "}
        </Typography>
      )}

      {intRaceType === "intRaceType" ? (
        <Box className="button-box">
          <Button
            className="nav-button"
            // onClick={() =>
            //   handleNavigate(`/teamsports/americanfootball/odds/0/false`)
            // }
          >
            International Horse Racing
          </Button>
          <Button
            className="nav-button"
            // onClick={() =>
            //   handleNavigate(`/teamsports/americanfootball/odds/0/false`)
            // }
          >
            Singapore Horse Racing
          </Button>
          <Button
            className="nav-button"
            // onClick={() =>
            //   handleNavigate(`/teamsports/americanfootball/odds/0/false`)
            // }
          >
            Hong Kong Horse Racing
          </Button>
        </Box>
      ) : (
        <>
          <Box className="button-box">
            {release[Config.release]?.NoDataComp?.map((item, index) => {
              return (
                <>
                  <Button
                    key={index}
                    className="nav-button"
                    onClick={() => handleNavigate(`${item?.url}`)}
                  >
                    {item?.name}
                  </Button>
                </>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};

export default NoDataComp;
