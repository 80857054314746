import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import Select, { components } from "react-select";
import { ReactComponent as Unchecked } from "../../../../assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Checked } from "../../../../assets/images/icons/smartb-check.svg";
import { ReactComponent as Search } from "../../../../assets/images/icons/search.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";

const RacesSearchFilter = ({
  params,
  handleSearch,
  searchInput,
  filterRaceType,
  ChangeRaceFilter,
  collepseRace,
  letters,
  toggleLetter,
  handleOnScrollBottomCountry,
  handleCountryInputChange,
  isCountrySearch,
  searchCountry,
  countryId,
  country,
  fetchState,
  setCountryId,
  setState,
  setStateId,
  setListCount,
  setViewAllOffset,
  setisCountrySelectOpen,
  fetchTrackList,
  selectedLetters,
  lettersCount,
  handleOnScrollBottomState,
  handleStateInputChange,
  isStateSearch,
  searchState,
  stateId,
  state,
  setisStateSelectOpen,
  handleResetAll
}) => {
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectIndicator />
      </components.DropdownIndicator>
    );
  };

  return (
    <Box className="select-search-container races-filter-wrapper">
      <TextField
        placeholder={
          params?.profileType === "trackprofiles"
            ? "Search (by track name)"
            : params?.profileType === "jockeyprofiles"
            ? "Search (by jockey name)"
            : params?.profileType === "trainerprofiles"
            ? "Search (by trainer name)"
            : "Search (by runner name)"
        }
        variant="outlined"
        type="text"
        name="search"
        className="search_input"
        onChange={e => {
          handleSearch(e);
        }}
        value={searchInput}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
      />
      {params?.profileType === "trackprofiles" ? (
        <Box className="select-wrap">
          <Select
            className="React series-select"
            classNamePrefix="select"
            onMenuScrollToBottom={e => handleOnScrollBottomCountry(e)}
            onInputChange={e => handleCountryInputChange(0, e)}
            placeholder="Country: All"
            value={
              isCountrySearch
                ? searchCountry?.find(item => {
                    return item?.value == countryId;
                  })
                : country?.find(item => {
                    return item?.value == countryId;
                  })
            }
            onChange={e => {
              fetchState(e?.value, 0, true, 0);
              setCountryId(e?.value);
              setState([]);
              setStateId("");
              setListCount(0);
              setViewAllOffset(0);
              setisCountrySelectOpen(false);
              fetchTrackList(
                e?.value,
                0,
                "",
                collepseRace,
                0,
                selectedLetters?.length > 0 ? selectedLetters : lettersCount
              );
            }}
            onFocus={() => setisCountrySelectOpen(true)}
            onBlur={() => setisCountrySelectOpen(false)}
            options={isCountrySearch ? searchCountry : country}
            components={{ DropdownIndicator }}
          />
          <Select
            className={`React series-select ${
              countryId === 0 ? "disable-state" : ""
            }`}
            classNamePrefix="select"
            onMenuScrollToBottom={e => handleOnScrollBottomState(e)}
            onInputChange={e => handleStateInputChange(0, e)}
            placeholder="State: All"
            isDisabled={countryId === 0}
            value={
              isStateSearch
                ? searchState?.find(item => {
                    return item?.value == stateId;
                  })
                : stateId !== "" &&
                  state?.find(item => {
                    return item?.value == stateId;
                  })
            }
            onChange={e => {
              setStateId(e?.value);
              setisStateSelectOpen(false);
              setListCount(0);
              setViewAllOffset(0);
              fetchTrackList(
                countryId,
                e?.value,
                "",
                collepseRace,
                0,
                selectedLetters?.length > 0 ? selectedLetters : lettersCount
              );
            }}
            onFocus={() => setisStateSelectOpen(true)}
            onBlur={() => setisStateSelectOpen(false)}
            options={isStateSearch ? searchState : state}
            components={{ DropdownIndicator }}
          />
          <Box>
            <ul className="Filteritemlist-racing">
              {filterRaceType?.length > 0 &&
                filterRaceType?.map((filter, i) => (
                  <li key={i}>
                    <FormControl>
                      <label>
                        <Checkbox
                          className="filter-racing"
                          icon={<Unchecked />}
                          checkedIcon={<Checked />}
                          name="filter"
                          value={filter?.id}
                          onChange={event => {
                            ChangeRaceFilter(event, filter?.id);
                          }}
                          checked={collepseRace.includes(filter?.id)}
                        />
                        {filter?.icon}
                      </label>
                    </FormControl>
                  </li>
                ))}
            </ul>
          </Box>
        </Box>
      ) : (
        <></>
      )}

      <Box className="track-filter-section">
        <Box
          className={
            `alphabet-filter`
            // params?.profileType !== "trackprofiles"
            //   ? `alphabet-filter nosport-filter`
            //   : `alphabet-filter`
          }
        >
          {letters?.map((item, index) => (
            <Box
              key={index}
              className={`letters-alphabet ${
                item.isSelected ? "selected" : ""
              }`}
              onClick={() => toggleLetter(index)}
            >
              {item.letter}
            </Box>
          ))}
        </Box>
        <Box
          className="reset-all cursor-pointer"
          onClick={() => handleResetAll()}
        >
          <Typography className="reset-all-text">Clear all filters</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default RacesSearchFilter;
