import React from "react";
import Page from "src/components/Page";
import TippingFAQsPage from "src/views/component/TippingFAQs";

const TippingFAQs = () => {
  return (
    <Page title="Tipping FAQs">
      <TippingFAQsPage />
    </Page>
  );
};

export default TippingFAQs;
