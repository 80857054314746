import React, { useState, useContext, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { ReactComponent as VisiblePassword } from "src/assets/images/icons/VisiblePassword.svg";
import { ReactComponent as UnVisiblePassword } from "src/assets/images/icons/UnVisiblePassword.svg";
import { parseISO } from "date-fns";
import { Config, fetchFromStorage } from "src/helpers/context";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import * as Yup from "yup";
import Select, { components } from "react-select";
import _ from "lodash";
import { IntlContext } from "src/App";
import { differenceInYears } from "date-fns";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "src/components/Loader";
import DefaultImg from "src/assets/images/smartb_default.png";
import "./membershipFrom.scss";

const MemberPersonalInfo = ({ innerRef, emailmsg, setEmailmsg }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const { ValueContainer, Placeholder } = components;

  const reduxGetUserData = useSelector(
    (state) => state?.reduxData?.SubscripitionData,
  );

  const [formvalues, setformvalues] = useState();
  const [getUserData, setGetUserData] = useState({});
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [state, setState] = useState([]);
  const [count, setcount] = useState(0);
  const [pageCountry, setpageCountry] = useState(0);
  const [statecount, setstatecount] = useState(0);
  const [pageState, setpageState] = useState(0);
  const [searchCountryCount, setsearchCountryCount] = useState("");
  const [searchCountry, setsearchCountry] = useState([]);
  const [isCountrySearch, setisCountrySearch] = useState("");
  const [SearchCountrypage, setSearchCountrypage] = useState(0);
  const [searchStateCount, setsearchStateCount] = useState("");
  const [searchState, setsearchState] = useState([]);
  const [isStateSearch, setisStateSearch] = useState("");
  const [searchStatePage, setsearchStatePage] = useState(0);
  const [isCountrySelectOpen, setisCountrySelectOpen] = useState(false);
  const [countryApiCount, setCountryApiCount] = useState(0);
  const [isStateSelectOpen, setisStateSelectOpen] = useState(false);
  const [stateApiCount, setStateApiCount] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showPassword, setshowPassword] = useState(false);
  const [isDataFetch, setIsDataFetch] = useState(false);
  const [countryPhone, setCountryPhone] = useState([]);
  const [countCountryPhone, setCountCountryPhone] = useState(0);
  const [pageCountryPhone, setpageCountryPhone] = useState(0);
  const [searchCountryPhoneCount, setsearchCountryPhoneCount] = useState("");
  const [searchCountryPhone, setsearchCountryPhone] = useState([]);
  const [isCountryPhoneSearch, setisCountryPhoneSearch] = useState("");
  const [SearchCountryPhonepage, setSearchCountryPhonepage] = useState(0);
  const [isCountryPhoneSelectOpen, setisCountryPhoneSelectOpen] =
    useState(false);
  const [countryPhoneApiCount, setCountryPhoneApiCount] = useState(0);
  const [priorityCountry, setPriorityCountry] = useState([]);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const nameRegex = /^[a-zA-Z\s'-]+$/;

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const isLogin = handleRestrictedUser();

  const passwordValidation =
    isLogin === false
      ? Yup.string()
          .required(localesData?.validation?.required_message)
          .min(6, localesData?.validation?.password_length)
          .label("Password")
      : Yup.string().nullable();

  const dobValidation =
    isLogin === false
      ? Yup.date()
          .nullable()
          .typeError("")
          .test("dob", "You have to be at least 18", function (value) {
            return differenceInYears(new Date(), new Date(value)) >= 18;
          })
          .required(localesData?.validation?.required_message)
      : Yup.string().nullable();

  const countryStateValidation =
    isLogin === false
      ? Yup.string().required(localesData?.validation?.required_message)
      : Yup.string().nullable();

  // country select box api

  const fetchCountry = async (page) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/country?limit=20&offset=${page}`,
      );
      if (status === 200) {
        setCountryApiCount(countryApiCount + 1);
        setcount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        const topCountries = [
          {
            label: "Australia",
            value: 13,
          },
          {
            label: "United Kingdom",
            value: 230,
          },
          {
            label: "United States",
            value: 231,
          },
          {
            label: "India",
            value: 101,
          },
        ];

        const nonPrioritizedCountries = data?.result?.rows?.filter(
          (country) => !topCountries?.includes(country?.value),
        );

        let track = nonPrioritizedCountries?.map((item) => {
          newdata.push({
            label: item?.country,
            value: item?.id,
          });
        });
        const orderedPriorityCountries = _.concat(topCountries, newdata);
        const orderedCountries = newdata;
        setCountry(
          _.unionBy(
            country,
            page == 0 ? orderedPriorityCountries : orderedCountries,
          ),
        );
        let filterData = _.unionBy(
          country,
          page == 0 ? orderedPriorityCountries : orderedCountries,
        )?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });

        const finalArray = filterData?.unshift(...topCountries);

        setCountry(
          _.uniqBy(filterData, function (e) {
            return e.value;
          }),
        );

        //Old Code

        // let track = data?.result?.rows?.map((item) => {
        //   newdata.push({
        //     label: item?.country,
        //     value: item?.id,
        //   });
        // });

        // setCountry(_.unionBy(country, newdata));
      }
    } catch (err) {}
  };

  const handleOnScrollBottomCountry = () => {
    if (
      isCountrySearch !== "" &&
      searchCountryCount !== Math.ceil(SearchCountrypage / 20)
    ) {
      handleCountryInputChange(SearchCountrypage + 20, isCountrySearch);

      setSearchCountrypage(SearchCountrypage + 20);
    } else {
      if (count !== Math.ceil(pageCountry / 20)) {
        fetchCountry(pageCountry + 20);
        setpageCountry(pageCountry + 20);
      }
    }
  };

  const handleCountryInputChange = (page, value) => {
    axiosInstance
      .get(`public/country?limit=20&offset=${page}&search=${value}`)
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchCountryCount(res?.data?.result?.count / 20);

          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.country,
              value: item?.id,
            });
          });
          let filterData = _.unionBy(searchCountry, newdata);
          setsearchCountry(
            _.uniqBy(filterData, function (e) {
              return e.value;
            }),
          );
          setisCountrySearch(value);
        }
      });
  };

  const fetchState = async (id, page, type, statecount) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/state/country/${id}?limit=20&offset=${page}`,
      );
      if (status === 200) {
        setpageState(page);
        setStateApiCount(statecount + 1);
        setstatecount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map((item) => {
          newdata.push({
            label: item?.state,
            value: item?.id,
          });
        });
        // setState(_.unionBy(state, newdata))
        if (type) {
          // setState(_.unionBy(newdata));
          let filterData = _.unionBy(newdata);
          let sortData = filterData?.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          setState(
            _.uniqBy(sortData, function (e) {
              return e.value;
            }),
          );
        } else {
          // setState(_.unionBy(state, newdata));
          let filterData = _.unionBy(state, newdata);
          let sortData = filterData?.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          setState(
            _.uniqBy(sortData, function (e) {
              return e.value;
            }),
          );
        }
      }
    } catch (err) {}
  };

  const handleOnScrollBottomState = () => {
    // if (statecount !== Math.ceil(pageState / 20)) {
    //   fetchState(countryId, pageState + 20, false);
    //   setpageState(pageState + 20);
    // }
    if (
      isStateSearch !== "" &&
      searchStateCount !== Math.ceil(searchStatePage / 20)
    ) {
      handleStateInputChange(searchStatePage + 20, isStateSearch);

      setsearchStatePage(searchStatePage + 20);
    } else {
      if (statecount !== Math.ceil(pageState / 20)) {
        fetchState(countryId, pageState + 20, false, stateApiCount);
        setpageState(pageState + 20);
      }
    }
  };

  const handleStateInputChange = (page, value) => {
    axiosInstance
      .get(
        `public/state/country/${countryId}?limit=20&offset=${page}&search=${value}`,
      )
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchStateCount(res?.data?.result?.count / 20);

          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.state,
              value: item?.id,
            });
          });
          const finalStateData = newdata.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          let filterData = _.unionBy(searchState, finalStateData);
          setsearchState(
            _.uniqBy(filterData, function (e) {
              return e.value;
            }),
          );
          setisStateSearch(value);
        }
      });
  };

  const formatOptionLabel = ({ label, flag, phoneCode }) => {
    if (isCountryPhoneSearch) {
      return label;
    } else {
      return (
        <div>
          <img
            src={flag}
            onError={(e) => (e.target.src = DefaultImg)}
            style={{ width: "24px" }}
          />
          <span
            style={{
              width: "42px",
              margin: "0px 5px",
              display: "inline-block",
            }}
          >
            {phoneCode ? `+${phoneCode}` : ""}
          </span>
          <span>{`(` + label + `)`}</span>
        </div>
      );
    }
  };

  const fetchProrityCountry = async (page) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/country?limit=20&offset=${page}&countryId=13,230,231,101`,
      );
      if (status === 200) {
        let newdata = [];
        let track = data?.result?.rows?.map((item) => {
          newdata.push({
            label: item?.country,
            value: item?.id,
            phoneCode: item?.phoneCode,
            flag: item?.country_flag?.includes("uploads")
              ? Config?.baseURL + item?.country_flag
              : item?.country_flag,
          });
        });
        setPriorityCountry(newdata);
        fetchCountryPhone(0, newdata);
        fetchCountry(0, newdata);
      }
    } catch (err) {}
  };
  const fetchCountryPhone = async (page, priorityCountryData) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/country?limit=20&offset=${page}`,
      );
      if (status === 200) {
        setCountryApiCount(countryApiCount + 1);
        setCountCountryPhone(Math.ceil(data?.result?.count / 20));
        let newdata = [];

        const nonPrioritizedCountries = data?.result?.rows?.filter(
          (country) => !priorityCountryData?.includes(country?.value),
        );

        let track = nonPrioritizedCountries?.map((item) => {
          newdata.push({
            label: item?.country,
            value: item?.id,
            phoneCode: item?.phoneCode,
            flag: item?.country_flag?.includes("uploads")
              ? Config?.baseURL + item?.country_flag
              : item?.country_flag,
          });
        });
        const orderedPriorityCountries = priorityCountryData
          ? _.concat(priorityCountryData, newdata)
          : [];
        const orderedCountries = newdata;
        setCountryPhone(
          _.unionBy(
            countryPhone,
            page == 0 ? orderedPriorityCountries : orderedCountries,
          ),
        );
        let filterData = _.unionBy(
          countryPhone,
          page == 0 ? orderedPriorityCountries : orderedCountries,
        )?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });

        const finalArray = filterData?.unshift(...priorityCountryData);

        setCountryPhone(
          _.uniqBy(filterData, function (e) {
            return e.value;
          }),
        );

        //Old Code

        // let track = data?.result?.rows?.map((item) => {
        //   newdata.push({
        //     label: item?.country,
        //     value: item?.id,
        //   });
        // });

        // setCountry(_.unionBy(country, newdata));
      }
    } catch (err) {}
  };

  const handleOnScrollBottomCountryPhone = () => {
    if (
      isCountryPhoneSearch !== "" &&
      searchCountryPhoneCount !== Math.ceil(SearchCountryPhonepage / 20)
    ) {
      handleCountryPhoneInputChange(
        SearchCountryPhonepage + 20,
        isCountryPhoneSearch,
      );

      setSearchCountryPhonepage(SearchCountryPhonepage + 20);
    } else {
      if (countCountryPhone !== Math.ceil(pageCountryPhone / 20)) {
        fetchCountryPhone(pageCountryPhone + 20, priorityCountry);
        setpageCountryPhone(pageCountryPhone + 20);
      }
    }
  };
  const handleCountryPhoneInputChange = (page, value) => {
    axiosInstance
      .get(`public/country?limit=20&offset=${page}&search=${value}`)
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchCountryPhoneCount(res?.data?.result?.count / 20);

          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.country,
              value: item?.id,
              phoneCode: item?.phoneCode,
              flag: item?.country_flag?.includes("uploads")
                ? Config?.baseURL + item?.country_flag
                : item?.country_flag,
            });
          });
          let filterData = _.unionBy(searchCountryPhone, newdata);
          setsearchCountryPhone(
            _.uniqBy(filterData, function (e) {
              return e.value;
            }),
          );
          setisCountryPhoneSearch(value);
        }
      });
  };

  useEffect(() => {
    fetchCountry(0);
    fetchProrityCountry(0);
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (isLogin) {
      if (reduxGetUserData && reduxGetUserData !== undefined) {
        setGetUserData(reduxGetUserData);
        setIsDataFetch(true);
      }
    } else {
      setIsDataFetch(true);
    }
  }, [reduxGetUserData]);

  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isCountrySelectOpen) {
          if (isCountrySearch === "" && countryApiCount !== count) {
            fetchCountry(pageCountry + 20);
            setpageCountry(pageCountry + 20);
          }
        }
      }, 2000);
    }
  }, [isCountrySelectOpen, countryApiCount, isCountrySearch]);

  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isStateSelectOpen) {
          if (isStateSearch === "" && stateApiCount !== statecount) {
            fetchState(countryId, pageState + 20, false, stateApiCount);
            setpageState(pageState + 20);
          }
        }
      }, 2000);
    }
  }, [isStateSelectOpen, stateApiCount, isStateSearch]);

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null,
        )}
      </ValueContainer>
    );
  };

  const checkEmail = async (value) => {
    try {
      const { status, data } = await axiosInstance.get(
        `user/checkEmail/${value}`,
      );
      if (status === 200 && data?.success === true) {
        setEmailmsg("");
      }
    } catch (err) {
      setEmailmsg(err?.response?.data?.message);
    }
  };

  return (
    <Box className="your-details-wrap">
      <Typography className="details">
        {isLogin ? "Check your details" : "Enter your details"}
      </Typography>
      {!isDataFetch ? (
        <div className="allsport-loader-center ">
          <Loader />
        </div>
      ) : (
        <Formik
          enableReinitialize
          innerRef={innerRef}
          initialValues={{
            email: getUserData ? getUserData?.username : "",
            firstname: getUserData ? getUserData?.firstName : "",
            lastname: getUserData ? getUserData?.lastName : "",
            password: "",
            dob: null,
            country: "",
            state: "",
            countryPhone: getUserData ? getUserData?.phoneCountryId : "",
            phonenumber: getUserData ? getUserData?.phone : "",
            documentsRead: false,
            documentsReadSOC: false,
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .nullable()
              .matches(
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                localesData?.validation?.valid_email,
              )
              .max(255)
              .required(localesData?.validation?.required_message),
            firstname: Yup.string()
              .nullable()
              .required(localesData?.validation?.required_message)
              .test(
                "firstname",
                "Only letters, spaces, hyphens, and apostrophes are allowed.",
                (value) => value && value.match(nameRegex),
              ),
            lastname: Yup.string()
              .nullable()
              .required(localesData?.validation?.required_message)
              .test(
                "lastname",
                "Only letters, spaces, hyphens, and apostrophes are allowed.",
                (value) => value && value.match(nameRegex),
              ),
            password: passwordValidation,
            dob: dobValidation,
            countryPhone: Yup.string().required(
              localesData?.validation?.required_message,
            ),
            country: countryStateValidation,
            state: countryStateValidation,
            phonenumber: Yup.string()
              .nullable()
              .matches(phoneRegExp, localesData?.validation?.number_valid)
              .required(localesData?.validation?.required_message)
              .min(9, localesData?.validation?.NUMBER_MIN_MAX_LENGTH)
              .max(10, localesData?.validation?.NUMBER_MIN_MAX_LENGTH),
            documentsRead:
              isLogin === false
                ? Yup.boolean().oneOf([true], "Checkbox is required")
                : Yup.boolean().nullable(),
            documentsReadSOC: Yup.boolean().oneOf(
              [true],
              "Checkbox is required",
            ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box className="personal-info-container">
                <Box className="personal-info">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textfield"
                    type="text"
                    name="email"
                    label="Email"
                    error={Boolean(touched?.email && errors?.email) || emailmsg}
                    helperText={
                      emailmsg ? emailmsg : touched?.email ? errors?.email : ""
                    }
                    onChange={handleChange}
                    value={values?.email}
                    onBlur={(e) => {
                      if (!isLogin) {
                        checkEmail(e.target.value);
                      }
                      handleBlur(e);
                    }}
                  />
                </Box>
                <Box className="personal-info grid-2">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textfield"
                    type="text"
                    name="firstname"
                    label="First name"
                    error={Boolean(touched?.firstname && errors?.firstname)}
                    helperText={touched?.firstname ? errors?.firstname : ""}
                    onChange={handleChange}
                    value={values?.firstname}
                    onBlur={handleBlur}
                    onKeyDown={(e) => {
                      if (!nameRegex.test(e.key)) e.preventDefault();
                    }}
                  />
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textfield"
                    type="text"
                    name="lastname"
                    label="Last name"
                    error={Boolean(touched?.lastname && errors?.lastname)}
                    helperText={touched?.lastname ? errors?.lastname : ""}
                    onChange={handleChange}
                    value={values?.lastname}
                    onKeyDown={(e) => {
                      if (!nameRegex.test(e.key)) e.preventDefault();
                    }}
                  />
                </Box>
                {!isLogin && (
                  <>
                    <Box className="personal-info grid-2">
                      <Box className="date-picker-wrap">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            autoOk
                            // disableToolbar
                            disableFuture
                            variant="inline"
                            format="dd/MM/yyyy"
                            placeholder="DD/MM/YYYY"
                            label="DOB"
                            margin="dense"
                            id="date-picker-inline"
                            inputVariant="outlined"
                            value={
                              values?.dob
                                ? typeof values?.dob === "string"
                                  ? parseISO(values?.dob)
                                  : values?.dob
                                : null
                            }
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("dob", e);
                              setformvalues((prevFormValues) => ({
                                ...prevFormValues,
                                dob: e,
                              }));
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            // style={{ marginRight: 5 }}
                            className="details-search-picker"
                          />
                        </LocalizationProvider>
                        <span className="text-danger">
                          {touched?.dob && errors?.dob ? errors?.dob : ""}
                        </span>
                      </Box>

                      <Box className="textfield-sec  select-box-wrap">
                        <Box className="mobile-number-wrap">
                          <Select
                            className="select phone-code"
                            classNamePrefix="select"
                            onMenuScrollToBottom={(e) =>
                              handleOnScrollBottomCountryPhone(e)
                            }
                            placeholder={"Code"}
                            onInputChange={(e) =>
                              handleCountryPhoneInputChange(0, e)
                            }
                            value={
                              isCountryPhoneSearch
                                ? searchCountryPhone?.find((item) => {
                                    return item?.value == countryPhone;
                                  })
                                : countryPhone?.find((item) => {
                                    const countryValue =
                                      formvalues?.countryPhone &&
                                      formvalues?.countryPhone !== ""
                                        ? formvalues?.countryPhone
                                        : values?.countryPhone;
                                    return item?.value == countryValue;
                                  })
                            }
                            onChange={(e) => {
                              setFieldValue("countryPhone", e?.value);
                              setisCountryPhoneSelectOpen(false);
                              // setstateValue("");
                              setformvalues((prevFormValues) => ({
                                ...prevFormValues,
                                countryPhone: e?.value,
                              }));
                            }}
                            // isSearchable={false}
                            onFocus={() => setisCountryPhoneSelectOpen(true)}
                            onBlur={() => setisCountryPhoneSelectOpen(false)}
                            options={
                              isCountryPhoneSearch
                                ? searchCountryPhone
                                : countryPhone
                            }
                            getOptionLabel={formatOptionLabel}
                          />

                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            className="details-textfield mobile-textfield"
                            type="number"
                            label="Mobile number"
                            name="phonenumber"
                            onBlur={handleBlur}
                            error={Boolean(
                              touched?.phonenumber && errors?.phonenumber,
                            )}
                            helperText={
                              touched?.phonenumber ? errors?.phonenumber : ""
                            }
                            onChange={handleChange}
                            value={values?.phonenumber}
                          />
                        </Box>
                        <span className="text-danger">
                          {errors?.phonenumber || formvalues?.countryPhone
                            ? ""
                            : errors?.countryPhone
                              ? errors?.countryPhone
                              : ""}
                        </span>
                      </Box>
                    </Box>
                    <Box className="personal-info grid-2">
                      <Box className="select-box-wrap ">
                        <Select
                          className="select"
                          classNamePrefix="select"
                          onMenuScrollToBottom={(e) =>
                            handleOnScrollBottomCountry(e)
                          }
                          onInputChange={(e) => handleCountryInputChange(0, e)}
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          placeholder="Country"
                          styles={{
                            container: (provided, state) => ({
                              ...provided,
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              overflow: "visible",
                            }),
                            placeholder: (provided, state) => ({
                              ...provided,
                              position: "absolute",
                              top:
                                state.hasValue ||
                                state.selectProps.inputValue ||
                                state.selectProps.isFocused
                                  ? -11
                                  : "auto",
                              backgroundColor:
                                state.hasValue ||
                                state.selectProps.inputValue ||
                                state.selectProps.isFocused
                                  ? "white"
                                  : "transparent",
                              transition: "top 2s, font-size 0.1s !important",
                              fontSize:
                                (state.hasValue ||
                                  state.selectProps.inputValue ||
                                  state.selectProps.isFocused) &&
                                "12px !important",
                              color:
                                state.hasValue ||
                                state.selectProps.inputValue ||
                                state.selectProps.isFocused
                                  ? "#4455c7"
                                  : "#a4a4a4",
                              padding:
                                (state.hasValue ||
                                  state.selectProps.inputValue ||
                                  state.selectProps.isFocused) &&
                                "0px 3px",
                              paddingLeft:
                                (state.hasValue ||
                                  state.selectProps.inputValue ||
                                  state.selectProps.isFocused) &&
                                "1px !important",
                              marginLeft:
                                (state.hasValue ||
                                  state.selectProps.inputValue ||
                                  state.selectProps.isFocused) &&
                                "7px !important",
                              lineHeight:
                                (state.hasValue ||
                                  state.selectProps.inputValue ||
                                  state.selectProps.isFocused) &&
                                "8px !important",
                            }),
                          }}
                          value={
                            isCountrySearch
                              ? searchCountry?.find((item) => {
                                  return item?.value == country;
                                })
                              : country?.find((item) => {
                                  const countryValue = values?.country;
                                  return item?.value == countryValue;
                                })
                          }
                          onChange={(e) => {
                            setFieldValue("country", e?.value);
                            fetchState(e?.value, 0, true, 0);
                            setCountryId(e?.value);
                            setFieldValue("state", "");
                            setState([]);
                            setStateApiCount(0);
                            setpageState(0);
                            setisCountrySelectOpen(false);
                            // setstateValue("");
                            setformvalues((prevFormValues) => ({
                              ...prevFormValues,
                              country: e?.value,
                            }));
                          }}
                          isFocused={isCountrySelectOpen}
                          onFocus={() => setisCountrySelectOpen(true)}
                          onBlur={() => setisCountrySelectOpen(false)}
                          options={isCountrySearch ? searchCountry : country}
                        />

                        <span className="text-danger">
                          {touched?.country && errors?.country
                            ? errors?.country
                            : ""}
                        </span>
                      </Box>
                      <Box className="select-box-wrap ">
                        <Select
                          className="select"
                          classNamePrefix="select"
                          onMenuScrollToBottom={(e) =>
                            handleOnScrollBottomState(e)
                          }
                          onInputChange={(e) => handleStateInputChange(0, e)}
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          placeholder="State"
                          styles={{
                            container: (provided, state) => ({
                              ...provided,
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              overflow: "visible",
                            }),
                            placeholder: (provided, state) => ({
                              ...provided,
                              position: "absolute",
                              top:
                                state.hasValue ||
                                state.selectProps.inputValue ||
                                state.selectProps.isFocused
                                  ? -11
                                  : "auto",
                              backgroundColor:
                                state.hasValue ||
                                state.selectProps.inputValue ||
                                state.selectProps.isFocused
                                  ? "white"
                                  : "transparent",
                              transition: "top 2s, font-size 0.1s !important",
                              fontSize:
                                (state.hasValue ||
                                  state.selectProps.inputValue ||
                                  state.selectProps.isFocused) &&
                                "12px !important",
                              color:
                                state.hasValue ||
                                state.selectProps.inputValue ||
                                state.selectProps.isFocused
                                  ? "#4455c7"
                                  : "#a4a4a4",
                              padding:
                                (state.hasValue ||
                                  state.selectProps.inputValue ||
                                  state.selectProps.isFocused) &&
                                "0px 3px",
                              paddingLeft:
                                (state.hasValue ||
                                  state.selectProps.inputValue ||
                                  state.selectProps.isFocused) &&
                                "1px !important",
                              marginLeft:
                                (state.hasValue ||
                                  state.selectProps.inputValue ||
                                  state.selectProps.isFocused) &&
                                "7px !important",
                              lineHeight:
                                (state.hasValue ||
                                  state.selectProps.inputValue ||
                                  state.selectProps.isFocused) &&
                                "8px !important",
                            }),
                          }}
                          value={
                            isStateSearch
                              ? searchState?.find((item) => {
                                  return item?.value == state;
                                })
                              : values?.state !== "" &&
                                state?.find((item) => {
                                  const stateValue = values?.state;
                                  return item?.value == stateValue;
                                })
                          }
                          onChange={(e) => {
                            setFieldValue("state", e?.value);
                            setisStateSelectOpen(false);
                            setStateApiCount(0);
                            // setstateValue(e?.value);
                            setformvalues((prevFormValues) => ({
                              ...prevFormValues,
                              state: e?.value,
                            }));
                          }}
                          isFocused={isStateSelectOpen}
                          onFocus={() => setisStateSelectOpen(true)}
                          onBlur={() => setisStateSelectOpen(false)}
                          options={isStateSearch ? searchState : state}
                        />
                        <span className="text-danger">
                          {touched?.state && errors?.state ? errors?.state : ""}
                        </span>
                      </Box>
                    </Box>
                    <Box className="personal-info">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="details-textfield"
                        label="Create password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        InputProps={{
                          endAdornment: !showPassword ? (
                            <UnVisiblePassword
                              onClick={() => setshowPassword(!showPassword)}
                            />
                          ) : (
                            <VisiblePassword
                              onClick={() => setshowPassword(!showPassword)}
                            />
                          ),
                          autoComplete: "new-password",
                        }}
                        error={Boolean(touched?.password && errors?.password)}
                        helperText={touched?.password ? errors?.password : ""}
                        onChange={handleChange}
                        value={values?.password}
                      />
                    </Box>
                  </>
                )}
                {isLogin && (
                  <Box className="personal-info ">
                    <Box className=" grid-2 mobile-number-wrap select-box-wrap">
                      <Select
                        className="select phone-code"
                        classNamePrefix="select"
                        onMenuScrollToBottom={(e) =>
                          handleOnScrollBottomCountryPhone(e)
                        }
                        placeholder={"Code"}
                        onInputChange={(e) =>
                          handleCountryPhoneInputChange(0, e)
                        }
                        value={
                          isCountryPhoneSearch
                            ? searchCountryPhone?.find((item) => {
                                return item?.value == countryPhone;
                              })
                            : countryPhone?.find((item) => {
                                const countryValue =
                                  formvalues?.countryPhone &&
                                  formvalues?.countryPhone !== ""
                                    ? formvalues?.countryPhone
                                    : values?.countryPhone;
                                return item?.value == countryValue;
                              })
                        }
                        onChange={(e) => {
                          setFieldValue("countryPhone", e?.value);
                          setisCountryPhoneSelectOpen(false);
                          // setstateValue("");
                          setformvalues((prevFormValues) => ({
                            ...prevFormValues,
                            countryPhone: e?.value,
                          }));
                        }}
                        // isSearchable={false}
                        onFocus={() => setisCountryPhoneSelectOpen(true)}
                        onBlur={() => setisCountryPhoneSelectOpen(false)}
                        options={
                          isCountryPhoneSearch
                            ? searchCountryPhone
                            : countryPhone
                        }
                        getOptionLabel={formatOptionLabel}
                      />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="details-textfield  mobile-textfield"
                        type="number"
                        label="Mobile number"
                        name="phonenumber"
                        onBlur={handleBlur}
                        error={Boolean(
                          touched?.phonenumber && errors?.phonenumber,
                        )}
                        helperText={
                          touched?.phonenumber ? errors?.phonenumber : ""
                        }
                        onChange={handleChange}
                        value={values?.phonenumber}
                      />
                    </Box>
                    <span className="text-danger">
                      {touched?.countryPhone && errors?.countryPhone
                        ? errors?.countryPhone
                        : ""}
                    </span>
                  </Box>
                )}
                {!isLogin && (
                  <>
                    <Box className="checkBox-wrap">
                      <FormControlLabel
                        className="documentsRead-check"
                        control={
                          <Checkbox
                            className="documentsRead-checkbox"
                            checked={values?.documentsRead}
                            onChange={() =>
                              setFieldValue(
                                "documentsRead",
                                !values?.documentsRead,
                              )
                            }
                            color="primary"
                          />
                        }
                        error={touched?.documentsRead && errors?.documentsRead}
                      />
                      <Typography className="policy-text">
                        I have read and agree to the{" "}
                        <span
                        // onClick={() => navigate("")}
                        >
                          <NavLink to="/privacy-policy" target="_blank">
                            Privacy Policy
                          </NavLink>
                        </span>{" "}
                        &{" "}
                        <span
                        // onClick={() => navigate("/terms-and-conditions")}
                        >
                          <NavLink to="/terms-and-conditions" target="_blank">
                            Terms and Conditions
                          </NavLink>
                        </span>
                      </Typography>
                    </Box>
                    {touched?.documentsRead && errors?.documentsRead && (
                      <Typography variant="body2" color="error">
                        {errors?.documentsRead}
                      </Typography>
                    )}
                  </>
                )}
                <Box className="checkBox-wrap">
                  <FormControlLabel
                    className="documentsRead-check"
                    control={
                      <Checkbox
                        className="documentsRead-checkbox"
                        checked={values?.documentsReadSOC}
                        onChange={() =>
                          setFieldValue(
                            "documentsReadSOC",
                            !values?.documentsReadSOC,
                          )
                        }
                        color="primary"
                      />
                    }
                    error={
                      touched?.documentsReadSOC && errors?.documentsReadSOC
                    }
                  />
                  <Typography className="policy-text">
                    I have read{" "}
                    <span
                    // onClick={() => navigate("")}
                    >
                      <NavLink
                        to="/subscription-terms-and-conditions"
                        target="_blank"
                      >
                        Subscription Terms and Conditions
                      </NavLink>
                    </span>{" "}
                    for SmartB Smart Odds Comparison (SOC) memberships
                  </Typography>
                </Box>
                {touched?.documentsReadSOC && errors?.documentsReadSOC && (
                  <Typography variant="body2" color="error">
                    {errors?.documentsReadSOC}
                  </Typography>
                )}
              </Box>
              {setformvalues(values)}
            </form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default MemberPersonalInfo;
