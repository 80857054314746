import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Grid,
  // TextField,
  SwipeableDrawer
} from "@mui/material";
// import { IntlContext } from "src/App";
import CloseIcon from "@mui/icons-material/Close";
import HeaderTopBar from "../MainLayout/headertopbar/headertopbar";
import LeftSideBar from "../../views/component/leftsidebar/leftsidebar.jsx";
import banner from "../../assets/images/banner/smart-b-banner-primary.webp";
import AdvertisingBannner from "../../views/component/advertisingBanner";
// import { ReactComponent as Search } from "../../assets/images/icons/search.svg";
import { ReactComponent as FaBars } from "../../assets/images/icons/menu.svg";
import logo from "../../assets/images/logo.svg";
// import InputAdornment from "@mui/material/InputAdornment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import HeaderTopBarV2 from "../MainLayout/headertopbar/headertopbarV2";

const TopBar = ({
  auth,
  user_statics,
  verify_count,
  className,
  onMobileNavOpen,
  ...rest
}) => {
  // const intlContext = useContext(IntlContext);
  // const localesData = intlContext?.messages;
  const [showMenu, setshowMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const toggleMenu = () => {
    setshowMenu(!showMenu);
  };
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  return (
    <>
      {/* {screenWidth < 600 ? (
        <Box className="Main-Wrap auth-main-wrap">
          <HeaderTopBarV2 />
          <AppBar className="main-menu-wrap"> */}
      {/* <Toolbar>
        <NavLink to="/home" className="logo-anchor">
          <Logo />
        </NavLink>
        <Box onClick={returnWelcomePage}>
          <Typography variant="h5" className="topbar-return-text">
            {localesData?.register?.return}
          </Typography>
        </Box>
        <Box />
        {location.pathname !== "/terms-conditions" ? (
          <>
            <Hidden lgUp>
              <IconButton
                color="inherit"
                onClick={onMobileNavOpen}
                style={{ padding: "0px" }}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          </>
        ) : (
          ""
        )}
      </Toolbar> */}
      {/* <div className="mobileMenu">
              <SwipeableDrawer
                anchor={"left"}
                open={showMenu}
                onClose={toggleMenu}
                onOpen={toggleMenu}
                className="mobileMenuDrawer"
                swipeAreaWidth={0}
              >
                <div className="mobileMenuDrawerClose">
                  <CloseIcon onClick={toggleMenu} />
                </div>
                <LeftSideBar />
              </SwipeableDrawer>
            </div>
            <Grid
              container
              spacing={0}
              alignItems="center"
              className="header-wrap"
            >
              <Grid item xs={1} sm={1} className="mobileMenu">
                <FaBars onClick={toggleMenu} className="MenuTopBar-button" />
              </Grid>
              <Grid
                item
                md={2}
                sm={2}
                xs={5}
                className="text-left logo-container"
              >
                <NavLink to="/">
                  <img
                    src={logo}
                    style={{ maxWidth: " 240.56px" }}
                    className="App-logo-main"
                    alt="logo"
                  />
                </NavLink>
              </Grid>
              <Grid
                item
                md={6}
                sm={3}
                xs={6}
                className="text-right search-wrap"
                style={{ justifyContent: "end" }}
              > */}
      {/* <TextField
                  placeholder={localesData?.search_placeholder}
                  variant="outlined"
                  type="text"
                  name="search"
                  className="search_input hide-sx"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                /> */}
      {/* <Box
                  sx={{ display: { xs: "block", sm: "none" } }}
                  className="search-box"
                > */}
      {/* <TextField
                    placeholder={localesData?.search_placeholder}
                    variant="outlined"
                    type="text"
                    name="search"
                    className="search_input search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      )
                    }}
                  /> */}
      {/* <i>
                <Search />
              </i> */}
      {/* </Box>
              </Grid>
            </Grid>
            <AdvertisingBannner src={banner} />
          </AppBar>
        </Box>
      ) : (
        <></>
      )} */}
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
