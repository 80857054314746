import React from "react";
import Page from "src/components/Page";
import BlackbookPage from "../../component/Blackbook";

const Blackbook = () => {
  return (
    <Page title="Blackbook">
      <BlackbookPage />
    </Page>
  );
};

export default Blackbook;
