import React, { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  TextField,
  Typography
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import banner from "../../../assets/images/banner/smart-b-banner-primary.png";
import AdvertisingBannner from "../advertisingBanner";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import { Config } from "src/helpers/context";
import OurPartner from "../ourPartners";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DefaultImg from "../../../assets/images/smartb_default.png";
import AdBannner from "../AdBanner/AdBanner";
import Pagination from '@mui/material/Pagination';
import NoDataComp from "../UI/NoData";
import "./recommendedWebsites.scss";

const RecommendedWebsitesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [websiteData, setWebsiteData] = useState([]);
  const [websiteCount, setWebsiteCount] = useState(0);
  const [websiteoffset, setWebSiteOffset] = useState(0);
  const [websitePage, setWebsitePage] = useState(1);
  const [showFullText, setShowFullText] = useState(false);
  const [clickReadMoreId, setClickReadMoreId] = useState(null);
  const rowPerPage = 10;

  const pageNumbers = [];

  if (websiteCount > 0) {
    for (let i = 1; i <= Math.ceil(websiteCount / rowPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  useEffect(() => {
    fetchAllWebsite(0);
  }, []);

  const fetchAllWebsite = async page => {
    setWebSiteOffset(page);
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        `websites?limit=${rowPerPage}&offset=${page}`
      );

      if (status === 200) {
        const count = data?.count;
        setWebsiteCount(count);

        setWebsiteData(data?.result);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  };

  const handlePaginationClick = (event, page) => {
    setWebsitePage(Number(page));
    fetchAllWebsite((Number(page) - 1) * rowPerPage);
  };

  const toggleReadMore = () => {
    setShowFullText(!showFullText);
  };

  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    // try {
    //   const { status, data } = await axiosInstance.get(
    //     `getAllAds?page_id=1,11`
    //   );
    //   if (status === 200) {
    let AdvtData = ReduxAdsData?.filter(item => item?.page_id === 1);
    setAdData(AdvtData);
    // dispatch(fetchAdvtData(AdvtData?.[0]));
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const ReadMore = ({ item, index, title, shortDes, description, image }) => {
    const toggleReadMore = id => {
      clickReadMoreId !== id ? setClickReadMoreId(id) : setClickReadMoreId("");
    };

    const learnMore = url => {
      window.open(url, "_blank");
    };
    // showFullText === true &&
    return (
      <Box className="website-content-wrap">
        <Box className="img-sec">
          <img
            src={
              image?.includes("uploads") ? Config.mediaURL + image : DefaultImg
            }
            alt="website-img"
            className="left-img"
          />
        </Box>
        <Box className="txt-sec">
          <Typography className="title" onClick={() => learnMore(item?.url)}>
            {title}
          </Typography>
          <Box className="des-box">
            <Typography className="description">
              {/* <Typography className="description">{shortDes}</Typography> */}
              <Typography
                className="description"
                dangerouslySetInnerHTML={{
                  __html: shortDes
                }}
              ></Typography>
            </Typography>

            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              className="read-more-button"
              onClick={() => toggleReadMore(item?.id)}
              endIcon={
                clickReadMoreId === item?.id ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )
              }
            >
              {clickReadMoreId === item?.id ? "Read Less" : "Read More"}
            </Button>

            {clickReadMoreId === item?.id && (
              <>
                {/* <Typography className="description">{description}</Typography> */}
                <Typography
                  className="description suneditor-dec"
                  dangerouslySetInnerHTML={{
                    __html: description
                  }}
                ></Typography>
                <Typography
                  className="learn-more"
                  onClick={() => learnMore(item?.url)}
                >
                  Learn more
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box className="content-wrap">
        <Box className="mobile-banner">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="RW-wrap">
          <Box className="RW-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  HOME
                </Link>

                <Link underline="hover" color="inherit" to="">
                  Information
                </Link>

                <Typography>Recommended Websites</Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">Recommended Websites</Typography>
          </Box>
          <Box className="page-deatils-wrap">
            {loader ? (
              <div className="allsport-loader-center ">
                <Loader />
              </div>
            ) : websiteData && websiteData?.length > 0 ? (
              websiteData?.map((item, index) => {
                return (
                  <ReadMore
                    item={item}
                    key={item?.id}
                    index={index}
                    title={item?.title}
                    shortDes={item?.short_desc}
                    description={item?.desc}
                    image={item?.Medium?.filePath}
                  />
                );
              })
            ) : (
              <>
                <Box style={{ border: "transparent" }}>
                  <Box
                    style={{
                      textAlign: "center",
                      border: "transparent",
                      fontFamily: "sans-serif"
                    }}
                  >
                    <NoDataComp />
                  </Box>
                </Box>
              </>
            )}

            {!loader && websiteData && websiteData?.length > 0 ? (
              <Box className="pagination-section">
                <Pagination
                  disabled={websiteCount / rowPerPage > 1 ? false : true}
                  page={websitePage}
                  onChange={(e, value) => handlePaginationClick(e, value)}
                  count={pageNumbers[pageNumbers?.length - 1]}
                  siblingCount={2}
                  boundaryCount={1}
                  size="small"
                />
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <OurPartner />
        </Box>
      </Box>
    </>
  );
};

export default RecommendedWebsitesPage;
