import React from "react";
import Page from "src/components/Page";
import AdvertisingScreenPage from "src/views/component/advertisingScreenPage";

const AdvertisingScreen = () => {
  return (
    <Page title="Team Sports">
      <AdvertisingScreenPage />
    </Page>
  );
};

export default AdvertisingScreen;
