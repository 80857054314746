import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TawkToScript = () => {
  const location = useLocation();

  useEffect(() => {
    const matchRoute = "/odds-comparison";

    // Function to load the Tawk.to script if not already loaded
    const loadTawkTo = () => {
      if (!document.getElementById("tawk-script")) {
        const script = document.createElement("script");
        script.src = "https://embed.tawk.to/66b56e97146b7af4a4380580/1i4qbg0r8";
        script.async = true;
        script.charset = "UTF-8";
        script.setAttribute("crossorigin", "*");
        script.id = "tawk-script";
        document.body.appendChild(script);
      }
    };
    // Function to show or hide the widget
    const toggleWidget = () => {
      if (window.Tawk_API) {
        if (location.pathname.includes(matchRoute)) {
          window.Tawk_API.showWidget(); // Show the widget if on the matching route
        } else {
          // Hide the widget when leaving the route
          window.Tawk_API.hideWidget();

          // Close the chat if it's open
          if (window.Tawk_API.isChatMaximized()) {
            window.Tawk_API.toggle(); // Close the chat
          }
        }
      }
    };

    // Load the Tawk.to script when the component mounts
    location.pathname.includes(matchRoute) && loadTawkTo();

    // Toggle widget visibility based on the current route
    toggleWidget();

    // Cleanup on unmount or when the route changes
    return () => {
      // Optionally, you can keep the script loaded but hide the widget
      toggleWidget();
    };
  }, [location?.pathname]);

  return null; // This component does not render anything
};

export default TawkToScript;
