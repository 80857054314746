import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  IconButton,
  TextareaAutosize,
  Typography,
  Button
} from "@mui/material";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import Loader from "src/components/Loader";
import { useLocation } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import "./ui.scss";
import { Config, fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useDispatch } from "react-redux";

const CompJoinModal = ({
  handleClose,
  open,
  title,
  closeIcon,
  shareCode,
  handleJoin,
  compById,
  handleOpenConfirmModal
}) => {
  const localAuth = fetchFromStorage("auth_token");
  let isLogin = localAuth ? true : false;
  const dispatch = useDispatch();
  return (
    (<Dialog
      className="blackBook-dialog social-dialog"
      // maxWidth="md"
      onClose={handleClose}
      open={open}
      TransitionComponent={Grow}
      scroll={"paper"}
    >
      <Box className="dialog-content-box">
        {/* {closeIcon && (
          <DialogTitle>
            <Box className="custom-dialog-title">
              <Typography variant="h3" className="dialog-title">
                {title}
                {closeIcon !== false && (
                  <IconButton
                    className="dialog-close"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Typography>
            </Box>
          </DialogTitle>
        )} */}
        <Box className="blackBook-content-wrap social-padding">
          <Box className="close-btn">
            {closeIcon !== false && (
              <IconButton
                className="dialog-close"
                style={{ textAlig: "end" }}
                onClick={() => {
                  handleClose();
                }}
                size="large">
                <CloseIcon />
              </IconButton>
            )}
          </Box>

          <Typography className="send-invite-txt">
            {compById?.Tournament?.name}
          </Typography>
          <Typography className="share-txt">
            You have been invited to join a competition!
          </Typography>
          <Typography className="share-txt mt-0">
            Competition details:
          </Typography>
          <Box className="comp-modal-details">
            <Box className="user-comp-detail">
              <Typography className="left">Comp code#: </Typography>
              <Typography className="right"> {compById?.code}</Typography>
            </Box>
            <Box className="user-comp-detail">
              <Typography className="left">Sport: </Typography>
              <Typography className="right">
                {compById?.Sport?.sportName}
              </Typography>
            </Box>
            <Box className="user-comp-detail">
              <Typography className="left">League: </Typography>
              <Typography className="right">
                {compById?.Tournament?.name}
              </Typography>
            </Box>
            <Box className="user-comp-detail">
              <Typography className="left">Starting Round: </Typography>
              <Typography className="right">
                Round {compById?.startingRound}
              </Typography>
            </Box>
            <Box className="user-comp-detail">
              <Typography className="left">Privacy: </Typography>
              <Typography className="right">
                {compById?.privacy === "private" ? "Private" : "Public"}
              </Typography>
            </Box>
          </Box>

          <Box className="join-now-btn-sec">
            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              fullWidth
              className="join-now-btn"
              onClick={e => {
                // handleJoin(e, compById);
                handleClose();

                if (isLogin) {
                  handleOpenConfirmModal();
                } else {
                  dispatch(fetchRestrictedRoute(true));
                  localStorage.setItem(
                    "prev_authroute",
                    window.location.pathname + window.location.search
                  );
                }
              }}
            >
              Join now!
            </Button>
          </Box>
        </Box>
      </Box>
      {/* <Box>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Box> */}
    </Dialog>)
  );
};

export default CompJoinModal;
