import React from "react";
import Page from "src/components/Page";
import OurTeamPage from "src/views/component/ourTeam";

const OurPeople = () => {
  return (
    <Page title="Our People">
      <OurTeamPage />
    </Page>
  );
};

export default OurPeople;
