import React from "react";
import Page from "src/components/Page";
import SportMatchupsPage from "src/views/component/sportMatchupsPage";

const SportMatchups = () => {
  return (
    <Page title="Sport Matchups">
      <SportMatchupsPage />
    </Page>
  );
};

export default SportMatchups;
