import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  FormControl,
  Checkbox
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Config } from "src/helpers/context";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell
} from "recharts";
import { ReactComponent as Unchecked } from "src/assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Checked } from "src/assets/images/icons/smartb-check.svg";
import { ReactComponent as TriLeft } from "src/assets/images/icons/tri-left.svg";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import "./tipping.scss";
import TipperChatModal from "../../UI/tipperChatModal";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import NoDataComp from "../../UI/NoData";

const data = [
  { name: "Jan", MV: 50 },
  { name: "Feb", MV: 300 },
  { name: "Mar", MV: 100 },
  { name: "Apr", MV: 250 },
  { name: "May", MV: 500 },
  { name: "Jun", MV: 700 }
];

const tipData = [
  {
    id: 1,
    day: "Today",
    tips: 21,
    comment:
      "Comments - Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id finibus."
  },
  {
    id: 2,
    day: "Today",
    tips: 21,
    comment:
      "Comments - Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id finibus."
  },
  {
    id: 3,
    day: "Today",
    tips: 21,
    comment:
      "Comments - Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id finibus."
  }
];
const tableData = [
  {
    id: 1,
    date: "13 Jul, 2022",
    event: "R4 Mornington",
    Runner: "16 Ellenmac",
    result: "5th",
    win: "$4.40",
    place: "$1.80"
  },
  {
    id: 2,
    date: "13 Jul, 2022",
    event: "R4 Mornington",
    Runner: "16 Ellenmac",
    result: "5th",
    win: "$4.40",
    place: "$1.80"
  },
  {
    id: 3,
    date: "13 Jul, 2022",
    event: "R4 Mornington",
    Runner: "16 Ellenmac",
    result: "5th",
    win: "$4.40",
    place: "$1.80"
  }
];
const collapseData = [
  {
    id: 0,
    date: "2023-12-27T07:08:00.000Z",
    event: "R4 Mornington",
    runner: "16 Ellenmac",
    result: "5th",
    track: "R4 Mornington",
    jockey: "Logan Bates",
    trainer: "Chloe Royle",
    win: "$4.40",
    place: "$1.80",
    invoice: "Invoice",
    cost: "Cost",
    tournament: "NFL Divisions",
    team: "Brisbane Lions",
    event: "Brisbane Lions VS Melbourne"
  },
  {
    id: 1,
    date: "2023-12-27T07:08:00.000Z",
    event: "R4 Mornington",
    runner: "16 Ellenmac",
    result: "5th",
    track: "R4 Mornington",
    jockey: "Logan Bates",
    trainer: "Carlos Vinicius",
    win: "$4.40",
    place: "$1.80",
    invoice: "Invoice",
    cost: "Cost",
    tournament: "AFL",
    team: "Melbourne Demons",
    event: "Sydney Swans VS Melbourne Demons"
  },
  {
    id: 2,
    date: "2023-12-27T07:08:00.000Z",
    event: "R4 Mornington",
    runner: "16 Ellenmac",
    result: "5th",
    track: "R4 Mornington",
    jockey: "Logan Bates",
    trainer: "Sasa Kalajdzic",
    win: "$4.40",
    place: "$1.80",
    invoice: "Invoice",
    cost: "Cost",
    tournament: "NFL Divisions",
    team: "GWS Giants",
    event: "GWS Giants VS Fermantle"
  }
];

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function TippingTab({ typevalue, raceTipData, sportTipData, params }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [filterRaceOptions, setFilterRaceOptions] = useState([
    {
      id: 1,
      option: "All tips"
    },
    {
      id: 2,
      option: "Winning Tips"
    },
    {
      id: 3,
      option: "Placed Tips"
    }
  ]);

  const [filterSportsValue, setFilterSportsValue] = useState(1);
  const [filterRaceValue, setFilterRaceValue] = useState(1);
  const [openChatModal, setOpenChatModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [raceResultData, setRaceResultData] = useState([]);
  const [sportsResultData, setSportsResultData] = useState([]);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  useEffect(() => {
    if (typevalue === 0) {
      getTipperSportsResultData();
    } else {
      getTipperRaceResultData("");
    }
  }, [typevalue]);

  const handleClose = () => {
    setOpenChatModal(false);
  };

  const data1 = [
    { name: "Failure", value: 20 },
    { name: "Success", value: 80 }
  ];

  const colors1 = ["#D6D9F3", "#78C2A7"]; // Gray for failure, green for success

  const data2 = [
    { name: "Failure", value: 30 },
    { name: "Success", value: 70 }
  ];

  const colors2 = ["#D6D9F3", "#78C2A7"];

  const formatYAxisValue = value => {
    if (value === 0) return "0";
    if (value < 1000) return value;
    if (value > 1000) return `${value / 1000}k`;
    return value;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box className="custom-tooltip">
          <p>{`Month: ${label}`}</p>
          <p>{`Value: ${payload[0].value}`}</p>
        </Box>
      );
    }

    return null;
  };

  const getTipperRaceResultData = async winType => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/race/tip/result?timezone=${timezone}&UserId=${Number(
          params?.id
        )}&startDate=${moment()
          .tz(timezone)
          .format("YYYY-MM-DD")}&marketType=${winType}`
      );
      if (status === 200) {
        setLoading(false);
        setRaceResultData(data?.result);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };
  const getTipperSportsResultData = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/sport/tip/result?timezone=${timezone}&UserId=${Number(
          params?.id
        )}&startDate=${moment()
          .tz(timezone)
          .format("YYYY-MM-DD")}`
      );
      if (status === 200) {
        setLoading(false);
        setSportsResultData(data?.result);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  const getKeysforTips = key => {
    if (key === "today") {
      return "Today";
    } else if (key === "yesterday") {
      return "Yesterday";
    } else {
      return "The Day Before";
    }
  };

  return (
    <>
      <Box className="tipper-performance-sec">
        <Box className="left-sec">
          <Box className="graph">
            <LineChart
              width={
                screenWidth > 1280
                  ? 464
                  : screenWidth > 1180
                  ? 360
                  : screenWidth > 1023
                  ? 300
                  : screenWidth > 590
                  ? 465
                  : 360
              }
              height={130}
              data={data}
            >
              <XAxis tick={null} />
              <YAxis tickFormatter={formatYAxisValue} tickCount={4} />
              <Line type="monotone" dataKey="MV" stroke="#8884d8" />
              <Tooltip content={<CustomTooltip />} />
            </LineChart>
          </Box>
          <Box className="profit-wrap">
            <Box className="profit-section">
              <Typography className="profit-text">POT:</Typography>
              <Typography className="profit-text profit-text-bold">
                3%
              </Typography>
            </Box>
            <Box className="profit-section">
              <Typography className="profit-text">Strike:</Typography>
              <Typography className="profit-text profit-text-bold">
                22%
              </Typography>
            </Box>
            <Box className="profit-section">
              <Typography className="profit-text">Invested:</Typography>
              <Typography className="profit-text profit-text-bold">
                $783,123
              </Typography>
            </Box>
            <Box className="profit-section">
              <Typography className="profit-text">Profit:</Typography>
              <Typography className="profit-text profit-text-bold">
                $21,100
              </Typography>
            </Box>
          </Box>
          <Box className="profit-wrap">
            <Box className="profit-section">
              <Typography className="profit-text">Tips:</Typography>
              <Typography className="profit-text profit-text-bold">
                311
              </Typography>
            </Box>
            <Box className="profit-section">
              <Typography className="profit-text">Avg Win:</Typography>
              <Typography className="profit-text profit-text-bold">
                $7.61
              </Typography>
            </Box>
            <Box className="profit-section">
              <Typography className="profit-text">Avg Price:</Typography>
              <Typography className="profit-text profit-text-bold">
                $2.34
              </Typography>
            </Box>
          </Box>
          {screenWidth < 1110 && <Box className="devider"></Box>}

          <Box className="dual-pie-charts">
            <Box className="pie-chart-container">
              <PieChart
                width={screenWidth > 1110 ? 200 : 150}
                height={screenWidth > 1110 ? 200 : 150}
              >
                <Pie
                  data={data1}
                  // cx={200}
                  // cy={200}
                  cx="50%"
                  cy="50%"
                  outerRadius={screenWidth > 1110 ? 80 : 60}
                  innerRadius={screenWidth > 1110 ? 50 : 35}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data1.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors1[index]} />
                  ))}
                </Pie>
              </PieChart>
              <Box>
                <Typography className="prof-details">
                  Profit: $21,100
                </Typography>
                <Typography className="prof-details">
                  Investment: $783,123
                </Typography>
              </Box>
            </Box>

            <Box className="pie-chart-container">
              <PieChart
                width={screenWidth > 1110 ? 200 : 150}
                height={screenWidth > 1110 ? 200 : 150}
              >
                <Pie
                  data={data2}
                  // cx={200}
                  // cy={200}
                  cx="50%"
                  cy="50%"
                  outerRadius={screenWidth > 1110 ? 80 : 60}
                  innerRadius={screenWidth > 1110 ? 50 : 35}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data2.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors2[index]} />
                  ))}
                </Pie>
              </PieChart>
              <Box>
                <Typography className="prof-details">
                  Winning Days: 24
                </Typography>
                <Typography className="prof-details opactity">
                  Losing Days: 35
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="right-sec">
          {typevalue === 0 ? (
            <>
              {Object.keys(sportTipData).map(key => (
                <Box className="right-content" key={key}>
                  <Typography className="tips-detail">
                    {getKeysforTips(key)}: {sportTipData[key].tip} Tips
                  </Typography>
                  <Typography className="comment">
                    {sportTipData[key].comment
                      ? sportTipData[key].comment
                      : "No Comments"}
                  </Typography>

                  <Button className="buy-btn">Buy $10</Button>
                </Box>
              ))}
            </>
          ) : (
            <>
              {Object.keys(raceTipData).map(key => (
                <Box className="right-content" key={key}>
                  <Typography className="tips-detail">
                    {getKeysforTips(key)}: {raceTipData[key].tip} Tips
                  </Typography>
                  <Typography className="comment">
                    {raceTipData[key].comment
                      ? raceTipData[key].comment
                      : "No Comments"}
                  </Typography>

                  <Button className="buy-btn">Buy $10</Button>
                </Box>
              ))}
            </>
          )}
        </Box>
      </Box>
      {loading ? (
        <div className="allsport-loader-center app-loader">
          <Loader />
        </div>
      ) : (
        <Box className="performance-table-sec mt-18">
          <Box className="performance-table">
            <Typography className="title">Tipping Results</Typography>
            <ul className="Filteritemlist-racing">
              {typevalue === 1 &&
                filterRaceOptions?.length > 0 &&
                filterRaceOptions?.map((filter, i) => (
                  <li key={i}>
                    <FormControl>
                      <label>
                        <Checkbox
                          className="filter-racing"
                          icon={<Unchecked />}
                          checkedIcon={<Checked />}
                          name="filter"
                          value={filter?.id}
                          onChange={event => {
                            // ChangeRaceFilter(event, filter?.id);
                            setFilterRaceValue(filter?.id);
                            getTipperRaceResultData(
                              filter?.id === 1
                                ? ""
                                : filter?.id === 2
                                ? "win"
                                : "place"
                            );
                          }}
                          checked={filter?.id === filterRaceValue}
                        />
                        {filter?.option}
                      </label>
                    </FormControl>
                  </li>
                ))}
            </ul>
          </Box>
          <Box className="devider"></Box>

          <Box className="table-content-sec">
            {typevalue === 0 ? (
              <>
                {sportsResultData && sportsResultData?.length > 0 ? (
                  screenWidth > 1130 ? (
                    <>
                      <Table
                        className="inner-table-content blue-border"
                        // style={{ width: "90%" }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className="table-title"
                              style={{ width: "20%" }}
                            >
                              Date/Time
                            </TableCell>

                            <TableCell className="table-title">
                              Tournament
                            </TableCell>
                            <TableCell
                              className="table-title"
                              style={{ width: "25%" }}
                            >
                              Team
                            </TableCell>
                            <TableCell
                              className="table-title"
                              style={{ width: "20%" }}
                            >
                              Event
                            </TableCell>
                            <TableCell
                              className="table-title"
                              style={{ width: "20%", textAlign: "center" }}
                            >
                              Result
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sportsResultData?.map((item, index) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell className="table-detail">
                                    <Box className="time-count-txt">
                                      <Box>
                                        <span>
                                          {item?.startTime
                                            ? moment
                                                .utc(item?.startTime)
                                                .local()
                                                .format("DD/MM/YYYY")
                                            : ""}{" "}
                                        </span>
                                        |{" "}
                                        <span>
                                          {item?.startTime
                                            ? moment
                                                .utc(item?.startTime)
                                                .local()
                                                .format("hh:mm A")
                                            : ""}{" "}
                                        </span>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell className="table-detail">
                                    <Box className="chip-sec">
                                      <Typography className="chip-txt">
                                        {item?.tournamentName}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell className="table-detail">
                                    {item?.teamName}
                                  </TableCell>
                                  <TableCell className="table-detail">
                                    {item?.eventName}
                                  </TableCell>
                                  <TableCell className="table-detail text-center">
                                    {/* <Box className="chip-sec"> */}
                                    {item?.resultDisplay ? (
                                      <>
                                        <Typography>
                                          4{" "}
                                          <span>
                                            <TriLeft />
                                          </span>
                                        </Typography>
                                        <Typography>
                                          3{" "}
                                          <span>
                                            <TriLeft />
                                          </span>
                                        </Typography>
                                      </>
                                    ) : (
                                      <>-</>
                                    )}

                                    {/* </Box> */}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </>
                  ) : (
                    <>
                      {sportsResultData?.map((item, index) => {
                        return (
                          <Box className="accordion-details">
                            <Box className="first-sec" style={{ width: "40%" }}>
                              <Typography className="detail gray-txt">
                                Team
                              </Typography>

                              <Typography className="detail team-txt">
                                {item?.teamName}
                              </Typography>
                              <Box className="chip-sec">
                                <Typography className="chip-txt">
                                  {item?.tournamentName}
                                </Typography>
                              </Box>
                            </Box>

                            <Box
                              className="first-sec"
                              style={
                                screenWidth > 460
                                  ? { width: "35%" }
                                  : {
                                      width: "40%"
                                    }
                              }
                            >
                              <>
                                <Typography className="detail gray-txt">
                                  Event /{" "}
                                  {item?.startTime
                                    ? moment
                                        .utc(item?.startTime)
                                        .local()
                                        .format("DD/MM/YYYY")
                                    : ""}
                                </Typography>
                                <Box>
                                  <Typography className="detail fw-500 mw-150">
                                    {item?.eventName}
                                  </Typography>
                                  {/* <Box>
                            <Typography className="detail basic-flex">
                              4{" "}
                              <span>
                                <TriLeft />
                              </span>
                            </Typography>
                            <Typography className="detail basic-flex">
                              3{" "}
                              <span>
                                <TriLeft />
                              </span>
                            </Typography>
                          </Box> */}
                                </Box>
                              </>
                            </Box>
                            <Box
                              className="first-sec"
                              // style={
                              //   screenWidth > 460
                              //     ? { width: "30%" }
                              //     : {
                              //         width: "35%"
                              //       }
                              // }
                            >
                              <>
                                <Typography className="detail gray-txt">
                                  Result
                                </Typography>
                                {/* <Box className="basic-flex"> */}
                                {item?.resultDisplay ? (
                                  <Box>
                                    <Typography className="detail  basic-flex">
                                      4{" "}
                                      <span>
                                        <TriLeft className="red-tri" />
                                      </span>
                                    </Typography>
                                    <Typography className="detail  basic-flex">
                                      3{" "}
                                      <span>
                                        <TriLeft className="red-tri" />
                                      </span>
                                    </Typography>
                                  </Box>
                                ) : (
                                  <>-</>
                                )}

                                {/* </Box> */}
                              </>
                            </Box>
                          </Box>
                        );
                      })}
                    </>
                  )
                ) : (
                  <>
                    <Box style={{ border: "transparent" }}>
                      <Box
                        style={{
                          textAlign: "center",
                          border: "transparent",
                          fontFamily: "sans-serif"
                        }}
                      >
                        {/* No Data Available */}
                        <NoDataComp />
                      </Box>
                    </Box>
                  </>
                )}
              </>
            ) : (
              <>
                {raceResultData && raceResultData?.length > 0 ? (
                  screenWidth > 1130 ? (
                    <Table className="table-content">
                      <TableHead>
                        <TableRow>
                          <TableCell className="table-title">Date</TableCell>
                          <TableCell
                            className="table-title"
                            style={{ width: "32%" }}
                          >
                            Race
                          </TableCell>
                          <TableCell
                            className="table-title"
                            style={{ width: "25%" }}
                          >
                            Runner
                          </TableCell>
                          <TableCell className="table-title">Result</TableCell>
                          <TableCell className="table-title">Win</TableCell>
                          <TableCell className="table-title">Place</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {raceResultData?.map(item => {
                          return (
                            <TableRow>
                              <TableCell className="table-detail">
                                {moment
                                  .utc(item?.startDate)
                                  .local()
                                  .format("DD MMM YYYY")}
                              </TableCell>
                              <TableCell className="table-detail">
                                {item?.raceName}
                              </TableCell>
                              <TableCell className="table-detail">
                                {item?.runnerName}
                              </TableCell>
                              <TableCell className="table-detail">
                                {item?.resultDisplay}
                              </TableCell>
                              <TableCell className="table-detail">
                                {item?.win}
                              </TableCell>
                              <TableCell className="table-detail">
                                {item?.place}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  ) : (
                    <>
                      {raceResultData?.map(item => {
                        return (
                          <Box className="event-details">
                            <Box className="title">
                              <Typography className="title-txt">
                                {item?.raceName}
                              </Typography>
                              <Typography className="date-txt">
                                {moment
                                  .utc(item?.startDate)
                                  .local()
                                  .format("DD MMM YYYY")}
                              </Typography>
                            </Box>
                            <Box className="profit-wrap">
                              <Box className="profit-section">
                                <Typography className="profit-text">
                                  Runner:
                                </Typography>
                                <Typography className="profit-text profit-text-bold">
                                  {item?.runnerName}
                                </Typography>
                              </Box>
                              <Box className="profit-section">
                                <Typography className="profit-text">
                                  Result:
                                </Typography>
                                <Typography className="profit-text profit-text-bold">
                                  22%
                                </Typography>
                              </Box>
                              <Box className="profit-section">
                                <Typography className="profit-text">
                                  Place:
                                </Typography>
                                <Typography className="profit-text profit-text-bold">
                                  $783,123
                                </Typography>
                              </Box>
                              <Box className="profit-section">
                                <Typography className="profit-text">
                                  Win:
                                </Typography>
                                <Typography className="profit-text profit-text-bold">
                                  $21,100
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </>
                  )
                ) : (
                  <>
                    <Box style={{ border: "transparent" }}>
                      {/* No Data Available */}
                      <NoDataComp />
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      )}

      {/* <TipperChatModal
        open={openChatModal}
        handleClose={handleClose}
        title={"Chloe Royle"}
        closeIcon={true}
      /> */}
    </>
  );
}

export default TippingTab;
