import React, { useState, useContext, useEffect } from "react";
import AdvertisingBannner from "../../../../advertisingBanner";
import {
  Box,
  Breadcrumbs,
  Typography,
  Tabs,
  Tab,
  Button
} from "@mui/material";
import "./playerInfo.scss";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Australia from "../../../../../../assets/images/flag/au_flag.svg";
import ProfileInfoPage from "./profileInfoPage";
import FormInfoPage from "./forminfoPage";
import GalleryInfoPage from "./galleryInfoPage";
import TracksInfoPage from "./tracksInfoPage";
import TrainersInfoPage from "./trainersInfoPage";
import OurPartner from "../../../../ourPartners";
import HorsesInfoPage from "./horsesinfoPage";
import { IntlContext } from "src/App";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import AboveTable from "../../../../../../assets/images/ad-placeholder/sports1.webp";
import banner from "../../../../../../assets/images/banner/smart-b-banner-secodary.webp";
import AboveOurPartner from "../../../../../../assets/images/ad-placeholder/sports2.webp";
import BelowOurPartner from "../../../../../../assets/images/ad-placeholder/sports5.webp";
import { ReactComponent as BlackBookIcon } from "../../../../../../assets/images/icons/blackbookicon.svg";
import { Config } from "../../../../../../helpers/context/config";
import ScriptLoader from "src/views/component/scriptLoader/ScriptLoader";
import BlackBookModal from "src/views/component/UI/blackBookModal";

function PlayerInfoPage() {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const localesData = intlContext?.messages;
  const [value, setValue] = useState(0);
  const [headerData, setHeaderData] = useState({});
  const [isHeaderLoading, setisHeaderLoading] = useState(false);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  // const [trackProfileDetail, settrackProfileDetail] = useState(state);
  // const trackProfileDetail = state;
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");

  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBlackBookModal = () => {
    setSelectedTitle(
      params?.personType === "jockey"
        ? headerData?.jockey?.name
        : params?.personType === "trainer"
        ? headerData?.trainer?.name
        : ""
    );
    setSelectedType(
      params?.personType === "jockey"
        ? "Jockey"
        : params?.personType === "trainer"
        ? "Trainer"
        : ""
    );
    setSelectedId(
      params?.personType === "jockey"
        ? headerData?.jockey?.id
        : params?.personType === "trainer"
        ? headerData?.trainer?.id
        : ""
    );
    handleModal();
  };
  const jockeyTab = [
    {
      id: 0,
      name: localesData?.EDITPROFILE?.PROFILE
    },
    {
      id: 1,
      name: localesData?.STATISTICS?.FORM
    },
    {
      id: 2,
      name: localesData?.STATISTICS?.TRACKS
    },
    {
      id: 3,
      name: localesData?.STATISTICS?.TRAINERS
    }
  ];
  const trainerTab = [
    {
      id: 0,
      name: localesData?.EDITPROFILE?.PROFILE
    },
    {
      id: 1,
      name: localesData?.STATISTICS?.FORM
    },
    {
      id: 2,
      name: localesData?.STATISTICS?.TRACKS
    },
    {
      id: 3,
      name: localesData?.STATISTICS?.JOCKEYS
    },
    {
      id: 4,
      name: localesData?.STATISTICS?.HORSES
    }
  ];
  const handleChange = (event, value) => {
    setValue(value);
    fetchAdsData(value);
  };

  useEffect(() => {
    fetchHeadingData();
    fetchAdsData(0);
    setValue(0);
  }, [params?.trackId, params?.personId]);
  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = async tabValue => {
    try {
      let pageId =
        params?.personType === "jockey"
          ? tabValue === 0
            ? 29
            : tabValue === 1
            ? 30
            : tabValue === 2
            ? 31
            : 32
          : tabValue === 0
          ? 33
          : tabValue === 1
          ? 34
          : tabValue === 2
          ? 35
          : tabValue === 3
          ? 36
          : 37;
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=${pageId}&type=web&timeZone=${timezone}`
      );
      if (status === 200) {
        setAdData(data?.result);
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };
  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const fetchHeadingData = async () => {
    setisHeaderLoading(true);
    let passApi =
      params?.personType === "jockey"
        ? `statistics/headerData?trackId=${params?.trackId}&jockeyId=${params?.personId}`
        : `statistics/headerData?trackId=${params?.trackId}&trainerId=${params?.personId}`;

    try {
      const { status, data } = await axiosInstance.get(`${passApi}`);
      if (status === 200) {
        setHeaderData(data?.result);
        setisHeaderLoading(false);
      } else {
        setisHeaderLoading(false);
      }
    } catch (err) {
      setisHeaderLoading(false);
    }
  };
  const scriptCode = `
  rdt('track', 'ViewContent', {
    "products": [
      {
        "id": "product id 1",
        "name": "product name 1",
        "category": "product category 1"
      },
      // additional products can be added here
    ]
  });
`;
  return (<>
    <ScriptLoader scriptCode={scriptCode} />
    <Box className="content-wrap">
      <Box className="banner-img-fix">
        {fetchPageHeaderAds("218px", banner)}
      </Box>
      <Box className="player-info-wrap">
        <Box className="bredcrumn-details">
          {!isHeaderLoading ? (
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  {localesData?.MENU?.HOME}
                </Link>
                <Link underline="hover" color="inherit" to="">
                  {localesData?.MENU?.STATISTICS}
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  to={
                    params?.trackId !== "0"
                      ? "/statistics/trackprofiles"
                      : params?.personType === "jockey"
                      ? "/statistics/jockeyprofiles"
                      : "/statistics/trainerprofiles"
                  }
                >
                  {params?.trackId !== "0"
                    ? "Track profiles"
                    : params?.personType === "jockey"
                    ? "jockey Profiles"
                    : "Trainer Profiles"}
                </Link>
                {params?.trackId !== "0" ? (
                  <Typography>
                    {headerData?.track?.name},
                    {headerData?.track?.Country?.country}
                  </Typography>
                ) : params?.personType === "jockey" ? (
                  <Typography>{headerData?.jockey?.name}</Typography>
                ) : params?.personType === "trainer" ? (
                  <Typography>{headerData?.trainer?.name}</Typography>
                ) : (
                  <></>
                )}
              </Breadcrumbs>
            </Box>
          ) : (
            <></>
          )}
        </Box>

        <Box className="player-details">
          {!isHeaderLoading ? (
            <Box className="player-ul">
              <span className="player-name">
                {params?.personType === "jockey" ? (
                  <>
                    <span className="player-label"> Jockey:</span>{" "}
                    <span className="player-value">
                      {headerData?.jockey?.name}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="player-label"> Trainer:</span>{" "}
                    <span className="player-value">
                      {headerData?.trainer?.name}
                    </span>
                  </>
                )}
              </span>
              {/* {params?.trackId !== "0" ? (
                <Box className="player-scroll">
                  <ul>
                    <li>
                      <img
                        src={
                          headerData?.track?.Country?.country_flag.includes(
                            "uploads"
                          )
                            ? Config.baseURL +
                              headerData?.track?.Country?.country_flag
                            : headerData?.track?.Country?.country_flag
                        }
                        alt="flag"
                      />
                      <span>
                        {headerData?.track?.name},
                        {headerData?.track?.Country?.country}
                      </span>
                    </li>
                    <li>
                      {params?.personType === "jockey"
                        ? "Jockey Stats"
                        : "Trainer Stats"}
                    </li>
                    <li>
                      {" "}
                      {params?.personType === "jockey"
                        ? headerData?.jockey?.name
                        : headerData?.trainer?.name}
                    </li>
                  </ul>
                </Box>
              ) : (
                <></>
              )} */}
            </Box>
          ) : (
            <></>
          )}
          <Box className="player-tab blackbook-icon-wrap">
            <Box>
              {params?.personType === "jockey" ? (
                <Tabs
                  value={value}
                  variant="scrollable"
                  scrollButtons={false}
                  indicatorColor="primary"
                  textColor="primary"
                  className="player-tab-detail"
                  disableRipple
                  disableFocusRipple
                >
                  {jockeyTab?.map((item, index) => {
                    return (
                      <Box>
                        <Tab
                          disableRipple
                          disableFocusRipple
                          label={item?.name}
                          value={item?.id}
                          className={item?.id === value ? "active" : ""}
                          onChange={(event, newValue) =>
                            handleChange(event, item?.id)
                          }
                        />
                      </Box>
                    );
                  })}
                </Tabs>
              ) : (
                <Tabs
                  value={value}
                  variant="scrollable"
                  scrollButtons={false}
                  indicatorColor="primary"
                  textColor="primary"
                  className="player-tab-detail"
                  disableRipple
                  disableFocusRipple
                >
                  {trainerTab?.map((item, index) => {
                    return (
                      <Box>
                        <Tab
                          disableRipple
                          disableFocusRipple
                          label={item?.name}
                          value={item?.id}
                          className={item?.id === value ? "active" : ""}
                          onChange={(event, newValue) =>
                            handleChange(event, item?.id)
                          }
                        />
                      </Box>
                    );
                  })}
                </Tabs>
              )}
            </Box>
            <Box className="blackbook-button-wrap black-book-desktop">
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                className="blackbook-button"
                onClick={() => handleBlackBookModal()}
              >
                <span style={{ display: "flex" }}>
                  <BlackBookIcon />{" "}
                </span>{" "}
                <span style={{ paddingLeft: "8px" }}>
                  {localesData?.BLACKBOOK?.ADD_TO_MY_BLACKBOOK}
                </span>
              </Button>
            </Box>
          </Box>
          {params?.personType === "jockey" ? (
            <Box>
              {value === 0 ? (
                <Box className="tabs-header">
                  <Typography className="tabs-para">
                    {localesData?.EDITPROFILE?.PROFILE}
                  </Typography>
                  <Typography className="para-1">
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Sed fermentum consequat est, rhoncus scelerisque urna
                    cursus ut. Morbi pharetra mi ac ex mollis tristique. */}
                  </Typography>
                </Box>
              ) : value === 1 ? (
                <Box className="tabs-header">
                  <Typography className="tabs-para">
                    {localesData?.RUNNER_TABLE?.JOCKEY}{" "}
                    {localesData?.STATISTICS?.FORM}
                  </Typography>
                </Box>
              ) : value === 2 ? (
                <Box className="tabs-header">
                  <Typography className="tabs-para">
                    {localesData?.RUNNER_TABLE?.JOCKEY}{" "}
                    {localesData?.STATISTICS?.TRACKS}
                  </Typography>
                </Box>
              ) : value === 3 ? (
                <Box className="tabs-header">
                  <Typography className="tabs-para">
                    {localesData?.STATISTICS?.TRAINERS}{" "}
                    {localesData?.STATISTICS?.STATS}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
              <Box className="blackbook-button-wrap black-book-mobile">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="blackbook-button"
                  onClick={() => handleBlackBookModal()}
                >
                  <span style={{ display: "flex" }}>
                    <BlackBookIcon />{" "}
                  </span>{" "}
                  <span style={{ paddingLeft: "8px" }}>
                    {localesData?.BLACKBOOK?.ADD_TO_MY_BLACKBOOK}
                  </span>
                </Button>
              </Box>
            </Box>
          ) : (
            <Box>
              {value == 0 ? (
                <Box className="tabs-header">
                  <Typography className="tabs-para">
                    {localesData?.EDITPROFILE?.PROFILE}
                  </Typography>
                  <Typography className="para-1">
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Sed fermentum consequat est, rhoncus scelerisque urna
                    cursus ut. Morbi pharetra mi ac ex mollis tristique. */}
                  </Typography>
                </Box>
              ) : value == 1 ? (
                <Box className="tabs-header">
                  <Typography className="tabs-para">
                    {localesData?.RUNNER_TABLE?.TRAINER}{" "}
                    {localesData?.STATISTICS?.FORM}
                  </Typography>
                </Box>
              ) : value == 2 ? (
                <Box className="tabs-header">
                  <Typography className="tabs-para">
                    {localesData?.RUNNER_TABLE?.TRAINER}{" "}
                    {localesData?.STATISTICS?.TRACKS}
                  </Typography>
                </Box>
              ) : value == 3 ? (
                <Box className="tabs-header">
                  <Typography className="tabs-para">
                    {localesData?.STATISTICS?.TOP}{" "}
                    {localesData?.STATISTICS?.JOCKEYS}
                  </Typography>
                </Box>
              ) : value == 4 ? (
                <Box className="tabs-header">
                  <Typography className="tabs-para">
                    {localesData?.STATISTICS?.HORSES}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
            </Box>
          )}
        </Box>

        {params?.personType === "jockey" ? (
          <Box className="player-tab-details">
            {value === 0 ? (
              <>
                <ProfileInfoPage fetchAds={fetchAds} />
              </>
            ) : value === 1 ? (
              <>
                <FormInfoPage fetchAds={fetchAds} />
              </>
            ) : value === 2 ? (
              <>
                <TracksInfoPage fetchAds={fetchAds} />
              </>
            ) : // <HorsesInfoPage />
            value === 3 ? (
              <>
                <TrainersInfoPage fetchAds={fetchAds} />
              </>
            ) : (
              ""
            )}
          </Box>
        ) : (
          <Box className="player-tab-details">
            {value === 0 ? (
              <>
                <ProfileInfoPage fetchAds={fetchAds} />
              </>
            ) : value === 1 ? (
              <>
                <FormInfoPage fetchAds={fetchAds} />
              </>
            ) : value === 2 ? (
              <>
                <TracksInfoPage fetchAds={fetchAds} />
              </>
            ) : value === 3 ? (
              <>
                <TrainersInfoPage fetchAds={fetchAds} />
              </>
            ) : value === 4 ? (
              <>
                <HorsesInfoPage fetchAds={fetchAds} />
              </>
            ) : (
              ""
            )}
          </Box>
        )}
      </Box>
      {fetchAds(2, "102px", AboveOurPartner)}
      <OurPartner />
      {fetchAds(3, "102px", BelowOurPartner)}
    </Box>
    <BlackBookModal
      open={open}
      handleClose={handleClose}
      title={selectedTitle}
      type={selectedType}
      closeIcon={true}
      typeId={selectedId}
    />
  </>);
}

export default PlayerInfoPage;
