import React, { useState, useContext } from "react";
// import { Link, withRouter } from "react-router-dom";
import {
  ClickAwayListener,
  Checkbox,
  FormControl,
  Box,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { IntlContext } from "src/App";
import UpcommingCarousel from "./UpcommingCarousel";
import Horses from "../../../assets/images/filter1.svg";
import Greys from "../../../assets/images/filter2.svg";
import Harnes from "../../../assets/images/filter3.svg";
import Football from "../../../assets/images/Sport/football.svg";
import Baseball from "../../../assets/images/Sport/baseball.svg";
import Basketball from "../../../assets/images/Sport/basketball.svg";
import Boxing from "../../../assets/images/Sport/boxing.svg";
import Cricket from "../../../assets/images/Sport/cricket.svg";
import Golf from "../../../assets/images/Sport/golf.svg";
import IceHockey from "../../../assets/images/Sport/iceHockey.svg";
import MMA from "../../../assets/images/Sport/mma.svg";
import Rugby from "../../../assets/images/Sport/rugby.svg";
import Soccer from "../../../assets/images/Sport/soccer.svg";
import Tennis from "../../../assets/images/Sport/Tennis.svg";
import AR from "../../../assets/images/Sport/AR.svg";
import RU from "../../../assets/images/Sport/RU.svg";
import { ReactComponent as DownArrowIcon } from "../../../assets/images/icons/downArrow.svg";
import { ReactComponent as Unchecked } from "../../../assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Checked } from "../../../assets/images/icons/smartb-check.svg";
import { fetchUpcomingRaces } from "../../../helpers/store/Actions/sport";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./headertopbar.scss";
import "./headertopbarV2.scss";
import { useEffect } from "react";
import axiosInstance from "../../../helpers/Axios/axiosInstance";
import { fetchSubscriptionData } from "src/helpers/store/Actions/SubscriptionData";
import { release } from "src/helpers/context/release";
import { Config, fetchFromStorage } from "src/helpers/context";

const HeaderTopBarV2 = () => {
  const reduxSubscriptionData = useSelector(
    (state) => state?.reduxData?.SubscripitionData
  );
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const dispatch = useDispatch();
  const localAuth = fetchFromStorage("auth_token");
  let isLogin = localAuth ? true : false;
  const [filtermenu, setfiltermenu] = useState("none");
  const [filter, setfilter] = useState("Horse Racing");
  const [checkedFilter, setcheckedFilter] = useState("Horse Racing");
  const [resultdata, setResultdata] = useState([]);
  const [prevOffset, setPrevOffset] = useState(0);
  const [nextOffset, setNextOffset] = useState(1);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [isSameAsFilter, setIsSameAsFilter] = useState();
  const [jumpStartDate, setJumpStartDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [filterRaceType, setfilterRaceType] = useState([
    {
      id: 1,
      value: "Horse Racing",
      isChecked: true,
      icon: (
        <span>
          <LazyLoadImage src={Horses} alt="horse-race" />
        </span>
      ),
    },
    {
      id: 3,
      value: "Greyhound Racing",
      isChecked: true,
      icon: (
        <span>
          <LazyLoadImage src={Greys} alt="greyhound-race" />
        </span>
      ),
    },
    {
      id: 2,
      value: "Harness Racing",
      isChecked: true,
      icon: (
        <span>
          <LazyLoadImage src={Harnes} alt="harness-race" />
        </span>
      ),
    },
  ]);
  const [filterSportType, setfilterSportType] = useState([
    {
      id: 15,
      value: "American Football",
      name: "American Football",
      isChecked: true,
      icon: (
        <span>
          <LazyLoadImage src={Football} alt="sport" />
        </span>
      ),
    },
    {
      id: 9,
      value: "Australian Rules",
      name: "Australian Rules",
      isChecked: true,
      icon: (
        <span>
          <LazyLoadImage src={AR} alt="sport" />
        </span>
      ),
    },
    {
      id: 11,
      value: "Baseball",
      name: "Baseball",
      isChecked: true,
      icon: (
        <span>
          <LazyLoadImage src={Baseball} alt="sport" />
        </span>
      ),
    },
    {
      id: 10,
      value: "Basketball",
      name: "Basketball",
      isChecked: true,
      icon: (
        <span>
          <LazyLoadImage src={Basketball} alt="sport" />
        </span>
      ),
    },
    {
      id: 6,
      value: "Boxing",
      name: "Boxing",
      isChecked: true,
      icon: (
        <span>
          <LazyLoadImage src={Boxing} alt="sport" />
        </span>
      ),
    },
    {
      id: 4,
      value: "Cricket",
      name: "Cricket",
      isChecked: true,
      icon: (
        <span>
          <LazyLoadImage src={Cricket} alt="sport" />
        </span>
      ),
    },
    // {
    //   id: 16,
    //   value: "Golf",
    //   name: "Golf",
    //   isChecked: true,
    //   icon: (
    //     <span>
    //       <LazyLoadImage src={Golf} alt="sport" />
    //     </span>
    //   ),
    // },
    {
      id: 17,
      value: "Ice Hockey",
      name: "Ice Hockey",
      isChecked: true,
      icon: (
        <span>
          <LazyLoadImage src={IceHockey} alt="sport" />
        </span>
      ),
    },
    {
      id: 5,
      value: "Mixed Martial Arts",
      name: "Mixed Martial Arts",
      isChecked: true,
      icon: (
        <span>
          <LazyLoadImage src={MMA} alt="sport" />
        </span>
      ),
    },
    {
      id: 12,
      value: "Rugby League",
      name: "Rugby League",
      isChecked: true,
      icon: (
        <span>
          <LazyLoadImage src={Rugby} alt="sport" />
        </span>
      ),
    },
    {
      id: 13,
      value: "Rugby Union",
      name: "Rugby Union",
      isChecked: true,
      icon: (
        <span>
          <LazyLoadImage src={RU} alt="sport" />
        </span>
      ),
    },
    {
      id: 8,
      value: "Soccer",
      name: "Soccer",
      isChecked: true,
      icon: (
        <span>
          <LazyLoadImage src={Soccer} alt="sport" />
        </span>
      ),
    },
    {
      id: 7,
      value: "Tennis",
      name: "Tennis",
      isChecked: true,
      icon: (
        <span>
          <LazyLoadImage src={Tennis} alt="sport" />
        </span>
      ),
    },
  ]);
  const [filterCountry, setfilterCountry] = useState([
    {
      id: 1,
      value: "Aus/NZ",
      name: "Aus/NZ",
      isChecked: true,
    },
    {
      id: 2,
      value: "Intl",
      name: "Int'l",
      isChecked: true,
    },
  ]);
  const [collepseRace, setcollepseRace] = useState(
    release[Config.release]?.raceSportId
  );
  const [collepseSport, setcollepseSport] = useState(
    release[Config.release]?.sportId
  );
  const [collepseCountry, setcollepseCountry] = useState(
    release[Config.release]?.countryFilter
  );
  const [isLiveSelected, setIsLiveSelected] = useState(false);

  useEffect(() => {
    if (release[Config.release]?.sportId?.length > 0) {
      const releaseFilterSportType = filterSportType?.filter((item) =>
        release[Config.release]?.sportId?.includes(item?.id)
      );
      setfilterSportType(releaseFilterSportType);
    }
    const releaseFilterRaceType = filterRaceType?.filter((item) =>
      release[Config.release]?.raceSportId?.includes(item?.id)
    );
    setfilterRaceType(releaseFilterRaceType);
    const releaseFilterCountry = filterCountry?.filter((item) =>
      release[Config.release]?.countryFilter?.includes(item?.value)
    );
    setfilterCountry(releaseFilterCountry);
  }, [release, release[Config.release]?.sportId]);

  const handleFiltermenu = () => {
    setfiltermenu(filtermenu === "none" ? "block" : "none");
  };
  const handleFiltermenuClose = () => {
    setfiltermenu("none");
  };
  const handleFilter = (e) => {
    setfilter(filter === e.target.value ? "" : e.target.value);
    setcheckedFilter(checkedFilter === e.target.value ? "" : e.target.value);
  };

  const setNextToJumpRacing = async (raceArr) => {
    if (isLogin) {
      const layoutTypes =
        reduxSubscriptionData?.isDefaultHomePage === true
          ? "homepage"
          : reduxSubscriptionData?.isDefaultRacingPage === true
            ? "racingpage"
            : "sportpage";

      try {
        const { status, data } = await axiosInstance.post(
          `/user/customiseUserPages?type=${layoutTypes}`,
          { racingNextToJump: raceArr?.length > 0 ? raceArr : null }
        );
        if (status === 200) {
        } else {
        }
      } catch (err) {}
    }
  };

  // Change Racing Filter

  const ChangeRaceFilter = (event, item) => {
    dispatch(fetchUpcomingRaces(undefined));
    setResultdata([]);
    setPrevOffset(0);
    setNextOffset(1);
    setDisableNext(false);
    setDisablePrev(false);
    setJumpStartDate(moment().format("YYYY-MM-DD"));
    const flag = event.target.checked;
    if (flag) {
      const newItem = [...collepseRace, item];
      setcollepseRace(newItem);
      // setNextToJumpRacing(newItem)
    } else {
      // if (collepseRace?.length >= 2) {
      const removeItem = collepseRace?.filter((eItem) => eItem !== item);
      setcollepseRace(removeItem);
      // setNextToJumpRacing(removeItem)
      // }
    }
  };

  const ChangeLiveFilter = (event) => {
    dispatch(fetchUpcomingRaces(undefined));
    setResultdata([]);
    setPrevOffset(0);
    setNextOffset(1);
    setDisableNext(false);
    setDisablePrev(false);
    setJumpStartDate(moment().format("YYYY-MM-DD"));
    setIsLiveSelected(event ? event.target.checked : !isLiveSelected);
  };

  // Change sport Filter

  const ChangeSportFilter = (event, item) => {
    dispatch(fetchUpcomingRaces(undefined));
    setResultdata([]);
    setPrevOffset(0);
    setNextOffset(1);
    setDisableNext(false);
    setDisablePrev(false);
    setJumpStartDate(moment().format("YYYY-MM-DD"));
    const flag = event.target.checked;
    if (flag) {
      const newItem = [...collepseSport, item];
      setcollepseSport(newItem);
    } else {
      // if (collepseSport?.length >= 2) {
      const removeItem = collepseSport?.filter((eItem) => eItem !== item);
      setcollepseSport(removeItem);
      // }
    }
  };

  // Change Timezone Filter
  const ChangeCountryFilter = (event, item) => {
    const flag = event.target.checked;
    if (flag) {
      const newItem = [...collepseCountry, item];
      setcollepseCountry(newItem);
    } else {
      // if (collepseCountry.length >= 2) {
      const removeItem = collepseCountry.filter((eItem) => eItem !== item);
      setcollepseCountry(removeItem);
      // }
    }
  };
  const handleClearAllFilter = () => {
    setcollepseRace([]);
    setcollepseSport([]);
    dispatch(fetchUpcomingRaces(undefined));
    setResultdata([]);
    setPrevOffset(0);
    setNextOffset(1);
    setDisableNext(false);
    setDisablePrev(false);
    setJumpStartDate(moment().format("YYYY-MM-DD"));
    setcollepseCountry([]);
    setIsLiveSelected(false);
  };
  const handleSelectAllFilter = () => {
    setcollepseRace(release[Config.release]?.raceSportId);
    setcollepseSport(release[Config.release]?.sportId);
    setcollepseCountry(release[Config.release]?.countryFilter);
    setPrevOffset(0);
    setNextOffset(1);
    setDisableNext(false);
    setDisablePrev(false);
  };
  const updateSelectedJumpToNextToMeta = async () => {
    try {
      const payload = { collepseRace, collepseSport, collepseCountry };
      const { status, data } = await axiosInstance.put(
        "user/meta/interestedSport",
        payload
      );
      if (status === 200 && !reduxSubscriptionData?.interestedSports) {
        if (Config.release == "IN") {
          data["oddfixtures"] = false;
        }
        dispatch(fetchSubscriptionData(data));
      }
    } catch (err) {}
  };
  const updateInterestedSports = () => {
    const interestedSports =
      reduxSubscriptionData.interestedSports &&
      JSON.parse(reduxSubscriptionData.interestedSports);
    if (interestedSports) {
      let result = true;
      if (
        JSON.stringify(collepseRace) !==
        JSON.stringify(interestedSports.collepseRace)
      ) {
        setcollepseRace(
          interestedSports.collepseRace ? interestedSports.collepseRace : []
        );
        result = false;
      }
      if (
        JSON.stringify(collepseSport) !==
        JSON.stringify(interestedSports.collepseSport)
      ) {
        setcollepseSport(
          interestedSports.collepseSport ? interestedSports.collepseSport : []
        );
        result = false;
      }
      if (
        JSON.stringify(collepseCountry) !==
        JSON.stringify(interestedSports.collepseCountry)
      ) {
        setcollepseCountry(
          interestedSports.collepseCountry
            ? interestedSports.collepseCountry
            : []
        );
        result = false;
      }
      setIsSameAsFilter(result);
    } else {
      updateSelectedJumpToNextToMeta();
    }
  };

  useEffect(() => {
    if (reduxSubscriptionData?.id) {
      updateInterestedSports();
    }
    // login
    else handleSelectAllFilter(); // logout
  }, [reduxSubscriptionData?.interestedSports]);

  useEffect(() => {
    if (reduxSubscriptionData?.id) {
      updateSelectedJumpToNextToMeta();
    }
  }, [collepseRace, collepseSport, collepseCountry]);
  return (
    <>
      <Box className="bg-blue top-bar ">
        <Box className="header-bar">
          <div className="NextoJump-wrap ">
            <Box className="nexttoJump-Item-v2">
              <div className=" NextoJump-Title">
                <span>{localesData.next_to_jump}</span>
              </div>
              <ClickAwayListener onClickAway={handleFiltermenuClose}>
                <div className=" NextoJump-Filteritem">
                  <p className="innerTitle" onClick={handleFiltermenu}>
                    Filter <DownArrowIcon />
                  </p>
                  <div
                    style={{ display: filtermenu }}
                    className="NextoJump-Filteritemlist"
                  >
                    <Box className="nextoJump-race-list">
                      <Box className="select-clear-wrap">
                        <Typography
                          className="nextoJump-All-label"
                          onClick={() => handleSelectAllFilter()}
                        >
                          Select all
                        </Typography>
                        <Typography
                          className="nextoJump-All-label"
                          onClick={() => handleClearAllFilter()}
                        >
                          Clear all
                        </Typography>
                      </Box>
                      <Box className="nexttojump-live-wrap">
                        <FormControl>
                          <label>
                            <Checkbox
                              icon={<Unchecked />}
                              checkedIcon={<Checked />}
                              name="filter"
                              value={isLiveSelected}
                              onChange={(event) => {
                                ChangeLiveFilter(event);
                              }}
                              checked={isLiveSelected}
                            />
                            {filter?.icon}
                          </label>
                        </FormControl>
                        <Typography
                          className="nextoJump-filter-Name live-name"
                          onClick={() => ChangeLiveFilter()}
                        >
                          Live
                        </Typography>
                      </Box>
                      {filterRaceType?.length > 0 && (
                        <Box className="nextoJump-list-name">
                          <Typography className="nextoJump-filter-Name">
                            Racing
                          </Typography>
                        </Box>
                      )}
                      {filterRaceType?.length > 0 && (
                        <ul className="NextoJump-Filteritemlist-ul">
                          {filterRaceType?.map((filter, i) => (
                            <li key={filter?.id}>
                              <FormControl>
                                <label>
                                  <Checkbox
                                    icon={<Unchecked />}
                                    checkedIcon={<Checked />}
                                    name="filter"
                                    value={filter?.id}
                                    onChange={(event) => {
                                      ChangeRaceFilter(event, filter?.id);
                                    }}
                                    checked={collepseRace?.includes(filter?.id)}
                                  />
                                  {filter?.icon}
                                </label>
                              </FormControl>
                            </li>
                          ))}
                        </ul>
                      )}
                      {filterCountry?.length > 0 && (
                        <ul>
                          {filterCountry?.map((countryItem, i) => (
                            <li key={countryItem?.id}>
                              <FormControl>
                                <label>
                                  <Checkbox
                                    icon={<Unchecked />}
                                    checkedIcon={<Checked />}
                                    name="filtercountry"
                                    value={countryItem?.value}
                                    onChange={(event) => {
                                      ChangeCountryFilter(
                                        event,
                                        countryItem?.value
                                      );
                                    }}
                                    checked={collepseCountry?.includes(
                                      countryItem?.value
                                    )}
                                  />

                                  {countryItem?.name}
                                </label>
                              </FormControl>
                            </li>
                          ))}
                        </ul>
                      )}
                    </Box>
                    {filterSportType?.length > 0 && (
                      <Typography className="nextoJump-filter-Name sport-name">
                        Sports
                      </Typography>
                    )}
                    {filterSportType?.length > 0 && (
                      <ul className="NextoJump-Filteritemlist-ul ul-sport">
                        {filterSportType?.map((filter, i) => (
                          <li key={filter?.id}>
                            <FormControl>
                              <label className="sport-label">
                                <Checkbox
                                  icon={<Unchecked />}
                                  checkedIcon={<Checked />}
                                  name="filter"
                                  value={filter?.id}
                                  onChange={(event) => {
                                    ChangeSportFilter(event, filter?.id);
                                  }}
                                  checked={collepseSport?.includes(filter?.id)}
                                />
                                <Box className="label-name">
                                  {filter?.name}
                                  {filter?.icon}
                                </Box>
                              </label>
                            </FormControl>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </ClickAwayListener>
            </Box>
            <UpcommingCarousel
              filter={collepseRace}
              country={collepseCountry}
              sport={collepseSport}
              isLive={isLiveSelected}
              // search={searchrace}
              resultdata={resultdata}
              setResultdata={setResultdata}
              jumpStartDate={jumpStartDate}
              setJumpStartDate={setJumpStartDate}
              prevOffset={prevOffset}
              setPrevOffset={setPrevOffset}
              nextOffset={nextOffset}
              setNextOffset={setNextOffset}
              disablePrev={disablePrev}
              disableNext={disableNext}
              setDisableNext={setDisableNext}
              setDisablePrev={setDisablePrev}
              isSameAsFilter={isSameAsFilter}
              setIsSameAsFilter={setIsSameAsFilter}
            />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default HeaderTopBarV2;
