import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Tab,
  Tabs,
  Typography,
  Button,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Link, useParams, useNavigate } from "react-router-dom";
import { IntlContext } from "src/App";
import Select, { components } from "react-select";
import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
//   DatePicker
// } from "@material-ui/pickers";
import { ReactComponent as LeftArrow } from "src/assets/images/left-arrow.svg";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Bet365Long from "src/assets/images/bookmakers/bet365-long.png";
import LadBrokesLong from "src/assets/images/bookmakers/ladbrokes-long.png";
import Bet365Mobile from "../../../../../assets/images/bookmakers/Bet365-mobile.png";
import LadBrokesMobile from "../../../../../assets/images/bookmakers/ladbrokesMobile.svg";
import Bet365 from "../../../../../assets/images/bookmakers/bet365-thumb.svg";
import UniBet from "../../../../../assets/images/bookmakers/unibet-thumb.svg";
import BookMaker from "../../../../../assets/images/bookmakers/bookmakers-thumb.svg";
import Neds from "../../../../../assets/images/bookmakers/neds-thumb.svg";
import PlayUp from "../../../../../assets/images/bookmakers/playup-thumb.svg";
import BetStar from "../../../../../assets/images/bookmakers/betstar-thumb.svg";
import ViceBet from "../../../../../assets/images/bookmakers/vice-bet-thumb.svg";
import BlueBet from "../../../../../assets/images/bookmakers/BlueBet-thumb.png";
import TopSport from "../../../../../assets/images/bookmakers/top-sport-thumb.svg";
import BetFair from "../../../../../assets/images/bookmakers/betfair-thumb.svg";
import BoomBet from "../../../../../assets/images/bookmakers/BoomBet.png";
import LadBrokes from "../../../../../assets/images/bookmakers/Ladbrokes-thumb.png";
import Draftstars from "../../../../../assets/images/bookmakers/DraftStars.png";
import SouthernCrossBet from "../../../../../assets/images/bookmakers/SouthernCrossBet.png";

import moment from "moment-timezone";

const tabData = [
  {
    id: 0,
    name: "Matches",
  },
  {
    id: 1,
    name: "Other",
  },
];

const TournamentOptions = [
  {
    label: "All Tournaments ",
    value: 0,
  },
  {
    label: "internazionali-d-italia-men-s ",
    value: 1,
  },
  {
    label: "busan-challenger ",
    value: 2,
  },
  {
    label: "itf-men-monastir",
    value: 3,
  },
  {
    label: "itf-women-trnava",
    value: 4,
  },
];

const oddsDataOption = [
  // { value: "1", label: "ODDS TYPE", disabled: true },
  { value: 1, label: "Win Fixed" },
  // { value: 2, label: "Place Fixed" },
  // { value: 3, label: "Win Tote" },
  // { value: "4", label: "Win Tote" },
  // { value: "5", label: "Starting Price" }
];

const combatSprotData = [
  {
    id: 1,
    isLive: true,
    date: "10/05/2023",
    time: "01:45PM",
    market: "158 Markets",
    tag: "competitions",
    homeTeam: "Adrian Mannarino",
    awayTeam: "Christopher Eubanks",
  },
  {
    id: 2,
    isLive: false,
    date: "11/05/2023",
    time: "01:45PM",
    market: "49 Markets",
    tag: "competitions",
    homeTeam: "Adrian Mannarino",
    awayTeam: "Christopher Eubanks",
  },
  {
    id: 3,
    isLive: false,
    date: "11/05/2023",
    time: "01:45PM",
    market: "158 Markets",
    tag: "competitions",
    homeTeam: "Adrian Mannarino",
    awayTeam: "Christopher Eubanks",
  },
  {
    id: 4,
    isLive: false,
    date: "10/05/2023",
    time: "01:45PM",
    market: "158 Markets",
    tag: "competitions",
    homeTeam: "Adrian Mannarino",
    awayTeam: "Christopher Eubanks",
  },
  {
    id: 5,
    isLive: false,
    date: "12/05/2023",
    time: "01:45PM",
    market: "158 Markets",
    tag: "competitions",
    homeTeam: "Adrian Mannarino",
    awayTeam: "Christopher Eubanks",
  },
];

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const StickyTableCell = withStyles((theme) => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 98,
    width: "457px",
    backgroundColor: "#ffffff",
    "@media(max-width: 1090px)": {
      width: "300px",
    },
    "@media(max-width: 479px)": {
      width: "200px",
    },
  },
  body: {
    left: 0,
    position: "sticky",
    zIndex: 90,
    width: "457px",
    backgroundColor: "#ffffff",
    "@media(max-width: 1090px)": {
      width: "300px",
    },
    "@media(max-width: 479px)": {
      width: "200px",
    },
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  head: {},
  cell: {},
}));

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const AllCombatSportLayoutPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [tabvalue, setTabValue] = useState(0);
  let [stepperCount, setStepperCount] = useState(0);
  const [selectedDate, setselectedDate] = useState(null);
  const [selectedseries, setselectedseries] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [seeAll, setSeeAll] = useState(params?.seeall);
  const [scrollX, setscrollX] = useState("left");
  const [selectedOption, setSelectedOption] = useState(1);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const handleTabChange = (event, value) => {
    setTabValue(value);
    // setselectedseries(0);
    // // setselectedDate(null);
    // setselectedteam(0);
  };

  const handleStepper = (value) => {
    if (stepperCount >= 0) {
      return value === "left"
        ? stepperCount > 0
          ? setStepperCount(stepperCount - 1)
          : ""
        : setStepperCount(stepperCount + 1);
    }
  };

  const handleDateChange = (date) => {
    setselectedDate(moment(date).tz(timezone).format("YYYY-MM-DD"));
  };

  const fetchDayName = (date) => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };
  const scrollToTop = () => {
    document.getElementById("inner-content").scrollIntoView();
  };
  const handleSeeAll = (item) => {
    setSeeAll("true");
    navigate(`/combatsports/tennis/${item?.id}/true`);
    // setindividualTeamData(item);
    scrollToTop();
  };
  const handleSeeBest = () => {
    setSeeAll("false");
    navigate(`/combatsports/tennis/0/false`);
  };

  const SlideTable = (direction) => {
    setscrollX(direction);
    var container = document.getElementById("individual-team-table");
    var scrollCompleted = 0;
    var slideVar = setInterval(function () {
      if (direction == "left") {
        container.scrollLeft -= 10;
      } else {
        container.scrollLeft += 10;
      }
      scrollCompleted += 10;
      if (scrollCompleted >= 600) {
        window.clearInterval(slideVar);
      }
    }, 10);
  };

  const renderTableCell = (images) => {
    return (
      <>
        <TableCell className={classes.head}>
          <img
            className="bookmaker-thumb"
            src={images}
            alt="Odds Icon"
            loading="lazy"
          />
        </TableCell>
      </>
    );
  };

  const handleMarketPage = (data) => {
    navigate(`/sportmarket`, { state: data });
  };
  return (
    <>
      <Box className="combat-sport-layout">
        <Box className="layout-header">
          <Box className="bredcrumn-wrap">
            <Breadcrumbs
              separator="/"
              aria-label="breadcrumb"
              className="breadcrumb"
            >
              <Link underline="hover" color="inherit" to="/">
                {localesData?.MENU?.HOME}
              </Link>
              <Link underline="hover" color="inherit" to="">
                {localesData?.MENU?.SPORTS}
              </Link>
              <Typography>Tennis</Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="h1">Tennis</Typography>
        </Box>
        <Box className="combat-sports-tab">
          <Tabs
            value={tabvalue}
            variant="scrollable"
            scrollButtons={false}
            indicatorColor="primary"
            textColor="primary"
            className="combat-tab-detail"
            disableRipple
            disableFocusRipple
          >
            {tabData?.map((item, index) => {
              return (
                <Box>
                  <Tab
                    disableRipple
                    disableFocusRipple
                    label={item?.name}
                    value={item?.id}
                    className={item?.id === tabvalue ? "active " : ""}
                    onChange={(event, newValue) =>
                      handleTabChange(event, item?.id)
                    }
                  />
                </Box>
              );
            })}
          </Tabs>
          <Box className="select-wrap">
            <Select
              className="React series-select"
              value={TournamentOptions?.find((item) => {
                return item?.value === selectedseries;
              })}
              onChange={(e) => {
                setselectedseries(e?.value == 0 ? "" : e?.value);
                // setTournamentSelect(false);
              }}
              // onMenuScrollToBottom={e => handleOnScrollBottomTournament(e)}
              options={TournamentOptions}
              classNamePrefix="select"
              placeholder="All Tournaments"
              // isSearchable={false}
              // onFocus={() => setTournamentSelect(true)}
              // onBlur={() => setTournamentSelect(false)}
              components={{ DropdownIndicator }}
            />
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              placeholder="All"
              margin="normal"
              id="date-picker-inline"
              inputVariant="outlined"
              value={selectedDate}
              onChange={e => handleDateChange(e)}
              // disableFuture={value == 8}
              // disablePast={value == 7}
              // style={{ marginRight: 5 }}
              className="details-search-picker"
            />
          </MuiPickersUtilsProvider> */}
            <Select
              className="React team-select"
              //   value={TeamOptions?.find(item => {
              //     return item?.value === selectedteam;
              //   })}
              //   onChange={e => {
              //     setselectedteam(e?.value == 0 ? "" : e?.value);
              //     // setTeamSelect(false);
              //     navigate(`/teamsports/${params?.type}/0/false`);
              //   }}
              // onFocus={() => setTeamSelect(true)}
              // onBlur={() => setTeamSelect(false)}
              // onMenuScrollToBottom={e => handleOnScrollBottomTeam(e)}
              //   options={TeamOptions}
              classNamePrefix="select"
              placeholder="All Teams"
              // isSearchable={false}
              components={{ DropdownIndicator }}
            />

            <Box className="stepper-input">
              <Button
                className="stepper-input__button"
                onClick={(e) => {
                  e.preventDefault();
                  handleStepper("left");
                }}
                disabled={stepperCount == 0}
              >
                <LeftArrow />
              </Button>

              <div className="stepper-input__content">
                {stepperCount === 0 ? "Round" : `Round ${stepperCount}`}
              </div>

              <Button
                className="stepper-input__button"
                onClick={(e) => {
                  e.preventDefault();
                  handleStepper("right");
                }}
              >
                <RightArrow />
              </Button>
            </Box>
          </Box>
        </Box>
        {params?.seeall === "false" ? (
          <>
            {combatSprotData?.map((item) => {
              return (
                <>
                  <Box className="combat-sport-table-wrap">
                    <Box className="table-header-wrap">
                      <Box>
                        <Typography variant="h1">
                          {fetchDayName(item?.date)}{" "}
                          {moment(item?.date).local().format("DD/MM/YYYY")}
                        </Typography>
                      </Box>
                      <Box className="currentbest-seeall">
                        <span>
                          <Typography variant="h1">
                            {" "}
                            {localesData?.RUNNER_TABLE?.CURRENT_BEST}
                          </Typography>
                        </span>
                        <span
                          className="teamsports-seeall"
                          onClick={() => handleSeeAll(item)}
                        >
                          <Typography variant="h1">
                            {localesData?.RUNNER_TABLE?.SEE_ALL}
                          </Typography>
                        </span>
                      </Box>
                    </Box>
                    {screenWidth > 1023 ? (
                      item?.isLive ? (
                        <Box className="live-section">
                          <Table className="combat-sport-table-live">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Box className="combat-sport-indicator-live">
                                    <Box className="team-time-market">
                                      <span className="live">Live</span>
                                      <span
                                        className="cursor"
                                        onClick={() => handleMarketPage(item)}
                                      >
                                        {" "}
                                        {`${item?.market ? item?.market : 0}`}
                                      </span>
                                    </Box>
                                    <span className="sport-indicator-btn">
                                      {item?.tag}
                                    </span>
                                  </Box>
                                </TableCell>
                                <TableCell>Sets</TableCell>
                                <TableCell>Games</TableCell>
                                <TableCell>Points</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell className="text-left">
                                  {item?.homeTeam}V/S
                                </TableCell>
                                <TableCell>0</TableCell>
                                <TableCell>6</TableCell>
                                <TableCell>1</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="text-left">
                                  {item?.awayTeam}
                                </TableCell>
                                <TableCell>0</TableCell>
                                <TableCell>6</TableCell>
                                <TableCell>2</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          <Box className="win-odds-live">
                            <Box>
                              <Box className="odds-label">
                                {" "}
                                {item?.homeTeam ? item?.homeTeam : ""}
                              </Box>
                              <Box className="odds-wrap">
                                <span className="odds odds-left"> 1.88 </span>
                                <img
                                  className="bookmaker-long"
                                  src={Bet365Long}
                                  alt="Odds Icon"
                                />
                              </Box>
                            </Box>
                            <Box>
                              <Box className="odds-label">
                                {" "}
                                {item?.awayTeam ? item?.awayTeam : ""}
                              </Box>
                              <Box className="odds-wrap">
                                <span className="odds odds-left"> 1.88 </span>
                                <img
                                  className="bookmaker-long"
                                  src={LadBrokesLong}
                                  alt="Odds Icon"
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Box className="sponsored-wrap">
                            <Box className="sponsored-head-live">
                              <Box className="head-title">
                                Sponsored Head to Head
                              </Box>
                            </Box>
                            <Box className="sponsored-odds-wrap">
                              <Box className="sponsored-odds-live border-bottom-odds">
                                <Box className="sponsored-odd">
                                  <Box className="odds-label">
                                    {" "}
                                    {item?.homeTeam ? item?.homeTeam : ""}
                                  </Box>
                                  <Box className="odds-wrap">
                                    <span className="odds odds-left">
                                      {" "}
                                      1.88{" "}
                                    </span>
                                    <img
                                      className="bookmaker-long"
                                      src={Bet365Long}
                                      alt="Odds Icon"
                                    />
                                  </Box>
                                </Box>
                                <Box className="sponsored-odd">
                                  <Box className="odds-label">
                                    {" "}
                                    {item?.awayTeam ? item?.awayTeam : ""}
                                  </Box>
                                  <Box className="odds-wrap">
                                    <span className="odds odds-left">
                                      {" "}
                                      1.88{" "}
                                    </span>
                                    <img
                                      className="bookmaker-long"
                                      src={LadBrokesLong}
                                      alt="Odds Icon"
                                    />
                                  </Box>
                                </Box>
                              </Box>
                              <Box className="sponsored-odds-live">
                                <Box className="sponsored-odd">
                                  <Box className="odds-label">
                                    {" "}
                                    {item?.homeTeam ? item?.homeTeam : ""}
                                  </Box>
                                  <Box className="odds-wrap">
                                    <span className="odds odds-left">
                                      {" "}
                                      1.88{" "}
                                    </span>
                                    <img
                                      className="bookmaker-long"
                                      src={Bet365Long}
                                      alt="Odds Icon"
                                    />
                                  </Box>
                                </Box>
                                <Box className="sponsored-odd">
                                  <Box className="odds-label">
                                    {" "}
                                    {item?.awayTeam ? item?.awayTeam : ""}
                                  </Box>
                                  <Box className="odds-wrap">
                                    <span className="odds odds-left">
                                      {" "}
                                      1.88{" "}
                                    </span>
                                    <img
                                      className="bookmaker-long"
                                      src={LadBrokesLong}
                                      alt="Odds Icon"
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Table className="team-sport-table">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Box className="team-sport-table-indicator">
                                  <Box className="team-time-market">
                                    {/* {moment
                              .utc(item?.time)
                              .local()
                              .format("hh:mm A")}{" "} */}
                                    {item?.time}|{" "}
                                    <span
                                      className="cursor"
                                      onClick={() => handleMarketPage(item)}
                                    >
                                      {" "}
                                      {`${item?.market ? item?.market : 0}`}
                                    </span>
                                  </Box>
                                  <span className="sport-indicator-btn">
                                    {item?.tag}
                                  </span>
                                </Box>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "auto",
                                }}
                              >
                                {" "}
                                {localesData?.sport?.BEST_HEAD_TO_HEAD}{" "}
                              </TableCell>

                              <TableCell colSpan={2} className="sponsored-head">
                                Sponsored Head to Head
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow className="teamsports-tablerow hometeam-row">
                              <TableCell className="team-wrap">
                                <span className="team-name">
                                  {item?.homeTeam ? item?.homeTeam : ""} V/S
                                </span>
                              </TableCell>
                              <TableCell>
                                <Box className="odds-label">
                                  {" "}
                                  {item?.homeTeam ? item?.homeTeam : ""}
                                </Box>
                                <Box className="odds-wrap">
                                  <span className="odds odds-left"> 1.88 </span>
                                  <img
                                    className="bookmaker-long"
                                    src={Bet365Long}
                                    alt="Odds Icon"
                                  />
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Box className="odds-label">
                                  {" "}
                                  {item?.homeTeam ? item?.homeTeam : ""}
                                </Box>
                                <Box className="odds-wrap">
                                  <span className="odds odds-left"> 1.88 </span>
                                  <img
                                    className="bookmaker-long"
                                    src={LadBrokesLong}
                                    alt="Odds Icon"
                                  />
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Box className="odds-label">
                                  {" "}
                                  {item?.homeTeam ? item?.homeTeam : ""}
                                </Box>
                                <Box className="odds-wrap">
                                  <span className="odds odds-left"> 1.88 </span>
                                  <img
                                    className="bookmaker-long"
                                    src={Bet365Long}
                                    alt="Odds Icon"
                                  />
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow className="awayteam-row">
                              <TableCell className="team-wrap">
                                <span className="team-name">
                                  {" "}
                                  {item?.awayTeam ? item?.awayTeam : ""}
                                </span>
                              </TableCell>
                              <TableCell>
                                <Box className="odds-label">
                                  {" "}
                                  {item?.awayTeam ? item?.awayTeam : ""}
                                </Box>
                                <Box className="odds-wrap">
                                  <span className="odds odds-left"> 1.88 </span>
                                  <img
                                    className="bookmaker-long"
                                    src={Bet365Long}
                                    alt="Odds Icon"
                                  />
                                </Box>
                              </TableCell>

                              <TableCell>
                                <Box className="odds-label">
                                  {" "}
                                  {item?.awayTeam ? item?.awayTeam : ""}
                                </Box>
                                <Box className="odds-wrap">
                                  <span className="odds odds-left"> 1.88 </span>
                                  <img
                                    className="bookmaker-long"
                                    src={Bet365Long}
                                    alt="Odds Icon"
                                  />
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Box className="odds-label">
                                  {item?.awayTeam ? item?.awayTeam : ""}
                                </Box>
                                <Box className="odds-wrap">
                                  <span className="odds odds-left"> 1.88 </span>
                                  <img
                                    className="bookmaker-long"
                                    src={LadBrokesLong}
                                    alt="Odds Icon"
                                  />
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )
                    ) : item?.isLive ? (
                      <Box className="mobile-live-section">
                        <Box className="mobile-temsport-indicator">
                          <span className="live">Live</span>
                          <Box>
                            <Typography className="des-label">
                              1st Set |{" "}
                              <span
                                className="cursor"
                                onClick={() => handleMarketPage(item)}
                              >
                                {item?.market}
                              </span>{" "}
                              <span className="sport-indicator-btn">
                                {item?.tag}
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                        <Table className="mobile-live-table">
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell>Sets</TableCell>
                              <TableCell>Games</TableCell>
                              <TableCell>Points</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell className="table-head">
                                {item?.homeTeam} V/S
                              </TableCell>
                              <TableCell>0</TableCell>
                              <TableCell>6</TableCell>
                              <TableCell>1</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="table-head">
                                {item?.awayTeam}
                              </TableCell>
                              <TableCell>0</TableCell>
                              <TableCell>6</TableCell>
                              <TableCell>2</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        <Box className="win-odds-live">
                          <Box>
                            <Box className="odds-label">
                              {" "}
                              {item?.homeTeam ? item?.homeTeam : ""}
                            </Box>
                            <Box className="odds-wrap">
                              <span className="odds odds-left"> 1.88 </span>
                              <img
                                className="bookmaker-long"
                                src={
                                  screenWidth > 599 ? Bet365Long : Bet365Mobile
                                }
                                alt="Odds Icon"
                              />
                            </Box>
                          </Box>
                          <Box>
                            <Box className="odds-label">
                              {" "}
                              {item?.awayTeam ? item?.awayTeam : ""}
                            </Box>
                            <Box className="odds-wrap">
                              <span className="odds odds-left"> 1.88 </span>
                              <img
                                className="bookmaker-long"
                                src={
                                  screenWidth > 599
                                    ? LadBrokesLong
                                    : LadBrokesMobile
                                }
                                alt="Odds Icon"
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box className="des-label-head">
                          <Typography className="des-label sponsored-head ">
                            Sponsored Head to Head
                          </Typography>
                        </Box>
                        <Box className="odds-detail">
                          <Box className="odds-body sponsored-odds-body">
                            <Box>
                              <Box className="odds-label">
                                {item?.homeTeam ? item?.homeTeam : ""}
                              </Box>
                              <Box className="odds-wrap">
                                <img
                                  className="bookmaker-long odds-left"
                                  src={
                                    screenWidth > 599
                                      ? Bet365Long
                                      : Bet365Mobile
                                  }
                                  alt="Odds Icon"
                                />
                                <span className="odds"> 2.00 </span>
                              </Box>
                            </Box>
                            <Box>
                              <Box className="odds-label">
                                {item?.awayTeam ? item?.awayTeam : ""}{" "}
                              </Box>
                              <Box className="odds-wrap">
                                <span className="odds odds-left"> 2.00 </span>
                                <img
                                  className="bookmaker-long"
                                  src={
                                    screenWidth > 599
                                      ? LadBrokesLong
                                      : LadBrokesMobile
                                  }
                                  alt="Odds Icon"
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Box className="odds-body sponsored-odds-body">
                            <Box>
                              <Box className="odds-label">
                                {item?.homeTeam ? item?.homeTeam : ""}
                              </Box>
                              <Box className="odds-wrap">
                                <img
                                  className="bookmaker-long odds-left"
                                  src={
                                    screenWidth > 599
                                      ? Bet365Long
                                      : Bet365Mobile
                                  }
                                  alt="Odds Icon"
                                />
                                <span className="odds"> 2.00 </span>
                              </Box>
                            </Box>
                            <Box>
                              <Box className="odds-label">
                                {item?.awayTeam ? item?.awayTeam : ""}
                              </Box>
                              <Box className="odds-wrap">
                                <span className="odds odds-left"> 2.00 </span>
                                <img
                                  className="bookmaker-long"
                                  src={
                                    screenWidth > 599
                                      ? LadBrokesLong
                                      : LadBrokesMobile
                                  }
                                  alt="Odds Icon"
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box className="mobileview-teamsport-table-wrap">
                        <Box className="team-match-wrap">
                          <Box className="time-market-team-wrap">
                            <Box className="mobile-temsport-indicator">
                              <span className="sport-indicator-btn">
                                {item?.tag}
                              </span>
                              <Typography className="des-label">
                                {" "}
                                {/* {moment
                                    .utc(item?.startTime)
                                    .local()
                                    .format("hh:mm A")}{" "} */}
                                {item?.time}|{" "}
                                {/* {`${
                                    item?.MarketNumber
                                      ? item?.MarketNumber
                                      : 0
                                  } Markets`} */}
                                <span
                                  className="cursor"
                                  onClick={() => handleMarketPage(item)}
                                >
                                  {item?.market}
                                </span>
                              </Typography>
                            </Box>
                            <Box className="teams-details">
                              <Box className="team-wrap">
                                <span className="team-name">
                                  {item?.homeTeam ? item?.homeTeam : ""}
                                </span>
                              </Box>
                              <Box className="vs"> V/S </Box>
                              <Box className="team-wrap">
                                <span className="team-name">
                                  {" "}
                                  {item?.awayTeam ? item?.awayTeam : ""}{" "}
                                </span>
                              </Box>
                            </Box>
                          </Box>
                          <Typography className="des-label des-label-head">
                            {localesData?.sport?.BEST_HEAD_TO_HEAD}
                          </Typography>
                          <Box className="odds-detail">
                            <Box className="odds-body">
                              <Box>
                                <Box className="odds-label">
                                  {" "}
                                  {localesData?.sport?.WIN}{" "}
                                </Box>
                                <Box className="odds-wrap">
                                  <img
                                    className="bookmaker-long odds-left"
                                    src={
                                      screenWidth > 599
                                        ? Bet365Long
                                        : Bet365Mobile
                                    }
                                    alt="Odds Icon"
                                  />
                                  <span className="odds"> 2.00 </span>
                                </Box>
                              </Box>
                              <Box>
                                <Box className="odds-label">
                                  {" "}
                                  {localesData?.sport?.WIN}{" "}
                                </Box>
                                <Box className="odds-wrap">
                                  <img
                                    className="bookmaker-long odds-left"
                                    src={
                                      screenWidth > 599
                                        ? Bet365Long
                                        : Bet365Mobile
                                    }
                                    alt="Odds Icon"
                                  />
                                  <span className="odds"> 2.00 </span>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box className="des-label-head">
                            <Typography className="des-label sponsored-head ">
                              Sponsored Head to Head
                            </Typography>
                          </Box>
                          <Box className="odds-detail">
                            <Box className="odds-body sponsored-odds-body">
                              <Box>
                                <Box className="odds-label">
                                  {item?.homeTeam ? item?.homeTeam : ""}
                                </Box>
                                <Box className="odds-wrap">
                                  <img
                                    className="bookmaker-long odds-left"
                                    src={
                                      screenWidth > 599
                                        ? Bet365Long
                                        : Bet365Mobile
                                    }
                                    alt="Odds Icon"
                                  />
                                  <span className="odds"> 2.00 </span>
                                </Box>
                              </Box>
                              <Box>
                                <Box className="odds-label">
                                  {item?.awayTeam ? item?.awayTeam : ""}{" "}
                                </Box>
                                <Box className="odds-wrap">
                                  <span className="odds odds-left"> 2.00 </span>
                                  <img
                                    className="bookmaker-long"
                                    src={
                                      screenWidth > 599
                                        ? LadBrokesLong
                                        : LadBrokesMobile
                                    }
                                    alt="Odds Icon"
                                  />
                                </Box>
                              </Box>
                            </Box>
                            <Box className="odds-body sponsored-odds-body">
                              <Box>
                                <Box className="odds-label">
                                  {item?.homeTeam ? item?.homeTeam : ""}
                                </Box>
                                <Box className="odds-wrap">
                                  <img
                                    className="bookmaker-long odds-left"
                                    src={
                                      screenWidth > 599
                                        ? Bet365Long
                                        : Bet365Mobile
                                    }
                                    alt="Odds Icon"
                                  />
                                  <span className="odds"> 2.00 </span>
                                </Box>
                              </Box>
                              <Box>
                                <Box className="odds-label">
                                  {item?.awayTeam ? item?.awayTeam : ""}
                                </Box>
                                <Box className="odds-wrap">
                                  <span className="odds odds-left"> 2.00 </span>
                                  <img
                                    className="bookmaker-long"
                                    src={
                                      screenWidth > 599
                                        ? LadBrokesLong
                                        : LadBrokesMobile
                                    }
                                    alt="Odds Icon"
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </>
              );
            })}
          </>
        ) : (
          <>
            <Box className="teamsports-seeall-tablewrap">
              <TableContainer
                className="table-seeall-container"
                id="individual-team-table"
              >
                <Box className="overlay"></Box>
                <Box className="arrow-wrap">
                  <span
                    className={scrollX == "right" ? "arrow" : "disable"}
                    onClick={() => SlideTable("left")}
                  >
                    <ChevronLeftIcon />
                  </span>

                  <span
                    className={scrollX == "left" ? "arrow" : "disable"}
                    onClick={() => SlideTable("right")}
                  >
                    <ChevronRightIcon />
                  </span>
                </Box>
                <Box className="see-best" onClick={() => handleSeeBest()}>
                  {" "}
                  See Best
                </Box>
                <Table style={{ minWidth: 700 }} className="individual-table">
                  <TableHead className="individual-team-head">
                    <TableRow>
                      <StickyTableCell className={classes.head}>
                        <Box className="select-wrap">
                          <Select
                            className="React oddstype"
                            value={oddsDataOption?.find((item) => {
                              return item?.value === selectedOption;
                            })}
                            onChange={(e) => setSelectedOption(e?.value)}
                            options={oddsDataOption}
                            // isOptionDisabled={option => option.disabled}
                            classNamePrefix="select"
                            isSearchable={false}
                            // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            // menuPortalTarget={document.body}
                            // menuPosition={'fixed'}
                          />
                        </Box>
                      </StickyTableCell>
                      {/* {renderTableCell(Neds)} */}
                      {/* {renderTableCell(LadBrokes)} */}
                      {/* {renderTableCell(BookMaker)} */}
                      {/* {renderTableCell(BetStar)} */}
                      {/* {renderTableCell(Bet365)} */}
                      {renderTableCell(UniBet)}
                      {renderTableCell(BetFair)}
                      {/* {renderTableCell(TopSport)} */}
                      {/* {renderTableCell(PlayUp)} */}
                      {renderTableCell(BlueBet)}
                      {renderTableCell(BoomBet)}
                      {/* {renderTableCell(SouthernCrossBet)} */}
                      <TableCell className={classes.head}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="individual-team-body">
                    <TableRow>
                      <StickyTableCell className="time-market">
                        {/* {moment
                        .utc(individualTeamData?.startTime)
                        .local()
                        .format("hh:mm A")}{" "} */}
                        01:45PM |{" "}
                        {/* {`${
                        individualTeamData?.MarketNumber
                          ? individualTeamData?.MarketNumber
                          : 0
                      } Markets`} */}
                        158 Markets
                      </StickyTableCell>
                    </TableRow>
                    <TableRow className="individual-team-row">
                      <StickyTableCell
                        className={`${classes.cell} body-runner-name`}
                      >
                        <Box className="team-wrap">
                          {/* <span className="team-img-wrap">
                          {fetchTeamlogo(individualTeamData, "hometeam")}
                        </span> */}
                          <span className="team-name">
                            {" "}
                            {/* {individualTeamData?.homeTeam?.name
                            ? individualTeamData?.homeTeam?.name
                            : ""} */}
                            Jessica Pegula v/s
                          </span>
                        </Box>
                      </StickyTableCell>
                      {/* <TableCell className={classes.cell}>
                      <span className="odds">
                        {fetchSeeAllHomeTeamOddsvalue(individualTeamData, 2)}
                      </span>
                    </TableCell> */}
                      {/* <TableCell className={classes.cell}>
                      <span className="odds">
                        {fetchSeeAllHomeTeamOddsvalue(individualTeamData, 3)}
                      </span>
                    </TableCell> */}
                      {/* <TableCell className={classes.cell}>
                      <span className="odds">
                        {fetchSeeAllHomeTeamOddsvalue(individualTeamData, 4)}
                      </span>
                    </TableCell> */}
                      {/* <TableCell className={classes.cell}>
                      <span className="odds">
                        {fetchSeeAllHomeTeamOddsvalue(individualTeamData, 5)}
                      </span>
                    </TableCell> */}
                      {/* <TableCell className={classes.cell}>
                    <span className="odds">
                      {fetchSeeAllHomeTeamOddsvalue(individualTeamData, 6)}
                    </span>
                  </TableCell> */}
                      <TableCell className={classes.cell}>
                        <span className="odds">
                          {/* {fetchSeeAllHomeTeamOddsvalue(individualTeamData, 8)} */}
                          1.5
                        </span>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <span className="odds">
                          {/* {fetchSeeAllHomeTeamOddsvalue(individualTeamData, 10)} */}
                          2.1
                        </span>
                      </TableCell>
                      {/* <TableCell className={classes.cell}>
                      <span className="odds">
                        {fetchSeeAllHomeTeamOddsvalue(individualTeamData, 11)}
                      </span>
                    </TableCell> */}
                      {/* <TableCell className={classes.cell}>
                      <span className="odds">
                        {fetchSeeAllHomeTeamOddsvalue(individualTeamData, 12)}
                      </span>
                    </TableCell> */}
                      <TableCell className={classes.cell}>
                        <span className="odds">
                          {/* {fetchSeeAllHomeTeamOddsvalue(individualTeamData, 13)} */}
                          1.73
                        </span>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <span className="odds">
                          {/* {fetchSeeAllHomeTeamOddsvalue(individualTeamData, 14)} */}
                          2.3
                        </span>
                      </TableCell>
                      {/* <TableCell className={classes.cell}>
                      <span className="odds">
                        {fetchSeeAllHomeTeamOddsvalue(individualTeamData, 15)}
                      </span>
                    </TableCell> */}
                      <TableCell className={classes.cell}></TableCell>
                    </TableRow>
                    <TableRow className="individual-team-row">
                      <StickyTableCell
                        className={`${classes.cell} body-runner-name`}
                      >
                        <Box className="team-wrap">
                          {/* <span className="team-img-wrap">
                          {fetchTeamlogo(individualTeamData, "awayteam")}
                        </span> */}
                          <span className="team-name">
                            {/* {" "}
                          {individualTeamData?.awayTeam?.name
                            ? individualTeamData?.awayTeam?.name
                            : ""} */}
                            Anastasia Potapova
                          </span>
                        </Box>
                      </StickyTableCell>
                      {/* <TableCell className={classes.cell}>
                      <span className="odds">
                        {fetchSeeAllAwayTeamOddsvalue(individualTeamData, 2)}
                      </span>
                    </TableCell> */}
                      {/* <TableCell className={classes.cell}>
                      <span className="odds">
                        {fetchSeeAllAwayTeamOddsvalue(individualTeamData, 3)}
                      </span>
                    </TableCell> */}
                      {/* <TableCell className={classes.cell}>
                      <span className="odds">
                        {fetchSeeAllAwayTeamOddsvalue(individualTeamData, 4)}
                      </span>
                    </TableCell> */}
                      {/* <TableCell className={classes.cell}>
                      <span className="odds">
                        {fetchSeeAllAwayTeamOddsvalue(individualTeamData, 5)}
                      </span>
                    </TableCell> */}
                      {/* <TableCell className={classes.cell}>
                    <span className="odds">
                      {fetchSeeAllAwayTeamOddsvalue(individualTeamData, 6)}
                    </span>
                  </TableCell> */}
                      <TableCell className={classes.cell}>
                        <span className="odds">
                          {/* {fetchSeeAllAwayTeamOddsvalue(individualTeamData, 8)} */}
                          2.5
                        </span>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <span className="odds">
                          {/* {fetchSeeAllAwayTeamOddsvalue(individualTeamData, 10)} */}
                          1.2
                        </span>
                      </TableCell>
                      {/* <TableCell className={classes.cell}>
                      <span className="odds">
                        {fetchSeeAllAwayTeamOddsvalue(individualTeamData, 11)}
                      </span>
                    </TableCell> */}
                      {/* <TableCell className={classes.cell}>
                      <span className="odds">
                        {fetchSeeAllAwayTeamOddsvalue(individualTeamData, 12)}
                      </span>
                    </TableCell> */}
                      <TableCell className={classes.cell}>
                        <span className="odds">
                          {/* {fetchSeeAllAwayTeamOddsvalue(individualTeamData, 13)} */}
                          1.6
                        </span>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <span className="odds">
                          {/* {fetchSeeAllAwayTeamOddsvalue(individualTeamData, 14)} */}
                          NOA
                        </span>
                      </TableCell>
                      {/* <TableCell className={classes.cell}>
                      <span className="odds">
                        {fetchSeeAllAwayTeamOddsvalue(individualTeamData, 15)}
                      </span>
                    </TableCell> */}
                      <TableCell className={classes.cell}></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default AllCombatSportLayoutPage;
