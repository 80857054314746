import React, { useContext, useState } from "react";
import { Box, Breadcrumbs, Typography, Tabs, Tab, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import "./sportMatchups.scss";
import { IntlContext } from "src/App";
import AdvertisingBannner from "../../component/advertisingBanner/index";
import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import { ReactComponent as DatepickerBG } from "src/assets/images/eventcalendar/date-picker-bg.svg";
import moment from "moment-timezone";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker
// } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { parseISO } from "date-fns";
import { Button } from "react-scroll";
import NBAMatchup from "./sportsDetails/nbaMatchup";
import BettingTrends from "./sportsDetails/bettingTrends";
import OurPartner from "../ourPartners";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const day = [
  {
    id: 0,
    name: "Yesterday",
    date: moment
      .tz(moment(), timezone)
      .subtract(1, "days")
      .format("YYYY-MM-DD"),
    dayName: moment.tz(moment(), timezone).subtract(1, "days").format("dddd"),
  },
  {
    id: 1,
    name: "Today",
    date: moment.tz(moment(), timezone).format("YYYY-MM-DD"),
    dayName: moment.tz(moment(), timezone).format("dddd"),
  },
  {
    id: 2,
    name: "Tomorrow",
    date: moment.tz(moment(), timezone).add(1, "days").format("YYYY-MM-DD"),
    dayName: moment.tz(moment(), timezone).add(1, "days").format("dddd"),
  },
  {
    id: 3,
    name: "Wednesday",
    date: moment.tz(moment(), timezone).add(2, "days").format("YYYY-MM-DD"),
    dayName: moment.tz(moment(), timezone).add(2, "days").format("dddd"),
  },
  {
    id: 4,
    name: "Thursday",
    date: moment.tz(moment(), timezone).add(3, "days").format("YYYY-MM-DD"),
    dayName: moment.tz(moment(), timezone).add(3, "days").format("dddd"),
  },
  {
    id: 5,
    name: "Friday",
    date: moment.tz(moment(), timezone).add(4, "days").format("YYYY-MM-DD"),
    dayName: moment.tz(moment(), timezone).add(4, "days").format("dddd"),
  },
  {
    id: 6,
    name: "Saturday",
    date: moment.tz(moment(), timezone).add(5, "days").format("YYYY-MM-DD"),
    dayName: moment.tz(moment(), timezone).add(5, "days").format("dddd"),
  },
];

const sportMatchTab = [
  {
    id: 0,
    name: "NBA MATCHUPS",
  },
  // { id: 1, name: "BETTING TRENDS" }
];

const SportMatchupsPage = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [value, setValue] = useState(1);
  const [sportTabValue, setSportTabValue] = useState(0);
  const [selectedDate, setselectedDate] = useState(
    moment.tz(moment(), timezone).format("YYYY-MM-DD"),
  );
  const [open, setOpen] = useState(false);
  const Today = moment.tz(moment(), timezone).format("YYYY-MM-DD");
  const Tomorrow = moment
    .tz(moment(), timezone)
    .add(1, "days")
    .format("YYYY-MM-DD");
  const yesterDay = moment
    .tz(moment(), timezone)
    .subtract(1, "days")
    .format("YYYY-MM-DD");

  // Change Day Tab
  const handleChange = (event, value) => {
    setValue(value);
    let SelectDate = day?.filter((item) => {
      return item.id === value;
    });
    setselectedDate(
      SelectDate?.map((item) => {
        return item?.date;
      })?.[0],
    );
  };

  const handleSportMatchChange = (event, value) => {
    setSportTabValue(value);
  };

  const handleDateChange = (date) => {
    setselectedDate(date ? moment(date).format("YYYY-MM-DD") : null);
  };

  //   const fetchAdsData = async () => {
  //     try {
  //       let pageId =
  //         teamSportType === "cricket"
  //           ? 6
  //           : teamSportType === "rugbyleague"
  //           ? 7
  //           : teamSportType === "rugbyunion"
  //           ? 8
  //           : "";
  //       const { status, data } = await axiosInstance.get(
  //         `getAllAds?page_id=${pageId}`
  //       );
  //       if (status === 200) {
  //         setAdData(data?.data?.[0]?.pageAdLists);
  //         let ids = [];
  //         let listAds = data?.data?.[0]?.pageAdLists?.map(item => {
  //           ids.push(item?.id);
  //         });
  //         handleAdsImression(ids);
  //       }
  //     } catch (err) {}
  //   };
  //   const fetchAds = (position, height, placeholder) => {
  //     if (AdData?.length > 0) {
  //       let ad = AdData?.filter(item => {
  //         return item?.position_id === position;
  //       });
  //       if (ad?.length > 0) {
  //         return (
  //           <AdBannner
  //             placeholder={placeholder}
  //             addetails={ad?.[0]}
  //             height={height}
  //           />
  //         );
  //       } else {
  //         return (
  //           <AdBannner placeholder={placeholder} addetails={[]} height={height} />
  //         );
  //       }
  //     } else {
  //       return (
  //         <AdBannner placeholder={placeholder} addetails={[]} height={height} />
  //       );
  //     }
  //   };
  const clearDate = () => {
    setselectedDate(null);
    setOpen(false);
  };
  return (
    <>
      <Box className="content-wrap">
        <AdvertisingBannner src={banner} />
        {/* {fetchAds(1, "218px", banner)} */}
        <Box className="page-title-wrap">
          <Box className="layout-header">
            <Box className="page-bredcrumn-wrap">
              <Box className="bredcrumn-wrap">
                <Breadcrumbs
                  separator="/"
                  aria-label="breadcrumb"
                  className="breadcrumb"
                >
                  <Link underline="hover" color="inherit" to="/">
                    {localesData?.MENU?.HOME}
                  </Link>
                  <Link underline="hover" color="inherit" to="">
                    {localesData?.MENU?.SPORTS}
                  </Link>
                  <Link underline="hover" color="inherit" to="">
                    Basketball
                  </Link>
                  <Typography>NBA MATCHUPS & BETTING TRENDS</Typography>
                </Breadcrumbs>
              </Box>
            </Box>
            <Typography variant="h1">NBA MATCHUPS & BETTING TRENDS</Typography>
          </Box>
        </Box>
        <Box className="sport-tab">
          <Tabs
            value={value}
            variant="scrollable"
            scrollButtons={false}
            indicatorColor="primary"
            textColor="primary"
            className="sport-tab-detail"
          >
            {day?.map((item, index) => {
              return (
                <Box>
                  <Tab
                    disableRipple
                    disableFocusRipple
                    label={
                      item?.date == Today
                        ? "Today"
                        : item?.date == Tomorrow
                          ? "Tomorrow"
                          : item?.date == yesterDay
                            ? "Yesterday"
                            : item?.dayName
                    }
                    value={item?.id}
                    className={item?.id == value ? "active" : ""}
                    onChange={(event, newValue) =>
                      handleChange(event, item?.id)
                    }
                  />
                </Box>
              );
            })}
          </Tabs>
          <Box className="sport-match-filter">
            <Box className="sport-match-btn">
              <Tabs
                value={sportTabValue}
                variant="scrollable"
                scrollButtons={false}
                indicatorColor="primary"
                textColor="primary"
                className="sport-tab-detail"
              >
                {sportMatchTab?.map((item, index) => {
                  return (
                    <Box>
                      <Tab
                        disableRipple
                        disableFocusRipple
                        label={item?.name}
                        value={item?.id}
                        className={item?.id == sportTabValue ? "active" : ""}
                        onChange={(event, newValue) =>
                          handleSportMatchChange(event, item?.id)
                        }
                      />
                    </Box>
                  );
                })}
              </Tabs>
              <Box className="Filteritemlist-datepicker">
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container style={{ justifyContent: "end" }}>
                  <KeyboardDatePicker
                    autoOk
                    // disableToolbar
                    // variant="inline"
                    format="dd/MM/yyyy"
                    placeholder="DD/MM//YYYY"
                    margin="normal"
                    id="date-picker-inline"
                    inputVariant="outlined"
                    value={selectedDate ? parseISO(selectedDate) : null}
                    onChange={e => handleDateChange(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    onClear={clearDate}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    views={["year", "month", "date"]}
                    disableFuture={value == 8}
                    disablePast={value == 7}
                    // style={{ marginRight: 5 }}
                    keyboardIcon={
                      <DatepickerBG className="keyboard-icon-bg" />
                    }
                    className="details-search-picker"
                  />
                </Grid>
              </MuiPickersUtilsProvider> */}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="sport-tab-details">
          {sportTabValue === 0 ? (
            <NBAMatchup />
          ) : sportTabValue === 1 ? (
            <BettingTrends />
          ) : (
            ""
          )}
        </Box>
        {/* {fetchAds(4, "102px", AboveOurPartner)} */}
        <OurPartner />
        {/* {fetchAds(5, "102px", BelowOurPartner)} */}
      </Box>
    </>
  );
};

export default SportMatchupsPage;
