import React from "react";
import Page from "src/components/Page";
import RankingsPage from "src/views/component/rankings";

const Rankings = () => {
  return (
    <Page title="Rankings">
      <RankingsPage />
    </Page>
  );
};

export default Rankings;
