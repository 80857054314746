import React from "react";
import { Link } from "react-router-dom";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import PlanDetails from "./planDetails";
import MembershipFromDetails from "./membershipForm";
import OurPartner from "../ourPartners";
import "./membership.scss";

const MembershipPage = ({ profileModal }) => {
  return (
    <>
      <Box className="content-wrap">
        {profileModal !== "profile-modal" && (
          <Box className="membership-data-header">
            <Box className="bredcrumn-details">
              <Box className="bredcrumn-wrap">
                <Breadcrumbs
                  separator="/"
                  aria-label="breadcrumb"
                  className="breadcrumb"
                >
                  <Link underline="hover" color="inherit" to="/">
                    Home
                  </Link>
                  <Typography>Become a member</Typography>
                </Breadcrumbs>
              </Box>
            </Box>
            <Typography variant="h1">Become a member</Typography>
          </Box>
        )}
        <Box
          className={
            profileModal === "profile-modal"
              ? "profile-membership-page-wrap membership-page-wrap"
              : "membership-page-wrap"
          }
        >
          <PlanDetails />
          <MembershipFromDetails />
        </Box>
        {profileModal !== "profile-modal" && <OurPartner />}
      </Box>
    </>
  );
};

export default MembershipPage;
