import React from "react";
import Page from "src/components/Page";
import PurchasedTipsPage from "src/views/component/TipsMarketplace/purchasedTips";

const PurchasedTips = () => {
  return (
    <Page title="Purchased Tips">
      <PurchasedTipsPage />
    </Page>
  );
};

export default PurchasedTips;
