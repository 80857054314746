import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { IntlContext } from "src/App";
import { Config } from "../../../../../helpers/context/config";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import { useParams } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import Loader from "src/components/Loader";
import NoDataComp from "src/views/component/UI/NoData";

const SportinfoData = [
  {
    id: 1,
    name: "Event",
    nameInfo: "Brisbane Lions VS Melbourne"
  },
  {
    id: 2,
    name: "Date/Time",
    nameInfo: "June 23 @ 07:50 PM - 10:00 PM"
  },
  {
    id: 3,
    name: "Venue",
    nameInfo: "Venue - Sunshine Coast Stadium (Australia)"
  }
];

const InfoFixture = ({ fixtureInfoData, singleMatchData }) => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [matchInfo, setMatchInfo] = useState({});
  const [isInfoLoading, setIsInfoLoading] = useState(true);
  useEffect(() => {
    fetchMatchInfo();
  }, [params]);

  const fetchMatchInfo = async () => {
    setIsInfoLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `sports/statistics/soccer/event/info/${params?.id}`
      );
      if (status === 200) {
        setIsInfoLoading(false);
        setMatchInfo(data?.result);
      } else {
        setIsInfoLoading(false);
      }
    } catch (err) {
      setIsInfoLoading(false);
    }
  };
  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Brisbane} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Brisbane} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Melbourne} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Melbourne} alt="Odds Icon" />;
      }
    }
  };
  return (
    <>
      {isInfoLoading ? (
        <div className="allsport-loader-center">
          <Loader />
        </div>
      ) : (
        <>
          <Box className="fixture-wrap">
            <Typography variant="h4" className="fixture-heading">
              {localesData?.FIXTURE_INFO?.MATCH_INFO}
            </Typography>
            <Box className="d-flex match-info-wrap">
              <Typography className="match-info-text bold w-15">
                Date/Time
              </Typography>
              <span className="match-info-text">-</span>
              <Typography className="match-info-text light w-60">
                {moment(matchInfo?.startTime).format("DD MMMM YYYY")}
              </Typography>
            </Box>
            <Box className="d-flex match-info-wrap">
              <Typography className="match-info-text bold w-15">
                Referee
              </Typography>
              <span className="match-info-text">-</span>
              <Typography className="match-info-text light w-60">
                {matchInfo?.SoccerEventReferees?.length > 0
                  ? matchInfo?.SoccerEventReferees?.[0]?.SoccerReferee?.Nm +
                    (matchInfo?.SoccerEventReferees?.[0]?.SoccerReferee?.Country
                      ?.country
                      ? " (" +
                        matchInfo?.SoccerEventReferees?.[0]?.SoccerReferee
                          ?.Country?.country +
                        ")"
                      : "")
                  : "-"}
              </Typography>
            </Box>
            <Box className="d-flex match-info-wrap">
              <Typography className="match-info-text bold w-15">
                Venue
              </Typography>
              <span className="match-info-text">-</span>
              <Typography className="match-info-text light w-60">
                {matchInfo?.SoccerStadium?.name
                  ? matchInfo?.SoccerStadium?.name
                  : "-"}
              </Typography>
            </Box>
            {matchInfo?.Vsp && (
              <Box className="d-flex match-info-wrap">
                <Typography className="match-info-text bold w-15">
                  Crowd
                </Typography>
                <span className="match-info-text">-</span>
                <Typography className="match-info-text light w-60">
                  {matchInfo?.Vsp}
                </Typography>
              </Box>
            )}
          </Box>
          {/* <Box className="fixture-wrap">
        <Typography variant="h4" className="fixture-heading">
          {localesData?.FIXTURE_INFO?.WINNING_PROBABILITY}
        </Typography>
        <Box className="winning-probability-wrap">
          <Box className="d-flex align-center team-wrap">
            <span className="team-img-wrap">
              {fetchTeamlogo(fixtureInfoData, "hometeam")}
            </span>
            <span className="team-name medium">
              {fixtureInfoData?.homeTeam?.name
                ? fixtureInfoData?.homeTeam?.name
                : ""}
            </span>
          </Box>
          <Box>
            <Box className="d-flex align-center win-value">
              <span
                style={{
                  width: "70%",
                  color: "#003764"
                }}
                className="value-text"
              >
                70%
              </span>
              <span
                style={{
                  width: "30%",
                  color: "#5D5D5D"
                }}
                className="value-text"
              >
                30%
              </span>
            </Box>
            <Box className="win-progress d-flex align-center">
              <span
                style={{
                  width: "70%",
                  backgroundColor: "#003764",
                  borderRadius: "4px"
                }}
              ></span>
              <span
                style={{
                  width: "30%"
                }}
              ></span>
            </Box>
          </Box>
          <Box className="d-flex align-center team-wrap">
            <span className="team-img-wrap">
              {fetchTeamlogo(fixtureInfoData, "awayteam")}
            </span>
            <span className="team-name medium">
              {fixtureInfoData?.awayTeam?.name
                ? fixtureInfoData?.awayTeam?.name
                : ""}
            </span>
          </Box>
        </Box>
      </Box> */}
        </>
      )}
    </>
  );
};

export default InfoFixture;
