import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";

const TableContent = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
  {
    id: 4
  },
  {
    id: 5
  }
];

const StickyTableCell = withStyles(theme => ({
  head: {
    right: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    // minWidth: "20px",
    // boxShadow: "inset 1px 0px 6px 0px #ebebeb",
    "@media(max-width: 1280px)": {
      maxWidth: "20px",
      boxShadow: "inset 1px 0px 6px 0px #ebebeb"
    }
  },
  body: {
    right: 0,
    position: "sticky",
    background: "#FFFFFF",
    boxShadow: "inset 1px 0px 6px 0px #ebebeb",
    "@media(max-width: 1280px)": {
      boxShadow: "inset 1px 0px 6px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const PlayerSummaryDetails = ({ playerData, playerDetailsLoader }) => {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const SeasonId = searchParams.get("seasonId");
  const [loader, setLoader] = useState(false);
  const [playerSummaryTeam, setPlayerSummaryTeam] = useState([]);

  const handleNavigate = data => {
    const stateToPass = {
      ListDataPage: data?.id,
      listPage: true
      // Add your state data here
    };
    navigate(
      `/sport/statistics/${params?.sportId}/${
        params?.tournamentId
      }/league-data/${params?.teamId}/team-data?seasonId=${
        SeasonId ? SeasonId : ""
      }`,
      {
        state: stateToPass
      }
    );
  };

  useEffect(() => {
    if (playerData?.length > 0) {
      const tournamentIds = playerData?.map(item => ({
        id: item?.id,
        name: item?.name
      }));
      fetchPlayerSummaryDetailsByTeam(tournamentIds);
    }
  }, [params, playerData, SeasonId]);

  const fetchPlayerSummaryDetailsByTeam = async tournamentIds => {
    setLoader(true);
    try {
      const requests = tournamentIds?.map(async tournamentId => {
        const { data } = await axiosInstance.get(
          `/sports/statistics/player/summary/team/${params?.playerId}?SportId=${
            params?.sportId
          }&tournamentId=${tournamentId?.id}&seasonId=${
            SeasonId ? SeasonId : ""
          }`
        );
        return {
          id: tournamentId?.id,
          name: tournamentId?.name,
          result: data?.result
        };
      });
      const results = await Promise.all(requests);
      setLoader(false);
      setPlayerSummaryTeam(results);
    } catch (err) {
      setLoader(false);
    }
  };
  return (
    <>
      <Box className="teamsport-data-accordian league-data-details mb-18">
        <Box className="teamsport-section-details">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Playing Career Statistics</Typography>
            </AccordionSummary>
            <AccordionDetails className="team-accordion-details">
              <Box className="short-name-details">
                <Typography className="short-name">
                  App - <span>Starting Appearances</span>, T -{" "}
                  <span>Try/Penalty Goal</span>, GK% - , FG -{" "}
                  <span>Field Goal/Drop Goal</span>, Pts - <span>Points</span>,
                  L - <span>Loss</span>, W - <span>Win</span>, D -{" "}
                  <span>Draw</span>
                </Typography>
              </Box>
              <Box className="teamsport-header-details">
                <Typography className="heading-title">Competitions</Typography>
              </Box>
              {playerDetailsLoader && (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              )}
              {!playerDetailsLoader && (
                <>
                  <Typography className="note" variant="p">
                    To view more info on a match, click the <span>List</span>{" "}
                    button.
                  </Typography>
                  <Box className="table-overlay">
                    {Number(params?.sportId) === 4 ? (
                      <TableContainer
                        id="details-table"
                        className="teamsport-data-table team-sport-tables player-data-table"
                      >
                        {/* <Box className="overlay"></Box> */}
                        <Table
                          className="data-table"
                          style={{ minWidth: "max-content" }}
                        >
                          <TableHead className="table-sub-header">
                            <TableRow className="table-sub-head-row">
                              <TableCell
                                className={`${classes.head} sticky-head`}
                              ></TableCell>
                              <TableCell className={classes.head} colSpan={13}>
                                Batting Summary
                              </TableCell>
                              <TableCell className={classes.head} colSpan={12}>
                                Bowling Summary
                              </TableCell>
                              <StickyTableCell
                                className={classes.head}
                              ></StickyTableCell>
                            </TableRow>
                          </TableHead>
                          <TableHead className="table-header">
                            <TableRow className="table-head-row ">
                              <TableCell
                                className={`${classes.head} first-cell border-right`}
                              >
                                Competition
                              </TableCell>
                              <TableCell className={classes.head}>M</TableCell>
                              <TableCell className={classes.head}>
                                Inn
                              </TableCell>
                              <TableCell className={classes.head}>No</TableCell>
                              <TableCell className={classes.head}>
                                Runs
                              </TableCell>
                              <TableCell className={classes.head}>
                                HSS
                              </TableCell>
                              <TableCell className={classes.head}>
                                Avg
                              </TableCell>
                              <TableCell className={classes.head}>BF</TableCell>
                              <TableCell className={classes.head}>SR</TableCell>
                              <TableCell className={classes.head}>
                                100
                              </TableCell>
                              <TableCell className={classes.head}>
                                200
                              </TableCell>
                              <TableCell className={classes.head}>50</TableCell>
                              <TableCell className={classes.head}>4s</TableCell>
                              <TableCell
                                className={`${classes.head} border-right`}
                              >
                                6s
                              </TableCell>
                              <TableCell className={classes.head}>M</TableCell>
                              <TableCell className={classes.head}>
                                Inn
                              </TableCell>
                              <TableCell className={classes.head}>B</TableCell>
                              <TableCell className={classes.head}>
                                Runs
                              </TableCell>
                              <TableCell className={classes.head}>
                                Wkts
                              </TableCell>
                              <TableCell className={classes.head}>
                                BBI
                              </TableCell>
                              <TableCell className={classes.head}>
                                BBM
                              </TableCell>
                              <TableCell className={classes.head}>
                                Econ
                              </TableCell>
                              <TableCell className={classes.head}>
                                Avg
                              </TableCell>
                              <TableCell className={classes.head}>SR</TableCell>
                              <TableCell className={classes.head}>5W</TableCell>
                              <TableCell className={classes.head}>
                                10W
                              </TableCell>
                              <StickyTableCell
                                className={classes.head}
                              ></StickyTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="table-body">
                            {playerData?.length > 0 ? (
                              <>
                                {playerData?.map((item, i) => {
                                  return (
                                    <>
                                      <TableRow
                                        key={i}
                                        className="table-body-row "
                                      >
                                        <TableCell
                                          className={`${classes.cell} first-cell light-blue cursor-pointer border-right`}
                                          onClick={() =>
                                            navigate(
                                              `/sport/statistics/${
                                                params?.sportId
                                              }/${
                                                params?.tournamentId
                                              }/league-data?seasonId=${
                                                SeasonId ? SeasonId : ""
                                              }`
                                            )
                                          }
                                        >
                                          {item?.name}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.battingLine?.match ||
                                          item?.battingLine?.match === 0
                                            ? item?.battingLine?.match
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.battingLine?.inning ||
                                          item?.battingLine?.inning === 0
                                            ? item?.battingLine?.inning
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.battingLine?.notOut ||
                                          item?.battingLine?.notOut === 0
                                            ? item?.battingLine?.notOut
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.battingLine?.runs ||
                                          item?.battingLine?.runs === 0
                                            ? item?.battingLine?.runs
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.battingLine?.HS ||
                                          item?.battingLine?.HS === 0
                                            ? item?.battingLine?.HS
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.battingLine?.AVG
                                            ? item?.battingLine?.AVG.toFixed(
                                                2
                                              ) + "%"
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.battingLine?.BF ||
                                          item?.battingLine?.BF === 0
                                            ? item?.battingLine?.BF
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.battingLine?.SR
                                            ? item?.battingLine?.SR.toFixed(2)
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.battingLine?.century ||
                                          item?.battingLine?.century === 0
                                            ? item?.battingLine?.century
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.battingLine?.two_century ||
                                          item?.battingLine?.two_century === 0
                                            ? item?.battingLine?.two_century
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.battingLine?.half_century ||
                                          item?.battingLine?.half_century === 0
                                            ? item?.battingLine?.half_century
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.battingLine?.four ||
                                          item?.battingLine?.four === 0
                                            ? item?.battingLine?.four
                                            : "-"}
                                        </TableCell>
                                        <TableCell
                                          className={`${classes.cell} border-right`}
                                        >
                                          {item?.battingLine?.six ||
                                          item?.battingLine?.six === 0
                                            ? item?.battingLine?.six
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.match ||
                                          item?.bowlingLine?.match === 0
                                            ? item?.bowlingLine?.match
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.inning ||
                                          item?.bowlingLine?.inning === 0
                                            ? item?.bowlingLine?.inning
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.B ||
                                          item?.bowlingLine?.B === 0
                                            ? item?.bowlingLine?.B
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.runs ||
                                          item?.bowlingLine?.runs === 0
                                            ? item?.bowlingLine?.runs
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.wkts ||
                                          item?.bowlingLine?.wkts === 0
                                            ? item?.bowlingLine?.wkts
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.BBI
                                            ? item?.bowlingLine?.BBI
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.BBM
                                            ? item?.bowlingLine?.BBM
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.econ
                                            ? item?.bowlingLine?.econ.toFixed(2)
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.AVG ||
                                          item?.bowlingLine?.AVG === 0
                                            ? item?.bowlingLine?.AVG.toFixed(2)
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.SR ||
                                          item?.bowlingLine?.SR === 0
                                            ? item?.bowlingLine?.SR.toFixed(2)
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.five_wicket ||
                                          item?.bowlingLine?.five_wicket === 0
                                            ? item?.bowlingLine?.five_wicket
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.ten_wicket ||
                                          item?.bowlingLine?.ten_wicket === 0
                                            ? item?.bowlingLine?.ten_wicket
                                            : "-"}
                                        </TableCell>
                                        <StickyTableCell
                                          className={classes.cell}
                                        >
                                          <span
                                            className="view-btn cursor-pointer"
                                            onClick={() => handleNavigate(item)}
                                          >
                                            List
                                          </span>
                                        </StickyTableCell>
                                      </TableRow>
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <TableRow>
                                <TableCell colSpan={12} className="no-data-td">
                                  No Data Available
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <TableContainer
                        id="details-table"
                        className="teamsport-data-table team-sport-tables player-data-table"
                      >
                        {/* <Box className="overlay"></Box> */}
                        <Table className="data-table" style={{ minWidth: 940 }}>
                          <TableHead className="table-header">
                            <TableRow className="table-head-row ">
                              <TableCell
                                className={`${classes.head} first-cell border-right`}
                              >
                                Competition
                              </TableCell>
                              <TableCell className={classes.head}>
                                App
                              </TableCell>
                              <TableCell className={classes.head}>T</TableCell>
                              <TableCell className={classes.head}>G</TableCell>
                              <TableCell className={classes.head}>
                                GK%
                              </TableCell>
                              <TableCell className={classes.head}>FG</TableCell>
                              <TableCell className={classes.head}>
                                Pts
                              </TableCell>
                              <TableCell className={classes.head}>W</TableCell>
                              <TableCell className={classes.head}>L</TableCell>
                              <TableCell className={classes.head}>D</TableCell>
                              <TableCell className={classes.head}>
                                Win%
                              </TableCell>
                              <StickyTableCell
                                className={classes.head}
                              ></StickyTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="table-body">
                            {playerData?.length > 0 ? (
                              <>
                                {playerData?.map((item, i) => {
                                  return (
                                    <>
                                      <TableRow
                                        key={i}
                                        className="table-body-row "
                                      >
                                        <TableCell
                                          className={`${classes.cell} first-cell light-blue cursor-pointer border-right`}
                                          onClick={() =>
                                            navigate(
                                              `/sport/statistics/${
                                                params?.sportId
                                              }/${
                                                params?.tournamentId
                                              }/league-data?seasonId=${
                                                SeasonId ? SeasonId : ""
                                              }`
                                            )
                                          }
                                        >
                                          {item?.name}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.app || item?.app === 0
                                            ? item?.app
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.T || item?.T === 0
                                            ? item?.T
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.G || item?.G === 0
                                            ? item?.G
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.GK || item?.GK === 0
                                            ? item?.GK + "%"
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.FG || item?.FG === 0
                                            ? item?.FG
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.Pts || item?.Pts === 0
                                            ? item?.Pts
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.W || item?.W === 0
                                            ? item?.W
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.L || item?.L === 0
                                            ? item?.L
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.D || item?.D === 0
                                            ? item?.D
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.win || item?.win === 0
                                            ? item?.win
                                            : "-"}
                                        </TableCell>
                                        <StickyTableCell
                                          className={classes.cell}
                                        >
                                          <span
                                            className="view-btn cursor-pointer"
                                            onClick={() => handleNavigate(item)}
                                          >
                                            List
                                          </span>
                                        </StickyTableCell>
                                      </TableRow>
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <TableRow>
                                <TableCell colSpan={12} className="no-data-td">
                                  No Data Available
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Box>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Box className="teamsport-data-accordian league-data-details">
        <Box className="teamsport-section-details">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Club Career</Typography>
            </AccordionSummary>
            <AccordionDetails className="team-accordion-details">
              <Box className="short-name-details">
                <Typography className="short-name">
                  App - <span>Starting Appearances</span>, T -{" "}
                  <span>Try/Penalty Goal</span>, GK% - , FG -{" "}
                  <span>Field Goal/Drop Goal</span>, Pts - <span>Points</span>,
                  L - <span>Loss</span>, W -<span>Win</span>, D -{" "}
                  <span>Draw</span>
                </Typography>
              </Box>

              {/* NRL/NSWRL - By Year */}

              {/* <Box className="teamsport-header-details">
                <Typography className="heading-title">
                  NRL/NSWRL - By Year
                </Typography>
              </Box>
              <Typography className="note" variant="p">
                To view more info on a match, click the <span>List</span>{" "}
                button.
              </Typography>
              <Box className="table-overlay">
                <TableContainer
                  id="details-table"
                  className="teamsport-data-table team-sport-tables player-career-table"
                >
                  {/* <Box className="overlay"></Box> /}
                  <Table className="data-table" style={{ minWidth: 940 }}>
                    <TableHead className="table-header">
                      <TableRow className="table-head-row ">
                        <TableCell className={`${classes.head} first-cell`}>
                          Team
                        </TableCell>
                        <TableCell className={`${classes.head} border-right`}>
                          Season
                        </TableCell>
                        <TableCell className={classes.head}>Age</TableCell>
                        <TableCell className={classes.head}>T</TableCell>
                        <TableCell className={classes.head}>G</TableCell>
                        <TableCell className={classes.head}>GK%</TableCell>
                        <TableCell className={classes.head}>FG</TableCell>
                        <TableCell className={classes.head}>Pts</TableCell>
                        <TableCell className={classes.head}>W</TableCell>
                        <TableCell className={classes.head}>L</TableCell>
                        <TableCell className={classes.head}>D</TableCell>
                        <TableCell className={classes.head}>Win%</TableCell>
                        <StickyTableCell
                          className={classes.head}
                        ></StickyTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                      {TableContent?.length > 0 ? (
                        <>
                          {TableContent?.map((item, i) => {
                            return (
                              <>
                                <TableRow key={i} className="table-body-row ">
                                  <TableCell
                                    className={`${classes.cell} first-cell light-blue cursor-pointer`}
                                  >
                                    Gold Coast
                                  </TableCell>
                                  <TableCell
                                    className={`${classes.cell} light-blue cursor-pointer border-right`}
                                  >
                                    NRL 2019
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    3
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    1
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    0/1
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    0.00%
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    -
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    2
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    2
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    2
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    2
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    16.67%
                                  </TableCell>
                                  <StickyTableCell className={classes.cell}>
                                    <span className="view-btn cursor-pointer">
                                      List
                                    </span>
                                  </StickyTableCell>
                                </TableRow>
                              </>
                            );
                          })}
                          <TableRow className="table-body-row table-result-row">
                            <TableCell className={`${classes.cell} first-cell`}>
                              Overall
                            </TableCell>
                            <TableCell
                              className={`${classes.cell} border-right`}
                            >
                              2019-2023
                            </TableCell>
                            <TableCell className={classes.cell}>0</TableCell>
                            <TableCell className={classes.cell}>4</TableCell>
                            <TableCell className={classes.cell}>2/4</TableCell>
                            <TableCell className={classes.cell}>
                              50.00%
                            </TableCell>
                            <TableCell className={classes.cell}>0</TableCell>
                            <TableCell className={classes.cell}>
                              [total]
                            </TableCell>
                            <TableCell className={classes.cell}>
                              [total]
                            </TableCell>
                            <TableCell className={classes.cell}>
                              [total]
                            </TableCell>
                            <TableCell className={classes.cell}>
                              [total]
                            </TableCell>
                            <TableCell className={classes.cell}>
                              [total]
                            </TableCell>
                            <StickyTableCell className={classes.cell}>
                              {" "}
                              <span className="view-btn cursor-pointer">
                                List
                              </span>
                            </StickyTableCell>
                          </TableRow>
                        </>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12} className="no-data-td">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box> */}

              {/* NRL/NSWRL - By Team */}

              {loader && (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              )}
              {!loader && playerSummaryTeam?.length > 0 ? (
                playerSummaryTeam?.map((item, index) => {
                  return (
                    <>
                      <Box key={index}>
                        <Box className="teamsport-header-details">
                          <Typography className="heading-title">
                            {item?.name} - By Team
                          </Typography>
                        </Box>

                        <Box className="table-overlay">
                          {Number(params?.sportId) === 4 ? (
                            <TableContainer
                              id="details-table"
                              className="teamsport-data-table team-sport-tables player-career-table"
                            >
                              {/* <Box className="overlay"></Box> */}
                              <Table
                                className="data-table"
                                style={{ minWidth: "max-content" }}
                              >
                                <TableHead className="table-sub-header">
                                  <TableRow className="table-sub-head-row">
                                    <TableCell
                                      className={`${classes.head} sticky-head`}
                                      colSpan={2}
                                    ></TableCell>
                                    <TableCell
                                      className={classes.head}
                                      colSpan={13}
                                    >
                                      Batting Summary
                                    </TableCell>
                                    <TableCell
                                      className={classes.head}
                                      colSpan={12}
                                    >
                                      Bowling Summary
                                    </TableCell>
                                    <StickyTableCell
                                      className={classes.head}
                                    ></StickyTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableHead className="table-header">
                                  <TableRow className="table-head-row ">
                                    <TableCell
                                      className={`${classes.head} first-cell`}
                                    >
                                      Team
                                    </TableCell>
                                    <TableCell
                                      className={`${classes.head} border-right`}
                                    >
                                      Years
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      M
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      Inn
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      No
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      Runs
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      HSS
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      Avg
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      BF
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      SR
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      100
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      200
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      50
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      4s
                                    </TableCell>
                                    <TableCell
                                      className={`${classes.head} border-right`}
                                    >
                                      6s
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      M
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      Inn
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      B
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      Runs
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      Wkts
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      BBI
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      BBM
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      Econ
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      Avg
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      SR
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      5W
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      10W
                                    </TableCell>
                                    <StickyTableCell
                                      className={classes.head}
                                    ></StickyTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody className="table-body">
                                  {item?.result?.teamList?.length > 0 ? (
                                    <>
                                      {item?.result?.teamList?.map((obj, i) => {
                                        return (
                                          <>
                                            <TableRow
                                              key={i}
                                              className="table-body-row "
                                            >
                                              <TableCell
                                                className={`${classes.cell} first-cell light-blue cursor-pointer`}
                                                onClick={() =>
                                                  navigate(
                                                    `/sport/statistics/${
                                                      params?.sportId
                                                    }/${
                                                      params?.tournamentId
                                                    }/league-data/${
                                                      obj?.teamId
                                                    }/team-data?seasonId=${
                                                      SeasonId ? SeasonId : ""
                                                    }`
                                                  )
                                                }
                                              >
                                                {obj?.name}
                                              </TableCell>
                                              <TableCell
                                                className={`${classes.cell} light-blue cursor-pointer border-right`}
                                              >
                                                {obj?.year}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.battingLine?.match ||
                                                obj?.battingLine?.match === 0
                                                  ? obj?.battingLine?.match
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {" "}
                                                {obj?.battingLine?.inning ||
                                                obj?.battingLine?.inning === 0
                                                  ? obj?.battingLine?.inning
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.battingLine?.notOut ||
                                                obj?.battingLine?.notOut === 0
                                                  ? obj?.battingLine?.notOut
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.battingLine?.runs ||
                                                obj?.battingLine?.runs === 0
                                                  ? obj?.battingLine?.runs
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {" "}
                                                {obj?.battingLine?.HS ||
                                                obj?.battingLine?.HS === 0
                                                  ? obj?.battingLine?.HS
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {" "}
                                                {obj?.battingLine?.AVG
                                                  ? obj?.battingLine?.AVG.toFixed(
                                                      2
                                                    ) + "%"
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {" "}
                                                {obj?.battingLine?.BF ||
                                                obj?.battingLine?.BF === 0
                                                  ? obj?.battingLine?.BF
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {" "}
                                                {obj?.battingLine?.SR
                                                  ? obj?.battingLine?.SR.toFixed(
                                                      2
                                                    )
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {" "}
                                                {obj?.battingLine?.century ||
                                                obj?.battingLine?.century === 0
                                                  ? obj?.battingLine?.century
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {" "}
                                                {obj?.battingLine
                                                  ?.two_century ||
                                                obj?.battingLine
                                                  ?.two_century === 0
                                                  ? obj?.battingLine
                                                      ?.two_century
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.battingLine
                                                  ?.half_century ||
                                                obj?.battingLine
                                                  ?.half_century === 0
                                                  ? obj?.battingLine
                                                      ?.half_century
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {" "}
                                                {obj?.battingLine?.four ||
                                                obj?.battingLine?.four === 0
                                                  ? obj?.battingLine?.four
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={`${classes.cell} border-right`}
                                              >
                                                {obj?.battingLine?.six ||
                                                obj?.battingLine?.six === 0
                                                  ? obj?.battingLine?.six
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.bowlingLine?.match ||
                                                obj?.bowlingLine?.match === 0
                                                  ? obj?.bowlingLine?.match
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.bowlingLine?.inning ||
                                                obj?.bowlingLine?.inning === 0
                                                  ? obj?.bowlingLine?.inning
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.bowlingLine?.B ||
                                                obj?.bowlingLine?.B === 0
                                                  ? obj?.bowlingLine?.B
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.bowlingLine?.runs ||
                                                obj?.bowlingLine?.runs === 0
                                                  ? obj?.bowlingLine?.runs
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.bowlingLine?.wkts ||
                                                obj?.bowlingLine?.wkts === 0
                                                  ? obj?.bowlingLine?.wkts
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.bowlingLine?.BBI
                                                  ? obj?.bowlingLine?.BBI
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.bowlingLine?.BBM
                                                  ? obj?.bowlingLine?.BBM
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.bowlingLine?.econ
                                                  ? obj?.bowlingLine?.econ.toFixed(
                                                      2
                                                    )
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.bowlingLine?.AVG ||
                                                obj?.bowlingLine?.AVG === 0
                                                  ? obj?.bowlingLine?.AVG.toFixed(
                                                      2
                                                    )
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.bowlingLine?.SR ||
                                                obj?.bowlingLine?.SR === 0
                                                  ? obj?.bowlingLine?.SR.toFixed(
                                                      2
                                                    )
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.bowlingLine
                                                  ?.five_wicket ||
                                                obj?.bowlingLine
                                                  ?.five_wicket === 0
                                                  ? obj?.bowlingLine
                                                      ?.five_wicket
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.bowlingLine?.ten_wicket ||
                                                obj?.bowlingLine?.ten_wicket ===
                                                  0
                                                  ? obj?.bowlingLine?.ten_wicket
                                                  : "-"}
                                              </TableCell>
                                              <StickyTableCell
                                                className={classes.cell}
                                              >
                                                <span className="view-btn cursor-pointer">
                                                  List
                                                </span>
                                              </StickyTableCell>
                                            </TableRow>
                                          </>
                                        );
                                      })}
                                      <TableRow className="table-body-row table-result-row">
                                        <TableCell
                                          className={`${classes.cell} first-cell`}
                                        >
                                          {item?.result?.overAll?.name}
                                        </TableCell>
                                        <TableCell
                                          className={`${classes.cell} border-right`}
                                        >
                                          {item?.result?.overAll?.year}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.battingLine
                                            ?.match ||
                                          item?.result?.overAll?.battingLine
                                            ?.match === 0
                                            ? item?.result?.overAll?.battingLine
                                                ?.match
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.result?.overAll?.battingLine
                                            ?.inning ||
                                          item?.result?.overAll?.battingLine
                                            ?.inning === 0
                                            ? item?.result?.overAll?.battingLine
                                                ?.inning
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.battingLine
                                            ?.notOut ||
                                          item?.result?.overAll?.battingLine
                                            ?.notOut === 0
                                            ? item?.result?.overAll?.battingLine
                                                ?.notOut
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.battingLine
                                            ?.runs ||
                                          item?.result?.overAll?.battingLine
                                            ?.runs === 0
                                            ? item?.result?.overAll?.battingLine
                                                ?.runs
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.result?.overAll?.battingLine
                                            ?.HS ||
                                          item?.result?.overAll?.battingLine
                                            ?.HS === 0
                                            ? item?.result?.overAll?.battingLine
                                                ?.HS
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.result?.overAll?.battingLine
                                            ?.AVG
                                            ? item?.result?.overAll?.battingLine?.AVG.toFixed(
                                                2
                                              ) + "%"
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.result?.overAll?.battingLine
                                            ?.BF ||
                                          item?.result?.overAll?.battingLine
                                            ?.BF === 0
                                            ? item?.result?.overAll?.battingLine
                                                ?.BF
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.result?.overAll?.battingLine
                                            ?.SR
                                            ? item?.result?.overAll?.battingLine?.SR.toFixed(
                                                2
                                              )
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.result?.overAll?.battingLine
                                            ?.century ||
                                          item?.result?.overAll?.battingLine
                                            ?.century === 0
                                            ? item?.result?.overAll?.battingLine
                                                ?.century
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.result?.overAll?.battingLine
                                            ?.two_century ||
                                          item?.result?.overAll?.battingLine
                                            ?.two_century === 0
                                            ? item?.result?.overAll?.battingLine
                                                ?.two_century
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.battingLine
                                            ?.half_century ||
                                          item?.result?.overAll?.battingLine
                                            ?.half_century === 0
                                            ? item?.result?.overAll?.battingLine
                                                ?.half_century
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.result?.overAll?.battingLine
                                            ?.four ||
                                          item?.result?.overAll?.battingLine
                                            ?.four === 0
                                            ? item?.result?.overAll?.battingLine
                                                ?.four
                                            : "-"}
                                        </TableCell>
                                        <TableCell
                                          className={`${classes.cell} border-right`}
                                        >
                                          {item?.result?.overAll?.battingLine
                                            ?.six ||
                                          item?.result?.overAll?.battingLine
                                            ?.six === 0
                                            ? item?.result?.overAll?.battingLine
                                                ?.six
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.bowlingLine
                                            ?.match ||
                                          item?.result?.overAll?.bowlingLine
                                            ?.match === 0
                                            ? item?.result?.overAll?.bowlingLine
                                                ?.match
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.bowlingLine
                                            ?.inning ||
                                          item?.result?.overAll?.bowlingLine
                                            ?.inning === 0
                                            ? item?.result?.overAll?.bowlingLine
                                                ?.inning
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.bowlingLine
                                            ?.B ||
                                          item?.result?.overAll?.bowlingLine
                                            ?.B === 0
                                            ? item?.result?.overAll?.bowlingLine
                                                ?.B
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.bowlingLine
                                            ?.runs ||
                                          item?.result?.overAll?.bowlingLine
                                            ?.runs === 0
                                            ? item?.result?.overAll?.bowlingLine
                                                ?.runs
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.bowlingLine
                                            ?.wkts ||
                                          item?.result?.overAll?.bowlingLine
                                            ?.wkts === 0
                                            ? item?.result?.overAll?.bowlingLine
                                                ?.wkts
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.bowlingLine
                                            ?.BBI
                                            ? item?.result?.overAll?.bowlingLine
                                                ?.BBI
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.bowlingLine
                                            ?.BBM
                                            ? item?.result?.overAll?.bowlingLine
                                                ?.BBM
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.bowlingLine
                                            ?.econ
                                            ? item?.result?.overAll?.bowlingLine?.econ.toFixed(
                                                2
                                              )
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.bowlingLine
                                            ?.AVG ||
                                          item?.result?.overAll?.bowlingLine
                                            ?.AVG === 0
                                            ? item?.result?.overAll?.bowlingLine?.AVG.toFixed(
                                                2
                                              )
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.bowlingLine
                                            ?.SR ||
                                          item?.result?.overAll?.bowlingLine
                                            ?.SR === 0
                                            ? item?.result?.overAll?.bowlingLine?.SR.toFixed(
                                                2
                                              )
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.bowlingLine
                                            ?.five_wicket ||
                                          item?.result?.overAll?.bowlingLine
                                            ?.five_wicket === 0
                                            ? item?.result?.overAll?.bowlingLine
                                                ?.five_wicket
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.bowlingLine
                                            ?.ten_wicket ||
                                          item?.result?.overAll?.bowlingLine
                                            ?.ten_wicket === 0
                                            ? item?.result?.overAll?.bowlingLine
                                                ?.ten_wicket
                                            : "-"}
                                        </TableCell>
                                        <StickyTableCell
                                          className={classes.cell}
                                        >
                                          {" "}
                                          {/* <span className="view-btn cursor-pointer">
                                        List
                                      </span> */}
                                        </StickyTableCell>
                                      </TableRow>
                                    </>
                                  ) : (
                                    <TableRow>
                                      <TableCell
                                        colSpan={12}
                                        className="no-data-td"
                                      >
                                        No Data Available
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          ) : (
                            <TableContainer
                              id="details-table"
                              className="teamsport-data-table team-sport-tables player-career-table"
                            >
                              {/* <Box className="overlay"></Box> */}
                              <Table
                                className="data-table"
                                style={{ minWidth: 940 }}
                              >
                                <TableHead className="table-header">
                                  <TableRow className="table-head-row ">
                                    <TableCell
                                      className={`${classes.head} first-cell`}
                                    >
                                      Team
                                    </TableCell>
                                    <TableCell
                                      className={`${classes.head} border-right`}
                                    >
                                      Years
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      App
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      T
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      G
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      GK%
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      FG
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      Pts
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      W
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      L
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      D
                                    </TableCell>
                                    <TableCell className={classes.head}>
                                      Win%
                                    </TableCell>
                                    <StickyTableCell
                                      className={classes.head}
                                    ></StickyTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody className="table-body">
                                  {item?.result?.teamList?.length > 0 ? (
                                    <>
                                      {item?.result?.teamList?.map((obj, i) => {
                                        return (
                                          <>
                                            <TableRow
                                              key={i}
                                              className="table-body-row "
                                            >
                                              <TableCell
                                                className={`${classes.cell} first-cell light-blue cursor-pointer`}
                                                onClick={() =>
                                                  navigate(
                                                    `/sport/statistics/${
                                                      params?.sportId
                                                    }/${
                                                      params?.tournamentId
                                                    }/league-data/${
                                                      obj?.teamId
                                                    }/team-data?seasonId=${
                                                      SeasonId ? SeasonId : ""
                                                    }`
                                                  )
                                                }
                                              >
                                                {obj?.name}
                                              </TableCell>
                                              <TableCell
                                                className={`${classes.cell} light-blue cursor-pointer border-right`}
                                              >
                                                {obj?.year}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.app || obj?.app === 0
                                                  ? obj?.app
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.T || obj?.T === 0
                                                  ? obj?.T
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.G || obj?.G === 0
                                                  ? obj?.G
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.GK || obj?.GK === 0
                                                  ? obj?.GK
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.FG || obj?.FG === 0
                                                  ? obj?.FG
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.Pts || obj?.Pts === 0
                                                  ? obj?.Pts
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.W || obj?.W === 0
                                                  ? obj?.W
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.L || obj?.L === 0
                                                  ? obj?.L
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.D || obj?.D === 0
                                                  ? obj?.D
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                className={classes.cell}
                                              >
                                                {obj?.win || obj?.win === 0
                                                  ? obj?.win
                                                  : "-"}
                                              </TableCell>
                                              <StickyTableCell
                                                className={classes.cell}
                                              >
                                                <span className="view-btn cursor-pointer">
                                                  List
                                                </span>
                                              </StickyTableCell>
                                            </TableRow>
                                          </>
                                        );
                                      })}
                                      <TableRow className="table-body-row table-result-row">
                                        <TableCell
                                          className={`${classes.cell} first-cell`}
                                        >
                                          {item?.result?.overAll?.name}
                                        </TableCell>
                                        <TableCell
                                          className={`${classes.cell} border-right`}
                                        >
                                          {item?.result?.overAll?.year}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.app}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.T}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.G}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.GK}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.FG}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.Pts}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.win}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.L}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.D}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.result?.overAll?.win}
                                        </TableCell>
                                        <StickyTableCell
                                          className={classes.cell}
                                        >
                                          {" "}
                                          {/* <span className="view-btn cursor-pointer">
                                        List
                                      </span> */}
                                        </StickyTableCell>
                                      </TableRow>
                                    </>
                                  ) : (
                                    <TableRow>
                                      <TableCell
                                        colSpan={12}
                                        className="no-data-td"
                                      >
                                        No Data Available
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </Box>
                      </Box>
                    </>
                  );
                })
              ) : (
                <Box className="no-data-wrap">
                  <Typography className="text-align-center no-data">
                    No data Available
                  </Typography>
                </Box>
              )}

              {/* NSW/QLD Cup - By Year */}

              {/* <Box className="teamsport-header-details">
                <Typography className="heading-title">
                  NSW/QLD Cup - By Year
                </Typography>
              </Box>

              <Box className="table-overlay">
                <TableContainer
                  id="details-table"
                  className="teamsport-data-table team-sport-tables player-career-table"
                >
                  {/* <Box className="overlay"></Box> /}
                  <Table className="data-table" style={{ minWidth: 940 }}>
                    <TableHead className="table-header">
                      <TableRow className="table-head-row ">
                        <TableCell className={`${classes.head} first-cell `}>
                          Team
                        </TableCell>
                        <TableCell className={`${classes.head} border-right`}>
                          Season
                        </TableCell>
                        <TableCell className={classes.head}>Age</TableCell>
                        <TableCell className={classes.head}>T</TableCell>
                        <TableCell className={classes.head}>G</TableCell>
                        <TableCell className={classes.head}>GK%</TableCell>
                        <TableCell className={classes.head}>FG</TableCell>
                        <TableCell className={classes.head}>Pts</TableCell>
                        <TableCell className={classes.head}>W</TableCell>
                        <TableCell className={classes.head}>L</TableCell>
                        <TableCell className={classes.head}>D</TableCell>
                        <TableCell className={classes.head}>Win%</TableCell>
                        <StickyTableCell
                          className={classes.head}
                        ></StickyTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                      {TableContent?.length > 0 ? (
                        <>
                          {TableContent?.map((item, i) => {
                            return (
                              <>
                                <TableRow key={i} className="table-body-row ">
                                  <TableCell
                                    className={`${classes.cell} first-cell light-blue cursor-pointer`}
                                  >
                                    Gold Coast
                                  </TableCell>
                                  <TableCell
                                    className={`${classes.cell} light-blue cursor-pointer border-right`}
                                  >
                                    NRL 2019
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    3
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    1
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    0/1
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    0.00%
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    -
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    2
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    2
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    2
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    2
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    16.67%
                                  </TableCell>
                                  <StickyTableCell className={classes.cell}>
                                    <span className="view-btn cursor-pointer">
                                      List
                                    </span>
                                  </StickyTableCell>
                                </TableRow>
                              </>
                            );
                          })}
                          <TableRow className="table-body-row table-result-row">
                            <TableCell className={`${classes.cell} first-cell`}>
                              Overall
                            </TableCell>
                            <TableCell
                              className={`${classes.cell} border-right`}
                            >
                              2019-2023
                            </TableCell>
                            <TableCell className={classes.cell}>0</TableCell>
                            <TableCell className={classes.cell}>4</TableCell>
                            <TableCell className={classes.cell}>2/4</TableCell>
                            <TableCell className={classes.cell}>
                              50.00%
                            </TableCell>
                            <TableCell className={classes.cell}>0</TableCell>
                            <TableCell className={classes.cell}>
                              [total]
                            </TableCell>
                            <TableCell className={classes.cell}>
                              [total]
                            </TableCell>
                            <TableCell className={classes.cell}>
                              [total]
                            </TableCell>
                            <TableCell className={classes.cell}>
                              [total]
                            </TableCell>
                            <TableCell className={classes.cell}>
                              [total]
                            </TableCell>
                            <StickyTableCell className={classes.cell}>
                              {" "}
                              <span className="view-btn cursor-pointer">
                                List
                              </span>
                            </StickyTableCell>
                          </TableRow>
                        </>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12} className="no-data-td">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box> */}

              {/* NSW/QLD Cup - By Team */}

              {/* <Box className="teamsport-header-details">
                <Typography className="heading-title">
                  NSW/QLD Cup - By Team
                </Typography>
              </Box>

              <Box className="table-overlay">
                <TableContainer
                  id="details-table"
                  className="teamsport-data-table team-sport-tables player-career-table"
                >
                  {/* <Box className="overlay"></Box> /}
                  <Table className="data-table" style={{ minWidth: 940 }}>
                    <TableHead className="table-header">
                      <TableRow className="table-head-row ">
                        <TableCell className={`${classes.head} first-cell`}>
                          Team
                        </TableCell>
                        <TableCell className={`${classes.head} border-right`}>
                          Years
                        </TableCell>
                        <TableCell className={classes.head}>Age</TableCell>
                        <TableCell className={classes.head}>T</TableCell>
                        <TableCell className={classes.head}>G</TableCell>
                        <TableCell className={classes.head}>GK%</TableCell>
                        <TableCell className={classes.head}>FG</TableCell>
                        <TableCell className={classes.head}>Pts</TableCell>
                        <TableCell className={classes.head}>W</TableCell>
                        <TableCell className={classes.head}>L</TableCell>
                        <TableCell className={classes.head}>D</TableCell>
                        <TableCell className={classes.head}>Win%</TableCell>
                        <StickyTableCell
                          className={classes.head}
                        ></StickyTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                      {TableContent?.length > 0 ? (
                        <>
                          {TableContent?.map((item, i) => {
                            return (
                              <>
                                <TableRow key={i} className="table-body-row ">
                                  <TableCell
                                    className={`${classes.cell} first-cell light-blue cursor-pointer`}
                                  >
                                    Gold Coast
                                  </TableCell>
                                  <TableCell
                                    className={`${classes.cell} light-blue cursor-pointer border-right`}
                                  >
                                    2023
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    3
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    1
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    0/1
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    0.00%
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    -
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    2
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    2
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    2
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    2
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    16.67%
                                  </TableCell>
                                  <StickyTableCell className={classes.cell}>
                                    <span className="view-btn cursor-pointer">
                                      List
                                    </span>
                                  </StickyTableCell>
                                </TableRow>
                              </>
                            );
                          })}
                          <TableRow className="table-body-row table-result-row">
                            <TableCell className={`${classes.cell} first-cell`}>
                              Overall
                            </TableCell>
                            <TableCell
                              className={`${classes.cell} border-right`}
                            >
                              2019-2023
                            </TableCell>
                            <TableCell className={classes.cell}>0</TableCell>
                            <TableCell className={classes.cell}>4</TableCell>
                            <TableCell className={classes.cell}>2/4</TableCell>
                            <TableCell className={classes.cell}>
                              50.00%
                            </TableCell>
                            <TableCell className={classes.cell}>0</TableCell>
                            <TableCell className={classes.cell}>
                              [total]
                            </TableCell>
                            <TableCell className={classes.cell}>
                              [total]
                            </TableCell>
                            <TableCell className={classes.cell}>
                              [total]
                            </TableCell>
                            <TableCell className={classes.cell}>
                              [total]
                            </TableCell>
                            <TableCell className={classes.cell}>
                              [total]
                            </TableCell>
                            <StickyTableCell className={classes.cell}>
                              {" "}
                              <span className="view-btn cursor-pointer">
                                List
                              </span>
                            </StickyTableCell>
                          </TableRow>
                        </>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12} className="no-data-td">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box> */}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </>
  );
};

export default PlayerSummaryDetails;
