import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Breadcrumbs,
  Button,
  TextField
} from "@mui/material";
import banner from "../../../assets/images/banner/smart-b-banner-primary.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import OurPartner from "../ourPartners";
import { useParams } from "react-router-dom";
import AdBannner from "../AdBanner/AdBanner";
import moment from "moment";
import { ReactComponent as DeleteRed } from "src/assets/images/icons/delete-red.svg";
import { ReactComponent as WhiteLeftArrow } from "src/assets/images/icons/right-arrow-white.svg";
import Avatar from "src/assets/images/user-avatar.png";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import PaymentSuccessfulModal from "../UI/paymentSuccessfulModal";
import "./checkout.scss";

const cartItemData = [
  {
    id: 1,
    name: "D'Silva",
    date: "2023-12-26T11:24:00.000Z",
    ammount: 1000
  },
  {
    id: 2,
    name: "D'Costa",
    date: "2023-12-26T11:24:00.000Z",
    ammount: 10000
  },
  {
    id: 3,
    name: "D'Souza",
    date: "2023-12-26T11:24:00.000Z",
    ammount: 100
  }
];

const CheckoutPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  let [stepperCount, setStepperCount] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [newMessage, setNewMessage] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [cardsave, setCardSave] = useState(false);
  const [paymentConfirmModal, setPaymentConfirmModal] = useState(false);
  const [showCartComp, setShowCartComp] = useState(true);
  const [showPaymentComp, setShowPaymentComp] = useState(false);

  const handleNavigationBack = () => {
    const prevPage = localStorage.getItem("previous_page");
    if (prevPage) {
      setTimeout(() => {
        navigate(prevPage);
        localStorage.removeItem("previous_page");
      }, 1000);
    } else {
      navigate("/");
    }
  };

  const handleCardSave = event => {
    setCardSave(event?.target?.checked);
  };

  const handleClose = () => {
    setPaymentConfirmModal(false);
  };

  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handlePaymentModeChange = e => {
    setPaymentMode(e?.target?.value);
  };

  return (
    <>
      <Box className="content-wrap">
        <Box className="mobile-banner">
          {fetchPageHeaderAds("218px", banner)}
        </Box>

        <Box className="checkout-wrap">
          <Box className="checkout-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  HOME
                </Link>

                <Link underline="hover" color="inherit" to="">
                  Tipps Marketplace
                </Link>
                <Link underline="hover" color="inherit" to="">
                  {params?.page} Tips
                </Link>
                <Link underline="hover" color="inherit" to="">
                  Cart
                </Link>
                <Link underline="hover" color="inherit" to="">
                  Payment
                </Link>

                <Typography>Checkout</Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">Checkout</Typography>
          </Box>
          <Box className="page-deatils-wrap">
            <>
              <Box className="cart-total-sec">
                <Box className="cart-sec">
                  <Box
                    className="title-sec"
                    onClick={() => setShowCartComp(!showCartComp)}
                  >
                    <Typography className="title">CART</Typography>
                  </Box>
                  {showCartComp ? (
                    <Box className="cart-items-sec">
                      {cartItemData && cartItemData?.length > 0 ? (
                        cartItemData?.map(item => {
                          return (
                            <Box className="cart-item">
                              <Box className="activity-sec">
                                <Box className="user-img">
                                  <img src={Avatar} alt="avatar" />
                                </Box>
                                <Box className="activity-detail">
                                  <Typography className="tip-txt">
                                    Tips from{" "}
                                    <span className="user-name">
                                      {item?.name}
                                    </span>{" "}
                                    for {fetchDayName(item?.date)},{" "}
                                    {moment(item?.date)
                                      .local()
                                      .format("Do MMMM YYYY")}
                                    .
                                  </Typography>
                                  <Typography className="time-txt">
                                    AU ${item?.ammount}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                className="delete-box cursor-pointer"
                                //   onClick={() => handleDelete(item?.type, item?.tableId)}
                              >
                                <DeleteRed />
                              </Box>
                            </Box>
                          );
                        })
                      ) : (
                        <></>
                      )}

                      <Box className="continue-btn-sec">
                        <Button
                          //   variant="contained"
                          className="continue-btn"
                          endIcon={<WhiteLeftArrow />}
                          onClick={() => setShowPaymentComp(!showPaymentComp)}
                        >
                          Continue
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
                {screenWidth < 799 ? (
                  <Box className="subscribe-wrap-btn w-100">
                    <Button
                      variant="contained"
                      // type="submit"
                      className="subscribe-btn"
                      onClick={() => setPaymentConfirmModal(true)}
                    >
                      Place the Order
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}

                <Box className="total-sec">
                  <Box className="title-sec">
                    <Typography className="title">CART TOTAL</Typography>
                  </Box>
                  <Box className="total-ammount-sec">
                    <Typography className="left-txt">
                      Item(s) Subtotal:
                    </Typography>
                    <Typography className="ammount">$20.00</Typography>
                  </Box>
                  <Box className="total-ammount-sec border-bottom-blue">
                    <Typography className="left-txt">Amount Payable</Typography>
                    <Typography className="ammount">$20.00</Typography>
                  </Box>
                  <Box className="coupon-sec">
                    <Typography className="gift-txt">
                      Have a Gift Card
                    </Typography>
                    <Box className="send-box">
                      <TextField
                        placeholder="Enter Gift Card Number"
                        variant="outlined"
                        type="text"
                        name="sportoreventother"
                        className="text-field"
                        value={newMessage}
                        onChange={e => setNewMessage(e.target.value)}
                      />
                      <Button
                        className="send-btn"
                        disableElevation
                        disableFocusRipple
                        disableRipple
                        //   onClick={() => newMessage && handleSendMessage("bot")}
                      >
                        Apply
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>

            <Box className="payment-sec">
              <Box className="title-sec">
                <Typography className="title">PAYMENT</Typography>
              </Box>
              <Box className="title-sec">
                <Typography className="label">
                  Standard Debit Mastercard ****1234
                </Typography>
              </Box>

              {/* <Typography className="label">John Smith</Typography>
              <Typography className="label">Expiry - 12/24</Typography> */}
            </Box>

            {screenWidth < 799 ? (
              <Box className="subscribe-wrap-btn w-100">
                <Button
                  variant="contained"
                  // type="submit"
                  className="subscribe-btn"
                  onClick={() => setPaymentConfirmModal(true)}
                >
                  Place the Order
                </Button>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Box>
      <PaymentSuccessfulModal
        open={paymentConfirmModal}
        handleClose={handleClose}
        title={""}
        closeIcon={true}
        page={params?.page}
      />
    </>
  );
};

export default CheckoutPage;
