import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "src/helpers/store/Actions/auth";
import { IntlContext } from "src/App";
import { ReactComponent as BackArrow } from "../../../../assets/images/Back_Arrow.svg";
import { ReactComponent as CheckBoxChecked } from "src/assets/images/checkBoxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/checkboxUnChecked.svg";
import { ReactComponent as RadioChecked } from "src/assets/images/icons/smartb-check.svg";
import { ReactComponent as RadioUnChecked } from "src/assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Indeterminent } from "src/assets/images/icons/indeterminent-icon.svg";
import { ReactComponent as Football } from "src/assets/images/Sport/football.svg";
import { ReactComponent as Baseball } from "src/assets/images/Sport/baseballLight.svg";
import { ReactComponent as Basketball } from "src/assets/images/Sport/basketballLight.svg";
import { ReactComponent as Boxing } from "src/assets/images/Sport/boxingLight.svg";
import { ReactComponent as Cricket } from "src/assets/images/Sport/cricketLight.svg";
import { ReactComponent as Golf } from "src/assets/images/Sport/golfLight.svg";
import { ReactComponent as IceHockey } from "src/assets/images/Sport/iceHockey-new.svg";
import { ReactComponent as MMA } from "src/assets/images/Sport/mmaIcon-new.svg";
import { ReactComponent as Rugby } from "src/assets/images/Sport/rugby.svg";
import { ReactComponent as Soccer } from "src/assets/images/Sport/soccerIcon-new.svg";
import { ReactComponent as Tennis } from "src/assets/images/Sport/Tennis.svg";
import { ReactComponent as AR } from "src/assets/images/Sport/arIcon-new.svg";
import { ReactComponent as RU } from "src/assets/images/Sport/rugbyUnionIcon-new.svg";
import { ReactComponent as RaceHorses } from "src/assets/images/Sport/horseRacingIcon.svg";
import { release } from "src/helpers/context/release";
import { Config } from "src/helpers/context";
import Loader from "src/components/Loader";
import { setApiMessage } from "src/helpers/commonFunctions";

const notification = [
  { id: 1, name: "Race tips" },
  { id: 2, name: "Sports tips" },
  { id: 3, name: "Benefits and discounts" },
  { id: 4, name: "Customer forums" },
  { id: 5, name: "Racing Stats" },
  { id: 6, name: "Sport Stats" },
  { id: 7, name: "Form Guide" },
  { id: 8, name: "Other - Please specify" }
];

const notificationData = [
  {
    id: 1,
    name: "American Football",
    icon: <Football />,
    SportId: 15,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" }
    ]
  },
  {
    id: 2,
    name: "Australian Rules",
    icon: <AR />,
    SportId: 9,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" }
    ]
  },
  {
    id: 3,
    name: "Baseball",
    icon: <Baseball />,
    SportId: 11,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" }
    ]
  },
  {
    id: 4,
    name: "Basketball",
    icon: <Basketball />,
    SportId: 10,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" }
    ]
  },
  {
    id: 5,
    name: "Boxing",
    icon: <Boxing />,
    SportId: 6,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" }
    ]
  },
  {
    id: 6,
    name: "Cricket",
    icon: <Cricket />,
    SportId: 4,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" }
    ]
  },
  {
    id: 7,
    name: "Golf",
    icon: <Golf />,
    SportId: 16,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" }
    ]
  },
  {
    id: 8,
    name: "Ice Hockey",
    icon: <IceHockey />,
    SportId: 17,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" }
    ]
  },
  {
    id: 9,
    name: "Mixed Martial Arts",
    icon: <MMA />,
    SportId: 5,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" }
    ]
  },
  {
    id: 10,
    name: "Rugby League",
    icon: <Rugby />,
    SportId: 12,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" }
    ]
  },
  {
    id: 11,
    name: "Rugby Union",
    icon: <RU />,
    SportId: 13,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" }
    ]
  },
  {
    id: 12,
    name: "Soccer",
    icon: <Soccer />,
    SportId: 8,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" }
    ]
  },
  {
    id: 13,
    name: "Tennis",
    icon: <Tennis />,
    SportId: 7,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" }
    ]
  },
  {
    id: 14,
    name: "Racing",
    icon: <RaceHorses />,
    SportId: [1, 2, 3],
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" }
    ]
  }
];
function NotificationsEdit({ passData, handleUserProfile }) {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [newData, setnewData] = useState(passData);
  const [notificationValue, setnotificationValue] = useState(
    newData?.offerings
  );
  const [offeringsOther, setofferingsOther] = useState(
    newData?.offeringsOther?.join("")
  );
  const [isLoading, setisLoading] = useState(false);
  const [sports, setSports] = useState([]);

  const handlenotificationValueChange = e => {
    const { value, checked } = e.target;
    if (checked === true) {
      let checkboxdata = [...notificationValue, value];
      setnotificationValue(checkboxdata);
    } else {
      let checkboxdata = notificationValue?.filter(
        element => element !== value
      );
      setnotificationValue(checkboxdata);
    }
  };
  const handleOfferingsOther = e => {
    setofferingsOther([e.target.value]);
  };
  const handleSave = async () => {
    setisLoading(true);
    const notificationData =
      (await sports?.length) > 0 &&
      sports?.map(item => {
        if (item?.id != 1) {
          return {
            SportId: item?.id,
            tips: item?.notificationData?.[0]?.isChecked
              ? item?.notificationData?.[0]?.isChecked
              : false,
            news: item?.notificationData?.[1]?.isChecked
              ? item?.notificationData?.[1]?.isChecked
              : false,
            fixtures: item?.notificationData?.[2]?.isChecked
              ? item?.notificationData?.[2]?.isChecked
              : false
          };
        } else {
          return {
            SportId: item?.id,
            tips: item?.notificationData?.[0]?.isChecked
              ? item?.notificationData?.[0]?.isChecked
              : false,
            news: item?.notificationData?.[1]?.isChecked
              ? item?.notificationData?.[1]?.isChecked
              : false,
            dailyBestBet: item?.notificationData?.[2]?.isChecked
              ? item?.notificationData?.[2]?.isChecked
              : false,
            fixtures: item?.notificationData?.[3]?.isChecked
              ? item?.notificationData?.[3]?.isChecked
              : false,
            weeklyNewsLetter: item?.notificationData?.[4]?.isChecked
              ? item?.notificationData?.[4]?.isChecked
              : false,
            smartBNewsLetter: item?.notificationData?.[5]?.isChecked
              ? item?.notificationData?.[5]?.isChecked
              : false
          };
        }
      });
    let sportId1 =
      (await notificationData?.length) > 0 &&
      notificationData?.find(item => item.SportId === 1);
    if (sportId1) {
      var allSportsNotificationData = [
        ...notificationData,
        { ...sportId1, SportId: 2 },
        { ...sportId1, SportId: 3 }
      ];
    }
    const payload = {
      // offerings: notificationValue ? notificationValue : "",
      // offeringsOther: notificationValue?.includes("Other - Please specify")
      //   ? offeringsOther
      //     ? offeringsOther
      //     : []
      //   : [],
      NotificationPreference:
        release[Config.release]?.raceSportId?.length > 0
          ? allSportsNotificationData
          : notificationData
      // bookMaker: newData?.bookMaker,
      // bookMakersOther: newData?.bookMakerOther,
      // sportOrEvent: newData?.sportOrEvent,
      // sportOrEventOther: newData?.sportOrEventOther
    };

    try {
      const { status, data } = await axiosInstance.put(
        "user/editProfile",
        payload
      );
      if (status === 200) {
        // setuserData(data);
        setisLoading(false);
        // navigate("/profile");
        handleUserProfile();
        setApiMessage("success", data?.message);
      } else if (status === 403) {
        setisLoading(false);
        localStorage.removeItem("auth_token");
        navigate("/sign-in");
        dispatch(loginUser(""));
      } else {
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
      setApiMessage("error", error?.response?.data?.message);
    }
  };

  const handleBack = () => {
    navigate("/profile");
  };

  const handleSportChange = sportId => {
    const updatedSports = sports?.map(sport => {
      if (Array.isArray(sport?.id) && sport?.id.includes(sportId)) {
        const updatedSubscriptions = sport?.notificationData?.map(sub => ({
          ...sub,
          isChecked: !sub?.isChecked
        }));
        return {
          ...sport,
          notificationData: updatedSubscriptions
        };
      } else if (sport?.id === sportId) {
        return {
          ...sport,
          isChecked: !sport?.isChecked,
          isIndeterminate: false,
          notificationData: sport?.notificationData?.map(sub => ({
            ...sub,
            isChecked: !sport?.isChecked
          }))
        };
      }
      return sport;
    });

    setSports(updatedSports);
  };
  const handleSubscriptionChange = (sportId, subId) => {
    const updatedSports = sports?.map(sport => {
      if (sport?.id === sportId) {
        const updatedSubscriptions = sport?.notificationData?.map(sub => {
          if (sub?.id === subId) {
            return {
              ...sub,
              isChecked: !sub?.isChecked
            };
          }
          return sub;
        });

        const allChecked = updatedSubscriptions?.every(sub => sub?.isChecked);
        const someChecked = updatedSubscriptions?.some(sub => sub?.isChecked);
        const isChecked = allChecked || (!allChecked && someChecked);

        return {
          ...sport,
          notificationData: updatedSubscriptions,
          isChecked,
          isIndeterminate: !allChecked && someChecked
        };
      }
      return sport;
    });
    setSports(updatedSports);
  };

  const fetchSportData = async () => {
    setisLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/sport/?sportTypeId=${2}`
      );
      if (status === 200) {
        setisLoading(false);
        const releaseFilterSportData = data?.result?.filter(item =>
          release[Config.release]?.sportId?.includes(item?.id)
        );
        const SportsData = await releaseFilterSportData?.map(item => {
          return {
            ...item,
            notificationData: [
              { id: 1, subName: "Tips" },
              { id: 2, subName: "News" },
              { id: 3, subName: "Fixtures & Results" }
            ]
          };
        });
        const sortedData = SportsData?.sort((a, b) => {
          return a?.sportName.localeCompare(b?.sportName);
        });
        const mergeData =
          release[Config.release]?.raceSportId?.length > 0
            ? [
                ...sortedData,
                {
                  id: 1,
                  sportName: "Racing",
                  SportId: 1,
                  notificationData: [
                    { id: 1, subName: "Tips of the Day" },
                    { id: 2, subName: "News" },
                    { id: 4, subName: "Daily Best Bet" },
                    { id: 3, subName: "Fixtures & Results" },
                    { id: 5, subName: "Weekly Newsletter" },
                    { id: 6, subName: "SmartB Newsletter" }
                  ]
                }
              ]
            : [...sortedData];
        const SelectedData = await mergeData?.map(item => {
          const filteredSports =
            newData?.NotificationPreference?.sportsValue?.length > 0 &&
            newData?.NotificationPreference?.sportsValue?.filter(
              ele => ele?.SportId === item?.id
            );
          const allChecked =
            filteredSports?.[0]?.SportId == 1
              ? filteredSports?.[0]?.tips &&
                filteredSports?.[0]?.news &&
                filteredSports?.[0]?.dailyBestBet &&
                filteredSports?.[0]?.fixtures &&
                filteredSports?.[0]?.weeklyNewsLetter &&
                filteredSports?.[0]?.smartBNewsLetter
              : filteredSports?.[0]?.tips &&
                filteredSports?.[0]?.news &&
                filteredSports?.[0]?.fixtures;
          const someChecked =
            filteredSports?.[0]?.SportId == 1
              ? filteredSports?.[0]?.tips ||
                filteredSports?.[0]?.news ||
                filteredSports?.[0]?.dailyBestBet ||
                filteredSports?.[0]?.fixtures ||
                filteredSports?.[0]?.weeklyNewsLetter ||
                filteredSports?.[0]?.smartBNewsLetter
              : filteredSports?.[0]?.tips ||
                filteredSports?.[0]?.news ||
                filteredSports?.[0]?.fixtures;
          const isChecked = allChecked || (!allChecked && someChecked);
          return {
            ...item,
            isChecked,
            isIndeterminate: !allChecked && someChecked,
            notificationData: item?.notificationData?.map((ele, index) => {
              return {
                ...ele,
                isChecked:
                  item?.id == 1
                    ? index === 0
                      ? filteredSports?.[0]?.tips
                      : index === 1
                      ? filteredSports?.[0]?.news
                      : index === 2
                      ? filteredSports?.[0]?.dailyBestBet
                      : index === 3
                      ? filteredSports?.[0]?.fixtures
                      : index === 4
                      ? filteredSports?.[0]?.weeklyNewsLetter
                      : index === 5
                      ? filteredSports?.[0]?.smartBNewsLetter
                      : false
                    : index === 0
                    ? filteredSports?.[0]?.tips
                    : index === 1
                    ? filteredSports?.[0]?.news
                    : index === 2
                    ? filteredSports?.[0]?.fixtures
                    : false
              };
            })
          };
        });
        setSports(SelectedData);
      } else {
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
    }
  };

  const fetchSportIcon = sportId => {
    if (sportId === 1) {
      return <RaceHorses />;
    } else if (sportId === 15) {
      return <Football />;
    } else if (sportId === 9) {
      return <AR />;
    } else if (sportId === 11) {
      return <Baseball />;
    } else if (sportId === 10) {
      return <Basketball />;
    } else if (sportId === 6) {
      return <Boxing />;
    } else if (sportId === 4) {
      return <Cricket />;
    } else if (sportId === 16) {
      return <Golf />;
    } else if (sportId === 17) {
      return <IceHockey />;
    } else if (sportId === 5) {
      return <MMA />;
    } else if (sportId === 12) {
      return <Rugby />;
    } else if (sportId === 13) {
      return <RU />;
    } else if (sportId === 8) {
      return <Soccer />;
    } else if (sportId === 7) {
      return <Tennis />;
    }
  };

  useEffect(() => {
    fetchSportData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="allsport-loader-center">
          <Loader />
        </div>
      ) : (
        <Box>
          <Box className="useredit-details" onClick={() => handleBack()}>
            {/* <span>
              <BackArrow className="back-arrow" />
            </span> */}
            <h4 style={{ cursor: "pointer" }}>
              {localesData?.EDITPROFILE?.EDIT_NOTIFICATION}
            </h4>
          </Box>
          {/* <Box className="checkbox-list">
          <Box className="checkbox-list-3">
            {notification?.map(element => (
              <FormControlLabel
                className="bookmakers-check"
                key={element.id}
                onChange={e => handlenotificationValueChange(e)}
                control={<Checkbox className="bookmakers-checkbox" />}
                label={element?.name}
                value={element?.name}
                name="notification"
                checked={notificationValue?.includes(element?.name)}
              />
            ))}
          </Box>
        </Box>
        <Box className="other-filed">
          <Box>
            {notificationValue?.includes("Other - Please specify") ? (
              <Box>
                <TextField
                  placeholder={""}
                  variant="outlined"
                  type="text"
                  name="OtherPleaseSpecify"
                  className="text-field"
                  value={offeringsOther}
                  onChange={e => handleOfferingsOther(e)}
                />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box> */}
          <Box className="notification-wrap">
            {sports?.map(sport => {
              return (
                <Box key={sport?.id} className="parent-checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CheckboxUnChecked className="radio-icon" />}
                        checkedIcon={<CheckBoxChecked className="radio-icon" />}
                        indeterminateIcon={<Indeterminent />}
                        checked={
                          sport?.isChecked == true &&
                          sport?.isIndeterminate == false
                        }
                        indeterminate={sport?.isIndeterminate}
                        onChange={() => handleSportChange(sport?.id)}
                      />
                    }
                    label={
                      <Box className="sport-icon-wrap">
                        <span className="sports-name">{sport?.sportName}</span>
                        <span className="sport-icon">
                          {fetchSportIcon(sport?.id)}
                        </span>
                      </Box>
                    }
                  />
                  <Box className="child-wrap">
                    {sport?.notificationData?.map(sub => (
                      <span
                        key={sub?.id}
                        className={`child-checkbox p-25 ${
                          sub?.isChecked === true ? "active-label" : ""
                        }`}
                        onClick={() =>
                          handleSubscriptionChange(sport?.id, sub?.id)
                        }
                      >
                        {/* <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={<RadioUnChecked className="radio-icon" />}
                                                            checkedIcon={
                                                                <RadioChecked className="radio-icon" />
                                                            }
                                                            checked={sub?.isChecked === true}
                                                            onChange={() =>
                                                                handleSubscriptionChange(sport?.id, sub?.id)
                                                            }
                                                        />
                                                    }
                                                    label={sub?.subName}
                                                /> */}
                        {sub?.subName}
                      </span>
                    ))}
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box className="profile-button">
            <Box className="profile-btn">
              {/* <Button
                variant="outlined"
                className="btn-cancel"
                onClick={() => handleBack()}
              >
                {localesData?.EDITPROFILE?.CANCEL}
              </Button> */}
              <Button
                variant="contained"
                className="btn-save"
                onClick={() => handleSave()}
              >
                {localesData?.EDITPROFILE?.SAVE_CHANGES}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default NotificationsEdit;
