const sitemapurl = () => {
  return `  <url>
      <loc>https://smartb.com.au/sign-up</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/sign-in</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/forgot-password</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/forgot-password-otp</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/profile</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/profile-edit</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>1</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/comingsoon</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/racing</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/racing?type=1</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/racing?type=2</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/racing?type=3</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/terms-and-conditions</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/privacy-policy</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/responsible-gambling</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/teamsports/basketball/0/false</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.6</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/teamsports/cricket/0/false</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.6</priority>
  </url>
  
      <url>
      <loc>https://smartb.com.au/teamsports/rugbyleague/0/false</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.6</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/teamsports/rugbyunion/0/false</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.6</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/advertisingscreen</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/advertisingenquiries</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
  </url>
  
  <url>
      <loc>https://smartb.com.au/news/0</loc>
      <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
        "+00:00"}</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.6</priority>
  </url>`;
};

export default sitemapurl;
