import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { IntlContext } from "src/App";
import { useSelector } from "react-redux";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import racer from "../../../assets/images/horse-racer.png";
import OurPartner from "../ourPartners";
import Facebook from "../../../assets/images/social/fb-link.svg";
import Linkedin from "../../../assets/images/social/li-link.svg";
import Twitter_X from "../../../assets/images/social/x-link.svg";
import Snapchat from "../../../assets/images/social/sc-link.svg";
import Copy from "../../../assets/images/social/copy-link.svg";
import Messenger from "../../../assets/images/social/mess-link.svg";
import Wp from "../../../assets/images/social/wp-link.svg";
import Message from "../../../assets/images/social/messages.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton
} from "react-share";
import "./internationalRacing.scss";

const raceRecord = [
  {
    id: 1,
    year: "2023",
    winner: "Ace Impact",
    second: "Westover",
    third: "Onesto"
  },
  {
    id: 2,
    year: "2022",
    winner: "Ace Impact",
    second: "Westover",
    third: "Onesto"
  },
  {
    id: 3,
    year: "2021",
    winner: "Ace Impact",
    second: "Westover",
    third: "Onesto"
  },
  {
    id: 4,
    year: "2020",
    winner: "Ace Impact",
    second: "Westover",
    third: "Onesto"
  },
  {
    id: 5,
    year: "2019",
    winner: "Ace Impact",
    second: "Westover",
    third: "Onesto"
  }
];

const InRacingDetails = () => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds?.[0]?.pageAdLists);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };
  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="international-racing-wrap">
          <Box className="racing-header">
            <Box className="page-bredcrumn-wrap">
              <Box className="bredcrumn-wrap">
                <Breadcrumbs
                  separator="/"
                  aria-label="breadcrumb"
                  className="breadcrumb"
                >
                  <Link underline="hover" color="inherit" to="/">
                    {localesData?.MENU?.HOME}
                  </Link>
                  <Link underline="hover" color="inherit" to="#">
                    {localesData?.INTERNATIONAL?.INFORMATION}
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    to="/international-racing"
                  >
                    {localesData?.INTERNATIONAL?.INTERNATIONAL_RACING}
                  </Link>
                  <Typography>{params?.race}</Typography>
                </Breadcrumbs>
              </Box>
              <Typography variant="h1">{params?.race}</Typography>
            </Box>
          </Box>
          <Box className="international-racing-details-wrap">
            <Box className="top-img-header-sec">
              <Box className="img-sec">
                <img src={racer} alt="website-img" className="left-img" />
              </Box>
              <Box className="txt-sec">
                <Typography className="title">
                  Prix de l’Arc de Triomphe Race Information
                </Typography>
                <Box className="des-box">
                  {/* <Typography className="description"> */}
                  <Typography className="description">
                    The Prix de l’Arc de Triomphe is one of the most prestigious
                    horse races in Europe. It is a set weight race for
                    three-year-olds and up over 2400 metres, but no geldings are
                    allowed. Often referred to as the “Arc” it is held on the
                    first Sunday of October.
                  </Typography>
                  <Typography className="description">
                    <ul>
                      <li>
                        <span className="fw-600">Race Type:</span> Group 1
                      </li>
                      <li>
                        <span className="fw-600">Distance:</span> 2400 metres
                      </li>
                      <li>
                        <span className="fw-600">Conditions:</span> Set weights
                        for 3YO and up, excluding geldings
                      </li>
                      <li>
                        <span className="fw-600">Track:</span> Longchamp
                        Racecourse, Paris, France
                      </li>
                      <li>
                        <span className="fw-600">Prizemoney:</span> €5 million
                      </li>
                      <li>
                        <span className="fw-600">Date:</span> Sunday, 1 October
                        2023
                      </li>
                    </ul>
                  </Typography>
                  {/* <Typography
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: shortDes
                      }}
                    ></Typography> */}
                  {/* </Typography> */}
                </Box>
              </Box>
            </Box>
            <Box className="txt-sec mt-27 pb-bottom w-100">
              <Typography className="title">
                Prix de l’Arc de Triomphe Race History
              </Typography>
              <Box className="des-box">
                <Typography className="description mt-27">
                  The Arc was first run in 1920 when it was won by Comrade. It
                  had high prizemoney from its inception and was open to
                  international horses three-year-old and older with the aim of
                  encouraging French breeders, as much of their best bloodstock
                  had been decimated by the First World War. It was cancelled in
                  1939 and 1940 during World War II and was moved to Le Tremblay
                  over 2,300 metres during 1943 and 1944.
                </Typography>
                <Typography className="description mt-27">
                  It is Europe’s top race for three-year-olds and above over the
                  classic 2,400 metres, and normally attracts entries from
                  several European countries and other parts of the world. It is
                  currently the world’s fourth richest turf race, following the
                  Evereset, Melbourne Cup and the Japan Cup. Longchamp
                  racecourse is on the banks of the River Seine and the race is
                  steeped in tradition and style.
                </Typography>
                <Typography className="description mt-27">
                  2021 was the 100th running of the race. In 2022 the
                  bottom-rated runners were eliminated for the first time in the
                  race’s history. Among those who missed out were Australia’s
                  champion mare Verry Elleegant, Prix de Diane runner-up La
                  Parisienne and the Ed and Simon Crisford-trained West Wind
                  Blows. In 2023 the maximum field size was increased from 20 to
                  24 as the ParisLongchamp track is so huge there are no safety
                  issues with 24 runners.
                </Typography>
              </Box>
            </Box>
            <Box className="txt-sec mt-27 pb-bottom w-100">
              <Typography className="title">Useful Facts</Typography>
              <Box className="des-box">
                <Typography className="description mt-27">
                  Multiple winners: Enable (2017 & 2018), Treve (2013 & 2014),
                  Alleged (1977 & 1978), Ribot (1955 & 1956), Tantieme (1950 &
                  1951), Corrida (1936 & 1937), Motrico (1930 & 1932) and Ksar
                  (1921 & 1922)
                </Typography>
                <Typography className="description mt-27">
                  Interesting trivia:
                </Typography>
              </Box>
            </Box>
            <Box className="txt-sec mt-27 pb-bottom w-100">
              <Typography className="title">
                Past winners of the Prix de l’Arc de Triomphe
              </Typography>
              <Box className="des-box">
                <Typography className="description mt-27">
                  Eight horses have managed to win this race on more than one
                  occasion – Enable (2017 and 2018), Treve (2013 and 2014),
                  Alleged (1977 and 1978), Ribot (1955 and 1956), Tantieme
                  (1950, 1951), Corrida (1936 and 1937), Motrico (1930 and 1932)
                  and Ksar (1921 and 1922). Alleged was the great grandson of
                  the Tesio-bred Ribot and both had vicious tempers and were
                  almost unmanageable. Motrico was the oldest horse to win, at
                  seven years of age in 1932.Horses foaled in Ireland have a
                  great record in the race, and three-year-old colts have the
                  best strike-rate. When Zarkava won the race in 2008 she was
                  the first filly to win in 15 years and then retired undefeated
                  after 7 wins. Fillies Danedream (2011), Solemia (2012), Treve
                  (2013 and 2014) and Enable (2017 and 2018) have won since
                  then. The most successful trainer is Andre Fabre with seven
                  winners from 1987 to 2006. In 2016 Aidan O’Brien trained
                  first.second and third. Arc winners are always sought after at
                  stud.
                </Typography>
                <Box className="mt-27">
                  <TableContainer className="international-table-container border-gray">
                    <Table
                      style={{ minWidth: "max-content", height: "100%" }}
                      className="international-table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell className="bg-color-Light-blue in-head-txt">
                            {localesData?.INTERNATIONAL?.YEAR}
                          </TableCell>
                          <TableCell className="bg-color-Light-blue in-head-txt">
                            {localesData?.INTERNATIONAL?.WINNER}
                          </TableCell>
                          <TableCell className="bg-color-Light-blue in-head-txt">
                            {localesData?.INTERNATIONAL?.SECOND}
                          </TableCell>
                          <TableCell className="bg-color-Light-blue in-head-txt">
                            {localesData?.INTERNATIONAL?.THIRD}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {raceRecord?.map((item, index) => {
                          return (
                            <>
                              <TableRow key={index}>
                                <TableCell>{item?.year}</TableCell>
                                <TableCell>{item?.winner}</TableCell>
                                <TableCell>{item?.second}</TableCell>
                                <TableCell>{item?.third}</TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Box>{" "}
            <Box className="txt-sec mt-27 w-100">
              <Typography
                className={
                  screenWidth > 600 ? "title" : "fs-16 color-black fw-600"
                }
              >
                Share This Story, Choose Your Platform!
              </Typography>
              <Box className="race-share-option">
                <Box className="social-box">
                  <Box className="copy-link">
                    <CopyToClipboard
                    // text={link} onCopy={handleCopy}
                    >
                      <Box style={{ textAlign: "center" }}>
                        <img src={Copy} alt="Copy" />
                        <Typography className="option-name">
                          Copy Link
                        </Typography>
                      </Box>
                    </CopyToClipboard>
                  </Box>
                  <Box style={{ textAlign: "center" }}>
                    <WhatsappShareButton
                    //  url={url}
                    >
                      <img src={Wp} alt="Whatsapp" />
                    </WhatsappShareButton>
                    <Typography className="option-name">Whatsapp</Typography>
                  </Box>
                  <Box style={{ textAlign: "center" }}>
                    <FacebookShareButton
                    // url={url}
                    >
                      <img src={Facebook} alt="Facebook" />
                    </FacebookShareButton>
                    <Typography className="option-name">Facebook</Typography>
                  </Box>
                  <Box style={{ textAlign: "center" }}>
                    {/* <FacebookMessengerShareButton url={url}> */}
                    <img
                      src={Messenger}
                      alt="Messenger"
                      className="social-icon"
                      //   onClick={() => shareOnMessenger()}
                    />
                    {/* </FacebookMessengerShareButton> */}
                    <Typography className="option-name">Messenger</Typography>
                  </Box>
                  <Box style={{ textAlign: "center" }}>
                    <TwitterShareButton
                    // url={url}
                    >
                      {" "}
                      <img src={Twitter_X} alt="Twitter_X" />
                    </TwitterShareButton>
                    <Typography className="option-name">Twitter</Typography>
                  </Box>
                  <Box style={{ textAlign: "center" }}>
                    <LinkedinShareButton
                    // url={url}
                    >
                      {" "}
                      <img src={Linkedin} alt="Linkedin" />
                    </LinkedinShareButton>
                    <Typography className="option-name">Linkedin</Typography>
                  </Box>
                  <Box
                    className="copy-link"
                    // onClick={() => handleSnapchatLogin()}
                    style={{ textAlign: "center" }}
                  >
                    <img src={Snapchat} alt="Snapchat" />
                    <Typography className="option-name">Snapchat</Typography>
                  </Box>
                  <Box style={{ textAlign: "center" }}>
                    {" "}
                    <img src={Message} alt="Message" />
                    <Typography className="option-name">Message</Typography>
                  </Box>
                </Box>

                {/* <Box
                  className="social-box"
                  style={{
                    marginTop: "10px"
                  }}
                >
                  <Box style={{ textAlign: "center" }}>
                    <TwitterShareButton
                    // url={url}
                    >
                      {" "}
                      <img src={Twitter_X} alt="Twitter_X" />
                    </TwitterShareButton>
                    <Typography className="option-name">Twitter</Typography>
                  </Box>
                  <Box style={{ textAlign: "center" }}>
                    <LinkedinShareButton
                    // url={url}
                    >
                      {" "}
                      <img src={Linkedin} alt="Linkedin" />
                    </LinkedinShareButton>
                    <Typography className="option-name">Linkedin</Typography>
                  </Box>
                  <Box
                    className="copy-link"
                    // onClick={() => handleSnapchatLogin()}
                    style={{ textAlign: "center" }}
                  >
                    <img src={Snapchat} alt="Snapchat" />
                    <Typography className="option-name">Snapchat</Typography>
                  </Box>
                  <Box style={{ textAlign: "center" }}>
                    {" "}
                    <img src={Message} alt="Message" />
                    <Typography className="option-name">Message</Typography>
                  </Box>
                </Box> */}
              </Box>
            </Box>
          </Box>
          <OurPartner />
        </Box>
      </Box>
    </>
  );
};

export default InRacingDetails;
