import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as Insta } from "src/assets/images/social/insta.svg";
import { ReactComponent as Linkedin } from "src/assets/images/social/linkedin.svg";
import { ReactComponent as Tweeter } from "src/assets/images/social/twitter_x_new.svg";
import Loader from "src/components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataComp from "src/views/component/UI/NoData";
import "./ourTeam.scss";

const BackAWinner = ({
  loader,
  allTeams,
  getAllTeams,
  fetchMoreData,
  hasMore,
  offset,
  allTeamsCount,
  value,
  rowPerPage
}) => {
  return (
    <>
      {loader ? (
        <div style={{ textAlign: "center", width: "100%" }}>
          <Loader />
        </div>
      ) : allTeams?.length > 0 ? (
        <>
          <InfiniteScroll
            dataLength={allTeams?.length}
            next={() => fetchMoreData(value, offset + rowPerPage)}
            hasMore={
              allTeamsCount !== 0 &&
              Math.ceil(allTeamsCount / rowPerPage) !==
                Math.ceil(offset / rowPerPage + 1)
            }
            loader={
              allTeams?.length > 0 && (
                <div style={{ textAlign: "center", width: "100%" }}>
                  <Loader />
                </div>
              )
            }
            className="team-scroll-div"
            // style={{ height: "auto", overflow: "auto", padding: "5px" }}
            scrollableTarget="scrollableDiv"
          >
            {allTeams?.map(item => {
              return (
                <Box className="card-sec">
                  <Box className="top-sec">
                    <Typography className="name">{item?.name}</Typography>
                    <Typography className="title">
                      {item?.OurTeamPosition?.name
                        ? item?.OurTeamPosition?.name
                        : "-"}
                    </Typography>
                  </Box>
                  <Box className="bottom-sec">
                    <Box className="des-sec">
                      <Typography className="title">{item?.sports}</Typography>
                      <Typography className="des">{item?.content}</Typography>
                    </Box>
                    <Box className="social-sec">
                      {item?.socialProfile?.twitter && (
                        <a
                          href={item?.socialProfile?.twitter}
                          target="_blank"
                          className="social-btn"
                        >
                          <Tweeter />
                        </a>
                      )}
                      {item?.socialProfile?.linkedin && (
                        <a
                          href={item?.socialProfile?.linkedin}
                          target="_blank"
                          className="social-btn"
                        >
                          <Linkedin />
                        </a>
                      )}
                      {item?.socialProfile?.instagram && (
                        <a
                          href={item?.socialProfile?.instagram}
                          target="_blank"
                          className="social-btn"
                        >
                          <Insta />
                        </a>
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </InfiniteScroll>
        </>
      ) : (
        <Box className="no-data-wrap-bookmakers" style={{ width: "100%" }}>
          <Typography className="text-align no-data">
            <NoDataComp />
          </Typography>
        </Box>
      )}
    </>
  );
};

export default BackAWinner;
