import React from "react";
import Page from "src/components/Page";
import CreateCompPage from "src/views/component/createComp";

const CreateComp = () => {
  return (
    <Page title="Create Competition">
      <CreateCompPage />
    </Page>
  );
};

export default CreateComp;
