import React, { useContext, useEffect, useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useParams } from "react-router";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import Select, { components } from "react-select";
import { IntlContext } from "src/App";
import { playerStatData } from "src/helpers/jsondata";

const statsData = [
  { value: 0, label: "Leagues" },
  { value: 1, label: "Teams" },
  { value: 2, label: "Players" },
  { value: 3, label: "Coaches" },
  { value: 4, label: "Venues" }
];

const tableTabData = [
  {
    id: 1,
    name: "All",
    tabName: "all"
  },
  {
    id: 2,
    name: "Goals",
    tabName: "goals"
  },
  {
    id: 3,
    name: "Assists",
    tabName: "assists"
  },
  {
    id: 4,
    name: "Shots On Target",
    tabName: "shots-on-target"
  },
  {
    id: 5,
    name: "Yellow Cards",
    tabName: "yellow-cards"
  },
  {
    id: 6,
    name: "Red Cards",
    tabName: "red-cards"
  }
];

const TeamPlayerStatsPage = () => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [tabvalue, setTabValue] = useState(1);
  const [tabName, setTabName] = useState("all");

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectIndicator />
      </components.DropdownIndicator>
    );
  };

  const handleTabChange = (event, value, name) => {
    setTabValue(value);
    setTabName(name);
  };

  const playerStatsDatas =
    tabName === "all"
      ? playerStatData
      : playerStatData?.filter(item => item?.statId === tabName);

  return (<>
    <Box className="player-stats-wrap">
      <Box className="team-tournament-wrap ">
        <Select
          className="React tournament-select"
          // value={providerOption?.find(item => {
          //   return item?.value === selectedGraphId;
          // })}
          // onChange={e => setSelectedGraphId(e?.value)}
          options={statsData}
          classNamePrefix="select"
          placeholder="All Competitions"
          isSearchable={false}
          components={{ DropdownIndicator }}
        />
      </Box>
      <Tabs
        value={tabvalue}
        variant="scrollable"
        scrollButtons={false}
        indicatorColor="primary"
        textColor="primary"
        className="soccer-details-tab soccer-player-stats-tab-table"
      >
        {tableTabData?.map((item, index) => {
          return (
            <Box key={index}>
              <Tab
                label={
                  <Box className="d-flex align-center match-tab">
                    <span>{item?.name}</span>
                  </Box>
                }
                value={item?.id}
                className={item?.id === tabvalue ? "active " : ""}
                onChange={(event, newValue) =>
                  handleTabChange(event, item?.id, item?.tabName)
                }
              />
            </Box>
          );
        })}
      </Tabs>
      <Box className="player-stats-details-wrap">
        {playerStatsDatas?.map((item, index) => {
          return (
            <>
              <Box>
                <Typography className="overview-title">
                  {item?.statId}
                </Typography>
                <Box className="stats-details">
                  {item?.players?.map((obj, i) => {
                    return (
                      <>
                        <Box className="d-flex align-center content-space-between stats-list">
                          <Box className="d-flex align-center col-gap-15">
                            <Box>{obj?.rank}</Box>
                            <Box className="d-flex align-center tournament-name-wrap">
                              <Box className="tournament-img-wrap">
                                <img src={obj?.teamBadge?.high} alt="img" />
                              </Box>
                              <Box className="tournament-name-wrap">
                                <Typography className="tournament-name">
                                  {obj?.name}
                                </Typography>
                                <Typography className="tournament-country">
                                  {obj?.teamName}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>{obj?.statAmount}</Box>
                        </Box>
                      </>
                    );
                  })}
                </Box>
              </Box>
            </>
          );
        })}
      </Box>
    </Box>
  </>);
};

export default TeamPlayerStatsPage;
