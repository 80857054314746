import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ReactComponent as Checked } from "src/assets/images/billedChecked.svg";
import { ReactComponent as UnChecked } from "src/assets/images/billedUnChecked.svg";
import { ReactComponent as CheckBoxChecked } from "src/assets/images/checkBoxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/checkboxUnChecked.svg";
import { ReactComponent as Plus } from "../../../../assets/images/icons/plus.svg";
import Cvvimage from "../../../../assets/images/cvvimage.png";
import MasterCard from "../../../../assets/images/mastercard.png";
import Visa from "../../../../assets/images/visa.png";
import AmericanExpress from "../../../../assets/images/americanExpress.png";
import PayPal from "../../../../assets/images/payPal.png";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import { Config, fetchFromStorage } from "src/helpers/context";
import { Formik, Form, Field } from "formik";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment";
import PaymentAddEditModal from "../../UI/paymentAddEditModal";

const PaymentForm = ({
  innerRef,
  setPaymentMode,
  paymentMode,
  setCardSave,
  cardsave,
  setSelectedCard,
  selectedCard,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [cardList, setCardList] = useState([]);
  const [cardLoader, setCardLoader] = useState(false);
  const [cardAddEditModalOpen, setCardAddEditModalOpen] = useState(false);

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const isLogin = handleRestrictedUser();

  useEffect(() => {
    if (isLogin) {
      fetchGetCardDetails();
    }
  }, []);

  const handleCardAddEditModal = () => {
    setCardAddEditModalOpen(true);
  };

  const handleCardAddEditModalClose = () => {
    setCardAddEditModalOpen(false);
  };

  const fetchGetCardDetails = async () => {
    setCardLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/subscription/get-card`,
      );
      if (status === 200) {
        setCardLoader(false);
        const cardDetails = data?.card;
        setCardList(cardDetails);
      }
    } catch (error) {
      setCardLoader(false);
    }
  };

  const handleCardSave = (event) => {
    setCardSave(event?.target?.checked);
  };

  const handlePaymentModeChange = (e, data) => {
    if (e?.target?.checked) {
      setSelectedCard(data);
      setPaymentMode(e?.target?.value);
    } else {
      setSelectedCard("");
      setPaymentMode("");
    }
  };

  const handlecardRemove = async (id) => {
    try {
      const { status, data } = await axiosInstance.delete(
        `/subscription/delete-card/${id}`,
        {
          body: {
            type: "web",
          },
        },
      );
      if (status === 200) {
        fetchGetCardDetails();
        setSelectedCard("");
        setPaymentMode("");
      } else {
        setSelectedCard("");
        setPaymentMode("");
      }
    } catch (error) {
      setCardLoader(false);
      setSelectedCard("");
      setPaymentMode("");
    }
  };

  const validationSchema = Yup.object().shape({
    cardname: paymentMode
      ? Yup.string()
      : Yup.string().required(localesData?.validation?.required_message),
    cardNumber: paymentMode
      ? Yup.string()
      : Yup.string()
          .min(19, "Card number must be at most 16 characters")
          .max(19, "Card number must be at most 16 characters")
          .required("Card number is required"),
    expirationDate: paymentMode
      ? Yup.string()
      : Yup.string()
          .required("Expiration date is required")
          .typeError("Not a valid expiration date. Example: MM/YYYY")
          .max(7, "Not a valid expiration date. Example: MM/YYYY")
          .matches(
            /([0-9]{2})\/([0-9]{4})/,
            "Not a valid expiration date. Example: MM/YYYY",
          )
          .test(
            "is-future",
            "Card expiration date should be in the future",
            function (value) {
              if (!value) return false;
              const currentDate = new Date();
              const [month, year] = value.split("/");
              const cardExpirationDate = new Date(
                parseInt(year),
                parseInt(month) - 1,
              );
              return cardExpirationDate > currentDate;
            },
          )
          .test(
            "not-over-100",
            "Card expiration date should not exceed 100 years in the future",
            function (value) {
              if (!value) return false;
              const currentDate = new Date();
              const [month, year] = value.split("/");
              const cardExpirationDate = new Date(
                parseInt(year),
                parseInt(month) - 1,
              );
              return (
                cardExpirationDate.getFullYear() <=
                currentDate.getFullYear() + 100
              );
            },
          ),
    cvv: paymentMode
      ? Yup.string()
      : Yup.string().min(3).max(3).required("CVV number is required"),
  });
  return (
    <>
      <Box className="membership-payment-details">
        <Typography className="payment-details">Payment</Typography>
        <Box className="credit-card-details">
          <Box className="left-card-details">
            <Typography className="card-text">Credit or debit card</Typography>
            <Box className="card-section">
              <img src={MasterCard} alt="card" />
              <img src={Visa} alt="card" />
              <img src={AmericanExpress} alt="card" />
            </Box>
          </Box>
          {/* {isLogin && (
            <Box className="right-card-details">
              <Plus />
              <Typography
                className="add-new-card-text"
                onClick={() => handleCardAddEditModal()}
              >
                Add new card
              </Typography>
            </Box>
          )} */}
        </Box>
        <Box className="payment-select">
          <RadioGroup
            aria-label="payment"
            name="payment"
            className="payment-radioGroup"
            value={Number(paymentMode)}
          >
            {cardLoader && (
              <div className="allsport-loader-center">
                <Loader />
              </div>
            )}
            {!cardLoader &&
              (cardList?.length > 0 ? (
                cardList?.map((item) => {
                  return (
                    <>
                      <Box className="payment-card-flex">
                        <FormControlLabel
                          key={item.id}
                          className="save-card"
                          value={Number(item.id)}
                          onChange={(e) => handlePaymentModeChange(e, item)}
                          control={
                            <Checkbox
                              size="small"
                              icon={<UnChecked className="radio-icon" />}
                              checkedIcon={<Checked className="radio-icon" />}
                              disableRipple
                              checked={item?.id == paymentMode}
                            />
                          }
                          label={
                            <Box className="save-payment-card-label">
                              <Box>
                                <Typography className="bold">
                                  <span>****{item?.last4}</span>
                                </Typography>
                                <Typography className="expiry-date">
                                  Expiry - {item?.cardExp}{" "}
                                  <Box>
                                    <span
                                      className={
                                        item.is_default === 1
                                          ? "disabled delete-underline"
                                          : "cursor-pointer delete-underline"
                                      }
                                      onClick={() => {
                                        if (item && item.is_default !== 1) {
                                          handlecardRemove(item.id);
                                        }
                                      }}
                                    >
                                      Delete
                                    </span>
                                  </Box>
                                </Typography>
                              </Box>
                              <Box>
                                <img
                                  src={
                                    item?.brand === "Visa"
                                      ? Visa
                                      : item?.brand === "Master"
                                        ? MasterCard
                                        : AmericanExpress
                                  }
                                  alt="card"
                                />
                              </Box>
                            </Box>
                          }
                        />
                      </Box>
                    </>
                  );
                })
              ) : (
                <></>
              ))}
          </RadioGroup>
        </Box>
        <Box className="card-details">
          <Formik
            innerRef={innerRef}
            initialValues={{
              cardname: selectedCard ? selectedCard?.cardHolderName : "",
              cardNumber: "",
              expirationDate: selectedCard
                ? moment(selectedCard?.cardExp).format("MM/YYYY")
                : "",
              cvv: "",
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Box className="card-form">
                  <Box className="card-info mb-19">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label=" Card Number*"
                      className="details-textfield"
                      name="cardNumber"
                      error={Boolean(touched?.cardNumber && errors?.cardNumber)}
                      helperText={touched?.cardNumber && errors?.cardNumber}
                      inputProps={{ maxLength: 19 }}
                      disabled={paymentMode}
                      onChange={(e) => {
                        handleChange(e);
                        // setCardUpdate({
                        //   ...cardUpdate,
                        //   card_token: e.target.value
                        // });
                        setFieldValue(
                          "cardNumber",
                          e?.target?.value
                            .replace(/[^\dA-Z*]/g, "")
                            .replace(/(.{4})/g, "$1 ")
                            .trim(),
                        );
                      }}
                      value={values?.cardNumber}
                      onKeyPress={(e) => {
                        if (!/[0-9 ]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        // updateCardDetails(
                        //   "cardNumber",
                        //   values?.cardNumber
                        //     .replace(/[^\dA-Z*]/g, "")
                        //     .replace(/(.{4})/g, "$1 ")
                        //     .trim()
                        // );
                      }}
                    />
                  </Box>
                  <Box className="date-cvv-wrap mb-19">
                    <Box className="card-info date-card-info">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        label="Expiry Date*"
                        className="details-textfield"
                        placeholder="mm/yyyy"
                        error={Boolean(
                          touched?.expirationDate && errors?.expirationDate,
                        )}
                        helperText={
                          touched?.expirationDate && errors?.expirationDate
                        }
                        name="expirationDate"
                        disabled={paymentMode}
                        value={values?.expirationDate}
                        onChange={(e) => {
                          setFieldValue(
                            "expirationDate",
                            e?.target?.value
                              .replace(/[^0-9]/g, "") // To allow only numbers
                              .replace(/^([2-9])$/g, "0$1") // To handle 3 > 03
                              .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2") // 13 > 01/3
                              .replace(/^0{1,}/g, "0") // To handle 00 > 0
                              .replace(
                                /^([0-1]{1}[0-9]{1})([0-9]{1,4}).*/g,
                                "$1/$2",
                              ), // To handle 113 > 11/3
                          );
                          // setCardUpdate({
                          //   ...cardUpdate,
                          //   card_exp: e.target.value
                          // });
                        }}
                        inputProps={{ maxLength: 7 }}
                        onBlur={(e) => {
                          handleBlur(e);
                          // updateCardDetails(
                          //   "expirationDate",
                          //   values?.expirationDate
                          // );
                        }}
                      />
                    </Box>
                    <Box className="card-info cvv-card-info">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="details-textfield"
                        name="cvv"
                        label="CVV*"
                        type="password"
                        error={Boolean(touched?.cvv && errors?.cvv)}
                        disabled={paymentMode}
                        helperText={touched?.cvv && errors?.cvv}
                        value={values.cvv}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            handleChange(e);
                          }
                          // setCardUpdate({
                          //   ...cardUpdate,
                          //   cvv: e.target.value
                          // });
                        }}
                        inputProps={{ maxLength: 3 }}
                        onBlur={(e) => {
                          handleBlur(e);
                          // updateCardDetails("cvv", values?.cvv);
                        }}
                      />
                    </Box>
                  </Box>
                  <Box className="card-info mb-19">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="Name on Card*"
                      className="details-textfield"
                      error={Boolean(touched.cardname && errors.cardname)}
                      helperText={touched.cardname && errors.cardname}
                      name="cardname"
                      value={values.cardname}
                      disabled={paymentMode}
                      onChange={(e) => {
                        handleChange(e);
                        // setCardUpdate({
                        //   ...cardUpdate,
                        //   card_holder_name: e.target.value
                        // });
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        // updateCardDetails("cardname", values?.cardname);
                      }}
                    />
                  </Box>
                  <Box className="checkBox-wrap">
                    <FormControlLabel
                      className="documentsRead-check"
                      control={
                        <Checkbox
                          className="documentsRead-checkbox"
                          checked={cardsave}
                          icon={<CheckboxUnChecked className="radio-icon" />}
                          checkedIcon={
                            <CheckBoxChecked className="radio-icon" />
                          }
                          onChange={(e) => handleCardSave(e)}
                          color="primary"
                        />
                      }
                      label={
                        <Typography>Save the card for future use</Typography>
                      }
                    />
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>

      <Dialog
        onClose={handleCardAddEditModalClose}
        aria-labelledby="customized-dialog-title"
        open={cardAddEditModalOpen}
        className="payment-add-edit-modal"
      >
        <DialogTitle className="modal-title">
          <Typography variant="h6" className="title">
            Add Payment Method
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => handleCardAddEditModalClose()}
            className="close-icon"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="payment-details">
          <PaymentAddEditModal
            handleCardAddEditModalClose={handleCardAddEditModalClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PaymentForm;
