import React, { useContext, useEffect, useState } from "react";
import { IntlContext } from "src/App";
import {
  Box,
  Button,
  TextField,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from "@mui/material";
import { ReactComponent as Checked } from "src/assets/images/icons/CheckedRadioIcon.svg";
import { ReactComponent as UnChecked } from "src/assets/images/icons/UnCheckedRadioIcon.svg";

import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "src/helpers/context";
import DefaultImg from "src/assets/images/smartb_default.png";

const ProfileBookmaker = ({
  handleRadioChange,
  handleOnChange,
  checkboxValue,
  formvalues,
  fetchEditProfile,
  value,
}) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [bookmakersList, setBookmakersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    fetchBookKeeper();
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const fetchBookKeeper = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `apiProviders/bookKeepers`
      );
      if (status === 200) {
        setIsLoading(false);
        setBookmakersList(data?.result);
      } else {
      }
    } catch (err) {}
  };
  const fetchLabel = (element) => {
    return (
      <>
        <Box className="checkbox-label-wrap">
          {screenWidth > 799 ? (
            <span className="bookeeper-image-wrap">
              <img
                className="bookeeper-image"
                src={
                  element?.currentBest_logo
                    ? Config?.baseURL + element?.currentBest_logo
                    : DefaultImg
                }
                alt="bookeeper"
              />
            </span>
          ) : (
            <span className="bookeeper-image-wrap">
              <img
                className="bookeeper-image"
                src={
                  element?.long_logo
                    ? Config?.baseURL + element?.long_logo
                    : DefaultImg
                }
                alt="bookeeper"
              />
            </span>
          )}

          <span className="bookeeper-name">{element?.name}</span>
        </Box>
      </>
    );
  };
  return (
    <>
      <Box className="step3-container">
        {isLoading ? (
          <div className="auth-loader">
            <Loader />
          </div>
        ) : (
          <></>
        )}
        <Typography className="textfield-text">
          {localesData?.register?.HABITS_AND_INTERESTS}
        </Typography>
        <Box className="bookmakers-text">
          <FormLabel component="legend" className="bookmakers-label signup-que">
            {localesData?.register?.ACCOUNTS_BOOKMAKERS}
          </FormLabel>
          <RadioGroup
            aria-label="bookmakers"
            name="bookmakers"
            className="bookmakers-radioGroup"
            value={value}
            onChange={(e) => handleRadioChange(e)}
          >
            <FormControlLabel
              value="yes"
              control={
                <Radio
                  disableRipple
                  icon={<UnChecked className="radio-icon" />}
                  checkedIcon={<Checked className="radio-icon" />}
                />
              }
              label="Yes"
            />
            <FormControlLabel
              value="no"
              control={
                <Radio
                  disableRipple
                  icon={<UnChecked className="radio-icon" />}
                  checkedIcon={<Checked className="radio-icon" />}
                />
              }
              label="No"
            />
          </RadioGroup>
        </Box>
        {value == "yes" ? (
          <Box className="bookmakers-list">
            <FormLabel component="legend" className="bookmakers-list-label">
              {localesData?.register?.BOOKMAKERS_LIST}
            </FormLabel>
            <Box className="checkbox-list">
              <Box className="checkbox-list-1">
                {bookmakersList.map((element) => (
                  <FormControlLabel
                    className="bookmakers-check"
                    key={element.id}
                    onChange={(e) => handleOnChange(e)}
                    control={<Checkbox className="bookmakers-checkbox" />}
                    label={fetchLabel(element)}
                    value={element?.name}
                    name="bookmakers"
                    checked={checkboxValue?.includes(element?.name)}
                  />
                ))}
              </Box>
            </Box>
            {/* <Box className="other-filed">
                        <Box></Box>
                        <Box>
                            {checkboxValue?.includes("Other - Please specify") ? (
                                <Box>
                                    <TextField
                                        placeholder={""}
                                        variant="outlined"
                                        type="text"
                                        name="bookmakerother"
                                        className="text-field"
                                        value={formvalues?.bookMakersOther}
                                        onChange={(e) => handleBookmakerOther(e)}
                                    />
                                    {errorBookmakerOther ? (
                                        <p className="text-danger">{errorBookmakerOther}</p>
                                    ) : (
                                        ""
                                    )}
                                </Box>
                            ) : (
                                ""
                            )}
                        </Box>
                    </Box> */}
          </Box>
        ) : (
          <Box className="no-bookmaker"></Box>
        )}
        {/* <span className="text-danger">
                {errorBookmakersOption && checkboxValue?.length === 0
                    ? errorBookmakersOption
                    : ""}
            </span> */}
      </Box>
      <Box className="signup-btn-wrap step5-signup-btn-wrap">
        {/* <Button
                   disableElevation
                   disableFocusRipple
                   disableRipple
                   // type="submit"
                   className="submit-btn"
               // onClick={() => prevStep()}
               >
                   {localesData?.register?.auth_btn_prev}
               </Button> */}
        <Button
          disableElevation
          disableFocusRipple
          disableRipple
          // type="submit"
          className="submit-btn"
          disabled={isLoading}
          onClick={() => {
            // if (ValidateOfferingOther()) {
            fetchEditProfile("finished");
            // }
          }}
        >
          {localesData?.register?.NEXT}
        </Button>

        <Button className="finish-btn" onClick={() => fetchEditProfile()}>
          {" "}
          <a>{localesData?.register?.FINISH_MSG_STEPPER}</a>
        </Button>
      </Box>
    </>
  );
};

export default ProfileBookmaker;
