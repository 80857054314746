import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  IconButton,
  Collapse,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import Loader from "../../../../components/Loader";
import { IntlContext } from "src/App";
import NoDataComp from "../../UI/NoData";
const StickyTableCell = withStyles(theme => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    width: "250px",
    "@media(max-width: 799px)": {
      maxWidth: "222px",
      boxShadow: "inset -2px 0px 0px 0px #ebebeb"
    }
  },
  body: {
    left: 0,
    position: "sticky",
    background: "#FFFFFF",
    "@media(max-width: 799px)": {
      boxShadow: "inset -2px 0px 0px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const StatisticsTable = ({
  isLoading,
  TableData,
  collapseMenu,
  setcollapseMenu,
  Name,
  dataName,
  apiCallFunction,
  modalStaticsType
}) => {
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [TableContent, setTableContent] = useState([]);
  const tableRef = useRef(null);
  const observer = useRef(null);

  const handleIntersection = async entries => {
    entries.forEach(async entry => {
      if (entry.isIntersecting) {
        if (apiCallFunction) {
          try {
            await apiCallFunction();
            observer.current.unobserve(entry.target);
          } catch (error) {
            console.error("Error fetching table data:", error);
          }
        }
      }
    });
  };

  useEffect(() => {
    // Create the Intersection Observer instance
    observer.current = new IntersectionObserver(handleIntersection, {
      root: null, // Use the viewport as the root
      rootMargin: "0px",
      threshold: 0.1 // Adjust the threshold based on your needs
    });

    if (tableRef.current) {
      // Start observing the table component
      observer.current.observe(tableRef.current);
    }

    return () => {
      // Clean up the observer on unmount
      observer.current.disconnect();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  useEffect(() => {
    if (TableData?.length > 0) {
      setTableContent(TableData);
    }
  }, [TableData]);

  return (
    <>
      <Box
        className={
          modalStaticsType == "modal"
            ? "profile-details-wrap-2 modal-profile-details-wrap-2"
            : "profile-details-wrap-2"
        }
        ref={tableRef}
      >
        {screenWidth > 799 ? (
          <>
            {isLoading && (
              <div className="allsport-loader-center">
                <Loader />
              </div>
            )}
            {!isLoading && (
              <>
                <TableContainer id="details-table" className="details-table">
                  <Table className="table-wrap" style={{ minWidth: 940 }}>
                    <TableHead>
                      <TableRow className="table-head-row ">
                        <StickyTableCell className={classes.head}>
                          {Name}
                          <br />
                          <span className="head-row-span">{dataName}</span>
                        </StickyTableCell>
                        <TableCell className={classes.head}>
                          {localesData?.sport?.STARTS}
                        </TableCell>
                        <TableCell className={classes.head}>1st</TableCell>
                        <TableCell className={classes.head}>2nd</TableCell>
                        <TableCell className={classes.head}>3rd</TableCell>
                        <TableCell className={classes.head}>
                          {localesData?.sport?.WIN}%
                        </TableCell>
                        <TableCell className={classes.head}>
                          {localesData?.sport?.PLACE}%
                        </TableCell>
                        <TableCell className={classes.head}>
                          {localesData?.sport?.AVG_WIN_ODDS}
                        </TableCell>
                        <TableCell className={classes.head}>
                          {localesData?.sport?.ROI}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {TableContent?.length > 0 ? (
                        TableContent?.map((item, i) => {
                          return (
                            <>
                              <TableRow key={i} className="table-body-row ">
                                <StickyTableCell
                                  className={`${classes.cell} `}
                                  // onClick={() => handlenavigation(item)}
                                >
                                  {item?.name}
                                </StickyTableCell>
                                <TableCell className={classes.cell}>
                                  {item?.starts}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.first}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.second}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.third}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.win
                                    ? Number(item?.win).toFixed(2) + "% "
                                    : 0}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.place
                                    ? Number(item?.place).toFixed(2) + "%"
                                    : 0}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.avgWinOdd
                                    ? "$" + Number(item?.avgWinOdd).toFixed(2)
                                    : 0}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.roi}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12} className="no-data-td">
                            {/* No Data Available */}
                            <NoDataComp />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        ) : (
          <>
            <Box className="trackprofile-mobile-details">
              <Typography variant="h6" className="trackprofile-heading">
                {Name}
                <br />
                <span className="head-row-span">{dataName}</span>
              </Typography>
              {isLoading && (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              )}
              {!isLoading &&
                (TableContent?.length > 0 ? (
                  TableContent?.map((item, index) => (
                    <>
                      <Box
                        className={
                          collapseMenu !== index
                            ? "trackprofile-border-bottom trackprofile-deatils"
                            : "trackprofile-deatils"
                        }
                        onClick={() => {
                          return setcollapseMenu(
                            collapseMenu === index ? -1 : index
                          );
                        }}
                      >
                        <Typography className="details-name">
                          {item?.name}
                        </Typography>
                        <Box className="arrow-details">
                          <IconButton aria-label="expand row" size="small">
                            {collapseMenu === index ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </Box>
                      </Box>
                      <Collapse
                        in={collapseMenu === index}
                        timeout="auto"
                        unmountOnExit
                        className="collapse-box collapse-box-bottom"
                      >
                        <Box className="collapse-deatils">
                          <Table className="collapse-table">
                            <TableRow className="collapse-table-row">
                              <TableCell className="td-left">
                                {localesData?.sport?.STARTS}
                              </TableCell>
                              <TableCell className="td-right">
                                {" "}
                                {item?.starts}
                              </TableCell>
                            </TableRow>
                            <TableRow className="collapse-table-row">
                              <TableCell className="td-left">1st</TableCell>
                              <TableCell className="td-right">
                                {item?.first}
                              </TableCell>
                            </TableRow>
                            <TableRow className="collapse-table-row">
                              <TableCell className="td-left">2nd</TableCell>
                              <TableCell className="td-right">
                                {item?.second}
                              </TableCell>
                            </TableRow>
                            <TableRow className="collapse-table-row">
                              <TableCell className="td-left">3rd</TableCell>
                              <TableCell className="td-right">
                                {item?.third}
                              </TableCell>
                            </TableRow>
                            <TableRow className="collapse-table-row">
                              <TableCell className="td-left">
                                {localesData?.sport?.WIN}%
                              </TableCell>
                              <TableCell className="td-right">
                                {item?.win
                                  ? Number(item?.win).toFixed(2) + "%"
                                  : 0}
                              </TableCell>
                            </TableRow>
                            <TableRow className="collapse-table-row">
                              <TableCell className="td-left">
                                {localesData?.sport?.PLACE}%
                              </TableCell>
                              <TableCell className="td-right">
                                {item?.place
                                  ? Number(item?.place).toFixed(2) + "%"
                                  : 0}
                              </TableCell>
                            </TableRow>
                            <TableRow className="collapse-table-row">
                              <TableCell className="td-left">
                                {localesData?.sport?.AVG_WIN_ODDS}
                              </TableCell>
                              <TableCell className="td-right">
                                {item?.avgWinOdd
                                  ? "$" + Number(item?.avgWinOdd).toFixed(2)
                                  : 0}
                              </TableCell>
                            </TableRow>
                            <TableRow className="collapse-table-row">
                              <TableCell className="td-left">
                                {" "}
                                {localesData?.sport?.ROI}
                              </TableCell>
                              <TableCell className="td-right">
                                {item?.roi}
                              </TableCell>
                            </TableRow>
                          </Table>
                        </Box>
                        {/* <Box className="view-profile-box">
                        <Typography
                          variant="h6"
                          className="view-profile"
                          onClick={() => handlenavigation(item)}
                        >
                          View Profile
                        </Typography>
                      </Box> */}
                      </Collapse>
                    </>
                  ))
                ) : (
                  <Box className="no-data-mobile">
                    {/* No Data Available */}
                    <NoDataComp />
                  </Box>
                ))}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default StatisticsTable;
