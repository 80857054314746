import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Breadcrumbs,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  Checkbox,
  TableContainer,
  TextField,
  TableSortLabel,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import banner from "../../../assets/images/banner/smart-b-banner-primary.png";
import bannerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import { Link, useLocation } from "react-router-dom";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import OurPartner from "../ourPartners";
import { useParams } from "react-router-dom";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import Select, { components } from "react-select";
import { ReactComponent as LeftArrow } from "src/assets/images/left-arrow.svg";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import { ReactComponent as FirstRank } from "src/assets/images/icons/first_rank.svg";
import { ReactComponent as SecondRank } from "src/assets/images/icons/second_rank.svg";
import { ReactComponent as ThirdRank } from "src/assets/images/icons/third_rank.svg";
import { ReactComponent as SortingArrow } from "src/assets/images/icons/sorting-arrow.svg";
import { ReactComponent as Unchecked } from "../../../assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Checked } from "../../../assets/images/icons/smartb-check.svg";
import playerAvtar from "../../../assets/images/player-avatar.png";
import AdBannner from "../AdBanner/AdBanner";
import { useDispatch, useSelector } from "react-redux";
import "./rankings.scss";
import moment from "moment-timezone";
import NoDataComp from "../UI/NoData";
import { release } from "src/helpers/context/release";
import { Config } from "src/helpers/context";

const StickyTableCell = withStyles(theme => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 98,
    minWidth: "338px",
    width: "338px",
    backgroundColor: "#ffffff",
    boxShadow: "inset -2px 0px 6px 0px #ebebeb",
    "@media(max-width: 1090px)": {
      minWidth: "300px",
      width: "300px"
    },
    "@media(max-width: 479px)": {
      minWidth: "200px",
      width: "200px"
    }
  },
  body: {
    left: 0,
    position: "sticky",
    zIndex: 90,
    minWidth: "338px",
    width: "338px",
    backgroundColor: "#ffffff",
    boxShadow: "inset -2px 0px 6px 0px #ebebeb",
    "@media(max-width: 1090px)": {
      minWidth: "300px",
      width: "300px"
    },
    "@media(max-width: 479px)": {
      minWidth: "200px",
      width: "200px"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const tippingTypeRadio = [
  {
    label: "Winner tipping",
    value: "winning",
    id: 1
  },
  {
    label: "Odds tipping",
    value: "odds",
    id: 2
  },
  {
    label: "Line tipping",
    value: "spread",
    id: 3
  }
];

const RankingsPage = () => {
  const params = useParams();
  const classes = useStyles();
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const [rankingList, setrankingList] = useState([]);
  const [compInfo, setCompInfo] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [roundDateInfo, setRoundDateInfo] = useState({});
  const [rankingCount, setrankingCount] = useState(0);
  const [rankingOffset, setrankingOffset] = useState(0);
  const [stepperCount, setStepperCount] = useState("");
  const [dummyStepperCount, setDummyStepperCount] = useState(0);
  const [rounds, setRounds] = useState([]);
  const [newRoundDataOption, setNewRoundDataOption] = useState([]);
  const [allComps, setAllComps] = useState([]);
  const [originalComps, setOriginalComps] = useState([]);
  const [compCount, setCompCount] = useState(0);
  const [selectedComp, setSelectedComp] = useState(null);
  const [sports, setSports] = useState([]);
  const [selectedSports, setSelectedSports] = useState("");
  const [teamsAll, setTeamsAll] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState(null);
  const [OrgAll, setOrgAll] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedOrgName, setSelectedOrgName] = useState("");
  const [OrgApiCount, setOrgApiCount] = useState(0);
  const [isOrgSearch, setIsOrgSearch] = useState("");
  const [countOrg, setcountOrg] = useState(0);
  const [isOrgSelectOpen, setisOrgSelectOpen] = useState(false);
  const [searchOrg, setsearchOrg] = useState([]);
  const [searchOrgCount, setsearchOrgCount] = useState(0);
  const [SearchOrgpage, setSearchOrgpage] = useState(0);
  const [pageOrg, setpageOrg] = useState(0);
  const [selectedCompsType, setSelectedCompsType] = useState(0);
  const [compPage, setCompPage] = useState(1);
  const [isPublicCompsSearch, setIsPublicCompsSearch] = useState("");
  const [searchPublicComps, setsearchPublicComps] = useState([]);
  const [searchPublicCompsCount, setsearchPublicCompsCount] = useState(0);
  const [SearchPublicCompspage, setSearchPublicCompspage] = useState(1);
  const [isValue, setIsValue] = useState(false);
  const [errorNoRound, setNoRoundError] = useState(false);
  const [aflRoundZeroFlag, setAflRoundZeroFlag] = useState(true);
  const [sortType, setSortType] = useState("totalPoints");
  const [sortRound, setSortRound] = useState(true);
  const [sortOverallRound, setSortOverallRound] = useState(false);
  const [sortWinPoint, setSortWinPoint] = useState(true);
  const [selectedTipsType, setSelectedTipsType] = useState("winning");
  const rowperPage = 10;

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    // fetchRoundData(
    //   Number(params?.tournamentId),
    //   Number(params?.SportId),
    //   Number(params?.compsId)
    // );
    // fetchPublicComp(0, 0, null, 1);
    fetchSportData();
  }, []);

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchSportData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/sport?sportTypeId=${2}&competition=true`
      );
      if (status === 200) {
        const releaseFilterSportData = data?.result?.filter(item =>
          release[Config.release]?.sportId?.includes(item?.id)
        );
        var sportsdata = releaseFilterSportData?.map(s => {
          return { ...s, label: s?.sportName, value: s?.id };
        });
        // var sportsdata = data?.result?.map((s) => {
        //   return { ...s, label: s?.sportName, value: s?.id };
        // });
        var sportData = _.orderBy(sportsdata, ["label"], ["asc"]);
        // var mergedArray = _.concat(
        //   [{ label: "All Sports", value: null, isAll: true }],
        //   sportData
        // );
        setSports(sportData);
        setSelectedSports(sportData?.[0]?.value);
        fetchOrgData(0, sportData?.[0]?.value, []);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchOrgData = async (page, sID, OrgAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/public/tournament?SportId=${sID}&offset=${page}&limit=${20}&competition=true`
      );
      // const { status, data } = await axiosInstance.get(
      //   `/allsport/tournament?SportId=${sID}&offset=${page}&limit=${20}`
      // );
      if (status === 200) {
        setOrgApiCount(OrgApiCount + 1);
        setcountOrg(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          item?.name !== null &&
            newdata.push({
              label: item?.name,
              value: item?.id
            });
        });
        let filterData = _.unionBy(OrgAll, newdata);
        // let allteamdatas = filterData?.unshift({
        //   label: "All",
        //   value: 0,
        // });
        const uniqueData = _.uniqBy(filterData, function(e) {
          return e.value;
        })?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });
        setSelectedOrg(uniqueData?.[0]?.value);
        setOrgAll(uniqueData);
        // getRankingList(
        //   0,
        //   [],
        //   0,
        //   false,
        //   uniqueData?.[0]?.value,
        //   sID,
        //   null,
        //   null
        // );
        getLastRound(sID, uniqueData?.[0]?.value);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOrgInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/public/tournament?SportId=${sid}&limit=${20}&offset=${page}&search=${value}&competition=true`
      )
      // .get(
      //   `/allsport/tournament?SportId=${sid}&limit=${20}&offset=${page}&search=${value}`
      // )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchOrgCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const mergeData = _.unionBy(searchOrg, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchOrg(filterData);
          setIsOrgSearch(value);
        }
      });
  };

  const handleOnScrollBottomOrg = () => {
    if (
      isOrgSearch !== "" &&
      searchOrgCount !== Math.ceil(SearchOrgpage / 20)
    ) {
      handleOrgInputChange(SearchOrgpage + 20, isOrgSearch, selectedSports);

      setSearchOrgpage(SearchOrgpage + 20);
    } else {
      if (countOrg !== 0 && countOrg !== Math.ceil(pageOrg / 20 + 1)) {
        fetchOrgData(pageOrg + 20, selectedSports, OrgAll);
        setpageOrg(pageOrg + 20);
      }
    }
  };

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  // useEffect(() => {
  //   if (stepperCount) {
  //     getRankingList(0, [], stepperCount, false,
  // Number(params?.tournamentId),
  // Number(params?.SportId),
  // Number(params?.compsId));
  //   } else {
  //     getRankingList(0, [], 1, false,Number(params?.tournamentId),
  // Number(params?.SportId),
  // Number(params?.compsId));
  //   }
  // }, [params, stepperCount]);
  // useEffect(() => {
  //   if (dummyStepperCount) {
  //     getRankingList(
  //       0,
  //       rounds?.length > 0 ? rounds : [1],
  //       dummyStepperCount,
  //       false,
  //       Number(params?.tournamentId),
  //       Number(params?.SportId),
  //       Number(params?.compsId)
  //     );
  //   }
  // }, [dummyStepperCount]);

  const getLastRound = async (sId, tId) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/getLastRound?SportId=${sId ? sId : ""}&tournamentId=${
          tId ? tId : ""
        }`
      );
      if (status === 200) {
        // setLastRound(data?.result?.lastRound ? data?.result?.lastRound : 99);
        setNewRoundDataOption(
          data?.result?.roundList?.map(round => ({
            value: round?.round,
            displayName: round?.displayName,
            label: `${
              round?.displayName
                ? round?.displayName
                : (sId === 4 ? "Day" : "Round") + " " + round?.round
            }`
          }))
        );
        const finalRound = data?.result?.currentRound?.toString()
          ? data?.result?.currentRound
          : data?.result?.firstRound;
        setDummyStepperCount(finalRound);
        setSortRound(true);
        setSortOverallRound(false);
        setSortType("totalPoints");
        getRankingList(
          0,
          [],
          finalRound,
          false,
          tId,
          sId,
          null,
          null,
          "totalPoints",
          false,
          "winning"
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getRankingList = async (
    offset,
    rankingLists,
    round,
    hasMore,
    tId,
    sId,
    cId,
    orgName,
    type,
    order,
    tippingType
  ) => {
    setLoading(true);

    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/ranking?offset=${offset}&SportId=${
          sId ? sId : ""
        }&tournamentId=${tId ? tId : ""}&limit=10&competitionId=${
          cId ? cId : ""
        }&type=public&round=${Number(round)}&sort=${type ? type : ""}&orderBy=${
          order ? "asc" : "desc"
        }&tippingType=${tippingType}`
      );
      // const { status, data } = await axiosInstance.get(
      //   `/tipping/ranking?offset=${offset}&SportId=${
      //     sId ? sId : ""
      //   }&tournamentId=${tId ? tId : ""}&limit=10&competitionId=${
      //     cId ? cId : ""
      //   }&type=public&round=${
      //     orgName == "AFL" ? Number(round) + 1 : Number(round)
      //   }`
      // );
      if (status === 200) {
        setLoading(false);
        setrankingOffset(offset);
        setrankingCount(data?.result?.length);
        setCurrentUser(data?.currentUser);
        setRoundDateInfo(data?.roundInfo);
        const rankingDatas = hasMore
          ? [...rankingLists, ...data?.result]
          : data?.result;
        setrankingList(rankingDatas);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const fetchPublicComp = async (sports, tournament, type, page) => {
    setCompPage(page);
    try {
      const { status, data } = await axiosInstance.get(
        `tipping/getAll?SportId=${sports ? sports : ""}&tournamentId=${
          tournament ? tournament : ""
        }&privacy=public&limit=${rowperPage}&page=${page}&margin=true`
      );
      if (status === 200) {
        const count = Math.ceil(data?.count / rowperPage);
        setCompCount(count);
        let newdata = [];
        let track = data?.result?.map(item => {
          newdata.push({
            label: item?.competitionName,
            value: item?.id
          });
        });

        setOriginalComps(newdata);

        // let filterData = [...allComps, ...newdata];
        let filterData = [...newdata];
        let allteamdatas = filterData?.unshift({
          label: "All Competition",
          value: 0
        });
        const uniqueData = _.uniqBy(filterData, function(e) {
          return e.value;
        });
        setAllComps(uniqueData);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handlePublicCompsInputChange = (page, value) => {
    axiosInstance
      .get(
        `tipping/getAll?SportId=${
          selectedSports ? selectedSports : ""
        }&tournamentId=${
          selectedOrg ? selectedOrg : ""
        }&privacy=public&limit=${rowperPage}&page=${page}&margin=true&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result;
          setsearchPublicCompsCount(Math.ceil(res?.data?.count / rowperPage));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.competitionName,
              value: item?.id
            });
          });
          const mergeData = _.unionBy(allComps, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchPublicComps(filterData);
          setIsPublicCompsSearch(value);
          setSearchPublicCompspage(page);
        }
      });
  };

  const handleOnScrollBottomPublicComps = () => {
    if (
      isPublicCompsSearch !== "" &&
      searchPublicCompsCount !== Math.ceil(SearchPublicCompspage)
    ) {
      handlePublicCompsInputChange(
        SearchPublicCompspage + 1,
        isPublicCompsSearch
      );

      setSearchPublicCompspage(SearchPublicCompspage + 1);
    } else {
      if (compCount !== 0 && compCount !== Math.ceil(compPage)) {
        fetchPublicComp(
          selectedSports,
          selectedOrg,
          selectedCompsType,
          compPage + 1
        );
        setCompPage(compPage + 1);
      }
    }
  };

  const handleMoreData = () => {
    setrankingOffset(rankingOffset + 10);
    getRankingList(
      rankingOffset + 10,
      rankingList,
      selectedComp ? stepperCount : dummyStepperCount,
      true,
      selectedOrg,
      selectedSports,
      selectedComp,
      selectedOrgName,
      sortType,
      sortType == "roundPoints"
        ? sortRound
        : sortType === "totalPoints"
        ? sortOverallRound
        : sortWinPoint,
      selectedTipsType
    );
  };

  const handleLessData = () => {
    // scrollToTopSection();
    setrankingOffset(rankingOffset - 10);
    if (rankingList?.length % 10 === 0) {
      setrankingList(prevArray => prevArray.slice(0, -10));
    } else {
      setrankingList(prevArray => prevArray.slice(0, -rankingCount));
    }
  };
  // const fetchDayandTime = () => {
  //   if (compInfo?.cutOffTime) {
  //     const dayWithTime = moment
  //       .utc(`${compInfo?.cutOffWeek} ${compInfo?.cutOffTime}`, "dddd HH:mm:ss")
  //       .tz(timezone)
  //       .format("h:mm A");
  //     return <span>{compInfo?.cutOffWeek + " | " + dayWithTime}</span>;
  //   }
  // };
  // const fetchRoundData = async (tID, sID, cID) => {
  //   const date = moment(Date()).format("YYYY-MM-DD");
  //   // &startDate=${date}
  //   try {
  //     const { status, data } = await axiosInstance.get(
  //       `/tipping/rounds?timezone=${timezone}&tournamentId=${tID}&SportId=${sID}&competitionId=${cID}`
  //     );
  //     if (status === 200) {
  //       let newdata = [];
  //       let track = data?.result?.map(item => {
  //         newdata.push({
  //           label: `Round ${Number(item)}`,
  //           value: item
  //         });
  //       });
  //       // let track = data?.result?.map(item => {
  //       //   newdata.push({
  //       //     label: `Round ${
  //       //       selectedOrgName == "AFL" ? Number(item) - 1 : Number(item)
  //       //     }`,
  //       //     value: item
  //       //   });
  //       // });
  //       data?.result?.length > 0 &&
  //         getRankingList(
  //           rankingOffset,
  //           rankingList,
  //           data?.current ? data?.current : data?.result?.[0],
  //           false,
  //           selectedOrg,
  //           selectedSports,
  //           cID,
  //           selectedOrgName
  //         );
  //       setRounds(data?.result);
  //       setStepperCount(
  //         data?.current
  //           ? data?.current
  //           : data?.result?.length > 0
  //           ? data?.result?.[0]
  //           : 1
  //       );
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const sortLabelHandler = type => {
    setSortType(type);
    if (type === "roundPoints") {
      // getLadderList(ladderOffset, [], stepperCount, type, !sortRound);
      getRankingList(
        0,
        [],
        dummyStepperCount,
        false,
        selectedOrg,
        selectedSports,
        null,
        null,
        type,
        !sortRound,
        selectedTipsType
      );
      setSortRound(!sortRound);
      setSortOverallRound(true);
      setSortWinPoint(true);
    } else if (type === "totalPoints") {
      // getLadderList(ladderOffset, [], stepperCount, type, !sortOverallRound);
      getRankingList(
        0,
        [],
        dummyStepperCount,
        false,
        selectedOrg,
        selectedSports,
        null,
        null,
        type,
        !sortOverallRound,
        selectedTipsType
      );
      setSortRound(true);
      setSortOverallRound(!sortOverallRound);
      setSortWinPoint(true);
    } else {
      getRankingList(
        0,
        [],
        dummyStepperCount,
        false,
        selectedOrg,
        selectedSports,
        null,
        null,
        type,
        !sortWinPoint,
        selectedTipsType
      );
      setSortRound(true);
      setSortOverallRound(true);
      setSortWinPoint(!sortWinPoint);
    }
  };

  const startingEndingDate = (startDate, endDate) => {
    return (
      <span>
        :{" "}
        {moment(startDate)
          .tz(timezone)
          .format("dddd DD/MM/YYYY")}{" "}
        {"- "}
        {moment(endDate)
          .tz(timezone)
          .format("dddd DD/MM/YYYY")}
      </span>
    );
  };

  const handleSelectChange = selectedOption => {
    setDummyStepperCount(selectedOption.value);
    getRankingList(
      0,
      rankingList,
      selectedOption.value,
      false,
      selectedOrg,
      selectedSports,
      selectedComp,
      selectedOrgName,
      "totalPoints",
      false,
      selectedTipsType
    );
    setSortRound(true);
    setSortOverallRound(false);
    setSortType("totalPoints");
  };
  return (<>
    <Box className="content-wrap">
      <Box className="mobile-banner">
        {fetchPageHeaderAds(
          "218px",
          Config?.release == "IN" ? bannerIndia : banner
        )}
      </Box>

      <Box className="ranking-comp-wrap">
        <Box className="ranking-comp-header">
          <Box className="bredcrumn-wrap">
            <Breadcrumbs
              separator="/"
              aria-label="breadcrumb"
              className="breadcrumb"
            >
              <Link underline="hover" color="inherit" to="/">
                HOME
              </Link>

              <Link
                underline="hover"
                color="inherit"
                to=""
                className="cursor-default"
              >
                Tipping Competition
              </Link>

              <Typography>Rankings</Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="h1">Rankings</Typography>
        </Box>
        <Box className="tab-search-section">
          <Box className="filter-section">
            <Select
              className="React sort-select"
              value={sports?.find(item => {
                return item?.value === selectedSports;
              })}
              onChange={e => {
                setAllComps([]);
                setSelectedComp(null);
                // setDummyStepperCount(0);
                setSelectedSports(e?.value == 0 ? "" : e?.value);
                setCurrentPage(0);
                // setSelectedOrg(null);
                // setSelectedOrgName("");
                // getRankingList(
                //   rankingOffset,
                //   rankingList,
                //   0,
                //   false,
                //   "",
                //   e?.value ? e?.value : "",
                //   "",
                //   selectedOrgName
                // );
                // getLastRound(e?.value ? e?.value : "", null);
                // setOrgAll([]);
                // fetchPublicComp(e?.value, null, selectedCompsType, 1);
                if (e?.value) {
                  fetchOrgData(0, e?.value, []);
                }
              }}
              // onMenuScrollToBottom={e => handleOnScrollBottomSports(e)}
              options={sports}
              classNamePrefix="select"
              placeholder="Select sport"
              // isSearchable={false}
              // onFocus={() => setTournamentSelect(true)}
              // onBlur={() => setTournamentSelect(false)}
              components={{ DropdownIndicator }}
            />
            <Select
              className={`React sort-select ${
                selectedSports ? "" : "disable-state"
              }`}
              onMenuScrollToBottom={e => handleOnScrollBottomOrg(e)}
              onInputChange={e => handleOrgInputChange(0, e, selectedSports)}
              onChange={e => {
                setAllComps([]);
                setSelectedOrg(e?.value);
                setSelectedOrgName(e?.label);
                setOrgApiCount(0);
                setpageOrg(0);
                setisOrgSelectOpen(false);
                setCurrentPage(0);
                setSelectedComp(null);
                getLastRound(selectedSports, e?.value ? e?.value : "");
                // getRankingList(
                //   rankingOffset,
                //   rankingList,
                //   0,
                //   false,
                //   e?.value ? e?.value : "",
                //   selectedSports,
                //   "",
                //   e?.label
                // );
                // fetchPublicComp(
                //   selectedSports,
                //   e?.value,
                //   selectedCompsType,
                //   1
                // );
              }}
              onFocus={() => setisOrgSelectOpen(true)}
              onBlur={() => setisOrgSelectOpen(false)}
              value={
                selectedOrg &&
                (isOrgSearch
                  ? searchOrg?.find(item => {
                      return item?.value == selectedOrg;
                    })
                  : OrgAll?.find(item => {
                      return item?.value == selectedOrg;
                    }))
              }
              options={isOrgSearch ? searchOrg : OrgAll}
              classNamePrefix="select"
              placeholder="Select league"
              isDisabled={selectedSports ? false : true}
              components={{ DropdownIndicator }}
            />
            {/* <Select
              className={`React sort-select ${
                selectedOrg ? "" : "disable-state"
              }`}
              onMenuScrollToBottom={e => handleOnScrollBottomPublicComps(e)}
              onInputChange={e => handlePublicCompsInputChange(1, e)}
              value={
                selectedComp &&
                (isPublicCompsSearch
                  ? searchPublicComps?.find(item => {
                      return item?.value === selectedComp;
                    })
                  : allComps?.find(item => {
                      return item?.value === selectedComp;
                    }))
              }
              onChange={e => {
                setSelectedComp(e?.value);
                fetchRoundData(
                  selectedOrg,
                  selectedSports,
                  e?.value ? e?.value : ""
                );
              }}
              options={isPublicCompsSearch ? searchPublicComps : allComps}
              classNamePrefix="select"
              placeholder="Competition Name"
              isDisabled={selectedOrg ? false : true}
              components={{ DropdownIndicator }}
            /> */}

            <Box className="filter-list-racing">
              <ul className="Filteritemlist-racing tipping-type-racing">
                {tippingTypeRadio?.map((obj, i) => (
                  <li key={i}>
                    <FormControl>
                      <label>
                        <Checkbox
                          className="filter-racing"
                          icon={<Unchecked />}
                          checkedIcon={<Checked />}
                          name="filter"
                          onChange={() => {
                            setSelectedTipsType(obj?.value);
                            getRankingList(
                              0,
                              [],
                              dummyStepperCount,
                              false,
                              selectedOrg,
                              selectedSports,
                              null,
                              null,
                              "totalPoints",
                              false,
                              obj?.value
                            );
                          }}
                          checked={selectedTipsType === obj?.value}
                        />
                        <span className="badge-icon-wrap">
                          {obj?.icon ? obj?.icon : ""}
                          {obj?.label}
                        </span>
                      </label>
                    </FormControl>
                  </li>
                ))}
              </ul>
            </Box>
          </Box>

          <Box className="stepper-score-sec">
            {!selectedComp ? (
              <Box className="stepper-input">
                <Button
                  className="stepper-input__button"
                  onClick={e => {
                    e.preventDefault();
                    const index = newRoundDataOption?.findIndex(
                      item => item?.value === dummyStepperCount
                    );

                    setDummyStepperCount(
                      newRoundDataOption[index - 1]?.value
                    );
                    setSortRound(true);
                    setSortOverallRound(false);
                    setSortType("totalPoints");
                    getRankingList(
                      0,
                      rankingList,
                      Number(dummyStepperCount) - 1,
                      false,
                      selectedOrg,
                      selectedSports,
                      selectedComp,
                      selectedOrgName,
                      "totalPoints",
                      false,
                      selectedTipsType
                    );
                  }}
                  disabled={
                    !dummyStepperCount ||
                    dummyStepperCount === newRoundDataOption[0]?.value
                      ? true
                      : false
                  }
                  // disabled={
                  //   (selectedOrgName === "AFL"
                  //     ? dummyStepperCount == 0
                  //     : dummyStepperCount == 1) ||
                  //   dummyStepperCount == "" ||
                  //   dummyStepperCount == null ||
                  //   lastRound == null
                  // }
                  startIcon={<LeftArrow />}
                >
                  Previous
                </Button>

                {/* <div className="stepper-input__content">
                  {dummyStepperCount
                    ? `Round ${
                        selectedOrgName == "AFL"
                          ? Number(dummyStepperCount) - 1
                          : Number(dummyStepperCount)
                      }`
                    : `Round 1`}
                </div> */}

                <>
                  {/* Round{" "} */}
                  <Select
                    value={newRoundDataOption?.find(
                      item => item?.value === dummyStepperCount
                    )}
                    onChange={handleSelectChange}
                    options={newRoundDataOption}
                    className="React tipping-select"
                    classNamePrefix="select"
                    isSearchable={false}
                  />

                  {/* <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="margin-textfield"
                    // className="stepper-input__content"
                    name="compName"
                    disabled={lastRound == null}
                    onChange={e => {
                      const input = e?.target?.value;

                      const numericInput = input?.replace(/[^0-9]/g, "");
                      const newInput =
                        selectedSports == 4
                          ? input == `Day ${numericInput}`
                          : input == `Round ${numericInput}`;
                      if (/^\d{0,2}$/.test(numericInput)) {
                        // setDummyStepperCount(
                        //   numericInput
                        //     ? Number(numericInput)
                        //     : selectedOrgName == "AFL"
                        //     ? 0
                        //     : null
                        // );
                        setDummyStepperCount(
                          numericInput ? Number(numericInput) : 0
                        );

                        // !numericInput &&
                        // numericInput <= 0 &&
                        // selectedOrgName !== "AFL"
                        //   ? setAflRoundZeroFlag(false)
                        //   : setAflRoundZeroFlag(true);

                        if (
                          numericInput !== "" &&
                          parseInt(numericInput) > lastRound
                        ) {
                          setNoRoundError(true);
                        } else {
                          setNoRoundError(false);
                        }

                        newInput &&
                          parseInt(numericInput) <= lastRound &&
                          numericInput !== "00" &&
                          // numericInput !== "" &&
                          getRankingList(
                            0,
                            rankingList,
                            numericInput ? Number(numericInput) : 0,
                            false,
                            selectedOrg,
                            selectedSports,
                            selectedComp,
                            selectedOrgName,
                            "totalPoints",
                            false,
                            selectedTipsType
                          );
                        setSortRound(true);
                        setSortOverallRound(false);
                        setSortType("totalPoints");
                      }
                    }}
                    onFocus={e => {
                      e.target.select();
                      setIsValue(true);
                      // selectedOrgName == "AFL" && !isValue
                      //   ? dummyStepperCount
                      //     ? setDummyStepperCount(
                      //         Number(dummyStepperCount) - 1
                      //       )
                      //     : ""
                      //   : dummyStepperCount
                      //   ? setDummyStepperCount(Number(dummyStepperCount))
                      //   : "";
                    }}
                    onBlur={() => setIsValue(false)}
                    // value={`Round ${
                    //   selectedOrgName == "AFL"
                    //     ? //  && !isValue
                    //       dummyStepperCount
                    //       ? Number(dummyStepperCount)
                    //       : ""
                    //     : dummyStepperCount
                    //     ? Number(dummyStepperCount)
                    //     : ""
                    // }`}
                    // value={`Round ${
                    //   dummyStepperCount
                    //     ? selectedOrgName == "AFL"
                    //       ? Number(dummyStepperCount) - 1
                    //       : Number(dummyStepperCount)
                    //     : ""
                    // }`}
                    value={
                      selectedSports == 4
                        ? `Day ${Number(dummyStepperCount)}`
                        : `Round ${Number(dummyStepperCount)}`
                    }
                    // value={
                    //   dummyStepperCount
                    //     ? `Round ${
                    //         selectedOrgName == "AFL"
                    //           ? Number(dummyStepperCount) - 1
                    //           : Number(dummyStepperCount)
                    //       }`
                    //     : `Round ${1}`
                    // }
                  /> */}
                </>

                <Button
                  className="stepper-input__button Next-btn"
                  onClick={e => {
                    e.preventDefault();
                    const index = newRoundDataOption?.findIndex(
                      item => item?.value === dummyStepperCount
                    );

                    if (index - 1 !== -1) {
                      setDummyStepperCount(
                        newRoundDataOption[index + 1]?.value
                      );
                      setSortRound(true);
                      setSortOverallRound(false);
                      setSortType("totalPoints");

                      getRankingList(
                        0,
                        rankingList,
                        newRoundDataOption[index + 1]?.value,
                        false,
                        selectedOrg,
                        selectedSports,
                        selectedComp,
                        selectedOrgName,
                        "totalPoints",
                        false,
                        selectedTipsType
                      );
                    }

                    // setStepperCount(rounds[index + 1]);
                  }}
                  disabled={
                    dummyStepperCount == null ||
                    dummyStepperCount < 0 ||
                    dummyStepperCount ===
                      newRoundDataOption[newRoundDataOption?.length - 1]
                        ?.value
                      ? true
                      : false
                  }
                  // disabled={
                  //   !stepperCount ||
                  //   stepperCount === rounds[rounds.length - 1]
                  // }
                  endIcon={<RightArrow />}
                >
                  Next
                </Button>
              </Box>
            ) : (
              (<></>)
              // <Box className="stepper-input">
              //   <Button
              //     className="stepper-input__button"
              //     onClick={e => {
              //       const index = rounds?.indexOf(stepperCount);
              //       e.preventDefault();
              //       setStepperCount(rounds[index - 1]);
              //       getRankingList(
              //         rankingOffset,
              //         rankingList,
              //         rounds[index - 1],
              //         false,
              //         selectedOrg,
              //         selectedSports,
              //         selectedComp,
              //         selectedOrgName,
              //         sortType,
              //         false
              //       );
              //     }}
              //     disabled={!stepperCount || stepperCount === rounds[0]}
              //     startIcon={<LeftArrow />}
              //   >
              //     Previous
              //   </Button>

              //   <div className="stepper-input__content">
              //     {stepperCount ? `Round ${Number(stepperCount)}` : `Round 0`}
              //   </div>
              //   {/* <div className="stepper-input__content">
              //     {stepperCount
              //       ? `Round ${
              //           selectedOrgName == "AFL"
              //             ? Number(stepperCount) - 1
              //             : Number(stepperCount)
              //         }`
              //       : `Round 1`}
              //   </div> */}

              //   <Button
              //     className="stepper-input__button Next-btn"
              //     onClick={e => {
              //       const index = rounds?.indexOf(stepperCount);
              //       e.preventDefault();
              //       setStepperCount(rounds[index + 1]);
              //       getRankingList(
              //         rankingOffset,
              //         rankingList,
              //         rounds[index + 1],
              //         false,
              //         selectedOrg,
              //         selectedSports,
              //         selectedComp,
              //         selectedOrgName,
              //         sortType,
              //         false
              //       );
              //     }}
              //     endIcon={<RightArrow />}
              //     disabled={
              //       !stepperCount ||
              //       stepperCount === rounds[rounds.length - 1]
              //     }
              //   >
              //     Next
              //   </Button>
              // </Box>
            )}

            <Box className="score-share-sec">
              <Box className="score-sec">
                <Box className="bottom-line-sec">
                  <Typography className="final-txt">
                    {selectedSports == 4 ? "Day" : "Round"}{" "}
                    {Number(dummyStepperCount)}
                    {startingEndingDate(
                      roundDateInfo?.startOfRound,
                      roundDateInfo?.endOfRound
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="page-deatils-wrap">
          <>
            <Box>
              {loading ? (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              ) : // dummyStepperCount !== 0 &&
              //   selectedOrgName !== "AFL" &&
              // dummyStepperCount > 0 &&
              // aflRoundZeroFlag &&

              dummyStepperCount !== null &&
                rankingList &&
                rankingList?.length > 0 ? (
                <>
                  <Box className="new-ladder-sec">
                    <Table
                      style={{ minWidth: screenWidth > 799 ? 940 : 370 }}
                      className="new-ladder-table"
                    >
                      <TableHead>
                        <TableRow>
                          <StickyTableCell className={`${classes.head}`}>
                            <Table className="sticky-cell-table">
                              <TableRow>
                                <TableCell className="sticky-cell-table-pos">
                                  #Rank
                                </TableCell>
                                <TableCell>Tipster name</TableCell>
                              </TableRow>
                            </Table>
                          </StickyTableCell>
                          {/* <TableCell
                            onClick={() => sortLabelHandler("winPoint")}
                            className="primary-font-color table-cell-center"
                          >
                            <Box className="sorting-cell">
                              Total Weekly Wins
                              <TableSortLabel
                                active={true}
                                direction={
                                  sortType === "winPoint"
                                    ? sortWinPoint
                                      ? "asc"
                                      : "desc"
                                    : "desc"
                                }
                              />
                              <span className="sorting-arrow-wrap">
                                <SortingArrow
                                  className={`${
                                    sortType == "winPoint" &&
                                    sortWinPoint == true
                                      ? "active"
                                      : ""
                                  }`}
                                />
                                <SortingArrow
                                  className={`${
                                    sortType == "winPoint" &&
                                    sortWinPoint == false
                                      ? "active"
                                      : ""
                                  } down-arrow`}
                                />
                              </span>
                            </Box>
                          </TableCell> */}
                          <TableCell
                            onClick={() => sortLabelHandler("roundPoints")}
                            className="primary-font-color table-cell-center cursor-pointer"
                          >
                            <Box className="sorting-cell">
                              {selectedSports == 4 ? "Day" : "Round"} Points{" "}
                              {/* <TableSortLabel
                              active={true}
                              direction={
                                sortType === "roundPoints"
                                  ? sortRound
                                    ? "asc"
                                    : "desc"
                                  : "desc"
                              }
                            /> */}
                              <span className="sorting-arrow-wrap">
                                <SortingArrow
                                  className={`${
                                    sortType == "roundPoints" &&
                                    sortRound == true
                                      ? "active"
                                      : ""
                                  }`}
                                />
                                <SortingArrow
                                  className={`${
                                    sortType == "roundPoints" &&
                                    sortRound == false
                                      ? "active"
                                      : ""
                                  } down-arrow`}
                                />
                              </span>
                            </Box>
                          </TableCell>
                          {selectedTipsType == "winning" && (
                            <TableCell className="primary-font-color table-cell-center">
                              {selectedSports == 4 ? "Day" : "Round"} Margin
                            </TableCell>
                          )}

                          <TableCell
                            className="primary-font-color table-cell-center cursor-pointer"
                            onClick={() => sortLabelHandler("totalPoints")}
                          >
                            <Box className="sorting-cell">
                              Overall Points{" "}
                              {/* <TableSortLabel
                              active={true}
                              direction={
                                sortType === "totalPoints"
                                  ? sortOverallRound
                                    ? "asc"
                                    : "desc"
                                  : "desc"
                              }
                            /> */}
                              <span className="sorting-arrow-wrap">
                                <SortingArrow
                                  className={`${
                                    sortType == "totalPoints" &&
                                    sortOverallRound == true
                                      ? "active"
                                      : ""
                                  }`}
                                />
                                <SortingArrow
                                  className={`${
                                    sortType == "totalPoints" &&
                                    sortOverallRound == false
                                      ? "active"
                                      : ""
                                  } down-arrow`}
                                />
                              </span>
                            </Box>
                          </TableCell>
                          {selectedTipsType == "winning" && (
                            <TableCell className="primary-font-color table-cell-center">
                              Overall Margin
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentUser && (
                          <TableRow className="current-user-row">
                            <StickyTableCell
                              className={`${classes.cell} current-user-stickycell`}
                            >
                              <Table className="sticky-cell-table">
                                <TableRow>
                                  <TableCell className="highlight-border sticky-cell-table-pos">
                                    {currentUser?.rank == 1 ? (
                                      <FirstRank />
                                    ) : currentUser?.rank == 2 ? (
                                      <SecondRank />
                                    ) : currentUser?.rank == 3 ? (
                                      <ThirdRank />
                                    ) : (
                                      <span className="rank-name">
                                        {currentUser?.rank}
                                      </span>
                                    )}
                                  </TableCell>
                                  <TableCell className="highlight-border">
                                    <Box className="tipper-img-wrap">
                                      <Box
                                        className={
                                          currentUser?.filePath
                                            ? "profile-icon-wrap icon-border"
                                            : "profile-icon-wrap"
                                        }
                                      >
                                        <img
                                          src={
                                            currentUser?.filePath
                                              ? Config?.countryMediaURL +
                                                currentUser?.filePath
                                              : playerAvtar
                                          }
                                          alt="avtar"
                                          className="profile-icon"
                                        />
                                      </Box>
                                      <span>
                                        {currentUser?.firstName}{" "}
                                        {currentUser?.lastName}
                                      </span>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              </Table>
                            </StickyTableCell>
                            {/* <TableCell className="table-cell-center highlight-border">
                              {currentUser?.roundData?.winPoint ?? "-"}
                            </TableCell> */}
                            <TableCell className="table-cell-center highlight-border">
                              {currentUser?.roundData?.roundPoints ?? "-"}
                            </TableCell>
                            {selectedTipsType == "winning" && (
                              <TableCell className="table-cell-center highlight-border">
                                {currentUser?.roundData?.margin ?? "-"}
                              </TableCell>
                            )}
                            <TableCell className="table-cell-center highlight-border">
                              {currentUser?.roundData?.totalPoints ?? "-"}
                            </TableCell>
                            {selectedTipsType == "winning" && (
                              <TableCell className="table-cell-center highlight-border">
                                {currentUser?.roundData?.totalMargin ?? "-"}
                              </TableCell>
                            )}
                          </TableRow>
                        )}
                        {rankingList?.map((item, index) => {
                          return (
                            <TableRow key={index}>
                              <StickyTableCell className={`${classes.cell}`}>
                                <Table className="sticky-cell-table">
                                  <TableRow>
                                    <TableCell className="sticky-cell-table-pos">
                                      {item?.rank ? (
                                        item?.rank == 1 ? (
                                          <FirstRank />
                                        ) : item?.rank == 2 ? (
                                          <SecondRank />
                                        ) : item?.rank == 3 ? (
                                          <ThirdRank />
                                        ) : (
                                          <span className="rank-name">
                                            {item?.rank}
                                          </span>
                                        )
                                      ) : (
                                        <span>{index + 1}</span>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <Box className="tipper-img-wrap">
                                        <Box
                                          className={
                                            item?.filePath
                                              ? "profile-icon-wrap icon-border"
                                              : "profile-icon-wrap"
                                          }
                                        >
                                          <img
                                            src={
                                              item?.filePath
                                                ? Config?.countryMediaURL +
                                                  item?.filePath
                                                : playerAvtar
                                            }
                                            alt="avtar"
                                            className="profile-icon"
                                          />
                                        </Box>
                                        <span>
                                          {item?.firstName} {item?.lastName}
                                        </span>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                </Table>
                              </StickyTableCell>
                              {/* <TableCell className="table-cell-center">
                                {item?.roundData?.winPoint ?? "-"}
                              </TableCell> */}
                              <TableCell className="table-cell-center">
                                {item?.roundData?.roundPoints ?? "-"}
                              </TableCell>
                              {selectedTipsType == "winning" && (
                                <TableCell className="table-cell-center">
                                  {item?.roundData?.margin ?? "-"}
                                </TableCell>
                              )}
                              <TableCell className="table-cell-center">
                                {item?.roundData?.totalPoints ?? "-"}
                              </TableCell>
                              {selectedTipsType == "winning" && (
                                <TableCell className="table-cell-center">
                                  {item?.roundData?.totalMargin ?? "-"}
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>

                    {/* <Table className="table-content">
                  <TableHead>
                    <TableRow className="table-row">
                      <StickyTableCell className={classes.head}>
                        <Box
                          // style={{
                          //   position: "sticky",
                          //   left: 0,
                          //   zIndex: 2,
                          //   background: "white",
                          //   width: "100px"
                          // }}
                          className="table-title-left table-title table-position title-col-1"

                          // className="table-title"
                        >
                          #Pos
                        </Box>
                        <Box
                          // style={{
                          //   position: "sticky",
                          //   left: "100px",
                          //   zIndex: 2,
                          //   background: "white",
                          //   width: "200px"
                          // }}
                          // className="table-title"
                          className="table-title-left table-title title-col-2"

                          //   style={{ width: "32%" }}
                        >
                          Tipper
                        </Box>
                      </StickyTableCell>

                      <TableCell className="table-title txt-center">
                        Round Points
                      </TableCell>
                      <TableCell className="table-title txt-center">
                        Round Margin
                      </TableCell>
                      <TableCell className="table-title txt-center">
                        Overall Points
                      </TableCell>
                      <TableCell className="table-title txt-center">
                        Overall Margin
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentUser && (
                      <TableRow className="table-row">
                        <StickyTableCell
                          className={`border  ${classes.body}`}
                        >
                          <Box
                            // style={{
                            //   position: "sticky",
                            //   left: 0,
                            //   zIndex: 2,
                            //   background: "white",
                            //   width: "100px"
                            // }}
                            className="row-height tablecell-highlight table-detail table-title-left table-position title-col-1"
                          >
                            {currentUser && currentUser?.rank}
                          </Box>

                          <Box
                            style={{
                              // position: "sticky",
                              // left: "100px",
                              // zIndex: 2,
                              // background: "white",
                              width: "300px"
                            }}
                            className="row-height tablecell-highlight table-detail txt-width detail-flex table-title-left title-col-2"
                          >
                            <Box className="team-img-wrap">
                              <img
                                src={
                                  // item?.Favorite?.flag &&
                                  // item?.Favorite?.flag?.includes("uploads")
                                  //   ? Config.baseURL + item?.Favorite?.flag
                                  //   : item?.SportId === 5 ||
                                  //     item?.SportId === 6 ||
                                  //     item?.SportId === 7 ||
                                  //     item?.SportId === 16
                                  //   ?
                                  playerAvtar
                                  // : brisbane
                                }
                                // src={
                                //   item?.Favorite?.flag ? item?.Favorite?.flag : AUS
                                // }
                                // src={
                                //   item?.flag?.includes("uploads")
                                //     ? Config.baseURL + item?.flag
                                //     : item?.flag
                                // }
                                alt="avtar"
                                className="flag team-img"
                              />
                            </Box>
                            {/* {item?.name} /}
                            <span className="tipper-name">
                              {currentUser?.firstName} {currentUser?.lastName}
                            </span>
                          </Box>
                        </StickyTableCell>
                        <TableCell
                          className={
                            "tablecell-highlight table-detail table-detail-cell txt-center"
                          }
                        >
                          {currentUser?.roundData?.roundPoints ?? "-"}
                        </TableCell>
                        <TableCell
                          className={
                            "tablecell-highlight table-detail table-detail-cell txt-center"
                          }
                        >
                          {currentUser?.roundData?.margin ?? "-"}
                        </TableCell>
                        <TableCell
                          className={
                            "tablecell-highlight table-detail table-detail-cell txt-center"
                          }
                        >
                          {currentUser?.roundData?.totalPoints ?? "-"}
                        </TableCell>
                        <TableCell
                          className={
                            "tablecell-highlight table-detail table-detail-cell txt-center"
                          }
                        >
                          {currentUser?.roundData?.totalMargin ?? "-"}
                        </TableCell>
                      </TableRow>
                    )}
                    {rankingList?.map((item, index) => {
                      return (
                        <TableRow className="table-row">
                          <StickyTableCell className={` ${classes.body}`}>
                            <Box
                              // style={{
                              //   position: "sticky",
                              //   left: 0,
                              //   zIndex: 2,
                              //   background: "white",
                              //   width: "100px"
                              // }}
                              className="row-height table-detail table-title-left table-position title-col-1"
                            >
                              {item?.rank ? item?.rank : index + 1}
                            </Box>

                            <Box
                              // style={{
                              //   position: "sticky",
                              //   left: "100px",
                              //   zIndex: 3,
                              //   background: "white",
                              //   width: "200px"
                              // }}
                              className="row-height table-detail txt-width detail-flex table-title-left title-col-2"
                            >
                              <Box className="team-img-wrap">
                                <img
                                  src={
                                    // item?.Favorite?.flag &&
                                    // item?.Favorite?.flag?.includes("uploads")
                                    //   ? Config.baseURL + item?.Favorite?.flag
                                    //   : item?.SportId === 5 ||
                                    //     item?.SportId === 6 ||
                                    //     item?.SportId === 7 ||
                                    //     item?.SportId === 16
                                    //   ?
                                    playerAvtar
                                    // : brisbane
                                  }
                                  // src={
                                  //   item?.Favorite?.flag ? item?.Favorite?.flag : AUS
                                  // }
                                  // src={
                                  //   item?.flag?.includes("uploads")
                                  //     ? Config.baseURL + item?.flag
                                  //     : item?.flag
                                  // }
                                  alt="avtar"
                                  className="flag team-img"
                                />
                              </Box>
                              {/* {item?.name} /}
                              <span className="tipper-name">
                                {item?.firstName} {item?.lastName}
                              </span>
                            </Box>
                          </StickyTableCell>
                          <TableCell
                            className={
                              "table-detail table-detail-cell txt-center row-height"
                            }
                          >
                            {item?.roundData?.roundPoints ?? "-"}
                          </TableCell>
                          <TableCell
                            className={
                              "table-detail table-detail-cell txt-center row-height"
                            }
                          >
                            {item?.roundData?.margin ?? "-"}
                          </TableCell>
                          <TableCell
                            className={
                              "table-detail table-detail-cell txt-center row-height"
                            }
                          >
                            {item?.roundData?.totalPoints ?? "-"}
                          </TableCell>
                          <TableCell
                            className={
                              "table-detail table-detail-cell txt-center row-height"
                            }
                          >
                            {item?.roundData?.totalMargin ?? "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  </Table> */}
                    {/* </div> */}

                    {/* {screenWidth < 1150 ? (
                      <>
                        {rankingList?.length > 0 ? (
                          <Box className="view-more-btn txt-start view-more">
                            {rankingOffset + 10 === rankingList?.length ? (
                              <span
                                className="view-more-text"
                                onClick={() => handleMoreData()}
                              >
                                View More
                              </span>
                            ) : (
                              <></>
                            )}
                            {rankingOffset >= 10 &&
                            rankingList?.length > 10 ? (
                              <span
                                className="view-more-text view-lese-text"
                                onClick={() => handleLessData()}
                              >
                                View Less
                              </span>
                            ) : (
                              <></>
                            )}
                          </Box>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )} */}
                  </Box>
                  {rankingList?.length > 0 ? (
                    <Box className="view-more">
                      {rankingOffset + 10 === rankingList?.length ? (
                        <span
                          className="view-more-text"
                          onClick={() => handleMoreData()}
                        >
                          View More
                        </span>
                      ) : (
                        <></>
                      )}
                      {rankingOffset >= 10 && rankingList?.length > 10 ? (
                        <span
                          className="view-more-text view-lese-text"
                          onClick={() => handleLessData()}
                        >
                          View Less
                        </span>
                      ) : (
                        <></>
                      )}
                    </Box>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Box
                  style={{
                    textAlign: "center"
                  }}
                >
                  <NoDataComp />
                </Box>
              )}
            </Box>
          </>
        </Box>

        <OurPartner />
      </Box>
    </Box>
    {/* <Box className="learn-more-box">
            <Typography
              className="learn-more txt-start"
              // onClick={() => learnMore(item?.url)}
            >
              View more
            </Typography>
          </Box> */}
  </>);
};

export default RankingsPage;
