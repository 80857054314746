import React from "react";
import Page from "src/components/Page";
import OddsCompLayout from "src/views/component/RacingOddsComparison";

const RacingOddsComparison = () => {
  return (
    <Page title="Racing Odds Comparison">
      <OddsCompLayout />
    </Page>
  );
};

export default RacingOddsComparison;
