import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
  } from"@mui/material";
  import React, { useEffect, useState } from "react";
  import { Config } from "src/helpers/context";
  import "./raceResultHome.scss";
  import { ReactComponent as RightArrow } from "../../../../assets/images/icons/rightArrowThick.svg";
  import Loader from "src/components/Loader";
import { Routes } from "../../../../helpers/constants/routeConstants";
import { useNavigate } from "react-router";
  

const RaceResultHomeTable = ({
    raceList,
    raceCount,
    isLoading,
    selectedSport
  }) => {
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    useEffect(() => {
      window.addEventListener("resize", function() {
        setScreenWidth(window.innerWidth);
      });
    }, []);
    return (
      <>
        {isLoading ? (
          <div className="allsport-loader-center news-loader">
            <Loader />
          </div>
        ) : (
          <Box className="race-result-home-component-wrap">
            <TableContainer className="race-result-home-container">
              <Table className="race-result-home-table">
                <TableHead className="race-result-home-table-header-wrap">
                  <TableRow>
                    <TableCell className="race-result-home-header race-result-home-name-header">
                      Race
                    </TableCell>
                    {screenWidth > 799 && (
                      <>
                        <TableCell className="race-result-home-header align-left">
                          Race name
                        </TableCell>
  
                        <TableCell className="race-result-home-header">
                          Distance
                        </TableCell>
                      </>
                    )}
                    <TableCell
                      className={`race-result-home-header ${
                        screenWidth > 799 ? "align-left" : ""
                      }`}
                    >
                      Results
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="race-result-home-table-body-wrap">
                  {raceList?.length > 0 ? (
                    raceList?.map(row => (
                      <TableRow key={row?.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="race-result-home-name-wrap"
                        >
                          <Box className="name-img-wrap">
                            <Box className="race-result-home-img-wrap">
                              <img
                                src={
                                  row?.track?.Country?.country_flag?.includes(
                                    "uploads"
                                  )
                                    ? Config.mediaURL +
                                      row?.track?.Country?.country_flag
                                    : row?.track?.Country?.country_flag
                                }
                                // onError={e => {
                                //   e.target.src = DefaultImg;
                                // }}
                                alt="country"
                              />
                            </Box>
                            <Box className="race-details-wrap">
                              <span className="track-name-number">
                                <span className="race-number">
                                  {`R${row?.raceNumber}`}
                                </span>
                                <span className="track-name">
                                  {row?.track?.name}
                                </span>
                                <span className="country-code">
                                  {" "}
                                  {row?.countryCode
                                    ? "(" + row?.countryCode + ")"
                                    : ""}
                                </span>
                              </span>
                              {screenWidth < 800 && (
                                <Box className="res-race-name">
                                  {row?.raceName ?? ""}
  
                                  {row?.Distance?.name
                                    ? " - " + row?.Distance?.name + "m"
                                    : ""}
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </TableCell>
                        {screenWidth > 799 && (
                          <>
                            <TableCell className="race-name">
                              {row?.raceName ?? ""}
                            </TableCell>
  
                            <TableCell>
                              {row?.Distance?.name
                                ? row?.Distance?.name + "m"
                                : "-"}
                            </TableCell>
                          </>
                        )}
                        <TableCell className="last-result-cell">
                          <Box className="result-final-wrap">
                            <span className="result-display">
                              {" "}
                              {row?.resultDisplay ? row?.resultDisplay : "-"}
                            </span>
                            <span
                              className="result-final"
                              onClick={() => {
                                navigate(
                                  // Redirect to Runner Details
                                  Routes.RunnerDetails(
                                    row?.sportId === 1
                                      ? "horse"
                                      : row?.sportId === 3
                                      ? "greyhounds"
                                      : "harness",
                                    row?.sportId,
                                    row?.id,
                                    row?.trackId,
                                    row?.eventId,
                                    row?.startDate,
                                    row?.track?.countryId === 13 ||
                                      row?.track?.countryId === 157
                                      ? false
                                      : true
                                  )
                                );
                              }}
                            >
                              Final <RightArrow />
                            </span>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} style={{ padding: "12px 6px" }}>
                        No Data Available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {!isLoading ? (
              <Box className="view-more">
                <span
                  className="view-more-text"
                  onClick={() => navigate(`/racing?type=${selectedSport}`)}
                >
                  View All
                </span>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        )}
      </>
    );
  };
  
  export default RaceResultHomeTable;
  