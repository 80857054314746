import React from "react";
import Page from "src/components/Page";
import SmartbookPage from "../../component/Smartbook";

const Smartbook = () => {
  return (
    <Page title="Smartbook">
      <SmartbookPage />
    </Page>
  );
};

export default Smartbook;
