import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Breadcrumbs,
  Button,
  TableContainer,
  Tab,
  Tabs,
  TabPanel,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import banner from "../../../assets/images/banner/smart-b-banner-primary.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import PropTypes from "prop-types";
import OurPartner from "../ourPartners";
import { useParams } from "react-router-dom";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import Select, { components } from "react-select";
import { ReactComponent as LeftArrow } from "src/assets/images/left-arrow.svg";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import TipperProfile from "src/assets/images/tipper-profile.png";
import Avatar from "src/assets/images/chat-user.png";
import AdBannner from "../AdBanner/AdBanner";
import moment from "moment-timezone";
import "./userIndividualMessage.scss";

const UserIndividualMessagePage = () => {
  const params = useParams();
  const tabRef = useRef();
  const navigate = useNavigate();
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  let [stepperCount, setStepperCount] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [value, setValue] = useState(0);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const handleSendMessage = sender => {
    if (newMessage.trim() !== "") {
      setMessages([...messages, { text: newMessage, sender: sender }]);
      setNewMessage("");
    }
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  return (
    <>
      <Box className="content-wrap">
        <Box className="mobile-banner">
          {fetchPageHeaderAds("218px", banner)}
        </Box>

        <Box className="user-messages-wrap">
          <Box className="user-messages-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  HOME
                </Link>
                <Link underline="hover" color="inherit" to="/">
                  Messages
                </Link>

                <Typography>{params?.name}</Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">Messages</Typography>
          </Box>
          <Box className="page-deatils-wrap">
            <Box className="old-message-box">
              <Typography
                className="old-message-txt"
                // onClick={() => learnMore(item?.url)}
              >
                See Older Messages
              </Typography>
            </Box>
            <Box
              className={
                messages?.length > 3 ? "messages-sec" : "d-flex messages-sec"
              }
            >
              <Box className="messages-container">
                {messages?.length > 0 && (
                  <Box className="day-devider">
                    <Box className="devider"></Box>
                    <Typography className="day">
                      {moment(Date()).format("dddd, DD MMM")}
                    </Typography>
                    <Box className="devider"></Box>
                  </Box>
                )}

                {messages &&
                  messages.map((message, index) => (
                    <Box
                      key={index}
                      className={`message ${
                        message.sender === "user"
                          ? "user-message"
                          : "bot-message"
                      }`}
                    >
                      <Box
                        className={
                          message.sender === "user"
                            ? "message-sec"
                            : "message-sec bot-message-sec"
                        }
                      >
                        <Box className="profile-pic">
                          <img
                            src={
                              message.sender === "user" ? TipperProfile : Avatar
                            }
                            alt="profile"
                          />
                        </Box>
                        <Box className="msg-time">
                          <Box
                            className={`chat ${
                              message.sender === "user"
                                ? "user-message-txt"
                                : "bot-message-txt"
                            }`}
                          >
                            {message.text}
                          </Box>
                          <Box
                            className={`time ${
                              message.sender === "user" ? "" : "align-end"
                            }`}
                          >
                            10:49 am
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Box>
            <Box className="send-box">
              {/* <TextField
                          placeholder="type..."
                          variant="outlined"
                          type="text"
                          name="sportoreventother"
                          className="text-field"
                          value={newMessage}
                          onChange={e => setNewMessage(e.target.value)}
                        /> */}
              <TextareaAutosize
                id="outlined-basic"
                variant="outlined"
                className="details-textarea-field"
                placeholder="type..."
                multiline
                maxRows={6}
                name="message"
                style={{ width: "97%", height: "18px" }}
                value={newMessage}
                onChange={e => setNewMessage(e.target.value)}
              />
              <Button
                className="send-btn"
                disableElevation
                disableFocusRipple
                disableRipple
                onClick={() => newMessage && handleSendMessage("bot")}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Box>

        <OurPartner />
      </Box>
    </>
  );
};

export default UserIndividualMessagePage;
