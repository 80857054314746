import React from "react";
import Page from "src/components/Page";
import TeamSportsDataPage from "src/views/component/teamSportData";

const TeamSportsData = () => {
  return (
    <Page title="Team sorts Data">
      <TeamSportsDataPage />
    </Page>
  );
};

export default TeamSportsData;
