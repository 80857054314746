import React from "react";
import Page from "src/components/Page";
import ManageEmailSubscriptionsPage from "src/views/component/ManageEmailSubscriptions";

const ManageEmailSubscriptions = () => {
  return (
    <Page title="Manage Email Notifications">
      <ManageEmailSubscriptionsPage />
    </Page>
  );
};

export default ManageEmailSubscriptions;
