import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Breadcrumbs
} from "@mui/material";
import AdvertisingBannner from "../advertisingBanner";
import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import BannerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import ApplePodcasts from "../../../assets/images/podcast/podcast1.png";
import Spotify from "../../../assets/images/podcast/podcast2.png";
import AmazonMusic from "../../../assets/images/podcast/podcast3.png";
import iHeartRadio from "../../../assets/images/podcast/podcast4.png";
import TuneInAlexa from "../../../assets/images/podcast/podcast5.png";
import PodcastAddict from "../../../assets/images/podcast/podcast6.png";
import Podchaser from "../../../assets/images/podcast/podcast7.png";
import PocketCasts from "../../../assets/images/podcast/podcast8.png";
import Deezer from "../../../assets/images/podcast/podcast9.png";
import ListenNotes from "../../../assets/images/podcast/podcast10.png";
import PlayerFM from "../../../assets/images/podcast/podcast11.png";
import PodcastIndex from "../../../assets/images/podcast/podcast12.png";
import RSSFeed from "../../../assets/images/podcast/podcast13.png";
import GooglePodcasts from "../../../assets/images/podcast/podcast14.png";
import Facebook from "../../../assets/images/social/facebook.png";
import Snapchat from "../../../assets/images/social/snapchat-logo.png";
import Insta from "../../../assets/images/social/Insta.png";
import Tiktok from "../../../assets/images/social/tiktok.png";
import Twitter_X from "../../../assets/images/social/Twitter-X.png";
import Youtube from "../../../assets/images/social/youtube.png";
import Twitter from "../../../assets/images/social/twitter.png";
import Linkedin from "../../../assets/images/social/linkedin-round.png";
import Rumble from "../../../assets/images/social/rumble.png";
import Placeholder from "../../../assets/images/videoPlaceholder.png";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import { Link } from "react-router-dom";
import OurPartner from "src/views/component/ourPartners";
import { useDispatch, useSelector } from "react-redux";
import AdBannner from "../AdBanner/AdBanner";
import "./podcasts.scss";
import DialogBox from "../UI/dialogBox";
import { ScriptSmartB, ScriptRacingSmartB } from "./scriptSmartb";
import { release } from "src/helpers/context/release";
import { Config } from "src/helpers/context";

const PodcastsTitle = [
  { value: 0, label: "Sports Update" },
  { value: 3, label: "Racing Update" },
  { value: 1, label: "SEN" },
  { value: 2, label: "Videos" }
];

const podcastData = [
  {
    id: 1,
    img: ApplePodcasts,
    url:
      "https://podcasts.apple.com/us/podcast/the-smartb-sports-update/id1703526312"
  },
  {
    id: 2,
    img: Spotify,
    url: "https://open.spotify.com/show/6KXy5fauO552BlilRddbRL"
  },
  {
    id: 3,
    img: AmazonMusic,
    url:
      "https://music.amazon.com/podcasts/726f6a5d-1802-48f7-803a-cbac848b4cde/the-smartb-sports-update"
  },
  {
    id: 4,
    img: iHeartRadio,
    url:
      "https://www.iheart.com/podcast/269-the-smartb-sports-update-121460356/"
  },
  {
    id: 5,
    img: TuneInAlexa,
    url:
      "https://tunein.com/podcasts/Sports--Recreation-Podcasts/The-SmartB-Sports-Update-p3770985/?topicId=316589645"
  },
  {
    id: 6,
    img: PodcastAddict,
    url: "https://podcastaddict.com/podcast/the-smartb-sports-update/4586372"
  },
  {
    id: 7,
    img: Podchaser,
    url: "https://www.podchaser.com/podcasts/the-smartb-sports-update-5449083"
  },
  {
    id: 8,
    img: PocketCasts,
    url: "https://pca.st/kbpwc9d6"
  },
  {
    id: 9,
    img: Deezer,
    url: "https://www.deezer.com/us/show/1000197245"
  },
  {
    id: 10,
    img: ListenNotes,
    url:
      "https://www.listennotes.com/podcasts/the-smartb-sports-update-smartb-Jqp2WTuXQvL/"
  },
  {
    id: 11,
    img: PlayerFM,
    url: "https://player.fm/series/the-smartb-sports-update"
  },
  {
    id: 12,
    img: PodcastIndex,
    url: "https://podcastindex.org/podcast/6581113"
  },
  {
    id: 13,
    img: RSSFeed,
    url: "https://feeds.buzzsprout.com/2234145.rss"
  },
  {
    id: 14,
    img: GooglePodcasts,
    url:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8yMjM0MTQ1LnJzcw=="
  }
];

const socialData = [
  {
    id: 1,
    img: Facebook,
    url: "https://www.facebook.com/smartbapp"
  },
  {
    id: 2,
    img: Insta,
    url: "https://www.instagram.com/smartbapp/"
  },
  {
    id: 3,
    img: Tiktok,
    url: "https://www.tiktok.com/@smartbapp"
  },
  {
    id: 4,
    img: Youtube,
    url: "https://www.youtube.com/@smartbapp"
  },
  {
    id: 5,
    img: Twitter_X,
    url: "https://twitter.com/backawinneraus"
  },
  {
    id: 6,
    img: Snapchat,
    url: "https://t.snapchat.com/m2Cu44hr"
  },
  {
    id: 7,
    img: Linkedin,
    url: "https://www.linkedin.com/company/smartbapp/"
  },
  {
    id: 8,
    img: Rumble,
    url: "https://rumble.com/c/c-4732998"
  }
];

const moreVideo = [
  {
    id: 1,
    url:
      "https://www.youtube.com/embed/videoseries?si=ifZL_zV9NrMD5Z9y&list=PLJGv8nkG93Wkr0vVF707lKWm3mx3zy7nF",
    title: "Sports Update Snippets"
  },
  {
    id: 2,
    url:
      "https://www.youtube.com/embed/videoseries?si=LEf2vR5Yt387_Usg&list=PLJGv8nkG93WmUcqoEctzKKpFBN1nF-EpO",
    title: "Ollie's Updates"
  }
];

const PodcastsPage = () => {
  const tabsRef = useRef(null);
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [moreVideoOpen, setMoreVideoOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [moreVideoUrl, setMoreVideoUrl] = useState("");
  const [podcastsTitleTab, setPodcastsTitleTab] = useState(PodcastsTitle);

  useEffect(() => {
    const releasePodcastsTitleTab = podcastsTitleTab?.filter(item =>
      release[Config.release]?.podcasts?.includes(item?.label)
    );
    setPodcastsTitleTab(releasePodcastsTitleTab);
  }, []);

  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleChange = (id, value) => {
    setValue(id);
  };

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleMoreVideoModal = url => {
    setMoreVideoOpen(true);
    setMoreVideoUrl(url);
  };
  const handleCloseMoreVideoModal = () => {
    setMoreVideoOpen(false);
  };

  return (
    <>
      <Box className="content-wrap">
        {/* <AdvertisingBannner src={banner} /> */}
        <Box className="mobile-banner">
          {fetchPageHeaderAds(
            "218px",
            Config?.release == "IN" ? BannerIndia : banner
          )}
        </Box>
        <Box className="podcasts-wrap">
          <Box className="podcasts-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  Home
                </Link>

                <Typography>Podcasts</Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">Podcasts</Typography>
          </Box>
          <Box className="podcasts-details">
            {/* <TextField
              placeholder="Search"
              variant="outlined"
              type="text"
              name="search"
              className="search_input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
            />{" "} */}
            <Box>
              <Tabs
                value={value}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                className="podcasts-tab-details"
                // disableRipple
                // disableFocusRipple
                id="podcasts-tab"
                ref={tabsRef}
              >
                {podcastsTitleTab.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Tab
                        label={item?.label}
                        value={item?.value}
                        onChange={() => handleChange(item?.value, item?.label)}
                        className={item?.value == value ? "active" : ""}
                      />
                    </Box>
                  );
                })}
              </Tabs>
            </Box>
          </Box>
          <Box className="podcasts-deatils-wrap">
            {value === 0 ? (
              <>
                <ScriptSmartB />
              </>
            ) : value === 3 ? (
              <>
                <ScriptRacingSmartB />
              </>
            ) : value === 1 ? (
              <>
                <Box className="podcasts-sen">
                  <iframe
                    frameborder="0"
                    src="https://playlist.megaphone.fm/?p=NTETP7998936857&light=true"
                    width="100%"
                    height="482"
                  ></iframe>
                </Box>
              </>
            ) : (
              <>
                <Box className="podcasts-video">
                  <Typography className="podcasts-on-text">
                    Featured videos
                  </Typography>
                  <Box className="video-section">
                    <Box onClick={() => handleOpenModal()}>
                      {screenWidth > 539 ? (
                        <iframe
                          width="480"
                          height="600"
                          src="https://www.youtube.com/embed/videoseries?si=7Cq_Y2N3vU23nxYw&list=PLJGv8nkG93WnR08ZVu_jMOLk332l8s4rB&showinfo=0&controls=0&modestbranding=1"
                          title="YouTube video player"
                          frameborder="0"
                          allowfullscreen
                          style={{ pointerEvents: "none", width: "100%" }}
                        ></iframe>
                      ) : (
                        <iframe
                          width="366"
                          height="366"
                          src="https://www.youtube.com/embed/videoseries?si=7Cq_Y2N3vU23nxYw&list=PLJGv8nkG93WnR08ZVu_jMOLk332l8s4rB&showinfo=0&controls=0&modestbranding=1"
                          title="YouTube video player"
                          frameborder="0"
                          allowfullscreen
                          style={{ pointerEvents: "none" }}
                        ></iframe>
                      )}
                    </Box>
                  </Box>
                  <Box className="more-video-section">
                    <Typography className="podcasts-on-text">
                      More videos
                    </Typography>
                    <Typography className="subscribe-text">
                      Subscribe to our youtube channel for all the latest
                      videos.
                    </Typography>
                    <Box className="more-video">
                      {moreVideo?.map(item => (
                        <>
                          <Box onClick={() => handleMoreVideoModal(item?.url)}>
                            <iframe
                              width="560"
                              height="315"
                              src={`${item?.url}&showinfo=0&controls=0&modestbranding=1`}
                              title="YouTube video player"
                              frameborder="0"
                              allowfullscreen
                              style={{
                                pointerEvents: "none",
                                width: "100%",
                                height: "100%"
                              }}
                            ></iframe>
                            <Typography className="video-text">
                              {item?.title}
                            </Typography>
                          </Box>
                        </>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box className="our-podcasts-deatils">
            <Box className="podcasts-on">
              <Typography variant="h4" className="podcasts-on-text">
                Listen to our Podcasts on
              </Typography>
              <Box className="podcasts-grid">
                {podcastData?.map((item, index) => {
                  return (
                    <>
                      <Box key={index}>
                        <a href={item?.url} target="_blank">
                          <img src={item?.img} alt="icon" />
                        </a>
                      </Box>
                    </>
                  );
                })}
              </Box>
              <Typography variant="h4" className="podcasts-on-text">
                Follow us on
              </Typography>
              <Box className="podcasts-grid social-grid">
                {socialData?.map((item, index) => {
                  return (
                    <>
                      <Box key={index}>
                        <a href={item?.url} target="_blank">
                          <img src={item?.img} alt="icon" />
                        </a>
                      </Box>
                    </>
                  );
                })}
              </Box>
            </Box>
          </Box>
          <OurPartner />
        </Box>
      </Box>
      <DialogBox
        open={open}
        onClose={handleClose}
        handleClose={handleClose}
        title={false}
        className="podcasts-video-modal"
        content={
          <>
            <Box className="video-secton">
              {screenWidth > 539 ? (
                <iframe
                  width="480"
                  height="600"
                  src="https://www.youtube.com/embed/videoseries?si=7Cq_Y2N3vU23nxYw&list=PLJGv8nkG93WnR08ZVu_jMOLk332l8s4rB"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              ) : (
                <iframe
                  width="366"
                  height="366"
                  src="https://www.youtube.com/embed/videoseries?si=7Cq_Y2N3vU23nxYw&list=PLJGv8nkG93WnR08ZVu_jMOLk332l8s4rB"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              )}
            </Box>
          </>
        }
        closeIcon={true}
      />

      <DialogBox
        open={moreVideoOpen}
        onClose={handleCloseMoreVideoModal}
        handleClose={handleCloseMoreVideoModal}
        title={false}
        className="More-video-modal"
        content={
          <>
            <Box className="more-video-secton">
              <Box className="video-secton">
                <iframe
                  width="560"
                  height="315"
                  src={moreVideoUrl}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                  style={{ width: "100%" }}
                ></iframe>
              </Box>
            </Box>
          </>
        }
        closeIcon={true}
      />
    </>
  );
};

export default PodcastsPage;
