import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { IntlContext } from "src/App";
import { useSelector } from "react-redux";
import racingBanner2 from "../../../assets/images/horse-racing-banner-2.png";
import "./internationalRacing.scss";

const raceRecord = [
  {
    id: 1,
    field: "Win",
    field1: "Select a winner"
  },
  {
    id: 2,
    field: "Place",
    field1: "Select any horse of the first three horses"
  },
  {
    id: 3,
    field: "Quinella (QIN)",
    field1: "Select the 1st and 2nd horses in any order"
  },
  {
    id: 4,
    field: "Quinella Place (QPL)",
    field1: "Select any two of the first three horses in any order"
  }
];

const SingaporeHorseRacing = () => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  return (
    <>
      <Box className="img-sec hw-100">
        <img src={racingBanner2} alt="website-img" className="inner-img" />
      </Box>
      <Box className="txt-sec mt-27 pb-bottom w-100">
        <Typography className="title">Singapore Horse Racing</Typography>
        <Box className="des-box">
          <Typography className="description mt-27">
            Horse Racing in Singapore has a long and colorful history. The first
            horse race to take place was in February 1843 and was organised by
            the Singapore Sporting Club which was a recently formed group of
            racing enthusiasts.
          </Typography>
          <Typography className="description mt-27">
            <ul>
              <li>
                <span className="fw-600">Track:</span> Singapore Racecourse,
                Kranji
              </li>
              <li>
                <span className="fw-600">Feature Race:</span> Singapore Airlines
                International Cup
              </li>
              <li>
                <span className="fw-600">Weekly Race Meets:</span> Fridays,
                Saturdays, Sundays
              </li>
            </ul>
          </Typography>
        </Box>
      </Box>
      <Box className="txt-sec mt-27 pb-bottom w-100">
        <Typography className="title">
          Singapore Racing Odds & Betting
        </Typography>
        <Box className="des-box">
          <Typography className="description mt-27">
            Racing meets in Singapore are conducted on selected Fridays,
            Saturdays and Sundays throughout the year, with Friday racing
            typically held at night while the Saturday, Sunday races held during
            the daytime. Betting markets usually open the day before the racing
            meets and you can check out the latest odds at the following
            bookmakers.
          </Typography>
          <Typography className="description mt-27">
            Racing meets in Singapore are now conducted on selected Fridays,
            Saturdays and Sundays of each racing month. Horse Racing in
            Singapore is organised under the Malayan Racing Association (MRA)
            rules of Racing. The MRA is made up of four racing Clubs including,
            Singapore Turf Club, Selangor Turf Club, Perak Turf Club and Penang
            Turf Club.
          </Typography>
        </Box>
      </Box>
      {/* <Box className="txt-sec mt-27 pb-bottom w-100">
        <Typography className="title">HK Horse Race Betting</Typography>
        <Box className="des-box">
          <Typography className="description mt-27">
            How to place bets on HK horse racing?
          </Typography>

          <Box className="mt-27">
            <TableContainer className="international-table-container border-gray">
              <Table
                style={{ minWidth: "max-content", height: "100%" }}
                className="international-table"
              >
                <TableBody>
                  {raceRecord?.map((item, index) => {
                    return (
                      <>
                        <TableRow key={index}>
                          <TableCell style={{ width: "20%" }}>
                            {item?.field}
                          </TableCell>
                          <TableCell>{item?.field1}</TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box> */}
    </>
  );
};

export default SingaporeHorseRacing;
