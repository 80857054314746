import React, { useEffect, useState } from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ReactComponent as Watch } from "src/assets/images/watch.svg";
import { NavLink } from "react-router-dom";
import DefaultImg from "../../../assets/images/smartb_default.png";
import Loader from "src/components/Loader";
import moment from "moment-timezone";
import he from "he";
import { Config, fetchFromStorage } from "src/helpers/context";
import { ReactComponent as BookmarkIcon } from "src/assets/images/icons/bookmark-icon.svg";
import { ReactComponent as ChatIcon } from "src/assets/images/icons/chat-icon.svg";
import { setApiMessage } from "src/helpers/commonFunctions";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useDispatch } from "react-redux";
import "./newsComp.scss";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import useAutoSaveStore from "src/helpers/zustand/useAutoSaveStore";

const NewsArticleComp = ({ newsData }) => {
  const dispatch = useDispatch();
  const { setApiInfo } = useAutoSaveStore();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [featureNewsData, setFeatureNewsData] = useState(newsData);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const fetchNewsSubtitle = (data) => {
    const parseHTMLContent = (body) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(body, "text/html");
      const firstPTag = doc.querySelector("p");
      return firstPTag ? firstPTag.innerHTML : "";
    };
    const decodeHTMLContent = (content) => {
      return he.decode(content);
    };
    const removeHtmlTags = (htmlString) => {
      return htmlString.replace(/<[^>]*>/g, "");
    };
    const renderSubtitle = (content) => {
      return (
        <Typography className="sub-details">
          <span
            dangerouslySetInnerHTML={{
              __html:
                screenWidth >= 1023
                  ? content?.slice(0, 90)
                  : content?.slice(0, 50),
            }}
          ></span>
          {/* {screenWidth >= 1023 ? content?.slice(0, 100) : content?.slice(0, 50)} */}
          <span className="read-more">...read more</span>
        </Typography>
      );
    };
    if (data?.NewsProviderId === 1) {
      const editorBlockData = data?.body?.find(
        (item) =>
          item?.type === "editor_block" && item?.data?.type === "paragraph",
      );
      const content = parseHTMLContent(editorBlockData?.data?.content);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    } else {
      const content = parseHTMLContent(data?.body);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    }
  };

  const fetchNewsTime = (newsdate) => {
    const now = moment();
    const givenTime = moment(newsdate);
    const timeDiffMinutes = now.diff(givenTime, "minutes");
    const timeDiffHours = now.diff(givenTime, "hours");
    const timeDiffDays = now.diff(givenTime, "days");
    const timeDiffWeeks = now.diff(givenTime, "weeks");
    if (timeDiffMinutes > 60) {
      if (timeDiffMinutes / 60 > 24) {
        if (timeDiffDays > 7) {
          return `${timeDiffWeeks} ${
            timeDiffWeeks == 1 ? "week" : "weeks"
          } ago`;
        } else {
          return `${timeDiffDays} ${timeDiffDays == 1 ? "day" : "days"} ago`;
        }
      } else {
        return `${timeDiffHours} ${timeDiffHours == 1 ? "hour" : "hours"} ago`;
      }
    } else {
      return `${timeDiffMinutes} ${
        timeDiffMinutes == 1 ? "minute" : "minutes"
      } ago`;
    }
  };

  const handleNewsSave = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    if (handleRestrictedUser()) {
      let payload = {
        status: item?.NewsArticle?.isSaved === 0 ? "active" : "deleted",
      };
      try {
        const { status, data } = await axiosInstance.post(
          `/v2/news/saved/${item?.NewsArticleId}`,
          payload,
        );
        if (status === 200) {
          setApiMessage(
            "success",
            item?.NewsArticle?.isSaved === 0
              ? "News successfully saved!"
              : "News removed from saved!",
          );

          const updateNews = featureNewsData?.map((article) =>
            article.NewsArticleId === item?.NewsArticleId
              ? {
                  ...article,
                  NewsArticle: {
                    ...article.NewsArticle,
                    isSaved: item?.NewsArticle?.isSaved === 0 ? 1 : 0,
                  },
                }
              : article,
          );
          setFeatureNewsData(updateNews);
        } else {
          setApiMessage("error", data?.message);
        }
      } catch (err) {
        setApiMessage("error", err?.response?.data?.message);
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
      setApiInfo({
        api: `/v2/news/saved/${item?.NewsArticleId}`,
        payload: {
          status: item?.NewsArticle?.isSaved === 0 ? "active" : "deleted",
        },
        method: "post",
      });
    }
  };
  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };
  return (
    <Grid
      container
      className={`individual-news-cont comman-news-comp ${
        featureNewsData.length === 1 ? `single-news-comp` : ""
      }`}
    >
      {featureNewsData?.map((item) => (
        <Grid
          item
          size={{ xs: 12 }}
          className="individual-single-news"
          key={item?.id}
        >
          <NavLink
            to={`/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticleId}`}
          >
            <Box className="news-box">
              <Box className="news-tag-relative">
                <Box className="news-img-container">
                  <Box
                    className="background-overlay"
                    style={{
                      content: "''",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundImage: `url('${
                        item?.NewsArticle?.mainMedia?.gallery
                          ? item?.NewsArticle?.mainMedia?.gallery?.url &&
                            item?.NewsArticle?.mainMedia?.gallery?.url !== ""
                            ? item?.NewsArticle?.mainMedia?.gallery?.url
                            : DefaultImg
                          : item?.NewsArticle?.mainMedia?.[0]?.gallery
                            ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url &&
                              item?.NewsArticle?.mainMedia?.[0]?.gallery
                                ?.url !== ""
                              ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url
                              : DefaultImg
                            : DefaultImg
                      }')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center top",
                      filter: "blur(10px)",
                      zIndex: 0,
                      borderRadius: "8px",
                    }}
                  ></Box>
                  <Box className="news-img">
                    <img
                      src={
                        item?.NewsArticle?.mainMedia?.gallery
                          ? item?.NewsArticle?.mainMedia?.gallery?.url &&
                            item?.NewsArticle?.mainMedia?.gallery?.url !== ""
                            ? item?.NewsArticle?.mainMedia?.gallery?.url
                            : DefaultImg
                          : item?.NewsArticle?.mainMedia?.[0]?.gallery
                            ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url &&
                              item?.NewsArticle?.mainMedia?.[0]?.gallery
                                ?.url !== ""
                              ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url
                              : DefaultImg
                            : DefaultImg
                      }
                      alt="placeholder"
                    />
                  </Box>
                  <span className="news-tag">
                    {item?.NewsArticle?.NewsCategory?.initialTitle}
                  </span>
                </Box>
              </Box>
              <Box className="individual-news-details">
                <Typography
                  className="details"
                  dangerouslySetInnerHTML={{
                    __html: item?.NewsArticle?.title
                      ? item?.NewsArticle?.title
                      : item?.NewsArticle?.subTitle,
                  }}
                ></Typography>

                <Box className="sub-details-wrap">
                  {fetchNewsSubtitle(item?.NewsArticle)}
                </Box>

                {/* <Box className="tag-time-details">
                  <Typography className="stories-time">
                    <span className="watch">
                      <Watch />
                    </span>
                    {fetchNewsTime(item?.rapidCreatedAt)}
                  </Typography>
                </Box> */}
                <Box className="news-time-comment-wrapper">
                  <Typography className="news-time">
                    {fetchNewsTime(item?.NewsArticle?.rapidCreatedAt)}
                  </Typography>
                  <Box className="bookmark-chat-wrapper">
                    <Box onClick={(e) => handleNewsSave(e, item)}>
                      <Tooltip
                        placement="bottom"
                        arrow
                        title='Save to "Saved articles"'
                        classes={{
                          tooltip: "news-tooltip",
                        }}
                      >
                        <BookmarkIcon
                          className={
                            item?.NewsArticle?.isSaved === 0
                              ? "unsaved-bookmark-icon"
                              : "saved-bookmark-icon"
                          }
                        />
                      </Tooltip>
                    </Box>
                    <Tooltip
                      placement="bottom"
                      arrow
                      title="Comments"
                      classes={{
                        tooltip: "news-tooltip",
                      }}
                    >
                      <Box className="chat-details">
                        <ChatIcon />
                        <Typography className="news-time">
                          {item?.NewsArticle?.totalComments}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
          </NavLink>
        </Grid>
      ))}
    </Grid>
  );
};

export default NewsArticleComp;
