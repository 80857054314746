import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Navigate } from "react-router-dom";
import AdvertisingBannner from "../../advertisingBanner";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import OurPartner from "../../ourPartners";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import AdBannner from "../../AdBanner/AdBanner";
import AboveOurPartner from "../../../../assets/images/ad-placeholder/sports2.webp";
import BelowOurPartner from "../../../../assets/images/ad-placeholder/sports5.webp";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import AllCombatSportLayoutPage from "./allCombatSports/AllCombatSportLayout";
import "./combatSport.scss";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const TeamSports = () => {
  const { search, pathname } = useLocation();
  let teamSportType = pathname?.split?.("/")?.[2];
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    fetchAdsData();
  }, [teamSportType]);

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds?.[0]?.pageAdLists);
    }
  }, [ReduxAdsData]);

  // const handleAdsImression = async ids => {
  //   try {
  //     const payload = {
  //       ids: ids
  //     };
  //     const { status, data } = await axiosInstance.post(
  //       `addImpression`,
  //       payload
  //     );
  //     if (status === 200) {
  //     }
  //   } catch (err) {}
  // };

  const fetchAdsData = async () => {
    try {
      let pageId =
        teamSportType === "cricket"
          ? 6
          : teamSportType === "rugbyleague"
          ? 7
          : teamSportType === "rugbyunion"
          ? 8
          : teamSportType === "basketball"
          ? 12
          : "";
      const { status, data } = await axiosInstance.get(
        `getAllAds?page_id=${pageId}&timeZone=${timezone}`
      );
      if (status === 200) {
        setAdData(data?.data?.[0]?.pageAdLists);
        // let ids = [];
        // let listAds = data?.data?.[0]?.pageAdLists?.map(item => {
        //   ids.push(item?.id);
        // });
        // if (ids.length > 0) {
        //   handleAdsImression(ids);
        // }
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    if (AdData?.length > 0) {
      let ad = AdData?.filter(item => {
        return item?.position_id === position;
      });
      if (ad?.length > 0) {
        return (
          <AdBannner
            placeholder={placeholder}
            addetails={ad?.[0]}
            height={height}
            onAdVisible={handleAdVisible}
          />
        );
      } else {
        return (
          <AdBannner placeholder={placeholder} addetails={[]} height={height} />
        );
      }
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };
  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  return (
    <Box className="content-wrap">
      {/* <AdvertisingBannner src={banner} /> */}
      {/* {fetchAds(1, "218px", banner)} */}
      <Box className="banner-img-fix">
        {fetchPageHeaderAds("218px", banner)}
      </Box>
      <AllCombatSportLayoutPage />
      {/* <TeamSportsLayouts teamSportType={teamSportType} fetchAds={fetchAds} /> */}
      {fetchAds(4, "102px", AboveOurPartner)}
      <OurPartner />
      {fetchAds(5, "102px", BelowOurPartner)}
    </Box>
  );
};

export default TeamSports;
