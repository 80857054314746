import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import "./viewPage.scss";
import moment from "moment";

const newsTitle = [
  { value: 0, label: "Par - Mel" },
  { value: 1, label: "PEn - bri" },
  { value: 2, label: "War - New" },
  { value: 3, label: "Man - CBY" },
  { value: 4, label: "Man - CBY" },
  { value: 5, label: "Man - CBY" }
];

const StickyTableCell = withStyles(theme => ({
  head: {
    right: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    minWidth: "45px",
    boxShadow: "inset 1px 0px 6px 0px #ebebeb",
    "@media(max-width: 799px)": {
      maxWidth: "45px",
      boxShadow: "inset 1px 0px 6px 0px #ebebeb"
    }
  },
  body: {
    right: 0,
    position: "sticky",
    background: "#FFFFFF",
    boxShadow: "inset 1px 0px 6px 0px #ebebeb",
    "@media(max-width: 799px)": {
      boxShadow: "inset 1px 0px 6px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const ViewPage = ({ viewListPage }) => {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const SeasonId = searchParams.get("seasonId");
  const [viewDetailsLoader, setViewDetailsLoader] = useState(false);
  const [latestMatch, setLatestMatch] = useState({});
  const [nextHome, setNextHome] = useState({});
  const [nextAway, setNextAway] = useState({});
  const [previousHome, setPreviousHome] = useState({});
  const [previousAway, setPreviousAway] = useState({});
  const [homeTeamScore, setHomeTeamScore] = useState("");
  const [awayTeamScore, setAwayTeamScore] = useState("");
  const [homeResult, setHomeResult] = useState();
  const [awayResult, setAwayResult] = useState();
  const [homeInningsData, setHomeInningData] = useState({});
  const [awayInningsData, setAwayInningData] = useState({});
  const [value, setValue] = useState(0);
  const [inningTab, setInningTab] = useState([]);

  useEffect(() => {
    fetchViewTeamDetails();
  }, [params, SeasonId]);

  const fetchViewTeamDetails = async () => {
    setViewDetailsLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `sports/statistics/team/summary/${params?.teamId}?SportId=${
          params?.sportId
        }&eventId=${viewListPage}&tournamentId=${
          params?.tournamentId
        }&seasonId=${SeasonId ? SeasonId : ""}`
      );
      if (status === 200) {
        setViewDetailsLoader(false);
        setLatestMatch(data?.result?.latestMatch);
        setNextHome(data?.result?.nextHome);
        setNextAway(data?.result?.nextAway);
        setPreviousHome(data?.result?.previousHome);
        setPreviousAway(data?.result?.previousAway);
        const homeTeamId = data?.result?.latestMatch?.homeTeamId;
        const awayTeamId = data?.result?.latestMatch?.awayTeamId;
        const teamScores =
          Number(params?.sportId) === 4
            ? data?.result?.latestMatch?.CricketScores
            : Number(params?.sportId) === 10
            ? data?.result?.latestMatch?.NBAScores
            : Number(params?.sportId) === 12 ||
              Number(params?.sportId) === 13 ||
              Number(params?.sportId) === 14
            ? data?.result?.latestMatch?.RLScores
            : Number(params?.sportId) === 15
            ? data?.result?.latestMatch?.AFLScores
            : Number(params?.sportId) === 9
            ? data?.result?.latestMatch?.ARScores
            : Number(params?.sportId) === 16
            ? data?.result?.latestMatch?.GolfScores
            : Number(params?.sportId) === 7
            ? data?.result?.latestMatch?.TennisScores
            : Number(params?.sportId) === 11
            ? data?.result?.latestMatch?.BaseballScores
            : Number(params?.sportId) === 17
            ? data?.result?.latestMatch?.IceHockeyScores
            : Number(params?.sportId) === 6
            ? data?.result?.latestMatch?.BoxingScores
            : Number(params?.sportId) === 5
            ? data?.result?.latestMatch?.MMAScores
            : Number(params?.sportId) === 8
            ? data?.result?.latestMatch?.SoccerScores
            : "-";
        const hometeamScore = teamScores?.filter(
          item => item?.teamId === homeTeamId
        );
        const awayTeamScore = teamScores?.filter(
          item => item?.teamId === awayTeamId
        );
        const homeScore =
          Number(params?.sportId) === 4
            ? hometeamScore?.[0]?.score?.innings?.inning1
              ? hometeamScore?.[0]?.score?.innings?.inning1?.score +
                "/" +
                hometeamScore?.[0]?.score?.innings?.inning1?.wickets +
                " " +
                "(" +
                hometeamScore?.[0]?.score?.innings?.inning1?.overs +
                ")" +
                (hometeamScore?.[0]?.score?.innings?.inning2
                  ? "&" +
                    (hometeamScore?.[0]?.score?.innings?.inning2?.score +
                      "/" +
                      hometeamScore?.[0]?.score?.innings?.inning2?.wickets +
                      " " +
                      "(" +
                      hometeamScore?.[0]?.score?.innings?.inning2?.overs +
                      ")")
                  : "")
              : "-"
            : hometeamScore?.[0]?.score?.current;

        const awayScore =
          Number(params?.sportId) === 4
            ? awayTeamScore?.[0]?.score?.innings?.inning1
              ? awayTeamScore?.[0]?.score?.innings?.inning1?.score +
                "/" +
                awayTeamScore?.[0]?.score?.innings?.inning1?.wickets +
                " " +
                "(" +
                awayTeamScore?.[0]?.score?.innings?.inning1?.overs +
                ")" +
                (awayTeamScore?.[0]?.score?.innings?.inning2
                  ? "&" +
                    (awayTeamScore?.[0]?.score?.innings?.inning2?.score +
                      "/" +
                      awayTeamScore?.[0]?.score?.innings?.inning2?.wickets +
                      " " +
                      "(" +
                      awayTeamScore?.[0]?.score?.innings?.inning2?.overs +
                      ")")
                  : "")
              : "-"
            : awayTeamScore?.[0]?.score?.current;

        setHomeResult(hometeamScore?.[0]?.score?.current);
        setAwayResult(awayTeamScore?.[0]?.score?.current);
        setHomeTeamScore(homeScore);
        setAwayTeamScore(awayScore);
        const homeInningsData = data?.result?.latestMatch?.CricketInnings?.find(
          item => item?.battingTeamId === homeTeamId
        );
        const awayInningData = data?.result?.latestMatch?.CricketInnings?.find(
          item => item?.battingTeamId === awayTeamId
        );
        setHomeInningData(homeInningsData);
        setAwayInningData(awayInningData);
        const tabData = [
          {
            id: 0,
            label:
              // data?.result?.latestMatch?.homeTeamId === Number(params?.teamId)
              data?.result?.latestMatch?.homeTeam?.name
            // : data?.result?.latestMatch?.awayTeam?.name
          },
          {
            id: 1,
            label:
              // data?.result?.latestMatch?.awayTeamId === Number(params?.teamId)
              // ? data?.result?.latestMatch?.homeTeam?.name
              data?.result?.latestMatch?.awayTeam?.name
          }
        ];
        setInningTab(tabData);
      } else {
        setViewDetailsLoader(false);
      }
    } catch (err) {
      setViewDetailsLoader(false);
    }
  };

  const getTeamDetailsResult = () => {
    return (
      (latestMatch?.homeTeam?.name ? latestMatch?.homeTeam?.name : "") +
      " " +
      (homeTeamScore ? homeTeamScore : "") +
      " " +
      (homeResult > awayResult ? "def." : "Lost To") +
      " " +
      (latestMatch?.awayTeam?.name ? latestMatch?.awayTeam?.name : "") +
      " " +
      (awayTeamScore ? awayTeamScore : "")
    );
  };

  const handleChange = (id, value) => {
    setValue(id);
  };
  return (
    <>
      {viewDetailsLoader ? (
        <div className="allsport-loader-center">
          <Loader />
        </div>
      ) : Number(params?.sportId) === 4 ? (
        <Box className="view-page-wrap">
          {/* <Box className="teamsport-header-details">
            <Typography className="heading-title">
              Round {latestMatch?.round ? latestMatch?.round : "-"}
            </Typography>
          </Box> */}
          {/* <Box className="view-round-wrap">
            <Box className="round-details-wrap">
              <Box className="round-name">Par - Mel</Box>
              <Box className="round-name active">PEn - bri</Box>
              <Box className="round-name">War - New</Box>
              <Box className="round-name">Man - CBY</Box>
              <Box className="round-name">Man - CBY</Box>
              <Box className="round-name">Man - CBY</Box>
            </Box>
            <Box className="round-details">
              <Typography className="see-also-text">See also:</Typography>
              <Typography className="round-result">
                <span className="light-blue">Round 1 Results</span>, Penrith
                Season Summary, Brisbane Season Summary, Penrith/Brisbane Head
                To Head
              </Typography>
            </Box>
          </Box> */}
          <Box className="round-view-details">
            <Box className="teamsport-header-details details-header">
              <Typography className="heading-title">
                {getTeamDetailsResult()}
              </Typography>
            </Box>
            <Box className="info-wrap">
              <Box className="match-info-box">Match info</Box>
              <Box className="tab-details-grid">
                <Box className="details-box">
                  <Typography className="details-text bold">
                    Match URL:{" "}
                  </Typography>
                  <Typography className="details-text link-color">
                    [Match URL]
                  </Typography>
                </Box>
                <Box className="details-box">
                  <Typography className="details-text bold">Date:</Typography>
                  <Typography className="details-text">
                    {moment(latestMatch?.startTime).format(
                      "dddd, Do MMMM, YYYY"
                    )}
                  </Typography>
                </Box>
                <Box className="details-box">
                  <Typography className="details-text bold">Status:</Typography>
                  <Typography className="details-text">
                    [{latestMatch?.status}]
                  </Typography>
                </Box>
                <Box className="details-box">
                  <Typography className="details-text bold">
                    Kick Off:
                  </Typography>
                  <Typography className="details-text">
                    [{moment(latestMatch?.startTime).format("h:mm A")}]
                  </Typography>
                </Box>
                <Box className="details-box">
                  <Typography className="details-text bold">
                    Referee:
                  </Typography>
                  <Typography className="details-text link-color">
                    [Referee]
                  </Typography>
                </Box>
                <Box className="details-box">
                  <Typography className="details-text bold">Venue:</Typography>
                  <Typography className="details-text">
                    [
                    {Number(params?.sportId) === 4
                      ? latestMatch?.CricketStadium
                        ? latestMatch?.CricketStadium?.name
                        : "N/A"
                      : Number(params?.sportId) === 10
                      ? latestMatch?.NBAStadium
                        ? latestMatch?.NBAStadium?.name
                        : "N/A"
                      : Number(params?.sportId) === 12 ||
                        Number(params?.sportId) === 13 ||
                        Number(params?.sportId) === 14
                      ? latestMatch?.RLStadium
                        ? latestMatch?.RLStadium?.name
                        : "N/A"
                      : Number(params?.sportId) === 15
                      ? latestMatch?.AFLStadium
                        ? latestMatch?.AFLStadium?.name
                        : "N/A"
                      : Number(params?.sportId) === 9
                      ? latestMatch?.ARStadium
                        ? latestMatch?.ARStadium?.name
                        : "N/A"
                      : Number(params?.sportId) === 16
                      ? latestMatch?.GolfStadium
                        ? latestMatch?.GolfStadium?.name
                        : "N/A"
                      : Number(params?.sportId) === 7
                      ? latestMatch?.TennisStadium
                        ? latestMatch?.TennisStadium?.name
                        : "N/A"
                      : Number(params?.sportId) === 11
                      ? latestMatch?.BaseballStadium
                        ? latestMatch?.BaseballStadium?.name
                        : "N/A"
                      : Number(params?.sportId) === 17
                      ? latestMatch?.IceHockeyStadium
                        ? latestMatch?.IceHockeyStadium?.name
                        : "N/A"
                      : Number(params?.sportId) === 6
                      ? latestMatch?.BoxingStadium
                        ? latestMatch?.BoxingStadium?.name
                        : "N/A"
                      : Number(params?.sportId) === 5
                      ? latestMatch?.MMAStadium
                        ? latestMatch?.MMAStadium?.name
                        : "N/A"
                      : Number(params?.sportId) === 8
                      ? latestMatch?.SoccerStadium
                        ? latestMatch?.SoccerStadium?.name
                        : "N/A"
                      : "-"}
                    ]
                  </Typography>
                </Box>
                <Box className="details-box">
                  <Typography className="details-text bold">Crowd:</Typography>
                  <Typography className="details-text link-color">
                    [Crowd]
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="info-wrap team-info-wrap">
              <Box className="match-info-box">Team info</Box>
              <Box className="tab-details-grid team-info-grid">
                <Box className="details-box">
                  <Typography className="details-text bold">
                    {latestMatch?.homeTeam?.name
                      ? latestMatch?.homeTeam?.name
                      : "N/A"}
                    :
                  </Typography>
                  <Typography className="details-text ">
                    Previous Game -{" "}
                    <span className="link-color">
                      [
                      {previousHome?.eventName
                        ? previousHome?.eventName
                        : "N/A"}
                      ]
                    </span>
                  </Typography>
                  <Typography className="details-text ">
                    Next Game -
                    <span className="link-color">
                      {" "}
                      [{nextHome?.eventName ? nextHome?.eventName : "N/A"}]
                    </span>
                  </Typography>
                  <Typography className="details-text ">
                    Out - <span className="link-color">[N/A]</span>
                  </Typography>
                  <Typography className="details-text ">
                    In - <span className="link-color">[N/A]</span>
                  </Typography>
                  <Typography className="details-text ">
                    Changes - <span className="link-color">[N/A]</span>
                  </Typography>
                </Box>
                <Box className="details-box">
                  <Typography className="details-text bold">
                    {latestMatch?.awayTeam?.name
                      ? latestMatch?.awayTeam?.name
                      : "N/A"}
                    :
                  </Typography>
                  <Typography className="details-text ">
                    Previous Game -{" "}
                    <span className="link-color">
                      [
                      {previousAway?.eventName
                        ? previousAway?.eventName
                        : "N/A"}
                      ]
                    </span>
                  </Typography>
                  <Typography className="details-text ">
                    Next Game -{" "}
                    <span className="link-color">
                      [{nextAway?.eventName ? nextAway?.eventName : "N/A"}]
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="round-full-details">
            <Box className="round-grid match-name-wrap cricket-round-grid">
              <Box className="text-align-end">
                <Typography className="samll-text">
                  {latestMatch?.homeTeam?.name
                    ?.split(" ")
                    ?.slice(0, -1)
                    ?.join(" ")}
                </Typography>
                <Typography className="big-text">
                  {latestMatch?.homeTeam?.name?.split(" ")?.pop()}
                </Typography>
              </Box>
              <Box className="text-align-center d-flex text-align match-number ">
                <Typography className="big-text">{homeTeamScore}</Typography>
                <Typography className="big-text">-</Typography>
                <Typography className="big-text">{awayTeamScore}</Typography>
              </Box>
              <Box className="text-align-start">
                <Typography className="samll-text">
                  {" "}
                  {latestMatch?.awayTeam?.name
                    ?.split(" ")
                    ?.slice(0, -1)
                    ?.join(" ")}
                </Typography>
                <Typography className="big-text">
                  {" "}
                  {latestMatch?.awayTeam?.name?.split(" ")?.pop()}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="view-tab-details">
            <Tabs
              value={value}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              className="list-tab"
              // disableRipple
              // disableFocusRipple
              id="news-tab"
            >
              {inningTab?.map((item, index) => {
                return (
                  <Box key={index}>
                    <Tab
                      label={item?.label}
                      value={item?.id}
                      onChange={() => handleChange(item?.id, item?.value)}
                      className={item?.id == value ? "active" : ""}
                    />
                  </Box>
                );
              })}
            </Tabs>
          </Box>
          <Box className="match-details">
            {value === 0 ? (
              <>
                <TableContainer
                  id="details-table"
                  className="teamsport-data-table team-sport-tables player-data-table"
                >
                  {/* <Box className="overlay"></Box> */}
                  <Table
                    className="data-table"
                    style={{ minWidth: "max-content" }}
                  >
                    <TableHead className="table-header">
                      <TableRow className="table-head-row ">
                        <TableCell
                          className={`${classes.head} first-cell`}
                          style={{ width: "30%" }}
                        >
                          Batting
                        </TableCell>
                        <TableCell className={classes.head}>R</TableCell>
                        <TableCell className={classes.head}>B</TableCell>
                        <TableCell className={classes.head}>4s</TableCell>
                        <TableCell className={classes.head}>6s</TableCell>
                        <TableCell className={classes.head}>S/R</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                      {homeInningsData?.CricketBattingLines?.length > 0 ? (
                        <>
                          {homeInningsData?.CricketBattingLines?.filter(
                            item => item?.wicketTypeName !== "Did not bat"
                          )?.map((item, i) => {
                            return (
                              <>
                                <TableRow key={i} className="table-body-row ">
                                  <TableCell
                                    className={`${classes.cell} first-cell`}
                                  >
                                    <Box
                                      className="light-blue cursor-pointer"
                                      onClick={() =>
                                        navigate(
                                          `/sport/statistics/${
                                            params?.sportId
                                          }/${
                                            params?.tournamentId
                                          }/league-data/${
                                            params?.teamId
                                          }/team-data/${
                                            item?.CricketPlayer?.id
                                          }/player-data?seasonId=${
                                            SeasonId ? SeasonId : ""
                                          }`
                                        )
                                      }
                                    >
                                      {item?.CricketPlayer?.name}
                                    </Box>
                                    <Box>
                                      {item?.wicketTypeName !== "Not out" ? (
                                        <>
                                          <span>
                                            {item?.wicketTypeName === "Caught"
                                              ? item?.wicketCatch
                                                ? "c :" +
                                                  " " +
                                                  item?.wicketCatch?.name
                                                : ""
                                              : item?.wicketTypeName === "LBW"
                                              ? "lbw"
                                              : item?.wicketTypeName ===
                                                "Caught & Bowled"
                                              ? "c & "
                                              : item?.wicketTypeName ===
                                                "Run out"
                                              ? "run not" +
                                                " " +
                                                "(" +
                                                item?.wicketCatch?.name
                                                ? item?.wicketCatch?.name
                                                : "" + ")"
                                              : item?.wicketTypeName ===
                                                "Stumped"
                                              ? "st :" +
                                                " " +
                                                item?.wicketCatch?.name
                                              : item?.wicketTypeName ===
                                                "Bowled"
                                              ? "b :" + " " + item?.wicketBowler
                                                ? item?.wicketBowler?.name
                                                : ""
                                              : ""}
                                          </span>{" "}
                                          <span>
                                            {item?.wicketTypeName !== "Run out"
                                              ? item?.wicketBowler
                                                ? "b :" +
                                                  " " +
                                                  item?.wicketBowler?.name
                                                : ""
                                              : ""}
                                          </span>
                                        </>
                                      ) : (
                                        <span>{item?.wicketTypeName}</span>
                                      )}
                                    </Box>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    {item?.score || item?.score === 0
                                      ? item?.score
                                      : "-"}
                                  </TableCell>
                                  <TableCell className={`${classes.cell} `}>
                                    {item?.balls || item?.balls === 0
                                      ? item?.balls
                                      : "-"}
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    {item?.s4 || item?.s4 === 0
                                      ? item?.s4
                                      : "-"}
                                  </TableCell>
                                  <TableCell className={`${classes.cell}`}>
                                    {item?.s6 || item?.s6 === 0
                                      ? item?.s6
                                      : "-"}
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    {item?.score && item?.balls
                                      ? (
                                          (item.score / item.balls) *
                                          100
                                        ).toFixed(2)
                                      : "N/A"}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={100} className="no-data-td">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell className={`${classes.head} first-cell`}>
                          Extras
                        </TableCell>
                        <TableCell colSpan={5} className={`${classes.head} `}>
                          <Box className="extra-wrap">
                            {homeInningsData?.extra}{" "}
                            {homeInningsData?.extra &&
                            homeInningsData?.extra > 0 ? (
                              <Box className="extra-runs">
                                (
                                {homeInningsData?.wide !== 0 &&
                                  homeInningsData?.wide > 0 && (
                                    <span>W {homeInningsData?.wide},</span>
                                  )}
                                {homeInningsData?.noBall !== 0 &&
                                  homeInningsData?.noBall > 0 && (
                                    <span>NB {homeInningsData?.noBall},</span>
                                  )}
                                {homeInningsData?.legBye !== 0 &&
                                  homeInningsData?.legBye > 0 && (
                                    <span>LB {homeInningsData?.legBye},</span>
                                  )}
                                {homeInningsData?.bye !== 0 &&
                                  homeInningsData?.bye > 0 && (
                                    <span>B {homeInningsData?.bye}</span>
                                  )}
                                )
                              </Box>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box className="bowling-wrap">
                  <TableContainer
                    id="details-table"
                    className="teamsport-data-table team-sport-tables player-data-table"
                  >
                    {/* <Box className="overlay"></Box> */}
                    <Table
                      className="data-table"
                      style={{ minWidth: "max-content" }}
                    >
                      <TableHead className="table-header">
                        <TableRow className="table-head-row ">
                          <TableCell
                            className={`${classes.head} first-cell`}
                            style={{ width: "30%" }}
                          >
                            Bowling
                          </TableCell>
                          <TableCell className={classes.head}>O</TableCell>
                          <TableCell className={classes.head}>M</TableCell>
                          <TableCell className={classes.head}>R</TableCell>
                          <TableCell className={classes.head}>W</TableCell>
                          <TableCell className={classes.head}>Econ</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="table-body">
                        {homeInningsData?.CricketBowlingLines?.length > 0 ? (
                          <>
                            {homeInningsData?.CricketBowlingLines?.map(
                              (item, i) => {
                                return (
                                  <>
                                    <TableRow
                                      key={i}
                                      className="table-body-row "
                                    >
                                      <TableCell
                                        className={`${classes.cell} first-cell light-blue cursor-pointer`}
                                        onClick={() =>
                                          navigate(
                                            `/sport/statistics/${
                                              params?.sportId
                                            }/${
                                              params?.tournamentId
                                            }/league-data/${
                                              params?.teamId
                                            }/team-data/${
                                              item?.CricketPlayer?.id
                                            }/player-data?seasonId=${
                                              SeasonId ? SeasonId : ""
                                            }`
                                          )
                                        }
                                      >
                                        {item?.CricketPlayer?.name}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.over || item?.over === 0
                                          ? item?.over
                                          : "-"}
                                      </TableCell>
                                      <TableCell className={`${classes.cell} `}>
                                        {item?.maiden || item?.maiden === 0
                                          ? item?.maiden
                                          : "-"}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.run || item?.run === 0
                                          ? item?.run
                                          : "-"}
                                      </TableCell>
                                      <TableCell className={`${classes.cell}`}>
                                        {item?.wicket || item?.wicket === 0
                                          ? item?.wicket
                                          : "0"}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.run && item?.over
                                          ? (item.run / item.over).toFixed(2)
                                          : "N/A"}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={100} className="no-data-td">
                              No Data Available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </>
            ) : (
              <>
                <TableContainer
                  id="details-table"
                  className="teamsport-data-table team-sport-tables player-data-table"
                >
                  {/* <Box className="overlay"></Box> */}
                  <Table
                    className="data-table"
                    style={{ minWidth: "max-content" }}
                  >
                    <TableHead className="table-header">
                      <TableRow className="table-head-row ">
                        <TableCell
                          className={`${classes.head} first-cell`}
                          style={{ width: "30%" }}
                        >
                          Batting
                        </TableCell>
                        <TableCell className={classes.head}>R</TableCell>
                        <TableCell className={classes.head}>B</TableCell>
                        <TableCell className={classes.head}>4s</TableCell>
                        <TableCell className={classes.head}>6s</TableCell>
                        <TableCell className={classes.head}>S/R</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                      {awayInningsData?.CricketBattingLines?.length > 0 ? (
                        <>
                          {awayInningsData?.CricketBattingLines?.filter(
                            item => item?.wicketTypeName !== "Did not bat"
                          )?.map((item, i) => {
                            return (
                              <>
                                <TableRow key={i} className="table-body-row ">
                                  <TableCell
                                    className={`${classes.cell} first-cell`}
                                  >
                                    <Box
                                      className="light-blue cursor-pointer"
                                      onClick={() =>
                                        navigate(
                                          `/sport/statistics/${
                                            params?.sportId
                                          }/${
                                            params?.tournamentId
                                          }/league-data/${
                                            params?.teamId
                                          }/team-data/${
                                            item?.CricketPlayer?.id
                                          }/player-data?seasonId=${
                                            SeasonId ? SeasonId : ""
                                          }`
                                        )
                                      }
                                    >
                                      {item?.CricketPlayer?.name}
                                    </Box>
                                    <Box>
                                      {item?.wicketTypeName !== "Not out" ? (
                                        <>
                                          <span>
                                            {item?.wicketTypeName === "Caught"
                                              ? item?.wicketCatch
                                                ? "c :" +
                                                  " " +
                                                  item?.wicketCatch?.name
                                                : ""
                                              : item?.wicketTypeName === "LBW"
                                              ? "lbw"
                                              : item?.wicketTypeName ===
                                                "Caught & Bowled"
                                              ? "c & "
                                              : item?.wicketTypeName ===
                                                "Run out"
                                              ? "run not" +
                                                " " +
                                                "(" +
                                                item?.wicketCatch?.name
                                                ? item?.wicketCatch?.name
                                                : "" + ")"
                                              : item?.wicketTypeName ===
                                                "Stumped"
                                              ? "st :" +
                                                " " +
                                                item?.wicketCatch?.name
                                              : item?.wicketTypeName ===
                                                "Bowled"
                                              ? "b :" + " " + item?.wicketBowler
                                                ? item?.wicketBowler?.name
                                                : ""
                                              : ""}
                                          </span>{" "}
                                          <span>
                                            {item?.wicketTypeName !== "Run out"
                                              ? item?.wicketBowler
                                                ? "b :" +
                                                  " " +
                                                  item?.wicketBowler?.name
                                                : ""
                                              : ""}
                                          </span>
                                        </>
                                      ) : (
                                        <span>{item?.wicketTypeName}</span>
                                      )}
                                    </Box>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    {item?.score || item?.score === 0
                                      ? item?.score
                                      : "-"}
                                  </TableCell>
                                  <TableCell className={`${classes.cell} `}>
                                    {item?.balls || item?.balls === 0
                                      ? item?.balls
                                      : "-"}
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    {item?.s4 || item?.s4 === 0
                                      ? item?.s4
                                      : "-"}
                                  </TableCell>
                                  <TableCell className={`${classes.cell}`}>
                                    {item?.s6 || item?.s6 === 0
                                      ? item?.s6
                                      : "-"}
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    {item?.score && item?.balls
                                      ? (
                                          (item.score / item.balls) *
                                          100
                                        ).toFixed(2)
                                      : "N/A"}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={100} className="no-data-td">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell className={`${classes.head} first-cell`}>
                          Extras
                        </TableCell>
                        <TableCell colSpan={5} className={`${classes.head} `}>
                          <Box className="extra-wrap">
                            {awayInningsData?.extra}{" "}
                            {awayInningsData?.extra &&
                            awayInningsData?.extra > 0 ? (
                              <Box className="extra-runs">
                                (
                                {awayInningsData?.wide !== 0 &&
                                  awayInningsData?.wide > 0 && (
                                    <span>W {awayInningsData?.wide},</span>
                                  )}
                                {awayInningsData?.noBall !== 0 &&
                                  awayInningsData?.noBall > 0 && (
                                    <span>NB {awayInningsData?.noBall},</span>
                                  )}
                                {awayInningsData?.legBye !== 0 &&
                                  awayInningsData?.legBye > 0 && (
                                    <span>LB {awayInningsData?.legBye},</span>
                                  )}
                                {awayInningsData?.bye !== 0 &&
                                  awayInningsData?.bye > 0 && (
                                    <span>B {awayInningsData?.bye}</span>
                                  )}
                                )
                              </Box>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box className="bowling-wrap">
                  <TableContainer
                    id="details-table"
                    className="teamsport-data-table team-sport-tables player-data-table"
                  >
                    {/* <Box className="overlay"></Box> */}
                    <Table
                      className="data-table"
                      style={{ minWidth: "max-content" }}
                    >
                      <TableHead className="table-header">
                        <TableRow className="table-head-row ">
                          <TableCell
                            className={`${classes.head} first-cell`}
                            style={{ width: "30%" }}
                          >
                            Bowling
                          </TableCell>
                          <TableCell className={classes.head}>O</TableCell>
                          <TableCell className={classes.head}>M</TableCell>
                          <TableCell className={classes.head}>R</TableCell>
                          <TableCell className={classes.head}>W</TableCell>
                          <TableCell className={classes.head}>Econ</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="table-body">
                        {awayInningsData?.CricketBowlingLines?.length > 0 ? (
                          <>
                            {awayInningsData?.CricketBowlingLines?.map(
                              (item, i) => {
                                return (
                                  <>
                                    <TableRow
                                      key={i}
                                      className="table-body-row "
                                    >
                                      <TableCell
                                        className={`${classes.cell} first-cell light-blue cursor-pointer`}
                                        onClick={() =>
                                          navigate(
                                            `/sport/statistics/${
                                              params?.sportId
                                            }/${
                                              params?.tournamentId
                                            }/league-data/${
                                              params?.teamId
                                            }/team-data/${
                                              item?.CricketPlayer?.id
                                            }/player-data?seasonId=${
                                              SeasonId ? SeasonId : ""
                                            }`
                                          )
                                        }
                                      >
                                        {item?.CricketPlayer?.name}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.over || item?.over === 0
                                          ? item?.over
                                          : "-"}
                                      </TableCell>
                                      <TableCell className={`${classes.cell} `}>
                                        {item?.maiden || item?.maiden === 0
                                          ? item?.maiden
                                          : "-"}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.run || item?.run === 0
                                          ? item?.run
                                          : "-"}
                                      </TableCell>
                                      <TableCell className={`${classes.cell}`}>
                                        {item?.wicket || item?.wicket === 0
                                          ? item?.wicket
                                          : "0"}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.run && item?.over
                                          ? (item.run / item.over).toFixed(2)
                                          : "N/A"}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={100} className="no-data-td">
                              No Data Available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </>
            )}
          </Box>
        </Box>
      ) : (
        <Box className="view-page-wrap">
          <Box className="teamsport-header-details">
            <Typography className="heading-title">
              Round {latestMatch?.round ? latestMatch?.round : "-"}
            </Typography>
          </Box>
          {/* <Box className="view-round-wrap">
            <Box className="round-details-wrap">
              <Box className="round-name">Par - Mel</Box>
              <Box className="round-name active">PEn - bri</Box>
              <Box className="round-name">War - New</Box>
              <Box className="round-name">Man - CBY</Box>
              <Box className="round-name">Man - CBY</Box>
              <Box className="round-name">Man - CBY</Box>
            </Box>
            <Box className="round-details">
              <Typography className="see-also-text">See also:</Typography>
              <Typography className="round-result">
                <span className="light-blue">Round 1 Results</span>, Penrith
                Season Summary, Brisbane Season Summary, Penrith/Brisbane Head
                To Head
              </Typography>
            </Box>
          </Box> */}
          <Box className="round-view-details">
            <Box className="teamsport-header-details details-header">
              <Typography className="heading-title">
                {getTeamDetailsResult()}
              </Typography>
            </Box>
            <Box className="info-wrap">
              <Box className="match-info-box">Match info</Box>
              <Box className="tab-details-grid">
                <Box className="details-box">
                  <Typography className="details-text bold">
                    Match URL:{" "}
                  </Typography>
                  <Typography className="details-text link-color">
                    [Match URL]
                  </Typography>
                </Box>
                <Box className="details-box">
                  <Typography className="details-text bold">Date:</Typography>
                  <Typography className="details-text">
                    {moment(latestMatch?.startTime).format(
                      "dddd, Do MMMM, YYYY"
                    )}
                  </Typography>
                </Box>
                <Box className="details-box">
                  <Typography className="details-text bold">Status:</Typography>
                  <Typography className="details-text">
                    [{latestMatch?.status}]
                  </Typography>
                </Box>
                <Box className="details-box">
                  <Typography className="details-text bold">
                    Kick Off:
                  </Typography>
                  <Typography className="details-text">
                    [{moment(latestMatch?.startTime).format("h:mm A")}]
                  </Typography>
                </Box>
                <Box className="details-box">
                  <Typography className="details-text bold">
                    Referee:
                  </Typography>
                  <Typography className="details-text link-color">
                    [Referee]
                  </Typography>
                </Box>
                <Box className="details-box">
                  <Typography className="details-text bold">Venue:</Typography>
                  <Typography className="details-text">
                    [
                    {/* {latestMatch?.RLStadium ? latestMatch?.RLStadium?.name : ""} */}
                    {Number(params?.sportId) === 4
                      ? latestMatch?.CricketStadium?.name
                      : Number(params?.sportId) === 10
                      ? latestMatch?.NBAStadium?.name
                      : Number(params?.sportId) === 12 ||
                        Number(params?.sportId) === 13 ||
                        Number(params?.sportId) === 14
                      ? latestMatch?.RLStadium?.name
                      : Number(params?.sportId) === 15
                      ? latestMatch?.AFLStadium?.name
                      : Number(params?.sportId) === 9
                      ? latestMatch?.ARStadium?.name
                      : Number(params?.sportId) === 16
                      ? latestMatch?.GolfStadium?.name
                      : Number(params?.sportId) === 7
                      ? latestMatch?.TennisStadium?.name
                      : Number(params?.sportId) === 11
                      ? latestMatch?.BaseballStadium?.name
                      : Number(params?.sportId) === 17
                      ? latestMatch?.IceHockeyStadium?.name
                      : Number(params?.sportId) === 6
                      ? latestMatch?.BoxingStadium?.name
                      : Number(params?.sportId) === 5
                      ? latestMatch?.MMAStadium?.name
                      : Number(params?.sportId) === 8
                      ? latestMatch?.SoccerStadium?.name
                      : "-"}
                    ]
                  </Typography>
                </Box>
                <Box className="details-box">
                  <Typography className="details-text bold">Crowd:</Typography>
                  <Typography className="details-text link-color">
                    [Crowd]
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="info-wrap team-info-wrap">
              <Box className="match-info-box">Team info</Box>
              <Box className="tab-details-grid team-info-grid">
                <Box className="details-box">
                  <Typography className="details-text bold">
                    {latestMatch?.homeTeam?.name
                      ? latestMatch?.homeTeam?.name
                      : ""}
                    :
                  </Typography>
                  <Typography className="details-text ">
                    Previous Game -{" "}
                    <span className="link-color">
                      [{previousHome?.eventName ? previousHome?.eventName : ""}]
                    </span>
                  </Typography>
                  <Typography className="details-text ">
                    Next Game -
                    <span className="link-color">
                      {" "}
                      [{nextHome?.eventName ? nextHome?.eventName : ""}]
                    </span>
                  </Typography>
                  <Typography className="details-text ">
                    Out - <span className="link-color">[]</span>
                  </Typography>
                  <Typography className="details-text ">
                    In - <span className="link-color">[]</span>
                  </Typography>
                  <Typography className="details-text ">
                    Changes - <span className="link-color">[]</span>
                  </Typography>
                </Box>
                <Box className="details-box">
                  <Typography className="details-text bold">
                    {latestMatch?.awayTeam?.name
                      ? latestMatch?.awayTeam?.name
                      : ""}
                    :
                  </Typography>
                  <Typography className="details-text ">
                    Previous Game -{" "}
                    <span className="link-color">
                      [{previousAway?.eventName ? previousAway?.eventName : ""}]
                    </span>
                  </Typography>
                  <Typography className="details-text ">
                    Next Game -{" "}
                    <span className="link-color">
                      [{nextAway?.eventName ? nextAway?.eventName : ""}]
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="round-full-details">
            <Box className="round-grid match-name-wrap">
              <Box className="text-align-end">
                <Typography className="samll-text">
                  {latestMatch?.homeTeam?.name
                    ?.split(" ")
                    ?.slice(0, -1)
                    ?.join(" ")}
                </Typography>
                <Typography className="big-text">
                  {latestMatch?.homeTeam?.name?.split(" ")?.pop()}
                </Typography>
              </Box>
              <Box className="text-align-center d-flex text-align match-number">
                <Typography className="big-text">{homeTeamScore}</Typography>
                <Typography className="big-text">-</Typography>
                <Typography className="big-text">{awayTeamScore}</Typography>
              </Box>
              <Box className="text-align-start">
                <Typography className="samll-text">
                  {" "}
                  {latestMatch?.awayTeam?.name
                    ?.split(" ")
                    ?.slice(0, -1)
                    ?.join(" ")}
                </Typography>
                <Typography className="big-text">
                  {" "}
                  {latestMatch?.awayTeam?.name?.split(" ")?.pop()}
                </Typography>
              </Box>
            </Box>
            {/* <Box className="text-align-center result-header">
              <Typography className="header-name">Match Stats</Typography>
            </Box>
            <Box className="round-grid round-result-deatils">
              <Box className="text-align-end">
                <Typography className="text-result mb-19">
                  Halftime score
                </Typography>
                <Typography className="text-result">Penalities</Typography>
              </Box>
              <Box className="text-align-center d-flex text-align flex-direction-column ">
                <Box className="d-flex text-align match-number">
                  <Typography className="text-result mb-19">3</Typography>
                  <Typography className="text-result mb-19">-</Typography>
                  <Typography className="text-result mb-19">2</Typography>
                </Box>
                <Box className="d-flex text-align match-number">
                  <Typography className="text-result">3</Typography>
                  <Typography className="text-result">-</Typography>
                  <Typography className="text-result">2</Typography>
                </Box>
              </Box>
              <Box className="text-align-start"></Box>
            </Box>
            <Box className="text-align-center result-header">
              <Typography className="header-name">Match Scoresheet</Typography>
            </Box>
            <Box className="round-result-deatils">
              <Box className="round-grid ">
                <Box className="text-align-end">
                  <Typography className="text-result mb-19 light-blue">
                    Stephen CRICHTON
                  </Typography>
                </Box>
                <Box className="text-align-center d-flex text-align flex-direction-column ">
                  <Box className="d-flex text-align match-number">
                    <Typography className="text-result mb-19"></Typography>
                    <Typography className="text-result mb-19">T</Typography>
                    <Typography className="text-result mb-19">2</Typography>
                  </Box>
                </Box>
                <Box className="text-align-start">
                  <Typography className="text-result mb-19 light-blue">
                    Herbie FARNWORTH
                  </Typography>
                </Box>
              </Box>
              <Box className="round-grid ">
                <Box className="text-align-end">
                  <Typography className="text-result mb-19 light-blue">
                    Soni LUKE
                  </Typography>
                </Box>
                <Box className="text-align-center d-flex text-align flex-direction-column ">
                  <Box className="d-flex text-align match-number">
                    <Typography className="text-result mb-19"></Typography>
                    <Typography className="text-result mb-19"></Typography>
                    <Typography className="text-result mb-19"></Typography>
                  </Box>
                </Box>
                <Box className="text-align-start">
                  <Typography className="text-result mb-19 light-blue"></Typography>
                </Box>
              </Box>
              <Box className="round-grid ">
                <Box className="text-align-end">
                  <Typography className="text-result mb-19 light-blue">
                    Nathan CLEARY
                  </Typography>
                </Box>
                <Box className="text-align-center d-flex text-align flex-direction-column ">
                  <Box className="d-flex text-align match-number">
                    <Typography className="text-result mb-19">2/2</Typography>
                    <Typography className="text-result mb-19">2</Typography>
                    <Typography className="text-result mb-19">2/2</Typography>
                  </Box>
                </Box>
                <Box className="text-align-start">
                  <Typography className="text-result mb-19 light-blue">
                    Adam REYNOLDS
                  </Typography>
                </Box>
              </Box>
              <Box className="round-grid ">
                <Box className="text-align-end">
                  <Typography className="text-result mb-19 light-blue"></Typography>
                </Box>
                <Box className="text-align-center d-flex text-align flex-direction-column ">
                  <Box className="d-flex text-align match-number">
                    <Typography className="text-result mb-19">FG</Typography>
                  </Box>
                </Box>
                <Box className="text-align-start">
                  <Typography className="text-result mb-19 light-blue">
                    Adam REYNOLDS
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="text-align-center result-header">
              <Typography className="header-name">Teams</Typography>
            </Box>
            <Box className="round-result-deatils">
              <Box className="round-grid ">
                <Box className="text-align-end">
                  <Typography className="text-result mb-19 light-blue">
                    Dylan EDWARDS
                  </Typography>
                </Box>
                <Box className="text-align-center d-flex text-align flex-direction-column ">
                  <Box className="d-flex text-align match-number">
                    <Typography className="text-result mb-19">1</Typography>
                    <Typography className="text-result mb-19">FB</Typography>
                    <Typography className="text-result mb-19">2</Typography>
                  </Box>
                </Box>
                <Box className="text-align-start">
                  <Typography className="text-result mb-19 light-blue">
                    Selwyn COBBO
                  </Typography>
                </Box>
              </Box>
              <Box className="round-grid ">
                <Box className="text-align-end">
                  <Typography className="text-result mb-19 light-blue">
                    Sunia TURUVA
                  </Typography>
                </Box>
                <Box className="text-align-center d-flex text-align flex-direction-column ">
                  <Box className="d-flex text-align match-number">
                    <Typography className="text-result mb-19">2</Typography>
                    <Typography className="text-result mb-19">W</Typography>
                    <Typography className="text-result mb-19">3</Typography>
                  </Box>
                </Box>
                <Box className="text-align-start">
                  <Typography className="text-result mb-19 light-blue">
                    Corey OATES
                  </Typography>
                </Box>
              </Box>
              <Box className="round-grid ">
                <Box className="text-align-end">
                  <Typography className="text-result mb-19 light-blue">
                    Izack TAGO
                  </Typography>
                </Box>
                <Box className="text-align-center d-flex text-align flex-direction-column ">
                  <Box className="d-flex text-align match-number">
                    <Typography className="text-result mb-19">2</Typography>
                    <Typography className="text-result mb-19">C</Typography>
                    <Typography className="text-result mb-19">3</Typography>
                  </Box>
                </Box>
                <Box className="text-align-start">
                  <Typography className="text-result mb-19 light-blue">
                    Adam REYNOLDS
                  </Typography>
                </Box>
              </Box>
              <Box className="round-grid ">
                <Box className="text-align-end">
                  <Typography className="text-result mb-19 light-blue">
                    Stephen CRICHTON
                  </Typography>
                </Box>
                <Box className="text-align-center d-flex text-align flex-direction-column ">
                  <Box className="d-flex text-align match-number">
                    <Typography className="text-result mb-19">1</Typography>
                    <Typography className="text-result mb-19">FB</Typography>
                    <Typography className="text-result mb-19">2</Typography>
                  </Box>
                </Box>
                <Box className="text-align-start">
                  <Typography className="text-result mb-19 light-blue">
                    Herbie FARNWORTH
                  </Typography>
                </Box>
              </Box>

              <Box className="round-grid ">
                <Box className="text-align-end">
                  <Typography className="text-result mb-19 light-blue">
                    Stephen CRICHTON
                  </Typography>
                </Box>
                <Box className="text-align-center d-flex text-align flex-direction-column ">
                  <Box className="d-flex text-align match-number">
                    <Typography className="text-result mb-19">2</Typography>
                    <Typography className="text-result mb-19">W</Typography>
                    <Typography className="text-result mb-19">3</Typography>
                  </Box>
                </Box>
                <Box className="text-align-start">
                  <Typography className="text-result mb-19 light-blue">
                    Herbie FARNWORTH
                  </Typography>
                </Box>
              </Box>

              <Box className="round-grid ">
                <Box className="text-align-end">
                  <Typography className="text-result mb-19 light-blue">
                    Jarome LUAI
                  </Typography>
                </Box>
                <Box className="text-align-center d-flex text-align flex-direction-column ">
                  <Box className="d-flex text-align match-number">
                    <Typography className="text-result mb-19">2</Typography>
                    <Typography className="text-result mb-19">C</Typography>
                    <Typography className="text-result mb-19">2</Typography>
                  </Box>
                </Box>
                <Box className="text-align-start">
                  <Typography className="text-result mb-19 light-blue">
                    Ezra MAM
                  </Typography>
                </Box>
              </Box>

              <Box className="round-grid ">
                <Box className="text-align-end">
                  <Typography className="text-result mb-19 light-blue">
                    Nathan CLEARY <span>(c)</span>
                  </Typography>
                </Box>
                <Box className="text-align-center d-flex text-align flex-direction-column ">
                  <Box className="d-flex text-align match-number">
                    <Typography className="text-result mb-19">2</Typography>
                    <Typography className="text-result mb-19">C</Typography>
                    <Typography className="text-result mb-19">2</Typography>
                  </Box>
                </Box>
                <Box className="text-align-start">
                  <Typography className="text-result mb-19 light-blue">
                    Adam REYNOLDS <span>(c)</span>
                  </Typography>
                </Box>
              </Box>

              <Box className="round-grid ">
                <Box className="text-align-end">
                  <Typography className="text-result mb-19 light-blue">
                    Dylan EDWARDS
                  </Typography>
                </Box>
                <Box className="text-align-center d-flex text-align flex-direction-column ">
                  <Box className="d-flex text-align match-number">
                    <Typography className="text-result mb-19">1</Typography>
                    <Typography className="text-result mb-19">FB</Typography>
                    <Typography className="text-result mb-19">2</Typography>
                  </Box>
                </Box>
                <Box className="text-align-start">
                  <Typography className="text-result mb-19 light-blue">
                    Selwyn COBBO
                  </Typography>
                </Box>
              </Box>

              <Box className="round-grid ">
                <Box className="text-align-end">
                  <Typography className="text-result mb-19 light-blue">
                    Sunia TURUVA
                  </Typography>
                </Box>
                <Box className="text-align-center d-flex text-align flex-direction-column ">
                  <Box className="d-flex text-align match-number">
                    <Typography className="text-result mb-19">2</Typography>
                    <Typography className="text-result mb-19">W</Typography>
                    <Typography className="text-result mb-19">3</Typography>
                  </Box>
                </Box>
                <Box className="text-align-start">
                  <Typography className="text-result mb-19 light-blue">
                    Corey OATES
                  </Typography>
                </Box>
              </Box>

              <Box className="round-grid ">
                <Box className="text-align-end">
                  <Typography className="text-result mb-19 light-blue">
                    Izack TAGO
                  </Typography>
                </Box>
                <Box className="text-align-center d-flex text-align flex-direction-column ">
                  <Box className="d-flex text-align match-number">
                    <Typography className="text-result mb-19">2</Typography>
                    <Typography className="text-result mb-19">C</Typography>
                    <Typography className="text-result mb-19">3</Typography>
                  </Box>
                </Box>
                <Box className="text-align-start">
                  <Typography className="text-result mb-19 light-blue">
                    Adam REYNOLDS
                  </Typography>
                </Box>
              </Box>

              <Box className="round-grid ">
                <Box className="text-align-end">
                  <Typography className="text-result mb-19 light-blue">
                    Stephen CRICHTON
                  </Typography>
                </Box>
                <Box className="text-align-center d-flex text-align flex-direction-column ">
                  <Box className="d-flex text-align match-number">
                    <Typography className="text-result mb-19">1</Typography>
                    <Typography className="text-result mb-19">FB</Typography>
                    <Typography className="text-result mb-19">2</Typography>
                  </Box>
                </Box>
                <Box className="text-align-start">
                  <Typography className="text-result mb-19 light-blue">
                    Herbie FARNWORTH
                  </Typography>
                </Box>
              </Box>

              <Box className="round-grid ">
                <Box className="text-align-end">
                  <Typography className="text-result mb-19 light-blue">
                    Stephen CRICHTON
                  </Typography>
                </Box>
                <Box className="text-align-center d-flex text-align flex-direction-column ">
                  <Box className="d-flex text-align match-number">
                    <Typography className="text-result mb-19">2</Typography>
                    <Typography className="text-result mb-19">W</Typography>
                    <Typography className="text-result mb-19">3</Typography>
                  </Box>
                </Box>
                <Box className="text-align-start">
                  <Typography className="text-result mb-19 light-blue">
                    Herbie FARNWORTH
                  </Typography>
                </Box>
              </Box>

              <Box className="round-grid ">
                <Box className="text-align-end">
                  <Typography className="text-result light-blue">
                    Ivan CLEARY
                  </Typography>
                </Box>
                <Box className="text-align-center d-flex text-align flex-direction-column ">
                  <Box className="d-flex text-align match-number">
                    <Typography className="text-result">COACH</Typography>
                  </Box>
                </Box>
                <Box className="text-align-start">
                  <Typography className="text-result light-blue">
                    Kevin WALTERS
                  </Typography>
                </Box>
              </Box>
            </Box> */}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ViewPage;
