import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import moment from "moment";
import { Config } from "src/helpers/context";

const StickyTableCell = withStyles(theme => ({
  head: {
    right: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    // minWidth: "45px",
    // boxShadow: "inset 1px 0px 6px 0px #ebebeb",
    "@media(max-width: 1280px)": {
      maxWidth: "45px",
      boxShadow: "inset 1px 0px 6px 0px #ebebeb"
    }
  },
  body: {
    right: 0,
    position: "sticky",
    background: "#FFFFFF",
    boxShadow: "inset 1px 0px 6px 0px #ebebeb",
    "@media(max-width: 1280px)": {
      boxShadow: "inset 1px 0px 6px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const TeamSummaryDetails = ({
  setViewPage,
  headerData,
  setViewListPage,
  setListDataPage,
  setListPage,
  setPlayerName
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const SeasonId = searchParams.get("seasonId");
  const [loader, setLoader] = useState(false);
  const [teamSummaryData, setTeamSummaryData] = useState([]);
  const [playerDetailsLoader, setPlayerDetailsLoader] = useState(false);
  const [teamPlayerData, setTeamPlayerData] = useState([]);

  useEffect(() => {
    fetchTeamSummaryDetails();
    fetchTeamPlayerDetails();
  }, [params, SeasonId]);

  const fetchTeamSummaryDetails = async () => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/team/event/${params?.teamId}?SportId=${
          params?.sportId
        }&tournamentId=${params?.tournamentId}&seasonId=${
          SeasonId ? SeasonId : ""
        }`
      );
      if (status === 200) {
        setLoader(false);
        setTeamSummaryData(data?.result);
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  };
  const fetchTeamPlayerDetails = async () => {
    setPlayerDetailsLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/team/player/${params?.teamId}?SportId=${
          params?.sportId
        }&seasonId=${SeasonId ? SeasonId : ""}&tournamentId=${
          params?.tournamentId
        }`
      );
      if (status === 200) {
        setPlayerDetailsLoader(false);
        setTeamPlayerData(data?.result);
      } else {
        setPlayerDetailsLoader(false);
      }
    } catch (err) {
      setPlayerDetailsLoader(false);
    }
  };

  const getTeamScores = data => {
    const teamScores =
      Number(params?.sportId) === 4
        ? data?.CricketScores
        : Number(params?.sportId) === 10
        ? data?.NBAScores
        : Number(params?.sportId) === 12 ||
          Number(params?.sportId) === 13 ||
          Number(params?.sportId) === 14
        ? data?.RLScores
        : Number(params?.sportId) === 15
        ? data?.AFLScores
        : Number(params?.sportId) === 9
        ? data?.ARScores
        : Number(params?.sportId) === 16
        ? data?.GolfScores
        : Number(params?.sportId) === 7
        ? data?.TennisScores
        : Number(params?.sportId) === 11
        ? data?.BaseballScores
        : Number(params?.sportId) === 17
        ? data?.IceHockeyScores
        : Number(params?.sportId) === 6
        ? data?.BoxingScores
        : Number(params?.sportId) === 5
        ? data?.MMAScores
        : Number(params?.sportId) === 8
        ? data?.SoccerScores
        : "-";

    const hometeamScore = teamScores?.filter(
      item => item?.teamId === Number(params?.teamId)
    );
    const awayTeamScore = teamScores?.filter(
      item => item?.teamId !== Number(params?.teamId)
    );

    const homeScore =
      Number(params?.sportId) === 4
        ? hometeamScore?.[0]?.score?.innings?.inning1
          ? hometeamScore?.[0]?.score?.innings?.inning1?.score +
            "/" +
            hometeamScore?.[0]?.score?.innings?.inning1?.wickets +
            " " +
            "(" +
            hometeamScore?.[0]?.score?.innings?.inning1?.overs +
            ")" +
            (hometeamScore?.[0]?.score?.innings?.inning2
              ? "&" +
                (hometeamScore?.[0]?.score?.innings?.inning2?.score +
                  "/" +
                  hometeamScore?.[0]?.score?.innings?.inning2?.wickets +
                  " " +
                  "(" +
                  hometeamScore?.[0]?.score?.innings?.inning2?.overs +
                  ")")
              : "")
          : "-"
        : hometeamScore?.[0]?.score?.current;

    const awayScore =
      Number(params?.sportId) === 4
        ? awayTeamScore?.[0]?.score?.innings?.inning1
          ? awayTeamScore?.[0]?.score?.innings?.inning1?.score +
            "/" +
            awayTeamScore?.[0]?.score?.innings?.inning1?.wickets +
            " " +
            "(" +
            awayTeamScore?.[0]?.score?.innings?.inning1?.overs +
            ")" +
            (awayTeamScore?.[0]?.score?.innings?.inning2
              ? "&" +
                (awayTeamScore?.[0]?.score?.innings?.inning2?.score +
                  "/" +
                  awayTeamScore?.[0]?.score?.innings?.inning2?.wickets +
                  " " +
                  "(" +
                  awayTeamScore?.[0]?.score?.innings?.inning2?.overs +
                  ")")
              : "")
          : "-"
        : awayTeamScore?.[0]?.score?.current;
    return homeScore + "-" + awayScore;
  };

  return (
    <>
      <Box className="teamsport-data-accordian league-data-details mb-18">
        <Box className="teamsport-section-details">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{headerData?.team?.name} - Results</Typography>
            </AccordionSummary>
            <AccordionDetails className="team-accordion-details">
              <Box className="short-name-details">
                <Typography className="short-name">
                  H - <span>Home</span>, A - <span>Away</span>, N -{" "}
                  <span>Neutral/Alternative Venue</span>, W - <span>Win</span>,
                  L - <span>Loss</span>, D - <span>Draw</span>
                </Typography>
              </Box>
              <Box className="teamsport-header-details">
                <Typography className="heading-title">Results</Typography>
              </Box>
              <Typography className="note" variant="p">
                To view more info on a match, click the <span>View</span>{" "}
                button.
              </Typography>
              <Box className="table-overlay">
                {loader && (
                  <div className="allsport-loader-center">
                    <Loader />
                  </div>
                )}
                {!loader && (
                  <>
                    <TableContainer
                      id="details-table"
                      className="teamsport-data-table team-sport-tables"
                    >
                      {/* <Box className="overlay"></Box> */}
                      <Table
                        className="data-table"
                        style={{ minWidth: "max-content" }}
                      >
                        <TableHead className="table-header">
                          <TableRow className="table-head-row ">
                            <TableCell className={`${classes.head} first-cell`}>
                              Date
                            </TableCell>
                            <TableCell className={classes.head}>
                              Day/Time
                            </TableCell>
                            <TableCell className={classes.head}>
                              Competition
                            </TableCell>
                            <TableCell className={classes.head}></TableCell>
                            <TableCell className={classes.head}>
                              Opposition
                            </TableCell>
                            <TableCell className={classes.head}></TableCell>
                            <TableCell
                              className={classes.head}
                              // style={{ width: "6%" }}
                            >
                              Score
                            </TableCell>
                            <TableCell className={classes.head}>
                              Venue
                            </TableCell>
                            <TableCell className={classes.head}>
                              Crowd
                            </TableCell>
                            <TableCell className={classes.head}>Rank</TableCell>
                            <StickyTableCell
                              className={classes.head}
                            ></StickyTableCell>
                            {/* <TableCell></TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody className="table-body">
                          {teamSummaryData?.length > 0 ? (
                            <>
                              {teamSummaryData?.map((item, i) => {
                                return (
                                  <>
                                    <TableRow
                                      key={i}
                                      className="table-body-row "
                                    >
                                      <TableCell
                                        className={`${classes.cell} first-cell`}
                                      >
                                        {item?.startTime
                                          ? moment(item?.startTime).format(
                                              "MMM Do"
                                            )
                                          : "-"}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.startTime
                                          ? moment(item?.startTime).format(
                                              "ddd hh:mma"
                                            )
                                          : "-"}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.round
                                          ? "Round " + item?.round
                                          : "-"}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {Number(params?.teamId) ===
                                        item?.homeTeamId
                                          ? "H"
                                          : "A"}
                                      </TableCell>
                                      <TableCell
                                        className={`${classes.cell} light-blue cursor-pointer`}
                                        onClick={() =>
                                          navigate(
                                            `/sport/statistics/${
                                              params?.sportId
                                            }/${
                                              params?.tournamentId
                                            }/league-data/${
                                              Number(params?.teamId) ===
                                              item?.homeTeamId
                                                ? item?.awayTeamId
                                                : item?.homeTeamId
                                            }/team-data?seasonId=${
                                              SeasonId ? SeasonId : ""
                                            }`
                                          )
                                        }
                                      >
                                        {Number(params?.teamId) ===
                                        item?.homeTeamId
                                          ? item?.awayTeam?.name
                                          : item?.homeTeam?.name}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {Number(params?.teamId) ===
                                        item?.homeTeamId
                                          ? item?.winnerCode
                                            ? item?.winnerCode === 1
                                              ? "W"
                                              : "L"
                                            : ""
                                          : item?.winnerCode
                                          ? item?.winnerCode === 2
                                            ? "W"
                                            : "L"
                                          : ""}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {getTeamScores(item)}
                                      </TableCell>
                                      <TableCell
                                        className={`${classes.cell} light-blue cursor-pointer`}
                                        onClick={() =>
                                          navigate(
                                            `/sport/statistics/${
                                              params?.sportId
                                            }/${
                                              params?.tournamentId
                                            }/league-data/${
                                              params?.teamId
                                            }/team-data/${
                                              Number(params?.sportId) === 4
                                                ? item?.CricketStadiumId
                                                : Number(params?.sportId) === 10
                                                ? item?.NBAStadiumId
                                                : Number(params?.sportId) ===
                                                    12 ||
                                                  Number(params?.sportId) ===
                                                    13 ||
                                                  Number(params?.sportId) === 14
                                                ? item?.RLStadiumId
                                                : Number(params?.sportId) === 15
                                                ? item?.AFLStadiumId
                                                : Number(params?.sportId) === 9
                                                ? item?.ARStadiumId
                                                : Number(params?.sportId) === 16
                                                ? item?.GolfStadiumId
                                                : Number(params?.sportId) === 7
                                                ? item?.TennisStadiumId
                                                : Number(params?.sportId) === 11
                                                ? item?.BaseballStadiumId
                                                : Number(params?.sportId) === 17
                                                ? item?.IceHockeyStadiumId
                                                : Number(params?.sportId) === 6
                                                ? item?.BoxingStadiumId
                                                : Number(params?.sportId) === 5
                                                ? item?.MMAStadiumId
                                                : Number(params?.sportId) === 8
                                                ? item?.SoccerStadiumId
                                                : "-"
                                            }/stadium-data?seasonId=${
                                              SeasonId ? SeasonId : ""
                                            }`
                                          )
                                        }
                                      >
                                        {Number(params?.sportId) === 4
                                          ? item?.CricketStadium?.name
                                          : Number(params?.sportId) === 10
                                          ? item?.NBAStadium?.name
                                          : Number(params?.sportId) === 12 ||
                                            Number(params?.sportId) === 13 ||
                                            Number(params?.sportId) === 14
                                          ? item?.RLStadium?.name
                                          : Number(params?.sportId) === 15
                                          ? item?.AFLStadium?.name
                                          : Number(params?.sportId) === 9
                                          ? item?.ARStadium?.name
                                          : Number(params?.sportId) === 16
                                          ? item?.GolfStadium?.name
                                          : Number(params?.sportId) === 7
                                          ? item?.TennisStadium?.name
                                          : Number(params?.sportId) === 11
                                          ? item?.BaseballStadium?.name
                                          : Number(params?.sportId) === 17
                                          ? item?.IceHockeyStadium?.name
                                          : Number(params?.sportId) === 6
                                          ? item?.BoxingStadium?.name
                                          : Number(params?.sportId) === 5
                                          ? item?.MMAStadium?.name
                                          : Number(params?.sportId) === 8
                                          ? item?.SoccerStadium?.name
                                          : "-"}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        -
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        -
                                      </TableCell>
                                      <StickyTableCell className={classes.cell}>
                                        <span
                                          className="view-btn cursor-pointer"
                                          onClick={() => {
                                            setViewPage(true);
                                            setViewListPage(item?.id);
                                          }}
                                        >
                                          View
                                        </span>
                                      </StickyTableCell>
                                      {/* <TableCell>
                                        <span className="head-to-head cursor-pointer">
                                          Head to Head
                                        </span>
                                      </TableCell> */}
                                    </TableRow>
                                  </>
                                );
                              })}
                              {/* <TableRow className="table-body-row table-result-row">
                                <TableCell className="first-cell"></TableCell>
                                <TableCell></TableCell>
                                <TableCell>Round 12</TableCell>
                                <TableCell></TableCell>
                                <TableCell>[bye]</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                              </TableRow> */}
                            </>
                          ) : (
                            <TableRow>
                              <TableCell colSpan={12} className="no-data-td">
                                No Data Available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Box className="teamsport-data-accordian league-data-details">
        <Box className="teamsport-section-details">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{headerData?.team?.name} - Players</Typography>
            </AccordionSummary>
            <AccordionDetails className="team-accordion-details">
              <Box className="short-name-details">
                <Typography className="short-name">
                  Rep - <span>Representative Status</span>, App -{" "}
                  <span>Starting Appearances</span>, Int -{" "}
                  <span>Interchange Appearances</span>, T -{" "}
                  <span>Try/Penalty Goal</span>, G -
                  <span>Conversion/Penalty Goal</span> , FG -{" "}
                  <span>Field Goal/Drop Goal</span>, Pts -<span>Points</span>, B
                  - <span>5/10 Minute Sin Bins</span>, S - <span>Sendoffs</span>
                </Typography>
              </Box>
              <Box className="teamsport-header-details">
                <Typography className="heading-title">Players</Typography>
              </Box>
              <Typography className="note" variant="p">
                To view more info on a match, click the <span>List</span>{" "}
                button.
              </Typography>
              <Box className="table-overlay">
                {playerDetailsLoader && (
                  <div className="allsport-loader-center">
                    <Loader />
                  </div>
                )}
                {!playerDetailsLoader && (
                  <>
                    {Number(params?.sportId) === 4 ? (
                      <TableContainer
                        id="details-table"
                        className="teamsport-data-table  team-sport-tables"
                      >
                        {/* <Box className="overlay"></Box> */}
                        <Table
                          className="data-table"
                          style={{ minWidth: "max-content" }}
                        >
                          <TableHead className="table-sub-header">
                            <TableRow className="table-sub-head-row">
                              <TableCell
                                className={`${classes.head} sticky-head`}
                                colSpan={4}
                              ></TableCell>
                              <TableCell className={classes.head} colSpan={13}>
                                Batting Summary
                              </TableCell>
                              <TableCell className={classes.head} colSpan={12}>
                                Bowling Summary
                              </TableCell>
                              <StickyTableCell
                                className={classes.head}
                              ></StickyTableCell>
                            </TableRow>
                          </TableHead>
                          <TableHead className="table-header">
                            <TableRow className="table-head-row ">
                              <TableCell
                                className={`${classes.head} first-cell`}
                              >
                                Players
                              </TableCell>
                              <TableCell className={classes.head}>
                                Age
                              </TableCell>
                              <TableCell className={classes.head}>
                                Born
                              </TableCell>
                              <TableCell
                                className={`${classes.head} border-right`}
                              >
                                Rep
                              </TableCell>
                              <TableCell className={classes.head}>M</TableCell>
                              <TableCell className={classes.head}>
                                Inn
                              </TableCell>
                              <TableCell className={classes.head}>No</TableCell>
                              <TableCell className={classes.head}>
                                Runs
                              </TableCell>
                              <TableCell className={classes.head}>
                                HSS
                              </TableCell>
                              <TableCell className={classes.head}>
                                Avg
                              </TableCell>
                              <TableCell className={classes.head}>BF</TableCell>
                              <TableCell className={classes.head}>SR</TableCell>
                              <TableCell className={classes.head}>
                                100
                              </TableCell>
                              <TableCell className={classes.head}>
                                200
                              </TableCell>
                              <TableCell className={classes.head}>50</TableCell>
                              <TableCell className={classes.head}>4s</TableCell>
                              <TableCell
                                className={`${classes.head} border-right`}
                              >
                                6s
                              </TableCell>
                              <TableCell className={classes.head}>M</TableCell>
                              <TableCell className={classes.head}>
                                Inn
                              </TableCell>
                              <TableCell className={classes.head}>B</TableCell>
                              <TableCell className={classes.head}>
                                Runs
                              </TableCell>
                              <TableCell className={classes.head}>
                                Wkts
                              </TableCell>
                              <TableCell className={classes.head}>
                                BBI
                              </TableCell>
                              <TableCell className={classes.head}>
                                BBM
                              </TableCell>
                              <TableCell className={classes.head}>
                                Econ
                              </TableCell>
                              <TableCell className={classes.head}>
                                Avg
                              </TableCell>
                              <TableCell className={classes.head}>SR</TableCell>
                              <TableCell className={classes.head}>5W</TableCell>
                              <TableCell className={classes.head}>
                                10W
                              </TableCell>
                              <StickyTableCell
                                className={classes.head}
                              ></StickyTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="table-body">
                            {teamPlayerData?.length > 0 ? (
                              <>
                                {teamPlayerData?.map((item, i) => {
                                  return (
                                    <>
                                      <TableRow
                                        key={i}
                                        className="table-body-row "
                                      >
                                        <TableCell
                                          className={`${classes.cell} first-cell light-blue cursor-pointer`}
                                          onClick={() =>
                                            navigate(
                                              `/sport/statistics/${
                                                params?.sportId
                                              }/${
                                                params?.tournamentId
                                              }/league-data/${
                                                params?.teamId
                                              }/team-data/${
                                                item?.id
                                              }/player-data?seasonId=${
                                                SeasonId ? SeasonId : ""
                                              }`
                                            )
                                          }
                                        >
                                          {item?.name}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.dob
                                            ? moment().diff(
                                                moment(item?.dob),
                                                "years"
                                              )
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.Country
                                            ? item?.Country?.country
                                            : "-"}
                                        </TableCell>
                                        <TableCell
                                          className={`${classes.cell} border-right`}
                                        >
                                          <Box className="flag-img-wrap">
                                            {item?.Country?.country_flag?.includes(
                                              "uploads"
                                            ) ? (
                                              <Box className="flag-img">
                                                <img
                                                  src={
                                                    Config.mediaURL +
                                                    item?.Country?.country_flag
                                                  }
                                                  alt="flag"
                                                />
                                              </Box>
                                            ) : item?.Country?.country_flag ? (
                                              <Box className="flag-img">
                                                <img
                                                  src={
                                                    item?.Country?.country_flag
                                                  }
                                                  alt="flag"
                                                />
                                              </Box>
                                            ) : (
                                              "-"
                                            )}
                                          </Box>
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.battingLine?.match ||
                                          item?.battingLine?.match === 0
                                            ? item?.battingLine?.match
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.battingLine?.inning ||
                                          item?.battingLine?.inning === 0
                                            ? item?.battingLine?.inning
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.battingLine?.notOut ||
                                          item?.battingLine?.notOut === 0
                                            ? item?.battingLine?.notOut
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.battingLine?.runs ||
                                          item?.battingLine?.runs === 0
                                            ? item?.battingLine?.runs
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.battingLine?.HS ||
                                          item?.battingLine?.HS === 0
                                            ? item?.battingLine?.HS
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.battingLine?.AVG
                                            ? item?.battingLine?.AVG.toFixed(
                                                2
                                              ) + "%"
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.battingLine?.BF ||
                                          item?.battingLine?.BF === 0
                                            ? item?.battingLine?.BF
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.battingLine?.SR
                                            ? item?.battingLine?.SR.toFixed(2)
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.battingLine?.century ||
                                          item?.battingLine?.century === 0
                                            ? item?.battingLine?.century
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.battingLine?.two_century ||
                                          item?.battingLine?.two_century === 0
                                            ? item?.battingLine?.two_century
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.battingLine?.half_century ||
                                          item?.battingLine?.half_century === 0
                                            ? item?.battingLine?.half_century
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.battingLine?.four ||
                                          item?.battingLine?.four === 0
                                            ? item?.battingLine?.four
                                            : "-"}
                                        </TableCell>
                                        <TableCell
                                          className={`${classes.cell} border-right`}
                                        >
                                          {item?.battingLine?.six ||
                                          item?.battingLine?.six === 0
                                            ? item?.battingLine?.six
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.match ||
                                          item?.bowlingLine?.match === 0
                                            ? item?.bowlingLine?.match
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.inning ||
                                          item?.bowlingLine?.inning === 0
                                            ? item?.bowlingLine?.inning
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.B ||
                                          item?.bowlingLine?.B === 0
                                            ? item?.bowlingLine?.B
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.runs ||
                                          item?.bowlingLine?.runs === 0
                                            ? item?.bowlingLine?.runs
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.wkts ||
                                          item?.bowlingLine?.wkts === 0
                                            ? item?.bowlingLine?.wkts
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.BBI
                                            ? item?.bowlingLine?.BBI
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.BBM
                                            ? item?.bowlingLine?.BBM
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.econ
                                            ? item?.bowlingLine?.econ.toFixed(2)
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.AVG ||
                                          item?.bowlingLine?.AVG === 0
                                            ? item?.bowlingLine?.AVG.toFixed(2)
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.SR ||
                                          item?.bowlingLine?.SR === 0
                                            ? item?.bowlingLine?.SR.toFixed(2)
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.five_wicket ||
                                          item?.bowlingLine?.five_wicket === 0
                                            ? item?.bowlingLine?.five_wicket
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.bowlingLine?.ten_wicket ||
                                          item?.bowlingLine?.ten_wicket === 0
                                            ? item?.bowlingLine?.ten_wicket
                                            : "-"}
                                        </TableCell>
                                        <StickyTableCell
                                          className={classes.cell}
                                        >
                                          <span
                                            className="view-btn cursor-pointer"
                                            onClick={() => {
                                              setListPage(true);
                                              setListDataPage(item?.id);
                                              setPlayerName(item?.name);
                                            }}
                                          >
                                            List
                                          </span>
                                        </StickyTableCell>
                                      </TableRow>
                                    </>
                                  );
                                })}
                                {/* <TableRow className="table-body-row table-result-row">
                                <TableCell className="first-cell">
                                  10 players
                                </TableCell>
                                <TableCell colSpan={2}>
                                  3 current/16 total
                                </TableCell>
                                <TableCell>25.62</TableCell>
                                <TableCell></TableCell>
                                <TableCell>24 games</TableCell>
                                <TableCell></TableCell>
                                <TableCell>29</TableCell>
                                <TableCell>20</TableCell>
                                <TableCell>2</TableCell>
                                <TableCell>159</TableCell>
                                <TableCell>0</TableCell>
                                <TableCell>0</TableCell>
                                <TableCell></TableCell>
                              </TableRow> */}
                              </>
                            ) : (
                              <TableRow>
                                <TableCell colSpan={100} className="no-data-td">
                                  No Data Available
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <TableContainer
                        id="details-table"
                        className="teamsport-data-table team-sport-tables"
                      >
                        {/* <Box className="overlay"></Box> */}
                        <Table className="data-table" style={{ minWidth: 940 }}>
                          <TableHead className="table-header">
                            <TableRow className="table-head-row ">
                              <TableCell
                                className={`${classes.head} first-cell`}
                              >
                                Players
                              </TableCell>
                              <TableCell className={classes.head}>
                                Age
                              </TableCell>
                              <TableCell className={classes.head}>
                                Born
                              </TableCell>
                              <TableCell className={classes.head}>
                                Rep
                              </TableCell>
                              <TableCell className={classes.head}>
                                App
                              </TableCell>
                              <TableCell className={classes.head}>
                                Int
                              </TableCell>
                              <TableCell className={classes.head}>
                                Tot
                              </TableCell>
                              <TableCell className={classes.head}>T</TableCell>
                              <TableCell className={classes.head}>G</TableCell>
                              <TableCell className={classes.head}>FG</TableCell>
                              <TableCell className={classes.head}>
                                Pts
                              </TableCell>
                              <TableCell className={classes.head}>B</TableCell>
                              <TableCell className={classes.head}>S</TableCell>
                              <StickyTableCell
                                className={classes.head}
                              ></StickyTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="table-body">
                            {teamPlayerData?.length > 0 ? (
                              <>
                                {teamPlayerData?.map((item, i) => {
                                  return (
                                    <>
                                      <TableRow
                                        key={i}
                                        className="table-body-row "
                                      >
                                        <TableCell
                                          className={`${classes.cell} first-cell light-blue cursor-pointer`}
                                          onClick={() =>
                                            navigate(
                                              `/sport/statistics/${
                                                params?.sportId
                                              }/${
                                                params?.tournamentId
                                              }/league-data/${
                                                params?.teamId
                                              }/team-data/${
                                                item?.id
                                              }/player-data?seasonId=${
                                                SeasonId ? SeasonId : ""
                                              }`
                                            )
                                          }
                                        >
                                          {item?.name}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.dob
                                            ? moment().diff(
                                                moment(item?.dob),
                                                "years"
                                              )
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.Country
                                            ? item?.Country?.country
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          <Box className="flag-img-wrap">
                                            {item?.Country?.country_flag?.includes(
                                              "uploads"
                                            ) ? (
                                              <Box className="flag-img">
                                                <img
                                                  src={
                                                    Config.mediaURL +
                                                    item?.Country?.country_flag
                                                  }
                                                  alt="flag"
                                                />
                                              </Box>
                                            ) : item?.Country?.country_flag ? (
                                              <Box className="flag-img">
                                                <img
                                                  src={
                                                    item?.Country?.country_flag
                                                  }
                                                  alt="flag"
                                                />
                                              </Box>
                                            ) : (
                                              "-"
                                            )}

                                            {/* <Box className="flag-img">
                                            <img src={Canada} alt="flag" />
                                          </Box>
                                          <Box className="flag-img">
                                            <img src={USA} alt="flag" />
                                          </Box> */}
                                          </Box>
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.app || item?.app === 0
                                            ? item?.app
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.Int || item?.Int === 0
                                            ? item?.Int
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.Tot || item?.Tot === 0
                                            ? item?.Tot
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.T || item?.T === 0
                                            ? item?.T
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.G || item?.G === 0
                                            ? item?.G
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.FG || item?.FG === 0
                                            ? item?.FG
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.Pts || item?.Pts === 0
                                            ? item?.Pts
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.B || item?.B === 0
                                            ? item?.B
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {" "}
                                          {item?.S || item?.S === 0
                                            ? item?.S
                                            : "-"}
                                        </TableCell>
                                        <StickyTableCell
                                          className={classes.cell}
                                        >
                                          <span
                                            className="view-btn cursor-pointer"
                                            onClick={() => {
                                              setListPage(true);
                                              setListDataPage(item?.id);
                                              setPlayerName(item?.name);
                                            }}
                                          >
                                            List
                                          </span>
                                        </StickyTableCell>
                                      </TableRow>
                                    </>
                                  );
                                })}
                                {/* <TableRow className="table-body-row table-result-row">
                                <TableCell className="first-cell">
                                  10 players
                                </TableCell>
                                <TableCell colSpan={2}>
                                  3 current/16 total
                                </TableCell>
                                <TableCell>25.62</TableCell>
                                <TableCell></TableCell>
                                <TableCell>24 games</TableCell>
                                <TableCell></TableCell>
                                <TableCell>29</TableCell>
                                <TableCell>20</TableCell>
                                <TableCell>2</TableCell>
                                <TableCell>159</TableCell>
                                <TableCell>0</TableCell>
                                <TableCell>0</TableCell>
                                <TableCell></TableCell>
                              </TableRow> */}
                              </>
                            ) : (
                              <TableRow>
                                <TableCell colSpan={100} className="no-data-td">
                                  No Data Available
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </>
  );
};

export default TeamSummaryDetails;
