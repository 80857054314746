import React, { useEffect, useRef, useState } from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { ReactComponent as CheckBoxChecked } from "../../../../../assets/images/oddsComparison/filterCheckboxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "../../../../../assets/images/oddsComparison/filterCheckBoxUnChecked.svg";
import { ReactComponent as Indeterminent } from "../../../../../assets/images/oddsComparison/filterIndeterminent.svg";
import { ReactComponent as FilterPlus } from "../../../../../assets/images/oddsComparison/filterPlus.svg";
import { ReactComponent as FilterRight } from "../../../../../assets/images/oddsComparison/filterRight.svg";
import { ReactComponent as DownArrow } from "../../../../../assets/images/oddsComparison/downArrow.svg";
import { ReactComponent as UpArrow } from "../../../../../assets/images/oddsComparison/upArrow.svg";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "src/components/Loader";

const SportCustomDropdown = ({
  mainOption,
  sportId,
  isOpen,
  toggleDropdown,
  type,
  setMainOption,
  dropdownRef,
  id,
}) => {
  const [tournamentListLoading, setTournamentListLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const tournamentLimit = 50;

  const selectAllRef = useRef();
  useEffect(() => {
    if (mainOption?.isExpanded && type !== "featured_news") {
      if (!(mainOption?.tournaments?.length > 0)) {
        getSportTournamentName(mainOption?.tournaments?.length);
      }
    }
  }, [mainOption?.isExpanded]);

  const getSportTournamentName = async (offsetVal) => {
    setTournamentListLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/getTournamentsByUser?sportId=${sportId}&tournament=&limit=${tournamentLimit}&offset=${offsetVal}&isSetting=true`,
      );
      if (status === 200) {
        let newData = data?.result?.map((item) => {
          return {
            ...item,
            // selected: mainOption?.isSport ? true : false
            selected:
              mainOption?.isIntermediate &&
              mainOption?.isSport &&
              mainOption.selectedTournaments?.includes(item.id)
                ? true
                : mainOption?.isSport && !mainOption?.isIntermediate
                  ? true
                  : false,
          };
        });
        setMainOption((prevOptions) =>
          prevOptions.map((option) =>
            option.id === sportId
              ? {
                  ...option,
                  tournaments:
                    offsetVal === 0
                      ? newData
                      : [...option.tournaments, ...newData],
                  tournamentCount: data.count,
                }
              : option,
          ),
        );
        setHasMore(newData?.length === tournamentLimit);
        setTournamentListLoading(false);
      }
    } catch (err) {
      setTournamentListLoading(false);
      console.error(err);
    }
  };

  const fetchMoreData = () => {
    if (tournamentListLoading) return;
    const newOffset = offset + tournamentLimit;
    setOffset(newOffset);
    getSportTournamentName(newOffset);
  };

  const handleOptionClick = (options, tournamentId) => {
    let filterData = options?.map((opt) =>
      opt?.id === tournamentId ? { ...opt, selected: !opt.selected } : opt,
    );

    setMainOption((prevOptions) =>
      prevOptions.map((option) =>
        option.id === sportId
          ? {
              ...option,
              tournaments: filterData,
              isIntermediate: filterData?.some(
                (tournament) => tournament.selected,
              ),
              isSport: filterData?.some((tournament) => tournament.selected),
            }
          : option,
      ),
    );
  };

  const toggleSelectAll = (isChecked) => {
    setMainOption((prevOptions) =>
      prevOptions?.map((option) =>
        option?.id === sportId
          ? {
              ...option,
              tournaments: option?.tournaments?.map((tournament) => ({
                ...tournament,
                selected: isChecked,
              })),
              isIntermediate: false,
              isSport: isChecked,
            }
          : option,
      ),
    );
  };

  const handleToggleDropdown = (sportID) => {
    setMainOption((prevOptions) =>
      prevOptions.map((option) =>
        option.id === sportID
          ? { ...option, isExpanded: !option.isExpanded }
          : option,
      ),
    );
    toggleDropdown(sportID, type);
  };

  const allSubOptionChecked =
    type !== "featured_news"
      ? mainOption?.tournaments?.length !== 0 &&
        mainOption?.tournaments?.every((tournament) => tournament.selected)
      : mainOption?.isSport;
  const someSubOptionChecked =
    mainOption?.tournaments?.length !== 0 &&
    mainOption?.tournaments?.some((tournament) => tournament.selected);

  return (
    <Box className="home-custom-dropdown" id={id} ref={dropdownRef}>
      <Box
        className="dropdown-header"
        onClick={(e) => {
          handleToggleDropdown(sportId);
          e.stopPropagation();
        }}
      >
        <Box className="header-details">
          {type !== "featured_news" && (
            <Box>{isOpen ? <UpArrow /> : <DownArrow />}</Box>
          )}
          <Box>
            <Typography className="country-name">{mainOption?.name}</Typography>
          </Box>
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              // checked={mainOption?.isSport}
              checked={
                allSubOptionChecked ||
                (mainOption?.isSport && !mainOption?.isIntermediate)
              }
              icon={<CheckboxUnChecked />}
              checkedIcon={<CheckBoxChecked />}
              indeterminateIcon={<Indeterminent />}
              // indeterminate={mainOption?.isIntermediate}
              indeterminate={
                (!allSubOptionChecked && someSubOptionChecked) ||
                (mainOption?.isSport && mainOption?.isIntermediate)
              }
              onChange={(e) => {
                toggleSelectAll(e.target.checked);
              }}
              onClick={(e) => e.stopPropagation()}
              ref={selectAllRef}
              color="primary"
            />
          }
        />
      </Box>
      {isOpen && type !== "featured_news" && (
        <Box className="dropdown-options" id="scrollable-dropdown-options">
          <InfiniteScroll
            dataLength={mainOption?.tournaments?.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <div className="allsport-loader-center">
                <Loader />
              </div>
            }
            scrollableTarget="scrollable-dropdown-options"
          >
            {mainOption?.tournaments?.map((option) => (
              <Box
                key={option?.id}
                onClick={(e) => {
                  handleOptionClick(mainOption?.tournaments, option?.id);
                  e.stopPropagation();
                }}
                className="options-list"
              >
                <Typography className="state-name">{option?.name}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<FilterPlus />}
                      checkedIcon={<FilterRight />}
                      checked={option?.selected}
                      readOnly
                      color="primary"
                    />
                  }
                />
              </Box>
            ))}
          </InfiniteScroll>
          {!tournamentListLoading && mainOption?.tournaments?.length === 0 && (
            <div className="allsport-loader-center">
              <Typography>No data available</Typography>
            </div>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SportCustomDropdown;
