import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, Tab, Tabs } from "@mui/material";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import Select, { components } from "react-select";
import { useNavigate, useParams } from "react-router";
import { IntlContext } from "src/App";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "../../../../../components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import SoccerCard from "../soccerCard";
import { matchesObject } from "src/helpers/jsondata";

const summaryTabData = [
  {
    id: 1,
    name: "Fixtures"
  },
  {
    id: 2,
    name: "Results"
  }
];

const statsData = [
  { value: 0, label: "Leagues" },
  { value: 1, label: "Teams" },
  { value: 2, label: "Players" },
  { value: 3, label: "Coaches" },
  { value: 4, label: "Venues" }
];

const TeamMatchesPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [tabvalue, setTabValue] = useState(1);
  const [matchData, setMatchData] = useState([]);
  const [matchLoading, setMatchLoading] = useState(false);
  const [matchCount, setMatchCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [tournamentData, setTournamentData] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const rowPerPage = 20;

  const handleTabChange = (event, value) => {
    setTabValue(value);
    setSelectedTournament(null);
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectIndicator />
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    fetchMatchData(0, null);
    fetchTournamentDropDownData();
  }, [params, tabvalue]);

  const fetchMatchData = async (offset, id) => {
    setMatchLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/soccer/event/team/${params?.teamId}?type=${
          tabvalue === 1 ? "fixtures" : "result"
        }&limit=${rowPerPage}&offset=${offset}&tournamentId=${id ? id : ""}`
      );
      if (status === 200) {
        let count = data?.result?.count / 20;
        setMatchCount(Math.ceil(count));
        setMatchData(data?.result?.result);
        setMatchLoading(false);
        setSelectedTournament(id);
      } else {
        setMatchLoading(false);
      }
    } catch (err) {
      setMatchLoading(false);
    }
  };

  const handleOnScrollBottomMatch = async (offset, id) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/soccer/event/team/${params?.teamId}?type=${
          tabvalue === 1 ? "fixtures" : "result"
        }&limit=${rowPerPage}&offset=${offset}&tournamentId=${id ? id : ""}`
      );
      if (status === 200) {
        setOffset(offset);
        setMatchData([...matchData, ...data?.result?.result]);
      } else {
      }
    } catch (err) {}
  };

  const fetchTournamentDropDownData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/soccer/tournament/dropdowns?teamId=${params?.teamId}`
      );
      if (status === 200) {
        let response = data?.result?.result;
        if (response?.length > 0) {
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const sortedData = newdata?.sort((a, b) => {
            return a?.label.localeCompare(b?.label);
          });
          let alldatas = sortedData?.unshift({
            label: "All Tournament",
            value: 0
          });
          setTournamentData(sortedData);
        } else setTournamentData([]);
      } else {
      }
    } catch (err) {}
  };

  return (<>
    <Box className="match-wrap">
      <Tabs
        value={tabvalue}
        variant="scrollable"
        scrollButtons={false}
        indicatorColor="primary"
        textColor="primary"
        className="soccer-details-tab"
      >
        {summaryTabData?.map((item, index) => {
          return (
            <Box key={index}>
              <Tab
                label={
                  <Box className="d-flex align-center match-tab">
                    <span>{item?.name}</span>
                  </Box>
                }
                value={item?.id}
                className={item?.id === tabvalue ? "active " : ""}
                onChange={(event, newValue) =>
                  handleTabChange(event, item?.id)
                }
              />
            </Box>
          );
        })}
      </Tabs>
      {tournamentData?.length > 0 ? (
        <Box className="team-tournament-wrap pt-15">
          <Select
            className="React tournament-select"
            value={
              selectedTournament &&
              tournamentData?.find(item => {
                return item?.value === selectedTournament;
              })
            }
            onChange={e => {
              setSelectedTournament(e?.value == 0 ? null : e?.value);
              setMatchCount(0);
              setOffset(0);
              fetchMatchData(0, e?.value == 0 ? null : e?.value);
            }}
            options={tournamentData}
            classNamePrefix="select"
            placeholder="All Competitions"
            isSearchable={true}
            components={{ DropdownIndicator }}
          />
        </Box>
      ) : (
        <></>
      )}
      {/* )} */}
      {/* <Box className="mt-18">
        {matchesObject?.map((item, index) => {
          return (
            <>
              <Box className="d-flex align-center tournament-name-wrap mt-18">
                <Box className="tournament-img-wrap">
                  <img src={item?.Img} alt="img" />
                </Box>
                <Box className="tournament-name-wrap">
                  <Typography className="tournament-name">
                    {item?.name}
                  </Typography>
                  <Typography className="tournament-country">
                    {item?.country}
                  </Typography>
                </Box>
              </Box>
              {item?.data?.map(obj => {
                return (
                  <>
                    <Box className="mt-18">
                      <SoccerCard theme="light-theme" />
                    </Box>
                  </>
                );
              })}
            </>
          );
        })}
      </Box> */}

      {tabvalue === 1 ? (
        matchLoading ? (
          <div className="allsport-loader-center mt-18">
            <Loader />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={matchData?.length}
            next={() =>
              handleOnScrollBottomMatch(offset + 20, selectedTournament)
            }
            hasMore={
              matchCount !== 0 && matchCount !== Math.ceil(offset / 20 + 1)
            }
            loader={
              <div className="allsport-loader-center ">
                <Loader />
              </div>
            }
          >
            <Box className="mt-18">
              {matchData?.length > 0 ? (
                matchData?.map((data, index) => {
                  return (
                    <>
                      <Box className="mt-18 plr-5">
                        <SoccerCard
                          theme="light-theme"
                          type={
                            data?.ScoreBoard?.Epr == 0
                              ? "upcoming"
                              : data?.ScoreBoard?.Epr == 2
                              ? "past"
                              : data?.ScoreBoard?.Epr == 1
                              ? "live"
                              : "upcoming"
                          }
                          cardData={data}
                          index={index}
                        />
                      </Box>
                    </>
                  );
                })
              ) : (
                <>
                  <Box className="no-match no-match-boxshadow">
                    {localesData?.NO_DATA?.NO_FIXTURES}
                  </Box>
                  {/* <NoDataComp /> */}
                </>
              )}
            </Box>
          </InfiniteScroll>
        )
      ) : matchLoading ? (
        <div className="allsport-loader-center mt-18">
          <Loader />
        </div>
      ) : (
        <InfiniteScroll
          dataLength={matchData?.length}
          next={() =>
            handleOnScrollBottomMatch(offset + 20, selectedTournament)
          }
          hasMore={
            matchCount !== 0 && matchCount !== Math.ceil(offset / 20 + 1)
          }
          loader={
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          }
        >
          <Box className="mt-18">
            {matchData?.length > 0 ? (
              matchData?.map((data, index) => {
                return (
                  <>
                    <Box className="mt-18 plr-5">
                      <SoccerCard
                        theme="light-theme"
                        type={
                          data?.ScoreBoard?.Epr == 0
                            ? "upcoming"
                            : data?.ScoreBoard?.Epr == 2
                            ? "past"
                            : data?.ScoreBoard?.Epr == 1
                            ? "live"
                            : "upcoming"
                        }
                        cardData={data}
                        index={index}
                      />
                    </Box>
                  </>
                );
              })
            ) : (
              <>
                <Box className="no-match no-match-boxshadow">
                  {" "}
                  {localesData?.NO_DATA?.NO_RESULTS}
                </Box>
                {/* <NoDataComp /> */}
              </>
            )}
          </Box>
        </InfiniteScroll>
      )}
    </Box>
  </>);
};

export default TeamMatchesPage;
