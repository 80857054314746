export const soccerTable = {
  TEAM: [
    {
      rnk: 1,
      Tid: "3340",
      win: 13,
      winn: "13",
      wot: 0,
      Tnm: "Liverpool",
      lst: 1,
      lstn: "1",
      lreg: 1,
      lot: 0,
      lap: 0,
      drw: 6,
      drwn: "6",
      gf: 43,
      ga: 18,
      gd: 25,
      ptsn: "45",
      phr: [20],
      Ipr: 0,
      Img: "enet/8650.png",
      pts: 45,
      pld: 20,
      name: "Team"
    },
    {
      rnk: 2,
      Tid: "3863",
      win: 13,
      winn: "13",
      wot: 0,
      Tnm: "Aston Villa",
      lst: 4,
      lstn: "4",
      lreg: 4,
      lot: 0,
      lap: 0,
      drw: 3,
      drwn: "3",
      gf: 43,
      ga: 27,
      gd: 16,
      ptsn: "42",
      phr: [20],
      Ipr: 0,
      Img: "enet/10252.png",
      pts: 42,
      pld: 20,
      name: "Team"
    },
    {
      rnk: 3,
      Tid: "12633",
      win: 12,
      winn: "12",
      wot: 0,
      Tnm: "Manchester City",
      lst: 3,
      lstn: "3",
      lreg: 3,
      lot: 0,
      lap: 0,
      drw: 4,
      drwn: "4",
      gf: 45,
      ga: 21,
      gd: 24,
      ptsn: "40",
      phr: [20],
      Ipr: 0,
      Img: "enet/8456.png",
      pts: 40,
      pld: 19,
      name: "Team"
    },
    {
      rnk: 4,
      Tid: "2773",
      win: 12,
      winn: "12",
      wot: 0,
      Tnm: "Arsenal",
      lst: 4,
      lstn: "4",
      lreg: 4,
      lot: 0,
      lap: 0,
      drw: 4,
      drwn: "4",
      gf: 37,
      ga: 20,
      gd: 17,
      ptsn: "40",
      phr: [20],
      Ipr: 0,
      Img: "enet/9825.png",
      pts: 40,
      pld: 20,
      name: "Team"
    },
    {
      rnk: 5,
      Tid: "3339",
      win: 12,
      winn: "12",
      wot: 0,
      Tnm: "Tottenham Hotspur",
      lst: 5,
      lstn: "5",
      lreg: 5,
      lot: 0,
      lap: 0,
      drw: 3,
      drwn: "3",
      gf: 42,
      ga: 29,
      gd: 13,
      ptsn: "39",
      phr: [25],
      Ipr: 0,
      Img: "enet/8586.png",
      pts: 39,
      pld: 20,
      name: "Team"
    },
    {
      rnk: 6,
      Tid: "252",
      win: 10,
      winn: "10",
      wot: 0,
      Tnm: "West Ham United",
      lst: 6,
      lstn: "6",
      lreg: 6,
      lot: 0,
      lap: 0,
      drw: 4,
      drwn: "4",
      gf: 33,
      ga: 30,
      gd: 3,
      ptsn: "34",
      Ipr: 0,
      Img: "enet/8654.png",
      pts: 34,
      pld: 20,
      name: "Team"
    },
    {
      rnk: 7,
      Tid: "2918",
      win: 8,
      winn: "8",
      wot: 0,
      Tnm: "Brighton",
      lst: 5,
      lstn: "5",
      lreg: 5,
      lot: 0,
      lap: 0,
      drw: 7,
      drwn: "7",
      gf: 38,
      ga: 33,
      gd: 5,
      ptsn: "31",
      Ipr: 0,
      Img: "enet/10204.png",
      pts: 31,
      pld: 20,
      name: "Team"
    },
    {
      rnk: 8,
      Tid: "2810",
      win: 10,
      winn: "10",
      wot: 0,
      Tnm: "Manchester United",
      lst: 9,
      lstn: "9",
      lreg: 9,
      lot: 0,
      lap: 0,
      drw: 1,
      drwn: "1",
      gf: 22,
      ga: 27,
      gd: -5,
      ptsn: "31",
      Ipr: 0,
      Img: "enet/10260.png",
      pts: 31,
      pld: 20,
      name: "Team"
    },
    {
      rnk: 9,
      Tid: "2986",
      win: 9,
      winn: "9",
      wot: 0,
      Tnm: "Newcastle United",
      lst: 9,
      lstn: "9",
      lreg: 9,
      lot: 0,
      lap: 0,
      drw: 2,
      drwn: "2",
      gf: 39,
      ga: 29,
      gd: 10,
      ptsn: "29",
      Ipr: 0,
      Img: "enet/10261.png",
      pts: 29,
      pld: 20,
      name: "Team"
    },
    {
      rnk: 10,
      Tid: "2881",
      win: 8,
      winn: "8",
      wot: 0,
      Tnm: "Chelsea",
      lst: 8,
      lstn: "8",
      lreg: 8,
      lot: 0,
      lap: 0,
      drw: 4,
      drwn: "4",
      gf: 34,
      ga: 31,
      gd: 3,
      ptsn: "28",
      Ipr: 0,
      Img: "enet/8455.png",
      pts: 28,
      pld: 20,
      name: "Team"
    },
    {
      rnk: 11,
      Tid: "2985",
      win: 8,
      winn: "8",
      wot: 0,
      Tnm: "Wolves",
      lst: 8,
      lstn: "8",
      lreg: 8,
      lot: 0,
      lap: 0,
      drw: 4,
      drwn: "4",
      gf: 30,
      ga: 31,
      gd: -1,
      ptsn: "28",
      Ipr: 0,
      Img: "enet/8602.png",
      pts: 28,
      pld: 20,
      name: "Team"
    },
    {
      rnk: 12,
      Tid: "3928",
      win: 7,
      winn: "7",
      wot: 0,
      Tnm: "Bournemouth",
      lst: 8,
      lstn: "8",
      lreg: 8,
      lot: 0,
      lap: 0,
      drw: 4,
      drwn: "4",
      gf: 28,
      ga: 35,
      gd: -7,
      ptsn: "25",
      Ipr: 0,
      Img: "enet/8678.png",
      pts: 25,
      pld: 19,
      name: "Team"
    },
    {
      rnk: 13,
      Tid: "2872",
      win: 7,
      winn: "7",
      wot: 0,
      Tnm: "Fulham",
      lst: 10,
      lstn: "10",
      lreg: 10,
      lot: 0,
      lap: 0,
      drw: 3,
      drwn: "3",
      gf: 28,
      ga: 35,
      gd: -7,
      ptsn: "24",
      Ipr: 0,
      Img: "enet/9879.png",
      pts: 24,
      pld: 20,
      name: "Team"
    },
    {
      rnk: 14,
      Tid: "2772",
      win: 5,
      winn: "5",
      wot: 0,
      Tnm: "Crystal Palace",
      lst: 9,
      lstn: "9",
      lreg: 9,
      lot: 0,
      lap: 0,
      drw: 6,
      drwn: "6",
      gf: 22,
      ga: 29,
      gd: -7,
      ptsn: "21",
      Ipr: 0,
      Img: "enet/9826.png",
      pts: 21,
      pld: 20,
      name: "Team"
    },
    {
      rnk: 15,
      Tid: "3060",
      win: 5,
      winn: "5",
      wot: 0,
      Tnm: "Nottingham Forest",
      lst: 10,
      lstn: "10",
      lreg: 10,
      lot: 0,
      lap: 0,
      drw: 5,
      drwn: "5",
      gf: 24,
      ga: 35,
      gd: -11,
      ptsn: "20",
      Ipr: 0,
      Img: "enet/10203.png",
      pts: 20,
      pld: 20,
      name: "Team"
    },
    {
      rnk: 16,
      Tid: "2865",
      win: 5,
      winn: "5",
      wot: 0,
      Tnm: "Brentford",
      lst: 10,
      lstn: "10",
      lreg: 10,
      lot: 0,
      lap: 0,
      drw: 4,
      drwn: "4",
      gf: 26,
      ga: 31,
      gd: -5,
      ptsn: "19",
      Ipr: 0,
      Img: "enet/9937.png",
      pts: 19,
      pld: 19,
      name: "Team"
    },
    {
      rnk: 17,
      Tid: "2917",
      win: 8,
      winn: "8",
      wot: 0,
      Tnm: "Everton",
      lst: 10,
      lstn: "10",
      lreg: 10,
      lot: 0,
      lap: 0,
      drw: 2,
      drwn: "2",
      gf: 24,
      ga: 28,
      gd: -4,
      ptsn: "16",
      Ipr: 0,
      Img: "enet/8668.png",
      pts: 16,
      pld: 20,
      ptsXn: "-10",
      name: "Team"
    },
    {
      rnk: 18,
      Tid: "2857",
      win: 4,
      winn: "4",
      wot: 0,
      Tnm: "Luton Town",
      lst: 12,
      lstn: "12",
      lreg: 12,
      lot: 0,
      lap: 0,
      drw: 3,
      drwn: "3",
      gf: 23,
      ga: 37,
      gd: -14,
      ptsn: "15",
      phr: [15],
      Ipr: 0,
      Img: "enet/8346.png",
      pts: 15,
      pld: 19,
      name: "Team"
    },
    {
      rnk: 19,
      Tid: "2882",
      win: 3,
      winn: "3",
      wot: 0,
      Tnm: "Burnley",
      lst: 15,
      lstn: "15",
      lreg: 15,
      lot: 0,
      lap: 0,
      drw: 2,
      drwn: "2",
      gf: 20,
      ga: 41,
      gd: -21,
      ptsn: "11",
      phr: [15],
      Ipr: 0,
      Img: "enet/8191.png",
      pts: 11,
      pld: 20,
      name: "Team"
    },
    {
      rnk: 20,
      Tid: "251",
      win: 2,
      winn: "2",
      wot: 0,
      Tnm: "Sheffield United",
      lst: 15,
      lstn: "15",
      lreg: 15,
      lot: 0,
      lap: 0,
      drw: 3,
      drwn: "3",
      gf: 15,
      ga: 49,
      gd: -34,
      ptsn: "9",
      phr: [15],
      Ipr: 0,
      Img: "enet/8657.png",
      pts: 9,
      pld: 20,
      name: "Team"
    }
  ]
};

export const eventData = {
  incs: {
    football1: {
      "4": [
        {
          HOME: [
            {
              name: "Roman Yaremchuk",
              shortName: "R. Yaremchuk",
              time: "4'",
              id: "21371",
              score: {
                home: "1",
                away: "0"
              },
              type: "FootballGoal",
              assist: [
                {
                  name: "Jose Gaya",
                  shortName: "J. Gaya"
                }
              ]
            }
          ],
          AWAY: []
        }
      ],
      "27": [
        {
          HOME: [
            {
              name: "Pepelu",
              shortName: "Pepelu",
              time: "27'",
              id: "19060",
              score: {
                home: "2",
                away: "0"
              },
              type: "FootballGoalPen"
            }
          ],
          AWAY: []
        }
      ],
      "37": [
        {
          HOME: [],
          AWAY: [
            {
              name: "Eric Bailly",
              shortName: "E. Bailly",
              time: "37'",
              id: "27775",
              type: "FootballYellowCard"
            }
          ]
        }
      ]
    },
    football2: {
      "49": [
        {
          HOME: [],
          AWAY: [
            {
              name: "Dani Parejo",
              shortName: "D. Parejo",
              time: "49'",
              id: "18501",
              type: "FootballYellowCard"
            }
          ]
        }
      ],
      "56": [
        {
          HOME: [],
          AWAY: [
            {
              name: "Etienne Capoue",
              shortName: "E. Capoue",
              time: "56'",
              id: "18499",
              type: "FootballYellowCard"
            }
          ]
        }
      ],
      "57": [
        {
          HOME: [
            {
              name: "Pepelu",
              shortName: "Pepelu",
              time: "57'",
              id: "19060",
              score: {
                home: "3",
                away: "0"
              },
              type: "FootballGoalPen"
            }
          ],
          AWAY: []
        }
      ],
      "63": [
        {
          AWAY: [],
          HOME: [
            {
              name: "Jose Gaya",
              shortName: "J. Gaya",
              time: "63'",
              id: "18722",
              type: "FootballYellowCard"
            }
          ]
        }
      ],
      "73": [
        {
          AWAY: [
            {
              name: "Gerard Moreno",
              shortName: "G. Moreno",
              time: "73'",
              id: "18509",
              score: {
                home: "3",
                away: "1"
              },
              type: "FootballGoal",
              assist: [
                {
                  name: "Ilias Akhomach",
                  shortName: "I. Akhomach"
                }
              ]
            }
          ],
          HOME: []
        }
      ],
      "74": [
        {
          AWAY: [],
          HOME: [
            {
              name: "Diego Lopez",
              shortName: "D. Lopez",
              time: "74'",
              id: "18742",
              type: "FootballYellowCard"
            }
          ]
        }
      ],
      "80": [
        {
          HOME: [],
          AWAY: [
            {
              name: "Ramon Terrats",
              shortName: "R. Terrats",
              time: "80'",
              id: "12119",
              type: "FootballYellowCard"
            }
          ]
        }
      ],
      "87": [
        {
          HOME: [],
          AWAY: [
            {
              name: "Marcelino Garcia",
              shortName: "M. Garcia",
              time: "87'",
              id: "18653",
              type: "FootballYellowCard"
            }
          ]
        }
      ],
      "90+2": [
        {
          HOME: [],
          AWAY: [
            {
              name: "Jorge Cuenca",
              shortName: "J. Cuenca",
              time: "90 + 2'",
              id: "19236",
              type: "FootballYellowCard"
            }
          ]
        }
      ]
    }
  },
  hasAssists: true
};

export const commentary = [
  {
    time: "90+5'",
    text: "Valencia with a deserved victory after a fine display"
  },
  {
    time: "90+5'",
    text: "That's it! The referee blows the final whistle"
  },
  {
    time: "90+5'",
    text: "Ball possession: Valencia: 45%, Villarreal: 55%."
  },
  {
    time: "90+5'",
    text: "Valencia take a throw-in in the opponent's half of the field."
  },
  {
    time: "90+4'",
    text: "Diego Lopez from Valencia takes a short corner kick from the right."
  },
  {
    time: "90+4'",
    text:
      "Pepelu sends a direct free kick on target, but Filip Joergensen has it under control"
  },
  {
    time: "90+2'",
    incident: "FootballYellowCard",
    text:
      "Jorge Cuenca pulls a player from the opposite team by the shirt and is booked."
  },
  {
    time: "90+2'",
    text:
      "Jorge Cuenca from Villarreal goes a bit too far there as the pulls down Javier Guerra"
  },
  {
    time: "90+2'",
    text: "Valencia with a potentially dangerous attack."
  },
  {
    time: "90+2'",
    text:
      "Good effort by Ben Brereton Diaz as he directs a shot on target, but the keeper saves it"
  },
  {
    time: "90+2'",
    text: "Daniel Parejo creates a scoring opportunity for his teammate"
  },
  {
    time: "90+2'",
    text: "Villarreal are trying to create something here."
  },
  {
    time: "90+2'",
    text:
      "Safe hands from Giorgi Mamardashvili as he comes out and claims the ball"
  },
  {
    time: "90+1'",
    text:
      "The referee signals a free kick as Alberto Mari from Valencia trips Daniel Parejo"
  },
  {
    time: "90+1'",
    text: "The fourth official shows there are 4 minute(s) of time to be added."
  },
  {
    time: "90+1'",
    text: "Villarreal take a throw-in in their own half of the field."
  },
  {
    time: "90'",
    text: "Ball possession: Valencia: 45%, Villarreal: 55%."
  },
  {
    time: "90'",
    text: "Goal kick for Villarreal."
  },
  {
    time: "90'",
    text:
      "Jesus Vazquez fails to find the target with a shot from outside the box"
  },
  {
    time: "90'",
    text:
      "Francis Coquelin from Villarreal intercepts a cross aimed towards the box."
  },
  {
    time: "90'",
    text: "Valencia are trying to create something here."
  },
  {
    time: "89'",
    text:
      "Pepelu from Valencia is a contender for Man of the Match after a great performance today"
  },
  {
    time: "89'",
    text: "Villarreal take a throw-in in their own half of the field."
  },
  {
    time: "89'",
    text:
      "Eric Bailly from Villarreal intercepts a cross aimed towards the box."
  },
  {
    time: "89'",
    text: "A player from Valencia takes a long throw into the opponent's box."
  },
  {
    time: "89'",
    text: "Valencia take a throw-in in the opponent's half of the field."
  },
  {
    time: "88'",
    text: "Valencia take a throw-in in their own half of the field."
  },
  {
    time: "87'",
    incident: "FootballYellowCard",
    text:
      "The referee shows Marcelino Garcia the yellow card for unsportsmanlike conduct."
  },
  {
    time: "87'",
    text: "Safe hands from Filip Joergensen as he comes out and claims the ball"
  },
  {
    time: "86'",
    text:
      "Fran Perez is leaving the field to be replaced by Yarek Gasiorowski in a tactical substitution."
  },
  {
    time: "86'",
    text:
      "Hugo Duro is leaving the field to be replaced by Alberto Mari in a tactical substitution."
  },
  {
    time: "85'",
    text:
      "The referee signals a free kick as Ilias Akhomach from Villarreal trips Javier Guerra"
  },
  {
    time: "85'",
    text: "Villarreal take a throw-in in their own half of the field."
  },
  {
    time: "85'",
    text: "Ball possession: Valencia: 46%, Villarreal: 54%."
  },
  {
    time: "84'",
    text: "Valencia take a throw-in in their own half of the field."
  },
  {
    time: "84'",
    text:
      "Daniel Parejo swings in the ball from a corner on the left, but it is nowhere near a teammate."
  },
  {
    time: "83'",
    text: "Dimitri Foulquier relieves the pressure with a clearance"
  },
  {
    time: "83'",
    text: "Villarreal are trying to create something here."
  },
  {
    time: "82'",
    text: "Valencia take a throw-in in their own half of the field."
  },
  {
    time: "82'",
    text: "Jesus Vazquez relieves the pressure with a clearance"
  },
  {
    time: "82'",
    text: "Pepelu from Valencia intercepts a cross aimed towards the box."
  },
  {
    time: "81'",
    text:
      "The referee signals a free kick as Hugo Guillamon from Valencia trips Daniel Parejo"
  },
  {
    time: "81'",
    text: "Villarreal are trying to create something here."
  },
  {
    time: "81'",
    text:
      "Fran Perez shoots from outside the box, but Filip Joergensen has it under control"
  },
  {
    time: "81'",
    text: "Valencia are trying to create something here."
  },
  {
    time: "80'",
    incident: "FootballYellowCard",
    text:
      "After a tactical foul, Ramon Terrats sees no other way than stopping the counter and receives a yellow card."
  },
  {
    time: "80'",
    text:
      "Ramon Terrats from Villarreal goes a bit too far there as the pulls down Dimitri Foulquier"
  },
  {
    time: "80'",
    text: "Villarreal take a throw-in in the opponent's half of the field."
  },
  {
    time: "80'",
    text: "Ball possession: Valencia: 48%, Villarreal: 52%."
  },
  {
    time: "79'",
    text: "Valencia take a throw-in in the opponent's half of the field."
  },
  {
    time: "79'",
    text: "Eric Bailly wins an air challenge against Hugo Duro"
  },
  {
    time: "78'",
    text:
      "Adria Altimira is leaving the field to be replaced by Kiko in a tactical substitution."
  },
  {
    time: "78'",
    text:
      "Alberto Moreno is leaving the field to be replaced by Manuel Trigueros in a tactical substitution."
  },
  {
    time: "78'",
    text:
      "Jose Gaya is leaving the field to be replaced by Jesus Vazquez in a tactical substitution."
  },
  {
    time: "78'",
    text: "Gerard Moreno from Villarreal is ruled offside."
  },
  {
    time: "77'",
    text: "Alberto Moreno makes the tackle and wins possession for his team"
  },
  {
    time: "77'",
    text: "Gabriel Paulista relieves the pressure with a clearance"
  },
  {
    time: "76'",
    text:
      "Safe hands from Giorgi Mamardashvili as he comes out and claims the ball"
  },
  {
    time: "76'",
    text:
      "The referee signals a free kick as Javier Guerra from Valencia trips Ilias Akhomach"
  },
  {
    time: "75'",
    text: "Ball possession: Valencia: 50%, Villarreal: 50%."
  },
  {
    time: "75'",
    text: "Valencia take a throw-in in the opponent's half of the field."
  },
  {
    time: "74'",
    incident: "FootballYellowCard",
    text:
      "The referee runs back to show Diego Lopez from Valencia a yellow card for the previous foul."
  },
  {
    time: "73'",
    text:
      "Diego Lopez from Valencia concedes a foul on Daniel Parejo but the referee decides against stopping the play and waves play on!"
  },
  {
    time: "73'",
    text:
      "With the goal, Villarreal have reduced their deficit. Will they be capable of pulling something out of the game?"
  },
  {
    time: "73'",
    text: "Ilias Akhomach provided the assist for the goal."
  },
  {
    time: "73'",
    incident: "FootballGoal",
    text: "G O O O A A A L - Gerard Moreno scores with the right foot!"
  },
  {
    time: "73'",
    text: "Ilias Akhomach creates a scoring opportunity for his teammate"
  },
  {
    time: "73'",
    text: "Villarreal with a potentially dangerous attack."
  },
  {
    time: "73'",
    text: "Villarreal are trying to create something here."
  },
  {
    time: "72'",
    text: "Fran Perez is penalised for pushing Jorge Cuenca."
  },
  {
    time: "72'",
    text: "Jorge Cuenca relieves the pressure with a clearance"
  },
  {
    time: "71'",
    text: "Francis Coquelin is penalised for pushing Fran Perez."
  },
  {
    time: "71'",
    text: "Cristhian Mosquera makes the tackle and wins possession for his team"
  },
  {
    time: "71'",
    text: "Villarreal are trying to create something here."
  },
  {
    time: "71'",
    text: "Villarreal take a throw-in in their own half of the field."
  },
  {
    time: "71'",
    text:
      "The match is being played at a slow tempo with neither side having the upper hand"
  },
  {
    time: "70'",
    text: "Valencia take a throw-in in their own half of the field."
  },
  {
    time: "70'",
    text: "Ball possession: Valencia: 50%, Villarreal: 50%."
  },
  {
    time: "70'",
    text:
      "Roman Yaremchuk is leaving the field to be replaced by Javier Guerra in a tactical substitution."
  },
  {
    time: "69'",
    text:
      "Sergi Canos is leaving the field to be replaced by Diego Lopez in a tactical substitution."
  },
  {
    time: "69'",
    text:
      "The referee signals a free kick as Hugo Guillamon from Valencia trips Francis Coquelin"
  },
  {
    time: "69'",
    text: "Eric Bailly makes the tackle and wins possession for his team"
  },
  {
    time: "68'",
    text: "Roman Yaremchuk wins an air challenge against Daniel Parejo"
  },
  {
    time: "68'",
    text: "Goal kick for Valencia."
  },
  {
    time: "66'",
    text:
      "The referee signals a free kick as Ilias Akhomach from Villarreal trips Pepelu"
  },
  {
    time: "65'",
    text: "Valencia take a throw-in in their own half of the field."
  },
  {
    time: "65'",
    text: "Ball possession: Valencia: 51%, Villarreal: 49%."
  },
  {
    time: "65'",
    text: "Villarreal take a throw-in in the opponent's half of the field."
  },
  {
    time: "65'",
    text:
      "Jose Luis Morales is leaving the field to be replaced by Francis Coquelin in a tactical substitution."
  },
  {
    time: "65'",
    text:
      "Etienne Capoue is leaving the field to be replaced by Ben Brereton Diaz in a tactical substitution."
  },
  {
    time: "64'",
    text: "Roman Yaremchuk relieves the pressure with a clearance"
  },
  {
    time: "63'",
    incident: "FootballYellowCard",
    text:
      "After a tactical foul, Jose Gaya sees no other way than stopping the counter and receives a yellow card."
  },
  {
    time: "63'",
    text:
      "The referee signals a free kick as Jose Gaya from Valencia trips Ilias Akhomach"
  },
  {
    time: "63'",
    text: "Goal kick for Valencia."
  },
  {
    time: "62'",
    text: "Villarreal take a throw-in in their own half of the field."
  },
  {
    time: "61'",
    text: "Goal kick for Valencia."
  },
  {
    time: "61'",
    text:
      "Gerard Moreno from Villarreal fails to connect properly on the volley and sends the ball wide of target"
  },
  {
    time: "61'",
    text: "Jose Luis Morales creates a scoring opportunity for his teammate"
  },
  {
    time: "61'",
    text: "Villarreal with a potentially dangerous attack."
  },
  {
    time: "61'",
    text:
      "Cristhian Mosquera from Valencia intercepts a cross aimed towards the box."
  },
  {
    time: "60'",
    text:
      "Cristhian Mosquera from Valencia intercepts a cross aimed towards the box."
  },
  {
    time: "60'",
    text: "Ball possession: Valencia: 52%, Villarreal: 48%."
  },
  {
    time: "60'",
    text: "Villarreal take a throw-in in the opponent's half of the field."
  },
  {
    time: "59'",
    text:
      "Safe hands from Giorgi Mamardashvili as he comes out and claims the ball"
  },
  {
    time: "57'",
    incident: "FootballGoalPen",
    text:
      "G O O O A A A L - Pepelu from Valencia puts it away from the penalty spot with the right foot! Filip Joergensen was close to denying the opportunity."
  },
  {
    time: "56'",
    incident: "FootballYellowCard",
    text:
      "The referee runs back to show Etienne Capoue from Villarreal a yellow card for the previous foul."
  },
  {
    time: "56'",
    text:
      "PENALTY! - Adria Altimira handles the ball in the penalty box! Heavy protests from Adria Altimira but the ref is uninterested."
  },
  {
    time: "56'",
    text:
      "Close! Fran Perez with a great hit from long range that bounces off the post!"
  },
  {
    time: "56'",
    text: "Valencia with a potentially dangerous attack."
  },
  {
    time: "56'",
    text:
      "Etienne Capoue from Villarreal concedes a foul on Pepelu but the referee decides against stopping the play and waves play on!"
  },
  {
    time: "56'",
    text:
      "The referee signals a free kick as Hugo Guillamon from Valencia trips Etienne Capoue"
  },
  {
    time: "55'",
    text: "Villarreal take a throw-in in their own half of the field."
  },
  {
    time: "55'",
    text: "Roman Yaremchuk wins an air challenge against Etienne Capoue"
  },
  {
    time: "55'",
    text: "Ball possession: Valencia: 53%, Villarreal: 47%."
  },
  {
    time: "55'",
    text: "Goal kick for Valencia."
  },
  {
    time: "55'",
    text: "The game is restarted."
  },
  {
    time: "54'",
    text: "Play has been stopped while the referee talks to the players."
  },
  {
    time: "53'",
    text: "Valencia take a throw-in in their own half of the field."
  },
  {
    time: "53'",
    text: "Today's attendance is 44793."
  },
  {
    time: "52'",
    text:
      "Giorgi Mamardashvili from Valencia intercepts a cross aimed towards the box."
  },
  {
    time: "52'",
    text: "Valencia take a throw-in in their own half of the field."
  },
  {
    time: "51'",
    text:
      "Gabriel Paulista from Valencia intercepts a cross aimed towards the box."
  },
  {
    time: "51'",
    text: "Villarreal are in control of the ball."
  },
  {
    time: "51'",
    text: "Valencia are trying to create something here."
  },
  {
    time: "50'",
    text:
      "Daniel Parejo from Villarreal takes a short corner kick from the left."
  },
  {
    time: "50'",
    text: "Ball possession: Valencia: 54%, Villarreal: 46%."
  },
  {
    time: "50'",
    text: "Dimitri Foulquier relieves the pressure with a clearance"
  },
  {
    time: "49'",
    text: "Villarreal are trying to create something here."
  },
  {
    time: "49'",
    incident: "FootballYellowCard",
    text:
      "Daniel Parejo from Villarreal is booked after several commited fouls."
  },
  {
    time: "49'",
    text:
      "The referee signals a free kick as Daniel Parejo from Villarreal trips Hugo Duro"
  },
  {
    time: "48'",
    text: "Dimitri Foulquier makes the tackle and wins possession for his team"
  },
  {
    time: "48'",
    text:
      "Cristhian Mosquera from Valencia intercepts a cross aimed towards the box."
  },
  {
    time: "48'",
    text:
      "Gabriel Paulista from Valencia intercepts a cross aimed towards the box."
  },
  {
    time: "48'",
    text: "Villarreal are trying to create something here."
  },
  {
    time: "47'",
    text:
      "Roman Yaremchuk from Valencia goes a bit too far there as the pulls down Eric Bailly"
  },
  {
    time: "47'",
    text: "Valencia take a throw-in in their own half of the field."
  },
  {
    time: "46'",
    text:
      "Gerard Moreno from Villarreal commits a foul by elbowing Hugo Guillamon"
  },
  {
    time: "46'",
    text: "The second half is underway."
  },
  {
    time: "45+4'",
    text: "Valencia has been the better team in this half"
  },
  {
    time: "45+4'",
    text: "It's over! The referee blows for half time"
  },
  {
    time: "45+4'",
    text: "Ball possession: Valencia: 56%, Villarreal: 44%."
  },
  {
    time: "45+3'",
    text: "Villarreal are trying to create something here."
  },
  {
    time: "45+2'",
    text:
      "VAR CHECK OVER - No further actions have been taken after the VAR check."
  },
  {
    time: "45+1'",
    text:
      "VAR - GOAL! - The referee has stopped the play. VAR check in progress, a potential goal for Villarreal."
  },
  {
    time: "45+1'",
    text: "The fourth official shows there are 3 minute(s) of time to be added."
  },
  {
    time: "45+1'",
    text: "Jose Luis Morales from Villarreal is ruled offside."
  },
  {
    time: "45+1'",
    text: "Villarreal with a potentially dangerous attack."
  },
  {
    time: "45+1'",
    text: "The match resumes with a drop ball."
  },
  {
    time: "45+1'",
    text: "Play has been stopped because there is a player lying on the pitch."
  },
  {
    time: "45'",
    text:
      "Alberto Moreno volleys a shot on target, but Giorgi Mamardashvili is on hand to save it"
  },
  {
    time: "45'",
    text:
      "The cross by Ilias Akhomach from Villarreal successfully finds a teammate in the box."
  },
  {
    time: "45'",
    text: "Ball possession: Valencia: 60%, Villarreal: 40%."
  },
  {
    time: "45'",
    text: "Villarreal are trying to create something here."
  },
  {
    time: "44'",
    text: "Eric Bailly makes the tackle and wins possession for his team"
  },
  {
    time: "43'",
    text: "Valencia are trying to create something here."
  },
  {
    time: "43'",
    text: "Valencia are trying to create something here."
  },
  {
    time: "42'",
    text: "Ramon Terrats relieves the pressure with a clearance"
  },
  {
    time: "42'",
    text: "Valencia take a throw-in in the opponent's half of the field."
  },
  {
    time: "42'",
    text: "Jorge Cuenca relieves the pressure with a clearance"
  },
  {
    time: "42'",
    text: "Valencia start a counter attack."
  },
  {
    time: "41'",
    text:
      "Dimitri Foulquier from Valencia intercepts a cross aimed towards the box."
  },
  {
    time: "41'",
    text:
      "The referee signals a free kick as Pepelu from Valencia trips Daniel Parejo"
  },
  {
    time: "40'",
    text: "Goal kick for Valencia."
  },
  {
    time: "40'",
    text: "Ball possession: Valencia: 64%, Villarreal: 36%."
  },
  {
    time: "40'",
    text: "Eric Bailly from Villarreal sends an effort wide of target"
  },
  {
    time: "40'",
    text: "Daniel Parejo creates a scoring opportunity for his teammate"
  },
  {
    time: "40'",
    text: "Villarreal with a potentially dangerous attack."
  },
  {
    time: "40'",
    text:
      "Roman Yaremchuk from Valencia intercepts a cross aimed towards the box."
  },
  {
    time: "39'",
    text:
      "Daniel Parejo from Villarreal takes a short corner kick from the right."
  },
  {
    time: "39'",
    text: "Gabriel Paulista relieves the pressure with a clearance"
  },
  {
    time: "39'",
    text: "Villarreal with a potentially dangerous attack."
  },
  {
    time: "38'",
    text: "Roman Yaremchuk is penalised for pushing Daniel Parejo."
  },
  {
    time: "38'",
    text: "Ramon Terrats makes the tackle and wins possession for his team"
  },
  {
    time: "37'",
    incident: "FootballYellowCard",
    text:
      "After a tactical foul, Eric Bailly sees no other way than stopping the counter and receives a yellow card."
  },
  {
    time: "37'",
    text: "Eric Bailly is penalised for pushing Hugo Duro."
  },
  {
    time: "36'",
    text: "Goal kick for Valencia."
  },
  {
    time: "36'",
    text:
      "Ilias Akhomach fails to find the target with a shot from outside the box"
  },
  {
    time: "36'",
    text: "Gerard Moreno creates a scoring opportunity for his teammate"
  },
  {
    time: "36'",
    text: "Villarreal are trying to create something here."
  },
  {
    time: "35'",
    text: "Villarreal take a throw-in in their own half of the field."
  },
  {
    time: "35'",
    text: "Valencia are trying to create something here."
  },
  {
    time: "35'",
    text: "Ball possession: Valencia: 64%, Villarreal: 36%."
  },
  {
    time: "35'",
    text: "Hugo Guillamon makes the tackle and wins possession for his team"
  },
  {
    time: "34'",
    text:
      "The referee signals a free kick as Daniel Parejo from Villarreal trips Sergi Canos"
  },
  {
    time: "32'",
    text:
      "Adria Altimira from Villarreal intercepts a cross aimed towards the box."
  },
  {
    time: "32'",
    text: "Valencia are trying to create something here."
  },
  {
    time: "31'",
    text:
      "Good effort by Hugo Duro as he directs a shot on target, but the keeper saves it"
  },
  {
    time: "31'",
    text: "Valencia with a potentially dangerous attack."
  },
  {
    time: "31'",
    text: "Valencia take a throw-in in their own half of the field."
  },
  {
    time: "30'",
    text: "Valencia take a throw-in in their own half of the field."
  },
  {
    time: "30'",
    text: "Ball possession: Valencia: 64%, Villarreal: 36%."
  },
  {
    time: "29'",
    text:
      "Hugo Duro from Valencia goes a bit too far there as the pulls down Daniel Parejo"
  },
  {
    time: "29'",
    text: "Alberto Moreno makes the tackle and wins possession for his team"
  },
  {
    time: "29'",
    text: "Valencia are trying to create something here."
  },
  {
    time: "29'",
    text: "Valencia take a throw-in in their own half of the field."
  },
  {
    time: "27'",
    incident: "FootballGoalPen",
    text:
      "G O O O A A A L - Pepelu from Valencia puts it away from the penalty spot with the right foot! Filip Joergensen chose the wrong side."
  },
  {
    time: "26'",
    text:
      "PENALTY - Adria Altimira from Villarreal concedes a penalty for pushing Hugo Duro."
  },
  {
    time: "26'",
    text: "Valencia are trying to create something here."
  },
  {
    time: "25'",
    text: "Ball possession: Valencia: 61%, Villarreal: 39%."
  },
  {
    time: "25'",
    text: "Valencia are in control of the ball."
  },
  {
    time: "24'",
    text: "Villarreal take a throw-in in their own half of the field."
  },
  {
    time: "24'",
    text:
      "What a game! We are witnessing intense and high quality play from both sides."
  },
  {
    time: "24'",
    text: "Valencia take a throw-in in the opponent's half of the field."
  },
  {
    time: "23'",
    text: "The match resumes with a drop ball."
  },
  {
    time: "23'",
    text:
      "Gabriel Paulista is down injured and receives medical attention on the field."
  },
  {
    time: "23'",
    text: "Play has been stopped because there is a player lying on the pitch."
  },
  {
    time: "23'",
    text:
      "Alberto Moreno volleys a shot on target, but Giorgi Mamardashvili is on hand to save it"
  },
  {
    time: "23'",
    text: "Cristhian Mosquera successfully blocks the shot"
  },
  {
    time: "23'",
    text: "A shot by Gerard Moreno is blocked."
  },
  {
    time: "22'",
    text: "Villarreal with a potentially dangerous attack."
  },
  {
    time: "22'",
    text: "Ramon Terrats makes the tackle and wins possession for his team"
  },
  {
    time: "22'",
    text:
      "The referee signals a free kick as Alberto Moreno from Villarreal trips Pepelu"
  },
  {
    time: "21'",
    text: "Villarreal are trying to create something here."
  },
  {
    time: "21'",
    text: "Goal kick for Villarreal."
  },
  {
    time: "20'",
    text: "Ball possession: Valencia: 64%, Villarreal: 36%."
  },
  {
    time: "20'",
    text: "Villarreal take a throw-in in their own half of the field."
  },
  {
    time: "20'",
    text:
      "Sergi Canos goes for goal, but his effort was never close to hitting the target."
  },
  {
    time: "20'",
    text: "Eric Bailly could cost his team a goal after a defensive blunder."
  },
  {
    time: "20'",
    text: "Valencia with a potentially dangerous attack."
  },
  {
    time: "19'",
    text:
      "Giorgi Mamardashvili from Valencia intercepts a cross aimed towards the box."
  },
  {
    time: "19'",
    text: "Villarreal are trying to create something here."
  },
  {
    time: "18'",
    text: "Valencia take a throw-in in their own half of the field."
  },
  {
    time: "18'",
    text: "Villarreal are trying to create something here."
  },
  {
    time: "18'",
    text: "Villarreal take a throw-in in their own half of the field."
  },
  {
    time: "17'",
    text:
      "The captain is forced to give away his armband, because he is leaving the field."
  },
  {
    time: "17'",
    text:
      "Raul Albiol is coming out with a knock and is replaced by Eric Bailly."
  },
  {
    time: "16'",
    text:
      "The referee signals a free kick as Alberto Moreno from Villarreal trips Dimitri Foulquier"
  },
  {
    time: "15'",
    text: "Ball possession: Valencia: 69%, Villarreal: 31%."
  },
  {
    time: "15'",
    text:
      "Safe hands from Giorgi Mamardashvili as he comes out and claims the ball"
  },
  {
    time: "15'",
    text: "Villarreal are trying to create something here."
  },
  {
    time: "15'",
    text:
      "Hugo Guillamon sends a header towards goal, but Filip Joergensen is there to comfortably save it"
  },
  {
    time: "15'",
    text:
      "The cross by Sergi Canos from Valencia successfully finds a teammate in the box."
  },
  {
    time: "15'",
    text: "Pepelu from Valencia takes a short corner kick from the left."
  },
  {
    time: "14'",
    text: "Raul Albiol relieves the pressure with a clearance"
  },
  {
    time: "14'",
    text:
      "Adria Altimira from Villarreal intercepts a cross aimed towards the box."
  },
  {
    time: "14'",
    text: "Valencia with a potentially dangerous attack."
  },
  {
    time: "13'",
    text: "Raul Albiol from Villarreal is ruled offside."
  },
  {
    time: "12'",
    text:
      "The referee signals a free kick as Jose Gaya from Valencia trips Ilias Akhomach"
  },
  {
    time: "12'",
    text: "Villarreal are trying to create something here."
  },
  {
    time: "12'",
    text: "Ilias Akhomach makes the tackle and wins possession for his team"
  },
  {
    time: "11'",
    text: "Ramon Terrats wins an air challenge against Fran Perez"
  },
  {
    time: "11'",
    text: "Valencia take a throw-in in the opponent's half of the field."
  },
  {
    time: "11'",
    text: "Filip Joergensen relieves the pressure with a clearance"
  },
  {
    time: "10'",
    text:
      "Raul Albiol from Villarreal intercepts a cross aimed towards the box."
  },
  {
    time: "10'",
    text: "Raul Albiol relieves the pressure with a clearance"
  },
  {
    time: "10'",
    text:
      "The cross by Roman Yaremchuk from Valencia successfully finds a teammate in the box."
  },
  {
    time: "10'",
    text: "Ball possession: Valencia: 76%, Villarreal: 24%."
  },
  {
    time: "10'",
    text: "Villarreal are in control of the ball."
  },
  {
    time: "9'",
    text: "Hugo Duro from Valencia is ruled offside."
  },
  {
    time: "9'",
    text: "Pepelu from Valencia takes a short corner kick from the right."
  },
  {
    time: "9'",
    text:
      "Oooh... that was a sitter! Roman Yaremchuk should have scored from that position"
  },
  {
    time: "9'",
    text:
      "Good effort by Roman Yaremchuk as he directs a shot on target, but the keeper saves it"
  },
  {
    time: "9'",
    text: "Etienne Capoue could cost his team a goal after a defensive blunder."
  },
  {
    time: "8'",
    text: "Goal kick for Villarreal."
  },
  {
    time: "8'",
    text: "Valencia are trying to create something here."
  },
  {
    time: "8'",
    text: "Jorge Cuenca from Villarreal commits a foul by elbowing Hugo Duro"
  },
  {
    time: "7'",
    text: "Jose Luis Morales from Villarreal is ruled offside."
  },
  {
    time: "7'",
    text:
      "The referee signals a free kick as Adria Altimira from Villarreal trips Pepelu"
  },
  {
    time: "5'",
    text: "Ball possession: Valencia: 80%, Villarreal: 20%."
  },
  {
    time: "5'",
    text:
      "Valencia managed to score the important first goal. Will they build on that?"
  },
  {
    time: "4'",
    text: "Jose Gaya provided the assist for the goal."
  },
  {
    time: "4'",
    incident: "FootballGoal",
    text: "G O O O A A A L - Roman Yaremchuk scores with the right foot!"
  },
  {
    time: "4'",
    text: "Jose Gaya creates a scoring opportunity for his teammate"
  },
  {
    time: "4'",
    text: "Valencia with a potentially dangerous attack."
  },
  {
    time: "3'",
    text: "Valencia are in control of the ball."
  },
  {
    time: "3'",
    text:
      "Filip Joergensen from Villarreal intercepts a cross aimed towards the box."
  },
  {
    time: "3'",
    text:
      "Jose Gaya swings in the ball from a corner on the left, but it is nowhere near a teammate."
  },
  {
    time: "3'",
    text: "Ilias Akhomach relieves the pressure with a clearance"
  },
  {
    time: "2'",
    text: "Daniel Parejo relieves the pressure with a clearance"
  },
  {
    time: "2'",
    text:
      "Jorge Cuenca from Villarreal intercepts a cross aimed towards the box."
  },
  {
    time: "2'",
    text: "Valencia are trying to create something here."
  },
  {
    time: "2'",
    text:
      "Filip Joergensen from Villarreal intercepts a cross aimed towards the box."
  },
  {
    time: "1'",
    text: "Valencia are trying to create something here."
  },
  {
    time: "1'",
    text: "Valencia take a throw-in in the opponent's half of the field."
  },
  {
    time: "1'",
    text: "Welcome to tonights game where the floodlight surrounds the pitch."
  },
  {
    time: "1'",
    text:
      "The pitch is in good condition today allowing for more technical play"
  },
  {
    time: "1'",
    text: "Valencia kick-off, and the game is underway."
  },
  {
    time: "1'",
    text: "The referee starts the match."
  },
  {
    time: "1'",
    text:
      "Before we get underway, the players and crowd observe a minute's silence."
  },
  {
    time: "",
    text:
      "Welcome to Estadio Mestalla, the match will start in about 5 minutes."
  }
];

export const statistics = {
  shotsOnTarget: [8, 4],
  shotsOffTarget: [3, 3],
  shotsBlocked: [0, 1],
  possession: [45, 55],
  corners: [4, 3],
  offsides: [1, 4],
  fouls: [12, 15],
  throwIns: [21, 13],
  yellowCards: [2, 5],
  yellowRedCards: [0, 0],
  redCards: [0, 0],
  crosses: [10, 11],
  counterAttacks: [1, 0],
  goalkeeperSaves: [3, 5],
  goalKicks: [6, 3],
  treatments: [null, null]
};

export const matchesObject = [
  {
    name: "Super Cup",
    country: "Various",
    Img: "https://lsm-static-prod.livescore.com/medium/enet/8214.png",
    data: [1, 2, 3]
  },
  {
    name: "World Cup",
    country: "Various",
    Img: "https://lsm-static-prod.livescore.com/medium/enet/8371.png",
    data: [4, 5, 6]
  },
  {
    name: "Champions League",
    country: "Various",
    Img: "https://static.livescore.com/i2/fh/spain.jpg",
    data: [7, 8, 9]
  },
  {
    name: "Premier League",
    country: "England",
    Img: "https://static.livescore.com/i2/fh/champions-league.jpg",
    data: [10, 11, 12]
  },
  {
    name: "La Liga",
    country: "Spain",
    Img: "https://static.livescore.com/i2/fh/spain.jpg",
    data: [13, 14, 15]
  }
];

export const playerStatData = [
  {
    statId: "goals",
    statName: "team.stat-goals",
    statSlug: "/en/football/team/sassuolo/3862/stats/14689/goals/",
    players: [
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Domenico-Berardi",
        name: "Domenico Berardi",
        rank: 1,
        statAmount: "9",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Andrea-Pinamonti",
        name: "Andrea Pinamonti",
        rank: 2,
        statAmount: "7",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Kristian-Thorstvedt",
        name: "Kristian Thorstvedt",
        rank: 3,
        statAmount: "3",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Matheus-Henrique",
        name: "Matheus Henrique",
        rank: 4,
        statAmount: "2",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Nedim-Bajrami",
        name: "Nedim Bajrami",
        rank: 5,
        statAmount: "1",
        teamId: "3862",
        teamName: "Sassuolo"
      }
    ]
  },
  {
    statId: "assists",
    statName: "team.stat-assists",
    statSlug: "/en/football/team/sassuolo/3862/stats/14689/assists/",
    players: [
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Jeremy-Toljan",
        name: "Jeremy Toljan",
        rank: 1,
        statAmount: "5",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Armand-Lauriente",
        name: "Armand Lauriente",
        rank: 2,
        statAmount: "4",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Domenico-Berardi",
        name: "Domenico Berardi",
        rank: 3,
        statAmount: "3",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Matias-Vina",
        name: "Matias Vina",
        rank: 3,
        statAmount: "3",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Gregoire-Defrel",
        name: "Gregoire Defrel",
        rank: 5,
        statAmount: "1",
        teamId: "3862",
        teamName: "Sassuolo"
      }
    ]
  },
  {
    statId: "shots-on-target",
    statName: "team.stat-shots-on-target",
    statSlug: "/en/football/team/sassuolo/3862/stats/14689/shots-on-target/",
    players: [
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Domenico-Berardi",
        name: "Domenico Berardi",
        rank: 1,
        statAmount: "18",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Andrea-Pinamonti",
        name: "Andrea Pinamonti",
        rank: 2,
        statAmount: "13",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Kristian-Thorstvedt",
        name: "Kristian Thorstvedt",
        rank: 3,
        statAmount: "8",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Armand-Lauriente",
        name: "Armand Lauriente",
        rank: 4,
        statAmount: "7",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Matheus-Henrique",
        name: "Matheus Henrique",
        rank: 5,
        statAmount: "5",
        teamId: "3862",
        teamName: "Sassuolo"
      }
    ]
  },
  {
    statId: "yellow-cards",
    statName: "team.stat-yellow-cards",
    statSlug: "/en/football/team/sassuolo/3862/stats/14689/yellow-cards/",
    players: [
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Domenico-Berardi",
        name: "Domenico Berardi",
        rank: 1,
        statAmount: "6",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Matheus-Henrique",
        name: "Matheus Henrique",
        rank: 2,
        statAmount: "4",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Daniel-Boloca",
        name: "Daniel Boloca",
        rank: 3,
        statAmount: "3",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Martin-Erlic",
        name: "Martin Erlic",
        rank: 3,
        statAmount: "3",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Marcus-Holmgren-Pedersen",
        name: "Marcus Holmgren Pedersen",
        rank: 3,
        statAmount: "3",
        teamId: "3862",
        teamName: "Sassuolo"
      }
    ]
  },
  {
    statId: "red-cards",
    statName: "team.stat-red-cards",
    statSlug: "/en/football/team/sassuolo/3862/stats/14689/red-cards/",
    players: [
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Daniel-Boloca",
        name: "Daniel Boloca",
        rank: 1,
        statAmount: "1",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Maxime-Lopez",
        name: "Maxime Lopez",
        rank: 1,
        statAmount: "1",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Ruan",
        name: "Ruan",
        rank: 1,
        statAmount: "1",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Nedim-Bajrami",
        name: "Nedim Bajrami",
        rank: 4,
        statAmount: "0",
        teamId: "3862",
        teamName: "Sassuolo"
      },
      {
        teamBadge: {
          high: "https://lsm-static-prod.livescore.com/high/enet/7943.png",
          medium: "https://lsm-static-prod.livescore.com/medium/enet/7943.png"
        },
        playerKey: "player-stat-Domenico-Berardi",
        name: "Domenico Berardi",
        rank: 4,
        statAmount: "0",
        teamId: "3862",
        teamName: "Sassuolo"
      }
    ]
  }
];
