import React from "react";
import Page from "src/components/Page";
import IndividualCompetitionPublicPage from "src/views/component/individualCompetitionPublic";

const IndividualCompetitionPublic = () => {
  return (
    <Page title="My Public Competition">
      <IndividualCompetitionPublicPage />
    </Page>
  );
};

export default IndividualCompetitionPublic;
