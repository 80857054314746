import React from "react";
import Page from "src/components/Page";
import EventsCalendarPage from "src/views/component/EventsCalendar";

function EventsCalendar() {
  return (
    <Page
      title="Events Calendar"
      description="SmartB is a leading online sports news service and an aggregator of odds comparison from the most reliable bookmakers around the globe"
    >
      <EventsCalendarPage />
    </Page>
  );
}

export default EventsCalendar;
