import React, { useState, useEffect, useContext } from "react";
import { Box, Typography } from "@mui/material";
import banner from "../../../assets/images/banner/smart-b-banner-primary.webp";
import AdvertisingBannner from "../../component/advertisingBanner";
import "./policies.scss";
import OurPartner from "../../component/ourPartners";
import { IntlContext } from "src/App";
import ScriptLoader from "src/views/component/scriptLoader/ScriptLoader";
import { Helmet } from "react-helmet";
import { Config } from "src/helpers/context";

const TermsAndConditions = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  const scriptCode = `
  rdt('track', 'ViewContent', {
    "products": [
      {
        "id": "product id 1",
        "name": "product name 1",
        "category": "product category 1"
      },
      // additional products can be added here
    ]
  });
`;
  return (
    <>
      <Helmet>
        <title>SmartB | Check Our Detailed Terms & Conditions</title>
        <meta
          name="description"
          content="We hope you'll enjoy your visit to SmartB. Here are a few things you should know about the content and services available to you on or across our site"
        />
      </Helmet>
      <ScriptLoader scriptCode={scriptCode} />
      <Box className="content-wrap">
        {screenWidth > 479 ? "" : <AdvertisingBannner src={banner} />}
        <Box className="sport-wrap">
          <Box className="terms-wrap">
            <Typography variant="h1">
              <span className="title-orange">
                {localesData?.TERMS_AND_CONDITIONS?.TITLE}
              </span>
            </Typography>
            {/* <Typography className="created-date">
              <span className="title-skyblue">Created on: 1st August 2022</span>
            </Typography>
            <Typography className="modified-on">
              <span className="title-skyblue">Modified on:</span>
            </Typography> */}
            <Typography variant="body2">
              {localesData?.TERMS_AND_CONDITIONS?.TERMS_AND_CONDITIONS_PARA_1}
            </Typography>
            <Typography variant="body2">
              {localesData?.TERMS_AND_CONDITIONS?.TERMS_AND_CONDITIONS_PARA_2}
            </Typography>
            <Typography variant="h5" className="mb-10">
              {localesData?.TERMS_AND_CONDITIONS?.TERMS_AND_CONDITIONS_SMARTB}
            </Typography>
            <ul className="terms-ul">
              <li>
                {
                  localesData?.TERMS_AND_CONDITIONS
                    ?.TERMS_AND_CONDITIONS_SMARTB_LIST_1
                }
              </li>
              <li>
                {
                  localesData?.TERMS_AND_CONDITIONS
                    ?.TERMS_AND_CONDITIONS_SMARTB_LIST_2
                }
              </li>
              <li>
                {
                  localesData?.TERMS_AND_CONDITIONS
                    ?.TERMS_AND_CONDITIONS_SMARTB_LIST_3
                }
              </li>
            </ul>
            <Typography variant="h5" className="mb-10">
              {localesData?.TERMS_AND_CONDITIONS?.USE_OF_SMARTB_SERVICES}
            </Typography>
            <ul className="terms-ul">
              <li>
                {
                  localesData?.TERMS_AND_CONDITIONS
                    ?.USE_OF_SMARTB_SERVICES_LIST_1
                }
              </li>
              <li>
                {
                  localesData?.TERMS_AND_CONDITIONS
                    ?.USE_OF_SMARTB_SERVICES_LIST_2
                }
              </li>
              <li>
                {
                  localesData?.TERMS_AND_CONDITIONS
                    ?.USE_OF_SMARTB_SERVICES_LIST_3
                }
              </li>
              <li>
                {
                  localesData?.TERMS_AND_CONDITIONS
                    ?.USE_OF_SMARTB_SERVICES_LIST_4
                }
              </li>
            </ul>
            <Typography variant="h5" className="mb-10">
              {
                localesData?.TERMS_AND_CONDITIONS
                  ?.CONTENT_AND_FUNCTIONALITY_OF_THE_SITE
              }
            </Typography>
            <ul className="terms-ul">
              <li>
                {
                  localesData?.TERMS_AND_CONDITIONS
                    ?.CONTENT_AND_FUNCTIONALITY_OF_THE_SITE_LIST_1
                }
              </li>
              <li>
                {
                  localesData?.TERMS_AND_CONDITIONS
                    ?.CONTENT_AND_FUNCTIONALITY_OF_THE_SITE_LIST_2
                }
              </li>
              <li>
                {
                  localesData?.TERMS_AND_CONDITIONS
                    ?.CONTENT_AND_FUNCTIONALITY_OF_THE_SITE_LIST_3
                }
              </li>
              <li>
                {
                  localesData?.TERMS_AND_CONDITIONS
                    ?.CONTENT_AND_FUNCTIONALITY_OF_THE_SITE_LIST_4
                }
              </li>
            </ul>
            <Typography variant="h5" className="mb-10">
              {localesData?.TERMS_AND_CONDITIONS?.INTELLECTUAL_PROPERTY}
            </Typography>
            <ul className="terms-ul">
              <li>
                {
                  localesData?.TERMS_AND_CONDITIONS
                    ?.INTELLECTUAL_PROPERTY_LIST_1
                }
              </li>
              <li>
                {
                  localesData?.TERMS_AND_CONDITIONS
                    ?.INTELLECTUAL_PROPERTY_LIST_2
                }
              </li>
            </ul>
            <Typography variant="h5" className="mb-10">
              {localesData?.TERMS_AND_CONDITIONS?.NO_ADVICE}
            </Typography>
            <ul>
              <li>{localesData?.TERMS_AND_CONDITIONS?.NO_ADVICE_LIST_1}</li>
            </ul>
            <Typography variant="h5" className="mb-10">
              {localesData?.TERMS_AND_CONDITIONS?.LIABILITY_INDEMNITY}
            </Typography>
            <ul className="terms-ul">
              <li>
                {localesData?.TERMS_AND_CONDITIONS?.LIABILITY_INDEMNITY_LIST_1}
              </li>
              <li>
                {localesData?.TERMS_AND_CONDITIONS?.LIABILITY_INDEMNITY_LIST_2}
                <ul>
                  <li>
                    {
                      localesData?.TERMS_AND_CONDITIONS
                        ?.LIABILITY_INDEMNITY_LIST_2_SUB_1
                    }
                  </li>
                  <li>
                    {
                      localesData?.TERMS_AND_CONDITIONS
                        ?.LIABILITY_INDEMNITY_LIST_2_SUB_2
                    }
                  </li>
                  <li>
                    {
                      localesData?.TERMS_AND_CONDITIONS
                        ?.LIABILITY_INDEMNITY_LIST_2_SUB_3
                    }
                  </li>
                  <li>
                    {
                      localesData?.TERMS_AND_CONDITIONS
                        ?.LIABILITY_INDEMNITY_LIST_2_SUB_4
                    }
                  </li>
                  <li>
                    {
                      localesData?.TERMS_AND_CONDITIONS
                        ?.LIABILITY_INDEMNITY_LIST_2_SUB_5
                    }
                  </li>
                </ul>
              </li>
            </ul>
            <Typography variant="h5" className="mb-10">
              {localesData?.TERMS_AND_CONDITIONS?.CONTRIBUTING_CONTENT}
            </Typography>
            <ul className="terms-ul">
              <li>
                {localesData?.TERMS_AND_CONDITIONS?.CONTRIBUTING_CONTENT_LIST_1}
              </li>
              <li>
                {localesData?.TERMS_AND_CONDITIONS?.CONTRIBUTING_CONTENT_LIST_2}
              </li>
              <li>
                {localesData?.TERMS_AND_CONDITIONS?.CONTRIBUTING_CONTENT_LIST_3}
              </li>
              <li>
                {localesData?.TERMS_AND_CONDITIONS?.CONTRIBUTING_CONTENT_LIST_4}
              </li>
              <li>
                {localesData?.TERMS_AND_CONDITIONS?.CONTRIBUTING_CONTENT_LIST_5}
              </li>
            </ul>
            <Typography variant="h5" className="mb-10">
              {localesData?.TERMS_AND_CONDITIONS?.REFUND_POLICY}
            </Typography>
            <Typography style={{ marginBottom: "10px" }}>
              {localesData?.TERMS_AND_CONDITIONS?.SUBSCRIPTION_SERVICES}
            </Typography>
            <Typography>
              {localesData?.TERMS_AND_CONDITIONS?.SUBSCRIPTION_NOTICE}
            </Typography>
            <ul className="terms-ul">
              <li>
                <Typography style={{ marginBottom: "5px" }}>
                  {localesData?.TERMS_AND_CONDITIONS?.REFUND_LIST_1_HEADING}
                </Typography>
                <Typography>
                  {localesData?.TERMS_AND_CONDITIONS?.REFUND_LIST_1_PARA_1}
                </Typography>
                <Typography>
                  {localesData?.TERMS_AND_CONDITIONS?.REFUND_LIST_1_PARA_2}
                </Typography>
                <Typography>
                  {localesData?.TERMS_AND_CONDITIONS?.REFUND_LIST_1_PARA_3}
                </Typography>
              </li>
              <li>
                <Typography style={{ marginBottom: "5px" }}>
                  {localesData?.TERMS_AND_CONDITIONS?.REFUND_LIST_2_HEADING}
                </Typography>
                <Typography>
                  {localesData?.TERMS_AND_CONDITIONS?.REFUND_LIST_2_PARA_1}
                  <a
                    href={
                      Config?.release == "IN"
                        ? "mailto:info@smartb.co.in"
                        : "mailto:info@smartb.com.au"
                    }
                  >
                    <b className="gambling-link">
                      {Config?.release == "IN"
                        ? "info@smartb.co.in"
                        : "info@smartb.com.au"}
                    </b>
                  </a>
                </Typography>
                <Typography>
                  {localesData?.TERMS_AND_CONDITIONS?.REFUND_LIST_2_PARA_2}
                </Typography>
              </li>
              <li>
                <Typography style={{ marginBottom: "5px" }}>
                  {localesData?.TERMS_AND_CONDITIONS?.REFUND_LIST_3_HEADING}
                </Typography>
                <Typography>
                  {localesData?.TERMS_AND_CONDITIONS?.REFUND_LIST_3_PARA_1}
                </Typography>
              </li>
            </ul>
            <Typography>
              {localesData?.TERMS_AND_CONDITIONS?.REFUND_NOTICE}
            </Typography>
            <Typography variant="h5" className="mb-10">
              {localesData?.TERMS_AND_CONDITIONS?.GENERAL}
            </Typography>
            <ul className="terms-ul">
              <li>{localesData?.TERMS_AND_CONDITIONS?.GENERAL_LIST_1}</li>
              <li>{localesData?.TERMS_AND_CONDITIONS?.GENERAL_LIST_2}</li>
              <li>
                <span>{localesData?.TERMS_AND_CONDITIONS?.WARNING}</span>
                {localesData?.TERMS_AND_CONDITIONS?.GENERAL_LIST_3}
              </li>
              <li>{localesData?.TERMS_AND_CONDITIONS?.GENERAL_LIST_4}</li>
              <li>
                {localesData?.TERMS_AND_CONDITIONS?.GENERAL_LIST_5}{" "}
                <a
                  className="gambling-link"
                  href={
                    Config?.release == "IN"
                      ? "https://www.smartb.co.in"
                      : "https://www.smartb.com.au"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {Config?.release == "IN"
                    ? "www.smartb.co.in."
                    : "www.smartb.com.au."}
                </a>
              </li>
              <li>{localesData?.TERMS_AND_CONDITIONS?.GENERAL_LIST_6}</li>
              <li>{localesData?.TERMS_AND_CONDITIONS?.GENERAL_LIST_7}</li>
              <li>{localesData?.TERMS_AND_CONDITIONS?.GENERAL_LIST_8}</li>
              <li>{localesData?.TERMS_AND_CONDITIONS?.GENERAL_LIST_9}</li>
            </ul>
            <Typography variant="body2" className="mb-0">
              <b>SmartB</b>
            </Typography>
            <Typography variant="body2" className="mb-0">
              <a
                href={
                  Config?.release == "IN"
                    ? "mailto:info@smartb.co.in"
                    : "mailto:info@smartb.com.au"
                }
              >
                <b>
                  {Config?.release == "IN"
                    ? "info@smartb.co.in"
                    : "info@smartb.com.au"}
                </b>
              </a>
            </Typography>
            <Typography variant="body2" className="mb-0 pb-46">
              <a href="tel:1300065591">
                <b>1300 065 591 </b>
              </a>
            </Typography>
          </Box>
          {/* <Box className="our-partner">
            <Typography variant="h1">OUR PARTNERS</Typography>
            <h5>Coming Soon</h5>
          </Box> */}
        </Box>
        {screenWidth > 479 ? "" : <OurPartner />}
      </Box>
    </>
  );
};

export default TermsAndConditions;
