import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import Feature1 from "../../../assets/images/socSignupPage/feature1.png";
import Feature2 from "../../../assets/images/socSignupPage/feature2.png";
import Feature3 from "../../../assets/images/socSignupPage/feature3.png";
import Feature4 from "../../../assets/images/socSignupPage/feature4.png";
import Feature5 from "../../../assets/images/socSignupPage/feature5.png";
import Feature6 from "../../../assets/images/socSignupPage/feature6.png";
import Feature7 from "../../../assets/images/socSignupPage/feature7.png";
import Feature8 from "../../../assets/images/socSignupPage/feature8.png";
import { ReactComponent as RightArrow } from "../../../assets/images/socSignupPage/socRightArrow.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const featuresData = [
  {
    id: 1,
    title: "Odds Comparison",
    img: Feature1,
    description:
      "Racing Odds Comparison for Win & Place featuring <b> almost 100 corporate bookmakers </b>, plus Betfair and the 3 Totes.",
  },
  {
    id: 2,
    title: "Statistics",
    img: Feature2,
    description:
      "We're adding more and more runner, jockey, trainer, and racecourse statistics everyday. Access them directly in the SOC.",
  },
  {
    id: 3,
    title: "Customisable Layout",
    img: Feature3,
    description:
      "Customise the Smart Odds Comparison how you want to see it. 5 fully customisable layouts.",
  },
  {
    id: 4,
    title: "Market information",
    img: Feature4,
    description:
      "All the information you need is at your fingertips. Check historical info and current info.",
  },
  {
    id: 5,
    title: "Fluctuation graphs",
    img: Feature5,
    description:
      "Check odds fluctuations from every bookmaker. Choose which ones you want to see, or see them all.",
  },
  {
    id: 6,
    title: "Race Results",
    img: Feature6,
    description:
      "See the results of past races, and know the earning potential of your favourite runners.",
  },
  {
    id: 7,
    title: "Detailed form guide",
    img: Feature7,
    description:
      "Form guides for every race are available direct from bookmaker data.",
  },
  {
    id: 8,
    title: "Race Filters",
    img: Feature8,
    description:
      "Filter by race type, country, state, or, track. It's entirely up to you.",
  },
];

const SOCFeatures = ({ handleSOCMemberModalOpen }) => {
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [cardSelected, setcardSelected] = useState(1);
  const [selectedImg, setSelectedImg] = useState([]);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    const selectedImgData = featuresData?.filter(
      (item) => item?.id === cardSelected,
    );
    setSelectedImg(selectedImgData);
  }, [cardSelected]);

  const setting = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    dots: true,
    arrows: false,
  };

  const handleNavigate = () => {
    navigate("/membership");
  };
  return (
    <>
      <Box className="soc-features-wrap">
        <Box className="soc-features-inner-wrap">
          <Box className="features-inner-wrap-details">
            <Typography className="soc-signup-section-header">
              SOC <span className="highlight-text">Features</span>
            </Typography>
            <Typography className="soc-signup-header-details">
              We've got a large number of features for our users and we're
              constantly adding more!
            </Typography>
            {screenWidth > 1023 ? (
              <Box className="feature-details-wrap">
                <Box className="feature-details-left">
                  <Box className="feature-card-wrap">
                    {featuresData?.map((item, index) => {
                      return (
                        <>
                          <Box
                            key={index}
                            className={
                              cardSelected === item?.id
                                ? "active feature-card"
                                : "feature-card"
                            }
                            onClick={() => setcardSelected(item?.id)}
                          >
                            <Box className="feature-title-details">
                              <Typography className="feature-title">
                                {item.title}
                              </Typography>
                              <RightArrow />
                            </Box>
                            <Typography
                              className="feature-description"
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></Typography>
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                </Box>
                <Box className="feature-details-right">
                  <img src={selectedImg?.[0]?.img} alt="img" />
                </Box>
              </Box>
            ) : (
              <>
                <Box className="feature-slider">
                  <Slider {...setting}>
                    {featuresData?.map((item, index) => {
                      return (
                        <Box key={index} className="slider-card-details">
                          <Box className="feature-img-wrap">
                            <img src={item?.img} alt="img" />
                          </Box>
                          <Box className="feature-title-details">
                            <Typography className="feature-title">
                              {item.title}
                            </Typography>
                          </Box>
                          <Typography
                            className="feature-description"
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          ></Typography>
                        </Box>
                      );
                    })}
                  </Slider>
                </Box>
              </>
            )}
            <Box className="feature-btn-wrap">
              <Button
                variant="contained"
                className="soc-sign-up-button"
                // onClick={() => handleNavigate()}
                onClick={() => handleSOCMemberModalOpen()}
              >
                Sign up for your free trial today
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SOCFeatures;
