import React from "react";
import Page from "src/components/Page";
import RaceingTipsPage from "src/views/component/TipsMarketplace/racingTips";

const RaceingTips = () => {
  return (
    <Page title="Racing-Tips">
      <RaceingTipsPage />
    </Page>
  );
};

export default RaceingTips;
