import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Breadcrumbs,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import banner from "../../../../assets/images/banner/smart-b-banner-primary.png";
import AdvertisingBannner from "../../advertisingBanner";
import InformationTab from "./InformationTab";
import ReviewTab from "./ReviewTab";
import { Link, useLocation } from "react-router-dom";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import OurPartner from "../../ourPartners";
import { Config, fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdBannner from "../../AdBanner/AdBanner";
import "./bookmakersinfo.scss";
import BookmakerInfo from "./BookmakerInfo";
import DialogBox from "../../UI/dialogBox";
import { Rating } from '@mui/material';
import { ReactComponent as Star1 } from "src/assets/images/icons/star1.svg";
import { ReactComponent as Star2 } from "src/assets/images/icons/star2.svg";
import { ReactComponent as Star3 } from "src/assets/images/icons/star3.svg";
import { ReactComponent as Star4 } from "src/assets/images/icons/star4.svg";
import { ReactComponent as Star5 } from "src/assets/images/icons/star5.svg";
import { toast } from "react-toastify";

const tabsData = [
  { id: 0, name: "InformAtion" },
  { id: 1, name: "Reviews" }
];

const ratingOption = [
  { label: "5", value: 5 },
  { label: "4", value: 4 },
  { label: "3", value: 3 },
  { label: "2", value: 2 },
  { label: "1", value: 1 }
];
const BookMakersInfoPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);

  const params = useParams();
  const [tabvalue, setTabValue] = useState(0);
  const [EventPage, setEventPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [bookkeeperData, setBookkeeperData] = useState({});
  const [infoTabData, setInfoTabData] = useState({});
  const [reviewTabData, setReviewTabData] = useState({});
  const [loader, setLoader] = useState(false);
  const [reviewUserList, setReviewUserList] = useState([]);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [open, setOpen] = useState(false);
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);
  const [ratingError, setRatingError] = useState("");
  const [bookkeeperID, setBookkeeperID] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (location?.state?.data) {
      setBookkeeperData(location?.state?.data && location?.state?.data);
      getInfomationTabDataAPI(params?.id);
      getReviewTabDataAPI(params?.id, EventPage);
    } else {
      getBookmakersList();
      getInfomationTabDataAPI(params?.id);
      getReviewTabDataAPI(params?.id, EventPage);
    }
  }, [location?.state?.data, params?.id]);

  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    // try {
    //   const { status, data } = await axiosInstance.get(
    //     `getAllAds?page_id=1,11`
    //   );
    //   if (status === 200) {
    let AdvtData = ReduxAdsData?.filter(item => item?.page_id === 1);
    setAdData(AdvtData);
    // dispatch(fetchAdvtData(AdvtData?.[0]));
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const getBookmakersList = async () => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`
      );
      if (status === 200) {
        setLoader(false);
        if (data?.result && data?.result?.length > 0) {
          const dataR = data?.result?.find(
            item => Number(item?.id) === Number(params?.id)
          );
          setBookkeeperData(dataR?.BookKeeper);
        }
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log("error", err);
    }
  };

  const handleTabChange = (event, value) => {
    if (value === 1) {
      if (handleRestrictedUser()) {
        setTabValue(value);
      } else {
        dispatch(fetchRestrictedRoute(true));
        localStorage.setItem("prev_authroute", window.location.pathname);
      }
    } else {
      setTabValue(value);
    }
  };

  const getInfomationTabDataAPI = async id => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/public/bookkeepercms/bookkeepers/${id}`
      );
      if (status === 200) {
        setLoader(false);
        setInfoTabData(data ? data?.data : {});
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log("error", err);
    }
  };
  const getReviewTabDataAPI = async (id, EventPage) => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/public/reviews?bookKeeperId=${id}&page=${EventPage}`
      );
      if (status === 200) {
        setLoader(false);
        setReviewTabData(data);
        setReviewUserList(data?.reviews);
        setEventPage(EventPage);
        const totalCounts =
          data?.pagination?.totalPages * data?.pagination?.perPage;
        setTotalCount(totalCounts);
        setTotalPage(data?.pagination?.totalPages);
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log("error", err);
    }
  };
  // const handleScrollBootamReview = async (id, EventPage) => {
  //   try {
  //     const { status, data } = await axiosInstance.get(
  //       `/reviews?bookKeeperId=${id}&page=${EventPage}`
  //     );
  //     if (status === 200) {
  //       // setReviewTabData(data);
  //       setReviewUserList([...reviewUserList, ...data?.reviews]);
  //       setEventPage(EventPage);
  //     } else {
  //     }
  //   } catch (err) {
  //     console.log("error", err);
  //   }
  // };

  const showLogo = item => {
    var logo = item?.bookMakerList_logo
      ? Config.mediaURL + item?.bookMakerList_logo
      : "";
    return logo;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    var payload = {
      bookKeeperId: Number(bookkeeperID),
      review: review,
      rating: rating?.toString()
    };

    if (rating === 0) {
      setRatingError("Please select a rating.");
    } else {
      setRatingError("");
      if (rating !== null || rating > 0) {
        try {
          const { status, data } = await axiosInstance.post(
            `/reviews`,
            payload
          );
          if (status === 201) {
            setOpen(false);
            setReview("");
            setRating(0);
            toast.success("Review submitted successfully!", {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored"
            });
          }
        } catch (err) {
          console.log("err", err);
        }
      }
    }
  };

  const oncloseDialog = () => {
    setOpen(false);
    setReview("");
    setRating(0);
  };

  const handleReviewChange = event => {
    setReview(event.target.value);
  };

  const handleRatingChange = newValue => {
    setRating(newValue);
  };

  const handleReviewSection = id => {
    if (handleRestrictedUser()) {
      setOpen(true);
      setBookkeeperID(id);
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };

  const reviewEmoji = value => {
    if (value <= 1) {
      return <Star1 />;
    }
    if (value <= 2) {
      return <Star2 />;
    }
    if (value <= 3) {
      return <Star3 />;
    }
    if (value <= 4) {
      return <Star4 />;
    }
    if (value <= 5) {
      return <Star5 />;
    }
  };

  return (<>
    <Box className="content-wrap">
      <Box className="mobile-banner">
        {/* <AdvertisingBannner src={banner} className="banner" /> */}
        {fetchPageHeaderAds("218px", banner)}
      </Box>

      <Box className="bookmakersinfo-data">
        <Box className="bredcrumn-wrap">
          <Breadcrumbs
            separator="/"
            aria-label="breadcrumb"
            className="breadcrumb"
          >
            <Link underline="hover" color="inherit" to="/">
              HOME
            </Link>
            <Link underline="hover" color="inherit" to="/bookmaker">
              Bookmakers
            </Link>
            <Typography>{bookkeeperData?.name}</Typography>
          </Breadcrumbs>
        </Box>

        <Typography className="page-title">{bookkeeperData?.name}</Typography>

        <Box
          className="bookmakers-info-tab"
          // className="teamsport-data-tab"
        >
          <Tabs
            value={tabvalue}
            variant="scrollable"
            scrollButtons={false}
            indicatorColor="primary"
            textColor="primary"
            className="bookmakersinfo-tab-detail"
            // className="teamsport-data-tab-details player-tab-details"
          >
            {tabsData?.map((item, index) => {
              return (
                <Box key={index}>
                  <Tab
                    label={
                      <Box className="tab-label">
                        <span className="label-name">{item?.name}</span>
                      </Box>
                    }
                    value={item?.id}
                    className={item?.id === tabvalue ? "active " : ""}
                    onChange={(event, newValue) =>
                      handleTabChange(event, item?.id)
                    }
                  />
                </Box>
              );
            })}
          </Tabs>
          <BookmakerInfo
            bookkeeperData={bookkeeperData}
            showLogo={showLogo}
            handleReviewSection={handleReviewSection}
            reviewTabData={reviewTabData && reviewTabData}
          />
        </Box>
        {tabvalue === 0 ? (
          <InformationTab
            bookkeeperData={bookkeeperData}
            infoTabData={infoTabData}
            showLogo={showLogo}
          />
        ) : (
          <ReviewTab
            bookkeeperData={bookkeeperData}
            reviewTabData={reviewTabData}
            reviewUserList={reviewUserList}
            getReviewTabDataAPI={getReviewTabDataAPI}
            // handleScrollBootamReview={handleScrollBootamReview}
            BookKeeperIdFromData={params?.id}
            setEventPage={setEventPage}
            EventPage={EventPage}
            totalPage={totalPage}
            totalCount={totalCount}
            loader={loader}
            showLogo={showLogo}
          />
        )}
      </Box>
      <OurPartner />
      <Dialog
        onClose={() => oncloseDialog()}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="odds-Comparison-modal filter-modal"
      >
        <DialogTitle className="modal-title">
          <Typography variant="h6" className="title">
            Write a review
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => oncloseDialog()}
            className="close-icon"
            size="large">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="review-modal-details">
          <Box className="smartb-dialog">
            {/* <Typography className="title">Write a review</Typography> */}
            {/* <Rating
              value={rating}
              name="half-rating"
              className="rating"
              onChange={handleRatingChange}
            /> */}
            <Box className="d-flex text-align">
              {ratingOption?.map((item, i) => {
                return (
                  <Box
                    key={i}
                    className="bookmaker-total-review-emoji mr-18"
                    onClick={() => handleRatingChange(item?.value)}
                  >
                    {reviewEmoji(parseInt(item?.value))}
                    <Rating
                      name="customized-10"
                      defaultValue={item?.value}
                      // precision={0.5}
                      className={`rating ${
                        rating === item?.value ? "select-rating" : ""
                      }`}
                      readOnly
                    />
                  </Box>
                );
              })}
            </Box>
            {ratingError && !rating && (
              <div className="text-danger ml-4">{ratingError}</div>
            )}
            <TextField
              id="review"
              variant="outlined"
              className="txt-fld"
              placeholder="Write a review"
              multiline
              rows={8}
              fullWidth
              value={review}
              onChange={handleReviewChange}
            />
            <Box className="submit-btn-box">
              <Button className="submit-btn" onClick={e => handleSubmit(e)}>
                Submit
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* <DialogBox
        open={open}
        onClose={oncloseDialog}
        handleClose={oncloseDialog}
        title={false}
        className="dialog-box"
        content={
          <Box className="smartb-dialog">
            <Typography className="title">Write a review</Typography>
            <Rating
              value={rating}
              name="half-rating"
              className="rating"
              onChange={handleRatingChange}
            />
            {ratingError && !rating && (
              <div className="text-danger ml-4">{ratingError}</div>
            )}
            <TextField
              id="review"
              variant="outlined"
              className="txt-fld"
              placeholder="Write a review"
              multiline
              rows={8}
              fullWidth
              value={review}
              onChange={handleReviewChange}
            />

            <Box className="submit-btn-box">
              <Button className="submit-btn" onClick={e => handleSubmit(e)}>
                Submit
              </Button>
            </Box>
          </Box>
        }
        closeIcon={true}
      /> */}
    </Box>
  </>);
};

export default BookMakersInfoPage;
