import { Box } from "@mui/material";
import React, { useMemo, useState } from "react";
import CustomTab from "./CustomTab";
import RaceResultHomeTable from "./RaceResultHomeTable";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useSelector } from "react-redux";
import { fetchFromStorage } from "src/helpers/context";
import { useEffect } from "react";

const RaceResultHome = ({ availableSport }) => {
  const [tabvalue, setTabValue] = useState(0);
  const [allSportData, setAllSportData] = useState([]);
  const [selectedSport, setSelectedSport] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [raceList, setRaceList] = useState([]);
  const [raceCount, setRaceCount] = useState(0);
  const layoutData = useSelector((state) => state?.reduxData?.LayoutData);
  const localAuth = fetchFromStorage("auth_token");
  let isLogin = localAuth ? true : false;

  const handleTabChange = (event, item) => {
    setTabValue(item?.value);
    setSelectedSport(item?.id);
    fetchAllRaceResult(item?.id);
  };

  const fetchAllRaceResult = async (SportId) => {
    setIsLoading(true);

    try {
      const { status, data } = await axiosInstance.get(
        `/events/raceResult?sportId=${SportId}`,
      );
      if (status === 200) {
        setIsLoading(false);
        setRaceList(data?.result);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // const memoizedSportData = useMemo(() => {
  //   if (!availableSport || availableSport.length === 0) {
  //     return [];
  //   }

  //   const teamSportData = availableSport
  //     ?.filter(item => item?.sportTypeId == 1)
  //     ?.filter(item => item?.raceResult === true);

  //   const newData =
  //     teamSportData?.map((item, index) => ({
  //       label: item?.sportName,
  //       value: index,
  //       name: item?.sportName,
  //       id: item?.id
  //     })) || [];

  //   const idOrder = { 1: 0, 2: 2, 3: 1 };
  //   const SortedData = newData.sort(
  //     (a, b) => (idOrder[a?.id] || 0) - (idOrder[b?.id] || 0)
  //   );

  //   setAllSportData(SortedData);
  //   setSelectedSport(SortedData?.[0]?.id);
  //   fetchAllRaceResult(SortedData?.[0]?.id);

  //   return SortedData;
  // }, [availableSport]);

  useEffect(() => {
    if (isLogin) {
      if (
        (layoutData && Object.entries(layoutData)?.length > 0) ||
        layoutData === null
      ) {
        if (availableSport && availableSport?.length > 0) {
          const availableRacingData = availableSport
            ?.filter((item) => item?.sportTypeId === 1)
            ?.filter((ele) => ele?.raceResult === true);
          const selectedRaces =
            layoutData?.racingResult && layoutData?.racingResult?.length > 0
              ? layoutData?.racingResult
              : [1, 3, 2];
          const filterRaceResult = availableRacingData?.filter((item) =>
            selectedRaces?.includes(item?.id),
          );

          const newData =
            filterRaceResult?.map((item, index) => ({
              label: item?.sportName,
              value: index,
              name: item?.sportName,
              id: item?.id,
            })) || [];

          const idOrder = { 1: 0, 2: 2, 3: 1 };
          const SortedData = newData.sort(
            (a, b) => (idOrder[a?.id] || 0) - (idOrder[b?.id] || 0),
          );

          setAllSportData(SortedData);
          setSelectedSport(SortedData?.[0]?.id);
          fetchAllRaceResult(SortedData?.[0]?.id);
          setTabValue(SortedData?.[0]?.value);
        }
      }
    } else {
      if (availableSport && availableSport?.length > 0) {
        const teamSportData = availableSport
          ?.filter((item) => item?.sportTypeId == 1)
          ?.filter((item) => item?.raceResult === true);

        const newData =
          teamSportData?.map((item, index) => ({
            label: item?.sportName,
            value: index,
            name: item?.sportName,
            id: item?.id,
          })) || [];

        const idOrder = { 1: 0, 2: 2, 3: 1 };
        const SortedData = newData.sort(
          (a, b) => (idOrder[a?.id] || 0) - (idOrder[b?.id] || 0),
        );

        setAllSportData(SortedData);
        setSelectedSport(SortedData?.[0]?.id);
        fetchAllRaceResult(SortedData?.[0]?.id);
      }
    }
  }, [availableSport, layoutData]);

  return (
    <Box className="component-wrap upcoming-fixture-result-wrap">
      <h3 className="component-header">Racing latest Results</h3>
      <CustomTab
        mapData={allSportData}
        tabvalue={tabvalue}
        handleTabChange={handleTabChange}
      />
      <RaceResultHomeTable
        raceList={raceList}
        raceCount={raceCount}
        isLoading={isLoading}
        selectedSport={selectedSport}
      />
    </Box>
  );
};

export default RaceResultHome;
