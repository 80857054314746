import React from "react";
import Page from "src/components/Page";
import CompTipsPage from "src/views/component/compTips";

const CompTips = () => {
  return (
    <Page title="Tips">
      <CompTipsPage />
    </Page>
  );
};

export default CompTips;
