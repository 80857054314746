import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableContainer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Checkbox,
  TextField,
  ClickAwayListener
} from "@mui/material";
import { Config } from "src/helpers/context";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchFromStorage } from "src/helpers/context";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { ReactComponent as OddsLock } from "src/assets/images/icons/odds-lock.svg";
import DefaultImg from "src/assets/images/smartb_default.png";
import "./tippingSportsOdds.scss";
import useAutoSaveStore from "src/helpers/zustand/useAutoSaveStore";
const TippingSportsOdds = ({
  data,
  type,
  team,
  teamId,
  isScore,
  eventLength,
  eventByIdData
}) => {
  const dispatch = useDispatch();
  const { setApiInfo } = useAutoSaveStore();
  const BetslipData = useSelector(state => state?.reduxData?.BetSlipData);
  const BookkeeperData = useSelector(state => state?.reduxData?.BookkeeperData);

  const oddsicon = (BookKeeperId, type, item, fullData, oddsType) => {
    const Identifiers =
      data?.SportId == 4
        ? fullData?.CricketIdentifiers
        : data?.SportId == 10
        ? fullData?.NBAIdentifiers
        : data?.SportId == 15
        ? fullData?.AFLIdentifiers
        : data?.SportId == 9
        ? fullData?.ARIdentifiers
        : data?.SportId == 16
        ? fullData?.GolfIdentifiers
        : data?.SportId == 7
        ? fullData?.TennisIdentifiers
        : data?.SportId == 11
        ? fullData?.BaseballIdentifiers
        : data?.SportId == 17
        ? fullData?.IceHockeyIdentifiers
        : data?.SportId == 6
        ? fullData?.BoxingIdentifiers
        : data?.SportId == 5
        ? fullData?.MMAIdentifiers
        : data?.SportId == 8
        ? fullData?.SoccerIdentifiers
        : fullData?.RLIdentifiers;
    const newData = Identifiers?.map(obj => {
      const BookkeeperObj = obj?.ApiProvider?.BookKeeperProviders?.filter(
        item => {
          return item?.BookKeeperId === BookKeeperId;
        }
      );
      return {
        ...obj,
        BookKeeperId:
          BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null
      };
    });
    const filteredData = newData
      ?.filter?.(obj => obj?.BookKeeperId === BookKeeperId)
      ?.filter(obj => obj?.ApiProviderId !== 17);

    let icon = BookkeeperData?.filter(obj => obj?.id === BookKeeperId);
    let iconData = icon?.[0];
    let imageUrl =
      oddsType == "currentbest"
        ? iconData?.currentBest_logo
        : iconData?.long_logo;
    // if (BookKeeperId === 8) {
    //   const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

    //   const Odds = Bet365Data?.filter(
    //     ele => ele?.providerMarketId && ele?.providerParticipantId
    //   );
    //   const urlLink =
    //     Odds?.[0]?.providerMarketId && Odds?.[0]?.providerParticipantId
    //       ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.[0]?.providerMarketId}-${Odds?.[0]?.providerParticipantId}~${Odds?.[0]?.odd}~1&bet=1`
    //       : `${iconData?.affiliate_link}`;
    //   return (
    //     <Box
    //       // href={
    //       //
    //       // }
    //       // target="_blank"
    //       // rel="noopener noreferrer"
    //       className="current-best-odds-icon"
    //       // onClick={e => handleAnchorTagClick(e, urlLink)}
    //     >
    //       {oddsType == "currentbest" ? (
    //         <img
    //           className="currentbest-bookmaker-thumb"
    //           src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
    //           onClick={e => {
    //             handleBookkeeperCounter(BookKeeperId, type, e);
    //             handleAnchorTagClick(e, urlLink);
    //           }}
    //           alt="Odds Icon"
    //         />
    //       ) : (
    //         <Box className="odd-img">
    //           <img
    //             src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
    //             onClick={e => {
    //               handleBookkeeperCounter(BookKeeperId, type, e);
    //               handleAnchorTagClick(e, urlLink);
    //             }}
    //             alt="Odds Icon"
    //           />
    //         </Box>
    //       )}
    //     </Box>
    //   );
    // } else {
    const urlLink =
      filteredData?.length > 0 && filteredData?.[0]?.url
        ? filteredData?.[0]?.url + `?Referrer=SmartB`
        : iconData?.affiliate_link;
    return (
      <Box
        // href={
        //   filteredData?.length > 0 && filteredData?.[0]?.url
        //     ? filteredData?.[0]?.url + `?Referrer=SmartB`
        //     : iconData?.affiliate_link
        // }
        // target="_blank"
        // rel="noopener noreferrer"
        className="current-best-odds-icon"
        // onClick={e => handleAnchorTagClick(e, urlLink)}
      >
        {oddsType == "currentbest" ? (
          <img
            className="currentbest-bookmaker-thumb"
            src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
            onClick={e => {
              handleBookkeeperCounter(BookKeeperId, type, e);
              handleAnchorTagClick(e, urlLink);
            }}
            alt="Odds Icon"
          />
        ) : (
          <Box className="odd-img">
            <img
              src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
              onClick={e => {
                handleBookkeeperCounter(BookKeeperId, type, e);
                handleAnchorTagClick(e, urlLink);
              }}
              alt="Odds Icon"
            />
          </Box>
        )}
      </Box>
    );
    // }
  };

  const fetchCurrentBestOdds = (data, type, team, teamid, Isscore) => {
    let allTeamOdds =
      data?.SportId == 4
        ? data?.CricketBetOffers
        : data?.SportId == 10
        ? data?.NBABetOffers
        : data?.SportId == 15
        ? data?.AFLBetOffers
        : data?.SportId == 9
        ? data?.ARBetOffers
        : data?.SportId == 16
        ? data?.GolfBetOffers
        : data?.SportId == 7
        ? data?.TennisBetOffers
        : data?.SportId == 11
        ? data?.BaseballBetOffers
        : data?.SportId == 17
        ? data?.IceHockeyBetOffers
        : data?.SportId == 6
        ? data?.BoxingBetOffers
        : data?.SportId == 5
        ? data?.MMABetOffers
        : data?.SportId == 8
        ? data?.SoccerBetOffers
        : data?.RLBetOffers;
    let HomeTeamOdds = allTeamOdds?.homeOdds;
    let AwayTeamOdds = allTeamOdds?.awayOdds;
    let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;
    if (teamInfo?.odd) {
      return (
        <>
          <span className="odds-point">{teamInfo?.point}</span>
          {fetchClickableOdds(
            teamInfo?.odd,
            teamInfo?.BookKeeperId,
            "header",
            teamInfo,
            data,
            "betslip",
            Isscore,
            "currentbest",
            team
          )}
        </>
      );
    } else {
      return null;
    }
  };
  const fetchCurrentBestsOddsIcon = (data, type, team, teamid) => {
    let allTeamOdds =
      data?.SportId == 4
        ? data?.CricketBetOffers
        : data?.SportId == 10
        ? data?.NBABetOffers
        : data?.SportId == 15
        ? data?.AFLBetOffers
        : data?.SportId == 9
        ? data?.ARBetOffers
        : data?.SportId == 16
        ? data?.GolfBetOffers
        : data?.SportId == 7
        ? data?.TennisBetOffers
        : data?.SportId == 11
        ? data?.BaseballBetOffers
        : data?.SportId == 17
        ? data?.IceHockeyBetOffers
        : data?.SportId == 6
        ? data?.BoxingBetOffers
        : data?.SportId == 5
        ? data?.MMABetOffers
        : data?.SportId == 8
        ? data?.SoccerBetOffers
        : data?.RLBetOffers;
    let HomeTeamOdds = allTeamOdds?.homeOdds;
    let AwayTeamOdds = allTeamOdds?.awayOdds;
    let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;

    if (teamInfo?.odd && teamInfo?.odd !== 0) {
      return oddsicon(
        teamInfo?.BookKeeperId,
        "header",
        teamInfo,
        data,
        "currentbest"
      );
    } else {
      return "";
      // <span className="odds"> - </span>
    }
  };
  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip,
    Isscore,
    OddsType,
    team
  ) => {
    let icon = BookkeeperData?.filter(obj => obj?.id === BookKeeperId);
    let iconData = icon?.[0];
    const oddsInfo = item;
    let isAdded = BetslipData?.some(el => {
      if (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfo?.id &&
        el?.eventId == oddsInfo?.eventId
      )
        return true;
      else return false;
    });

    return (
      <>
        {eventLength !== 0 &&
        eventByIdData?.tippingType != "winning" &&
        (team == "hometeam"
          ? fulldata?.homeTeam?.isTip === 1
          : fulldata?.awayTeam?.isTip === 1) ? (
          <span
            className={`odds-lock-wrap ${isAdded ? "lock-betslip-added" : ""}`}
          >
            <OddsLock />
          </span>
        ) : null}

        {odds || odds == 0 ? (
          <span
            className={`cursor-pointer current-best-odds-value  ${
              isAdded && IsBetslip === "betslip" ? "betslip-added" : ""
            } `}
            onClick={e => {
              IsBetslip === "nobetslip"
                ? handleBookkeeperCounter(BookKeeperId, type, e)
                : isAdded
                ? handleDeleteBetslip(BookKeeperId, item, fulldata, e)
                : handleAddToBetSlip(
                    BookKeeperId,
                    type,
                    item,
                    fulldata,
                    Isscore,
                    e
                  );
            }}
          >
            <span className="odds-link">
              {IsBetslip === "betslip" && !Isscore ? (
                <Tooltip title="Bet" className="bet-tooltip" placement="top">
                  <span className="bet-now">
                    {" "}
                    {odds == 0 ? "SP" : Number(odds).toFixed(2)}{" "}
                  </span>
                </Tooltip>
              ) : (
                <>{odds == 0 ? "SP" : Number(odds).toFixed(2)}</>
              )}
            </span>
          </span>
        ) : null}
      </>
    );
  };
  const handleAddToBetSlip = async (
    BookKeeperId,
    type,
    item,
    fulldata,
    isScore,
    e
  ) => {
    e.stopPropagation();
    handleBookkeeperCounter(BookKeeperId, type, e);
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    const oddsInfo = item;
    let payload = {
      sportId: fulldata?.SportId,
      eventId: oddsInfo?.eventId,
      teamId: oddsInfo?.teamId,
      bookKeeperId: BookKeeperId,
      betOfferId: oddsInfo?.id
    };
    if (isLogin) {
      if (!isScore) {
        try {
          const { status, data } = await axiosInstance.post(
            `betSlipCard/BetSlipCard`,
            payload
          );
          if (status === 200) {
            toast.success(data?.message, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored"
            });

            const addedData = [...BetslipData, data?.result];
            dispatch(fetchBetSlipData(addedData));
            dispatch(fetchBetSlipCount(addedData?.length));
          } else {
          }
        } catch (err) {
          console.log("error", err);
        }
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
      if (!isScore) {
        setApiInfo({
          api: `betSlipCard/BetSlipCard`,
          payload: payload,
          method: "post"
        });
      }
    }
  };
  const handleDeleteBetslip = async (BookKeeperId, item, fulldata, e) => {
    e.stopPropagation();

    const oddsInfo = item;
    let delslip = BetslipData?.filter(el => {
      return (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfo?.id &&
        el?.eventId == oddsInfo?.eventId
      );
    })?.[0];

    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?id=${delslip?.id}`
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        let chageData = BetslipData?.filter(obj => obj?.id !== delslip?.id);

        dispatch(fetchBetSlipData(chageData));
        dispatch(fetchBetSlipCount(chageData?.length));
        // setBetSlipData(chageData);
      } else {
      }
    } catch (err) {}
  };
  //   const fetchBookKeeper = async () => {
  //     try {
  //       const { status, data } = await axiosInstance.get(
  //         `public/apiProviders/bookkeeperproviders`
  //       );
  //       if (status === 200) {
  //         setBookKeeperData(data?.result);
  //       } else {
  //       }
  //     } catch (err) {}
  //   };

  const handleBookkeeperCounter = async (BookKeeperId, type, e) => {
    e.stopPropagation();
    let SportId = data?.SportId;
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(SportId)
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const handleAnchorTagClick = (e, url) => {
    window.open(url, "_blank");
    e.stopPropagation();
    // href={
    //   Odds?.[0]?.providerMarketId && Odds?.[0]?.providerParticipantId
    //     ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.[0]?.providerMarketId}-${Odds?.[0]?.providerParticipantId}~${Odds?.[0]?.odd}~1&bet=1`
    //     : `${iconData?.affiliate_link}`
    // }
  };

  return (
    <Box className="sports-odds-wrap  sports-current-best-odds-wrap">
      <span>
        <span className="odds-container">
          {fetchCurrentBestOdds(data, type, team, teamId, isScore)}
        </span>
      </span>
      {fetchCurrentBestsOddsIcon(data, type, team)}
    </Box>
  );
};

export default TippingSportsOdds;
