import React from "react";
import "./uiFluctuationGraph.scss";
import { LineChart, Line, Tooltip, Legend } from "recharts";
import { useEffect } from "react";
import { useState } from "react";
import { Box } from "@mui/material";

export default function EnterTipsGraph({ FlucData }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  const customTooltipContent = ({ payload }) => {
    if (payload && payload[0]) {
      const value = payload[0].value;
      return <Box className="tool-tip-wrap">{`odds: ${value}`}</Box>;
    }
    return null;
  };

  const firstValue = FlucData?.[0]?.point;
  const lastValue = FlucData?.[FlucData?.length - 1]?.point;
  const lastIndex = FlucData?.length - 1;
  const percentageChange =
    ((lastValue - firstValue) / (firstValue === 0 ? 1 : firstValue)) * 100;
  const increasing = lastValue > firstValue;
  const highestPoint = Math.max(...FlucData?.map(item => item?.point));
  const lowestPoint = Math.min(...FlucData?.map(item => item?.point));

  const getDotColor = data => {
    if (data?.payload?.point === highestPoint) {
      return "green";
    } else if (data?.payload?.point === lowestPoint) {
      return "red";
    } else if (data?.index === lastIndex) {
      return "orange";
    } else {
      return "#C9C9C9";
    }
  };

  return (
    <LineChart
      width={screenWidth > 799 ? 300 : screenWidth < 469 ? 246 : 300}
      height={screenWidth > 799 ? 90 : screenWidth < 469 ? 65 : 100}
      data={FlucData}
      className="high-chart"
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <Tooltip content={customTooltipContent} />
      <Legend />
      <Line
        dataKey="point"
        name={
          percentageChange >= 0
            ? `${Math.ceil(percentageChange)}%`
            : `${Math.ceil(percentageChange)
                .toString()
                .replace("-", "")}%`
        }
        stroke={increasing ? "#82ca9d" : "#D84727"}
        dot={({ stroke, ...props }) => (
          <circle
            {...props}
            fill={getDotColor(props)}
            r={4}
            style={{ cursor: "pointer" }}
          />
        )}
      />
    </LineChart>
  );
}
