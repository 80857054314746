import React, { useEffect, useState } from "react";
import { Box, Table, TableBody, TableCell, TableRow } from "@mui/material";
import AboveTable from "../../../../../../assets/images/ad-placeholder/sports1.webp";
import { useParams } from "react-router";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "../../../../../../components/Loader";
import moment from "moment";
import NoDataComp from "src/views/component/UI/NoData";

function FormInfoPage({
  fetchAds,
  staticsType,
  profileType,
  personData,
  trackId
}) {
  const params = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [formLoading, setformLoading] = useState(false);
  const [formDetailsData, setformDetailsData] = useState([]);
  const [FormOffset, setFormOffset] = useState(0);
  const [FormAddedDataCount, setFormAddedDataCount] = useState(0);
  const checktrackcondition = data => {
    if (data.toLowerCase().includes("heavy")) {
      return "heavy";
    } else if (data.toLowerCase().includes("soft")) {
      return "soft";
    } else {
      return "good";
    }
  };
  const checkplayerplace = place => {
    if (place === "1") {
      return "first-place";
    }
  };
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchFormDetails(0, []);
  }, []);

  const fetchFormDetails = async (offset, info) => {
    setformLoading(true);
    try {
      let passApi =
        staticsType === "modal"
          ? profileType === "jockey"
            ? "getJockeyData"
            : "getTrainerData"
          : params?.personType === "jockey"
          ? "getJockeyData"
          : "getTrainerData";

      const { status, data } = await axiosInstance.get(
        `statistics/${passApi}/form/${
          staticsType == "modal" ? personData?.id : params?.personId
        }?limit=20&offset=${offset}`
      );
      if (status === 200) {
        setformLoading(false);

        const FormData = data?.result;

        let FormInfoData = FormData?.map(obj => {
          return {
            ...obj,
            Runner: obj?.runner?.map(runner => {
              return {
                ...runner,
                RunnerInfos: runner?.RunnerInfos?.map(item => {
                  return {
                    ...item,
                    info: item?.info ? JSON.parse(item?.info) : ""
                  };
                })
              };
            }),
            RaceResultSummary: obj?.RaceResultSummary?.summary
              ? JSON.parse(obj?.RaceResultSummary?.summary)
              : []
          };
        });
        let standingData = FormInfoData?.map(item => {
          return {
            ...item,
            RaceResultSummary: item?.RaceResultSummary?.map(obj => {
              return {
                ...obj,
                RunnerDetails: item?.runner?.filter(runner => {
                  return obj?.runner_number
                    ? runner?.runnerNumber === Number(obj?.runner_number)
                    : runner?.runnerNumber === Number(obj?.RunnerNumber);
                })
              };
            })
          };
        });
        setFormAddedDataCount(FormInfoData?.length);
        const formData = [...info, ...FormInfoData];
        setformDetailsData(formData);
      } else {
        setformLoading(false);
      }
    } catch (error) {
      setformLoading(false);
    }
  };

  const handleFormMoreData = () => {
    setFormOffset(FormOffset + 20);
    fetchFormDetails(FormOffset + 20, formDetailsData);
  };
  const handleFormLessData = () => {
    setFormOffset(FormOffset - 20);
    if (formDetailsData?.length % 20 === 0) {
      setformDetailsData(prevArray => prevArray.slice(0, -20));
    } else {
      setformDetailsData(prevArray => prevArray.slice(0, -FormAddedDataCount));
    }
  };

  return (
    <>
      {staticsType == "modal" ? "" : fetchAds(1, "102px", AboveTable)}
      <Box className="player-form-wrap">
        {screenWidth > 799 ? (
          <>
            {formLoading && (
              <div className="allsport-loader-center">
                <Loader />
              </div>
            )}
            {!formLoading && (
              <>
                <Table className="form-table">
                  <TableBody>
                    {formDetailsData?.length > 0 ? (
                      formDetailsData?.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell className="place-wrap">
                            <span
                              className={`player-rank ${checkplayerplace(
                                item?.place
                              )}`}
                            >
                              {/* <span className="place">8 </span> of
                              <span>12</span> */}
                              {item?.runnerLength}
                            </span>
                          </TableCell>
                          <TableCell className="form-extra-details">
                            <span> {item?.raceName}</span>{" "}
                            <span>
                              {item.startDate
                                ? moment(item.startDate).format("DD-MMM-YY")
                                : ""}{" "}
                              {item?.Distance ? item?.Distance?.name + "m" : ""}
                            </span>{" "}
                            {item?.trackCondition ? (
                              <span
                                className={`track-condition ${checktrackcondition(
                                  item?.trackCondition
                                )}`}
                              >
                                {item?.trackCondition}
                              </span>
                            ) : (
                              <></>
                            )}
                            <span>
                              {" "}
                              {item?.raceNumber
                                ? "R" + item?.raceNumber
                                : ""}{" "}
                            </span>
                            {/* <span>{item?.position}</span> */}
                            <span>
                              {item?.prizeMoney ? item?.prizeMoney : ""},{" "}
                            </span>
                            {item?.runner?.[0]?.barrierNumber ? (
                              <span>
                                {" "}
                                Barrier {item?.runner?.[0]?.barrierNumber},{" "}
                              </span>
                            ) : (
                              <></>
                            )}
                            {/* <span>Winning Time: {item?.winningtime}, </span>
                      <span> SP : {item?.sp}</span> */}
                            {item?.status ? (
                              <span>Status {item?.status}</span>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                          <TableCell>
                            <ul className="race-position-wrap">
                              {item?.RaceResultSummary?.slice(0, 4)?.map(
                                obj => {
                                  return (
                                    <>
                                      <li>
                                        <span>
                                          {" "}
                                          {obj?.position
                                            ? Number(obj?.position) === 1
                                              ? "1st"
                                              : Number(obj?.position) === 2
                                              ? "2nd"
                                              : Number(obj?.position) === 3
                                              ? "3rd"
                                              : `${Number(obj?.position)}th`
                                            : Number(obj?.Position) === 1
                                            ? "1st"
                                            : Number(obj?.Position) === 2
                                            ? "2nd"
                                            : Number(obj?.Position) === 3
                                            ? "3rd"
                                            : `${Number(obj?.Position)}th`}
                                        </span>{" "}
                                        <span>
                                          {obj?.name ? obj?.name : obj?.Name}
                                        </span>
                                      </li>
                                    </>
                                  );
                                }
                              )}
                            </ul>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12} className="no-data-td">
                          {/* No Data Available */}
                          <NoDataComp />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {formDetailsData?.length > 0 ? (
                  <Box className="view-more">
                    {FormOffset + 20 === formDetailsData?.length ? (
                      <span
                        className="view-more-text red"
                        onClick={() => handleFormMoreData()}
                      >
                        Show more
                      </span>
                    ) : (
                      <></>
                    )}
                    {FormOffset >= 20 && formDetailsData?.length > 20 ? (
                      <span
                        className="view-more-text view-lese-text red"
                        onClick={() => handleFormLessData()}
                      >
                        View Less
                      </span>
                    ) : (
                      <></>
                    )}
                  </Box>
                ) : (
                  <></>
                )}
              </>
            )}
            {/* <Box className="show-more">Show more</Box> */}
          </>
        ) : (
          <Box className="mobileview-forms-wrap">
            {formLoading && (
              <div className="allsport-loader-center">
                <Loader />
              </div>
            )}

            {!formLoading &&
              (formDetailsData?.length > 0 ? (
                <>
                  {formDetailsData?.map((item, index) => (
                    <Box className="form-detail">
                      <Box
                        className={`place-wrap ${checkplayerplace(
                          item?.place
                        )}`}
                      >
                        {/* <span>
                        <span>8 </span> of
                        <span> 12</span>
                      </span> */}
                        {item?.runnerLength}
                      </Box>
                      <Box className="form-extra-details">
                        <span> {item?.raceName}</span>
                        <span>
                          {item.startDate
                            ? moment(item.startDate).format("DD-MMM-YY")
                            : ""}{" "}
                          {item?.Distance ? item?.Distance?.name + "m" : ""}{" "}
                        </span>{" "}
                        {item?.trackCondition ? (
                          <span
                            className={`track-condition ${checktrackcondition(
                              item?.trackCondition
                            )}`}
                          >
                            {item?.trackCondition}
                          </span>
                        ) : (
                          <></>
                        )}{" "}
                        <span>
                          {" "}
                          {item?.raceNumber ? "R" + item?.raceNumber : ""}
                        </span>
                        {/* <span>{item?.position}</span> */}
                        <span>
                          {item?.prizeMoney ? item?.prizeMoney : ""},{" "}
                        </span>
                        {item?.runner?.[0]?.barrierNumber ? (
                          <span>
                            {" "}
                            Barrier {item?.runner?.[0]?.barrierNumber},{" "}
                          </span>
                        ) : (
                          <></>
                        )}
                        {/* <span>Winning Time: {item?.winningtime}, </span>
                  <span> SP : {item?.sp}</span> */}
                        {item?.status ? (
                          <span>Status {item?.status}</span>
                        ) : (
                          <></>
                        )}
                      </Box>
                      <Box>
                        <ul className="race-position-wrap">
                          {item?.RaceResultSummary?.slice(0, 4)?.map(obj => {
                            return (
                              <>
                                <li>
                                  <span>
                                    {" "}
                                    {obj?.position
                                      ? Number(obj?.position) === 1
                                        ? "1st"
                                        : Number(obj?.position) === 2
                                        ? "2nd"
                                        : Number(obj?.position) === 3
                                        ? "3rd"
                                        : `${Number(obj?.position)}th`
                                      : Number(obj?.Position) === 1
                                      ? "1st"
                                      : Number(obj?.Position) === 2
                                      ? "2nd"
                                      : Number(obj?.Position) === 3
                                      ? "3rd"
                                      : `${Number(obj?.Position)}th`}
                                  </span>{" "}
                                  <span>
                                    {obj?.name ? obj?.name : obj?.Name}
                                  </span>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </Box>
                    </Box>
                  ))}
                  {formDetailsData?.length > 0 ? (
                    <Box className="view-more">
                      {FormOffset + 20 === formDetailsData?.length ? (
                        <span
                          className="view-more-text red"
                          onClick={() => handleFormMoreData()}
                        >
                          Show more
                        </span>
                      ) : (
                        <></>
                      )}
                      {FormOffset >= 20 && formDetailsData?.length > 20 ? (
                        <span
                          className="view-more-text view-lese-text red"
                          onClick={() => handleFormLessData()}
                        >
                          View Less
                        </span>
                      ) : (
                        <></>
                      )}
                    </Box>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Box className="no-data-mobile">
                  {/* No Data Available */}
                  <NoDataComp />
                </Box>
              ))}
          </Box>
        )}
      </Box>
    </>
  );
}

export default FormInfoPage;
