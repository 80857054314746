import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { IntlContext } from "src/App";
import { Config } from "../../../../../helpers/context/config";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import { ReactComponent as Bat } from "../../../../../assets/images/SportIcon/bat.svg";
import { ReactComponent as Ball } from "../../../../../assets/images/SportIcon/ball.svg";
import { useParams } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
// import { statistics } from "src/helpers/jsondata";

// const matchStatsData = [
//   {
//     id: 1,
//     homeStats: 8,
//     stats: "Corner Kicks",
//     awayStats: 8
//   },
//   {
//     id: 2,
//     homeStats: 1,
//     stats: "Yellow Cards",
//     awayStats: 2
//   },
//   {
//     id: 3,
//     homeStats: 1,
//     stats: "Yellow Red Cards",
//     awayStats: 8
//   },
//   {
//     id: 4,
//     homeStats: 1,
//     stats: "Red Cards",
//     awayStats: 5
//   }
// ];

const MatchStats = ({ fixtureInfoData, singleMatchData }) => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [StatsData, setStatsData] = useState([]);
  const [statsLoading, setStatsLoading] = useState(false);

  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Brisbane} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Brisbane} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Melbourne} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Melbourne} alt="Odds Icon" />;
      }
    }
  };

  const TeamStats = (data, type) => {
    const total = data?.homeStats + data?.awayStats;
    const width = (
      ((type === "homeTeam" ? data?.homeStats : data?.awayStats) / total) *
      100
    ).toFixed(2);
    return (
      <>
        <Box
          className="progress-bar"
          style={{
            width: `${width}%`,
            background: width > 50 ? "#003764" : "#D4D6D8"
          }}
        ></Box>
      </>
    );
  };

  useEffect(() => {
    fetchTeamStatsData();
  }, [params]);

  const fetchTeamStatsData = async () => {
    setStatsLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/soccer/event/stats/${params?.id}`
      );
      if (status === 200) {
        setStatsData(data?.result?.result);
        setStatsLoading(false);
      } else {
        setStatsLoading(false);
      }
    } catch (err) {
      setStatsLoading(false);
    }
  };
  const homeTeamId = StatsData && singleMatchData?.homeTeamId;
  const awayTeamId = StatsData && singleMatchData?.awayTeamId;
  const HomeStatsData =
    StatsData && StatsData?.filter(item => item?.teamId === homeTeamId);
  const AwayStatsData =
    StatsData && StatsData?.filter(item => item?.teamId === awayTeamId);

  const statistics = {
    shotsOnTarget: [
      HomeStatsData?.[0]?.Shon ?? null,
      AwayStatsData?.[0]?.Shon ?? null,
      "Shots on target"
    ],
    shotsOffTarget: [
      HomeStatsData?.[0]?.Shof ?? null,
      AwayStatsData?.[0]?.Shof ?? null,
      "Shots off target"
    ],
    shotsBlocked: [
      HomeStatsData?.[0]?.Shbl ?? null,
      AwayStatsData?.[0]?.Shbl ?? null,
      "Shots blocked"
    ],
    possession: [
      HomeStatsData?.[0]?.Pss ?? null,
      AwayStatsData?.[0]?.Pss ?? null,
      "Possession (%)"
    ],
    corners: [
      HomeStatsData?.[0]?.Cos ?? null,
      AwayStatsData?.[0]?.Cos ?? null,
      "Corner Kicks"
    ],
    offsides: [
      HomeStatsData?.[0]?.Ofs ?? null,
      AwayStatsData?.[0]?.Ofs ?? null,
      "Offsides"
    ],
    fouls: [
      HomeStatsData?.[0]?.Fls ?? null,
      AwayStatsData?.[0]?.Fls ?? null,
      "Fouls"
    ],
    throwIns: [
      HomeStatsData?.[0]?.Ths ?? null,
      AwayStatsData?.[0]?.Ths ?? null,
      "Throw ins"
    ],
    yellowCards: [
      HomeStatsData?.[0]?.Ycs ?? null,
      AwayStatsData?.[0]?.Ycs ?? null,
      "Yellow cards"
    ],
    yellowRedCards: [
      HomeStatsData?.[0]?.YRcs ?? null,
      AwayStatsData?.[0]?.YRcs ?? null,
      "Yellow Red cards"
    ],
    redCards: [
      HomeStatsData?.[0]?.Rcs ?? null,
      AwayStatsData?.[0]?.Rcs ?? null,
      "Red cards"
    ],
    crosses: [
      HomeStatsData?.[0]?.Crs ?? null,
      AwayStatsData?.[0]?.Crs ?? null,
      "Crosses"
    ],
    counterAttacks: [
      HomeStatsData?.[0]?.Att ?? null,
      AwayStatsData?.[0]?.Att ?? null,
      "Counter attacks"
    ],
    goalkeeperSaves: [
      HomeStatsData?.[0]?.Gks ?? null,
      AwayStatsData?.[0]?.Gks ?? null,
      "Goalkeeper saves"
    ],
    goalKicks: [
      HomeStatsData?.[0]?.Goa ?? null,
      AwayStatsData?.[0]?.Goa ?? null,
      "Goal kicks"
    ]
  };

  const matchStatsData = Object.keys(statistics)
    ?.filter(
      key =>
        (statistics[key][0] !== 0 || statistics[key][1] !== 0) &&
        (statistics[key][0] !== null || statistics[key][1] !== null)
    )
    ?.map((key, index) => ({
      id: index + 1,
      homeStats: statistics[key][0],
      stats: key,
      awayStats: statistics[key][1],
      name: statistics[key][2]
    }));

  return (
    <>
      <Box className="fixture-wrap">
        <Typography variant="h4" className="fixture-heading">
          {localesData?.FIXTURE_INFO?.MATCH_STATS}
        </Typography>
        {statsLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : matchStatsData?.length > 0 ? (
          <Box className="match-stats-wrap">
            <Box className="d-flex align-center match-team-heading">
              <Box className="d-flex align-center team-wrap">
                <span className="team-name bold">
                  {singleMatchData?.homeTeam?.name
                    ? singleMatchData?.homeTeam?.name
                    : ""}
                </span>
                <span className="team-img-wrap">
                  {fetchTeamlogo(singleMatchData, "hometeam")}
                </span>
              </Box>
              <Box className="bold divider">
                <span></span>
              </Box>
              <Box className="d-flex align-center team-wrap">
                <span className="team-img-wrap ">
                  {fetchTeamlogo(singleMatchData, "awayteam")}
                </span>
                <span className="team-name bold">
                  {singleMatchData?.awayTeam?.name
                    ? singleMatchData?.awayTeam?.name
                    : ""}
                </span>
              </Box>
            </Box>
            {matchStatsData?.map((data, index) => (
              <>
                <Box className="match-stats-details" key={index}>
                  <Box className="d-flex align-center stats-value-wrap">
                    <Typography className="details-value drak">
                      {data?.homeStats}
                    </Typography>
                    <Typography className="details-name">
                      {data?.name}
                    </Typography>
                    <Typography className="details-value light">
                      {data?.awayStats}
                    </Typography>
                  </Box>
                  <Box className="stats-progress-bar">
                    {TeamStats(data, "homeTeam")}
                    {TeamStats(data, "awayTeam")}
                  </Box>
                </Box>
              </>
            ))}
          </Box>
        ) : (
          <Box className="no-match"> No Stats Available </Box>
        )}
      </Box>
    </>
  );
};

export default MatchStats;
