import React, { useState, useContext, useEffect } from "react";
// import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "src/helpers/store/Actions/auth";
import {
  Box,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import "../profile.scss";
import Select, { components } from "react-select";
import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
import {
  LocalizationProvider,
  DatePicker,
  DesktopDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { parseISO } from "date-fns";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import differenceInYears from "date-fns/differenceInYears";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import Loader from "../../../../components/Loader";
import { ReactComponent as BackArrow } from "../../../../assets/images/Back_Arrow.svg";
import { fetchSubscriptionData } from "src/helpers/store/Actions/SubscriptionData";
import { ReactComponent as VisiblePassword } from "src/assets/images/icons/VisiblePassword.svg";
import { ReactComponent as UnVisiblePassword } from "src/assets/images/icons/UnVisiblePassword.svg";
import DefaultImg from "src/assets/images/smartb_default.png";
import { Config } from "src/helpers/context";
import { toast } from "react-toastify";
import profileImage from "src/assets/images/profileImage.png";
import { ReactComponent as EditButton } from "src/assets/images/round-edit-icon.svg";
import UploadImageModal from "../../UI/uploadImage";
import { setApiMessage } from "src/helpers/commonFunctions";

const titleOptions = [
  { value: "Mr", label: "Mr" },
  { value: "Mrs", label: "Mrs" },
  { value: "Ms", label: "Ms" },
  { value: "Miss", label: "Miss" },
  { value: "Dr", label: "Dr" },
];

const hearAboutList = [
  { id: 1, name: "Google" },
  { id: 2, name: "Radio" },
  { id: 3, name: "Social Media" },
  { id: 4, name: "Friend" },
  { id: 5, name: "Subversion" },
  { id: 6, name: "Other" },
];

function UserEditDetails({ passData, handleUserProfile }) {
  let dispatch = useDispatch();
  // const location = useLocation();
  const navigate = useNavigate();
  const { ValueContainer, Placeholder } = components;
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [newData, setnewData] = useState(passData);
  const [formvalues, setformvalues] = useState();
  const [countryAll, setCountryAll] = useState([]);
  const [country, setcountry] = useState(null);
  const [stateAll, setStateAll] = useState([]);
  const [count, setcount] = useState(0);
  const [statecount, setstatecount] = useState(0);
  const [pageCountry, setpageCountry] = useState(0);
  const [pageState, setpageState] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [state, setstate] = useState("");
  const [searchCountryCount, setsearchCountryCount] = useState(0);
  const [searchCountry, setsearchCountry] = useState([]);
  const [isCountrySearch, setisCountrySearch] = useState("");
  const [SearchCountrypage, setSearchCountrypage] = useState(0);
  const [searchStateCount, setsearchStateCount] = useState(0);
  const [searchState, setsearchState] = useState([]);
  const [isStateSearch, setisStateSearch] = useState("");
  const [searchStatePage, setsearchStatePage] = useState(0);
  const [error, seterror] = useState(false);
  const [errorCountry, seterrorCountry] = useState("");
  const [errorState, seterrorState] = useState("");
  const [isCountrySelectOpen, setisCountrySelectOpen] = useState(false);
  const [countryApiCount, setCountryApiCount] = useState(0);
  const [isStateSelectOpen, setisStateSelectOpen] = useState(false);
  const [stateApiCount, setStateApiCount] = useState(-1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [IsPasswordEnter, setIsPasswordEnter] = useState(false);
  const [countryPhone, setCountryPhone] = useState([]);
  const [countCountryPhone, setCountCountryPhone] = useState(0);
  const [pageCountryPhone, setpageCountryPhone] = useState(0);
  const [searchCountryPhoneCount, setsearchCountryPhoneCount] = useState("");
  const [searchCountryPhone, setsearchCountryPhone] = useState([]);
  const [isCountryPhoneSearch, setisCountryPhoneSearch] = useState("");
  const [SearchCountryPhonepage, setSearchCountryPhonepage] = useState(0);
  const [isCountryPhoneSelectOpen, setisCountryPhoneSelectOpen] =
    useState(false);
  const [countryPhoneApiCount, setCountryPhoneApiCount] = useState(0);
  const [priorityCountry, setPriorityCountry] = useState([]);
  const updatedHearAbout = newData?.hearedAbout
    ? newData?.hearedAbout?.map((item) => {
        if (item?.includes("other")) {
          return `Other`;
        }
        return item;
      })
    : [];
  const [hearAboutData, setHearAboutData] = useState(updatedHearAbout);
  const [isGenderSelectOpen, setisGenderSelectOpen] = useState(false);
  const updatedHearAboutOther = newData?.hearedAbout?.filter((item) =>
    item?.includes("other"),
  );
  const [hearAboutOther, sethearAboutOther] = useState(
    updatedHearAboutOther?.[0]?.split(":")[1],
  );
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const nameRegex = /^[a-zA-Z\s'-]+$/;

  const handleHearAboutOther = (e) => {
    sethearAboutOther(e.target.value);
  };

  const handleImageModal = () => {
    setImageModalOpen(true);
  };

  const handleImageClose = () => {
    setImageModalOpen(false);
  };

  const getImage = (data) => {
    setCropImage(data);
    fetchEditProfile(data?.id);
    // setformvalues(prevFormValues => ({
    //   ...prevFormValues,
    //   mediaId: data?.id,
    //   mediaUrl: data?.filePath
    // }));
  };
  const fetchEditProfile = async (MediaId) => {
    let payload = {
      MediaId,
    };

    try {
      const { status, data } = await axiosInstance.put(
        "user/editProfile",
        payload,
      );
      if (status === 200) {
        setApiMessage("success", data?.message);
        fetchSubscriptionPaymentData();
        handleUserProfile();
      } else {
        setApiMessage("error", data?.message);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
    }
  };
  useEffect(() => {
    if (newData) {
      let seletedCountry = [
        {
          label: newData?.address?.Country?.country,
          value: newData?.address?.country,
        },
      ];
      let seletedCountryPhone = newData?.Country?.country
        ? [
            {
              label: newData?.Country?.country,
              value: newData?.Country?.id,
              phoneCode: newData?.Country?.phoneCode,
              flag: newData?.Country?.country_flag?.includes("uploads")
                ? Config?.baseURL + newData?.Country?.country_flag
                : newData?.Country?.country_flag,
            },
          ]
        : [];
      let selectedState = [
        {
          label: newData?.address?.State?.state,
          value: newData?.address?.state,
        },
      ];
      window.addEventListener("resize", function () {
        setScreenWidth(window.innerWidth);
      });
      setCountryAll(newData?.address?.country ? seletedCountry : countryAll);
      setcountry(newData?.address?.country ? newData?.address?.country : "");
      setstate(newData?.address?.state ? newData?.address?.state : "");
      setStateAll(newData?.address?.state ? selectedState : stateAll);
      setCountryPhone(
        newData?.Country?.id ? seletedCountryPhone : countryPhone,
      );
      // setcountryPhone(newData?.Country?.id ? newData?.Country?.id : "");
      // fetchCountry(0, seletedCountry);
      fetchState(
        newData?.address?.country,
        0,
        false,
        selectedState,
        stateApiCount,
      );
      fetchProrityCountry(0, seletedCountry, seletedCountryPhone);
    }
  }, [newData]);

  useEffect(() => {
    if (passData) {
      setnewData(passData);
      setLoading(false);
    }
  }, [passData]);

  const fetchProrityCountry = async (
    page,
    seletedCountry,
    seletedCountryPhone,
  ) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/country?limit=20&offset=${page}&countryId=13,230,231,101`,
      );
      if (status === 200) {
        let newdata = [];
        let track = data?.result?.rows?.map((item) => {
          newdata.push({
            label: item?.country,
            value: item?.id,
            phoneCode: item?.phoneCode,
            flag: item?.country_flag?.includes("uploads")
              ? Config?.baseURL + item?.country_flag
              : item?.country_flag,
          });
        });
        setPriorityCountry(newdata);
        fetchCountry(0, [...seletedCountry, ...newdata]);
        fetchCountryPhone(0, [...seletedCountryPhone, ...newdata]);
      }
    } catch (err) {}
  };

  const fetchCountryPhone = async (page, priorityCountryData) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/country?limit=20&offset=${page}`,
      );
      if (status === 200) {
        setCountryApiCount(countryApiCount + 1);
        setCountCountryPhone(Math.ceil(data?.result?.count / 20));
        let newdata = [];

        const nonPrioritizedCountries = data?.result?.rows?.filter(
          (country) => !priorityCountryData?.includes(country?.value),
        );

        let track = nonPrioritizedCountries?.map((item) => {
          newdata.push({
            label: item?.country,
            value: item?.id,
            phoneCode: item?.phoneCode,
            flag: item?.country_flag?.includes("uploads")
              ? Config?.baseURL + item?.country_flag
              : item?.country_flag,
          });
        });
        const orderedPriorityCountries = priorityCountryData
          ? _.concat(priorityCountryData, newdata)
          : [];
        const orderedCountries = newdata;
        setCountryPhone(
          _.unionBy(
            countryPhone,
            page == 0 ? orderedPriorityCountries : orderedCountries,
          ),
        );
        let filterData = _.unionBy(
          countryPhone,
          page == 0 ? orderedPriorityCountries : orderedCountries,
        )?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });

        const finalArray = filterData?.unshift(...priorityCountryData);

        setCountryPhone(
          _.uniqBy(filterData, function (e) {
            return e.value;
          }),
        );

        //Old Code

        // let track = data?.result?.rows?.map((item) => {
        //   newdata.push({
        //     label: item?.country,
        //     value: item?.id,
        //   });
        // });

        // setCountry(_.unionBy(country, newdata));
      }
    } catch (err) {}
  };
  const handleOnScrollBottomCountryPhone = () => {
    if (
      isCountryPhoneSearch !== "" &&
      searchCountryPhoneCount !== Math.ceil(SearchCountryPhonepage / 20)
    ) {
      handleCountryPhoneInputChange(
        SearchCountryPhonepage + 20,
        isCountryPhoneSearch,
      );

      setSearchCountryPhonepage(SearchCountryPhonepage + 20);
    } else {
      if (countCountryPhone !== Math.ceil(pageCountryPhone / 20)) {
        fetchCountryPhone(pageCountryPhone + 20, priorityCountry);
        setpageCountryPhone(pageCountryPhone + 20);
      }
    }
  };
  const handleCountryPhoneInputChange = (page, value) => {
    axiosInstance
      .get(`public/country?limit=20&offset=${page}&search=${value}`)
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchCountryPhoneCount(res?.data?.result?.count / 20);

          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.country,
              value: item?.id,
              phoneCode: item?.phoneCode,
              flag: item?.country_flag?.includes("uploads")
                ? Config?.baseURL + item?.country_flag
                : item?.country_flag,
            });
          });
          let filterData = _.unionBy(searchCountryPhone, newdata);
          setsearchCountryPhone(
            _.uniqBy(filterData, function (e) {
              return e.value;
            }),
          );
          setisCountryPhoneSearch(value);
        }
      });
  };
  const fetchCountry = async (page, priorityCountryData) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/country?limit=20&offset=${page}`,
      );
      if (status === 200) {
        setCountryApiCount(countryApiCount + 1);
        setcount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        // let track = data?.result?.rows?.map(item => {
        //   newdata.push({
        //     label: item?.country,
        //     value: item?.id
        //   });
        // });

        const nonPrioritizedCountries = data?.result?.rows?.filter(
          (country) => !priorityCountryData?.includes(country?.value),
        );

        let track = nonPrioritizedCountries?.map((item) => {
          newdata.push({
            label: item?.country,
            value: item?.id,
          });
        });

        if (priorityCountryData) {
          // setCountryAll(_.unionBy(countryAll, countryData, newdata));
          let filterData = _.unionBy(
            countryAll,
            priorityCountryData,
            newdata,
          )?.sort((a, b) => {
            return a?.label.localeCompare(b?.label);
          });
          const finalArray = filterData?.unshift(...priorityCountryData);

          setCountryAll(
            _.uniqBy(filterData, function (e) {
              return e.value;
            }),
          );
        } else {
          // setCountryAll(_.unionBy(countryAll, newdata));
          let filterData = _.unionBy(countryAll, newdata)?.sort((a, b) => {
            return a?.label.localeCompare(b?.label);
          });
          const finalArray = filterData?.unshift(...priorityCountryData);
          setCountryAll(
            _.uniqBy(filterData, function (e) {
              return e.value;
            }),
          );
        }
      }
    } catch (err) {}
  };

  const fetchState = async (id, page, type, stateData, statecount) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/state/country/${id}?limit=20&offset=${page}`,
      );
      if (status === 200) {
        setpageState(page);
        setStateApiCount(statecount + 1);
        setstatecount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map((item) => {
          newdata.push({
            label: item?.state,
            value: item?.id,
          });
        });
        if (stateData) {
          // setStateAll(_.unionBy(stateAll, stateData, newdata));
          let filterData = _.unionBy(stateAll, stateData, newdata);
          let sortData = filterData?.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          setStateAll(
            _.uniqBy(sortData, function (e) {
              return e.value;
            }),
          );
        } else {
          if (type) {
            // setStateAll(_.unionBy(newdata));
            let filterData = _.unionBy(newdata);
            let sortData = filterData?.sort((a, b) => {
              return a.label > b.label ? 1 : -1;
            });
            setStateAll(
              _.uniqBy(sortData, function (e) {
                return e.value;
              }),
            );
          } else {
            // setStateAll(_.unionBy(stateAll, newdata));
            let filterData = _.unionBy(stateAll, newdata);
            let sortData = filterData?.sort((a, b) => {
              return a.label > b.label ? 1 : -1;
            });
            setStateAll(
              _.uniqBy(sortData, function (e) {
                return e.value;
              }),
            );
          }
        }
      }
    } catch (err) {}
  };
  const handleOnScrollBottomCountry = () => {
    if (
      isCountrySearch !== "" &&
      searchCountryCount !== Math.ceil(SearchCountrypage / 20)
    ) {
      handleCountryInputChange(SearchCountrypage + 20, isCountrySearch);

      setSearchCountrypage(SearchCountrypage + 20);
    } else {
      if (count !== Math.ceil(pageCountry / 20)) {
        fetchCountry(pageCountry + 20, priorityCountry);
        setpageCountry(pageCountry + 20);
      }
    }
  };
  const handleOnScrollBottomState = () => {
    if (
      isStateSearch !== "" &&
      searchStateCount !== Math.ceil(searchStatePage / 20)
    ) {
      handleStateInputChange(searchStatePage + 20, isStateSearch);

      setsearchStatePage(searchStatePage + 20);
    } else {
      if (statecount !== Math.ceil(pageState / 20)) {
        fetchState(country, pageState + 20, false, null, stateApiCount);
        setpageState(pageState + 20);
      }
    }
  };

  const handleCountryInputChange = (page, value) => {
    axiosInstance
      .get(`public/country?limit=20&offset=${page}&search=${value}`)
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchCountryCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.country,
              value: item?.id,
            });
          });
          let filterData = _.unionBy(searchCountry, newdata);
          setsearchCountry(
            _.uniqBy(filterData, function (e) {
              return e.value;
            }),
          );
          setisCountrySearch(value);
        }
      });
  };

  const handleStateInputChange = (page, value) => {
    axiosInstance
      .get(
        `public/state/country/${country}?limit=20&offset=${page}&search=${value}`,
      )
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;

          setsearchStateCount(Math.ceil(res?.data?.result?.count / 20));

          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.state,
              value: item?.id,
            });
          });
          const finalStateData = newdata.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          let filterData = _.unionBy(searchState, finalStateData);
          setsearchState(
            _.uniqBy(filterData, function (e) {
              return e.value;
            }),
          );
          setisStateSearch(value);
        }
      });
  };
  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isCountrySelectOpen) {
          if (isCountrySearch === "" && countryApiCount !== count) {
            fetchCountry(pageCountry + 20, priorityCountry);
            setpageCountry(pageCountry + 20);
          }
        }
      }, 2000);
    }
  }, [isCountrySelectOpen, countryApiCount, isCountrySearch]);

  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isStateSelectOpen) {
          if (isStateSearch === "" && stateApiCount !== statecount) {
            fetchState(country, pageState + 20, false, null, stateApiCount);
            setpageState(pageState + 20);
          }
        }
      }, 2000);
    }
  }, [isStateSelectOpen, stateApiCount, isStateSearch]);
  const validate = () => {
    let flag = true;

    if (country === "") {
      flag = false;
      seterror(true);
      seterrorCountry("This field is required");
      isLoading(false);
    } else if (state === "") {
      flag = false;
      seterror(true);
      seterrorState("This field is required");
      isLoading(false);
    } else {
      flag = true;
      seterror(false);
      seterrorCountry("");
      seterrorState("");
    }

    return flag;
  };

  const fetchSubscriptionPaymentData = async () => {
    try {
      const { status, data } = await axiosInstance.get(`/user/get-user`);
      if (status === 200) {
        if (Config.release == "IN") {
          data["oddfixtures"] = false;
        }
        dispatch(fetchSubscriptionData(data));
      } else {
        dispatch(fetchSubscriptionData(undefined));
      }
    } catch (err) {
      dispatch(fetchSubscriptionData(undefined));
    }
  };
  const fetchGender = (gender) => {
    if (gender == "m") {
      return "Male";
    } else if (gender == "f") {
      return "Female";
    } else if (gender == "nb") {
      return "Non-binary";
    } else if (gender == "pnts") {
      return "Prefer not to say";
    }
  };
  const handleSave = async () => {
    const EditHearAbout = hearAboutData?.map((item) => {
      if (item === "Other") {
        return `other:${hearAboutOther ? hearAboutOther : ""}`;
      }
      return item;
    });
    setLoading(true);
    let payload = {
      firstName: formvalues?.firstname,
      lastName: formvalues?.lastname,
      dob: formvalues?.dob,
      nickName: formvalues?.nickName,
      gender: formvalues?.gender,
      address: {
        country: country,
        state: state == "" ? null : state,
      },
      phone: formvalues?.phonenumber,
      phoneCountryId: formvalues?.countryPhone,
      // bookMaker: newData?.bookMaker,
      // bookMakersOther: newData?.bookMakerOther,
      // sportOrEvent: newData?.sportOrEvent,
      // sportOrEventOther: newData?.sportOrEventOther,
      // NotificationPreferences: newData?.NotificationPreferences,
      // offerings: newData?.offerings,
      // offeringsOther: newData?.offeringsOther,
      // username: formvalues?.email
      // hearedAbout: EditHearAbout
    };
    // if (newData?.role == "admin" || newData?.role == "expertTip") {
    //   payload = {
    //     ...payload,
    //     wpEmail: formvalues?.wpEmail ? formvalues?.wpEmail : null,
    //     wpUserName: formvalues?.wpUserName ? formvalues?.wpUserName : null
    //   };
    //   if (formvalues?.wpPassword && formvalues?.wpPassword.trim().length > 0) {
    //     payload.wpPassword = formvalues?.wpPassword;
    //   }
    // }

    try {
      if (validate()) {
        const { status, data } = await axiosInstance.put(
          "user/editProfile",
          payload,
        );
        if (status === 200) {
          // setuserData(data);
          // setLoading(false);
          navigate("/profile");
          fetchSubscriptionPaymentData();
          handleUserProfile();
          setApiMessage("success", data?.message);
        } else if (status === 403) {
          setLoading(false);
          localStorage.removeItem("auth_token");
          navigate("/sign-in");
          dispatch(loginUser(""));
        } else {
          setLoading(false);
          setApiMessage("error", data?.message);
        }
      }
    } catch (err) {
      setLoading(false);
      setApiMessage("error", error?.response?.data?.message);
    }
  };
  const handleOnChangeHearAbout = (e) => {
    const { value, checked } = e.target;
    if (checked === true) {
      let checkboxdata = [...hearAboutData, value];
      setHearAboutData(checkboxdata);
    } else {
      let checkboxdata = hearAboutData?.filter((element) => element !== value);
      setHearAboutData(checkboxdata);
    }
  };
  const handleBack = () => {
    navigate("/profile");
  };
  const formatOptionLabel = ({ label, flag, phoneCode }) => {
    if (isCountrySearch) {
      return label;
    } else {
      return (
        <div>
          <img
            src={flag}
            onError={(e) => (e.target.src = DefaultImg)}
            style={{ width: "24px" }}
          />
          <span
            style={{
              width: "42px",
              margin: "0px 5px",
              display: "inline-block",
            }}
          >
            {phoneCode ? `+${phoneCode}` : ""}
          </span>
          <span>{`(` + label + `)`}</span>
        </div>
      );
    }
  };
  const genderOption = [
    {
      label: "Male",
      value: "m",
    },
    {
      label: "Female",
      value: "f",
    },
    {
      label: "Non-binary",
      value: "nb",
    },
    {
      label: "Prefer not to say",
      value: "pnts",
    },
  ];
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null,
        )}
      </ValueContainer>
    );
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  return (
    <>
      {isLoading ? (
        <div className="loader_center">
          <Loader />
        </div>
      ) : (
        <Formik
          initialValues={{
            firstname: newData?.firstName,
            lastname: newData?.lastName,
            dob: newData?.dob,
            countryPhone: newData?.phoneCountryId,
            // countrys: newData?.address?.country,
            // states: newData?.address?.state,
            phonenumber: newData?.phone,
            nickName: newData?.nickName,
            gender: newData?.gender,
            email: newData?.username,
            // hearAboutInfo:
            //   newData?.hearedAbout && newData?.hearedAbout?.length > 0
            //     ? newData?.hearedAbout
            //     : "",
            wpUserName: newData?.wpUserName,
            wpEmail: newData?.wpEmail,
            // wpPassword: newData?.wpPassword
          }}
          validationSchema={Yup.object().shape({
            // title: Yup.string()
            //   .nullable()
            //   .required(localesData?.validation?.required_message),
            firstname: Yup.string()
              .nullable()
              .required(localesData?.validation?.required_message)
              .test(
                "firstname",
                "Only letters, spaces, hyphens, and apostrophes are allowed.",
                (value) => value && value.match(nameRegex),
              ),
            lastname: Yup.string()
              .nullable()
              .required(localesData?.validation?.required_message)
              .test(
                "lastname",
                "Only letters, spaces, hyphens, and apostrophes are allowed.",
                (value) => value && value.match(nameRegex),
              ),
            dob: Yup.date()
              .nullable()
              .typeError("")
              .test("dob", "You have to be at least 18", function (value) {
                return differenceInYears(new Date(), new Date(value)) >= 18;
              })
              .required(localesData?.validation?.required_message),
            nickName: Yup.string()
              .nullable()
              .required(localesData?.validation?.required_message),
            gender: Yup.string().required(
              localesData?.validation?.required_message,
            ),
            // countrys: Yup.string().required(
            //   localesData?.validation?.required_message
            // ),
            // states: Yup.string().required(
            //   localesData?.validation?.required_message
            // ),
            phonenumber: Yup.string()
              .nullable()
              .matches(phoneRegExp, localesData?.validation?.number_valid)
              .required(localesData?.validation?.required_message)
              .min(9, localesData?.validation?.NUMBER_MIN_MAX_LENGTH)
              .max(10, localesData?.validation?.NUMBER_MIN_MAX_LENGTH),
            email: Yup.string()
              .nullable()
              .matches(
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                localesData?.validation?.valid_email,
              )
              .max(255)
              .required(localesData?.validation?.required_message),
            wpUserName: Yup.string().nullable().notRequired(),
            wpEmail: Yup.string()
              .nullable()
              .email(localesData?.validation?.valid_email)
              .max(255)
              .notRequired(),
            wpPassword: Yup.string().nullable().notRequired(),
          })}
          // validate={values => {
          //   if (!values.hearAboutInfo.length) {
          //     return {
          //       hearAboutInfo: "Please Choose Atleast One"
          //     };
          //   }
          //   return {};
          // }}
          onSubmit={() => handleSave()}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box>
                {screenWidth > 799 && (
                  <Box className="useredit-details">
                    {/* <span>
                <BackArrow className="back-arrow" />
              </span>
              <h4>{localesData?.EDITPROFILE?.EDIT_USER_DETAILS}</h4> */}
                    <h4>{localesData?.EDITPROFILE?.UPDATE_MY_DETAILS}</h4>
                  </Box>
                )}

                {/* <Box className="details">
              <Typography className="textfield-text">
                {localesData?.register?.title}:
              </Typography>
              <Box className="title-select-wrap">
                <Select
                  className="select select-title "
                  classNamePrefix="select"
                  placeholder="-Select-"
                  onChange={e => {
                    setFieldValue("title", e?.value);
                  }}
                  value={titleOptions?.find(item => {
                    return item?.value == values?.title;
                  })}
                  options={titleOptions}
                />
                <span className="text-danger">
                  {touched?.title && errors?.title ? errors?.title : ""}
                </span>
              </Box>
            </Box> */}
                <Box className="profile-image-name-wrap">
                  <Box
                    className="profile-image-wrap"
                    onClick={() => handleImageModal()}
                  >
                    <img
                      className="profile-image"
                      src={
                        cropImage?.filePath
                          ? Config?.countryMediaURL + cropImage?.filePath
                          : newData?.Media?.filePath
                            ? Config?.countryMediaURL + newData?.Media?.filePath
                            : profileImage
                      }
                      alt="profile-image"
                    />
                    <EditButton className="add-button" />
                  </Box>
                  <Typography
                    className="profile-link"
                    onClick={() => handleImageModal()}
                  >
                    {localesData?.EDITPROFILE?.CHANGE_PROFILE}
                  </Typography>
                </Box>
                <Box className="details">
                  {/* <Typography className="textfield-text">
                {localesData?.register?.first_name}:
              </Typography> */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textfield"
                    type="text"
                    label="First name"
                    name="firstname"
                    // value={firstName}
                    // onChange={e => setfirstName(e.target.value)}
                    error={Boolean(touched?.firstname && errors?.firstname)}
                    helperText={touched?.firstname ? errors?.firstname : ""}
                    onChange={handleChange}
                    value={values?.firstname}
                    onKeyDown={(e) => {
                      if (!nameRegex.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Box>
                <Box className="details">
                  {/* <Typography className="textfield-text">
                {localesData?.register?.last_name}:
              </Typography> */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textfield"
                    type="text"
                    name="lastname"
                    label="Last name"
                    // value={lastName}
                    // onChange={e => setlastName(e.target.value)}
                    error={Boolean(touched?.lastname && errors?.lastname)}
                    helperText={touched?.lastname ? errors?.lastname : ""}
                    onChange={handleChange}
                    value={values?.lastname}
                    onKeyDown={(e) => {
                      if (!nameRegex.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Box>
                <Box className="details two-element-grid">
                  {/* <Typography className="textfield-text">
                {localesData?.register?.dob}:
              </Typography> */}
                  <Box className="Filteritemlist-datepicker">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        autoOk
                        // disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        placeholder="DD/MM//YYYY"
                        margin="normal"
                        id="date-picker-inline"
                        inputVariant="outlined"
                        // value={parseISO(selectedDate)}
                        // onChange={(e) => handleDateChange(e)}
                        label="DOB"
                        value={
                          values?.dob
                            ? typeof values?.dob === "string"
                              ? parseISO(values?.dob)
                              : values?.dob
                            : null
                        }
                        onChange={(date) => {
                          setFieldValue("dob", date);
                        }}
                        // views={["year", "month", "date"]}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        className="datebirth-picker"
                      />
                    </LocalizationProvider>
                    <span className="text-danger">
                      {touched?.dob && errors?.dob ? errors?.dob : ""}
                    </span>
                  </Box>
                  <Box className="textfield-sec mobile-number-wrap">
                    {/* <Typography className="textfield-text">
                                      {localesData?.register?.country_of_residence}
                                  </Typography> */}
                    <Select
                      className="select phone-code"
                      classNamePrefix="select"
                      onMenuScrollToBottom={(e) =>
                        handleOnScrollBottomCountryPhone(e)
                      }
                      placeholder={"Code"}
                      onInputChange={(e) => handleCountryPhoneInputChange(0, e)}
                      value={
                        isCountryPhoneSearch
                          ? searchCountryPhone?.find((item) => {
                              return item?.value == countryPhone;
                            })
                          : countryPhone?.find((item) => {
                              const countryPhoneValue =
                                formvalues?.countryPhone &&
                                formvalues?.countryPhone !== ""
                                  ? formvalues?.countryPhone
                                  : values?.countryPhone;
                              return item?.value == countryPhoneValue;
                            })
                      }
                      onChange={(e) => {
                        setFieldValue("countryPhone", e?.value);
                        setisCountryPhoneSelectOpen(false);
                        // setstateValue("");
                        setformvalues((prevFormValues) => ({
                          ...prevFormValues,
                          countryPhone: e?.value,
                        }));
                      }}
                      // isSearchable={false}
                      onFocus={() => setisCountryPhoneSelectOpen(true)}
                      onBlur={() => setisCountryPhoneSelectOpen(false)}
                      options={
                        isCountryPhoneSearch ? searchCountryPhone : countryPhone
                      }
                      getOptionLabel={formatOptionLabel}
                      // getOptionValue={(option) => option.value}
                    />
                    <TextField
                      placeholder={""}
                      variant="outlined"
                      type="text"
                      name="phonenumber"
                      className="details-textfield mobile-textfield"
                      label="Mobile number"
                      error={Boolean(
                        touched?.phonenumber && errors?.phonenumber,
                      )}
                      helperText={
                        touched?.phonenumber ? errors?.phonenumber : ""
                      }
                      onChange={(e) => {
                        handleChange(e);
                        setformvalues((prevFormValues) => ({
                          ...prevFormValues,
                          [e?.target?.name]: e?.target?.value,
                        }));
                      }}
                      onBlur={handleBlur}
                      values={
                        formvalues?.phonenumber &&
                        formvalues?.phonenumber !== ""
                          ? formvalues?.phonenumber
                          : values?.phonenumber
                      }
                      value={
                        formvalues?.phonenumber &&
                        formvalues?.phonenumber !== ""
                          ? formvalues?.phonenumber
                          : values?.phonenumber
                      }
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Box>
                </Box>

                <Box className="details">
                  {/* <Typography className="textfield-text">
                {localesData?.register?.email}:
              </Typography> */}
                  <TextField
                    id="outlined-basic"
                    disabled
                    variant="outlined"
                    className="details-textfield"
                    type="text"
                    name="email"
                    error={Boolean(touched?.email && errors?.email)}
                    helperText={touched?.email ? errors?.email : ""}
                    onChange={handleChange}
                    value={values?.email}
                  />
                </Box>
                <Box className="details two-element-grid">
                  {/* <Typography className="textfield-text">
                                      {localesData?.register?.first_name}
                                  </Typography> */}
                  <TextField
                    placeholder={""}
                    variant="outlined"
                    label="Username"
                    type="text"
                    name="nickName"
                    className="details-textfield"
                    error={Boolean(touched?.nickName && errors?.nickName)}
                    helperText={touched?.nickName ? errors?.nickName : ""}
                    onChange={(e) => {
                      handleChange(e);
                      setformvalues((prevFormValues) => ({
                        ...prevFormValues,
                        [e?.target?.name]: e?.target?.value,
                      }));
                    }}
                    value={values?.nickName}
                    onBlur={handleBlur}

                    // value={formvalues?.nickName && formvalues?.nickName !== "" ? formvalues?.nickName : values?.nickName}
                  />

                  <Box className="textfield-sec">
                    <Select
                      className="select"
                      classNamePrefix="select"
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      placeholder={localesData?.register?.GENDER}
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          overflow: "visible",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top:
                            state.hasValue ||
                            state.selectProps.inputValue ||
                            state.selectProps.isFocused
                              ? -12
                              : "auto",
                          backgroundColor:
                            state.hasValue ||
                            state.selectProps.inputValue ||
                            state.selectProps.isFocused
                              ? "white"
                              : "transparent",
                          transition: "top 2s, font-size 0.1s !important",
                          fontSize:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "12px !important",
                          color: state.selectProps.isFocused
                            ? "#4455c7"
                            : "#a4a4a4",
                          padding:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "0px 3px",
                          paddingLeft:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "1px !important",
                          marginLeft:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "7px !important",
                          lineHeight:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "8px !important",
                        }),
                      }}
                      value={genderOption?.find((item) => {
                        const genderValue =
                          formvalues?.gender && formvalues?.gender !== ""
                            ? formvalues?.gender
                            : values?.gender;
                        return item?.value == genderValue;
                      })}
                      onChange={(e) => {
                        setFieldValue("gender", e?.value);

                        setformvalues((prevFormValues) => ({
                          ...prevFormValues,
                          gender: e?.value,
                        }));
                      }}
                      isFocused={isGenderSelectOpen == true}
                      onFocus={() => {
                        setisStateSelectOpen(false);
                        setisCountrySelectOpen(false);
                        setisGenderSelectOpen(true);
                      }}
                      onBlur={() => setisGenderSelectOpen(false)}
                      options={genderOption}
                    />

                    <span className="text-danger">
                      {touched?.gender && errors?.gender ? errors?.gender : ""}
                    </span>
                  </Box>
                </Box>
                <Box className="details two-element-grid">
                  {/* <Typography className="textfield-text">
                {localesData?.register?.country_of_residence}:
              </Typography> */}
                  <Box>
                    <Select
                      className="select select-country-state"
                      classNamePrefix="select"
                      // name="country"
                      // isSearchable={false}
                      onMenuScrollToBottom={(e) =>
                        handleOnScrollBottomCountry(e)
                      }
                      onInputChange={(e) => handleCountryInputChange(0, e)}
                      onChange={(e) => {
                        setcountry(e?.value);
                        setStateAll([]);
                        setStateApiCount(0);
                        fetchState(e?.value, 0, true, null, 0);
                        setpageState(0);
                        setstate("");
                        setisCountrySelectOpen(false);
                      }}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      placeholder={localesData?.register?.country_of_residence}
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          overflow: "visible",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top:
                            state.hasValue ||
                            state.selectProps.inputValue ||
                            state.selectProps.isFocused
                              ? -14
                              : "auto",
                          backgroundColor:
                            state.hasValue ||
                            state.selectProps.inputValue ||
                            state.selectProps.isFocused
                              ? "white"
                              : "transparent",
                          transition: "top 2s, font-size 0.1s !important",
                          fontSize:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "12px !important",
                          color: state.selectProps.isFocused
                            ? "#4455c7"
                            : "#a4a4a4",
                          padding:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "0px 3px",
                          paddingLeft:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "1px !important",
                          marginLeft:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "7px !important",
                          lineHeight:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "8px !important",
                        }),
                      }}
                      isFocused={isCountrySelectOpen == true}
                      onFocus={() => {
                        setisStateSelectOpen(false);
                        setisCountrySelectOpen(true);
                        setisGenderSelectOpen(false);
                      }}
                      onBlur={() => setisCountrySelectOpen(false)}
                      value={
                        isCountrySearch
                          ? searchCountry?.find((item) => {
                              return item?.value == country;
                            })
                          : countryAll?.find((item) => {
                              return item?.value == country;
                            })
                      }
                      options={isCountrySearch ? searchCountry : countryAll}
                    />

                    <span className="text-danger">
                      {/* {touched?.countrys && errors?.countrys
                      ? errors?.countrys
                      : ""} */}
                      {errorCountry ? errorCountry : ""}
                    </span>
                  </Box>
                  {/* <Typography className="textfield-text">
                {localesData?.register?.state}:{" "}
              </Typography> */}
                  <Box>
                    <Select
                      className="select select-country-state"
                      classNamePrefix="select"
                      // isSearchable={false}
                      onMenuScrollToBottom={(e) => handleOnScrollBottomState(e)}
                      onInputChange={(e) => handleStateInputChange(0, e)}
                      onChange={(e) => {
                        setstate(e?.value);
                        setisStateSelectOpen(false);
                        setStateApiCount(0);
                      }}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      placeholder={localesData?.register?.state}
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          overflow: "visible",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top:
                            state.hasValue ||
                            state.selectProps.inputValue ||
                            state.selectProps.isFocused
                              ? -14
                              : "auto",
                          backgroundColor:
                            state.hasValue ||
                            state.selectProps.inputValue ||
                            state.selectProps.isFocused
                              ? "white"
                              : "transparent",
                          transition: "top 2s, font-size 0.1s !important",
                          fontSize:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "12px !important",
                          color: state.selectProps.isFocused
                            ? "#4455c7"
                            : "#a4a4a4",
                          padding:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "0px 3px",
                          paddingLeft:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "1px !important",
                          marginLeft:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "7px !important",
                          lineHeight:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "8px !important",
                        }),
                      }}
                      isFocused={isStateSelectOpen == true}
                      onFocus={() => {
                        setisStateSelectOpen(true);
                        setisCountrySelectOpen(false);
                        setisGenderSelectOpen(false);
                      }}
                      onBlur={() => setisStateSelectOpen(false)}
                      value={
                        isStateSearch
                          ? searchState?.find((item) => {
                              return item?.value == state;
                            })
                          : state !== "" &&
                            stateAll?.find((item) => {
                              return item?.value == state;
                            })
                      }
                      options={isStateSearch ? searchState : stateAll}
                    />

                    <span className="text-danger">
                      {/* {touched?.states && errors?.states ? errors?.states : ""} */}
                      {errorState ? errorState : ""}
                    </span>
                  </Box>
                </Box>
                {/* <Box className="details">
              <TextField
                id="outlined-basic"
                variant="outlined"
                className="details-textfield"
                type="text"
                name="phonenumber"
                error={Boolean(touched?.phonenumber && errors?.phonenumber)}
                helperText={touched?.phonenumber ? errors?.phonenumber : ""}
                onChange={handleChange}
                value={values?.phonenumber}
              />
            </Box> */}

                {/* {(newData?.role == "admin" || newData?.role == "expertTip") && (
              <>
                <Box className="details">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textfield"
                    type="text"
                    name="wpUserName"
                    label={localesData?.register?.WP_USER}
                    placeholder="Wordpress User"
                    autoComplete="off"
                    inputProps={{
                      autoComplete: "new-password"
                    }}
                    onChange={handleChange}
                    value={values?.wpUserName}
                  />
                </Box>
                <Box className="details">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textfield"
                    type="text"
                    name="wpEmail"
                    label={localesData?.register?.WP_EMAIL_ID}
                    placeholder="Wordpress Email"
                    inputProps={{
                      autoComplete: "new-password"
                    }}
                    error={Boolean(touched?.wpEmail && errors?.wpEmail)}
                    helperText={touched?.wpEmail ? errors?.wpEmail : ""}
                    onChange={handleChange}
                    value={values?.wpEmail}
                  />
                </Box>
                <Box className="details">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textfield wp-password"
                    name="wpPassword"
                    placeholder="Wordpress Password"
                    autoComplete="off"
                    label={localesData?.register?.WP_PASSWORD}
                    inputProps={{
                      autoComplete: "new-password"
                    }}
                    type={IsPasswordEnter ? "text" : "password"}
                    InputProps={{
                      endAdornment: IsPasswordEnter ? (
                        <UnVisiblePassword
                          onClick={() => setIsPasswordEnter(!IsPasswordEnter)}
                        />
                      ) : (
                        <VisiblePassword
                          onClick={() => setIsPasswordEnter(!IsPasswordEnter)}
                        />
                      )
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.wpPassword}
                  />
                </Box>
              </>
            )} */}

                {/* <Box className="details hear-about-details">
              <Typography className="textfield-text">
                {localesData?.register?.HEAR_ABOUT}
              </Typography>
              <Box className="checkbox-list">
                <Box className="checkbox-list-1">
                  {hearAboutList?.map(element => (
                    <FormControlLabel
                      className="bookmakers-check"
                      key={element.id}
                      onChange={e => {
                        handleChange(e);
                        handleOnChangeHearAbout(e);
                      }}
                      control={<Checkbox className="bookmakers-checkbox" />}
                      label={element?.name}
                      value={element?.name}
                      name="hearAboutInfo"
                      checked={hearAboutData?.includes(element?.name)}
                    />
                  ))}
                </Box>
                <span className="text-danger">
                  {touched?.hearAboutInfo && errors?.hearAboutInfo
                    ? errors?.hearAboutInfo
                    : ""}
                </span>
                <Box className="other-filed filed-1 ">
                  <Box></Box>
                  <Box>
                    {hearAboutData?.includes("Other") ? (
                      <Box>
                        <TextField
                          placeholder={""}
                          variant="outlined"
                          type="text"
                          name="OtherPleaseSpecify"
                          className="text-field"
                          value={hearAboutOther}
                          onChange={e => handleHearAboutOther(e)}
                        />
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              </Box>
            </Box> */}

                <Box className="profile-button">
                  <Box className="profile-btn">
                    {/* <Button
                  variant="outlined"
                  className="btn-cancel"
                  onClick={() => handleBack()}
                >
                  {localesData?.EDITPROFILE?.CANCEL}
                </Button> */}
                    <Button
                      variant="contained"
                      className="btn-save"
                      type="submit"
                      // onClick={() => handleSave()}
                    >
                      {localesData?.EDITPROFILE?.SAVE_CHANGES}
                    </Button>
                  </Box>
                </Box>
              </Box>
              {setformvalues(values)}
            </form>
          )}
        </Formik>
      )}
      <UploadImageModal
        open={imageModalOpen}
        handleClose={handleImageClose}
        title={"Upload Profile Picture"}
        closeIcon={false}
        getImage={getImage}
        //  listingFunction={() =>
        //    setTimeout(() => {
        //      getEventByID(stepperCount);
        //    }, 3000)
        //  }
      />
    </>
  );
}

export default UserEditDetails;
