import React from "react";
import Page from "src/components/Page";
import ProfilePageV2 from "src/views/component/Profile/ProfileV2";
import Profiles from "src/views/component/Profile/Profiles";

function Profile() {
  return (
    <Page
      title="SmartB | Your Profile"
      description="SmartB is a leading online sports news service and an aggregator of odds comparison from the most reliable bookmakers around the globe"
    >
      {/* <Profiles /> */}
      <ProfilePageV2 />
    </Page>
  );
}

export default Profile;
