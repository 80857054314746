import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";

const StickyTableCell = withStyles(theme => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    minWidth: "165px",
    boxShadow: "inset -2px 0px 0px 0px #ebebeb",
    "@media(max-width: 799px)": {
      maxWidth: "133px",
      width: "220px",
      boxShadow: "inset -2px 0px 0px 0px #ebebeb"
    }
  },
  body: {
    left: 0,
    position: "sticky",
    background: "#FFFFFF",
    boxShadow: "inset -2px 0px 0px 0px #ebebeb",
    "@media(max-width: 799px)": {
      width: "220px",
      boxShadow: "inset -2px 0px 0px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const LeagueTeamDetails = ({ headerData }) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const SeasonId = searchParams.get("seasonId");
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [teamDeatils, setTeamDeatils] = useState([]);
  const [offset, setOffset] = useState(0);
  const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    fetchTeamDetails(0, []);
  }, [params, SeasonId]);

  const fetchTeamDetails = async (offset, teamData) => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/teams/${params?.tournamentId}?SportId=${
          params?.sportId
        }&limit=10&offset=${offset}&seasonId=${SeasonId ? SeasonId : ""}
        `
      );
      if (status === 200) {
        setLoader(false);
        setDataCount(data?.result?.team?.length);
        const teamDeatilsData = [...teamData, ...data?.result?.team];
        setTeamDeatils(teamDeatilsData);
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  };

  const handleMoreData = () => {
    setOffset(offset + 10);
    fetchTeamDetails(offset + 10, teamDeatils);
  };
  const handleLessData = () => {
    setOffset(offset - 10);
    if (teamDeatils?.length % 10 === 0) {
      setTeamDeatils(prevArray => prevArray.slice(0, -10));
    } else {
      setTeamDeatils(prevArray => prevArray.slice(0, -dataCount));
    }
  };

  const handleNavigate = data => {
    navigate(
      `/sport/statistics/${params?.sportId}/${
        params?.tournamentId
      }/league-data/${data?.id}/team-data?seasonId=${SeasonId ? SeasonId : ""}`
    );
  };
  return (
    <>
      <Box className="teamsport-data-accordian league-data-details">
        <Box className="teamsport-section-details">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                {SeasonId
                  ? headerData?.season?.name
                  : headerData?.tournament?.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="league-accordion-details">
              <Box className="short-name-details">
                <Typography className="short-name">
                  P - <span>Played</span> , W - <span>Win</span>, L -{" "}
                  <span>Loss</span>, D - <span>Draw</span>
                  {/* , F - , A -{" "}
                  <span>Away</span>, PD - <span>Point Differential</span>, FPG -{" "}
                  <span>Fantasy Points per Game</span>, APG -{" "}
                  <span>Assists Points per Game</span>, Att% -{" "}
                  <span>Attempts</span> , Def% - <span>Defeated</span>{" "} */}
                </Typography>
              </Box>
              <Box className="teamsport-header-details">
                <Typography className="heading-title">Teams</Typography>
              </Box>
              <Box className="table-overlay">
                {loader && (
                  <div className="allsport-loader-center">
                    <Loader />
                  </div>
                )}
                {!loader && (
                  <>
                    <TableContainer
                      id="details-table"
                      className="teamsport-data-table summary-table"
                    >
                      <Box className="overlay"></Box>
                      <Table className="data-table" style={{ minWidth: 940 }}>
                        <TableHead className="table-sub-header">
                          <TableRow className="table-sub-head-row">
                            <StickyTableCell
                              className={`${classes.head} sticky-head`}
                            ></StickyTableCell>
                            <TableCell className={classes.head} colSpan={4}>
                              Home
                            </TableCell>
                            <TableCell className={classes.head} colSpan={4}>
                              Away
                            </TableCell>
                            <TableCell className={classes.head} colSpan={4}>
                              Overall
                            </TableCell>
                            {/* <TableCell className={classes.head} colSpan={3}>
                              stats
                            </TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableHead className="table-header">
                          <TableRow className="table-head-row ">
                            <StickyTableCell
                              className={`${classes.head} table-head-first`}
                            >
                              Teams
                            </StickyTableCell>
                            <TableCell className={classes.head}>P</TableCell>
                            <TableCell className={classes.head}>W</TableCell>
                            <TableCell className={classes.head}>L</TableCell>
                            <TableCell
                              className={`${classes.head} border-right`}
                            >
                              D
                            </TableCell>
                            {/* <TableCell className={classes.head}>F</TableCell>
                            <TableCell className={classes.head}>A</TableCell>
                            <TableCell
                              className={`${classes.head} border-right`}
                            >
                              PD
                            </TableCell> */}
                            <TableCell className={classes.head}>P</TableCell>
                            <TableCell className={classes.head}>W</TableCell>
                            <TableCell className={classes.head}>L</TableCell>
                            <TableCell
                              className={`${classes.head} border-right`}
                            >
                              D
                            </TableCell>
                            {/* <TableCell className={classes.head}>F</TableCell>
                            <TableCell className={classes.head}>A</TableCell>
                            <TableCell
                              className={`${classes.head} border-right`}
                            >
                              PD
                            </TableCell> */}
                            <TableCell className={classes.head}>P</TableCell>
                            <TableCell className={classes.head}>W</TableCell>
                            <TableCell className={classes.head}>L</TableCell>
                            <TableCell
                              className={`${classes.head} border-right`}
                            >
                              D
                            </TableCell>
                            {/* <TableCell className={classes.head}>Bye</TableCell>
                            <TableCell className={classes.head}>F</TableCell>
                            <TableCell className={classes.head}>A</TableCell>
                            <TableCell className={classes.head}>Pts</TableCell>
                            <TableCell className={classes.head}>PD</TableCell>
                            <TableCell className={classes.head}>FPG</TableCell>
                            <TableCell className={classes.head}>APG</TableCell>
                            <TableCell className={classes.head}>Att%</TableCell>
                            <TableCell className={classes.head}>Def%</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody className="table-body">
                          {teamDeatils?.length > 0 ? (
                            teamDeatils?.map((item, i) => {
                              return (
                                <>
                                  <TableRow key={i} className="table-body-row ">
                                    <StickyTableCell
                                      className={`${classes.cell} table-body-first cursor-pointer`}
                                      onClick={() => handleNavigate(item)}
                                    >
                                      {item?.name}
                                    </StickyTableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.home?.played ||
                                      item?.home?.played === 0
                                        ? item?.home?.played
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.home?.win || item?.home?.win === 0
                                        ? item?.home?.win
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.home?.loss ||
                                      item?.home?.loss === 0
                                        ? item?.home?.loss
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      className={`${classes.cell} border-right`}
                                    >
                                      {item?.home?.draw ||
                                      item?.home?.draw === 0
                                        ? item?.home?.draw
                                        : "-"}
                                    </TableCell>
                                    {/* <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell
                                      className={`${classes.cell} border-right`}
                                    >
                                      -
                                    </TableCell> */}
                                    <TableCell className={classes.cell}>
                                      {item?.away?.played ||
                                      item?.away?.played === 0
                                        ? item?.away?.played
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.away?.win || item?.away?.win === 0
                                        ? item?.away?.win
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.away?.loss ||
                                      item?.away?.loss === 0
                                        ? item?.away?.loss
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      className={`${classes.cell} border-right`}
                                    >
                                      {item?.away?.draw ||
                                      item?.away?.draw === 0
                                        ? item?.away?.draw
                                        : "-"}
                                    </TableCell>
                                    {/* <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell
                                      className={`${classes.cell} border-right`}
                                    >
                                      -
                                    </TableCell> */}
                                    <TableCell className={classes.cell}>
                                      {item?.overAll?.played ||
                                      item?.overAll?.played === 0
                                        ? item?.overAll?.played
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.overAll?.win ||
                                      item?.overAll?.win === 0
                                        ? item?.overAll?.win
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.overAll?.loss ||
                                      item?.overAll?.loss === 0
                                        ? item?.overAll?.loss
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      className={`${classes.cell} border-right`}
                                    >
                                      {item?.overAll?.draw ||
                                      item?.overAll?.draw === 0
                                        ? item?.overAll?.draw
                                        : "-"}
                                    </TableCell>
                                    {/* <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      - */}
                                    {/* <Box className="percentage-data">
                                      <span className="line"></span>
                                      <Box className="att-data"></Box>
                                    </Box> */}
                                    {/* </TableCell>
                                    <TableCell className={classes.cell}>
                                      - */}
                                    {/* <Box className="percentage-data">
                                      <span className="line"></span>
                                      <Box className="def-data"></Box>
                                    </Box> */}
                                    {/* </TableCell> */}
                                    <TableCell
                                      className={classes.cell}
                                    ></TableCell>
                                  </TableRow>
                                </>
                              );
                            })
                          ) : (
                            <TableRow>
                              <TableCell colSpan={100} className="no-data-td">
                                No Data Available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {teamDeatils?.length > 0 ? (
                      <Box className="view-more team-view-more">
                        {offset + 10 === teamDeatils?.length ? (
                          <span
                            className="view-more-text"
                            onClick={() => handleMoreData()}
                          >
                            View More
                          </span>
                        ) : (
                          <></>
                        )}
                        {offset >= 10 && teamDeatils?.length > 10 ? (
                          <span
                            className="view-more-text view-lese-text"
                            onClick={() => handleLessData()}
                          >
                            View Less
                          </span>
                        ) : (
                          <></>
                        )}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </>
  );
};

export default LeagueTeamDetails;
