import React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { ReactComponent as UpArrow } from "../../../../assets/images/oddsComparison/upArrow.svg";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { setApiMessage } from "src/helpers/commonFunctions";
import moment from "moment";
import useMembershipStore from "src/helpers/zustand/useMembershipStore";

const CouponCodeDetails = ({
  setCouponCode,
  couponCode,
  setCouponApply,
  couponApply,
  setCouponCodeDetails,
  couponCodeDetails,
}) => {
  const { selectedPlanDetailsStore } = useMembershipStore();

  const handleApplyCouponCode = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/subscription/coupon/verify/${couponCode}?plateform=web&plan_id=${2}`,
      );
      if (status === 200) {
        setCouponApply(true);
        setCouponCodeDetails(data?.data);
        setApiMessage("success", data?.data?.message);
      } else {
        setCouponCodeDetails({});
        setCouponApply(false);
        setCouponCode("");
        setApiMessage("error", data?.data?.message);
      }
    } catch (err) {
      setCouponCodeDetails({});
      setCouponApply(false);
      setCouponCode("");
      setApiMessage("error", err?.response?.data?.data?.message);
    }
  };
  return (
    <>
      <Box className="member-other-details-wrap">
        <Box className="left-your-selection">
          <Box className="your-selection-wrap">
            <Box className="selection-header">
              <Typography className="header ">Apply coupon code</Typography>
            </Box>
            <Box className="subscription-type-details">
              <Box className="coupon-code-wrap">
                <TextField
                  placeholder="Coupon code (optional)"
                  variant="outlined"
                  type="text"
                  className="text-field details-textfield"
                  label="Coupon code (optional)"
                  name="couponCode"
                  onChange={(e) => {
                    setCouponCode(e?.target?.value);
                  }}
                  value={couponCode}
                />

                <Button
                  variant="contained"
                  className="apply-btn"
                  onClick={() => handleApplyCouponCode()}
                >
                  Apply
                </Button>
              </Box>
            </Box>
          </Box>

          <Box className="your-selection-wrap">
            <Box className="selection-header">
              <Typography className="header ">Summary</Typography>
            </Box>
            <Box className="subscription-type-details">
              <Box
                className={
                  selectedPlanDetailsStore?.key === "odds"
                    ? "plan-deatils plan-deatils-mb-9"
                    : "plan-deatils plan-deatils-mb-19"
                }
              >
                <Typography className="plan-name subscription-text">
                  {selectedPlanDetailsStore?.name
                    ? selectedPlanDetailsStore?.name
                    : "-"}
                </Typography>
                <Typography className="plan-price subscription-text">
                  {selectedPlanDetailsStore?.key === "odds"
                    ? "14 days free"
                    : selectedPlanDetailsStore?.key === "free"
                      ? "Free"
                      : selectedPlanDetailsStore?.amount
                        ? `${"$" + selectedPlanDetailsStore?.amount}/${
                            selectedPlanDetailsStore?.duration
                          }`
                        : "-"}
                </Typography>
              </Box>
              {selectedPlanDetailsStore?.key === "odds" && (
                <Box className="plan-deatils plan-deatils-mb-19">
                  <Typography className="plan-name subscription-text"></Typography>
                  <Typography className="plan-price subscription-text">
                    {selectedPlanDetailsStore?.amount
                      ? `${"$" + selectedPlanDetailsStore?.amount}/${
                          selectedPlanDetailsStore?.duration
                        } after`
                      : "-"}
                  </Typography>
                </Box>
              )}
              {couponApply && (
                <Box className="total-details-wrap coupon-code-details-wrap">
                  <Box className="total-details ">
                    <Typography className="total-name subscription-text fw-400">
                      Subtotal
                    </Typography>
                    <Typography className="total-price subscription-text fw-400">
                      {selectedPlanDetailsStore?.amount
                        ? `${
                            "$" +
                            Number(selectedPlanDetailsStore?.amount)?.toFixed(2)
                          }`
                        : "-"}
                    </Typography>
                  </Box>

                  <Box className="total-details ">
                    <Typography className="total-name subscription-text fw-400">
                      Discount
                    </Typography>
                    <Typography className="total-price subscription-text discount-amount fw-400">
                      {couponCodeDetails?.data?.discountAmount === 0 ||
                      couponCodeDetails?.data?.discountAmount === ""
                        ? couponCodeDetails?.data?.extraDays +
                          " " +
                          (couponCodeDetails?.data?.extraDays > 1
                            ? "days free"
                            : "day free")
                        : couponCodeDetails?.discountAmountPrize
                          ? "- " +
                            `${
                              "$" +
                              Number(
                                couponCodeDetails?.discountAmountPrize,
                              )?.toFixed(2)
                            }`
                          : "-"}
                      <span>
                        <UpArrow />
                      </span>
                    </Typography>
                  </Box>
                  <Box className="coupon-code-details">
                    <Box className="details-code">
                      <Typography className="amount-off">
                        {couponCodeDetails?.data?.discountAmount === 0 ||
                        couponCodeDetails?.data?.discountAmount === ""
                          ? `${couponCodeDetails?.data?.extraDays} ${
                              couponCodeDetails?.data?.extraDays > 1
                                ? "days free trial"
                                : "day free trial"
                            }`
                          : `${
                              couponCodeDetails?.data?.isPercentage
                                ? couponCodeDetails?.data?.discountAmount +
                                  "% off"
                                : couponCodeDetails?.data?.discountAmount +
                                  "$ off"
                            }` +
                            (couponCodeDetails?.data?.extraDays
                              ? ` + ${couponCodeDetails?.data?.extraDays} ${
                                  couponCodeDetails?.data?.extraDays > 1
                                    ? "days free"
                                    : "day free"
                                }`
                              : "")}{" "}
                      </Typography>
                      {couponCodeDetails?.data?.months && (
                        <Typography className="card-text mb-7 duration-text">
                          {couponCodeDetails?.data?.isPercentage
                            ? couponCodeDetails?.data?.discountAmount + "%"
                            : couponCodeDetails?.data?.discountAmount +
                              "$"}{" "}
                          of on subscription for the first{" "}
                          {couponCodeDetails?.data?.months}{" "}
                          {couponCodeDetails?.data?.months > 1
                            ? "months"
                            : "month"}
                        </Typography>
                      )}
                      <Typography className="card-text mb-7 coupon-details-text">
                        {couponCodeDetails?.data?.description
                          ? couponCodeDetails?.data?.description
                          : "-"}
                      </Typography>
                      <Typography className="card-text copon-vaild-date">
                        Valid:{" "}
                        {moment(couponCodeDetails?.data?.startAt).format(
                          "DD/MM/YYYY",
                        ) ?? ""}{" "}
                        {couponCodeDetails?.data?.expireAt
                          ? (" - " +
                              moment(couponCodeDetails?.data?.expireAt).format(
                                "DD/MM/YYYY",
                              ) ?? "")
                          : ""}
                      </Typography>
                    </Box>
                    <Box className="coupon-number">
                      <Box className="coupon-card border-element">
                        <span className="circle-bottom"></span>
                        {couponCodeDetails?.data?.code}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              <Box className="total-details-wrap">
                {selectedPlanDetailsStore?.key === "odds" && (
                  <Box className="total-details ">
                    <Typography className="total-name subscription-text fw-400">
                      Total after trial
                    </Typography>
                    <Typography className="total-price subscription-text fw-400">
                      {couponApply
                        ? "$" +
                          (
                            Number(selectedPlanDetailsStore?.amount ?? 0) -
                            Number(couponCodeDetails?.discountAmountPrize ?? 0)
                          )?.toFixed(2)
                        : selectedPlanDetailsStore?.amount
                          ? `${
                              "$" +
                              Number(selectedPlanDetailsStore?.amount)?.toFixed(
                                2,
                              )
                            }`
                          : "-"}
                    </Typography>
                  </Box>
                )}
                <Box className="total-details ">
                  <Typography className="total-name subscription-text">
                    Today's total
                  </Typography>
                  <Typography className="total-price subscription-text">
                    {selectedPlanDetailsStore?.key === "odds"
                      ? "$0.00"
                      : selectedPlanDetailsStore?.amount
                        ? `${
                            "$" +
                            Number(selectedPlanDetailsStore?.amount)?.toFixed(2)
                          }`
                        : "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CouponCodeDetails;
