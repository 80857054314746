import React from "react";
import Page from "src/components/Page";
import Trackprofiles from "src/views/component/Statistics/trackprofile/Trackprofiles";

function trackprofiles() {
  return (
    <Page title="statistics">
      <Trackprofiles />
    </Page>
  );
}

export default trackprofiles;
