import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  IconButton,
  Collapse
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { IntlContext } from "src/App";
import NoDataComp from "src/views/component/UI/NoData";
import Loader from "../../../../components/Loader";
import { useParams } from "react-router";
import _ from "lodash";

const TableDetails = () => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [tableLoading, setTableLoading] = useState(false);
  const [tablePointData, setTablePointData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [collapseMenu, setcollapseMenu] = useState(-1);
  const [categoryMenu, setcategoryMenu] = useState(-1);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    fetchTeamTableData();
  }, [params]);

  const fetchTeamTableData = async () => {
    setTableLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/cricket/table/tournament/${params?.tournamentId}`
      );
      if (status === 200) {
        const groupedData = _.groupBy(data?.result?.result, "name");
        setTablePointData(groupedData);
        setTableLoading(false);
      } else {
        setTableLoading(false);
      }
    } catch (err) {
      setTableLoading(false);
    }
  };
  return (
    <>
      <Box className="details-wrap mb-18">
        {tableLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : (
          <>
            {Object.keys(tablePointData)?.length > 0 ? (
              <>
                <Box className="short-name-details">
                  <Typography className="short-name">
                    M - <span>Played</span> , W - <span>Win</span>, L -{" "}
                    <span>Loss</span>, T - <span>Ties</span>, N/R -{" "}
                    <span>No Results</span>, PTS - <span>Points</span>, NRR -{" "}
                    <span>Net Run Rate</span>
                  </Typography>
                </Box>
                {screenWidth >= 1023
                  ? Object.entries(tablePointData)?.map(([category, items]) => (
                      <TableContainer
                        id="details-table"
                        className="info-table-cont-wrap"
                      >
                        <Table
                          className="data-table"
                          style={{ minWidth: "max-content", height: "100%" }}
                        >
                          <TableHead className="table-header">
                            <TableRow className="table-head-row ">
                              <TableCell>#</TableCell>
                              <TableCell>{category}</TableCell>
                              <TableCell>M</TableCell>
                              <TableCell>W</TableCell>
                              <TableCell>L</TableCell>
                              <TableCell>T</TableCell>
                              <TableCell>N/R</TableCell>
                              <TableCell>PTS</TableCell>
                              <TableCell>NRR</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="table-body">
                            {items?.map((item, i) => {
                              return (
                                <>
                                  <TableRow key={i} className="table-body-row ">
                                    <TableCell>{item?.rnk}</TableCell>
                                    <TableCell>{item?.Tnm}</TableCell>
                                    <TableCell>{item?.pld}</TableCell>
                                    <TableCell>{item?.win}</TableCell>
                                    <TableCell>{item?.lstn}</TableCell>
                                    <TableCell>{item?.drw}</TableCell>
                                    <TableCell>{item?.nr}</TableCell>
                                    <TableCell>{item?.pts}</TableCell>
                                    <TableCell>{item?.nrr}</TableCell>
                                  </TableRow>
                                </>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ))
                  : Object.entries(tablePointData)?.map(
                      ([category, items], categoryIndex) => {
                        return (
                          <>
                            <Box className="mobile-info-table">
                              <Box className="d-flex align-center mobile-table-heading">
                                <Typography
                                  variant="h6"
                                  className="table-heading heading-pos"
                                >
                                  #
                                </Typography>
                                <Typography
                                  variant="h6"
                                  className="table-heading"
                                >
                                  {category}
                                </Typography>
                              </Box>
                              {items?.map((item, index) => (
                                <>
                                  <Box
                                    className={
                                      collapseMenu !== index ||
                                      categoryMenu !== categoryIndex
                                        ? "info-table-border-bottom info-table-deatils"
                                        : "info-table-deatils"
                                    }
                                    onClick={() => {
                                      setcollapseMenu(
                                        collapseMenu === index &&
                                          categoryMenu === categoryIndex
                                          ? -1
                                          : index
                                      );
                                      setcategoryMenu(
                                        collapseMenu === index &&
                                          categoryMenu === categoryIndex
                                          ? -1
                                          : categoryIndex
                                      );
                                    }}
                                  >
                                    <Box className="d-flex align-center info-table-mobile-header">
                                      <Typography className="details-name">
                                        {item?.rnk}
                                      </Typography>
                                      <Box className="table-body-column">
                                        <Box className="d-flex align-center team-wrap-body">
                                          <Typography className="team-name">
                                            {item?.Tnm}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="arrow-details">
                                      <IconButton
                                        aria-label="expand row"
                                        size="small"
                                      >
                                        {collapseMenu === index &&
                                        categoryMenu === categoryIndex ? (
                                          <KeyboardArrowUpIcon />
                                        ) : (
                                          <KeyboardArrowDownIcon />
                                        )}
                                      </IconButton>
                                    </Box>
                                  </Box>
                                  <Collapse
                                    in={
                                      collapseMenu === index &&
                                      categoryMenu === categoryIndex
                                    }
                                    timeout="auto"
                                    unmountOnExit
                                    className="collapse-box collapse-box-bottom"
                                  >
                                    <Box className="collapse-deatils">
                                      <Table className="collapse-table">
                                        <TableRow className="collapse-table-row">
                                          <TableCell className="td-left">
                                            M
                                          </TableCell>
                                          <TableCell className="td-right">
                                            {item?.pld}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow className="collapse-table-row">
                                          <TableCell className="td-left">
                                            W
                                          </TableCell>
                                          <TableCell className="td-right">
                                            {item?.win}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow className="collapse-table-row">
                                          <TableCell className="td-left">
                                            L
                                          </TableCell>
                                          <TableCell className="td-right">
                                            {item?.lstn}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow className="collapse-table-row">
                                          <TableCell className="td-left">
                                            T
                                          </TableCell>
                                          <TableCell className="td-right">
                                            {item?.drw}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow className="collapse-table-row">
                                          <TableCell className="td-left">
                                            N/R
                                          </TableCell>
                                          <TableCell className="td-right">
                                            {item?.nr}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow className="collapse-table-row">
                                          <TableCell className="td-left">
                                            PTS
                                          </TableCell>
                                          <TableCell className="td-right">
                                            {item?.pts}
                                          </TableCell>
                                        </TableRow>

                                        <TableRow className="collapse-table-row">
                                          <TableCell className="td-left">
                                            NRR
                                          </TableCell>
                                          <TableCell className="td-right">
                                            {item?.nrr}
                                          </TableCell>
                                        </TableRow>
                                      </Table>
                                    </Box>
                                  </Collapse>
                                </>
                              ))}
                            </Box>
                          </>
                        );
                      }
                    )}
              </>
            ) : (
              <>
                {/* <NoDataComp /> */}
                <Box className="no-match no-match-boxshadow">
                  {" "}
                  {localesData?.NO_DATA?.NO_TABLE}
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default TableDetails;
