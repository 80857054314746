import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  ClickAwayListener
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Avtar from "../../../../assets/images/avtarPlaceholder.png";
import Follow from "../../../../assets/images/Follow.png";
import Unfollow from "../../../../assets/images/unfollow.png";
import rightArrow from "../../../../assets/images/icons/rightArrow.svg";
import { ReactComponent as Checked } from "src/assets/images/billedChecked.svg";
import { ReactComponent as UnChecked } from "src/assets/images/billedUnChecked.svg";
import { ReactComponent as CheckBoxChecked } from "src/assets/images/checkBoxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/checkboxUnChecked.svg";
import { ReactComponent as TooltipArrow } from "src/assets/images/icons/tooltip_blue_arrow.svg";
import Cvvimage from "../../../../assets/images/cvvimage.png";
import MasterCard from "../../../../assets/images/mastercard.png";
import Visa from "../../../../assets/images/visa.png";
import AmericanExpress from "../../../../assets/images/americanExpress.png";
import PayPal from "../../../../assets/images/payPal.png";
import { ToastContainer, toast } from "react-toastify";
import { Rating } from '@mui/material';
import { fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from "recharts";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import "./tipsCard.scss";
import { useNavigate } from "react-router";
import axiosInstance from "src/helpers/Axios/axiosInstance";

const data = [
  { name: "Jan", MV: 500 },
  { name: "Feb", MV: 3000 },
  { name: "Mar", MV: 1000 },
  { name: "Apr", MV: 2500 },
  { name: "May", MV: 5000 },
  { name: "Jun", MV: 7000 }
];

const TipsCard = ({ tipsData, index, page }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [follow, setFollow] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentMode, setPaymentMode] = useState("");
  const [cardsave, setCardSave] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [addToCart, setAddToCart] = useState(false);
  const [TTopen, setTTOpen] = useState(false);

  const handleCardSave = event => {
    setCardSave(event?.target?.checked);
  };

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const handleTipperFollow = async type => {
    // setIsFollow(type === "unfollow" ? false :  true);
    let payload = {
      type: type,
      toUserId: tipsData?.UserId ?? null
    };
    setFollow(!follow);
    // try {
    //   const { status, data } = await axiosInstance.post(
    //     `/user/follow`,
    //     payload
    //   );
    //   if (status === 200) {
    //     toast.success(data?.message, {
    //       position: "bottom-center",
    //       autoClose: 2000,
    //       hideProgressBar: true,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       theme: "colored"
    //     });
    //   } else {
    //   }
    // } catch (err) {
    //   console.log("error", err);
    //   toast.error(err?.response?.data?.message, {
    //     position: "bottom-center",
    //     autoClose: 2000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     theme: "colored"
    //   });
    // }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box className="custom-tooltip">
          <p>{`Month: ${label}`}</p>
          <p>{`Value: ${payload[0].value}`}</p>
        </Box>
      );
    }

    return null;
  };

  const formatYAxisValue = value => {
    if (value === 0) return "0";
    if (value < 1000) return value;
    if (value > 1000) return `${value / 1000}k`;
    return value;
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const handleClose = () => {
    setPaymentModalOpen(false);
  };

  const handleNavigation = page => {
    if (handleRestrictedUser()) {
      // setPaymentModalOpen(true);
      localStorage.setItem(
        "previous_page",
        window.location.pathname + window.location.search
      );
      navigate(`/${page}/cart-payment`);
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };

  const handlePaymentModeChange = e => {
    setPaymentMode(e?.target?.value);
  };

  const handleOutsideClick = (item, index) => {
    if (hoveredIndex === index) {
      if (tooltipOpen) {
        setTooltipOpen(false);
      }
    }
  };

  const handleTooltipContentClick = (event, index) => {
    event.stopPropagation();
    setTooltipOpen(!tooltipOpen);
    setHoveredIndex(index);
  };

  const handleOpenTooltip = (item, index) => {
    return (
      <Box
        className="custom-tooltip"
        onClick={e => handleTooltipContentClick(e, index)}
      >
        {tipsData?.todayCount && (
          <Typography className="tips-today">
            Today:{" "}
            <span className="tips-number">
              {tipsData?.todayCount ? `${tipsData?.todayCount} Tips` : ""}{" "}
            </span>
          </Typography>
        )}
        {/* <BookIcon className="book-icon" /> */}
        {hoveredIndex === index ? (
          tooltipOpen && (
            <Box
              // className={sportId === "3" ? `tooltip greyhound-tooltip` : `tooltip`}
              className="sport-tooltip"
            >
              <TooltipArrow className="tooltip-arrow" />
              <Typography className="details">[Match]</Typography>

              {/* <Typography className="add-blackbook">
                <AddBookIcon /> {localesData?.BLACKBOOK?.ADD_TO_MY_BLACKBOOK}
              </Typography> */}
              {/* <Box className="black-details">
                {item?.animal ? (
                  <Typography
                    className="details"
                    onClick={() =>
                      handleBlackBookModal(
                        item?.animal?.name,
                        item?.animal?.id,
                        "Runner"
                      )
                    }
                  >
                    Runner: {item?.animal?.name}
                  </Typography>
                ) : (
                  ""
                )}
                {item?.Jockey ? (
                  <Typography
                    className="details"
                    onClick={() =>
                      handleBlackBookModal(
                        item?.Jockey?.name,
                        item?.Jockey?.id,
                        "Jockey"
                      )
                    }
                  >
                    {sportId === "2"
                      ? `${localesData?.RUNNER_TABLE?.DRIVER}: `
                      : `${localesData?.RUNNER_TABLE?.JOCKEY}: `}
                    {item?.Jockey?.name}
                  </Typography>
                ) : (
                  ""
                )}
                {item?.Trainer ? (
                  <Typography
                    className="details"
                    onClick={() =>
                      handleBlackBookModal(
                        item?.Trainer?.name,
                        item?.Trainer?.id,
                        "Trainer"
                      )
                    }
                  >
                    Trainer: {item?.Trainer?.name}
                  </Typography>
                ) : (
                  ""
                )}
              </Box> */}
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
    );
  };
  return (<>
    <Box key={index} className="tips-card">
      <Box className="tips-header">
        <Box className="d-flex align-center">
          <Box className="tips-avatar">
            <img src={Avtar} alt="avtar" />
          </Box>
          <Box className="header-wrap-text">
            <Typography className="header-text bold mb-5">
              {tipsData?.firstName ?? ""} {tipsData?.lastName ?? ""}
            </Typography>
            <Typography className="header-text d-flex align-center mb-5">
              Tipper Ratings:{" "}
              <Rating
                value={tipsData?.tipperRate ?? 0}
                precision={0.5}
                className="rating"
                readOnly
              />
            </Typography>
            <Typography className="header-text d-flex align-center">
              Loyalty Ratings:{" "}
              <Rating
                value={tipsData?.loyaltyRate ?? 0}
                precision={0.5}
                className="rating"
                readOnly
              />
            </Typography>
          </Box>
        </Box>
        <Box
          className="follow-btn"
          onClick={() =>
            handleTipperFollow(
              tipsData?.isFollowed === 1 ? "unfollow" : "follow"
            )
          }
        >
          {follow ? (
            <img src={Unfollow} alt="follow" />
          ) : (
            <img src={Follow} alt="follow" />
          )}
        </Box>
      </Box>
      <Box className="graph">
        <LineChart
          width={
            screenWidth > 1280
              ? 465
              : screenWidth > 1180
              ? 360
              : screenWidth > 1023
              ? 300
              : screenWidth > 590
              ? 465
              : 300
          }
          height={130}
          data={data}
        >
          <XAxis tick={null} />
          <YAxis tickFormatter={formatYAxisValue} tickCount={4} />
          <Line type="monotone" dataKey="MV" stroke="#8884d8" />
          <Tooltip content={<CustomTooltip />} />
        </LineChart>
      </Box>
      <Box className="profit-wrap">
        <Box className="profit-section">
          <Typography className="profit-text">POT:</Typography>
          <Typography className="profit-text profit-text-bold">3%</Typography>
        </Box>
        <Box className="profit-section">
          <Typography className="profit-text">Strike:</Typography>
          <Typography className="profit-text profit-text-bold">
            22%
          </Typography>
        </Box>
        <Box className="profit-section">
          <Typography className="profit-text">Invested:</Typography>
          <Typography className="profit-text profit-text-bold">
            $783,123
          </Typography>
        </Box>
        <Box className="profit-section">
          <Typography className="profit-text">Profit:</Typography>
          <Typography className="profit-text profit-text-bold">
            $21,100
          </Typography>
        </Box>
      </Box>
      <Box className="footer-wrap-text">
        <Box className="comments-wrap">
          <Typography>
            <span>Comments -</span> {tipsData?.comment}
          </Typography>
        </Box>
        <Box className="buy-now-wrap">
          <ClickAwayListener onClickAway={() => handleOutsideClick([], 1)}>
            <Box className="blackbook-icon">{handleOpenTooltip("", 1)}</Box>
          </ClickAwayListener>
          {/* <Typography className="tips-today">
            Today: <span className="tips-number">21 Tips</span>
          </Typography> */}

          {addToCart ? (
            <Button
              className="review-btn"
              disableElevation
              disableFocusRipple
              disableRipple
              endIcon={<img src={rightArrow} alt="arrow" />}
              onClick={() => handleNavigation(page)}
            >
              View in Cart
            </Button>
          ) : (
            <Button
              variant="contained"
              className="buy-now"
              onClick={() => setAddToCart(true)}
            >
              Add to cart <span className="price">$10</span>
            </Button>
          )}
        </Box>
      </Box>
    </Box>
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={paymentModalOpen}
      className="payment-modal"
    >
      <DialogTitle className="modal-title">
        <Typography variant="h6" className="title">
          Payment
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          className="close-icon"
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="payment-details">
        <Box className="payment-select">
          <RadioGroup
            aria-label="payment"
            name="payment"
            className="payment-radioGroup"
            // value={Number(paymentMode)}
          >
            {/* {cardLoader && (
              <div className="allsport-loader-center">
                <Loader />
              </div>
            )} */}
            {/* {!cardLoader &&
              (cardList?.length > 0 ? (
                cardList?.map(item => {
                  return (
                    <>
                      <FormControlLabel
                        key={item.id}
                        className="save-card"
                        value={Number(item.id)}
                        onChange={e => handlePaymentModeChange(e, item)}
                        control={
                          <Radio
                            size="small"
                            icon={<UnChecked className="radio-icon" />}
                            checkedIcon={<Checked className="radio-icon" />}
                            disableRipple
                          />
                        }
                        label=<Box className="save-payment-card-label">
                          <Box>
                            <Typography className="bold">
                              {item?.brand}
                              <span>****{item?.last4}</span>
                            </Typography>
                            <Typography>{item?.cardHolderName}</Typography>
                            <Typography>Expiry - {item?.cardExp}</Typography>
                          </Box>
                          <Box>
                            <span onClick={() => handlecardRemove(item?.id)}>
                              Remove
                            </span>
                          </Box>
                        </Box>
                      />
                    </>
                  );
                })
              ) : (
                <></>
              ))} */}
            <FormControlLabel
              // value={paymentModalOpen}
              // onChange={e => handlePaymentModeChange(e)}
              value="card"
              control={
                <Radio
                  size="small"
                  icon={<UnChecked className="radio-icon" />}
                  checkedIcon={<Checked className="radio-icon" />}
                  disableRipple
                />
              }
              label={
                <Box className="payment-label">
                  <Typography className="label">
                    Credit Or Debit Card
                  </Typography>
                  <Box className="card-section">
                    <img src={MasterCard} alt="card" />
                    <img src={Visa} alt="card" />
                    <img src={AmericanExpress} alt="card" />
                  </Box>
                </Box>
              }
            />
            <FormControlLabel
              // value={paymentModalOpen}
              value="PayPal"
              onChange={e => handlePaymentModeChange(e)}
              control={
                <Radio
                  size="small"
                  icon={<UnChecked className="radio-icon" />}
                  checkedIcon={<Checked className="radio-icon" />}
                  disableRipple
                />
              }
              label={
                <Box className="payment-label">
                  <Typography className="label">PayPal</Typography>
                  <Box className="card-section">
                    <img src={PayPal} alt="card" />
                  </Box>
                </Box>
              }
            />
          </RadioGroup>
        </Box>
        <Box className="card-details">
          <Formik
            initialValues={{
              cardname: "",
              cardNumber: "",
              expirationDate: "",
              cvv: ""
            }}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              cardname: Yup.string().required(
                localesData?.validation?.required_message
              ),
              cardNumber: Yup.string()
                .min(19, "Card number must be at most 16 characters")
                .max(19, "Card number must be at most 16 characters")
                .required("Card number is required"),
              expirationDate: Yup.string()
                .required("Expiration date is required")
                .typeError("Not a valid expiration date. Example: MM/YYYY")
                .max(7, "Not a valid expiration date. Example: MM/YYYY")
                .matches(
                  /([0-9]{2})\/([0-9]{4})/,
                  "Not a valid expiration date. Example: MM/YYYY"
                )
                .test(
                  "is-future",
                  "Card expiration date should be in the future",
                  function(value) {
                    if (!value) return false;
                    const currentDate = new Date();
                    const [month, year] = value.split("/");
                    const cardExpirationDate = new Date(
                      parseInt(year),
                      parseInt(month) - 1
                    );
                    return cardExpirationDate > currentDate;
                  }
                )
                .test(
                  "not-over-100",
                  "Card expiration date should not exceed 100 years in the future",
                  function(value) {
                    if (!value) return false;
                    const currentDate = new Date();
                    const [month, year] = value.split("/");
                    const cardExpirationDate = new Date(
                      parseInt(year),
                      parseInt(month) - 1
                    );
                    return (
                      cardExpirationDate.getFullYear() <=
                      currentDate.getFullYear() + 100
                    );
                  }
                ),
              cvv: Yup.string()
                .min(3)
                .max(3)
                .required("CVV number is required")
            })}
            onSubmit={async requestData => {}}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <Box className="card-form">
                  <Box className="card-info mb-19">
                    <Typography className="textfield-text">
                      Card Number<span className="star">*</span>
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      className="details-textfield"
                      name="cardNumber"
                      error={Boolean(
                        touched?.cardNumber && errors?.cardNumber
                      )}
                      helperText={touched?.cardNumber && errors?.cardNumber}
                      inputProps={{ maxLength: 19 }}
                      onChange={e => {
                        handleChange(e);
                        // setCardUpdate({
                        //   ...cardUpdate,
                        //   card_token: e.target.value
                        // });
                        setFieldValue(
                          "cardNumber",
                          e?.target?.value
                            .replace(/[^\dA-Z*]/g, "")
                            .replace(/(.{4})/g, "$1 ")
                            .trim()
                        );
                      }}
                      value={values?.cardNumber}
                      onKeyPress={e => {
                        if (!/[0-9 ]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onBlur={e => {
                        handleBlur(e);
                        // updateCardDetails(
                        //   "cardNumber",
                        //   values?.cardNumber
                        //     .replace(/[^\dA-Z*]/g, "")
                        //     .replace(/(.{4})/g, "$1 ")
                        //     .trim()
                        // );
                      }}
                    />
                  </Box>
                  <Box className="date-cvv-wrap mb-19">
                    <Box className="card-info date-card-info">
                      <Typography className="textfield-text">
                        Expiry Date<span className="star">*</span>
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="details-textfield"
                        placeholder="mm/yyyy"
                        error={Boolean(
                          touched?.expirationDate && errors?.expirationDate
                        )}
                        helperText={
                          touched?.expirationDate && errors?.expirationDate
                        }
                        name="expirationDate"
                        value={values?.expirationDate}
                        onChange={e => {
                          setFieldValue(
                            "expirationDate",
                            e?.target?.value
                              .replace(/[^0-9]/g, "") // To allow only numbers
                              .replace(/^([2-9])$/g, "0$1") // To handle 3 > 03
                              .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2") // 13 > 01/3
                              .replace(/^0{1,}/g, "0") // To handle 00 > 0
                              .replace(
                                /^([0-1]{1}[0-9]{1})([0-9]{1,4}).*/g,
                                "$1/$2"
                              ) // To handle 113 > 11/3
                          );
                          // setCardUpdate({
                          //   ...cardUpdate,
                          //   card_exp: e.target.value
                          // });
                        }}
                        inputProps={{ maxLength: 7 }}
                        onBlur={e => {
                          handleBlur(e);
                          // updateCardDetails(
                          //   "expirationDate",
                          //   values?.expirationDate
                          // );
                        }}
                      />
                    </Box>
                    <Box className="card-info cvv-card-info">
                      <Typography className="textfield-text cvv-text">
                        CVV <span className="star">*</span>
                        <img src={Cvvimage} alt="cvv" />
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="details-textfield"
                        name="cvv"
                        type="password"
                        error={Boolean(touched?.cvv && errors?.cvv)}
                        helperText={touched?.cvv && errors?.cvv}
                        value={values.cvv}
                        onChange={e => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            handleChange(e);
                          }
                          // setCardUpdate({
                          //   ...cardUpdate,
                          //   cvv: e.target.value
                          // });
                        }}
                        inputProps={{ maxLength: 3 }}
                        onBlur={e => {
                          handleBlur(e);
                          // updateCardDetails("cvv", values?.cvv);
                        }}
                      />
                    </Box>
                  </Box>
                  <Box className="card-info mb-19">
                    <Typography className="textfield-text">
                      Name on Card<span className="star">*</span>
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      className="details-textfield"
                      error={Boolean(touched.cardname && errors.cardname)}
                      helperText={touched.cardname && errors.cardname}
                      name="cardname"
                      value={values.cardname}
                      onChange={e => {
                        handleChange(e);
                        // setCardUpdate({
                        //   ...cardUpdate,
                        //   card_holder_name: e.target.value
                        // });
                      }}
                      onBlur={e => {
                        handleBlur(e);
                        // updateCardDetails("cardname", values?.cardname);
                      }}
                    />
                  </Box>
                  <Box className="checkBox-wrap">
                    <FormControlLabel
                      className="documentsRead-check"
                      control={
                        <Checkbox
                          className="documentsRead-checkbox"
                          checked={cardsave}
                          icon={<CheckboxUnChecked className="radio-icon" />}
                          checkedIcon={
                            <CheckBoxChecked className="radio-icon" />
                          }
                          onChange={e => handleCardSave(e)}
                          color="primary"
                        />
                      }
                      label={
                        <Typography>Save the card for future use</Typography>
                      }
                    />
                  </Box>
                  <Box className="total-amount">
                    <Typography className="bold">Total Cost:</Typography>
                    <Typography>
                      {/* {"$" + Number(planPrice)?.toFixed(2)} */}
                      $10.00
                    </Typography>
                  </Box>
                  <Box className="subscribe-wrap-btn">
                    <Button
                      variant="contained"
                      type="submit"
                      className="subscribe-btn"
                    >
                      Place the Order
                    </Button>
                  </Box>
                  <Box className="subscribe-note">
                    <Typography>
                      Plan renews automatically. You can change or cancel
                      anytime
                    </Typography>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  </>);
};

export default TipsCard;
