import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  IconButton,
  Collapse
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Brisbane from "src/assets/images/brisbane.png";
import { Config } from "../../../../helpers/context/config";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { IntlContext } from "src/App";
import NoDataComp from "src/views/component/UI/NoData";
import Loader from "../../../../components/Loader";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import _ from "lodash";
import SoccerCard from "./soccerCard";
import { soccerTable } from "src/helpers/jsondata";

const OverviewDetails = ({ fetchTableTab }) => {
  const params = useParams();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [collapseMenu, setcollapseMenu] = useState(-1);
  const [categoryMenu, setcategoryMenu] = useState(-1);
  const [overviewUpcomingData, setOverviewUpcomingData] = useState([]);
  const [overviewResultData, setOverviewResultData] = useState([]);
  const [overviewLoading, setOverViewLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [tablePointData, setTablePointData] = useState({});

  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    fetchOverviewData();
    fetchTeamTableData();
  }, [params]);

  const fetchOverviewData = async () => {
    setOverViewLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/soccer/overview/tournament/${params?.tournamentId}?timeZone=${timezone}`
      );
      if (status === 200) {
        setOverviewUpcomingData(data?.result?.upcoming);
        setOverviewResultData(data?.result?.past);
        setOverViewLoading(false);
        fetchTableTab(
          data?.result?.upcoming?.[0]?.tableExists,
          data?.result?.past?.[0]?.tableExists
        );
      } else {
        setOverViewLoading(false);
      }
    } catch (err) {
      setOverViewLoading(false);
    }
  };
  const fetchTeamTableData = async () => {
    setTableLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/soccer/table/tournament/${params?.tournamentId}?isAll=true`
      );
      if (status === 200) {
        const groupedData = _.groupBy(data?.result?.result, "name");
        setTablePointData(groupedData);
        setTableLoading(false);
      } else {
        setTableLoading(false);
      }
    } catch (err) {
      setTableLoading(false);
    }
  };

  const handleNavigate = data => {
    const teamSlug = data?.Tnm
      ? data?.Tnm.trim()
          .toLowerCase()
          .replaceAll(" ", "-")
          .replaceAll("/", "-")
      : "team";
    navigate(
      `/teamsports/${params?.type}/statistics/team/${teamSlug}/${params?.sportId}/${params?.tournamentId}/${data?.teamId}`
    );
  };

  return (
    <>
      <Box className="overview-details-wrap">
        {overviewLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : (
          <>
            {overviewUpcomingData?.length > 0 && (
              <Box className="details-wrap mb-18">
                <Typography className="overview-title">FIXTURES</Typography>
                {overviewUpcomingData?.map((data, index) => {
                  return (
                    <>
                      <SoccerCard
                        theme="light-theme"
                        type={
                          data?.ScoreBoard?.Epr == 0
                            ? "upcoming"
                            : data?.ScoreBoard?.Epr == 2
                            ? "past"
                            : data?.ScoreBoard?.Epr == 1
                            ? "live"
                            : "upcoming"
                        }
                        cardData={data}
                        index={index}
                      />
                    </>
                  );
                })}
              </Box>
            )}
            {overviewResultData?.length > 0 && (
              <Box className="details-wrap mb-18">
                <Typography className="overview-title">RESULTS</Typography>
                {overviewResultData?.map((data, index) => {
                  return (
                    <>
                      <SoccerCard
                        theme="light-theme"
                        type={
                          data?.ScoreBoard?.Epr == 0
                            ? "upcoming"
                            : data?.ScoreBoard?.Epr == 2
                            ? "past"
                            : data?.ScoreBoard?.Epr == 1
                            ? "live"
                            : "upcoming"
                        }
                        cardData={data}
                        index={index}
                      />
                    </>
                  );
                })}
              </Box>
            )}
          </>
        )}
        <Box className="details-wrap mb-18">
          {tableLoading ? (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          ) : (
            <>
              <>
                {Object.keys(tablePointData)?.length > 0 && (
                  <>
                    <Typography className="overview-title">TABLE</Typography>
                    <Box className="short-name-details">
                      <Typography className="short-name">
                        P - <span>Played</span> , W - <span>Win</span>, L -{" "}
                        <span>Loss</span>, D - <span>Draws</span>, F -{" "}
                        <span>Goals For</span>, A - <span>Goals Against</span>,
                        GD - <span>Goals Differnce</span>, PTS -{" "}
                        <span>Points</span>
                      </Typography>
                    </Box>
                    {/* <Box className="d-flex align-center league-name-indicator">
                      <Box className="d-flex align-center col-gap-5">
                        <Box className="league-name-color light-blue"></Box>
                        <Box className="league">Champions League</Box>
                      </Box>
                      <Box className="d-flex align-center col-gap-5">
                        <Box className="league-name-color medium-orange"></Box>
                        <Box className="league">Europa League</Box>
                      </Box>
                      <Box className="d-flex align-center col-gap-5">
                        <Box className="league-name-color dark-red"></Box>
                        <Box className="league">Relegation</Box>
                      </Box>
                    </Box> */}
                    {screenWidth >= 1023
                      ? Object.entries(tablePointData)?.map(
                          ([category, items]) => (
                            <TableContainer
                              id="details-table"
                              className="info-table-cont-wrap"
                            >
                              <Table
                                className="data-table"
                                style={{
                                  minWidth: "max-content",
                                  height: "100%"
                                }}
                              >
                                <TableHead className="table-header">
                                  <TableRow className="table-head-row ">
                                    <TableCell className="text-align-center">
                                      #
                                    </TableCell>
                                    <TableCell>{category}</TableCell>
                                    <TableCell>P</TableCell>
                                    <TableCell>W</TableCell>
                                    <TableCell>D</TableCell>
                                    <TableCell>L</TableCell>
                                    <TableCell>F</TableCell>
                                    <TableCell>A</TableCell>
                                    <TableCell>GD</TableCell>
                                    <TableCell>PTS</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody className="table-body">
                                  {items?.map((item, i) => {
                                    return (
                                      <>
                                        <TableRow
                                          key={i}
                                          className="table-body-row "
                                        >
                                          <TableCell className="body-cell-indicator text-align-center">
                                            {/* <Box
                                              className={
                                                item?.phr?.[0] == 20
                                                  ? "light-blue league-indicator"
                                                  : item?.phr?.[0] == 25
                                                  ? "medium-orange league-indicator"
                                                  : item?.phr?.[0] == 15
                                                  ? "dark-red league-indicator"
                                                  : ""
                                              }
                                            ></Box> */}
                                            {item?.rnk}
                                          </TableCell>
                                          <TableCell>
                                            <Box className="d-flex align-center col-gap-5 table-team-logo">
                                              <Box className="logo-wrap">
                                                <img
                                                  // src={`https://lsm-static-prod.livescore.com/medium/${item?.SoccerTeam?.flag}`}
                                                  src={
                                                    item?.SoccerTeam?.flag
                                                      ? Config.mediaURL +
                                                        item?.SoccerTeam?.flag
                                                      : Brisbane
                                                  }
                                                  alt="logo"
                                                />
                                              </Box>
                                              <Typography
                                                className="soccer-tema-name cursor-pointer"
                                                onClick={() =>
                                                  handleNavigate(item)
                                                }
                                              >
                                                {item?.Tnm}
                                              </Typography>
                                            </Box>
                                          </TableCell>
                                          <TableCell>{item?.pld}</TableCell>
                                          <TableCell>{item?.win}</TableCell>
                                          <TableCell>{item?.drw}</TableCell>
                                          <TableCell>{item?.lst}</TableCell>
                                          <TableCell>{item?.gf}</TableCell>
                                          <TableCell>{item?.ga}</TableCell>
                                          <TableCell>{item?.gd}</TableCell>
                                          <TableCell>{item?.pts}</TableCell>
                                        </TableRow>
                                      </>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )
                        )
                      : Object.entries(tablePointData)?.map(
                          ([category, items], categoryIndex) => {
                            return (
                              <>
                                <Box className="mobile-info-table">
                                  <Box className="d-flex align-center mobile-table-heading">
                                    <Typography
                                      variant="h6"
                                      className="table-heading heading-pos"
                                    >
                                      #
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      className="table-heading"
                                    >
                                      {category}
                                    </Typography>
                                  </Box>
                                  {items?.map((item, index) => (
                                    <>
                                      <Box
                                        className={
                                          collapseMenu !== index ||
                                          categoryMenu !== categoryIndex
                                            ? "info-table-border-bottom info-table-deatils"
                                            : "info-table-deatils"
                                        }
                                        onClick={() => {
                                          setcollapseMenu(
                                            collapseMenu === index &&
                                              categoryMenu === categoryIndex
                                              ? -1
                                              : index
                                          );
                                          setcategoryMenu(
                                            collapseMenu === index &&
                                              categoryMenu === categoryIndex
                                              ? -1
                                              : categoryIndex
                                          );
                                        }}
                                      >
                                        <Box className="d-flex align-center info-table-mobile-header">
                                          <Typography className="details-name">
                                            {/* <Box
                                              className={
                                                item?.phr?.[0] == 20
                                                  ? "light-blue league-indicator"
                                                  : item?.phr?.[0] == 25
                                                  ? "medium-orange league-indicator"
                                                  : item?.phr?.[0] == 15
                                                  ? "dark-red league-indicator"
                                                  : ""
                                              }
                                            ></Box> */}
                                            {item?.rnk}
                                          </Typography>
                                          <Box className="table-body-column">
                                            <Box className="d-flex align-center team-wrap-body">
                                              <Box className="img-wrap">
                                                <img
                                                  src={
                                                    item?.SoccerTeam?.flag
                                                      ? Config.mediaURL +
                                                        item?.SoccerTeam?.flag
                                                      : Brisbane
                                                  }
                                                  alt="logo"
                                                />
                                              </Box>
                                              <Typography
                                                className="team-name cursor-pointer"
                                                onClick={() =>
                                                  handleNavigate(item)
                                                }
                                              >
                                                {item?.Tnm}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className="arrow-details">
                                          <IconButton
                                            aria-label="expand row"
                                            size="small"
                                          >
                                            {collapseMenu === index &&
                                            categoryMenu === categoryIndex ? (
                                              <KeyboardArrowUpIcon />
                                            ) : (
                                              <KeyboardArrowDownIcon />
                                            )}
                                          </IconButton>
                                        </Box>
                                      </Box>
                                      <Collapse
                                        in={
                                          collapseMenu === index &&
                                          categoryMenu === categoryIndex
                                        }
                                        timeout="auto"
                                        unmountOnExit
                                        className="collapse-box collapse-box-bottom"
                                      >
                                        <Box className="collapse-deatils">
                                          <Table className="collapse-table">
                                            <TableRow className="collapse-table-row">
                                              <TableCell className="td-left">
                                                P
                                              </TableCell>
                                              <TableCell className="td-right">
                                                {item?.pld}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow className="collapse-table-row">
                                              <TableCell className="td-left">
                                                W
                                              </TableCell>
                                              <TableCell className="td-right">
                                                {item?.win}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow className="collapse-table-row">
                                              <TableCell className="td-left">
                                                D
                                              </TableCell>
                                              <TableCell className="td-right">
                                                {item?.drw}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow className="collapse-table-row">
                                              <TableCell className="td-left">
                                                L
                                              </TableCell>
                                              <TableCell className="td-right">
                                                {item?.lstn}
                                              </TableCell>
                                            </TableRow>

                                            <TableRow className="collapse-table-row">
                                              <TableCell className="td-left">
                                                F
                                              </TableCell>
                                              <TableCell className="td-right">
                                                {item?.gf}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow className="collapse-table-row">
                                              <TableCell className="td-left">
                                                A
                                              </TableCell>
                                              <TableCell className="td-right">
                                                {item?.ga}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow className="collapse-table-row">
                                              <TableCell className="td-left">
                                                GD
                                              </TableCell>
                                              <TableCell className="td-right">
                                                {item?.gd}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow className="collapse-table-row">
                                              <TableCell className="td-left">
                                                PTS
                                              </TableCell>
                                              <TableCell className="td-right">
                                                {item?.pts}
                                              </TableCell>
                                            </TableRow>
                                          </Table>
                                        </Box>
                                      </Collapse>
                                    </>
                                  ))}
                                </Box>
                              </>
                            );
                          }
                        )}
                  </>
                )}
              </>
            </>
          )}
        </Box>
      </Box>
      {overviewLoading && tableLoading
        ? ""
        : overviewUpcomingData?.length === 0 &&
          overviewResultData?.length === 0 &&
          Object.keys(tablePointData)?.length === 0 && (
            <Box className="no-match no-match-boxshadow">
              {localesData?.NO_DATA?.NO_OVERVIEW}
            </Box>
          )}
    </>
  );
};

export default OverviewDetails;
