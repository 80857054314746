import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Button, Typography, TextField } from "@mui/material";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import DialogBox from "../../UI/dialogBox";
import { IntlContext } from "src/App";
import Loader from "../../../../components/Loader";
import { ReactComponent as BackArrow } from "../../../../assets/images/Back_Arrow.svg";
import moment from "moment";
import "../profile.scss";

function SubscriptionEditDetails(data) {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [newData, setnewData] = useState(data?.data);
  const [isLoading, setLoading] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);

  const handleBack = () => {
    navigate("/profile");
  };

  const handleCancelModal = () => {
    setCancelOpen(true);
  };

  const handleCancelClose = () => {
    setCancelOpen(false);
  };
  const handleSubscriptionPlanDelete = async id => {
    let payload = {
      SubscriptionPlanId: id
    };
    try {
      const { status, data } = await axiosInstance.put(
        `/subscription/cancel-plan`,
        payload
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        navigate("/profile");
        setCancelOpen(false);
      } else {
        toast.error(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored"
      });
    }
  };
  return (
    <>
      <Box>
        <Box className="useredit-details" onClick={() => handleBack()}>
          <span>
            <BackArrow className="back-arrow" />
          </span>
          <h4>{localesData?.EDITPROFILE?.SUBSCRIPTION_DETAILS}</h4>
        </Box>
        {isLoading && (
          <div className="loader_center">
            <Loader />
          </div>
        )}
        <Box className="details subscription-wrap">
          <Typography className="textfield-text subscription-text">
            {localesData?.EDITPROFILE?.SUBSCRIPTION_TYPE}:
          </Typography>
          <Box className="subscription-details">
            {newData?.subscription
              ? newData?.subscription?.SubscriptionPlan?.name
              : ""}
            <span className="plan-amount">
              {newData?.subscription
                ? "(" +
                  "$" +
                  newData?.subscription?.SubscriptionPlan?.amount +
                  "/month" +
                  ")"
                : ""}
            </span>
          </Box>
        </Box>
        <Box className="details subscription-wrap">
          <Typography className="textfield-text subscription-text">
            {localesData?.EDITPROFILE?.STATUS}:
          </Typography>
          <Box className="subscription-details">
            <span
              className={
                newData?.subscription?.status === "active"
                  ? "active plan-status"
                  : "deactive plan-status"
              }
            >
              {newData?.subscription ? newData?.subscription?.status : ""}
            </span>
          </Box>
        </Box>
        <Box className="details subscription-wrap">
          <Typography className="textfield-text subscription-text">
            {localesData?.EDITPROFILE?.RENEWAL_DATE}:
          </Typography>
          <Box className="subscription-details">
            {newData?.subscription
              ? moment(newData?.subscription?.expireAt).format("DD/MM/YYYY")
              : ""}
          </Box>
        </Box>
        <Box className="details subscription-wrap">
          <Typography className="textfield-text subscription-text">
            {localesData?.EDITPROFILE?.SUBSCRIPTION_START_DATE}:
          </Typography>
          <Box className="subscription-details">
            {newData?.subscription
              ? moment(newData?.subscription?.startAt).format("DD/MM/YYYY")
              : ""}
          </Box>
        </Box>
        <Box className="details subscription-wrap">
          <Typography className="textfield-text subscription-text">
            {localesData?.EDITPROFILE?.INITIAL_AMOUNT_PAID}:
          </Typography>
          <Box className="subscription-details">
            {newData?.subscription
              ? moment(newData?.subscription?.startAt).format("DD/MM/YYYY")
              : ""}
          </Box>
        </Box>

        <Box className="profile-button">
          <Box className="profile-btn">
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={() => handleBack()}
            >
              {localesData?.EDITPROFILE?.CANCEL}
            </Button>
            {/* <Button
              variant="contained"
              className="btn-save"
              type="submit"
              // onClick={() => handleSave()}
            >
              {localesData?.EDITPROFILE?.SAVE}
            </Button> */}
          </Box>
        </Box>
        {newData?.subscription?.plateform === "web" && (
          <Box className="cancel-subscription-wrap">
            <Typography
              className={`${
                newData?.subscription?.status === "deactive" ? "disabled" : ""
              } cancel-subscription `}
              onClick={() => handleCancelModal()}
            >
              Cancel Subscription
            </Typography>
          </Box>
        )}
      </Box>
      <DialogBox
        open={cancelOpen}
        onClose={handleCancelClose}
        handleClose={handleCancelClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="delete-title">
                Are you sure, you want to cancel your subscription?
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel"
                  onClick={() => {
                    handleSubscriptionPlanDelete(
                      newData?.subscription?.SubscriptionPlanId
                    );
                  }}
                >
                  Yes
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-save"
                  onClick={() => handleCancelClose()}
                >
                  No
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={true}
      />
      {/* <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> */}
    </>
  );
}

export default SubscriptionEditDetails;
