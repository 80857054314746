import React, { useContext, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import { Box, Button, TextField, Typography, IconButton } from "@mui/material";
import { ReactComponent as VisiblePassword } from "src/assets/images/icons/VisiblePassword.svg";
import { ReactComponent as UnVisiblePassword } from "src/assets/images/icons/UnVisiblePassword.svg";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import { ReactComponent as AuthClose } from "src/assets/images/signup-img/auth-close.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import AuthTitleLogo from "src/assets/images/signup-img/auth-title-logo.webp";
import leftimage from "src/assets/images/signup-img/auth-left.webp";
import logo from "src/assets/images/logo.svg";
import "../auth.scss";
import { useState } from "react";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "src/components/Loader";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function EnterOtp({ handleModal }) {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const [showPassword, setshowPassword] = useState(false);
  const [showRepeatPassword, setshowRepeatPassword] = useState(false);
  const [formvalues, setformvalues] = useState({});
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isOtpError, setOtpError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [link, setLink] = useState(window.location.href);
  const [isOTPVerify, setIsOTPVerify] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const location = useLocation();
  // const { state } = useLocation();,
  const searchParams = new URLSearchParams(window.location.search);
  const emailAddress = searchParams.get("email");

  useEffect(() => {
    if (emailAddress) {
      handleSendOtp(emailAddress);
    }
  }, []);

  const handleSendOtp = async (email) => {
    setisLoading(false);
    try {
      const { status } = await axiosInstance.get(
        `user/forgotPassword/${email}`
      );
      if (status === 200) {
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
      console.log("errormsg", err?.response?.data?.message);
    }
  };

  const handleResetPassword = async () => {
    setisLoading(true);
    let payload = {
      password: formvalues?.password,
      token: authToken,
    };
    try {
      const { status } = await axiosInstance.put(
        `user/resetPassword/${
          link?.includes("verify")
            ? emailAddress
            : location?.state?.forgotPasswordEmail
        }`,
        payload
      );
      if (status === 200) {
        setisLoading(false);
        setPasswordError("");
        toast.success(localesData?.register?.PASSWORD_CHANGED_SUCCESSFULLY, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        navigate("/sign-in");
      }
    } catch (err) {
      setPasswordError(err?.response?.data?.message);
      setisLoading(false);
    }
  };
  const handleVerifyOtp = async () => {
    setisLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        // `user/verifyOtp/${state}/${formvalues?.otp}`
        `user/verifyOtp/${
          link?.includes("verify")
            ? emailAddress
            : location?.state?.forgotPasswordEmail
        }/${formvalues?.otp}?isRegister=0`
      );
      if (status === 200) {
        setisLoading(false);
        setAuthToken(data?.access_token);
        setIsOTPVerify(true);
        // handleResetPassword(data?.access_token);
        setOtpError("");
      }
    } catch (err) {
      console.log("err", err?.response);
      setOtpError(err?.response?.data?.message);
      setisLoading(false);
    }
  };
  const handleResendOtp = async () => {
    setisLoading(true);
    setOtpError("");
    try {
      const { status } = await axiosInstance.get(
        `user/resendOtp/${
          link?.includes("verify")
            ? emailAddress
            : location?.state?.forgotPasswordEmail
        }`
      );
      if (status === 200) {
        setisLoading(false);
        toast.success(localesData?.register?.OTP_SENT, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (err) {
      setisLoading(false);
      console.log("err", err?.response);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const handleClose = () => {
    navigate("/forgot-password", {
      state: {
        otpEmail: link?.includes("verify")
          ? emailAddress
          : location?.state?.forgotPasswordEmail,
        loginEmail: location?.state?.loginEmail,
      },
    });
  };
  return (
    <>
      <Helmet>
        <title>SmartB | Forgot Password ? Get OTP for New Password</title>
        <meta
          name="description"
          content="We will send you an email with instructions to reset your password | SmartB is the best odds comparison platform."
        />
      </Helmet>
      <Box className="auth-close-wrap" onClick={() => handleClose()}>
        <AuthClose />
      </Box>
      <Box className="auth-sec">
        <Box item md={3} lg={4} className="left-sec hide-mobilemenu">
          <img
            src={leftimage}
            alt="left-banner"
            className="image-sec"
            width="100%"
          />
        </Box>
        <Box item xs={12} sm={12} md={9} lg={8} className="right-sec">
          {!isOTPVerify ? (
            <Formik
              enableReinitialize
              initialValues={{
                otp: formvalues?.otp ? formvalues?.otp : "",
              }}
              validationSchema={Yup.object().shape({
                otp: Yup.string()
                  .required(localesData?.validation?.required_message)
                  .matches(/^\d{6}$/, "OTP must be a 6-digit number"),
              })}
              onSubmit={() => handleVerifyOtp()}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  {isLoading ? (
                    <div className="auth-loader">
                      <Loader />
                    </div>
                  ) : (
                    <></>
                  )}
                  {screenWidth > 599 ? (
                    <>
                      <NavLink to="/" className="hide-mobilemenu">
                        <img
                          src={AuthTitleLogo}
                          style={{ maxWidth: " 240.56px" }}
                          className="App-logo-main"
                          alt="logo"
                        />
                      </NavLink>
                      {/* <IconButton
                                          disableRipple
                                          className="dialog-close"
                                          onClick={() => handleClose()}
                                      >
                                          <CloseIcon />
                                      </IconButton> */}
                    </>
                  ) : (
                    <></>
                  )}
                  <Typography className="login-title login-heading">
                    {localesData?.register?.enter_otp}
                  </Typography>
                  <Typography className="otp-content">
                    We have sent an OTP to{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {link?.includes("verify")
                        ? emailAddress
                        : location?.state?.forgotPasswordEmail}
                      .
                    </span>{" "}
                    Please check it and enter the OTP here. If you don't receive
                    an email in your inbox, please check your spam or junk
                    folder
                  </Typography>
                  <Box className="textfield-sec">
                    {/* <Typography className="textfield-text">
                                      {localesData?.register?.otp}
                                  </Typography> */}
                    <TextField
                      placeholder={""}
                      variant="outlined"
                      type="text"
                      name="otp"
                      label="Enter OTP"
                      className="text-field"
                      error={Boolean(touched?.otp && errors?.otp)}
                      helperText={touched?.otp ? errors?.otp : ""}
                      onChange={handleChange}
                      value={values?.otp}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      InputProps={{
                        inputProps: {
                          maxLength: 6,
                        },
                      }}
                      onFocus={() => setOtpError("")}
                    />
                  </Box>

                  {isOtpError && (
                    <span className="text-danger">{isOtpError}</span>
                  )}
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    type="submit"
                    className="submit-btn"
                  >
                    {/* {location?.state?.forgotPasswordEmail
                  ? localesData?.register?.reset_password
                  : "Generate Password"} */}
                    {localesData?.register?.SUBMIT}
                  </Button>
                  <Box className="sign-in-account-sec resend-otp-msg-wrap">
                    <Typography className="account-text">
                      {localesData?.register?.DONT_RECEIEVE_OTP}
                    </Typography>
                    <Typography
                      className="redirect-link "
                      onClick={() => {
                        resetForm();
                        handleResendOtp();
                      }}
                    >
                      {localesData?.register?.RESEND_OTP}
                    </Typography>
                  </Box>
                  {setformvalues(values)}
                </form>
              )}
            </Formik>
          ) : (
            <Formik
              enableReinitialize
              initialValues={{
                password: formvalues?.password ? formvalues?.password : "",
                repeatpassword: formvalues?.repeatpassword
                  ? formvalues?.repeatpassword
                  : "",
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .required(localesData?.validation?.required_message)
                  .min(6, localesData?.validation?.passowrd_length),
                repeatpassword: Yup.string()
                  .required(localesData?.validation?.required_message)
                  .min(6, localesData?.validation?.passowrd_length)
                  .oneOf(
                    [Yup.ref("password"), null],
                    localesData?.validation?.password_confpass_not_match
                  ),
              })}
              onSubmit={() => handleResetPassword()}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  {isLoading ? (
                    <div className="auth-loader">
                      <Loader />
                    </div>
                  ) : (
                    <></>
                  )}
                  {screenWidth > 599 ? (
                    <>
                      <NavLink to="/" className="hide-mobilemenu">
                        <img
                          src={AuthTitleLogo}
                          style={{ maxWidth: " 240.56px" }}
                          className="App-logo-main"
                          alt="logo"
                        />
                      </NavLink>
                      {/* <IconButton
                                      disableRipple
                                      className="dialog-close"
                                      onClick={() => handleClose()}
                                  >
                                      <CloseIcon />
                                  </IconButton> */}
                    </>
                  ) : (
                    <></>
                  )}
                  <Typography className="login-title login-heading">
                    {link?.includes("verify")
                      ? "Generate Password"
                      : localesData?.register?.reset_password}
                  </Typography>

                  <Box className="textfield-sec">
                    {/* <Typography className="textfield-text">
                                  {localesData?.register?.new_password}
                              </Typography> */}
                    <TextField
                      placeholder={""}
                      variant="outlined"
                      label="New password"
                      type={showPassword ? "text" : "password"}
                      error={Boolean(touched?.password && errors?.password)}
                      helperText={touched?.password ? errors?.password : ""}
                      value={values?.password}
                      onChange={(e) => {
                        handleChange(e);
                        setPasswordError("");
                      }}
                      name="password"
                      className="text-field"
                      InputProps={{
                        endAdornment: !showPassword ? (
                          <UnVisiblePassword
                            onClick={() => setshowPassword(!showPassword)}
                          />
                        ) : (
                          <VisiblePassword
                            onClick={() => setshowPassword(!showPassword)}
                          />
                        ),
                      }}
                    />
                  </Box>
                  <Box className="textfield-sec">
                    {/* <Typography className="textfield-text">
                                  {localesData?.register?.repeat_new_password}
                              </Typography> */}
                    <TextField
                      placeholder={""}
                      variant="outlined"
                      label="Repeat password"
                      type={showRepeatPassword ? "text" : "password"}
                      name="repeatpassword"
                      error={Boolean(
                        touched?.repeatpassword && errors?.repeatpassword
                      )}
                      helperText={
                        touched?.repeatpassword ? errors?.repeatpassword : ""
                      }
                      value={values?.repeatpassword}
                      onChange={handleChange}
                      className="text-field"
                      InputProps={{
                        endAdornment: !showRepeatPassword ? (
                          <UnVisiblePassword
                            onClick={() =>
                              setshowRepeatPassword(!showRepeatPassword)
                            }
                          />
                        ) : (
                          <VisiblePassword
                            onClick={() =>
                              setshowRepeatPassword(!showRepeatPassword)
                            }
                          />
                        ),
                      }}
                    />
                  </Box>
                  {passwordError && (
                    <span className="text-danger">{passwordError}</span>
                  )}
                  {/* {screenWidth < 600 && (
                              <Box className="prev-next-btn">
                                  <Button
                                      disableElevation
                                      disableFocusRipple
                                      disableRipple
                                      // type="submit"
                                      className="submit-btn"
                                      onClick={() => handleClose()}
                                  >
                                      {localesData?.register?.auth_btn_prev}
                                  </Button>
                              </Box>
                          )} */}
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    type="submit"
                    className="submit-btn"
                  >
                    {/* {location?.state?.forgotPasswordEmail
              ? localesData?.register?.reset_password
              : "Generate Password"} */}
                    {link?.includes("verify")
                      ? "Generate Password"
                      : localesData?.register?.reset_password}
                  </Button>
                  {setformvalues(values)}
                </form>
              )}
            </Formik>
          )}
        </Box>
        {/* <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> */}
      </Box>
    </>
  );
}
