import React, { useState } from "react";
import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SOCSignupHeader from "./socSignupHeader";
import SOCFeatures from "./socFeatures";
import SOCHowItworks from "./socHowItworks";
import OddsComparisons from "./oddsComparisons";
import SOCBronze from "./socBronze";
import SOCBenefits from "./socBenefits";
import SmartBAppNow from "./smartBAppNow";
import SOCTestimonials from "./socTestimonials";
import OurPartner from "../ourPartners";
import "./socSignupPageV2.scss";
import MembershipPage from "../membershipPage";
import PlanDetails from "../membershipPage/planDetails";
import MembershipFromDetails from "../membershipPage/membershipForm";

const SOCSignupPageV2 = () => {
  const [socMembershipModal, setSOCMembershipModal] = useState(false);

  const handleSOCMemberModalOpen = () => {
    setSOCMembershipModal(true);
  };

  const handleSOCMemberModalClose = () => {
    setSOCMembershipModal(false);
  };
  return (
    <>
      <Box className="soc-signup-section-wrap">
        <SOCSignupHeader />
        <SOCFeatures handleSOCMemberModalOpen={handleSOCMemberModalOpen} />
        <SOCHowItworks />
        <OddsComparisons handleSOCMemberModalOpen={handleSOCMemberModalOpen} />
        <SOCBronze />
        <SOCBenefits handleSOCMemberModalOpen={handleSOCMemberModalOpen} />
        <SmartBAppNow />
        <SOCTestimonials />
        <OurPartner />
      </Box>

      <Dialog
        onClose={() => handleSOCMemberModalClose()}
        aria-labelledby="customized-dialog-title"
        open={socMembershipModal}
        className="SOC-member-modal"
      >
        <IconButton
          aria-label="close"
          onClick={() => handleSOCMemberModalClose()}
          className="close-icon"
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className="SOC-member-modal-details">
          <Box className="member-details-wrap">
            <PlanDetails />
            <MembershipFromDetails />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SOCSignupPageV2;
