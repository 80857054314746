import React from "react";
import Page from "src/components/Page";
// import SOCSignupPage from "src/views/component/socSignupPage";
import SOCSignupPageV2 from "src/views/component/socSignupPageV2";

const SOCSignup = () => {
  return (
    <Page title="SOCSignup">
      {/* <SOCSignupPage /> */}
      <SOCSignupPageV2 />
    </Page>
  );
};

export default SOCSignup;
