import React from "react";
import Page from "src/components/Page";
import SocialSharePage from "src/views/component/socialShare";

const SocialShare = () => {
  return (
    <Page title="social-share">
      <SocialSharePage />
    </Page>
  );
};

export default SocialShare;
