import React from "react";
import Page from "src/components/Page";
import MembershipPage from "src/views/component/membershipPage";

const Membership = () => {
  return (
    <Page title="Membership">
      <MembershipPage />
    </Page>
  );
};

export default Membership;
