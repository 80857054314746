import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  IconButton,
  Typography,
  Button
} from "@mui/material";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import "./ui.scss";
import { useDispatch } from "react-redux";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import AuthTextLogo from "src/assets/images/white-logo.png";

const LoginPopUp = ({
  handleClose,
  open,
  onClose,
  title,
  content,
  closeIcon,
  className
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  return (
    (<Dialog
      className="login-dialog"
      // maxWidth="md"
      onClose={onClose}
      open={open}
      TransitionComponent={Grow}
      scroll={"paper"}
    >
      <Box className="dialog-content-box">
        {// title && closeIcon && (
        //   <DialogTitle>
        //     <Box className="custom-dialog-title">
        //       <Typography variant="h3" className="dialog-title">
        //         {title}
        //         {closeIcon !== false && (
        //           <IconButton className="dialog-close" onClick={handleClose}>
        //             <CloseIcon />
        //           </IconButton>
        //         )}
        //       </Typography>
        //     </Box>
        //   </DialogTitle>
        // )
        closeIcon && (
          <IconButton className="dialog-close" onClick={() => handleClose()} size="large">
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <Box className="login-dialogbox">
        <Typography className="login-title">Log In Required</Typography>
        <Typography className="login-text">
          Please log in with your{" "}
          <img src={AuthTextLogo} className="text-logo" alt="logo" /> account
          for unrestricted access.
        </Typography>

        <Button
          disableElevation
          disableFocusRipple
          disableRipple
          className="login-button"
          onClick={() => {
            navigate("/sign-in");
            dispatch(fetchRestrictedRoute(false));
          }}
        >
          Log In
        </Button>
        <Box>
          {" "}
          <span className="dont-account-text">Don't have an account?</span>{" "}
          <span
            className="signup-text"
            onClick={() => {
              navigate("/sign-up");
              dispatch(fetchRestrictedRoute(false));
            }}
          >
            Sign Up Now
          </span>
        </Box>
        <Box className="login-extra-info">
          <Typography className="tag-line">
            Your ultimate sports ecosystem!
          </Typography>
          <Box className="smartb-feature-wrap">
            {screenWidth > 639 ? (
              <>
                <Typography className="smartb-feature">
                  Smart odds comparison <span className="seperator">|</span>{" "}
                  Daily Tips{" "}
                </Typography>
                <Typography className="smartb-feature">
                  Results <span className="seperator">|</span> News{" "}
                  <span className="seperator">|</span> Stats & info
                </Typography>
                <Typography className="smartb-feature">Live scores</Typography>
              </>
            ) : (
              <>
                <Typography className="smartb-feature">
                  Smart odds comparison
                </Typography>
                <Typography className="smartb-feature">
                  Daily Tips <span className="seperator">|</span> Results{" "}
                  <span className="seperator">|</span> News{" "}
                </Typography>
                <Typography className="smartb-feature">
                  Stats & info <span className="seperator">|</span> Live scores
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>)
  );
};

export default LoginPopUp;
