import React from "react";
import Page from "src/components/Page";
import SportExpertTipsPage from "src/views/component/SportExpertTips";

const SportExpertTips = () => {
  return (
    <Page title="Sports Expert Tips">
      <SportExpertTipsPage />
    </Page>
  );
};

export default SportExpertTips;
