import React, { useEffect, useRef } from "react";
import postscribe from "postscribe";

const DynamicBookkeeperScript = ({ scriptContent }) => {
  const scriptRef = useRef(null);
  useEffect(() => {
    if (scriptRef) {
      postscribe(scriptRef.current, scriptContent);
    }
  }, []);
  return <div ref={scriptRef}></div>;
};
export default DynamicBookkeeperScript;
