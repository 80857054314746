import React, { useEffect, useRef, useState } from "react";
import { Box, Breadcrumbs, Tab, Tabs, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import banner from "../../../assets/images/banner/smart-b-banner-primary.png";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import OurPartner from "../ourPartners";
import { useDispatch, useSelector } from "react-redux";
import AdBannner from "../AdBanner/AdBanner";
import "./ourTeam.scss";
import BackAWinner from "./backAWinner";
import OtherTeams from "./otherTeams";

const teamTitle = [
  { value: 0, label: "Back A Winner Team Members" },
  { value: 1, label: "Other Team Members" },
];

const OurTeamPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabsRef = useRef(null);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const [loader, setLoader] = useState(false);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [allTeamsCount, setAllTeamsCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const rowPerPage = 18;

  useEffect(() => {
    fetchCategoryData();
  }, []);

  useEffect(() => {
    if (categoryData?.length > 0) {
      getAllTeams(categoryId, 0);
    }
  }, [value]);

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  const handleChange = (id, value, teamId) => {
    setValue(id);
    setCategoryId(teamId);
  };

  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = (ReduxAdsData) => {
    let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const getAllTeams = async (category, offset) => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `ourteam?categoryId=${category}&limit=${rowPerPage}&offset=${offset}`,
      );
      if (status === 200) {
        setHasMore(true);
        setLoader(false);
        setAllTeams(data?.result);
        setAllTeamsCount(data?.count);
        setOffset(offset);
      } else {
        setHasMore(false);
        setLoader(false);
      }
    } catch (err) {
      console.log("err", err?.response);
      setLoader(false);
      setHasMore(false);
    }
  };

  const fetchMoreData = async (category, offset) => {
    try {
      const { status, data } = await axiosInstance.get(
        `ourteam?categoryId=${category}&limit=${rowPerPage}&offset=${offset}`,
      );

      if (status === 200) {
        const newItems = data?.result || [];
        setAllTeams([...allTeams, ...newItems]);
        setOffset(offset);
        if (newItems?.length === 0) {
          setHasMore(false);
        }
      }
    } catch (err) {
      console.error("Error fetching more data", err);
      setHasMore(false);
    }
  };

  const fetchCategoryData = async () => {
    setCategoryLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `all/category?withData=1`,
      );
      if (status === 200) {
        // let sortedData = await data?.result?.sort((a, b) => {
        //   return a?.name.localeCompare(b?.name);
        // });
        const newCategoryData = await data?.result?.map((item, index) => {
          return {
            ...item,
            value: index,
          };
        });
        // let alldatas = {
        //   name: "All Our Team",
        //   value: 0,
        //   id: ""
        // };
        // let alldata = [alldatas, ...newCategoryData];
        await setCategoryData(newCategoryData);
        await setCategoryId(newCategoryData?.[0]?.id);
        getAllTeams(newCategoryData?.[0]?.id, 0);
        setCategoryLoading(false);
      } else {
        setCategoryLoading(false);
      }
    } catch (err) {
      setCategoryLoading(false);
    }
  };

  return (
    <>
      <Box className="content-wrap">
        <Box className="mobile-banner">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="our-team-wrap">
          <Box className="team-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  HOME
                </Link>

                <Link underline="hover" color="inherit" to="">
                  Information
                </Link>

                <Typography>Our People</Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">Our People</Typography>
          </Box>
          <Box className="team-details">
            {/* <TextField
              placeholder="Search"
              variant="outlined"
              type="text"
              name="search"
              className="search_input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
            />{" "} */}
            {categoryLoading ? (
              <div style={{ textAlign: "center", width: "100%" }}>
                <Loader />
              </div>
            ) : (
              <Box>
                <Tabs
                  value={value}
                  variant="scrollable"
                  scrollButtons="auto"
                  indicatorColor="primary"
                  textColor="primary"
                  className="team-tab-details"
                  // disableRipple
                  // disableFocusRipple
                  id="team-tab"
                  ref={tabsRef}
                >
                  {categoryData.map((item, index) => {
                    return (
                      <Box key={index}>
                        <Tab
                          label={item?.name}
                          value={item?.value}
                          onChange={() =>
                            handleChange(item?.value, item?.name, item?.id)
                          }
                          className={item?.value == value ? "active" : ""}
                        />
                      </Box>
                    );
                  })}
                </Tabs>
              </Box>
            )}
          </Box>
          <Box className="page-deatils-wrap">
            {/* {value === 0 ? (
              <> */}
            <BackAWinner
              loader={loader}
              allTeams={allTeams}
              getAllTeams={() => getAllTeams(categoryId)}
              fetchMoreData={fetchMoreData}
              hasMore={hasMore}
              offset={offset}
              allTeamsCount={allTeamsCount}
              value={categoryId}
              rowPerPage={rowPerPage}
            />
            {/* </>
            ) : (
              <>
                <OtherTeams
                  loader={loader}
                  allTeams={allTeams}
                  getAllTeams={() => getAllTeams(value)}
                  fetchMoreData={fetchMoreData}
                  hasMore={hasMore}
                  offset={offset}
                  allTeamsCount={allTeamsCount}
                />
              </>
            )} */}
          </Box>
          <OurPartner />
        </Box>
      </Box>
    </>
  );
};

export default OurTeamPage;
