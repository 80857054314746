import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  IconButton,
  Typography
} from "@mui/material";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import { useLocation } from "react-router-dom";
import "./ui.scss";

const DialogBox = ({
  handleClose,
  open,
  onClose,
  title,
  content,
  closeIcon,
  className
}) => {
  const location = useLocation();
  return (
    (<Dialog
      className={`custom-dialog ${className}`}
      // maxWidth="md"
      onClose={onClose}
      open={open}
      TransitionComponent={Grow}
      scroll={"paper"}
    >
      <Box className="dialog-content-box">
        {// title && closeIcon && (
        //   <DialogTitle>
        //     <Box className="custom-dialog-title">
        //       <Typography variant="h3" className="dialog-title">
        //         {title}
        //         {closeIcon !== false && (
        //           <IconButton className="dialog-close" onClick={handleClose}>
        //             <CloseIcon />
        //           </IconButton>
        //         )}
        //       </Typography>
        //     </Box>
        //   </DialogTitle>
        // )
        closeIcon && (
          <IconButton className="dialog-close" onClick={() => handleClose()} size="large">
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      {content}
    </Dialog>)
  );
};

export default DialogBox;
