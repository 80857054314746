const { authRoutes, userRoutes, publicRoute } = require("./routes");

const generateSitemap = () => {
  const routes = [];

  // Add routes from authRoutes
  authRoutes.forEach(route => {
    route.children.forEach(childRoute => {
      routes.push(childRoute.path);
    });
  });

  // Add routes from userRoutes
  userRoutes.forEach(route => {
    route.children.forEach(childRoute => {
      routes.push(childRoute.path);
    });
  });

  // Add routes from publicRoute
  publicRoute.forEach(route => {
    routes.push(route.path);
  });

  // Generate the sitemap XML
  const sitemap = `<?xml version="1.0" encoding="UTF-8"?>
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
        ${routes
          .map(
            route => `<url>
          <loc>https://smartb.com.au${route}</loc>
          <lastmod>${new Date().toISOString().replace(/\.\d+/, "") +
            "+00:00"}</lastmod>
          <changefreq>daily</changefreq>
          <priority>0.8</priority>
        </url>`
          )
          .join("\n")}
      </urlset>`;

  // Log the sitemap
  return sitemap;
};

export default generateSitemap;
