import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Breadcrumbs,
  Button,
  Checkbox,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import banner from "../../../assets/images/banner/smart-b-banner-primary.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import OurPartner from "../ourPartners";
import { useParams } from "react-router-dom";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import Select, { components } from "react-select";
import { ReactComponent as LeftArrow } from "src/assets/images/left-arrow.svg";
import AdBannner from "../AdBanner/AdBanner";
import moment from "moment";
import { ReactComponent as DeleteRed } from "src/assets/images/icons/delete-red.svg";
import { ReactComponent as WhiteLeftArrow } from "src/assets/images/icons/right-arrow-white.svg";
import { ReactComponent as Checked } from "src/assets/images/billedChecked.svg";
import { ReactComponent as UnChecked } from "src/assets/images/billedUnChecked.svg";
import { ReactComponent as CheckBoxChecked } from "src/assets/images/checkBoxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/checkboxUnChecked.svg";
import Cvvimage from "../../../assets/images/cvvimage.png";
import MasterCard from "../../../assets/images/mastercard.png";
import Visa from "../../../assets/images/visa.png";
import AmericanExpress from "../../../assets/images/americanExpress.png";
import PayPal from "../../../assets/images/payPal.png";
import Avatar from "src/assets/images/user-avatar.png";
import NoDataComp from "../UI/NoData";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import "./cartAndPayment.scss";
import PaymentSuccessfulModal from "../UI/paymentSuccessfulModal";

const cartItemData = [
  {
    id: 1,
    name: "D'Silva",
    date: "2023-12-26T11:24:00.000Z",
    ammount: 1000
  },
  {
    id: 2,
    name: "D'Costa",
    date: "2023-12-26T11:24:00.000Z",
    ammount: 10000
  },
  {
    id: 3,
    name: "D'Souza",
    date: "2023-12-26T11:24:00.000Z",
    ammount: 100
  }
];

const CartAndPaymentPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  let [stepperCount, setStepperCount] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [newMessage, setNewMessage] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [cardsave, setCardSave] = useState(false);
  const [paymentConfirmModal, setPaymentConfirmModal] = useState(false);
  const [showCartComp, setShowCartComp] = useState(true);
  const [showPaymentComp, setShowPaymentComp] = useState(false);
  const [isContinued, setIsContinued] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [isCartLoading, setIsCartLoading] = useState(false);

  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleNavigationBack = () => {
    const prevPage = localStorage.getItem("previous_page");
    if (prevPage) {
      setTimeout(() => {
        navigate(prevPage);
        localStorage.removeItem("previous_page");
      }, 1000);
    } else {
      navigate("/");
    }
  };

  const handleCardSave = event => {
    setCardSave(event?.target?.checked);
  };

  const handleClose = () => {
    setPaymentConfirmModal(false);
  };

  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchTipList();
  }, []);

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handlePaymentModeChange = e => {
    setPaymentMode(e?.target?.value);
  };

  const fetchTipList = async () => {
    setIsCartLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/race/tips/634?timezone=${timezone}&startDate=2024-01-09&SportId=`
      );
      if (status === 200) {
        setCartData(data?.result);
        setIsCartLoading(false);
      } else {
        setIsCartLoading(false);
      }
    } catch (err) {
      console.log("error", err);
      setIsCartLoading(false);
    }
  };
  const handleDelete = id => {
    const filteredData = cartData?.filter(item => item?.id !== id);
    setCartData(filteredData);
  };
  // /:page/ccehkotu;
  return (<>
    <Box className="content-wrap">
      <Box className="mobile-banner">
        {fetchPageHeaderAds("218px", banner)}
      </Box>

      <Box className="cart-payment-wrap">
        <Box className="cart-payment-header">
          <Box className="bredcrumn-wrap">
            <Breadcrumbs
              separator="/"
              aria-label="breadcrumb"
              className="breadcrumb"
            >
              <Link underline="hover" color="inherit" to="/">
                HOME
              </Link>

              <Link underline="hover" color="inherit" to="">
                Tipps Marketplace
              </Link>
              <Link underline="hover" color="inherit" to="">
                {params?.page}
              </Link>
              <Typography>Cart & Payment</Typography>
            </Breadcrumbs>
            {/* {screenWidth > 799 ? (
              <></>
            ) : (
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  HOME
                </Link>

                <Link underline="hover" color="inherit" to="">
                  Tipps Marketplace
                </Link>
                <Link underline="hover" color="inherit" to="">
                  {params?.page} Tips
                </Link>
                <Link underline="hover" color="inherit" to="">
                  Cart
                </Link>
                <Link underline="hover" color="inherit" to="">
                  Payment
                </Link>
                <Typography>Payment</Typography>
              </Breadcrumbs>
            )} */}
          </Box>
          {/* {screenWidth > 799 ? ( */}
          <Typography variant="h1">Cart & Payment</Typography>
          {/* ) : (
            <Typography variant="h1">Payment</Typography>
          )} */}
        </Box>
        <Box className="page-deatils-wrap">
          <Box className="back-btn-sec">
            <Button
              className="back-btn"
              disableElevation
              disableFocusRipple
              disableRipple
              startIcon={<LeftArrow />}
              // startIcon={<img src={rightArrow} alt="arrow" />}
              onClick={() => handleNavigationBack(true)}
            >
              Buy more tips
            </Button>
          </Box>
          {/* {screenWidth > 799 ? ( */}
          <>
            <Box className="cart-total-sec">
              <Box className="cart-accordian-wrap">
                <Box className="cart-sec">
                  <Box
                    className="title-sec"
                    onClick={() => setShowCartComp(!showCartComp)}
                  >
                    <Typography className="title">CART</Typography>
                  </Box>
                  {showCartComp ? (
                    <Box className="cart-items-sec">
                      {cartData && cartData?.length > 0 ? (
                        cartData?.map(item => {
                          return (
                            <Box className="cart-item">
                              <Box className="activity-sec">
                                <Box className="user-img">
                                  <img src={Avatar} alt="avatar" />
                                </Box>
                                <Box className="activity-detail">
                                  <Typography className="tip-txt">
                                    Tips from{" "}
                                    <span className="user-name">
                                      {item?.name}
                                    </span>{" "}
                                    for {fetchDayName(item?.Race?.startDate)},{" "}
                                    {moment(item?.Race?.startDate)
                                      .local()
                                      .format("Do MMMM YYYY")}
                                    .
                                  </Typography>
                                  <Typography className="time-txt">
                                    AU ${item?.ammount}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                className="delete-box cursor-pointer"
                                onClick={() => handleDelete(item?.id)}
                              >
                                <DeleteRed />
                              </Box>
                            </Box>
                          );
                        })
                      ) : (
                        <></>
                      )}

                      <Box className="continue-btn-sec">
                        <Button
                          //   variant="contained"
                          className="continue-btn"
                          endIcon={<WhiteLeftArrow />}
                          onClick={() => {
                            setShowPaymentComp(!showPaymentComp);
                            setShowCartComp(!showCartComp);
                            setIsContinued(true);
                          }}
                        >
                          Continue
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
                <Box className="payment-sec">
                  <Box
                    className="title-sec"
                    onClick={() =>
                      isContinued && setShowPaymentComp(!showPaymentComp)
                    }
                  >
                    <Typography className="title">PAYMENT</Typography>
                  </Box>
                  {showPaymentComp && (
                    <Box className="payment-info">
                      <Box className="payment-select">
                        <RadioGroup
                          aria-label="payment"
                          name="payment"
                          className="payment-radioGroup"
                          // value={Number(paymentMode)}
                        >
                          {/* {cardLoader && (
              <div className="allsport-loader-center">
                <Loader />
              </div>
            )} */}
                          {/* {!cardLoader &&
              (cardList?.length > 0 ? (
                cardList?.map(item => {
                  return (
                    <>
                      <FormControlLabel
                        key={item.id}
                        className="save-card"
                        value={Number(item.id)}
                        onChange={e => handlePaymentModeChange(e, item)}
                        control={
                          <Radio
                            size="small"
                            icon={<UnChecked className="radio-icon" />}
                            checkedIcon={<Checked className="radio-icon" />}
                            disableRipple
                          />
                        }
                        label=<Box className="save-payment-card-label">
                          <Box>
                            <Typography className="bold">
                              {item?.brand}
                              <span>****{item?.last4}</span>
                            </Typography>
                            <Typography>{item?.cardHolderName}</Typography>
                            <Typography>Expiry - {item?.cardExp}</Typography>
                          </Box>
                          <Box>
                            <span onClick={() => handlecardRemove(item?.id)}>
                              Remove
                            </span>
                          </Box>
                        </Box>
                      />
                    </>
                  );
                })
              ) : (
                <></>
              ))} */}
                          <FormControlLabel
                            // value={paymentModalOpen}
                            // onChange={e => handlePaymentModeChange(e)}
                            value="card"
                            control={
                              <Radio
                                size="small"
                                icon={<UnChecked className="radio-icon" />}
                                checkedIcon={
                                  <Checked className="radio-icon" />
                                }
                                disableRipple
                              />
                            }
                            label={
                              <Box className="payment-label d-flex">
                                <Typography className="label">
                                  Credit Or Debit Card
                                </Typography>
                                <Box className="card-section">
                                  <img src={MasterCard} alt="card" />
                                  <img src={Visa} alt="card" />
                                  <img src={AmericanExpress} alt="card" />
                                </Box>
                              </Box>
                            }
                          />
                          <FormControlLabel
                            // value={paymentModalOpen}
                            value="PayPal"
                            onChange={e => handlePaymentModeChange(e)}
                            control={
                              <Radio
                                size="small"
                                icon={<UnChecked className="radio-icon" />}
                                checkedIcon={
                                  <Checked className="radio-icon" />
                                }
                                disableRipple
                              />
                            }
                            label=<Box className="payment-label d-flex">
                              <Typography className="label">
                                PayPal
                              </Typography>
                              <Box className="card-section">
                                <img src={PayPal} alt="card" />
                              </Box>
                            </Box>
                          />
                          <FormControlLabel
                            // value={paymentModalOpen}
                            value="SavedCard"
                            onChange={e => handlePaymentModeChange(e)}
                            control={
                              <Radio
                                size="small"
                                icon={<UnChecked className="radio-icon" />}
                                checkedIcon={
                                  <Checked className="radio-icon" />
                                }
                                disableRipple
                              />
                            }
                            label=<Box className="payment-label">
                              <Typography className="label">
                                Standard Debit Mastercard ****1234
                              </Typography>
                              <Typography className="label">
                                John Smith
                              </Typography>
                              <Typography className="label">
                                Expiry - 12/24
                              </Typography>
                              {/* <Box className="card-section">
                        <img src={PayPal} alt="card" />
                      </Box> */}
                            </Box>
                          />
                        </RadioGroup>
                      </Box>
                      <Box className="card-details">
                        <Formik
                          initialValues={{
                            cardname: "",
                            cardNumber: "",
                            expirationDate: "",
                            cvv: ""
                          }}
                          enableReinitialize={true}
                          // validationSchema={Yup.object().shape({
                          //   cardname: Yup.string().required(
                          //     localesData?.validation?.required_message
                          //   ),
                          //   cardNumber: Yup.string()
                          //     .min(19, "Card number must be at most 16 characters")
                          //     .max(19, "Card number must be at most 16 characters")
                          //     .required("Card number is required"),
                          //   expirationDate: Yup.string()
                          //     .required("Expiration date is required")
                          //     .typeError(
                          //       "Not a valid expiration date. Example: MM/YYYY"
                          //     )
                          //     .max(7, "Not a valid expiration date. Example: MM/YYYY")
                          //     .matches(
                          //       /([0-9]{2})\/([0-9]{4})/,
                          //       "Not a valid expiration date. Example: MM/YYYY"
                          //     )
                          //     .test(
                          //       "is-future",
                          //       "Card expiration date should be in the future",
                          //       function(value) {
                          //         if (!value) return false;
                          //         const currentDate = new Date();
                          //         const [month, year] = value.split("/");
                          //         const cardExpirationDate = new Date(
                          //           parseInt(year),
                          //           parseInt(month) - 1
                          //         );
                          //         return cardExpirationDate > currentDate;
                          //       }
                          //     )
                          //     .test(
                          //       "not-over-100",
                          //       "Card expiration date should not exceed 100 years in the future",
                          //       function(value) {
                          //         if (!value) return false;
                          //         const currentDate = new Date();
                          //         const [month, year] = value.split("/");
                          //         const cardExpirationDate = new Date(
                          //           parseInt(year),
                          //           parseInt(month) - 1
                          //         );
                          //         return (
                          //           cardExpirationDate.getFullYear() <=
                          //           currentDate.getFullYear() + 100
                          //         );
                          //       }
                          //     ),
                          //   cvv: Yup.string()
                          //     .min(3)
                          //     .max(3)
                          //     .required("CVV number is required")
                          // })}
                          onSubmit={async requestData => {
                            if (screenWidth > 799) {
                              setPaymentConfirmModal(true);
                            } else {
                              navigate(`/${params?.page}/checkout`);
                            }
                          }}
                        >
                          {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            touched,
                            values,
                            setFieldValue
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <Box className="card-form">
                                <Box className="card-info mb-19">
                                  <Typography className="textfield-text">
                                    Card Number
                                    <span className="star">*</span>
                                  </Typography>
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    className="details-textfield"
                                    name="cardNumber"
                                    error={Boolean(
                                      touched?.cardNumber &&
                                        errors?.cardNumber
                                    )}
                                    helperText={
                                      touched?.cardNumber &&
                                      errors?.cardNumber
                                    }
                                    inputProps={{ maxLength: 19 }}
                                    onChange={e => {
                                      handleChange(e);
                                      // setCardUpdate({
                                      //   ...cardUpdate,
                                      //   card_token: e.target.value
                                      // });
                                      setFieldValue(
                                        "cardNumber",
                                        e?.target?.value
                                          .replace(/[^\dA-Z*]/g, "")
                                          .replace(/(.{4})/g, "$1 ")
                                          .trim()
                                      );
                                    }}
                                    value={values?.cardNumber}
                                    onKeyPress={e => {
                                      if (!/[0-9 ]/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onBlur={e => {
                                      handleBlur(e);
                                      // updateCardDetails(
                                      //   "cardNumber",
                                      //   values?.cardNumber
                                      //     .replace(/[^\dA-Z*]/g, "")
                                      //     .replace(/(.{4})/g, "$1 ")
                                      //     .trim()
                                      // );
                                    }}
                                  />
                                </Box>
                                <Box className="date-cvv-wrap mb-19">
                                  <Box className="card-info date-card-info">
                                    <Typography className="textfield-text">
                                      Expiry Date
                                      <span className="star">*</span>
                                    </Typography>
                                    <TextField
                                      id="outlined-basic"
                                      variant="outlined"
                                      className="details-textfield"
                                      placeholder="mm/yyyy"
                                      error={Boolean(
                                        touched?.expirationDate &&
                                          errors?.expirationDate
                                      )}
                                      helperText={
                                        touched?.expirationDate &&
                                        errors?.expirationDate
                                      }
                                      name="expirationDate"
                                      value={values?.expirationDate}
                                      onChange={e => {
                                        setFieldValue(
                                          "expirationDate",
                                          e?.target?.value
                                            .replace(/[^0-9]/g, "") // To allow only numbers
                                            .replace(/^([2-9])$/g, "0$1") // To handle 3 > 03
                                            .replace(
                                              /^(1{1})([3-9]{1})$/g,
                                              "0$1/$2"
                                            ) // 13 > 01/3
                                            .replace(/^0{1,}/g, "0") // To handle 00 > 0
                                            .replace(
                                              /^([0-1]{1}[0-9]{1})([0-9]{1,4}).*/g,
                                              "$1/$2"
                                            ) // To handle 113 > 11/3
                                        );
                                        // setCardUpdate({
                                        //   ...cardUpdate,
                                        //   card_exp: e.target.value
                                        // });
                                      }}
                                      inputProps={{ maxLength: 7 }}
                                      onBlur={e => {
                                        handleBlur(e);
                                        // updateCardDetails(
                                        //   "expirationDate",
                                        //   values?.expirationDate
                                        // );
                                      }}
                                    />
                                  </Box>
                                  <Box className="card-info cvv-card-info">
                                    <Typography className="textfield-text cvv-text">
                                      CVV <span className="star">*</span>
                                      <img src={Cvvimage} alt="cvv" />
                                    </Typography>
                                    <TextField
                                      id="outlined-basic"
                                      variant="outlined"
                                      className="details-textfield"
                                      name="cvv"
                                      type="password"
                                      error={Boolean(
                                        touched?.cvv && errors?.cvv
                                      )}
                                      helperText={touched?.cvv && errors?.cvv}
                                      value={values.cvv}
                                      onChange={e => {
                                        const re = /^[0-9\b]+$/;
                                        if (
                                          e.target.value === "" ||
                                          re.test(e.target.value)
                                        ) {
                                          handleChange(e);
                                        }
                                        // setCardUpdate({
                                        //   ...cardUpdate,
                                        //   cvv: e.target.value
                                        // });
                                      }}
                                      inputProps={{ maxLength: 3 }}
                                      onBlur={e => {
                                        handleBlur(e);
                                        // updateCardDetails("cvv", values?.cvv);
                                      }}
                                    />
                                  </Box>
                                </Box>
                                <Box className="card-info mb-19">
                                  <Typography className="textfield-text">
                                    Name on Card
                                    <span className="star">*</span>
                                  </Typography>
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    className="details-textfield"
                                    error={Boolean(
                                      touched.cardname && errors.cardname
                                    )}
                                    helperText={
                                      touched.cardname && errors.cardname
                                    }
                                    name="cardname"
                                    value={values.cardname}
                                    onChange={e => {
                                      handleChange(e);
                                      // setCardUpdate({
                                      //   ...cardUpdate,
                                      //   card_holder_name: e.target.value
                                      // });
                                    }}
                                    onBlur={e => {
                                      handleBlur(e);
                                      // updateCardDetails("cardname", values?.cardname);
                                    }}
                                  />
                                </Box>
                                <Box className="checkBox-wrap">
                                  <FormControlLabel
                                    className="documentsRead-check"
                                    control={
                                      <Checkbox
                                        className="documentsRead-checkbox"
                                        checked={cardsave}
                                        icon={
                                          <CheckboxUnChecked className="radio-icon" />
                                        }
                                        checkedIcon={
                                          <CheckBoxChecked className="radio-icon" />
                                        }
                                        onChange={e => handleCardSave(e)}
                                        color="primary"
                                      />
                                    }
                                    label={
                                      <Typography>
                                        Save the card for future use
                                      </Typography>
                                    }
                                  />
                                </Box>
                                {/* <Box className="total-amount">
                          <Typography className="bold">
                            Total Cost:
                          </Typography>
                          <Typography>
                            $10.00
                          </Typography>
                        </Box> */}
                                <Box className="subscribe-wrap-btn">
                                  <Button
                                    variant="contained"
                                    type="submit"
                                    className="subscribe-btn"
                                  >
                                    {/* {screenWidth > 799
                                      ? "Place the Order"
                                      : "Proceed to Pay"} */}
                                    Place the Order
                                  </Button>
                                </Box>
                                {/* <Box className="subscribe-note">
                          <Typography>
                            Plan renews automatically. You can change or
                            cancel anytime
                          </Typography>
                        </Box> */}
                              </Box>
                            </form>
                          )}
                        </Formik>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box className="total-sec">
                <Box className="title-sec">
                  <Typography className="title">CART TOTAL</Typography>
                </Box>
                <Box className="total-ammount-sec">
                  <Typography className="left-txt">
                    Item(s) Subtotal:
                  </Typography>
                  <Typography className="ammount">$20.00</Typography>
                </Box>
                <Box className="total-ammount-sec border-bottom-blue">
                  <Typography className="left-txt">Amount Payable</Typography>
                  <Typography className="ammount">$20.00</Typography>
                </Box>
                <Box className="coupon-sec">
                  <Typography className="gift-txt">
                    Have a Gift Card
                  </Typography>
                  <Box className="send-box">
                    <TextField
                      placeholder="Enter Gift Card Number"
                      variant="outlined"
                      type="text"
                      name="sportoreventother"
                      className="text-field"
                      value={newMessage}
                      onChange={e => setNewMessage(e.target.value)}
                    />
                    <Button
                      className="send-btn"
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      //   onClick={() => newMessage && handleSendMessage("bot")}
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
          {/* ) : (
            <></>
          )} */}

          {/* {screenWidth < 560 ? (
            <Box className="subscribe-wrap-btn w-100">
              <Button
                variant="contained"
                // type="submit"
                className="subscribe-btn"
                onClick={() => setPaymentConfirmModal(true)}
              >
                Place the Order
              </Button>
            </Box>
          ) : (
            <></>
          )} */}
        </Box>
      </Box>
    </Box>
    <OurPartner />
    <PaymentSuccessfulModal
      open={paymentConfirmModal}
      handleClose={handleClose}
      title={""}
      closeIcon={true}
      page={params?.page}
    />
  </>);
};

export default CartAndPaymentPage;
