import React from "react";
import Page from "src/components/Page";
import RecommendedWebsitesPage from "src/views/component/RecommendedWebsites";

const RecommendedWebsites = () => {
  return (
    <Page title="Recommended Websites">
      <RecommendedWebsitesPage />
    </Page>
  );
};

export default RecommendedWebsites;
