import React, { useContext } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Container,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Checkbox,
  StepConnector,
  IconButton,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { ReactComponent as VisiblePassword } from "src/assets/images/icons/VisiblePassword.svg";
import { ReactComponent as UnVisiblePassword } from "src/assets/images/icons/UnVisiblePassword.svg";
import { ReactComponent as CheckBoxChecked } from "src/assets/images/checkBoxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/checkboxUnChecked.svg";
import { ReactComponent as RadioChecked } from "src/assets/images/icons/smartb-check.svg";
import { ReactComponent as RadioUnChecked } from "src/assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Indeterminent } from "src/assets/images/icons/indeterminent-icon.svg";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import { ReactComponent as Football } from "src/assets/images/Sport/football.svg";
import { ReactComponent as Baseball } from "src/assets/images/Sport/baseballLight.svg";
import { ReactComponent as Basketball } from "src/assets/images/Sport/basketballLight.svg";
import { ReactComponent as Boxing } from "src/assets/images/Sport/boxingLight.svg";
import { ReactComponent as Cricket } from "src/assets/images/Sport/cricketLight.svg";
import { ReactComponent as Golf } from "src/assets/images/Sport/golfLight.svg";
import { ReactComponent as IceHockey } from "src/assets/images/Sport/iceHockey-new.svg";
import { ReactComponent as MMA } from "src/assets/images/Sport/mmaIcon-new.svg";
import { ReactComponent as Rugby } from "src/assets/images/Sport/rugby.svg";
import { ReactComponent as Soccer } from "src/assets/images/Sport/soccerIcon-new.svg";
import { ReactComponent as Tennis } from "src/assets/images/Sport/Tennis.svg";
import { ReactComponent as AR } from "src/assets/images/Sport/arIcon-new.svg";
import { ReactComponent as RU } from "src/assets/images/Sport/rugbyUnionIcon-new.svg";
import { ReactComponent as RaceHorses } from "src/assets/images/Sport/horseRacingIcon.svg";
import { NavLink, Link, useNavigate } from "react-router-dom";
import logo from "src/assets/images/logo.svg";
import finish_logo from "src/assets/images/finish-logo.png";
import leftimage from "src/assets/images/signupPage.png";
import leftimage1 from "src/assets/images/signup-img/left-image-1.webp";
import leftimage2 from "src/assets/images/signup-img/left-image-2.webp";
import leftimage3 from "src/assets/images/signup-img/left-image-3.webp";
import leftimage4 from "src/assets/images/signup-img/left-image-4.webp";
import leftimage5 from "src/assets/images/signup-img/left-image-5.webp";
import leftimage6 from "src/assets/images/signup-img/left-image-6.webp";
import "../auth.scss";
import { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
import Select from "react-select";
import { Helmet } from "react-helmet";
import differenceInYears from "date-fns/differenceInYears";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment/moment";
import _ from "lodash";
import { loginUser } from "src/helpers/store/Actions/auth";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "src/components/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScriptLoader from "../../scriptLoader/ScriptLoader";
import { Config, fetchFromStorage } from "src/helpers/context";
import { release } from "src/helpers/context/release";

const titleOptions = [
  { value: "Mr", label: "Mr" },
  { value: "Mrs", label: "Mrs" },
  { value: "Ms", label: "Ms" },
  { value: "Miss", label: "Miss" },
  { value: "Dr", label: "Dr" },
];
const bookmakersList = [
  { id: 1, name: "Bet365" },
  { id: 2, name: "Betgold" },
  { id: 3, name: "PlayUp" },
  { id: 4, name: "Elitebet" },
  { id: 5, name: "UniBet" },
  { id: 6, name: "Gallopbet" },
  { id: 7, name: "TopSport" },
  { id: 8, name: "VICBet" },
  { id: 9, name: "BlueBet" },
  { id: 10, name: "Unchecked" },
  { id: 11, name: "BoomBet" },
  { id: 12, name: "TAB" },
  { id: 13, name: "Southern Cross Bet" },
  { id: 14, name: "SportsBet" },
  { id: 15, name: "Palmerbet" },
  { id: 16, name: "Betfair" },
  { id: 17, name: "Bbet" },
  { id: 18, name: "Winbet" },
  { id: 19, name: "Pointsbet Australia" },
  { id: 20, name: "Other - Please specify" },
];

const hearAboutList = [
  { id: 1, name: "Google" },
  { id: 2, name: "Radio" },
  { id: 3, name: "Social Media" },
  { id: 4, name: "Friend" },
  { id: 5, name: "Subversion" },
  { id: 6, name: "Other" },
];
const sportsList = [
  { id: 1, name: "Horses" },
  { id: 2, name: "Cycling" },
  { id: 3, name: "Netball" },
  { id: 4, name: "Greyhounds" },
  { id: 5, name: "Darts" },
  { id: 6, name: "Novelties and Entertainment" },
  { id: 7, name: "Harness" },
  { id: 8, name: "e - Sports" },
  { id: 9, name: "Politics" },
  { id: 10, name: "American Football" },
  { id: 11, name: "GAA Matches" },
  { id: 12, name: "Rugby League" },
  { id: 13, name: "Australian Rules" },
  { id: 14, name: "Golf" },
  { id: 15, name: "Rugby Union" },
  { id: 16, name: "Baseball" },
  { id: 17, name: "Handball" },
  { id: 18, name: "Snooker" },
  { id: 19, name: "Basketball - Aus/Other" },
  { id: 20, name: "Ice Hockey - Other" },
  { id: 21, name: "Soccer" },
  { id: 22, name: "Basketball - US" },
  { id: 23, name: "Ice Hockey - US" },
  { id: 24, name: "Sports Novelties" },
  { id: 25, name: "Boxing" },
  { id: 26, name: "Lacrosse" },
  { id: 27, name: "Table Tennis" },
  { id: 28, name: "Chess" },
  { id: 29, name: "Megabet" },
  { id: 30, name: "Tennis" },
  { id: 31, name: "Cricket" },
  { id: 32, name: "Motor Racing" },
  { id: 33, name: "UFC - MMA" },
  { id: 34, name: "Other - Please specify" },
];
const otherOffering = [
  { id: 1, name: "Race tips" },
  { id: 2, name: "Sports tips" },
  { id: 3, name: "Benefits and discounts" },
  { id: 4, name: "Customer forums" },
  { id: 5, name: "Racing Stats" },
  { id: 6, name: "Sport Stats" },
  { id: 7, name: "Form Guide" },
  { id: 8, name: "Other - Please specify" },
];
const notificationData = [
  {
    id: 1,
    name: "American Football",
    icon: <Football />,
    SportId: 15,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 2,
    name: "Australian Rules",
    icon: <AR />,
    SportId: 9,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 3,
    name: "Baseball",
    icon: <Baseball />,
    SportId: 11,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 4,
    name: "Basketball",
    icon: <Basketball />,
    SportId: 10,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 5,
    name: "Boxing",
    icon: <Boxing />,
    SportId: 6,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 6,
    name: "Cricket",
    icon: <Cricket />,
    SportId: 4,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 7,
    name: "Golf",
    icon: <Golf />,
    SportId: 16,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 8,
    name: "Ice Hockey",
    icon: <IceHockey />,
    SportId: 17,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 9,
    name: "Mixed Martial Arts",
    icon: <MMA />,
    SportId: 5,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 10,
    name: "Rugby League",
    icon: <Rugby />,
    SportId: 12,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 11,
    name: "Rugby Union",
    icon: <RU />,
    SportId: 13,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 12,
    name: "Soccer",
    icon: <Soccer />,
    SportId: 8,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 13,
    name: "Tennis",
    icon: <Tennis />,
    SportId: 7,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 14,
    name: "Racing",
    icon: <RaceHorses />,
    SportId: [1, 2, 3],
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
];
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#4455c7",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#4455c7",
    },
  },
  line: {
    borderColor: "#D4D6D8",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);
export default function SignUp({ handleModal }) {
  let dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const [showPassword, setshowPassword] = useState(false);
  const [showRepeatPassword, setshowRepeatPassword] = useState(false);
  let [step, setStep] = useState(1);
  // const [title, setTitle] = useState("");
  const [value, setValue] = useState("no");
  const [checkboxValue, setcheckboxValue] = useState([]);
  const [hearAboutData, setHearAboutData] = useState([]);
  const [selectedSports, setselectedSports] = useState([]);
  const [selectedOffering, setselectedOffering] = useState([]);
  const [isFinish, setIsFinish] = useState(false);
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [state, setState] = useState([]);
  const [formvalues, setformvalues] = useState({});
  const [emailmsg, setEmailmsg] = useState("");
  const [count, setcount] = useState(0);
  const [pageCountry, setpageCountry] = useState(0);
  const [statecount, setstatecount] = useState(0);
  const [pageState, setpageState] = useState(0);
  const [isOtp, setOtp] = useState(false);
  const [isOtpError, setOtpError] = useState(false);
  const [isToken, setIsToken] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [searchCountryCount, setsearchCountryCount] = useState("");
  const [searchCountry, setsearchCountry] = useState([]);
  const [isCountrySearch, setisCountrySearch] = useState("");
  const [SearchCountrypage, setSearchCountrypage] = useState(0);
  const [searchStateCount, setsearchStateCount] = useState("");
  const [searchState, setsearchState] = useState([]);
  const [isStateSearch, setisStateSearch] = useState("");
  const [searchStatePage, setsearchStatePage] = useState(0);
  const [isCountrySelectOpen, setisCountrySelectOpen] = useState(false);
  const [countryApiCount, setCountryApiCount] = useState(0);
  const [isStateSelectOpen, setisStateSelectOpen] = useState(false);
  const [stateApiCount, setStateApiCount] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [errorBookmakerOther, seterrorBookmakerOther] = useState("");
  const [errorSportOther, seterrorSportOther] = useState("");
  const [errorBookmakersOption, setErrorBookmakersOption] = useState("");
  const [errorOfferingOther, seterrorOfferingOther] = useState("");
  const [sports, setSports] = useState([]);

  const getSteps = () => {
    return ["step1", "step2", "step3", "step4", "step5"];
  };

  const handleClose = () => {
    let prev_auth_route = localStorage.getItem("prev_authroute");
    navigate(prev_auth_route ? prev_auth_route : "/");
    localStorage.removeItem("prev_authroute");
    dispatch(fetchRestrictedRoute(false));
  };
  const prevStep = () => {
    if (step > 1) {
      step--;
    }
    setStep(step);
  };
  const nextStep = () => {
    if (step < 5) {
      step++;
    }
    setStep(step);

    // if (step === 2) {
    //   if (step < 5) {
    //     step++;
    //   }
    //   setStep(step);
    // }
    // if (step === 3) {
    //   if (step < 5) {
    //     step++;
    //   }
    //   setStep(step);
    // }
    // if (step === 4) {
    //   if (step < 5) {
    //     step++;
    //   }
    //   setStep(step);
    // }
  };
  const submit = async () => {
    setisLoading(true);
    const updatedHearAbout = formvalues?.hearAbout?.map((item) => {
      if (item === "Other") {
        return `other:${formvalues?.hearAboutOther ? formvalues?.hearAboutOther : ""
          }`;
      }
      return item;
    });
    const notificationData =
      (await sports?.length) > 0 &&
      sports?.map((item) => {
        if (item?.id != 1) {
          return {
            SportId: item?.id,
            tips: item?.notificationData?.[0]?.isChecked
              ? item?.notificationData?.[0]?.isChecked
              : false,
            news: item?.notificationData?.[1]?.isChecked
              ? item?.notificationData?.[1]?.isChecked
              : false,
            fixtures: item?.notificationData?.[2]?.isChecked
              ? item?.notificationData?.[2]?.isChecked
              : false,
          };
        } else {
          return {
            SportId: item?.id,
            tips: item?.notificationData?.[0]?.isChecked
              ? item?.notificationData?.[0]?.isChecked
              : false,
            dailyBet: item?.notificationData?.[1]?.isChecked
              ? item?.notificationData?.[1]?.isChecked
              : false,
            news: item?.notificationData?.[2]?.isChecked
              ? item?.notificationData?.[2]?.isChecked
              : false,
            fixtures: item?.notificationData?.[3]?.isChecked
              ? item?.notificationData?.[3]?.isChecked
              : false,
          };
        }
      });
    let sportId1 =
      (await notificationData?.length) > 0 &&
      notificationData?.find((item) => item.SportId === 1);
    if (sportId1) {
      var allSportsNotificationData = [
        ...notificationData,
        { ...sportId1, SportId: 2 },
        { ...sportId1, SportId: 3 },
      ];
    }
    let payload = {
      firstName: formvalues?.firstname,
      lastName: formvalues?.lastname,
      dob: moment(formvalues?.dob).format("YYYY-MM-DD"),
      bookMakerAccount: formvalues?.bookMakerAccount
        ? formvalues?.bookMakerAccount
        : "",
      phone: formvalues?.phonenumber,
      titlePrefix: formvalues?.title,
      address: {
        country: formvalues?.country,
        state: formvalues?.state == "" ? null : formvalues?.state,
      },
      username: formvalues?.email,
      password: formvalues?.password,
      bookMakers: formvalues?.bookMakers ? formvalues?.bookMakers : [],
      sportOrEvent: formvalues?.sportOrEvent ? formvalues?.sportOrEvent : [],
      NotificationPreferences:
        release[Config.release]?.raceSportId?.length > 0
          ? allSportsNotificationData
          : notificationData,
      // offerings: formvalues?.offerings ? formvalues?.offerings : [],
      // offeringsOther: formvalues?.offerings?.includes("Other - Please specify")
      //   ? formvalues?.offeringsOther
      //     ? formvalues?.offeringsOther
      //     : []
      //   : [],
      sportOrEventOther: formvalues?.sportOrEvent?.includes(
        "Other - Please specify"
      )
        ? formvalues?.sportOrEventOther
          ? formvalues?.sportOrEventOther
          : []
        : [],
      bookMakersOther: formvalues?.bookMakers?.includes(
        "Other - Please specify"
      )
        ? formvalues?.bookMakersOther
          ? formvalues?.bookMakersOther
          : []
        : [],
      hearedAbout: updatedHearAbout,
      // wpUserName: formvalues?.wpUserName ? formvalues?.wpUserName : null,
      // referralLink: referral ? referral: null
    };
    const referralCode = localStorage.getItem("referralCode");
    const referralType = localStorage.getItem("referralType");
    try {
      const { status, data } = await axiosInstance.post(
        `user/register?type=${referralType ? referralType : ""}&referralCode=${referralCode ? referralCode : ""
        }`,
        payload
      );
      if (status === 200) {
        setOtp(true);
        toast.success(localesData?.register?.OTP_SENT, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        setisLoading(false);
      } else {
        toast.error("An Error Occurred", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (err) {
      setisLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };
  //step1 function

  //step3 function
  const handleRadioChange = (e) => {
    setValue(e?.target?.value);
    e?.target?.value === "no" && setErrorBookmakersOption("");
    setformvalues({
      ...formvalues,
      bookMakerAccount: e?.target?.value === "yes" ? true : false,
    });
  };
  const handleOnChange = (e) => {
    const { value, checked } = e.target;
    if (checked === true) {
      let checkboxdata = [...checkboxValue, value];
      setcheckboxValue(checkboxdata);
      setformvalues({ ...formvalues, bookMakers: checkboxdata });
    } else {
      let checkboxdata = checkboxValue.filter((element) => element !== value);
      setcheckboxValue(checkboxdata);
      setformvalues({ ...formvalues, bookMakers: checkboxdata });
    }
  };
  const handleOnChangeHearAbout = (e) => {
    const { value, checked } = e.target;
    if (checked === true) {
      let checkboxdata = [...hearAboutData, value];
      setHearAboutData(checkboxdata);
      setformvalues({ ...formvalues, hearAbout: checkboxdata });
    } else {
      let checkboxdata = hearAboutData.filter((element) => element !== value);
      setHearAboutData(checkboxdata);
      setformvalues({ ...formvalues, hearAbout: checkboxdata });
    }
  };
  const handleSelectedSports = (e) => {
    const { value, checked } = e.target;
    if (checked === true) {
      let checkboxdata = [...selectedSports, value];
      setselectedSports(checkboxdata);
      setformvalues({ ...formvalues, sportOrEvent: checkboxdata });
    } else {
      let checkboxdata = selectedSports.filter((element) => element !== value);
      setselectedSports(checkboxdata);
      setformvalues({ ...formvalues, sportOrEvent: checkboxdata });
    }
  };
  const handleOtherOffering = (e) => {
    const { value, checked } = e.target;
    if (checked === true) {
      let checkboxdata = [...selectedOffering, value];
      setselectedOffering(checkboxdata);
      setformvalues({ ...formvalues, offerings: checkboxdata });
    } else {
      let checkboxdata = selectedOffering.filter(
        (element) => element !== value
      );
      setselectedOffering(checkboxdata);
      setformvalues({ ...formvalues, offerings: checkboxdata });
    }
  };
  const ValidateBookmakerOther = () => {
    let flag = true;
    if (
      checkboxValue?.includes("Other - Please specify") &&
      (formvalues?.bookMakersOther
        ? formvalues?.bookMakersOther?.[0] === ""
        : true)
    ) {
      flag = false;
      seterrorBookmakerOther("Please Add Bookmaker");
    } else if (checkboxValue && checkboxValue?.length === 0 && value == "yes") {
      flag = false;
      setErrorBookmakersOption("Please select atleast one bookmaker");
    } else {
      setErrorBookmakersOption("");
      seterrorBookmakerOther("");
    }
    return flag;
  };
  const ValidateSportOther = () => {
    let flag = true;
    if (
      selectedSports?.includes("Other - Please specify") &&
      (formvalues?.sportOrEventOther
        ? formvalues?.sportOrEventOther?.[0] === ""
        : true)
    ) {
      flag = false;
      seterrorSportOther("Please Add Sport or Event");
    } else {
      seterrorSportOther("");
    }
    return flag;
  };
  const ValidateOfferingOther = () => {
    let flag = true;
    if (
      selectedOffering?.includes("Other - Please specify") &&
      (formvalues?.offeringsOther
        ? formvalues?.offeringsOther?.[0] === ""
        : true)
    ) {
      flag = false;
      seterrorOfferingOther("Please Add Offering");
    } else {
      seterrorOfferingOther("");
    }
    return flag;
  };
  const fetchCountry = async (page) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/country?limit=20&offset=${page}`
      );
      if (status === 200) {
        setCountryApiCount(countryApiCount + 1);
        setcount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        const topCountries = [
          {
            label: "Australia",
            value: 13,
          },
          {
            label: "United Kingdom",
            value: 230,
          },
          {
            label: "United States",
            value: 231,
          },
          {
            label: "India",
            value: 101,
          },
        ];

        const nonPrioritizedCountries = data?.result?.rows?.filter(
          (country) => !topCountries?.includes(country?.value)
        );

        let track = nonPrioritizedCountries?.map((item) => {
          newdata.push({
            label: item?.country,
            value: item?.id,
          });
        });
        const orderedPriorityCountries = _.concat(topCountries, newdata);
        const orderedCountries = newdata;
        setCountry(
          _.unionBy(
            country,
            page == 0 ? orderedPriorityCountries : orderedCountries
          )
        );
        let filterData = _.unionBy(
          country,
          page == 0 ? orderedPriorityCountries : orderedCountries
        )?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });

        const finalArray = filterData?.unshift(...topCountries);

        setCountry(
          _.uniqBy(filterData, function (e) {
            return e.value;
          })
        );

        //Old Code

        // let track = data?.result?.rows?.map((item) => {
        //   newdata.push({
        //     label: item?.country,
        //     value: item?.id,
        //   });
        // });

        // setCountry(_.unionBy(country, newdata));
      }
    } catch (err) { }
  };
  const handleOnScrollBottomCountry = () => {
    if (
      isCountrySearch !== "" &&
      searchCountryCount !== Math.ceil(SearchCountrypage / 20)
    ) {
      handleCountryInputChange(SearchCountrypage + 20, isCountrySearch);

      setSearchCountrypage(SearchCountrypage + 20);
    } else {
      if (count !== Math.ceil(pageCountry / 20)) {
        fetchCountry(pageCountry + 20);
        setpageCountry(pageCountry + 20);
      }
    }
  };
  const fetchState = async (id, page, type, statecount) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/state/country/${id}?limit=20&offset=${page}`
      );
      if (status === 200) {
        setpageState(page);
        setStateApiCount(statecount + 1);
        setstatecount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map((item) => {
          newdata.push({
            label: item?.state,
            value: item?.id,
          });
        });
        // setState(_.unionBy(state, newdata))
        if (type) {
          // setState(_.unionBy(newdata));
          let filterData = _.unionBy(newdata);
          let sortData = filterData?.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          setState(
            _.uniqBy(sortData, function (e) {
              return e.value;
            })
          );
        } else {
          // setState(_.unionBy(state, newdata));
          let filterData = _.unionBy(state, newdata);
          let sortData = filterData?.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          setState(
            _.uniqBy(sortData, function (e) {
              return e.value;
            })
          );
        }
      }
    } catch (err) { }
  };
  const handleOnScrollBottomState = () => {
    // if (statecount !== Math.ceil(pageState / 20)) {
    //   fetchState(countryId, pageState + 20, false);
    //   setpageState(pageState + 20);
    // }
    if (
      isStateSearch !== "" &&
      searchStateCount !== Math.ceil(searchStatePage / 20)
    ) {
      handleStateInputChange(searchStatePage + 20, isStateSearch);

      setsearchStatePage(searchStatePage + 20);
    } else {
      if (statecount !== Math.ceil(pageState / 20)) {
        fetchState(countryId, pageState + 20, false, stateApiCount);
        setpageState(pageState + 20);
      }
    }
  };
  const handleCountryInputChange = (page, value) => {
    axiosInstance
      .get(`public/country?limit=20&offset=${page}&search=${value}`)
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchCountryCount(res?.data?.result?.count / 20);

          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.country,
              value: item?.id,
            });
          });
          let filterData = _.unionBy(searchCountry, newdata);
          setsearchCountry(
            _.uniqBy(filterData, function (e) {
              return e.value;
            })
          );
          setisCountrySearch(value);
        }
      });
  };
  const handleStateInputChange = (page, value) => {
    axiosInstance
      .get(
        `public/state/country/${countryId}?limit=20&offset=${page}&search=${value}`
      )
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchStateCount(res?.data?.result?.count / 20);

          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.state,
              value: item?.id,
            });
          });
          const finalStateData = newdata.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          let filterData = _.unionBy(searchState, finalStateData);
          setsearchState(
            _.uniqBy(filterData, function (e) {
              return e.value;
            })
          );
          setisStateSearch(value);
        }
      });
  };
  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isCountrySelectOpen) {
          if (isCountrySearch === "" && countryApiCount !== count) {
            fetchCountry(pageCountry + 20);
            setpageCountry(pageCountry + 20);
          }
        }
      }, 2000);
    }
  }, [isCountrySelectOpen, countryApiCount, isCountrySearch]);

  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isStateSelectOpen) {
          if (isStateSearch === "" && stateApiCount !== statecount) {
            fetchState(countryId, pageState + 20, false, stateApiCount);
            setpageState(pageState + 20);
          }
        }
      }, 2000);
    }
  }, [isStateSelectOpen, stateApiCount, isStateSearch]);
  // const checkEmail = async (email) => {
  //   setisLoading(true);
  //   try {
  //     const { status, data } = await axiosInstance.get(
  //       `user/checkEmail/${email}`
  //     );
  //     if (status === 200) {
  //       setisLoading(false);
  //       if (data?.success === true) {
  //         if (step < 5) {
  //           step++;
  //         }
  //         setStep(step);
  //         setEmailmsg("");
  //       }
  //     }
  //   } catch (err) {
  //     setEmailmsg(err?.response?.data?.message);
  //     setisLoading(false);
  //   }
  // };
  const handleVerifyOtp = async (value) => {
    setisLoading(true);

    try {
      const { status, data } = await axiosInstance.get(
        // `user/verifyOtp/${formvalues?.email}/${value?.otp}`
        `user/verifyOtp/${formvalues?.email}/${value?.otp}?isRegister=1`
      );
      if (status === 200) {
        setisLoading(false);
        let { access_token } = data;
        setIsToken(access_token);
        setIsFinish(true);
        setOtp(false);
        setOtpError("");
      }
    } catch (err) {
      console.log("err", err?.response);
      setOtpError(err?.response?.data?.message);
      setisLoading(false);
    }
  };
  const handleResendOtp = async () => {
    setisLoading(true);
    setOtpError("");
    try {
      const { status, data } = await axiosInstance.get(
        `user/resendOtp/${formvalues?.email}`
      );
      if (status === 200) {
        setisLoading(false);
        toast.success(localesData?.register?.OTP_SENT, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (err) {
      console.log("err", err?.response);
      setisLoading(false);
    }
  };
  const handleHearAboutOther = (e) => {
    setformvalues({ ...formvalues, hearAboutOther: e?.target?.value });
  };
  const handleBookmakerOther = (e) => {
    setformvalues({ ...formvalues, bookMakersOther: [e?.target?.value] });
  };
  const handleSportsOrEventsOther = (e) => {
    setformvalues({ ...formvalues, sportOrEventOther: [e?.target?.value] });
  };
  const handleOfferingsOther = (e) => {
    setformvalues({ ...formvalues, offeringsOther: [e?.target?.value] });
  };

  const handleSportChange = (sportId) => {
    const updatedSports = sports?.map((sport) => {
      if (Array.isArray(sport?.id) && sport?.id.includes(sportId)) {
        const updatedSubscriptions = sport?.notificationData?.map((sub) => ({
          ...sub,
          isChecked: !sub?.isChecked,
        }));
        return {
          ...sport,
          notificationData: updatedSubscriptions,
        };
      } else if (sport?.id === sportId) {
        return {
          ...sport,
          isChecked: !sport?.isChecked,
          isIndeterminate: false,
          notificationData: sport?.notificationData?.map((sub) => ({
            ...sub,
            isChecked: !sport?.isChecked,
          })),
        };
      }
      return sport;
    });

    setSports(updatedSports);
  };
  const handleSubscriptionChange = (sportId, subId) => {
    const updatedSports = sports?.map((sport) => {
      if (sport?.id === sportId) {
        const updatedSubscriptions = sport?.notificationData?.map((sub) => {
          if (sub?.id === subId) {
            return {
              ...sub,
              isChecked: !sub?.isChecked,
            };
          }
          return sub;
        });

        const allChecked = updatedSubscriptions?.every((sub) => sub?.isChecked);
        const someChecked = updatedSubscriptions?.some((sub) => sub?.isChecked);
        const isChecked = allChecked || (!allChecked && someChecked);

        return {
          ...sport,
          notificationData: updatedSubscriptions,
          isChecked,
          isIndeterminate: !allChecked && someChecked,
        };
      }
      return sport;
    });
    setSports(updatedSports);
  };

  const handleFinishRegistration = () => {
    let Type = localStorage.getItem("type");
    let Url = localStorage.getItem("url");
    if (Type && Url) {
      window.location.href = Url;
      localStorage.removeItem("type");
      localStorage.removeItem("url");
      localStorage.setItem("smartb_user", formvalues?.email);
      localStorage.setItem("auth_token", JSON.stringify(isToken));
      dispatch(loginUser(isToken));
      dispatch(fetchRestrictedRoute(false));
    } else {
      let prev_auth_route = localStorage.getItem("prev_authroute");
      localStorage.setItem("auth_token", JSON.stringify(isToken));
      dispatch(loginUser(isToken));
      setTimeout(() => {
        dispatch(fetchRestrictedRoute(false));
        // navigate(prev_auth_route ? prev_auth_route : "/");
        const url = window?.location?.origin;
        window.location.href = prev_auth_route ? url + prev_auth_route : url;
        localStorage.removeItem("prev_authroute");
      }, 1000);
    }
  };
  const fetchSportData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/sport/?sportTypeId=${2}`
      );
      if (status === 200) {
        const releaseFilterSportData = data?.result?.filter((item) =>
          release[Config.release]?.sportId?.includes(item?.id)
        );
        const SportsData = await releaseFilterSportData?.map((item) => {
          return {
            ...item,
            notificationData: [
              { id: 1, subName: "Tips" },
              { id: 2, subName: "News" },
              { id: 3, subName: "Fixtures & Results" },
            ],
          };
        });
        const sortedData = SportsData?.sort((a, b) => {
          return a?.sportName.localeCompare(b?.sportName);
        });
        const mergeData =
          release[Config.release]?.raceSportId?.length > 0
            ? [
              ...sortedData,
              {
                id: 1,
                sportName: "Racing",
                SportId: 1,
                isChecked: true,
                isIndeterminate: true,
                notificationData: [
                  { id: 1, subName: "Tip of the Day", isChecked: true },
                  { id: 4, subName: "Daily Best Bet", isChecked: true },
                  { id: 2, subName: "News" },
                  { id: 3, subName: "Fixtures & Results" },
                ],
              },
            ]
            : [...sortedData];

        setSports(sports?.length > 0 ? sports : mergeData);
      }
    } catch (err) { }
  };
  const fetchSportIcon = (sportId) => {
    if (sportId === 1) {
      return <RaceHorses />;
    } else if (sportId === 15) {
      return <Football />;
    } else if (sportId === 9) {
      return <AR />;
    } else if (sportId === 11) {
      return <Baseball />;
    } else if (sportId === 10) {
      return <Basketball />;
    } else if (sportId === 6) {
      return <Boxing />;
    } else if (sportId === 4) {
      return <Cricket />;
    } else if (sportId === 16) {
      return <Golf />;
    } else if (sportId === 17) {
      return <IceHockey />;
    } else if (sportId === 5) {
      return <MMA />;
    } else if (sportId === 12) {
      return <Rugby />;
    } else if (sportId === 13) {
      return <RU />;
    } else if (sportId === 8) {
      return <Soccer />;
    } else if (sportId === 7) {
      return <Tennis />;
    }
  };
  useEffect(() => {
    fetchCountry(0);
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
    // fetchSportData()
  }, []);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const signupstep1 = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          title:
            formvalues?.title && formvalues?.title !== ""
              ? formvalues?.title
              : "",
          firstname: formvalues?.firstname || "",
          lastname:
            formvalues?.lastname && formvalues?.lastname !== ""
              ? formvalues?.lastname
              : "",
          dob: formvalues?.dob ? formvalues?.dob : null,
          country:
            formvalues?.country && formvalues?.country !== ""
              ? formvalues?.country
              : "",
          state:
            formvalues?.state && formvalues?.state !== ""
              ? formvalues?.state
              : "",
          phonenumber:
            formvalues?.phonenumber && formvalues?.phonenumber !== ""
              ? formvalues?.phonenumber
              : "",
          // wpUserName:
          //   formvalues?.wpUserName && formvalues?.wpUserName !== ""
          //     ? formvalues?.wpUserName
          //     : "",
          hearAboutInfo:
            formvalues?.hearAbout && formvalues?.hearAbout?.length > 0
              ? formvalues?.hearAbout
              : "",
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required(
            localesData?.validation?.required_message
          ),
          firstname: Yup.string()
            .trim()
            .required(localesData?.validation?.required_message)
            .test(
              "firstname",
              "Only letters are allowed for first name",
              (value) =>
                value &&
                value.match(
                  /^\p{L}[\p{L}'.\s]*(?:[\-]{0,1}[\p{L}.'\s]*\p{L}+)*$/gu
                )
            ),
          lastname: Yup.string()
            .trim()
            .required(localesData?.validation?.required_message)
            .test(
              "lastname",
              "Only letters are allowed for last name",
              (value) => value && value.match(/^[\p{L}]+$/gu)
            ),
          dob: Yup.date()
            .nullable()
            .typeError("")
            .test("dob", "You have to be at least 18", function (value) {
              return differenceInYears(new Date(), new Date(value)) >= 18;
            })
            .required(localesData?.validation?.required_message),
          country: Yup.string().required(
            localesData?.validation?.required_message
          ),
          state: Yup.string().required(
            localesData?.validation?.required_message
          ),
          phonenumber: Yup.string()
            .required(localesData?.validation?.required_message)
            .min(10, localesData?.validation?.NUMBER_MIN_MAX_LENGTH)
            .max(12, localesData?.validation?.NUMBER_MIN_MAX_LENGTH),
          //  wpUserName: Yup.string(),
        })}
        validate={(values) => {
          if (!values.hearAboutInfo.length) {
            return {
              hearAboutInfo: "Please Choose Atleast One",
            };
          }
          return {};
        }}
        onSubmit={(reqdata) => {
          nextStep();
          setformvalues({ ...formvalues, ...reqdata });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box className="step1-container">
              {isLoading ? (
                <div className="auth-loader">
                  <Loader />
                </div>
              ) : (
                <></>
              )}
              <Typography className="textfield-text signup-header">
                {localesData?.register?.SIGNUP_WELCOME_MSG}
              </Typography>
              <Box className="textfield-sec">
                <Typography className="textfield-text">
                  {localesData?.register?.title}
                </Typography>
                <Select
                  className={`${touched?.title && errors?.title ? "text-error" : ""
                    } select select-title React`}
                  classNamePrefix="select"
                  isSearchable={false}
                  placeholder="-Select-"
                  onChange={(e) => {
                    setFieldValue("title", e?.value);
                    setformvalues((prevFormValues) => ({
                      ...prevFormValues,
                      title: e?.value,
                    }));
                  }}
                  value={titleOptions?.find((item) => {
                    const titleValue =
                      formvalues?.title && formvalues?.title !== ""
                        ? formvalues?.title
                        : values?.title;
                    return item?.value == titleValue;
                  })}
                  options={titleOptions}
                  name="title"
                />
                <span className="text-danger">
                  {touched?.title && errors?.title ? errors?.title : ""}
                </span>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box className="textfield-sec">
                    <Typography className="textfield-text">
                      {localesData?.register?.first_name}
                    </Typography>
                    <TextField
                      placeholder={""}
                      variant="outlined"
                      type="text"
                      name="firstname"
                      className="text-field"
                      error={Boolean(touched?.firstname && errors?.firstname)}
                      helperText={touched?.firstname ? errors?.firstname : ""}
                      onChange={(e) => {
                        handleChange(e);
                        setformvalues((prevFormValues) => ({
                          ...prevFormValues,
                          [e?.target?.name]: e?.target?.value,
                        }));
                      }}
                      value={values?.firstname}
                      onKeyDown={(e) => {
                        if (!/[\p{L}.' \-]/gu.test(e.key)) e.preventDefault();
                      }}
                    // value={formvalues?.firstname && formvalues?.firstname !== "" ? formvalues?.firstname : values?.firstname}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className="textfield-sec">
                    <Typography className="textfield-text">
                      {localesData?.register?.last_name}
                    </Typography>
                    <TextField
                      placeholder={""}
                      variant="outlined"
                      type="text"
                      name="lastname"
                      className="text-field"
                      error={Boolean(touched?.lastname && errors?.lastname)}
                      helperText={touched?.lastname ? errors?.lastname : ""}
                      onChange={(e) => {
                        handleChange(e);
                        setformvalues((prevFormValues) => ({
                          ...prevFormValues,
                          [e?.target?.name]: e?.target?.value,
                        }));
                      }}
                      // values={formvalues?.lastname && formvalues?.lastname !== "" ? formvalues?.lastname : values?.lastname}
                      value={values?.lastname}
                      onKeyDown={(e) => {
                        if (!/[\p{L}]/gu.test(e.key)) e.preventDefault();
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box className="textfield-sec">
                    <Typography className="textfield-text">
                      {localesData?.register?.dob}
                    </Typography>
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        // disableToolbar
                        disableFuture
                        variant="inline"
                        format="dd/MM/yyyy"
                        placeholder="DD/MM/YYYY"
                        margin="dense"
                        id="date-picker-inline"
                        // openTo="date month year"
                        inputVariant="outlined"
                        // value={selectedDate}
                        // onChange={(e) => handleDateChange(e)}
                        value={formvalues?.dob ? formvalues?.dob : values?.dob}
                        views={["year", "month", "date"]}
                        onChange={(e) => {
                          setFieldValue("dob", e);
                          setformvalues((prevFormValues) => ({
                            ...prevFormValues,
                            dob: e,
                          }));
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        // style={{ marginRight: 5 }}
                        className="details-search-picker"
                      />
                    </MuiPickersUtilsProvider> */}
                    <span className="text-danger">
                      {touched?.dob && errors?.dob ? errors?.dob : ""}
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className="textfield-sec">
                    <Typography className="textfield-text">
                      {localesData?.register?.country_of_residence}
                    </Typography>
                    <Select
                      className="select"
                      classNamePrefix="select"
                      onMenuScrollToBottom={(e) =>
                        handleOnScrollBottomCountry(e)
                      }
                      onInputChange={(e) => handleCountryInputChange(0, e)}
                      placeholder="-Select-"
                      value={
                        isCountrySearch
                          ? searchCountry?.find((item) => {
                            return item?.value == country;
                          })
                          : country?.find((item) => {
                            const countryValue =
                              formvalues?.country &&
                                formvalues?.country !== ""
                                ? formvalues?.country
                                : values?.country;
                            return item?.value == countryValue;
                          })
                      }
                      onChange={(e) => {
                        setFieldValue("country", e?.value);
                        fetchState(e?.value, 0, true, 0);
                        setCountryId(e?.value);
                        setFieldValue("state", "");
                        setState([]);
                        setStateApiCount(0);
                        setpageState(0);
                        setisCountrySelectOpen(false);
                        // setstateValue("");
                        setformvalues((prevFormValues) => ({
                          ...prevFormValues,
                          country: e?.value,
                        }));
                      }}
                      onFocus={() => setisCountrySelectOpen(true)}
                      onBlur={() => setisCountrySelectOpen(false)}
                      options={isCountrySearch ? searchCountry : country}
                    />

                    <span className="text-danger">
                      {touched?.country && errors?.country
                        ? errors?.country
                        : ""}
                    </span>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box className="textfield-sec">
                    <Typography className="textfield-text">
                      {localesData?.register?.state}
                    </Typography>
                    <Select
                      className="select"
                      classNamePrefix="select"
                      onMenuScrollToBottom={(e) => handleOnScrollBottomState(e)}
                      onInputChange={(e) => handleStateInputChange(0, e)}
                      placeholder="-Select-"
                      value={
                        isStateSearch
                          ? searchState?.find((item) => {
                            return item?.value == state;
                          })
                          : (values?.state !== "" ||
                            formvalues?.state !== "") &&
                          state?.find((item) => {
                            const stateValue =
                              formvalues?.state && formvalues?.state !== ""
                                ? formvalues?.state
                                : values?.state;
                            return item?.value == stateValue;
                          })
                      }
                      onChange={(e) => {
                        setFieldValue("state", e?.value);
                        setisStateSelectOpen(false);
                        setStateApiCount(0);
                        // setstateValue(e?.value);
                        setformvalues((prevFormValues) => ({
                          ...prevFormValues,
                          state: e?.value,
                        }));
                      }}
                      onFocus={() => setisStateSelectOpen(true)}
                      onBlur={() => setisStateSelectOpen(false)}
                      options={isStateSearch ? searchState : state}
                    />
                    <span className="text-danger">
                      {touched?.state && errors?.state ? errors?.state : ""}
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className="textfield-sec">
                    <Typography className="textfield-text">
                      {localesData?.register?.phonenumber}
                    </Typography>
                    <TextField
                      placeholder={""}
                      variant="outlined"
                      type="text"
                      name="phonenumber"
                      className="text-field"
                      error={Boolean(
                        touched?.phonenumber && errors?.phonenumber
                      )}
                      helperText={
                        touched?.phonenumber ? errors?.phonenumber : ""
                      }
                      onChange={(e) => {
                        handleChange(e);
                        setformvalues((prevFormValues) => ({
                          ...prevFormValues,
                          [e?.target?.name]: e?.target?.value,
                        }));
                      }}
                      values={
                        formvalues?.phonenumber &&
                          formvalues?.phonenumber !== ""
                          ? formvalues?.phonenumber
                          : values?.phonenumber
                      }
                      value={
                        formvalues?.phonenumber &&
                          formvalues?.phonenumber !== ""
                          ? formvalues?.phonenumber
                          : values?.phonenumber
                      }
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              {/* <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box className="textfield-sec">
                    <Typography className="textfield-text">
                      {localesData?.register?.WP_USER}
                    </Typography>
                    <TextField
                      placeholder={""}
                      variant="outlined"
                      type="text"
                      name="wpUserName"
                      className="text-field"
                      // error={Boolean(touched?.firstname && errors?.firstname)}
                      // helperText={touched?.firstname ? errors?.firstname : ""}
                      onChange={(e) => {
                        handleChange(e);
                        setformvalues((prevFormValues) => ({
                          ...prevFormValues,
                          [e?.target?.name]: e?.target?.value,
                        }));
                      }}
                      value={values?.wpUserName}
                      // value={formvalues?.firstname && formvalues?.firstname !== "" ? formvalues?.firstname : values?.firstname}
                    />
                  </Box>
                </Grid>
              </Grid> */}
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Box className="textfield-sec">
                    <Typography className="textfield-text">
                      How did you hear about us?
                    </Typography>
                  </Box>
                  <Box className="bookmakers-list hear-about-list">
                    <Box className="checkbox-list">
                      <Box className="checkbox-list-1 ">
                        {hearAboutList?.map((element) => (
                          <FormControlLabel
                            className="bookmakers-check"
                            key={element.id}
                            onChange={(e) => {
                              handleChange(e);
                              handleOnChangeHearAbout(e);
                            }}
                            control={
                              <Checkbox
                                className="bookmakers-checkbox"
                                icon={
                                  <CheckboxUnChecked className="radio-icon" />
                                }
                                checkedIcon={
                                  <CheckBoxChecked className="radio-icon" />
                                }
                              />
                            }
                            label={element?.name}
                            value={element?.name}
                            name="hearAboutInfo"
                            checked={hearAboutData?.includes(element?.name)}
                          />
                        ))}
                      </Box>
                    </Box>
                    <span className="text-danger">
                      {touched?.hearAboutInfo && errors?.hearAboutInfo
                        ? errors?.hearAboutInfo
                        : ""}
                    </span>
                    <Box className="other-filed">
                      <Box></Box>
                      <Box></Box>
                      <Box>
                        {hearAboutData?.includes("Other") ? (
                          <Box>
                            <TextField
                              placeholder={""}
                              variant="outlined"
                              type="text"
                              name="bookmakerother"
                              className="text-field"
                              value={formvalues?.hearAboutOther}
                              onChange={(e) => handleHearAboutOther(e)}
                            />
                          </Box>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {screenWidth < 600 && (
                <Box className="prev-next-btn">
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    // type="submit"
                    className="submit-btn"
                    onClick={() => handleClose()}
                  >
                    {localesData?.register?.auth_btn_prev}
                  </Button>
                </Box>
              )}
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                type="submit"
                className="submit-btn step1-btn"
              // onClick={() => {
              //   step < 5 ? nextStep() : submit();
              // }}
              >
                {localesData?.register?.continue}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    );
  };

  const signupstep2 = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          email:
            formvalues?.email && formvalues?.email !== ""
              ? formvalues?.email
              : "",
          password: "",
          repeatpassword: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              localesData?.validation?.valid_email,
            )
            .max(255)
            .required(localesData?.validation?.required_message),
          password: Yup.string()
            .required("You’ll need to set a password to create an account.")
            .min(6, localesData?.validation?.passowrd_length),
          repeatpassword: Yup.string()
            .required("You’ll need to set a password to create an account.")
            .min(6, localesData?.validation?.passowrd_length)
            .oneOf(
              [Yup.ref("password"), null],
              localesData?.validation?.password_confpass_not_match
            ),
        })}
        // onSubmit={(reqdata) => {
        //   checkEmail(reqdata?.email);
        //   setformvalues({ ...formvalues, ...reqdata });
        // }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const { status, data } = await axiosInstance.get(
              `user/checkEmail/${values.email}`
            );
            if (status === 200 && data?.success === true) {
              if (step < 5) {
                step++;
                setStep(step);
              }
              setformvalues({ ...formvalues, ...values });
              setEmailmsg("");
              if (Config.release == "IN") {
                fetchSportData();
              }
            }
            // else {
            //   setEmailmsg(data?.message || "Email is already in use");
            // }
          } catch (err) {
            setEmailmsg(err?.response?.data?.message);
          } finally {
            setSubmitting(false); // Allow the form to be submitted again
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box className="step2-container">
              {isLoading ? (
                <div className="auth-loader">
                  <Loader />
                </div>
              ) : (
                <></>
              )}
              <Typography className="textfield-text signup-header">
                {localesData?.register?.CREATE_LOGIN}
              </Typography>
              <Box className="textfield-sec">
                <Typography className="textfield-text">
                  {localesData?.register?.email}
                </Typography>
                <TextField
                  placeholder={""}
                  variant="outlined"
                  type="text"
                  name="email"
                  className="text-field email-text-field"
                  error={Boolean(touched?.email && errors?.email)}
                  helperText={touched?.email ? errors?.email : ""}
                  onChange={(e) => {
                    handleChange(e);
                    setEmailmsg(""); // Clear the error message when the email input changes
                  }}
                  onBlur={handleBlur}
                  value={values?.email}
                // value={formvalues?.email && formvalues?.email !== "" ? formvalues?.email : values?.email}
                />
                <span className="text-danger">{emailmsg}</span>
              </Box>
              <Box className="textfield-sec">
                <Typography className="textfield-text">
                  {localesData?.register?.password}
                </Typography>
                <TextField
                  placeholder={""}
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  // value={values?.password}
                  // onChange={handleChange}
                  name="password"
                  className="text-field"
                  InputProps={{
                    endAdornment: !showPassword ? (
                      <UnVisiblePassword
                        onClick={() => setshowPassword(!showPassword)}
                      />
                    ) : (
                      <VisiblePassword
                        onClick={() => setshowPassword(!showPassword)}
                      />
                    ),
                  }}
                  error={Boolean(touched?.password && errors?.password)}
                  helperText={touched?.password ? errors?.password : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values?.password}
                />
              </Box>
              <Box className="textfield-sec">
                <Typography className="textfield-text">
                  {localesData?.register?.repeat_password}
                </Typography>
                <TextField
                  placeholder={""}
                  variant="outlined"
                  type={showRepeatPassword ? "text" : "password"}
                  name="repeatpassword"
                  // value={values?.repeatpassword}
                  // onChange={handleChange}
                  className="text-field"
                  InputProps={{
                    endAdornment: !showRepeatPassword ? (
                      <UnVisiblePassword
                        onClick={() =>
                          setshowRepeatPassword(!showRepeatPassword)
                        }
                      />
                    ) : (
                      <VisiblePassword
                        onClick={() =>
                          setshowRepeatPassword(!showRepeatPassword)
                        }
                      />
                    ),
                  }}
                  error={Boolean(
                    touched?.repeatpassword && errors?.repeatpassword
                  )}
                  helperText={
                    touched?.repeatpassword ? errors?.repeatpassword : ""
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values?.repeatpassword}
                />
              </Box>
              <Box className="prev-next-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  // type="submit"
                  className="submit-btn"
                  onClick={() => prevStep()}
                >
                  {localesData?.register?.auth_btn_prev}
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  type="submit"
                  className="submit-btn"
                >
                  {localesData?.register?.auth_btn_next}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    );
  };

  const signupstep3 = () => {
    return (
      <Box className="step3-container">
        {isLoading ? (
          <div className="auth-loader">
            <Loader />
          </div>
        ) : (
          <></>
        )}
        <Typography className="textfield-text">
          {localesData?.register?.HABITS_AND_INTERESTS}
        </Typography>
        <Box className="bookmakers-text">
          <FormLabel component="legend" className="bookmakers-label signup-que">
            {localesData?.register?.ACCOUNTS_BOOKMAKERS}
          </FormLabel>
          <RadioGroup
            aria-label="bookmakers"
            name="bookmakers"
            className="bookmakers-radioGroup"
            value={value}
            onChange={(e) => handleRadioChange(e)}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </Box>
        {value == "yes" ? (
          <Box className="bookmakers-list">
            <FormLabel component="legend" className="bookmakers-list-label">
              {localesData?.register?.BOOKMAKERS_LIST}
            </FormLabel>
            <Box className="checkbox-list">
              <Box className="checkbox-list-1">
                {bookmakersList.map((element) => (
                  <FormControlLabel
                    className="bookmakers-check"
                    key={element.id}
                    onChange={(e) => handleOnChange(e)}
                    control={<Checkbox className="bookmakers-checkbox" />}
                    label={element?.name}
                    value={element?.name}
                    name="fruits"
                    checked={checkboxValue?.includes(element?.name)}
                  />
                ))}
              </Box>
            </Box>
            <Box className="other-filed">
              <Box></Box>
              <Box>
                {checkboxValue?.includes("Other - Please specify") ? (
                  <Box>
                    <TextField
                      placeholder={""}
                      variant="outlined"
                      type="text"
                      name="bookmakerother"
                      className="text-field"
                      value={formvalues?.bookMakersOther}
                      onChange={(e) => handleBookmakerOther(e)}
                    />
                    {errorBookmakerOther ? (
                      <p className="text-danger">{errorBookmakerOther}</p>
                    ) : (
                      ""
                    )}
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          ""
        )}
        <span className="text-danger">
          {errorBookmakersOption && checkboxValue?.length === 0
            ? errorBookmakersOption
            : ""}
        </span>
        <Box className="signup-btn-wrap">
          <Box className="prev-next-btn">
            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              // type="submit"
              className="submit-btn"
              onClick={() => prevStep()}
            >
              {localesData?.register?.auth_btn_prev}
            </Button>
            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              // type="submit"
              className="submit-btn"
              onClick={() => {
                if (ValidateBookmakerOther()) {
                  step < 5 ? nextStep() : submit();
                }
              }}
            >
              {localesData?.register?.auth_btn_next}
            </Button>
          </Box>

          <Button
            className="finish-btn"
            onClick={() => {
              if (ValidateBookmakerOther()) {
                submit();
              }
            }}
          >
            {" "}
            <a>{localesData?.register?.FINISH}</a>
          </Button>
        </Box>
      </Box>
    );
  };

  const signupstep4 = () => {
    return (
      <>
        <Box className="step4-container">
          {isLoading ? (
            <div className="auth-loader">
              <Loader />
            </div>
          ) : (
            <></>
          )}
          <Typography Typography className="textfield-text">
            {localesData?.register?.HABITS_AND_INTERESTS}
          </Typography>
          <Box className="sportslist-wrap">
            <FormLabel
              component="legend"
              className="sportlist-label signup-que"
            >
              {localesData?.register?.INTRESTED_SPORTS}
            </FormLabel>
            <Box className="checkbox-list">
              <Box className="checkbox-list-1">
                {sportsList.map((element) => (
                  <FormControlLabel
                    className="bookmakers-check"
                    key={element.id}
                    onChange={(e) => handleSelectedSports(e)}
                    control={<Checkbox className="bookmakers-checkbox" />}
                    label={element?.name}
                    value={element?.name}
                    // name="fruits"
                    checked={selectedSports?.includes(element?.name)}
                  />
                ))}
              </Box>
            </Box>
            <Box className="other-filed">
              <Box className="sport-field"></Box>
              <Box>
                {selectedSports?.includes("Other - Please specify") ? (
                  <Box>
                    <TextField
                      placeholder={""}
                      variant="outlined"
                      type="text"
                      name="sportoreventother"
                      className="text-field"
                      value={formvalues?.sportOrEventOther}
                      onChange={(e) => handleSportsOrEventsOther(e)}
                    />
                    {errorSportOther ? (
                      <p className="text-danger">{errorSportOther}</p>
                    ) : (
                      ""
                    )}
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Box>
            <Box className="signup-btn-wrap">
              <Box className="prev-next-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  // type="submit"
                  className="submit-btn"
                  onClick={() => prevStep()}
                >
                  {localesData?.register?.auth_btn_prev}
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  // type="submit"
                  className="submit-btn"
                  onClick={() => {
                    if (ValidateSportOther()) {
                      nextStep();
                      fetchSportData();
                    }
                  }}
                >
                  {localesData?.register?.auth_btn_next}
                </Button>
              </Box>
              <Box>
                <Button className="finish-btn" onClick={() => submit()}>
                  {" "}
                  <a>{localesData?.register?.FINISH}</a>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  const signupstep5 = () => {
    return (
      <>
        <Box className="step5-container">
          <>
            {isLoading ? (
              <div className="auth-loader">
                <Loader />
              </div>
            ) : (
              <></>
            )}
            <Typography Typography className="textfield-text">
              {localesData?.register?.HABITS_AND_INTERESTS}
            </Typography>
            <Box className="otherOffering-wrap">
              <FormLabel
                component="legend"
                className="otheroffering-label signup-que"
              >
                {localesData?.register?.OTHER_OFFERING}
              </FormLabel>
              {/* <Box className="checkbox-list">
                    <Box className="checkbox-list-1">
                      {otherOffering.map((element) => (
                        <FormControlLabel
                          className="bookmakers-check"
                          key={element.id}
                          onChange={(e) => handleOtherOffering(e)}
                          control={<Checkbox className="bookmakers-checkbox" />}
                          label={element?.name}
                          value={element?.name}
                          // name="fruits"
                          checked={selectedOffering?.includes(element?.name)}
                        />
                      ))}
                    </Box>
                  </Box>
                  <Box className="other-filed">
                    <Box>
                      {selectedOffering?.includes("Other - Please specify") ? (
                        <Box>
                          <TextField
                            placeholder={""}
                            variant="outlined"
                            type="text"
                            name="offeringsother"
                            className="text-field"
                            value={formvalues?.offeringsOther}
                            onChange={(e) => handleOfferingsOther(e)}
                          />
                          {errorOfferingOther ? (
                            <p className="text-danger">{errorOfferingOther}</p>
                          ) : (
                            ""
                          )}
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box> */}
              <Box className="notification-wrap">
                {sports?.map((sport) => {
                  return (
                    <Box key={sport?.id} className="parent-checkbox">
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<CheckboxUnChecked className="radio-icon" />}
                            checkedIcon={
                              <CheckBoxChecked className="radio-icon" />
                            }
                            indeterminateIcon={<Indeterminent />}
                            checked={
                              sport?.isChecked == true &&
                              sport?.isIndeterminate == false
                            }
                            indeterminate={sport?.isIndeterminate}
                            onChange={() => handleSportChange(sport?.id)}
                          />
                        }
                        label={
                          <Box className="sport-icon-wrap">
                            <span className="sports-name">
                              {sport?.sportName}
                            </span>
                            <span className="sport-icon">
                              {fetchSportIcon(sport?.id)}
                            </span>
                          </Box>
                        }
                      />
                      {sport?.notificationData?.map((sub) => (
                        <Box key={sub?.id} className="child-checkbox p-25">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<RadioUnChecked className="radio-icon" />}
                                checkedIcon={
                                  <RadioChecked className="radio-icon" />
                                }
                                checked={sub?.isChecked === true}
                                onChange={() =>
                                  handleSubscriptionChange(sport?.id, sub?.id)
                                }
                              />
                            }
                            label={sub?.subName}
                          />
                        </Box>
                      ))}
                    </Box>
                  );
                })}
              </Box>
              {screenWidth > 1279 && (
                <Box className="signup-btn-wrap">
                  <Box className="prev-next-btn">
                    <Button
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      // type="submit"
                      className="submit-btn"
                      onClick={() => prevStep()}
                    >
                      {localesData?.register?.auth_btn_prev}
                    </Button>
                    <Button
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      // type="submit"
                      className="submit-btn"
                      disabled={isLoading}
                      onClick={() => {
                        if (ValidateOfferingOther()) {
                          submit();
                        }
                      }}
                    >
                      {localesData?.register?.auth_btn_finish}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </>
        </Box>
        {screenWidth < 1280 && (
          <Box className="signup-btn-wrap step5-signup-btn-wrap">
            <Box className="prev-next-btn">
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                // type="submit"
                className="submit-btn"
                onClick={() => prevStep()}
              >
                {localesData?.register?.auth_btn_prev}
              </Button>
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                // type="submit"
                className="submit-btn"
                disabled={isLoading}
                onClick={() => {
                  if (ValidateOfferingOther()) {
                    submit();
                  }
                }}
              >
                {localesData?.register?.auth_btn_finish}
              </Button>
            </Box>
          </Box>
        )}
      </>
    );
  };

  const renderStep = () => {
    let content = null;
    switch (step) {
      case 1:
        content = signupstep1();
        break;
      case 2:
        content = signupstep2();
        break;
      case 3:
        content = signupstep3();
        break;
      case 4:
        content = signupstep4();
        break;
      case 5:
        content = signupstep5();
        break;
      default:
        content = <div>Error</div>;
        break;
    }
    return content;
  };

  const INRenderStep = () => {
    let content = null;
    switch (step) {
      case 1:
        content = signupstep1();
        break;
      case 2:
        content = signupstep2();
        break;
      case 3:
        content = signupstep5();
        break;
      default:
        content = <div>Error</div>;
        break;
    }
    return content;
  };

  const steps = release[Config.release]?.signStep;

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2500,
  };
  const scriptCode = `
                rdt('track', 'SignUp', {
                  "currency": "USD",
                "transactionId": "12345678",
                "value": 100,
                "products": [
                {
                  "id": "product id 1",
                "name": "product name 1",
                "category": "product category 1"
        },
                // additional products can be added here
                ]
});
                `;
  return (
    <>
      <Helmet>
        <title>
          SmartB | Sign UP Today To Get The Latest News and Odds Data
        </title>
        <meta
          name="description"
          content="Sign up Today. SmartB is the smartest Odds Comparison Platform on the Web. Learn more about Australian sports trends and news.
"
        />
      </Helmet>
      <ScriptLoader scriptCode={scriptCode} />
      <Box className="auth-sec signup-sec">
        <Box item md={3} lg={4} className="left-sec hide-mobilemenu">
          {/* <img
        src={leftimage}
        alt="left image"
        className="image-sec"
        
        width="100%"
      /> */}
          <Slider {...settings} className="our-partner-slider">
            <Box>
              <img
                src={leftimage1}
                alt="left image"
                className="image-sec"
                width="100%"
              />
            </Box>
            <Box>
              <img
                src={leftimage2}
                alt="left image"
                className="image-sec"
                width="100%"
              />
            </Box>
            <Box>
              <img
                src={leftimage3}
                alt="left image"
                className="image-sec"
                width="100%"
              />
            </Box>
            <Box>
              <img
                src={leftimage4}
                alt="left image"
                className="image-sec"
                width="100%"
              />
            </Box>
            <Box>
              <img
                src={leftimage5}
                alt="left image"
                className="image-sec"
                width="100%"
              />
            </Box>
            <Box>
              <img
                src={leftimage6}
                alt="left image"
                className="image-sec"
                width="100%"
              />
            </Box>
          </Slider>
        </Box>
        <Box item xs={12} sm={12} md={9} lg={8} className="right-sec">
          {screenWidth > 599 ? (
            <>
              <NavLink to="/" className="hide-mobilemenu">
                <img
                  src={logo}
                  style={{ maxWidth: " 240.56px" }}
                  className="App-logo-main"
                  alt="logo"
                />
              </NavLink>
              <IconButton
                disableRipple
                className="dialog-close"
                onClick={() => handleClose()}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <></>
          )}
          {!isOtp && !isFinish ? (
            <>
              <Typography className="login-title">
                {localesData?.auth_btn?.signup}
              </Typography>
              <Box className="sign-in-account-sec">
                <Typography className="account-text">
                  {localesData?.register?.already_account}
                </Typography>
                <Link
                  className="redirect-link"
                  // onClick={() => {handleModal("signin");}}
                  to="/sign-in"
                >
                  {localesData?.auth_btn?.signin}
                </Link>
              </Box>
              <Stepper
                activeStep={step - 1}
                alternativeLabel
                className="stepper-label-wrap"
                connector={<QontoConnector />}
              >
                {steps.map((label) => (
                  <Step key={label} className="stepper-label">
                    <StepLabel></StepLabel>
                  </Step>
                ))}
              </Stepper>

              <Container className="stepper-main-wrap">
                {Config.release == "AU" ? renderStep() : INRenderStep()}
              </Container>
            </>
          ) : (
            <>
              {isOtp ? (
                <Box className="otp-msg-wrap">
                  <Formik
                    initialValues={{
                      otp: "",
                    }}
                    validationSchema={Yup.object().shape({
                      otp: Yup.string()
                        .required(localesData?.validation?.required_message)
                        .matches(/^\d{6}$/, "OTP must be a 6-digit number"),
                    })}
                    onSubmit={(values) => handleVerifyOtp(values)}
                  >
                    {({
                      errors,
                      resetForm,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values,
                      setFieldValue,
                      ...formik
                    }) => (
                      <form onSubmit={handleSubmit}>
                        {isLoading ? (
                          <div className="auth-loader">
                            <Loader />
                          </div>
                        ) : (
                          <></>
                        )}
                        <Typography className="login-title login-heading">
                          {localesData?.register?.enter_otp}
                        </Typography>
                        <Typography className="otp-content">
                          We have sent an OTP to{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {formvalues?.email}
                          </span>{" "}
                          Please check it and enter the OTP here
                        </Typography>
                        <Box className="textfield-sec">
                          <Typography className="textfield-text">
                            {localesData?.register?.otp}
                          </Typography>
                          <TextField
                            placeholder={""}
                            variant="outlined"
                            type="text"
                            name="otp"
                            className="text-field"
                            error={Boolean(touched?.otp && errors?.otp)}
                            helperText={touched?.otp ? errors?.otp : ""}
                            onChange={handleChange}
                            value={values?.otp}
                            onKeyPress={(e) => {
                              if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            InputProps={{
                              inputProps: {
                                maxLength: 6,
                              },
                            }}
                            onFocus={() => setOtpError("")}
                          />
                          <span className="text-danger">{isOtpError}</span>
                        </Box>
                        <Typography
                          className="forgot-password-link"
                          onClick={() => {
                            resetForm();
                            handleResendOtp();
                          }}
                        >
                          {localesData?.register?.RESEND_OTP}
                        </Typography>
                        <Button type="submit" className="submit-btn">
                          {localesData?.register?.VERIFY_OTP}
                        </Button>
                      </form>
                    )}
                  </Formik>
                </Box>
              ) : (
                <Box>
                  <Box className="finish-msg-wrap">
                    <img src={finish_logo} alt="finish-logo" />
                    <Typography className="finish-done">
                      {localesData?.register?.ALL_DONE}
                    </Typography>
                    <Typography className="finish-msg">
                      {localesData?.register?.FINISH_MSG}
                    </Typography>

                    <Button
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      // type="submit"
                      className="submit-btn"
                      onClick={() => handleFinishRegistration()}
                    >
                      {localesData?.register?.continue}
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
        {/* <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> */}
      </Box>
    </>
  );
}
