import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Breadcrumbs, Tabs, Tab } from "@mui/material";
import _ from "lodash";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import Select, { components } from "react-select";
import AdvertisingBannner from "../../advertisingBanner";
import OurPartner from "src/views/component/ourPartners";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import AdBannner from "../../AdBanner/AdBanner";
import LeagueSummaryDetails from "./leagueSummaryDetails";
import LeagueCoachDetails from "./leagueCoachDetails";
import LeaguePlayerDetails from "./leaguePlayerDetails";
import LeagueSeasonDetails from "./leagueSeasonDetails";
import LeagueTeamDetails from "./leagueTeamDetails";
import LeagueVenuesDetails from "./leagueVenuesDetails";
import TeamSportNews from "../../allsports/teamSpoorts/allTeamSports/TeamSportNews";
import "./leagueData.scss";

// const newsTitle = [
//   { value: 0, label: "Summary" },
//   { value: 1, label: "Coaches" },
//   { value: 2, label: "Finals" },
//   { value: 3, label: "Players" },
//   { value: 4, label: "Records" },
//   { value: 5, label: "Refrees" },
//   { value: 6, label: "Seasons" },
//   { value: 7, label: "Tables" },
//   { value: 8, label: "Teams" },
//   { value: 9, label: "Venues" },
//   { value: 10, label: "Data" }
// ];

const newsTitle = [
  { value: 0, label: "Summary" },
  { value: 1, label: "Coaches" },
  { value: 2, label: "Players" },
  { value: 3, label: "Seasons" },
  { value: 4, label: "Teams" },
  { value: 5, label: "Venues" },
  { value: 6, label: "News" }
];

const LeagueDataPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const SeasonId = searchParams.get("seasonId");
  const tabsRef = useRef(null);
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [value, setValue] = useState(0);
  const [headerData, setHeaderData] = useState("");
  const [summaryData, setSummaryData] = useState({});
  const [summarySeasonData, setSummarySeasonData] = useState([]);
  const [seasonActive, setSeasonActive] = useState(false);
  const [TeamsAll, setTeamsAll] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState("");
  const [selectedTeamsLabel, setSelectedTeamsLabel] = useState("");
  const [isTeamsSearch, setIsTeamsSearch] = useState("");
  const [isTeamsSelectOpen, setisTeamsSelectOpen] = useState(false);
  const [searchTeams, setsearchTeams] = useState([]);
  const [searchTeamsCount, setsearchTeamsCount] = useState(0);
  const [SearchTeamspage, setSearchTeamspage] = useState(0);
  const [pageTeams, setpageTeams] = useState(0);
  const [TeamsApiCount, setTeamsApiCount] = useState(0);
  const [countVen, setcountVen] = useState(0);
  const [countTeams, setcountTeams] = useState(0);

  const routeName =
    params?.sportId === "4"
      ? "cricket"
      : params?.sportId === "12"
      ? "rugbyleague"
      : params?.sportId === "13"
      ? "rugbyunion"
      : params?.sportId === "10"
      ? "basketball"
      : params?.sportId === "15"
      ? "americanfootball"
      : params?.sportId === "9"
      ? "australianrules"
      : params?.sportId === "16"
      ? "golf"
      : params?.sportId === "7"
      ? "tennis"
      : params?.sportId === "11"
      ? "baseball"
      : params?.sportId === "17"
      ? "icehockey"
      : params?.sportId === "6"
      ? "boxing"
      : params?.sportId === "5"
      ? "mma"
      : params?.sportId === "8"
      ? "soccer"
      : "";

  const handleChange = (id, value) => {
    setValue(id);
  };

  useEffect(() => {
    fetchBreacrumnData();
    fetchSummarydeatils();
    fetchTeamsData(0, Number(params?.sportId), []);
  }, [params, SeasonId]);

  useEffect(() => {
    if (seasonActive) {
      setValue(0);
    }
  }, [SeasonId]);

  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const fetchBreacrumnData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/header?SportId=${params?.sportId}&tournamentId=${
          params?.tournamentId
        }&seasonId=${SeasonId ? SeasonId : ""}`
      );
      if (status === 200) {
        setHeaderData(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const fetchSummarydeatils = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/season/${params?.tournamentId}?SportId=${
          params?.sportId
        }&seasonId=${SeasonId ? SeasonId : ""}`
      );
      if (status === 200) {
        setSummaryData(data?.result?.summaryData);
        setSummarySeasonData(data?.result?.season);
      } else {
      }
    } catch (err) {}
  };

  const handleNavigate = data => {
    setSeasonActive(true);
    navigate(
      `/sport/statistics/${params?.sportId}/${params?.tournamentId}/league-data?seasonId=${data?.id}`
    );
  };
  const fetchTeamsData = async (page, sID, TeamsAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/team?SportId=${sID}&tournamentId=${params?.tournamentId}&offset=${page}&limit=20`
      );
      if (status === 200) {
        // setTeamsApiCount(teamApiCount + 1);
        setcountTeams(Math.ceil(data?.result?.rows?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.name,
            value: item?.id
          });
        });
        let filterData = _.unionBy(TeamsAll, newdata);
        setTeamsAll(
          _.uniqBy(filterData, function(e) {
            return e.value;
          })
        );
      }
    } catch (err) {}
  };
  const handleTeamsInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/team?SportId=${sid}&limit=20&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchTeamsCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const mergeData = _.unionBy(searchTeams, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchTeams(filterData);
          setIsTeamsSearch(value);
        }
      });
  };
  const handleOnScrollBottomTeams = () => {
    if (
      isTeamsSearch !== "" &&
      searchTeamsCount !== Math.ceil(SearchTeamspage / 20)
    ) {
      handleTeamsInputChange(
        SearchTeamspage + 20,
        isTeamsSearch,
        Number(params?.sportId)
      );

      setSearchTeamspage(SearchTeamspage + 20);
    } else {
      if (countTeams !== Math.ceil(pageTeams / 20)) {
        fetchTeamsData(pageTeams + 20, Number(params?.sportId), TeamsAll);
        setpageTeams(pageTeams + 20);
      }
    }
  };
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectIndicator />
      </components.DropdownIndicator>
    );
  };
  return (
    <>
      <Box className="content-wrap">
        {/* <AdvertisingBannner src={banner} /> */}
        <Box className="mobile-banner">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="league-data-wrap">
          <Box className="teamsport-data-header">
            <Box className="bredcrumn-deatils">
              <Box className="bredcrumn-wrap">
                <Breadcrumbs
                  separator="/"
                  aria-label="breadcrumb"
                  className="breadcrumb"
                >
                  <Link underline="hover" color="inherit" to="/">
                    Home
                  </Link>
                  <Link underline="hover" color="inherit" to="#">
                    Sports
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    to={`/teamsports/${routeName}/odds/0/false`}
                  >
                    {headerData?.sport?.sportName}
                  </Link>
                  {/* <Link underline="hover" color="inherit" to="#">
                    [team Sport Data]
                  </Link> */}
                  <Typography>
                    {" "}
                    {SeasonId
                      ? headerData?.season?.name
                      : headerData?.tournament?.name}
                  </Typography>
                </Breadcrumbs>
              </Box>
            </Box>
            <Typography variant="h1">
              {SeasonId
                ? headerData?.season?.name
                : headerData?.tournament?.name}
            </Typography>
          </Box>
          <Box className="teamsport-data-tab">
            <Tabs
              value={value}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              className="teamsport-data-tab-details"
              // disableRipple
              // disableFocusRipple
              id="news-tab"
              ref={tabsRef}
            >
              {newsTitle.map((item, index) => {
                return (
                  <Box key={index}>
                    <Tab
                      label={item?.label}
                      value={item?.value}
                      onChange={() => handleChange(item?.value, item?.label)}
                      className={item?.value == value ? "active" : ""}
                    />
                  </Box>
                );
              })}
            </Tabs>
            {value === 0 ||
            value === 1 ||
            value === 2 ||
            value === 3 ||
            value === 4 ||
            value === 5 ? (
              <>
                <Box className="tab-deatils">
                  {/* <Typography className="tab-content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    fermentum consequat est, rhoncus scelerisque urna cursus ut.
                    Morbi pharetra mi ac ex mollis tristique.{" "}
                  </Typography> */}
                  <Box className="tab-details-grid">
                    {params?.sportId != 12 && (
                      <>
                        <Box className="details-box">
                          <Typography className="details-text bold">
                            Current Premiers:
                          </Typography>
                          <Typography className="details-text">
                            {summaryData?.currentPremiers
                              ? summaryData?.currentPremiers
                              : "-"}
                          </Typography>
                        </Box>
                        <Box className="details-box">
                          <Typography className="details-text bold">
                            Year Commenced:
                          </Typography>
                          <Typography className="details-text">
                            {" "}
                            {summaryData?.yearCommenced
                              ? summaryData?.yearCommenced
                              : "-"}
                          </Typography>
                        </Box>
                        <Box className="details-box">
                          <Typography className="details-text bold">
                            No. of Seasons:
                          </Typography>
                          <Typography className="details-text">
                            {" "}
                            {summaryData?.totalSeason
                              ? summaryData?.totalSeason
                              : "-"}
                          </Typography>
                        </Box>
                      </>
                    )}
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        No. of Games:
                      </Typography>
                      <Typography className="details-text">
                        {" "}
                        {summaryData?.totalEvent
                          ? summaryData?.totalEvent
                          : "-"}
                      </Typography>
                    </Box>
                    {params?.sportId != 12 && (
                      <Box className="details-box">
                        <Typography className="details-text bold">
                          No. of Players:
                        </Typography>
                        <Typography className="details-text">
                          {summaryData?.totalPlayer
                            ? summaryData?.totalPlayer
                            : "-"}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box className="quick-link-wrap">
                    <Typography className="details-text bold">
                      Quick Links:
                    </Typography>
                    <Typography className="details-text">
                      Last 10 Seasons-{" "}
                      {summarySeasonData?.length > 0
                        ? summarySeasonData?.map((item, index) => {
                            return (
                              <>
                                <span
                                  key={index}
                                  onClick={() => handleNavigate(item)}
                                >
                                  {" "}
                                  {item?.year}
                                  {index !== summarySeasonData.length - 1
                                    ? ","
                                    : ""}
                                </span>
                              </>
                            );
                          })
                        : "-"}
                    </Typography>
                  </Box>
                </Box>
              </>
            ) : value === 6 ? (
              <>
                <Select
                  className={`React series-select`}
                  onMenuScrollToBottom={e => handleOnScrollBottomTeams(e)}
                  onInputChange={e =>
                    handleTeamsInputChange(0, e, Number(params?.sportId))
                  }
                  onChange={e => {
                    setSelectedTeams(e?.value);
                    setSelectedTeamsLabel(e?.label);
                    // fetchTeamsData(e?.value, Number(params?.sportId), TeamsAll);
                    setTeamsApiCount(0);
                    setpageTeams(0);
                    setisTeamsSelectOpen(false);
                  }}
                  onFocus={() => setisTeamsSelectOpen(true)}
                  onBlur={() => setisTeamsSelectOpen(false)}
                  value={
                    selectedTeams !== "" &&
                    (isTeamsSearch
                      ? searchTeams?.find(item => {
                          return item?.value == selectedTeams;
                        })
                      : TeamsAll?.find(item => {
                          return item?.value == selectedTeams;
                        }))
                  }
                  options={isTeamsSearch ? searchTeams : TeamsAll}
                  classNamePrefix="select"
                  placeholder="All Teams"
                  components={{ DropdownIndicator }}
                />
              </>
            ) : (
              <></>
            )}
          </Box>

          {/* All tab deatils add here condition add like value === 0 ? "" : "" */}
          {value === 0 ? (
            <LeagueSummaryDetails headerData={headerData} />
          ) : value === 1 ? (
            <LeagueCoachDetails headerData={headerData} />
          ) : value === 2 ? (
            <LeaguePlayerDetails headerData={headerData} />
          ) : value === 3 ? (
            <LeagueSeasonDetails
              headerData={headerData}
              setSeasonActive={setSeasonActive}
            />
          ) : value === 4 ? (
            <LeagueTeamDetails headerData={headerData} />
          ) : value === 5 ? (
            <LeagueVenuesDetails headerData={headerData} />
          ) : value === 6 ? (
            <TeamSportNews
              teamSportType={routeName}
              headerData={headerData}
              newsType={"league"}
              selectedTeamsLabel={selectedTeamsLabel}
              selectedTeams={selectedTeams}
            />
          ) : (
            <>
              <Box className="no-data-wrap">
                <Typography className="text-align-center no-data">
                  No data Available
                </Typography>
              </Box>
            </>
          )}
        </Box>
        <OurPartner />
      </Box>
    </>
  );
};

export default LeagueDataPage;
