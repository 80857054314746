import React from "react";
import Page from "src/components/Page";
import SportsTipsPage from "src/views/component/TipsMarketplace/sportsTips";

const SportsTips = () => {
  return (
    <Page title="Sports Tips">
      <SportsTipsPage />
    </Page>
  );
};

export default SportsTips;
