import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  // Radio,
  // FormControlLabel,
  // FormControl,
  // FormLabel,
  // RadioGroup,
} from "@mui/material";
import banner from "../../../assets/images/banner/smart-b-banner-primary.webp";
import AdvertisingBannner from "../../component/advertisingBanner";
import "./policies.scss";
import OurPartner from "../../component/ourPartners";
import { IntlContext } from "src/App";
import ScriptLoader from "src/views/component/scriptLoader/ScriptLoader";
import { Helmet } from "react-helmet";

const ResponsibleGambling = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  const scriptCode = `
  rdt('track', 'ViewContent', {
    "products": [
      {
        "id": "product id 1",
        "name": "product name 1",
        "category": "product category 1"
      },
      // additional products can be added here
    ]
  });
`;
  return (
    <>
      <Helmet>
        <title>SmartB | Responsible Gambling | Learn More</title>
        <meta
          name="description"
          content="SmartB is your guide to responsible gambling. Find the best offers and empower yourself with valid information to make great decisions in sports betting."
        />
      </Helmet>
      <ScriptLoader scriptCode={scriptCode} />
      <Box className="content-wrap">
        {screenWidth > 479 ? "" : <AdvertisingBannner src={banner} />}
        <Box className="sport-wrap">
          <Box className="terms-wrap">
            <Typography variant="h1">
              <span className="title-orange">
                {localesData?.RESPONSIBLE_GAMBLING?.TITLE}
              </span>
            </Typography>
            {/* <Typography className="created-date">
              <span className="title-skyblue">Created on: 1st August 2022</span>
            </Typography>
            <Typography className="modified-on">
              <span className="title-skyblue">Modified on:</span>
            </Typography> */}
            <Typography className="mb-10">
              <b>{localesData?.RESPONSIBLE_GAMBLING?.GUIDE}</b>
            </Typography>
            <Typography variant="body2">
              {localesData?.RESPONSIBLE_GAMBLING?.GUIDE_PARA_1}
            </Typography>
            <Typography variant="body2">
              {localesData?.RESPONSIBLE_GAMBLING?.GUIDE_PARA_2}
            </Typography>
            <Typography variant="body2">
              {localesData?.RESPONSIBLE_GAMBLING?.GUIDE_PARA_3}
              <a
                className="gambling-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://nt.gov.au/industry/gambling/gambling/gambling-codes-of-practice/nt-code-of-practice-for-responsible-online-gambling-2019"
              >
                (https://nt.gov.au/industry/gambling/gambling/gambling-codes-of-practice/nt-code-of-practice-for-responsible-online-gambling-2019)
              </a>
              .
            </Typography>
            <Typography variant="h5">
              {localesData?.RESPONSIBLE_GAMBLING?.WHAT_IS_RESPONSIBLE_GAMBLING}
            </Typography>
            <Typography variant="body2" className="mb-10">
              {localesData?.RESPONSIBLE_GAMBLING?.RESPONSIBLE_GAMBLING_MEANS}
            </Typography>
            <ul>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.RESPONSIBLE_GAMBLING_MEANS_LIST_1
                }
              </li>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.RESPONSIBLE_GAMBLING_MEANS_LIST_2
                }
              </li>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.RESPONSIBLE_GAMBLING_MEANS_LIST_3
                }
              </li>
            </ul>
            <Typography variant="body2" className="mb-10">
              {
                localesData?.RESPONSIBLE_GAMBLING
                  ?.RESPONSIBLE_GAMBLING_BROADER_COMMUNITY
              }
            </Typography>
            <ul>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.RESPONSIBLE_GAMBLING_BROADER_COMMUNITY_LIST_1
                }
              </li>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.RESPONSIBLE_GAMBLING_BROADER_COMMUNITY_LIST_2
                }
              </li>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.RESPONSIBLE_GAMBLING_BROADER_COMMUNITY_LIST_3
                }
              </li>
            </ul>
            <Typography variant="h5">
              {localesData?.RESPONSIBLE_GAMBLING?.WHAT_IS_PROBLEM_GAMBLING}
            </Typography>
            <Typography variant="body2">
              {localesData?.RESPONSIBLE_GAMBLING?.PROBLEM_GAMBLING_SITUATION}
            </Typography>
            <Typography variant="body2" className="mb-10">
              {
                localesData?.RESPONSIBLE_GAMBLING
                  ?.NEGATIVE_CONSEQUENCES_OF_PROBLEM_GAMBLING
              }
            </Typography>
            <ul>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.NEGATIVE_CONSEQUENCES_OF_PROBLEM_GAMBLING_LIST_1
                }
              </li>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.NEGATIVE_CONSEQUENCES_OF_PROBLEM_GAMBLING_LIST_2
                }
              </li>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.NEGATIVE_CONSEQUENCES_OF_PROBLEM_GAMBLING_LIST_3
                }
              </li>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.NEGATIVE_CONSEQUENCES_OF_PROBLEM_GAMBLING_LIST_4
                }
              </li>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.NEGATIVE_CONSEQUENCES_OF_PROBLEM_GAMBLING_LIST_5
                }
              </li>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.NEGATIVE_CONSEQUENCES_OF_PROBLEM_GAMBLING_LIST_6
                }
              </li>
            </ul>
            <Typography variant="body2" className="mb-10">
              {
                localesData?.RESPONSIBLE_GAMBLING
                  ?.TO_ASSIST_WITH_GAMBLING_RESPONSIBLY_SMARTB
              }
            </Typography>
            <ul>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.TO_ASSIST_WITH_GAMBLING_RESPONSIBLY_SMARTB_LIST_1
                }
              </li>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.TO_ASSIST_WITH_GAMBLING_RESPONSIBLY_SMARTB_LIST_2
                }
              </li>
            </ul>
            <Typography variant="body2" className="mb-10">
              {
                localesData?.RESPONSIBLE_GAMBLING
                  ?.SOME_TIPS_FOR_ENSURING_YOUR_GAMBLING_DOES_NOT_START_AFFECTING_YOU_NEGATIVELY
              }
            </Typography>

            <ul>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.SOME_TIPS_FOR_ENSURING_YOUR_GAMBLING_DOES_NOT_START_AFFECTING_YOU_NEGATIVELY_LIST_1
                }
              </li>
              <li>
                {" "}
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.SOME_TIPS_FOR_ENSURING_YOUR_GAMBLING_DOES_NOT_START_AFFECTING_YOU_NEGATIVELY_LIST_2
                }
              </li>
              <li>
                {" "}
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.SOME_TIPS_FOR_ENSURING_YOUR_GAMBLING_DOES_NOT_START_AFFECTING_YOU_NEGATIVELY_LIST_3
                }
              </li>
              {/* <li>If you're behind, don't chase, it will only get worse</li> */}
            </ul>
            <Typography variant="h5">
              {
                localesData?.RESPONSIBLE_GAMBLING
                  ?.CLIENT_CARE_PRINCIPLES_AND_RESPONSIBLE_GAMBLING
              }
            </Typography>
            <Typography variant="body2">
              {
                localesData?.RESPONSIBLE_GAMBLING
                  ?.CLIENT_CARE_PRINCIPLES_AND_RESPONSIBLE_GAMBLING_PARA_1
              }
            </Typography>
            <Typography variant="body2" className="mb-10">
              {
                localesData?.RESPONSIBLE_GAMBLING
                  ?.CLIENT_CARE_PRINCIPLES_AND_RESPONSIBLE_GAMBLING_PARA_2
              }
            </Typography>
            <ul>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.CLIENT_CARE_PRINCIPLES_AND_RESPONSIBLE_GAMBLING_LIST_1
                }
              </li>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.CLIENT_CARE_PRINCIPLES_AND_RESPONSIBLE_GAMBLING_LIST_2
                }
              </li>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.CLIENT_CARE_PRINCIPLES_AND_RESPONSIBLE_GAMBLING_LIST_3
                }
              </li>
            </ul>
            <Typography variant="body2" className="mb-10">
              {
                localesData?.RESPONSIBLE_GAMBLING
                  ?.WE_AIM_TO_ACHIEVE_THE_FOLLOWING_OUTCOMES
              }
            </Typography>
            <ul>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.WE_AIM_TO_ACHIEVE_THE_FOLLOWING_OUTCOMES_LIST_1
                }
              </li>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.WE_AIM_TO_ACHIEVE_THE_FOLLOWING_OUTCOMES_LIST_2
                }
              </li>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.WE_AIM_TO_ACHIEVE_THE_FOLLOWING_OUTCOMES_LIST_3
                }
              </li>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.WE_AIM_TO_ACHIEVE_THE_FOLLOWING_OUTCOMES_LIST_4
                }
              </li>
              <li>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.WE_AIM_TO_ACHIEVE_THE_FOLLOWING_OUTCOMES_LIST_5
                }
              </li>
            </ul>

            <Typography variant="h5">
              {localesData?.RESPONSIBLE_GAMBLING?.GAMBLING_SELF_ASSESSMENT}
            </Typography>
            <Typography variant="body2">
              {
                localesData?.RESPONSIBLE_GAMBLING
                  ?.GAMBLING_SELF_ASSESSMENT_PARA_1
              }
            </Typography>
            {/* <ul className="problem-question">
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>Have you ever ignored your job in order to gamble?</b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>
                      Have you felt unhappy with your personal life due to
                      gambling?
                    </b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>
                      Does your gambling affect your perception of others, or
                      how others may perceive you?
                    </b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>
                      Has your gambling left you with a deep sense of regret?
                    </b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>
                      Have you ever tried to meet your financial obligations by
                      gambling?
                    </b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>Has gambling affected your motivation to succeed?</b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>
                      Do you feel obliged to gamble in order to recoup lost
                      money?
                    </b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>Do you consider a win as motivation to spend more?</b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>
                      Have you ever spent your last available dollar on
                      gambling?
                    </b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>Have you ever borrowed money in order to gamble?</b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>Have you ever sold assets in order to gamble?</b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>
                      Have you felt reluctant to use money set aside for
                      gambling on everyday purchases?
                    </b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>
                      Has gambling negatively impacted on the way that you treat
                      yourself or others?
                    </b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>
                      Do you find yourself gambling beyond the time you had
                      planned to stop?
                    </b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>
                      Have you ever gambled to avoid dealing with personal
                      problems?
                    </b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>
                      Have you ever committed (or considered committing) a
                      criminal offence in order to gamble?
                    </b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>Does your gambling affect your sleep?</b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>
                      Do the frustrations of your personal life encourage you to
                      gamble?
                    </b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>
                      Have you celebrated good fortune by spending excessive
                      time gambling?
                    </b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
              <li>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>
                      Have you considered harming yourself as a result of
                      gambling?
                    </b>
                  </FormLabel>
                  <RadioGroup
                    className="radio-group"
                    // aria-label="gender"
                    // name="gender1"
                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </li>
            </ul> */}
            <Typography variant="body2">
              {
                localesData?.RESPONSIBLE_GAMBLING
                  ?.GAMBLING_SELF_ASSESSMENT_PARA_2
              }
            </Typography>
            <Typography variant="body2">
              {
                localesData?.RESPONSIBLE_GAMBLING
                  ?.GAMBLING_SELF_ASSESSMENT_PARA_3
              }
            </Typography>
            <Typography variant="body2">
              <b>
                {
                  localesData?.RESPONSIBLE_GAMBLING
                    ?.TAKE_THE_SELF_ASSESSMENT_NOW
                }{" "}
              </b>
              <a
                className="gambling-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.gamblinghelponline.org.au/take-a-step-forward/self-assessment/problem-gambling-severity-index-pgsi#"
              >
                https://www.gamblinghelponline.org.au/take-a-step-forward/self-assessment/problem-gambling-severity-index-pgsi#
              </a>
            </Typography>
            {/* <Typography variant="h5">Where to Get Help</Typography>
             */}
            <Typography variant="h5">
              {localesData?.RESPONSIBLE_GAMBLING?.SELF_EXCLUSION}
            </Typography>
            <Typography variant="body2">
              {localesData?.RESPONSIBLE_GAMBLING?.SELF_EXCLUSION_PARA_1}{" "}
              <a href="mailto:support@SmartB.com">support@SmartB.com</a>{" "}
              {localesData?.RESPONSIBLE_GAMBLING?.AND_WE_WILL_ASSIST_YOU}
            </Typography>
            <Typography variant="body2">
              {localesData?.RESPONSIBLE_GAMBLING?.SELF_EXCLUSION_PARA_2}
            </Typography>
            <Typography variant="body2" className="mb-0">
              {localesData?.RESPONSIBLE_GAMBLING?.SELF_EXCLUSION_PARA_3}
            </Typography>
            <Typography variant="body2">
              <a
                className="gambling-link"
                href="https://nt.gov.au/industry/gambling/gambling/self-exclusion-from-a-sports-bookmaker-or-betting-exchange-operator"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://nt.gov.au/industry/gambling/gambling/self-exclusion-from-a-sports-bookmaker-or-betting-exchange-operator
              </a>
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Bet365</TableCell>
                    <TableCell>
                      <a href="mailto:support-eng@customerservice365.com ">
                        support-eng@customerservice365.com
                      </a>
                    </TableCell>
                    <TableCell>
                      <a href="tel: 1800200365">1800 200 365</a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Betfair</TableCell>
                    <TableCell>
                      <a href="mailto:service@betfair.com.au">
                        service@betfair.com.au
                      </a>
                    </TableCell>
                    <TableCell>
                      <a href="tel: 1300238324">1300 238 324</a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Unibet</TableCell>
                    <TableCell>
                      <a href="mailto:customerservice@unibet.com.au">
                        customerservice@unibet.com.au
                      </a>
                    </TableCell>
                    <TableCell>
                      <a href="tel: 137868">13 78 68</a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Ubet</TableCell>
                    <TableCell>
                      <a href="mailto:support@ubet.com.au">
                        support@ubet.com.au
                      </a>
                    </TableCell>
                    <TableCell>
                      <a href="tel: 1800823888">1800 823 888</a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>TAB.com.au</TableCell>
                    <TableCell>
                      <a href="mailto:betcare@tabcorp.com.au">
                        betcare@tabcorp.com.au
                      </a>
                    </TableCell>
                    <TableCell>
                      <a href="tel: 1800822876">1800 822 876</a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Classic Bet</TableCell>
                    <TableCell>
                      <a href="mailto:support@classicbet.com.au">
                        support@classicbet.com.au
                      </a>
                    </TableCell>
                    <TableCell>
                      <a href="tel: 1800888001">1800 888 001</a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Draftstars</TableCell>
                    <TableCell>
                      <a href="mailto:support@draftstars.com.au">
                        support@draftstars.com.au
                      </a>
                    </TableCell>
                    <TableCell>
                      <a href="tel: 1800131939">1800 131 939</a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Ladbrokes</TableCell>
                    <TableCell>
                      <a href="mailto:support@ladbrokes.com.au">
                        support@ladbrokes.com.au
                      </a>
                    </TableCell>
                    <TableCell>
                      <a href="tel: 1300523276">1300 523 276</a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>BetEasy</TableCell>
                    <TableCell>
                      <a href="mailto:support@beteasy.com.au">
                        support@beteasy.com.au
                      </a>
                    </TableCell>
                    <TableCell>
                      <a href="tel: 132769">132769</a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Sportsbet</TableCell>
                    <TableCell>
                      <a href="mailto:rsg@sportsbet.com.au">
                        rsg@sportsbet.com.au
                      </a>
                    </TableCell>
                    <TableCell>
                      <a href="tel: 1800990907">1800 990 907</a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="h5">{""}</Typography>
            {/* <Typography variant="body2">
              Please contact{" "}
              <a href="mailto:support@SmartB.com.au">support@SmartB.com.au</a>{" "}
              if you would like SmartB to assist in excluding you from other
              operators.
            </Typography> */}
            <Typography variant="h5">
              {localesData?.RESPONSIBLE_GAMBLING?.PRE_COMMITMENT}
            </Typography>
            <Typography variant="body2">
              {localesData?.RESPONSIBLE_GAMBLING?.PRE_COMMITMENT_PARA_1}
            </Typography>
            <Typography variant="body2">
              {localesData?.RESPONSIBLE_GAMBLING?.PRE_COMMITMENT_PARA_2}
            </Typography>
            <Typography variant="h5">
              {localesData?.RESPONSIBLE_GAMBLING?.GAMBLING_HELP_ONLINE}
            </Typography>
            <Typography variant="body2">
              {
                localesData?.RESPONSIBLE_GAMBLING
                  ?.YOU_CAN_CALL_THE_GAMBLING_HELPLINE_ON
              }{" "}
              <a href="tel:1800858858">1800 858 858</a>{" "}
              {
                localesData?.RESPONSIBLE_GAMBLING
                  ?.FINANCIAL_COUNSELLING_HELPLINE_ON
              }{" "}
              <a href="tel:1800007007">1800 007 007</a>{" "}
              {localesData?.RESPONSIBLE_GAMBLING?.VISIT}
            </Typography>
            <Typography variant="body2">
              <a
                className="link-blue"
                href="https://www.gamblinghelponline.org.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.gamblinghelponline.org.au
              </a>
            </Typography>
            <Typography variant="body2">
              <a
                className="link-blue"
                href="https://www.gambleaware.nsw.gov.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.gamblinghelp.nsw.gov.au
              </a>
            </Typography>
            <Typography variant="body2">
              {localesData?.RESPONSIBLE_GAMBLING?.GAMBLING_SUPPORT_SERVICE}{" "}
              <a href="mailto:support@SmartB.com">support@SmartB.com</a>{" "}
              {
                localesData?.RESPONSIBLE_GAMBLING
                  ?.OTHER_GAMBLING_SUPPORT_SERVICE
              }
            </Typography>
            <Typography variant="body2">
              {localesData?.RESPONSIBLE_GAMBLING?.LIST_OF_SUPPORT_SERVICES}
            </Typography>
            <Typography variant="body2">
              <b>{localesData?.RESPONSIBLE_GAMBLING?.CLOSE_IS_IN_DANGER}</b>
            </Typography>
            <Typography variant="body2">
              <b>{localesData?.RESPONSIBLE_GAMBLING?.GAMBLING_HELP_ONLINE}</b>
              <br />
              {localesData?.RESPONSIBLE_GAMBLING?.INFORMATION_AND_SUPPORT}{" "}
              <br />
              <a
                className="link-blue"
                href="https://www.gamblinghelponline.org.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.gamblinghelponline.org.au
              </a>{" "}
              <br />
              <a className="link-blue" href="tel:1800007007">
                1800 007 007
              </a>
            </Typography>
            <Typography variant="body2">
              <b>{localesData?.RESPONSIBLE_GAMBLING?.GAMBLERS_ANONYMOUS}</b>
              <br />
              {localesData?.RESPONSIBLE_GAMBLING?.MEETINGS_AND_SUPPORT} <br />
              <a
                className="link-blue"
                href="https://www.gaaustralia.org.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.gaaustralia.org.au
              </a>{" "}
            </Typography>
            <Typography variant="body2">
              <b>{localesData?.RESPONSIBLE_GAMBLING?.GAMBLING_THERAPY}</b>
              <br />
              {localesData?.RESPONSIBLE_GAMBLING?.ADVISE_AND_SUPPORT}
              <br />
              <a
                className="link-blue"
                href="https://www.gamblingtherapy.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.gamblingtherapy.org
              </a>{" "}
            </Typography>
            <Typography variant="body2">
              <b>{localesData?.RESPONSIBLE_GAMBLING?.DAY_CHALLENGE}</b>
              <br />
              {localesData?.RESPONSIBLE_GAMBLING?.ANONYMOUS_SUPPORT}
              <br />
              <a
                className="link-blue"
                href="https://www.100dc.com.au "
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.100dc.com.au
              </a>{" "}
            </Typography>
            <Typography variant="body2">
              <b>{localesData?.RESPONSIBLE_GAMBLING?.NATIONAL_DEBT_HELPLINE}</b>
              <br />
              {
                localesData?.RESPONSIBLE_GAMBLING
                  ?.PROFESSIONAL_FINANCIAL_COUNSELLORS
              }
              <br />
              <a
                className="link-blue"
                href="https://ndh.org.au "
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ndh.org.au
              </a>{" "}
              <br />
              <a className="link-blue" href="tel:1800007007">
                1800 007 007
              </a>
            </Typography>
            <Typography variant="body2">
              <b>{localesData?.RESPONSIBLE_GAMBLING?.GOOD_SHEPHERD}</b>
              <br />
              {localesData?.RESPONSIBLE_GAMBLING?.ADDRESSING_ISSUES}
              <br />
              <a
                className="link-blue"
                href="https://goodshep.org.au/services/financial-services/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://goodshep.org.au/services/financial-services
              </a>
            </Typography>
            <Typography variant="body2">
              <b>{localesData?.RESPONSIBLE_GAMBLING?.LIFELINE}</b>
              <br />
              {localesData?.RESPONSIBLE_GAMBLING?.CRISIS_SUPPORT}
              <br />
              <a
                className="link-blue"
                href="https://www.lifeline.org.au "
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.lifeline.org.au
              </a>{" "}
              <br />
              <a className="link-blue" href="tel:131114">
                13 11 14
              </a>
            </Typography>
            <Typography variant="body2">
              <b>{localesData?.RESPONSIBLE_GAMBLING?.R_U_OK}</b>
              <br />
              {localesData?.RESPONSIBLE_GAMBLING?.CHANGE_A_LIFE}
              <br />
              <a
                className="link-blue"
                href="https://www.ruok.org.au "
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.ruok.org.au
              </a>
            </Typography>
            <Typography variant="body2">
              <b>{localesData?.RESPONSIBLE_GAMBLING?.BEYOND_BLUE}</b>
              <br />
              {
                localesData?.RESPONSIBLE_GAMBLING
                  ?.MENTAL_HEALTH_AND_WELLBEING_SUPPORT
              }
              <br />
              <a
                className="link-blue"
                href="https://www.beyondblue.org.au "
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.beyondblue.org.au
              </a>
              <br />
              <a className="link-blue" href="tel:1300224636">
                1300 224 636
              </a>
            </Typography>
            <Typography variant="body2">
              <b>
                {localesData?.RESPONSIBLE_GAMBLING?.RELATIONSHIPS_AUSTRALIA}
              </b>
              <br />
              {localesData?.RESPONSIBLE_GAMBLING?.RELATIONSHIP_SUPPORT}
              <br />
              <a
                className="link-blue"
                href="https://www.relationships.org.au/ "
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.relationships.org.au/
              </a>
              <br />
              <a className="link-blue" href="tel:1300364277">
                1300 364 277
              </a>
            </Typography>
            <Typography variant="body2" className="pb-46 mb-0">
              <b>{localesData?.RESPONSIBLE_GAMBLING?.RESPECT}</b>
              <br />
              {
                localesData?.RESPONSIBLE_GAMBLING
                  ?.EXPERIENCING_VIOLENCE_OR_ABUSE
              }
              <br />
              <a
                className="link-blue"
                href="https://www.1800respect.org.au "
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.1800respect.org.au
              </a>
              <br />
              <a className="link-blue" href="tel:1800737732">
                1800 737 732
              </a>
            </Typography>
            {/* <Typography variant="body2">
              <a className="link-blue" href="tel:1800858858">
                1800 858 858
              </a>
            </Typography>
            <Typography variant="body2">
              <a
                className="link-blue"
                href="https://www.gamblinghelponline.org.au/"
              >
                www.gamblinghelponline.org.au
              </a>
            </Typography>
            <Typography variant="body2">
              Confidential, professional, free help, 24 hours a day, 7 days a
              week. Talk to someone who understands your issues.
            </Typography>
            <Typography variant="h5">Chaplaincy Support Service</Typography>
            <Typography variant="body2" className="pb-46 mb-0">
              A free, private and confidential inter-faith Chaplaincy Support
              Service. The service is available by calling Fr James Grant on
              <a href="tel:1800456228">1800 456 228</a> or{" "}
              <a href="mailto:frjames@keypoint.com.au">
                frjames@keypoint.com.au
              </a>{" "}
              enables people to make an appointment with Fr James to seek
              guidance in their own way and aims to be relevant to the
              particular problems and life situations of the individual.
            </Typography> */}
          </Box>
          {/* <Box className="our-partner">
            <Typography variant="h1">OUR PARTNERS</Typography>
            <h5>Coming Soon</h5>
          </Box> */}
        </Box>
        {screenWidth > 479 ? "" : <OurPartner />}
      </Box>
    </>
  );
};

export default ResponsibleGambling;
