import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Box,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import "./advertisingScreen.scss";
import { IntlContext } from "src/App";
import moment from "moment-timezone";
import Tooltip from "@mui/material/Tooltip";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import RaceCountdown from "../allsports/racing/runnerTable/raceCountdown";
import HeaderTopBar from "src/layouts/MainLayout/headertopbar/headertopbar";
import screenBaner from "../../../assets/images/banner/advertisingScreen.webp";
import AdvertisingBanner from "../../../assets/images/banner/advertisingScreenBanner.png";
import AdvertisingBannner from "../advertisingBanner";
import Australia from "../../../assets/images/flag/au_flag.svg";
import adCutOut from "../../../assets/images/ad-cutOut.svg";
import QRScnner from "../../../assets/images/QR-scnner.svg";
import Bet365 from "../../../assets/images/bookmakers/bet365-thumb.svg";
import UniBet from "../../../assets/images/bookmakers/unibet-thumb.svg";
import BookMaker from "../../../assets/images/bookmakers/bookmakers-thumb.svg";
import Neds from "../../../assets/images/bookmakers/neds-thumb.svg";
import PlayUp from "../../../assets/images/bookmakers/playup-thumb.svg";
import BetStar from "../../../assets/images/bookmakers/betstar-thumb.svg";
// import ViceBet from "../../../assets/images/bookmakers/vice-bet-thumb.svg";
import BlueBet from "../../../assets/images/bookmakers/BlueBet-thumb.png";
import TopSport from "../../../assets/images/bookmakers/top-sport-thumb.svg";
import betFair from "../../../assets/images/bookmakers/betfair-thumb.svg";
import BoomBet from "../../../assets/images/bookmakers/BoomBet.png";
import LadBrokes from "../../../assets/images/bookmakers/Ladbrokes-thumb.png";
import Draftstars from "../../../assets/images/bookmakers/DraftStars.png";
import SouthernCrossBet from "../../../assets/images/bookmakers/SouthernCrossBet.png";
import PuntOnDogs from "../../../assets/images/bookmakers/Puntondogs.webp";
import BetRight from "../../../assets/images/new-bookmakers/BETRIGHT-icon.webp";
import EliteBet from "../../../assets/images/new-bookmakers/ELITEBET-icon.webp";
import GetSetBet from "../../../assets/images/new-bookmakers/GSB-icon.webp";
import Weather from "../../../assets/images/icons/weather/w-good.svg";
import Cloudy from "../../../assets/images/icons/weather/cloudy.svg";
import Rain from "../../../assets/images/icons/weather/rain.svg";
import Showers from "../../../assets/images/icons/weather/showers.svg";
import Loader from "src/components/Loader";
import { Config } from "../../../helpers/context/config";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { animateScroll as scroll } from "react-scroll";
import AdvertisingScreenTable from "./AdvertisingScreenTable";
import _ from "lodash";
import HeaderTopBarV2 from "src/layouts/MainLayout/headertopbar/headertopbarV2";

function AdvertisingScreenPage() {
  const [selectedRaceId, setselectedRaceId] = useState(3);
  const [oddsLoader, setOddsLoader] = useState(false);
  const [runnerData, setRunnerData] = useState([]);
  const [timeinterval, setTimeInterval] = useState();
  const [apiTimeout, setAPiTimeout] = useState();
  const [scrollht, setscrollht] = useState(0);
  const [clientht, setclientht] = useState(0);
  const [ProviderData, setProviderData] = useState([]);
  const advtData = useSelector((state) => state?.reduxData);
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const { AdvScreenData, isLoading } = advtData;
  let eventDetail = AdvScreenData?.race;
  let raceList = AdvScreenData?.raceList;
  const handleChange = (event, value) => {
    setselectedRaceId(value);
  };
  const [direction, setDirection] = useState(1);
  const [scrollTop, setScrollTop] = useState(0);
  const [sponsoredId, setSponsoredId] = useState([]);
  const [pageHeadingData, setPageHeadingData] = useState([]);
  const [sponsorsLogo, setSponsorsLogo] = useState([]);
  const [BookkeeperData, setBookKeeperData] = useState([]);
  const tableBodyRef = useRef();
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const oddsicon = (ApiProviderId) => {
    let icon = BookkeeperData?.filter(
      (obj) => obj?.BookKeeper?.apiProviderId === ApiProviderId,
    );
    let iconData = icon?.[0]?.BookKeeper;

    if (iconData) {
      return (
        <a
          href={iconData?.affiliate_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            alt="Odds Icon"
            //
          />
        </a>
      );
    }

    // if (ApiProviderId === 1) {
    //   return;
    // } else if (ApiProviderId === 2) {
    //   return (
    //     <a
    //       href="https://record.nedsaffiliates.com.au/_F_7-VCjCT37BkgpoV0de5mNd7ZgqdRLk/1/"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <img
    //         className="bookmaker-thumb"
    //         src={Neds}
    //         alt="Odds Icon"
    //         //
    //       />
    //     </a>
    //   );
    // } else if (ApiProviderId === 3) {
    //   return (
    //     <a
    //       href="https://record.wageringaffiliates.com.au/_kfGDBcwJlRmitX36G815rmNd7ZgqdRLk/1/"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <img className="bookmaker-thumb" src={LadBrokes} alt="Odds Icon" />
    //     </a>
    //   );
    // } else if (ApiProviderId === 4) {
    //   return (
    //     <img
    //       className="bookmaker-thumb not-clickable"
    //       src={BookMaker}
    //       alt="Odds Icon"
    //     />
    //   );
    // } else if (ApiProviderId === 5) {
    //   return (
    //     <img
    //       className="bookmaker-thumb not-clickable"
    //       src={BetStar}
    //       alt="Odds Icon"
    //     />
    //   );
    // } else if (ApiProviderId === 6) {
    //   return (
    //     <a
    //       href="https://www.bet365.com/olp/open-account?affiliate=365_02038070"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <img className="bookmaker-thumb" src={Bet365} alt="Odds Icon" />
    //     </a>
    //   );
    // } else if (ApiProviderId === 7) {
    //   return;
    // } else if (ApiProviderId === 8) {
    //   return (
    //     <a
    //       href="https://www.unibet.com.au/"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <img className="bookmaker-thumb" src={UniBet} alt="Odds Icon" />
    //     </a>
    //   );
    // } else if (ApiProviderId === 9) {
    //   return (
    //     <a
    //       href="https://record.affiliates.playup.com/_IZoOYUcz-mj6PBA04iUMN2Nd7ZgqdRLk/1/"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <img className="bookmaker-thumb" src={Draftstars} alt="Odds Icon" />
    //     </a>
    //   );
    // } else if (ApiProviderId === 10) {
    //   return (
    //     <a
    //       href="https://www.betfair.com.au/extensions/redir/NA/108/PI-660691"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <img className="bookmaker-thumb" src={betFair} alt="Odds Icon" />
    //     </a>
    //   );
    // } else if (ApiProviderId === 11) {
    //   return (
    //     <a
    //       href="https://www.topsport.com.au/?promocode=SmartB"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <img className="bookmaker-thumb" src={TopSport} alt="Odds Icon" />
    //     </a>
    //   );
    // } else if (ApiProviderId === 12) {
    //   return (
    //     <a
    //       href="https://record.affiliates.playup.com/_IZoOYUcz-mhhg6WO2I1rgWNd7ZgqdRLk/1/"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <img className="bookmaker-thumb" src={PlayUp} alt="Odds Icon" />
    //     </a>
    //   );
    // } else if (ApiProviderId === 13) {
    //   return (
    //     <a
    //       href="https://record.bluebetaffiliates.com.au/_-h3CNTRAkkuXrZzxC638nWNd7ZgqdRLk/1/"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <img className="bookmaker-thumb" src={BlueBet} alt="Odds Icon" />
    //     </a>
    //   );
    // } else if (ApiProviderId === 14) {
    //   return (
    //     <a
    //       href="https://www.boombet.com.au/?Referrer=SmartB"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <img className="bookmaker-thumb" src={BoomBet} alt="Odds Icon" />
    //     </a>
    //   );
    // } else if (ApiProviderId === 15) {
    //   return (
    //     <a
    //       href="https://www.southerncrossbet.com.au/?referrer=SmartB"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <img
    //         className="bookmaker-thumb"
    //         src={SouthernCrossBet}
    //         alt="Odds Icon"
    //       />
    //     </a>
    //   );
    // } else if (ApiProviderId === 18) {
    //   return (
    //     <a
    //       href="https://www.puntondogs.com.au/?BDM=smartb"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <img className="bookmaker-thumb" src={PuntOnDogs} alt="Odds Icon" />
    //     </a>
    //   );
    // } else if (ApiProviderId === 19) {
    //   return (
    //     <a
    //       href="https://record.betrightaffiliates.com/_l4N4mP86oGbUOsjNOfgKeWNd7ZgqdRLk/1/"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <img className="bookmaker-thumb" src={BetRight} alt="Odds Icon" />
    //     </a>
    //   );
    // } else if (ApiProviderId === 20) {
    //   return (
    //     <a
    //       href="https://www.elitebet.com.au/?Referrer=SMARTB"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <img className="bookmaker-thumb" src={EliteBet} alt="Odds Icon" />
    //     </a>
    //   );
    // } else if (ApiProviderId === 21) {
    //   return (
    //     <a
    //       href="https://getsetbet.com.au/"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <img className="bookmaker-thumb" src={GetSetBet} alt="Odds Icon" />
    //     </a>
    //   );
    // } else {
    //   return;
    // }
  };

  const fetchOddsData = async (isFirstTime) => {
    if (isFirstTime) {
      setOddsLoader(true);
    }
    try {
      const { status, data } = await axiosInstance.get(
        `events/getOddsByrace/${eventDetail?.id}?marketId=1&oddKey=1`,
      );
      if (status === 200) {
        setOddsLoader(false);
        // setOddsAPiCount(oddsApiCount + 1);

        let Provider = [];
        let ProviderListfirstRunner = data?.marketRelation?.[0]?.data?.map(
          (element) => {
            return Provider?.push(element?.BookKeeperId);
          },
        );
        let ProviderListsecondRunner = data?.marketRelation?.[1]?.data?.map(
          (element) => {
            return Provider?.push(element?.BookKeeperId);
          },
        );
        let ProviderListthirdRunner = data?.marketRelation?.[2]?.data?.map(
          (element) => {
            return Provider?.push(element?.BookKeeperId);
          },
        );

        const uniqueArray = _.uniq(Provider);
        setProviderData(uniqueArray);
        fetchTableHeading(uniqueArray);

        let newData = eventDetail?.runner?.map((obj) => {
          return {
            ...obj,
            oddsData: data?.marketRelation
              ?.map((item) => {
                return item?.RacingParticipantId == obj?.id ? item : [];
              })
              ?.filter((x) => {
                return x?.data?.length > 0;
              }),
          };
        });
        setRunnerData(newData);
        // setrunnerInfoData(newData);
      }
    } catch (err) {
      setOddsLoader(false);
    }
  };
  const fetchLatestOdds = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `sync/oddOnDemand/${eventDetail?.id}`,
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const fetchBestOpenValue = (data) => {
    // let newOdds = data?.filter(odds => {
    //   return odds.ApiProviderId > 5;
    // });
    let newOdds = data;
    let maxno = newOdds?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno == 0) {
        let newmaxno = newOdds?.reduce((max, obj) => {
          obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
            ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
            : (max = max);
          return max;
        }, -1);
        if (newmaxno !== -1) {
          return newmaxno;
        } else {
          return "-";
        }
      }
      return maxno;
    } else {
      return (
        <Tooltip
          title="No odds available"
          className="odds-tooltip"
          placement="top"
        >
          <span className="no-odds">NOA</span>
        </Tooltip>
      );
    }
  };
  const fetchBestOpenIcon = (data) => {
    // let newOdds = data?.filter(odds => {
    //   return odds.ApiProviderId > 5;
    // });
    let newOdds = data;
    let maxno = newOdds?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = newOdds
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue === maxno) {
              return obj?.ApiProviderId;
            }
          })
          .filter((x) => x !== undefined);
        return oddsicon(providerid?.[0]);
      } else {
        let newmaxno = newOdds?.reduce((max, obj) => {
          obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
            ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
            : (max = max);
          return max;
        }, -1);
        if (newmaxno !== -1) {
          let providerid = newOdds
            ?.map((obj) => {
              if (
                obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue === newmaxno
              ) {
                return obj?.ApiProviderId;
              }
            })
            .filter((x) => x !== undefined);
          return oddsicon(providerid?.[0]);
        } else {
          return "";
        }
      }
    } else {
      return "";
    }
  };
  const fetchCurrentBestValue = (data) => {
    // let newOdds = data?.filter(odds => {
    //   return odds.ApiProviderId > 5;
    // });
    let newOdds = data;
    let maxno = newOdds?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        return maxno;
      } else {
        return "SP";
      }
    } else {
      return "SP";
    }
  };
  const fetchCurrentBestIcon = (data) => {
    // let newOdds = data?.filter(odds => {
    //   return odds.ApiProviderId > 5;
    // });
    let newOdds = data;
    let maxno = newOdds?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = newOdds
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.ApiProviderId;
            }
          })
          .filter((x) => x !== undefined);
        return oddsicon(providerid?.[0]);
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  const fetchSeeAllOddsvalue = (item, ProviderId) => {
    let datas = item?.oddsData?.[0]?.data
      ?.map((obj) => {
        if (obj?.ApiProviderId === ProviderId) {
          return obj?.RaceOdds?.[0]?.intValue !== 0
            ? obj?.RaceOdds?.[0]?.intValue
            : "SP";
        }
      })
      .filter((x) => x !== undefined);
    if (datas?.length > 0) {
      return datas;
    } else {
      return (
        <Tooltip
          title="No odds available"
          className="odds-tooltip"
          placement="top"
        >
          <span className="no-odds">NOA</span>
        </Tooltip>
      );
    }
  };
  const fetchTableCell = (item, ApiProviderId) => {
    return (
      <TableCell>
        <Box className="odds-details">
          <span style={{ paddingBottom: "5px" }}>
            {" "}
            {fetchSeeAllOddsvalue(item, ApiProviderId)}
          </span>
          <span> {oddsicon(ApiProviderId)}</span>
        </Box>
      </TableCell>
    );
  };

  const GetSponsoredOdds = async (sportId) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/sponsor?timeZone=${timezone}&SportId=${sportId}`,
      );
      if (status === 200) {
        let providerIDs = [];
        let Ids = data?.result?.map((item) =>
          providerIDs?.push(item?.apiProviderId),
        );
        setSponsoredId(providerIDs);
      } else {
        setSponsoredId([]);
      }
    } catch (err) {
      setSponsoredId([]);
    }
  };
  useEffect(() => {
    clearInterval(timeinterval);
    clearTimeout(apiTimeout);
    if (eventDetail?.id) {
      fetchOddsData(true);
      fetchLatestOdds();
      GetSponsoredOdds(eventDetail?.sportId);
      // fetchTableHeading();
    }
    let timeout = setTimeout(() => {
      if (eventDetail?.id) {
        fetchOddsData(false);
      }
      let Interval = setInterval(() => {
        if (eventDetail?.id) {
          fetchOddsData(false);
        }
      }, 30000);
      setTimeInterval(Interval);
    }, 15000);
    setAPiTimeout(timeout);
  }, [eventDetail?.id]);
  const WeatherIcon = (type) => {
    if (type === "showers") {
      return Showers;
    } else if (type === "Rain") {
      return Rain;
    } else if (type === "fine") {
      return Weather;
    } else if (type === "FINE") {
      return Weather;
    } else if (type === "Good") {
      return Weather;
    } else if (type === "Cloudy") {
      return Cloudy;
    } else {
      return Weather;
    }
  };
  let currentDate = moment().format("YYYY-MM-DD");

  const fetchTableHeading = async (Provider) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders?SportId=1,2,3`,
      );
      if (status === 200) {
        let filteredHeading = data?.result?.filter((item) =>
          Provider?.includes(item?.BookKeeperId),
        );
        setPageHeadingData(filteredHeading);
      } else {
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchBookKeeper();
    fetchHederBookKeeper();
  }, []);

  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `advertiselogo/advertiselogo`,
      );
      if (status === 200) {
        setSponsorsLogo(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const fetchHederBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`,
      );
      if (status === 200) {
        setBookKeeperData(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const settings = {
    infinite: sponsorsLogo?.length >= 8,
    speed: 500,
    slidesToShow: sponsorsLogo?.length >= 8 ? 8 : sponsorsLogo?.length,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2500,
  };
  return (
    <>
      <Box className="ad-screen-container">
        <Box className="ad-screen-wrap">
          <HeaderTopBarV2 />
          {/* <HeaderTopBar /> */}
          {/* <Box className="ad-screen-banner">
          <AdvertisingBannner src={AdvertisingBanner} />
        </Box> */}
          <Box className="ad-screen-banner">
            <Slider {...settings} className="our-partner-slider">
              {sponsorsLogo?.map((item) => {
                return (
                  <Box className="partner-logo">
                    {/* <a
                    href={item?.affiliate_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  > */}
                    <img
                      className="slider-img"
                      src={
                        item?.logo?.includes("uploads")
                          ? Config.mediaURL + item?.logo
                          : item?.logo
                      }
                      alt="sponsors logo"
                    />
                    {/* </a> */}
                  </Box>
                );
              })}
            </Slider>
          </Box>
        </Box>
        {isLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : (
          <Box className="ad-screen-details">
            <Box className="race-details">
              <Box className="racename-weather-wrap">
                <Box className="race-details-name">
                  <span className="race-name">
                    {eventDetail?.event?.eventName
                      ? eventDetail?.event?.eventName
                      : ""}
                  </span>
                  <span className="location">
                    {eventDetail?.event?.track?.Country?.country_flag.includes(
                      "uploads",
                    ) ? (
                      <img
                        src={
                          Config.mediaURL +
                          eventDetail?.event?.track?.Country?.country_flag
                        }
                        width="28px"
                        height="16px"
                        alt="Race Country"
                      />
                    ) : (
                      <img
                        src={eventDetail?.event?.track?.Country?.country_flag}
                        width="28px"
                        height="16px"
                        alt="Race Country"
                      />
                    )}
                    {eventDetail?.event?.track?.Country?.country
                      ? eventDetail?.event?.track?.Country?.country
                      : ""}
                    {eventDetail?.event?.track?.State?.state
                      ? "," + " " + eventDetail?.event?.track?.State?.state
                      : ""}
                  </span>
                </Box>
                <Box className="race-track-weather">
                  <Box className="race-track">
                    <span>{localesData?.RUNNER_TABLE?.TRACK}:</span>
                    <span>
                      {eventDetail?.trackCondition
                        ? eventDetail?.trackCondition
                        : ""}
                    </span>
                  </Box>
                  <Box className="race-weather">
                    <span>{localesData?.RUNNER_TABLE?.WEATHER}:</span>
                    <span className="weather">
                      {" "}
                      {/* <img src={Weather} alt="weather" /> */}
                      {eventDetail?.Weather &&
                      eventDetail?.Weather?.weatherType ? (
                        <img
                          src={WeatherIcon(eventDetail?.Weather?.weatherType)}
                          alt="weather"
                        />
                      ) : (
                        ""
                      )}
                      <span>
                        {eventDetail?.Weather &&
                        eventDetail?.Weather?.weatherType
                          ? eventDetail?.Weather?.weatherType
                          : ""}
                      </span>
                    </span>
                  </Box>
                </Box>
              </Box>
              <Box className="trackdetails-qr-wrap">
                <Box className="race-track-details">
                  <Box className="race-track">
                    <Tabs
                      value={eventDetail?.raceNumber}
                      variant="scrollable"
                      scrollButtons={false}
                      // indicatorColor="primary"
                      textColor="primary"
                      className="race-track-tab"
                      disableRipple
                      disableFocusRipple
                    >
                      {raceList?.map((item, index) => {
                        return (
                          <Box>
                            <Tab
                              disableRipple
                              disableFocusRipple
                              label={item?.raceNumber}
                              value={item?.raceNumber}
                              className={
                                item?.raceNumber === eventDetail?.raceNumber
                                  ? "active"
                                  : item?.raceNumber < eventDetail?.raceNumber
                                    ? "disable-track"
                                    : ""
                              }
                              // onChange={(event, newValue) =>
                              //   handleChange(event, item?.id)
                              // }
                            />
                          </Box>
                        );
                      })}
                    </Tabs>
                  </Box>
                  <Box className="racing-detail-head">
                    <Box>
                      <h6>
                        {eventDetail?.raceName ? eventDetail?.raceName : ""}
                      </h6>
                    </Box>
                    <Box className="race-track-info-right">
                      <span>{localesData?.RUNNER_TABLE?.DISTANCE}: </span>
                      <span>
                        {eventDetail?.Distance && eventDetail?.Distance?.name
                          ? eventDetail?.Distance?.name + "m"
                          : ""}
                      </span>
                    </Box>
                    <Box>
                      <span>{localesData?.RUNNER_TABLE?.JUMP_TIME} : </span>
                      <span>
                        {moment(currentDate).isSame(
                          moment(eventDetail?.startTimeDate).format(
                            "YYYY-MM-DD",
                          ),
                        )
                          ? moment
                              .utc(eventDetail?.startTimeDate)
                              .local()
                              .format("hh:mm a")
                          : moment
                              .utc(eventDetail?.startTimeDate)
                              .local()
                              .format("YYYY/MM/DD hh:mm a")}
                      </span>
                    </Box>
                    <Box className="race-track-info-right">
                      <span>{localesData?.RUNNER_TABLE?.TIME_TILL_JUMP}: </span>
                      <span>
                        <RaceCountdown
                          expiryTimestamp={new Date(
                            eventDetail?.startTimeDate,
                          ).getTime()}
                        />
                      </span>
                    </Box>
                  </Box>
                </Box>
                <Box className="QR-Code">
                  <div className="qr-code-wrap">
                    <QRCode
                      value="https://smartb.com.au/"
                      style={{ height: "65px", width: "65px" }}
                    />
                  </div>
                  <img src={QRScnner} alt="QR" />
                </Box>
              </Box>
            </Box>
            <Box className="race-table-details">
              {isLoading || oddsLoader ? (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              ) : (
                <AdvertisingScreenTable
                  runnerData={runnerData}
                  fetchBestOpenValue={fetchBestOpenValue}
                  fetchBestOpenIcon={fetchBestOpenIcon}
                  fetchCurrentBestValue={fetchCurrentBestValue}
                  fetchCurrentBestIcon={fetchCurrentBestIcon}
                  fetchTableCell={fetchTableCell}
                  oddsicon={oddsicon}
                  sponsoredId={sponsoredId}
                  pageHeadingData={pageHeadingData}
                />
              )}
            </Box>
          </Box>
        )}
        {/* <Box className="ad-scrren">
        <img src={adCutOut} alt="ad" />
      </Box> */}
      </Box>
    </>
  );
}

export default AdvertisingScreenPage;
