import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Tabs,
  Tab,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import moment from "moment";
import "./listPage.scss";

const StickyTableCell = withStyles(theme => ({
  head: {
    right: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    minWidth: "45px",
    boxShadow: "inset 1px 0px 6px 0px #ebebeb",
    "@media(max-width: 799px)": {
      maxWidth: "45px",
      boxShadow: "inset 1px 0px 6px 0px #ebebeb"
    }
  },
  body: {
    right: 0,
    position: "sticky",
    background: "#FFFFFF",
    boxShadow: "inset 1px 0px 6px 0px #ebebeb",
    "@media(max-width: 799px)": {
      boxShadow: "inset 1px 0px 6px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const Listtab = [
  { id: 0, label: "Match list", value: "match" },
  { id: 1, label: "Player list", value: "player" }
];

const ListPage = ({ listDataPage, playerName, setListPage }) => {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const SeasonId = searchParams.get("seasonId");
  const [listPageLoader, setListPageLoader] = useState(false);
  const [playerListPageData, setPlayerListPageData] = useState([]);
  const [playerOffset, setPlayerOffset] = useState(0);
  const [matchOffset, setMatchOffset] = useState(0);
  const [playerListCount, setPlayerListCount] = useState(0);
  const [matchListCount, setMatchListCount] = useState(0);
  const [playerMatchData, setPlayerMatchData] = useState([]);
  const [value, setValue] = useState(0);
  const [label, setLabel] = useState("match");

  useEffect(() => {
    fetchPlayerListDetails(0, [], []);
    setPlayerListPageData([]);
    setPlayerMatchData([]);
    setPlayerOffset(0);
    setMatchOffset(0);
  }, [params, label, SeasonId]);

  const fetchPlayerListDetails = async (offset, infoplayer, infoMatch) => {
    setListPageLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/team/match/player/${params?.teamId}?SportId=${
          params?.sportId
        }&type=${label}&tournamentId=${
          params?.tournamentId
        }&limit=5&offset=${offset}&seasonId=${SeasonId ? SeasonId : ""}`
      );
      if (status === 200) {
        setListPageLoader(false);
        setPlayerListCount(data?.result?.events?.length);
        setMatchListCount(data?.result?.players?.length);
        // const playerList = [...infoplayer, ...data?.result?.events];
        // const matchList = [...infoMatch, ...data?.result?.players];
        const playerList = [...infoplayer, ...(data?.result?.events || [])];
        const matchList = [...infoMatch, ...(data?.result?.players || [])];
        setPlayerListPageData(playerList);
        setPlayerMatchData(matchList);
      } else {
        setListPageLoader(false);
      }
    } catch (err) {
      setListPageLoader(false);
    }
  };

  const handlePlayerMoreData = () => {
    setPlayerOffset(playerOffset + 5);
    fetchPlayerListDetails(playerOffset + 5, playerListPageData, []);
  };
  const handlePlayerLessData = () => {
    setPlayerOffset(playerOffset - 5);
    if (playerListPageData?.length % 5 === 0) {
      setPlayerListPageData(prevArray => prevArray.slice(0, -5));
    } else {
      setPlayerListPageData(prevArray => prevArray.slice(0, -playerListCount));
    }
  };

  const handleMatchMoreData = () => {
    setMatchOffset(matchOffset + 5);
    fetchPlayerListDetails(matchOffset + 5, [], playerMatchData);
  };
  const handleMatchLessData = () => {
    setMatchOffset(matchOffset - 5);
    if (playerMatchData?.length % 5 === 0) {
      setPlayerMatchData(prevArray => prevArray.slice(0, -5));
    } else {
      setPlayerMatchData(prevArray => prevArray.slice(0, -matchListCount));
    }
  };

  const getTeamScores = data => {
    const teamScores =
      Number(params?.sportId) === 4
        ? data?.CricketScores
        : Number(params?.sportId) === 10
        ? data?.NBAScores
        : Number(params?.sportId) === 12 ||
          Number(params?.sportId) === 13 ||
          Number(params?.sportId) === 14
        ? data?.RLScores
        : Number(params?.sportId) === 15
        ? data?.AFLScores
        : Number(params?.sportId) === 9
        ? data?.ARScores
        : Number(params?.sportId) === 16
        ? data?.GolfScores
        : Number(params?.sportId) === 7
        ? data?.TennisScores
        : Number(params?.sportId) === 11
        ? data?.BaseballScores
        : Number(params?.sportId) === 17
        ? data?.IceHockeyScores
        : Number(params?.sportId) === 6
        ? data?.BoxingScores
        : Number(params?.sportId) === 5
        ? data?.MMAScores
        : Number(params?.sportId) === 8
        ? data?.SoccerScores
        : "-";
    const hometeamScore = teamScores?.filter(
      item => item?.teamId === Number(params?.teamId)
    );
    const awayTeamScore = teamScores?.filter(
      item => item?.teamId !== Number(params?.teamId)
    );

    const homeScore =
      Number(params?.sportId) === 4
        ? hometeamScore?.[0]?.score?.innings?.inning1
          ? hometeamScore?.[0]?.score?.innings?.inning1?.score +
            "/" +
            hometeamScore?.[0]?.score?.innings?.inning1?.wickets +
            " " +
            "(" +
            hometeamScore?.[0]?.score?.innings?.inning1?.overs +
            ")" +
            (hometeamScore?.[0]?.score?.innings?.inning2
              ? "&" +
                (hometeamScore?.[0]?.score?.innings?.inning2?.score +
                  "/" +
                  hometeamScore?.[0]?.score?.innings?.inning2?.wickets +
                  " " +
                  "(" +
                  hometeamScore?.[0]?.score?.innings?.inning2?.overs +
                  ")")
              : "")
          : "-"
        : hometeamScore?.[0]?.score?.current;

    const awayScore =
      Number(params?.sportId) === 4
        ? awayTeamScore?.[0]?.score?.innings?.inning1
          ? awayTeamScore?.[0]?.score?.innings?.inning1?.score +
            "/" +
            awayTeamScore?.[0]?.score?.innings?.inning1?.wickets +
            " " +
            "(" +
            awayTeamScore?.[0]?.score?.innings?.inning1?.overs +
            ")" +
            (awayTeamScore?.[0]?.score?.innings?.inning2
              ? "&" +
                (awayTeamScore?.[0]?.score?.innings?.inning2?.score +
                  "/" +
                  awayTeamScore?.[0]?.score?.innings?.inning2?.wickets +
                  " " +
                  "(" +
                  awayTeamScore?.[0]?.score?.innings?.inning2?.overs +
                  ")")
              : "")
          : "-"
        : awayTeamScore?.[0]?.score?.current;
    return homeScore + "-" + awayScore;
  };

  const handleNavigate = data => {
    const stateToPass = {
      viewListPage: data?.id,
      viewPage: true
      // Add your state data here
    };
    navigate(
      `/sport/statistics/${params?.sportId}/${
        params?.tournamentId
      }/league-data/${params?.teamId}/team-data?seasonId=${
        SeasonId ? SeasonId : ""
      }`,
      {
        state: stateToPass
      }
    );
  };

  const handleChange = (id, value) => {
    setValue(id);
    setLabel(value);
  };
  return (
    <>
      <Box className="view-page-wrap list-page-wrap">
        <Box className="teamsport-header-details">
          <Typography className="heading-title">List Type</Typography>
        </Box>
        <Box className="view-round-wrap">
          {/* <Box className="round-details-wrap">
            <Box className="round-name active">Match list</Box>
            <Box className="round-name">Player list</Box>
          </Box> */}
          <Tabs
            value={value}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            className="list-tab"
            // disableRipple
            // disableFocusRipple
            id="news-tab"
          >
            {Listtab?.map((item, index) => {
              return (
                <Box key={index}>
                  <Tab
                    label={item?.label}
                    value={item?.id}
                    onChange={() => handleChange(item?.id, item?.value)}
                    className={item?.id == value ? "active" : ""}
                  />
                </Box>
              );
            })}
          </Tabs>
        </Box>
        {/* <Box className="round-view-details">
          <Box className="teamsport-header-details details-header">
            <Typography className="heading-title">Parameters</Typography>
            <Box className="tab-details-grid mt-34 mb-9">
              <Box className="details-box">
                <Typography className="details-text bold">Columns:</Typography>
                <Typography className="details-text light-blue">
                  num, date_short, year,comp, round, for_team_nick, wdl,
                  score_teamfirst, against_team_nick, halftime_score_teamfirst
                  ,number,position,events
                </Typography>
              </Box>
            </Box>
            <Box className="tab-details-grid">
              <Box className="details-box">
                <Typography className="details-text bold">Options:</Typography>
                <Typography className="details-text">-</Typography>
              </Box>
              <Box className="details-box">
                <Typography className="details-text bold">
                  Competition: :
                </Typography>
                <Typography className="details-text">-</Typography>
              </Box>
              <Box className="details-box">
                <Typography className="details-text bold">Team:</Typography>
                <Typography className="details-text">-</Typography>
              </Box>
              <Box className="details-box">
                <Typography className="details-text bold">Player:</Typography>
                <Typography className="details-text">{playerName}</Typography>
              </Box>
            </Box>
          </Box>
        </Box> */}
        <Box className="player-games-wrap">
          <Box className="short-name-details">
            <Typography className="short-name">
              H - <span>Home</span>, A - <span>Away</span>, N -{" "}
              <span>Neutral/Alternative Venue</span>, L - <span>Loss</span>, W -{" "}
              <span>Win</span>, D - <span>Draw</span>
            </Typography>
          </Box>
          <Box className="teamsport-header-details">
            <Typography className="heading-title">Results</Typography>
          </Box>
          {listPageLoader && (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          )}
          {!listPageLoader && (
            <>
              <Typography className="note" variant="p">
                To view more info on a match, click the <span>View</span>{" "}
                button.
              </Typography>
              <Box className="table-overlay">
                {label === "match" ? (
                  <>
                    <TableContainer
                      id="details-table"
                      className="teamsport-data-table team-sport-tables player-data-table"
                    >
                      {/* <Box className="overlay"></Box> */}
                      <Table
                        className="data-table"
                        style={{ minWidth: "max-content" }}
                      >
                        <TableHead className="table-header">
                          <TableRow className="table-head-row ">
                            <TableCell className={`${classes.head} first-cell`}>
                              Date
                            </TableCell>
                            <TableCell className={classes.head}>Year</TableCell>
                            <TableCell className={classes.head}>
                              Competition
                            </TableCell>
                            <TableCell className={classes.head}>
                              Round
                            </TableCell>
                            <TableCell className={classes.head}>Team</TableCell>
                            <TableCell className={classes.head}></TableCell>
                            <TableCell className={classes.head}>
                              Score
                            </TableCell>
                            <TableCell className={classes.head}>
                              Opposition
                            </TableCell>
                            <TableCell className={classes.head}>
                              Halftime
                            </TableCell>
                            <TableCell className={classes.head}>
                              Number
                            </TableCell>
                            <TableCell className={classes.head}>Pos</TableCell>
                            <TableCell className={classes.head}>
                              Scooring
                            </TableCell>
                            <StickyTableCell
                              className={classes.head}
                            ></StickyTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="table-body">
                          {playerListPageData?.length > 0 ? (
                            <>
                              {playerListPageData?.map((item, i) => {
                                return (
                                  <>
                                    <TableRow
                                      key={i}
                                      className="table-body-row "
                                    >
                                      <TableCell
                                        className={`${classes.cell} first-cell`}
                                      >
                                        {item?.startTime
                                          ? moment(item?.startTime).format(
                                              "MMM Do"
                                            )
                                          : "-"}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.startTime
                                          ? moment(item?.startTime).format(
                                              "YYYY"
                                            )
                                          : "-"}
                                      </TableCell>
                                      <TableCell
                                        className={`${classes.cell} light-blue cursor-pointer`}
                                        onClick={() =>
                                          navigate(
                                            `/sport/statistics/${
                                              params?.sportId
                                            }/${
                                              Number(params?.sportId) === 4
                                                ? item?.CricketTournament?.id
                                                : Number(params?.sportId) === 10
                                                ? item?.NBATournament?.id
                                                : Number(params?.sportId) ===
                                                    12 ||
                                                  Number(params?.sportId) ===
                                                    13 ||
                                                  Number(params?.sportId) === 14
                                                ? item?.RLTournament?.id
                                                : Number(params?.sportId) === 15
                                                ? item?.AFLTournament?.id
                                                : Number(params?.sportId) === 9
                                                ? item?.ARTournament?.id
                                                : Number(params?.sportId) === 16
                                                ? item?.GolfTournament?.id
                                                : Number(params?.sportId) === 7
                                                ? item?.TennisTournament?.id
                                                : Number(params?.sportId) === 11
                                                ? item?.BaseballTournament?.id
                                                : Number(params?.sportId) === 17
                                                ? item?.IceHockeyTournament?.id
                                                : Number(params?.sportId) === 6
                                                ? item?.BoxingTournament?.id
                                                : Number(params?.sportId) === 5
                                                ? item?.MMATournament?.id
                                                : Number(params?.sportId) === 8
                                                ? item?.SoccerTournament?.id
                                                : "-"
                                            }/league-data?seasonId=${
                                              SeasonId ? SeasonId : ""
                                            }`
                                          )
                                        }
                                      >
                                        {/* {item?.RLTournament
                                          ? item?.RLTournament?.name
                                          : ""} */}
                                        {Number(params?.sportId) === 4
                                          ? item?.CricketTournament?.name
                                          : Number(params?.sportId) === 10
                                          ? item?.NBATournament?.name
                                          : Number(params?.sportId) === 12 ||
                                            Number(params?.sportId) === 13 ||
                                            Number(params?.sportId) === 14
                                          ? item?.RLTournament?.name
                                          : Number(params?.sportId) === 15
                                          ? item?.AFLTournament?.name
                                          : Number(params?.sportId) === 9
                                          ? item?.ARTournament?.name
                                          : Number(params?.sportId) === 16
                                          ? item?.GolfTournament?.name
                                          : Number(params?.sportId) === 7
                                          ? item?.TennisTournament?.name
                                          : Number(params?.sportId) === 11
                                          ? item?.BaseballTournament?.name
                                          : Number(params?.sportId) === 17
                                          ? item?.IceHockeyTournament?.name
                                          : Number(params?.sportId) === 6
                                          ? item?.BoxingTournament?.name
                                          : Number(params?.sportId) === 5
                                          ? item?.MMATournament?.name
                                          : Number(params?.sportId) === 8
                                          ? item?.SoccerTournament?.name
                                          : "-"}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.round
                                          ? "Round " + item?.round
                                          : "-"}
                                      </TableCell>
                                      <TableCell
                                        className={`${classes.cell} light-blue cursor-pointer`}
                                        onClick={() =>
                                          navigate(
                                            `/sport/statistics/${
                                              params?.sportId
                                            }/${
                                              Number(params?.sportId) === 4
                                                ? item?.CricketTournament?.id
                                                : Number(params?.sportId) === 10
                                                ? item?.NBATournament?.id
                                                : Number(params?.sportId) ===
                                                    12 ||
                                                  Number(params?.sportId) ===
                                                    13 ||
                                                  Number(params?.sportId) === 14
                                                ? item?.RLTournament?.id
                                                : Number(params?.sportId) === 15
                                                ? item?.AFLTournament?.id
                                                : Number(params?.sportId) === 9
                                                ? item?.ARTournament?.id
                                                : Number(params?.sportId) === 16
                                                ? item?.GolfTournament?.id
                                                : Number(params?.sportId) === 7
                                                ? item?.TennisTournament?.id
                                                : Number(params?.sportId) === 11
                                                ? item?.BaseballTournament?.id
                                                : Number(params?.sportId) === 17
                                                ? item?.IceHockeyTournament?.id
                                                : Number(params?.sportId) === 6
                                                ? item?.BoxingTournament?.id
                                                : Number(params?.sportId) === 5
                                                ? item?.MMATournament?.id
                                                : Number(params?.sportId) === 8
                                                ? item?.SoccerTournament?.id
                                                : "-"
                                            }/league-data/${
                                              Number(params?.teamId) ===
                                              item?.homeTeamId
                                                ? item?.homeTeamId
                                                : item?.awayTeamId
                                            }/team-data?seasonId=${
                                              SeasonId ? SeasonId : ""
                                            }`
                                          )
                                        }
                                      >
                                        {" "}
                                        {Number(params?.teamId) ===
                                        item?.homeTeamId
                                          ? item?.homeTeam?.name
                                          : item?.awayTeam?.name}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {" "}
                                        {item?.winnerCode === 1 ? "W" : "L"}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {getTeamScores(item)}
                                      </TableCell>
                                      <TableCell
                                        className={`${classes.cell} light-blue cursor-pointer`}
                                        onClick={() =>
                                          navigate(
                                            `/sport/statistics/${
                                              params?.sportId
                                            }/${
                                              Number(params?.sportId) === 4
                                                ? item?.CricketTournament?.id
                                                : Number(params?.sportId) === 10
                                                ? item?.NBATournament?.id
                                                : Number(params?.sportId) ===
                                                    12 ||
                                                  Number(params?.sportId) ===
                                                    13 ||
                                                  Number(params?.sportId) === 14
                                                ? item?.RLTournament?.id
                                                : Number(params?.sportId) === 15
                                                ? item?.AFLTournament?.id
                                                : Number(params?.sportId) === 9
                                                ? item?.ARTournament?.id
                                                : Number(params?.sportId) === 16
                                                ? item?.GolfTournament?.id
                                                : Number(params?.sportId) === 7
                                                ? item?.TennisTournament?.id
                                                : Number(params?.sportId) === 11
                                                ? item?.BaseballTournament?.id
                                                : Number(params?.sportId) === 17
                                                ? item?.IceHockeyTournament?.id
                                                : Number(params?.sportId) === 6
                                                ? item?.BoxingTournament?.id
                                                : Number(params?.sportId) === 5
                                                ? item?.MMATournament?.id
                                                : Number(params?.sportId) === 8
                                                ? item?.SoccerTournament?.id
                                                : "-"
                                            }/league-data/${
                                              Number(params?.teamId) ===
                                              item?.homeTeamId
                                                ? item?.awayTeamId
                                                : item?.homeTeamId
                                            }/team-data?seasonId=${
                                              SeasonId ? SeasonId : ""
                                            }`
                                          )
                                        }
                                      >
                                        {" "}
                                        {Number(params?.teamId) ===
                                        item?.homeTeamId
                                          ? item?.awayTeam?.name
                                          : item?.homeTeam?.name}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        -
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        -
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        -
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        -
                                      </TableCell>
                                      <StickyTableCell className={classes.cell}>
                                        <span
                                          className="view-btn cursor-pointer"
                                          onClick={() => {
                                            setListPage(false);
                                            handleNavigate(item);
                                          }}
                                        >
                                          View
                                        </span>
                                      </StickyTableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <TableRow>
                              <TableCell colSpan={100} className="no-data-td">
                                No Data Available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {playerListPageData?.length > 0 ? (
                      <Box className="view-more">
                        {playerOffset + 5 === playerListPageData?.length ? (
                          <span
                            className="view-more-text"
                            onClick={() => handlePlayerMoreData()}
                          >
                            View More
                          </span>
                        ) : (
                          <></>
                        )}
                        {playerOffset >= 5 && playerListPageData?.length > 5 ? (
                          <span
                            className="view-more-text view-lese-text"
                            onClick={() => handlePlayerLessData()}
                          >
                            View Less
                          </span>
                        ) : (
                          <></>
                        )}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <TableContainer
                      id="details-table"
                      className="teamsport-data-table team-sport-tables player-data-table"
                    >
                      {/* <Box className="overlay"></Box> */}
                      <Table
                        className="data-table"
                        style={{ minWidth: "max-content" }}
                      >
                        <TableHead className="table-header">
                          <TableRow className="table-head-row ">
                            <TableCell className={`${classes.head} first-cell`}>
                              Player
                            </TableCell>
                            <TableCell className={classes.head}>Age</TableCell>

                            <TableCell className={classes.head}>Rep</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="table-body">
                          {playerMatchData?.length > 0 ? (
                            <>
                              {playerMatchData?.map((item, i) => {
                                return (
                                  <>
                                    <TableRow
                                      key={i}
                                      className="table-body-row "
                                    >
                                      <TableCell
                                        className={`${classes.cell} first-cell`}
                                      >
                                        {item?.name ? item?.name : "-"}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.dob
                                          ? moment().diff(
                                              moment(item?.dob),
                                              "years"
                                            )
                                          : "-"}
                                      </TableCell>

                                      <TableCell className={classes.cell}>
                                        {item?.Country
                                          ? item?.Country?.country
                                          : "-"}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <TableRow>
                              <TableCell colSpan={100} className="no-data-td">
                                No Data Available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {playerMatchData?.length > 0 ? (
                      <Box className="view-more">
                        {matchOffset + 5 === playerMatchData?.length ? (
                          <span
                            className="view-more-text"
                            onClick={() => handleMatchMoreData()}
                          >
                            View More
                          </span>
                        ) : (
                          <></>
                        )}
                        {matchOffset >= 5 && playerMatchData?.length > 5 ? (
                          <span
                            className="view-more-text view-lese-text"
                            onClick={() => handleMatchLessData()}
                          >
                            View Less
                          </span>
                        ) : (
                          <></>
                        )}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ListPage;
