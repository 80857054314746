import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useDispatch } from "react-redux";
import { loginUser } from "src/helpers/store/Actions/auth";
import { IntlContext } from "src/App";
import { ReactComponent as BackArrow } from "../../../../assets/images/Back_Arrow.svg";
import Loader from "src/components/Loader";
import { Config } from "src/helpers/context";
import DefaultImg from "src/assets/images/smartb_default.png";
import { setApiMessage } from "src/helpers/commonFunctions";

function BookmakersEdit({ passData, handleUserProfile }) {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [newData, setnewData] = useState(passData);
  const [checkboxValue, setcheckboxValue] = useState(newData?.bookMaker);
  const [bookMakerOther, setbookMakerOther] = useState(
    newData?.bookMakerOther?.join("")
  );
  const [isLoading, setisLoading] = useState(false);
  const [bookmakersList, setBookmakersList] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    fetchBookKeeper();
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  const fetchBookKeeper = async () => {
    setisLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `apiProviders/bookKeepers`
      );
      if (status === 200) {
        setisLoading(false);
        setBookmakersList(data?.result);
      } else {
      }
    } catch (err) {}
  };
  const fetchLabel = element => {
    return (
      <>
        <Box className="checkbox-label-wrap">
          {screenWidth > 799 ? (
            <span className="bookeeper-image-wrap">
              <img
                className="bookeeper-image"
                src={
                  element?.currentBest_logo
                    ? Config?.baseURL + element?.currentBest_logo
                    : DefaultImg
                }
                alt="bookeeper"
              />
            </span>
          ) : (
            <span className="bookeeper-image-wrap">
              <img
                className="bookeeper-image"
                src={
                  element?.long_logo
                    ? Config?.baseURL + element?.long_logo
                    : DefaultImg
                }
                alt="bookeeper"
              />
            </span>
          )}

          <span className="bookeeper-name">{element?.name}</span>
        </Box>
      </>
    );
  };
  const handleOnChange = e => {
    const { value, checked } = e.target;
    if (checked === true) {
      let checkboxdata = [...checkboxValue, value];
      setcheckboxValue(checkboxdata);
      // setnewData([])
    } else {
      let checkboxdata = checkboxValue?.filter(element => element !== value);
      setcheckboxValue(checkboxdata);
    }
  };

  const handleBookmakerOther = e => {
    setbookMakerOther(e.target.value);
  };
  const handleSave = async () => {
    setisLoading(true);
    const payload = {
      bookMaker: checkboxValue?.length ? checkboxValue : ""
      // bookMakersOther: checkboxValue?.includes("Other - Please specify")
      //   ? bookMakerOther
      //     ? [bookMakerOther]
      //     : []
      //   : [],
      // sportOrEvent: newData?.sportOrEvent,
      // sportOrEventOther: newData?.sportOrEventOther,
      // NotificationPreferences: newData?.NotificationPreferences
      // offerings: newData?.offerings,
      // offeringsOther: newData?.offeringsOther
    };

    try {
      const { status, data } = await axiosInstance.put(
        "user/editProfile",
        payload
      );
      if (status === 200) {
        // setuserData(data);
        setisLoading(false);
        // navigate("/profile");
        handleUserProfile();
        setApiMessage("success", data?.message);
      } else if (status === 403) {
        setisLoading(false);
        localStorage.removeItem("auth_token");
        navigate("/sign-in");
        dispatch(loginUser(""));
      } else {
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
      setApiMessage("error", error?.response?.data?.message);
    }
  };

  const handleBack = () => {
    navigate("/profile");
  };
  return (
    <>
      {isLoading ? (
        <div className="allsport-loader-center">
          <Loader />
        </div>
      ) : (
        <Box>
          <Box className="useredit-details" onClick={() => handleBack()}>
            {/* <span>
              <BackArrow className="back-arrow" />
            </span> */}
            <h4>{localesData?.EDITPROFILE?.EDIT_BOOKMAKER_ACCOUNTS}</h4>
          </Box>

          <Box className="checkbox-list profile-bookmaker-list">
            <Box className="checkbox-list-1">
              {bookmakersList?.map(element => (
                <FormControlLabel
                  className="bookmakers-check"
                  key={element.id}
                  onChange={e => handleOnChange(e)}
                  control={<Checkbox className="bookmakers-checkbox" />}
                  label={fetchLabel(element)}
                  value={element?.name}
                  name="bookmakers"
                  checked={checkboxValue?.includes(element?.name)}
                />
              ))}
            </Box>
            {/* <Box className="other-filed filed-1 ">
              <Box></Box>
              <Box>
                {checkboxValue?.includes("Other") ? (
                  <Box>
                    <TextField
                      placeholder={""}
                      variant="outlined"
                      type="text"
                      name="OtherPleaseSpecify"
                      className="text-field"
                      value={bookMakerOther}
                      onChange={e => handleBookmakerOther(e)}
                    />
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Box> */}
          </Box>

          <Box className="profile-button">
            <Box className="profile-btn">
              {/* <Button
                variant="outlined"
                className="btn-cancel"
                onClick={() => handleBack()}
              >
                {localesData?.EDITPROFILE?.CANCEL}
              </Button> */}
              <Button
                variant="contained"
                className="btn-save"
                onClick={() => handleSave()}
              >
                {localesData?.EDITPROFILE?.SAVE_CHANGES}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default BookmakersEdit;
