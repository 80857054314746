import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { IntlContext } from "src/App";
import { useNavigate, useParams } from "react-router-dom";
import AboveTable from "../../../../../assets/images/ad-placeholder/sports1.webp";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment";
import Loader from "src/components/Loader";
import NoDataComp from "src/views/component/UI/NoData";

function ResultsDetails({ fetchAds, headerData, trackType, trackIdModal }) {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const params = useParams();
  let navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [lastResult, setlastResult] = useState([]);
  const [isLastResultLoading, setisLastResultLoading] = useState(false);
  const [nextResult, setnextResult] = useState([]);
  const [isNextResultLoading, setisNextResultLoading] = useState(false);
  const [trackName, setTrackName] = useState("");
  const checktrackcondition = data => {
    if (data.toLowerCase().includes("heavy")) {
      return "heavy";
    } else if (data.toLowerCase().includes("soft")) {
      return "soft";
    } else {
      return "good";
    }
  };
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchLastResult();
    fetchNextResult();
  }, []);
  useEffect(() => {
    if (headerData) {
      setTrackName(headerData?.track?.name);
    }
  }, [headerData]);

  const fetchLastResult = async () => {
    setisLastResultLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/result/${
          trackType == "modal" ? trackIdModal : params?.trackId
        }?limit=5&type=upcoming&offset=0`
      );
      if (status === 200) {
        setisLastResultLoading(false);

        let newData = data?.result?.map(item => {
          return {
            ...item,
            RaceResultSummary: item?.RaceResultSummary
              ? JSON.parse(item?.RaceResultSummary?.summary)
              : []
          };
        });
        let standingData = newData?.map(item => {
          return {
            ...item,
            RaceResultSummary: item?.RaceResultSummary?.map(obj => {
              return {
                ...obj,
                RunnerDetails: item?.runner?.filter(runner => {
                  return obj?.runner_number
                    ? runner?.runnerNumber === Number(obj?.runner_number)
                    : runner?.runnerNumber === Number(obj?.RunnerNumber);
                })
              };
            })
          };
        });
        setlastResult(standingData);
      } else {
        setisLastResultLoading(false);
      }
    } catch (error) {
      setisLastResultLoading(false);
    }
  };
  const fetchNextResult = async () => {
    setisNextResultLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/result/${
          trackType == "modal" ? trackIdModal : params?.trackId
        }?limit=20&offset=5`
      );
      if (status === 200) {
        setisNextResultLoading(false);

        setnextResult(data?.result);
      } else {
        setisNextResultLoading(false);
      }
    } catch (error) {
      setisNextResultLoading(false);
    }
  };
  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };
  return (
    <>
      {trackType == "modal" ? "" : fetchAds(1, "102px", AboveTable)}
      <Box className="results-details-wrap">
        <Box
          className={
            trackType == "modal"
              ? "results-details-wrap-1 modal-results-details-wrap-1"
              : "results-details-wrap-1"
          }
        >
          <Typography variant="h1">
            {trackType == "modal" ? "Upcoming" : "Last 5 Races"}{" "}
          </Typography>
          {isLastResultLoading ? (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          ) : lastResult?.length > 0 ? (
            lastResult?.map(item => {
              return screenWidth > 799 ? (
                <Table className="result-table">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} style={{ width: "55%" }}>
                        <span> Race {item?.raceNumber} </span>
                        <span className="table-head-des">{item?.raceName}</span>
                        <br />
                        <span className="race-des">
                          {item?.Distance ? item?.Distance?.name + "m" : ""}{" "}
                          {item?.prizeMoney ? item?.prizeMoney + "$" : ""}{" "}
                          {item?.trackCondition}
                        </span>
                        <br />
                        <span className="race-des">
                          {fetchDayName(item?.startDate)} at{" "}
                          {item?.startDate
                            ? moment(item?.startDate)?.format("hh:mm a")
                            : ""}
                        </span>
                      </TableCell>
                      <TableCell className="tope-tote" style={{ width: "15%" }}>
                        {localesData?.sport?.TOPE_TOTE}
                      </TableCell>
                      <TableCell style={{ width: "15%" }}>
                        {localesData?.sport?.SP}
                      </TableCell>
                      <TableCell style={{ width: "15%" }}>
                        {localesData?.sport?.MARGIN}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item?.RaceResultSummary?.slice(0, 4)?.map((obj, i) => (
                      <TableRow key={i}>
                        <TableCell style={{ width: "30%" }}>
                          <span
                            className={
                              obj?.position
                                ? Number(obj?.position) === 1 ||
                                  Number(obj?.position) === 2 ||
                                  Number(obj?.position) === 3
                                  ? "winner"
                                  : "rank"
                                : Number(obj?.Position) === 1 ||
                                  Number(obj?.Position) === 2 ||
                                  Number(obj?.Position) === 3
                                ? "winner"
                                : "rank"
                            }
                          >
                            {obj?.position ? obj?.position : obj?.Position}
                          </span>
                          <span className="runner-number">
                            {obj?.runner_number
                              ? obj?.runner_number
                              : obj?.RunnerNumber}
                          </span>
                          <span
                            onClick={() =>
                              trackType == "modal"
                                ? ""
                                : obj?.RunnerDetails &&
                                  obj?.RunnerDetails?.[0]?.TrainerId &&
                                  obj?.RunnerDetails?.[0]?.animal?.id
                                ? navigate(
                                    `/statistics/trackprofiles/${
                                      trackType == "modal"
                                        ? trackIdModal
                                        : params?.trackId
                                    }/trainer/${
                                      obj?.RunnerDetails?.[0]?.TrainerId
                                    }/${obj?.RunnerDetails?.[0]?.animal?.id}`
                                  )
                                : ""
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {obj?.name ? obj?.name : obj?.Name}
                          </span>
                        </TableCell>
                        <TableCell
                          className="jockey"
                          style={{ width: "25%", cursor: "pointer" }}
                          onClick={() =>
                            trackType == "modal"
                              ? ""
                              : obj?.RunnerDetails &&
                                obj?.RunnerDetails?.[0]?.JockeyId
                              ? navigate(
                                  `/statistics/trackprofiles/${
                                    trackType == "modal"
                                      ? trackIdModal
                                      : params?.trackId
                                  }/jockey/${obj?.RunnerDetails?.[0]?.JockeyId}`
                                )
                              : ""
                          }
                        >
                          {" "}
                          J :{" "}
                          {obj?.RunnerDetails
                            ? obj?.RunnerDetails?.[0]?.Jockey
                              ? obj?.RunnerDetails?.[0]?.Jockey?.name
                              : "N/A"
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {obj?.toptote ? obj?.toptote : "N/A"}
                        </TableCell>
                        <TableCell>
                          {obj?.RunnerDetails?.[0]?.MarketRelations?.[0]
                            ?.RaceOdds?.length > 0
                            ? "$" +
                              obj?.RunnerDetails?.[0]?.MarketRelations?.[0]
                                ?.RaceOdds?.[0]?.intValue
                            : obj?.RunnerDetails?.[0]?.MarketRelations?.[1]
                                ?.RaceOdds?.length > 0
                            ? "$" +
                              obj?.RunnerDetails?.[0]?.MarketRelations?.[1]
                                ?.RaceOdds?.[0]?.intValue
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {obj?.margin_length}
                          {obj?.MarginLength}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Box className="mobileview-result-wrap">
                  <Box className="mobile-result-table-header">
                    <Box>
                      <span className="racetrack-number">
                        {" "}
                        Race {item?.raceNumber}{" "}
                      </span>
                      <span className="table-head-des">{item?.raceName}</span>
                    </Box>
                    <span className="race-des">
                      {item?.Distance ? item?.Distance?.name + "m" : ""}{" "}
                      {item?.prizeMoney ? item?.prizeMoney + "$" : ""}{" "}
                      {item?.trackCondition}
                    </span>
                    <br />
                    <span className="race-des">
                      {fetchDayName(item?.startDate)} at{" "}
                      {item?.startDate
                        ? moment(item?.startDate)?.format("hh:mm a")
                        : ""}
                    </span>
                  </Box>
                  <Box className="mobileview-race-details">
                    {item?.RaceResultSummary?.slice(0, 4)?.map((obj, i) => (
                      <Box className="mobileview-result-detail-wrap">
                        <Box className="animal-jockey">
                          <Box>
                            <span
                              className={
                                obj?.position
                                  ? Number(obj?.position) === 1 ||
                                    Number(obj?.position) === 2 ||
                                    Number(obj?.position) === 3
                                    ? "winner"
                                    : "rank"
                                  : Number(obj?.Position) === 1 ||
                                    Number(obj?.Position) === 2 ||
                                    Number(obj?.Position) === 3
                                  ? "winner"
                                  : "rank"
                              }
                            >
                              {obj?.position ? obj?.position : obj?.Position}
                            </span>
                            <span className="runnernumber-animal">
                              <span className="runner-number">
                                {obj?.runner_number
                                  ? obj?.runner_number
                                  : obj?.RunnerNumber}
                              </span>
                              <span
                                onClick={() =>
                                  trackType == "modal"
                                    ? ""
                                    : obj?.RunnerDetails &&
                                      obj?.RunnerDetails?.[0]?.TrainerId &&
                                      obj?.RunnerDetails?.[0]?.animal?.id
                                    ? navigate(
                                        `/statistics/trackprofiles/${
                                          trackType == "modal"
                                            ? trackIdModal
                                            : params?.trackId
                                        }/trainer/${
                                          obj?.RunnerDetails?.[0]?.TrainerId
                                        }/${
                                          obj?.RunnerDetails?.[0]?.animal?.id
                                        }`
                                      )
                                    : ""
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {obj?.name}
                              </span>
                            </span>
                          </Box>
                          <Box
                            className="jockey"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              trackType == "modal"
                                ? ""
                                : obj?.RunnerDetails &&
                                  obj?.RunnerDetails?.[0]?.JockeyId
                                ? navigate(
                                    `/statistics/trackprofiles/${
                                      trackType == "modal"
                                        ? trackIdModal
                                        : params?.trackId
                                    }/jockey/${
                                      obj?.RunnerDetails?.[0]?.JockeyId
                                    }`
                                  )
                                : ""
                            }
                          >
                            {" "}
                            J :{" "}
                            {obj?.RunnerDetails
                              ? obj?.RunnerDetails?.[0]?.Jockey
                                ? obj?.RunnerDetails?.[0]?.Jockey?.name
                                : "N/A"
                              : "N/A"}
                          </Box>
                        </Box>
                        <Box className="animal-performance">
                          <span className="tope-tote">
                            <Box className="des-label">
                              {localesData?.sport?.TOPE_TOTE}
                            </Box>
                            <Box className="des-info">
                              {obj?.toptote ? obj?.toptote : "N/A"}
                            </Box>
                          </span>
                          <span className="sp">
                            <Box className="des-label">
                              {localesData?.sport?.SP}
                            </Box>
                            <Box className="des-info">
                              {" "}
                              {obj?.RunnerDetails?.[0]?.MarketRelations?.[0]
                                ?.RaceOdds?.length > 0
                                ? "$" +
                                  obj?.RunnerDetails?.[0]?.MarketRelations?.[0]
                                    ?.RaceOdds?.[0]?.intValue
                                : obj?.RunnerDetails?.[0]?.MarketRelations?.[1]
                                    ?.RaceOdds?.length > 0
                                ? "$" +
                                  obj?.RunnerDetails?.[0]?.MarketRelations?.[1]
                                    ?.RaceOdds?.[0]?.intValue
                                : "N/A"}
                            </Box>
                          </span>
                          <span>
                            <Box className="des-label">
                              {localesData?.sport?.MARGIN}
                            </Box>
                            <Box className="des-info">
                              {" "}
                              {obj?.margin_length}
                              {obj?.MarginLength}
                            </Box>
                          </span>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box
              style={{
                textAlign: "center",
                fontFamily: "sans-serif",
                fontSize: "16px",
                padding: "10px 0px"
              }}
            >
              {/* {" "}
              No Data Available{" "} */}
              <NoDataComp />
            </Box>
          )}
        </Box>

        <Box
          className={
            trackType == "modal"
              ? "results-details-wrap-2 modal-results-details-wrap-2"
              : "results-details-wrap-2"
          }
        >
          <Box className="latestresult-wrap">
            <Typography variant="h1">
              {" "}
              {localesData?.sport?.LATEST_RESULTS_AT} {trackName}
            </Typography>
            {isNextResultLoading ? (
              <div className="allsport-loader-center">
                <Loader />
              </div>
            ) : (
              <Table className="latest-result-table">
                <TableBody>
                  {nextResult?.length > 0 ? (
                    nextResult?.map((item, i) => (
                      <TableRow key={i}>
                        <TableCell>
                          <span className="resultlist">
                            <span>
                              {" "}
                              {fetchDayName(item?.startDate)}{" "}
                              {moment
                                .utc(item?.startDate)
                                .format("Do MMMM YYYY")}{" "}
                            </span>
                            <span className="resultlist-detail">
                              {item?.raceName}{" "}
                              {item?.Distance ? item?.Distance?.name + "m" : ""}{" "}
                              {item?.prizeMoney ? item?.prizeMoney + "$" : ""}
                            </span>
                          </span>
                        </TableCell>
                        <TableCell className="track-condition-wrap">
                          {item?.trackCondition ? (
                            <span
                              className={`track-condition ${checktrackcondition(
                                item?.trackCondition
                              )}`}
                            >
                              {item?.trackCondition}
                            </span>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <Box
                      style={{
                        textAlign: "center",
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        padding: "10px 0px"
                      }}
                    >
                      {/* {" "}
                      No Data Available{" "} */}
                      <NoDataComp />
                    </Box>
                  )}
                </TableBody>
              </Table>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ResultsDetails;
