import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import {
  Box,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  ClickAwayListener,
  List,
  ListItem,
} from "@mui/material";
import { ReactComponent as ResetAll } from "src/assets/images/oddsComparison/reset-all.svg";
import { ReactComponent as RacingIcon } from "src/assets/images/icons/racing-icon.svg";
import { ReactComponent as SportIcon } from "src/assets/images/icons/sport-icon.svg";
import { ReactComponent as CheckBoxChecked } from "src/assets/images/icons/checkbox-add-green.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/icons/checkbox-remove-red.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as Plus } from "../../../../../assets/images/icons/plus-layout.svg";
import { ReactComponent as Minus } from "../../../../../assets/images/icons/minus -layout.svg";
import { ReactComponent as DragAndDropIcon } from "../../../../../assets/images/icons/dragAndDropIcon.svg";
import { ReactComponent as DragAndDropIconDesktop } from "../../../../../assets/images/icons/drag_drop_desktop.svg";
import { Config } from "src/helpers/context";
import CustomDropdown from "./customDropdown";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import { IntlContext } from "src/App";
import { useDispatch, useSelector } from "react-redux";
import SportCustomDropdown from "./sportCustomDropdown";
import "./filterModal.scss";
import { fetchLayoutData } from "src/helpers/store/Actions/LayoutData";
import { setApiMessage } from "src/helpers/commonFunctions";
import {
  HomePageListing,
  SportPageListing,
  RacingPageListing,
} from "./homePageListingArray";
import { fetchSubscriptionData } from "src/helpers/store/Actions/SubscriptionData";

const resetPayload = {
  isReset: true,
};

const CustomiseFilterModal = ({ layoutType, customiseTabValue }) => {
  const sportData = useSelector((state) => state?.reduxData?.SportData);
  const layoutData = useSelector((state) => state?.reduxData?.LayoutData);
  const reduxSubscriptionData = useSelector(
    (state) => state?.reduxData?.SubscripitionData,
  );
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const dispatch = useDispatch();
  const dropdownRefs = useRef([]);
  const dropdownCountryRef = useRef([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectBoxIndex, setSelectBoxIndex] = useState(null);
  const [selectSportIndex, setSelectSportIndex] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [countryListLoading, setCountryListLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLayoutDataLoading, setIsLayoutDataLoading] = useState(true);
  const [selectCountryId, setSeletCountryId] = useState([]);
  const [selectStateId, setSelectedStateId] = useState([]);
  const otherRacingListData = [
    {
      id: 1,
      title: localesData?.HOME?.FEATURED_NEWS,
      subList: [
        {
          id: 1,
          name: localesData?.CUSTOMISE_HOMEPAGE?.HORSE_RACING,
          isSelected: false,
          value: 1,
        },
        {
          id: 3,
          name: localesData?.CUSTOMISE_HOMEPAGE?.GREYHOUND_RACING,
          isSelected: false,
          value: 3,
        },
        {
          id: 2,
          name: localesData?.CUSTOMISE_HOMEPAGE?.HARNESS_RACING,
          isSelected: false,
          value: 2,
        },
      ],
    },
    {
      id: 3,
      title: localesData?.HOME?.FEATURED_RACES,
      subList: [
        {
          id: 1,
          name: localesData?.CUSTOMISE_HOMEPAGE?.HORSE_RACING,
          isSelected: false,
          value: 1,
        },
        {
          id: 3,
          name: localesData?.CUSTOMISE_HOMEPAGE?.GREYHOUND_RACING,
          isSelected: false,
          value: 3,
        },
        {
          id: 2,
          name: localesData?.CUSTOMISE_HOMEPAGE?.HARNESS_RACING,
          isSelected: false,
          value: 2,
        },
      ],
    },
    {
      id: 5,
      title: localesData?.CUSTOMISE_HOMEPAGE?.ALL_RACES,
      subList: [
        {
          id: 1,
          name: localesData?.CUSTOMISE_HOMEPAGE?.HORSE_RACING,
          isSelected: false,
          value: 1,
        },
        {
          id: 3,
          name: localesData?.CUSTOMISE_HOMEPAGE?.GREYHOUND_RACING,
          isSelected: false,
          value: 3,
        },
        {
          id: 2,
          name: localesData?.CUSTOMISE_HOMEPAGE?.HARNESS_RACING,
          isSelected: false,
          value: 2,
        },
      ],
    },
    {
      id: 6,
      title: localesData?.CUSTOMISE_HOMEPAGE?.RACING_LATEST_RESULTS,
      subList: [
        {
          id: 1,
          name: localesData?.CUSTOMISE_HOMEPAGE?.HORSE_RACING,
          isSelected: false,
          value: 1,
        },
        {
          id: 3,
          name: localesData?.CUSTOMISE_HOMEPAGE?.GREYHOUND_RACING,
          isSelected: false,
          value: 3,
        },
        {
          id: 2,
          name: localesData?.CUSTOMISE_HOMEPAGE?.HARNESS_RACING,
          isSelected: false,
          value: 2,
        },
      ],
    },
    {
      id: 2,
      title: localesData?.CUSTOMISE_HOMEPAGE?.FREE_RACING_EXPERT_TIPS,
      subList: [
        {
          id: 1,
          name: localesData?.CUSTOMISE_HOMEPAGE?.HORSE_RACING,
          isSelected: false,
          value: 1,
        },
        {
          id: 3,
          name: localesData?.CUSTOMISE_HOMEPAGE?.GREYHOUND_RACING,
          isSelected: false,
          value: 3,
        },
        {
          id: 2,
          name: localesData?.CUSTOMISE_HOMEPAGE?.HARNESS_RACING,
          isSelected: false,
          value: 2,
        },
      ],
    },

    {
      id: 4,
      title: localesData?.CUSTOMISE_HOMEPAGE?.NEWS,
      subList: [
        {
          id: 1,
          name: localesData?.CUSTOMISE_HOMEPAGE?.HORSE_RACING,
          isSelected: false,
          value: 1,
        },
        {
          id: 3,
          name: localesData?.CUSTOMISE_HOMEPAGE?.GREYHOUND_RACING,
          isSelected: false,
          value: 3,
        },
        {
          id: 2,
          name: localesData?.CUSTOMISE_HOMEPAGE?.HARNESS_RACING,
          isSelected: false,
          value: 2,
        },
      ],
    },
  ];
  const otherSportListData = [
    {
      id: 1,
      title: localesData?.MENU?.TIPPING_COMP,
      subList: [
        {
          id: 0,
          name: localesData?.CUSTOMISE_HOMEPAGE?.PUBLIC_COMPS,
          isSelected: false,
          value: "public",
        },
        {
          id: 1,
          name: localesData?.CUSTOMISE_HOMEPAGE?.RANKINGS,
          isSelected: false,
          value: "ranking",
        },
      ],
    },
    {
      id: 2,
      title: localesData?.CUSTOMISE_HOMEPAGE?.FEATURED_PODCASTS,
      subList: [
        {
          id: 0,
          name: localesData?.sport?.racing,
          isSelected: false,
          value: "racing",
        },
        {
          id: 1,
          name: localesData?.sport?.sports,
          isSelected: false,
          value: "sport",
        },
      ],
    },
  ];

  const [otherRacingList, setOtherRacingList] = useState(otherRacingListData);
  const [otherSportList, setOtherSportList] = useState(otherSportListData);
  const [sportFeatureNewsData, setSportFeatureNewsData] = useState([]);
  const [sportUpcomingFixturesData, setSportUpcomingFixturesData] = useState(
    [],
  );
  const [sportLatestResultData, setSportLatestResultData] = useState([]);
  const [sportFeatureFixtureData, setSportFeatureFixtureData] = useState([]);
  const [sportExperTipsData, setSportExperTipsData] = useState([]);

  const [data, setData] = useState([]);
  const [addList, setAddList] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [pageOrderData, setPageOrderData] = useState([]);

  const keyArray = [];

  const [pageListing, setPageListing] = useState([]);

  useEffect(() => {
    const layoutListing =
      layoutType?.id === 1
        ? HomePageListing
        : layoutType?.id === 2
          ? SportPageListing
          : layoutType?.id === 3
            ? RacingPageListing
            : HomePageListing;

    setPageListing(layoutListing);
  }, [layoutType?.id]);

  // = useMemo(() => {
  //   const layoutListing =
  //     layoutType?.id === 1
  //       ? HomePageListing
  //       : layoutType?.id === 2
  //       ? SportPageListing
  //       : layoutType?.id === 3
  //       ? RacingPageListing
  //       : HomePageListing;
  //   return layoutListing;
  // }, [layoutType?.id]);

  useEffect(() => {
    fetchGetLayoutData("first");
  }, []);

  useEffect(() => {
    if (sportData && sportData?.length > 0) {
      const teamSportData = sportData
        ?.filter((item) => item?.sportTypeId == 2)
        ?.sort((a, b) => a?.sportName?.localeCompare(b?.sportName));
      const sportFeaturedNewsData = teamSportData?.map((item) => {
        if (
          layoutData &&
          Object.entries(layoutData)?.length > 0 &&
          layoutData?.sportFeaturedNews?.length > 0
        ) {
          return {
            ...item,
            name: item?.sportName,
            isExpanded: false,
            tournaments: [],
            tournamentCount: 0,
            isSport: layoutData?.sportFeaturedNews?.includes(item?.id)
              ? true
              : false,
            isIntermediate: false,
          };
        } else {
          return {
            ...item,
            name: item?.sportName,
            isExpanded: false,
            tournaments: [],
            tournamentCount: 0,
            isSport: false,
            isIntermediate: false,
          };
        }
      });
      const SportUpcomingFixturesData = teamSportData?.map((item) => {
        let selectedVal = layoutData?.sportUpcomingFixtures?.filter(
          (obj) => obj?.sportId === item?.id,
        );

        if (selectedVal?.length > 0) {
          return {
            ...item,
            name: item?.sportName,
            isExpanded: false,
            tournaments: [],
            tournamentCount: 0,
            isSport:
              selectedVal?.[0]?.tournament?.length > 0 ||
              selectedVal?.[0]?.isSport == true
                ? true
                : false,
            isIntermediate:
              selectedVal?.[0]?.tournament?.length > 0 &&
              selectedVal?.[0]?.isSport != true
                ? true
                : false,
            selectedTournaments: selectedVal?.[0]?.tournament ?? [],
          };
        } else {
          return {
            ...item,
            name: item?.sportName,
            isExpanded: false,
            tournaments: [],
            tournamentCount: 0,
            isSport: false,
            isIntermediate: false,
            selectedTournaments: [],
          };
        }
      });
      const SportLatestResultData = teamSportData?.map((item) => {
        let selectedVal = layoutData?.sportResult?.filter(
          (obj) => obj?.sportId === item?.id,
        );

        if (selectedVal?.length > 0) {
          return {
            ...item,
            name: item?.sportName,
            isExpanded: false,
            tournaments: [],
            tournamentCount: 0,
            isSport:
              selectedVal?.[0]?.tournament?.length > 0 ||
              selectedVal?.[0]?.isSport == true
                ? true
                : false,
            isIntermediate:
              selectedVal?.[0]?.tournament?.length > 0 &&
              selectedVal?.[0]?.isSport != true
                ? true
                : false,
            selectedTournaments: selectedVal?.[0]?.tournament ?? [],
          };
        } else {
          return {
            ...item,
            name: item?.sportName,
            isExpanded: false,
            tournaments: [],
            tournamentCount: 0,
            isSport: false,
            isIntermediate: false,
            selectedTournaments: [],
          };
        }
      });
      const SportFeatureFixtureData = teamSportData?.map((item) => {
        let selectedVal = layoutData?.sportFeaturedFixtures?.filter(
          (obj) => obj?.sportId === item?.id,
        );

        if (selectedVal?.length > 0) {
          return {
            ...item,
            name: item?.sportName,
            isExpanded: false,
            tournaments: [],
            tournamentCount: 0,
            isSport:
              selectedVal?.[0]?.tournament?.length > 0 ||
              selectedVal?.[0]?.isSport == true
                ? true
                : false,
            isIntermediate:
              selectedVal?.[0]?.tournament?.length > 0 &&
              selectedVal?.[0]?.isSport != true
                ? true
                : false,
            selectedTournaments: selectedVal?.[0]?.tournament ?? [],
          };
        } else {
          return {
            ...item,
            name: item?.sportName,
            isExpanded: false,
            tournaments: [],
            tournamentCount: 0,
            isSport: false,
            isIntermediate: false,
            selectedTournaments: [],
          };
        }
      });
      const SportExpertTipsData = teamSportData
        ?.filter((item) => [12, 4, 9]?.includes(item?.id))
        ?.map((item) => {
          let selectedVal = layoutData?.sportExpertTips?.filter(
            (obj) => obj?.sportId === item?.id,
          );

          if (selectedVal?.length > 0) {
            return {
              ...item,
              name: item?.sportName,
              isExpanded: false,
              tournaments: [],
              tournamentCount: 0,
              isSport:
                selectedVal?.[0]?.tournament?.length > 0 ||
                selectedVal?.[0]?.isSport == true
                  ? true
                  : false,
              isIntermediate:
                selectedVal?.[0]?.tournament?.length > 0 &&
                selectedVal?.[0]?.isSport != true
                  ? true
                  : false,
              selectedTournaments: selectedVal?.[0]?.tournament ?? [],
            };
          } else {
            return {
              ...item,
              name: item?.sportName,
              isExpanded: false,
              tournaments: [],
              tournamentCount: 0,
              isSport: false,
              isIntermediate: false,
              selectedTournaments: [],
            };
          }
        });

      setSportFeatureNewsData(sportFeaturedNewsData);
      setSportUpcomingFixturesData(SportUpcomingFixturesData);
      setSportLatestResultData(SportLatestResultData);
      setSportFeatureFixtureData(SportFeatureFixtureData);
      setSportExperTipsData(SportExpertTipsData);
    }
  }, [sportData, layoutData]);

  const handleClickOutside = (event) => {
    if (selectSportIndex) {
      const openDropdownRef = dropdownRefs.current[selectSportIndex];
      if (openDropdownRef && !openDropdownRef.contains(event.target)) {
        setSelectSportIndex(null);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectSportIndex]);

  useEffect(() => {
    function handleClickCountryOutside(event) {
      if (
        dropdownCountryRef.current.every(
          (ref) => ref && !ref.contains(event.target),
        )
      ) {
        setIsOpen(false);
        setSelectBoxIndex(null);
      }
    }

    document.addEventListener("mousedown", handleClickCountryOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickCountryOutside);
    };
  }, [dropdownCountryRef]);

  const toggleDropdown = (index, type) => {
    if (type === "racing") {
      setIsOpen(index == selectBoxIndex ? false : true);
      setSelectBoxIndex(index == selectBoxIndex ? null : index);
    } else {
      setIsOpen(`${type + "_" + index}` == selectBoxIndex ? false : true);
      setSelectSportIndex(
        `${type + "_" + index}` == selectSportIndex
          ? null
          : `${type + "_" + index}`,
      );
    }
  };

  const handleToggleDropdownClose = (e) => {
    setSelectSportIndex(null);
  };

  useEffect(() => {
    if (
      (layoutData && Object.entries(layoutData)?.length > 0) ||
      layoutData === null
    ) {
      // (layoutType?.id === 1 || layoutType?.id === 3) && fetchCountryList();

      // preselect racing featured race and expert tips
      const selectedRacingOtherList = otherRacingList
        ?.filter((ele) => (layoutType?.id === 1 ? ele.id !== 4 : ele)) // filter for news section only on racing layout
        ?.map((list) =>
          list.id === 1
            ? {
                ...list,
                subList: list.subList.map((sub) => ({
                  ...sub,
                  isSelected: layoutData?.racingFeaturedNews?.includes(sub?.id),
                })),
              }
            : list.id === 2
              ? {
                  ...list,
                  subList: list.subList.map((sub) => ({
                    ...sub,
                    isSelected: layoutData?.racingExpertTips?.includes(sub?.id),
                  })),
                }
              : list.id === 3
                ? {
                    ...list,
                    subList: list.subList.map((sub) => ({
                      ...sub,
                      isSelected: layoutData?.racingFeaturedFixtures?.includes(
                        sub?.id,
                      ),
                    })),
                  }
                : list.id === 4
                  ? {
                      ...list,
                      subList: list.subList.map((sub) => ({
                        ...sub,
                        isSelected: layoutData?.racingNews?.includes(sub?.id),
                      })),
                    }
                  : list.id === 6
                    ? {
                        ...list,
                        subList: list.subList.map((sub) => ({
                          ...sub,
                          isSelected: layoutData?.racingResult?.includes(
                            sub?.id,
                          ),
                        })),
                      }
                    : {
                        ...list,
                        subList: list.subList.map((sub) => ({
                          ...sub,
                          isSelected: layoutData?.racingNextToJump?.includes(
                            sub?.id,
                          ),
                        })),
                      },
        );
      setOtherRacingList(selectedRacingOtherList);

      // preselect tipping comp and podcast
      const selectedSportOtherList = otherSportList.map((list) =>
        list.id === 1
          ? {
              ...list,
              subList: list.subList.map((sub) => ({
                ...sub,
                isSelected: layoutData?.tippingcompetitions?.includes(
                  sub?.value,
                ),
              })),
            }
          : {
              ...list,
              subList: list.subList
                ?.filter((ele) =>
                  layoutType?.id === 2
                    ? ele?.value === "sport"
                    : layoutType?.id === 3
                      ? ele?.value === "racing"
                      : ele,
                )
                ?.map((sub) => ({
                  ...sub,
                  isSelected: layoutData?.featuredPodcasts?.includes(
                    sub?.value,
                  ),
                })),
            },
      );
      setOtherSportList(selectedSportOtherList);
    }
    setIsLayoutDataLoading(false);
  }, [layoutData?.id]);

  const fetchCountryList = async (layoutData) => {
    setCountryListLoading(true);
    try {
      const { status, data } = await axiosInstance.get(`/odds/countryList`);
      if (status === 200) {
        const countryData = data?.result?.rows?.map((item) => ({
          ...item,
          name: item?.country,
        }));
        setCountryList(countryData);

        setCountryListLoading(false);
        setSeletCountryId(
          layoutData?.racingCountryId?.length > 0
            ? layoutData?.racingCountryId
            : [],
        );
        setSelectedStateId(
          layoutData?.racingStateId?.length > 0
            ? layoutData?.racingStateId
            : [],
        );
      } else {
        setCountryListLoading(false);
      }
    } catch (err) {
      setCountryListLoading(false);
    }
  };

  const toggleSelectAll = (isChecked, id, state) => {
    const stateId = state?.map((i) => i?.id);
    if (isChecked) {
      setSeletCountryId([...selectCountryId, id]);
      setSelectedStateId([...selectStateId, ...stateId]);
    } else {
      let filterValue = selectCountryId?.filter((obj) => obj !== id);
      setSeletCountryId(filterValue);

      let filterStateId = selectStateId?.filter(
        (obj) => !stateId?.includes(obj),
      );

      setSelectedStateId(filterStateId);
    }
  };

  const handleOptionClick = (checkValue, country, option, countryId) => {
    if (checkValue) {
      let adddata = [...selectStateId, option?.id];
      setSelectedStateId(adddata);
      const matchedStates = country?.states?.filter((obj) => {
        return adddata?.includes(obj?.id);
      });
      if (matchedStates?.length == 1) {
        setSeletCountryId([...selectCountryId, countryId]);
      }
    } else {
      let filterStateId = selectStateId?.filter((obj) => {
        return obj !== option?.id;
      });
      const matchedStates = country?.states?.filter((obj) => {
        return filterStateId?.includes(obj?.id);
      });
      if (matchedStates?.length == 0) {
        let removeCountry = selectCountryId?.filter((obj) => {
          return obj !== countryId;
        });
        setSeletCountryId(removeCountry);
      }
      setSelectedStateId(filterStateId);
    }
  };

  const handleOtherRacingListOnChange = (listId, subId) => {
    setOtherRacingList((prevList) =>
      prevList.map((list) =>
        list.id === listId
          ? {
              ...list,
              subList: list.subList.map((sub) =>
                sub.id === subId
                  ? { ...sub, isSelected: !sub.isSelected }
                  : sub,
              ),
            }
          : list,
      ),
    );
  };

  const handleOtherSportListOnChange = (listId, subId) => {
    setOtherSportList((prevList) =>
      prevList.map((list) =>
        list.id === listId
          ? {
              ...list,
              subList: list.subList.map((sub) =>
                sub.id === subId
                  ? { ...sub, isSelected: !sub.isSelected }
                  : sub,
              ),
            }
          : list,
      ),
    );
  };

  const nextToJumpData = async (nextJumpData) => {
    const userParseData = JSON.parse(reduxSubscriptionData?.interestedSports);
    const payload = {
      collepseRace: nextJumpData,
      collepseSport: userParseData?.collepseSport,
      collepseCountry: userParseData?.collepseCountry,
    };
    // const updateSelectedJumpToNextToMeta = async () => {
    try {
      const { status, data } = await axiosInstance.put(
        "user/meta/interestedSport",
        payload,
      );
      if (status === 200) {
        if (Config.release == "IN") {
          data["oddfixtures"] = false;
        }
        dispatch(fetchSubscriptionData({ ...data, fromCustomise: true }));
      }
    } catch (err) {}
    // };
  };

  const handleSave = async () => {
    let racingFeaturedNews = otherRacingList
      ?.filter((item) => item?.id == 1)?.[0]
      ?.subList?.filter((item) => item?.isSelected)
      ?.map((item) => item?.id);

    let racingExpertTips = otherRacingList
      ?.filter((item) => item?.id == 2)?.[0]
      ?.subList?.filter((item) => item?.isSelected)
      ?.map((item) => item?.id);

    let racingFeaturedFixtures = otherRacingList
      ?.filter((item) => item?.id == 3)?.[0]
      ?.subList?.filter((item) => item?.isSelected)
      ?.map((item) => item?.id);

    let racingNews = otherRacingList
      ?.filter((item) => item?.id == 4)?.[0]
      ?.subList?.filter((item) => item?.isSelected)
      ?.map((item) => item?.id);

    let racingNextToJump = otherRacingList
      ?.filter((item) => item?.id == 5)?.[0]
      ?.subList?.filter((item) => item?.isSelected)
      ?.map((item) => item?.id);

    let racingResult = otherRacingList
      ?.filter((item) => item?.id == 6)?.[0]
      ?.subList?.filter((item) => item?.isSelected)
      ?.map((item) => item?.id);

    let tippingcompetitions = otherSportList?.[0]?.subList
      ?.filter((item) => item?.isSelected)
      ?.map((item) => item?.value);

    let featuredPodcasts = otherSportList?.[1]?.subList
      ?.filter((item) => item?.isSelected)
      ?.map((item) => item?.value);

    let sportFeaturedNews = processSportsIds(sportFeatureNewsData);
    let sportUpcomingFixtures = processSportsData(sportUpcomingFixturesData);
    let sportResult = processSportsData(sportLatestResultData);
    let sportFeaturedFixtures = processSportsData(sportFeatureFixtureData);
    let sportExpertTips = processSportsData(sportExperTipsData);

    // let obj = {
    //   ...(selectStateId?.length > 0 && { racingStateId: selectStateId }),
    //   ...(selectCountryId?.length > 0 && { racingCountryId: selectCountryId }),
    //   ...(racingFeaturedNews?.length > 0 && {
    //     racingFeaturedNews: racingFeaturedNews
    //   }),
    //   ...(racingExpertTips?.length > 0 && {
    //     racingExpertTips: racingExpertTips
    //   }),
    //   ...(tippingcompetitions?.length > 0 && {
    //     tippingcompetitions: tippingcompetitions
    //   }),
    //   ...(featuredPodcasts?.length > 0 && {
    //     featuredPodcasts: featuredPodcasts
    //   }),
    //   ...(sportFeaturedNews?.length > 0 && {
    //     sportFeaturedNews: sportFeaturedNews
    //   }),
    //   ...(sportResult?.length > 0 && { sportResult: sportResult }),
    //   ...(sportFeaturedFixtures?.length > 0 && {
    //     sportFeaturedFixtures: sportFeaturedFixtures
    //   }),
    //   ...(sportUpcomingFixtures?.length > 0 && {
    //     sportUpcomingFixtures: sportUpcomingFixtures
    //   }),
    //   ...(sportExpertTips?.length > 0 && { sportExpertTips: sportExpertTips }),
    //   ...(racingFeaturedFixtures?.length > 0 && {
    //     racingFeaturedFixtures: racingFeaturedFixtures
    //   }),
    //   ...(racingNews?.length > 0 && {
    //     racingNews: racingNews
    //   })
    // };

    const defaultObj = {
      racingStateId: selectStateId?.length > 0 ? selectStateId : null,
      racingCountryId: selectCountryId?.length > 0 ? selectCountryId : null,
      racingFeaturedNews:
        racingFeaturedNews?.length > 0 ? racingFeaturedNews : null,
      racingExpertTips: racingExpertTips?.length > 0 ? racingExpertTips : null,
      tippingcompetitions:
        tippingcompetitions?.length > 0 ? tippingcompetitions : null,
      featuredPodcasts: featuredPodcasts?.length > 0 ? featuredPodcasts : null,
      sportFeaturedNews:
        sportFeaturedNews?.length > 0 ? sportFeaturedNews : null,
      sportResult: sportResult?.length > 0 ? sportResult : null,
      sportFeaturedFixtures:
        sportFeaturedFixtures?.length > 0 ? sportFeaturedFixtures : null,
      sportUpcomingFixtures:
        sportUpcomingFixtures?.length > 0 ? sportUpcomingFixtures : null,
      sportExpertTips: sportExpertTips?.length > 0 ? sportExpertTips : null,
      racingFeaturedFixtures:
        racingFeaturedFixtures?.length > 0 ? racingFeaturedFixtures : null,
      racingNextToJump: racingNextToJump?.length > 0 ? racingNextToJump : null,
      racingResult: racingResult?.length > 0 ? racingResult : null,
      pageOrder: data?.length > 0 ? data : null,
      // racingNews: racingNews?.length > 0 ? racingNews : null
    };

    const racingObj = {
      racingStateId: selectStateId?.length > 0 ? selectStateId : null,
      racingCountryId: selectCountryId?.length > 0 ? selectCountryId : null,
      racingFeaturedNews:
        racingFeaturedNews?.length > 0 ? racingFeaturedNews : null,
      racingExpertTips: racingExpertTips?.length > 0 ? racingExpertTips : null,
      featuredPodcasts: featuredPodcasts?.length > 0 ? featuredPodcasts : null,
      racingFeaturedFixtures:
        racingFeaturedFixtures?.length > 0 ? racingFeaturedFixtures : null,
      racingNews: racingNews?.length > 0 ? racingNews : null,
      pageOrder: data?.length > 0 ? data : null,
      racingNextToJump: racingNextToJump?.length > 0 ? racingNextToJump : null,
      racingResult: racingResult?.length > 0 ? racingResult : null,
    };

    const sportObj = {
      tippingcompetitions:
        tippingcompetitions?.length > 0 ? tippingcompetitions : null,
      featuredPodcasts: featuredPodcasts?.length > 0 ? featuredPodcasts : null,
      sportFeaturedNews:
        sportFeaturedNews?.length > 0 ? sportFeaturedNews : null,
      sportResult: sportResult?.length > 0 ? sportResult : null,
      sportFeaturedFixtures:
        sportFeaturedFixtures?.length > 0 ? sportFeaturedFixtures : null,
      sportUpcomingFixtures:
        sportUpcomingFixtures?.length > 0 ? sportUpcomingFixtures : null,
      sportExpertTips: sportExpertTips?.length > 0 ? sportExpertTips : null,
      pageOrder: data?.length > 0 ? data : null,
    };
    const passPayload =
      layoutType?.id === 1
        ? defaultObj
        : layoutType?.id === 2
          ? sportObj
          : racingObj;
    saveLayoutData(passPayload, "save");
    // if (
    //   (layoutType?.id === 1 || layoutType?.id === 3) &&
    //   racingNextToJump &&
    //   racingNextToJump?.length > 0
    // ) {
    //   nextToJumpData(racingNextToJump);
    // }
  };

  const editProfileDetails = async () => {
    let payload;
    if (layoutType?.id === 1) {
      payload = {
        isDefaultHomePage: true,
        isDefaultSportPage: false,
        isDefaultRacingPage: false,
      };
    } else if (layoutType?.id === 2) {
      payload = {
        isDefaultHomePage: false,
        isDefaultSportPage: true,
        isDefaultRacingPage: false,
      };
    } else {
      payload = {
        isDefaultHomePage: false,
        isDefaultSportPage: false,
        isDefaultRacingPage: true,
      };
    }

    try {
      const { status, data } = await axiosInstance.put(
        "user/editProfile",
        payload,
      );
      if (status === 200) {
        setApiMessage("success", data?.message);
        let tempUserData = { ...reduxSubscriptionData };
        tempUserData.isDefaultHomePage = payload?.isDefaultHomePage;
        tempUserData.isDefaultSportPage = payload?.isDefaultSportPage;
        tempUserData.isDefaultRacingPage = payload?.isDefaultRacingPage;
        dispatch(fetchSubscriptionData(tempUserData));
      } else {
        setApiMessage("success", "An Error Occurred");
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
    }
  };

  const saveLayoutData = async (payload, btnType) => {
    let layoutTypes =
      layoutType?.id === 1
        ? "homepage"
        : layoutType?.id === 2
          ? "sportpage"
          : "racingpage";
    try {
      const { status, data } = await axiosInstance.post(
        `/user/customiseUserPages?type=${layoutTypes}`,
        payload,
      );
      if (status === 200) {
        if (
          (reduxSubscriptionData?.isDefaultHomePage === true &&
            layoutType?.id === 1) ||
          (reduxSubscriptionData?.isDefaultSportPage === true &&
            layoutType?.id === 2) ||
          (reduxSubscriptionData?.isDefaultRacingPage === true &&
            layoutType?.id === 3)
        ) {
        } else {
          editProfileDetails();
        }
        fetchGetLayoutData();
        setApiMessage("success", "Updated Successfully");
      } else {
      }
    } catch (err) {}
  };

  // set layout data in redux
  const fetchGetLayoutData = async (apicall) => {
    setLoading(true);
    let layoutTypes =
      layoutType?.id === 1
        ? "homepage"
        : layoutType?.id === 2
          ? "sportpage"
          : "racingpage";
    try {
      const { status, data } = await axiosInstance.get(
        `user/customise/UserPagesGet?type=${layoutTypes}`,
      );
      if (status === 200) {
        setLoading(false);
        dispatch(fetchLayoutData(data?.result));
        if (apicall === "first") {
          (layoutType?.id === 1 || layoutType?.id === 3) &&
            fetchCountryList(data?.result);
        }
      } else {
        setLoading(false);
        dispatch(fetchLayoutData(null));
      }
    } catch (err) {
      setLoading(false);
      dispatch(fetchLayoutData(null));
    }
  };

  const handleReset = (mountType) => {
    if (mountType !== "unmount") {
      saveLayoutData(resetPayload, "reset");
    }

    resetHomePageListing(pageListing);
    sortAndFilterList([]);

    const teamSportData = sportData
      ?.filter((item) => item?.sportTypeId == 2)
      ?.sort((a, b) => a?.sportName?.localeCompare(b?.sportName));
    const newSportData = teamSportData?.map((item) => ({
      ...item,
      name: item?.sportName,
      isExpanded: false,
      tournaments: [],
      tournamentCount: 0,
      isSport: false,
      isIntermediate: false,
    }));
    const expertTips = newSportData?.filter((item) =>
      [12, 4, 9]?.includes(item?.id),
    );

    setSportFeatureNewsData(newSportData);
    setSportUpcomingFixturesData(newSportData);
    setSportLatestResultData(newSportData);
    setSportFeatureFixtureData(newSportData);
    setSportExperTipsData(expertTips);
    setOtherRacingList(
      otherRacingListData?.filter((ele) =>
        layoutType?.id === 1 ? ele.id !== 4 : ele,
      ),
    );
    const selectedSportOtherList = otherSportListData.map((list) => {
      if (list.id === 1) {
        return list;
      } else {
        return {
          ...list,
          subList: list.subList?.filter((ele) =>
            layoutType?.id === 2
              ? ele?.value === "sport"
              : layoutType?.id === 3
                ? ele?.value === "racing"
                : ele,
          ),
        };
      }
    });
    setOtherSportList(selectedSportOtherList);
    setSeletCountryId([]);
    setSelectedStateId([]);
  };

  const processSportsIds = (data) => {
    return data
      .map((sport) => {
        if (sport?.isSport || sport?.isIntermediate) {
          let tournamentIds = [];
          if (sport?.isSport && !sport?.isIntermediate) {
            return sport.id;
          }
        }
      })
      ?.filter((item) => item != null);
  };
  const processSportsData = (data) => {
    return data
      ?.map((sport) => {
        if (sport?.isSport || sport?.isIntermediate) {
          let tournamentIds = [];
          if (sport?.isSport && !sport?.isIntermediate) {
            return {
              isSport: true,
              sportId: sport?.id,
              tournament: [],
            };
          } else if (sport?.isIntermediate && sport?.isSport) {
            if (
              sport?.tournaments?.length == 0 &&
              sport?.selectedTournaments?.length > 0
            ) {
              return {
                sportId: sport.id,
                tournament: sport?.selectedTournaments,
              };
            } else {
              tournamentIds = sport.tournaments
                .filter((tournament) => tournament.selected)
                .map((tournament) => tournament.id);

              return {
                sportId: sport.id,
                tournament: tournamentIds,
              };
            }
          }
        }
      })
      ?.filter((item) => item != null);
  };

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
    return () => {
      handleReset("unmount");
    };
  }, []);

  useEffect(() => {
    if (
      (layoutData && Object.entries(layoutData)?.length > 0) ||
      layoutData === null
    ) {
      setPageOrderData(layoutData?.pageOrder ? layoutData?.pageOrder : []);
      updateIsAddedStatus(
        pageListing,
        layoutData?.pageOrder ? layoutData?.pageOrder : [],
      );

      sortAndFilterList(layoutData?.pageOrder || []);
    }
  }, [layoutData?.pageOrder, customiseTabValue]);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(data);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
    setData(reorderedItems);
  };
  const updateIsAddedStatus = (homePageListing, pageOrder = []) => {
    // Create a set of keys from pageOrder for quick lookup
    const keysInPageOrder = new Set();

    // Populate the set with keys and nested item keys from pageOrder
    pageOrder.forEach((page) => {
      if (page.key) {
        keysInPageOrder.add(page.key);
      }
      if (page.items) {
        page.items.forEach((item) => keysInPageOrder.add(item.key));
      }
    });

    // Update the isAdded property in homePageListing based on the keys set
    homePageListing.forEach((page) => {
      if (page.key && keysInPageOrder.has(page.key)) {
        page.isAdded = true;
      }
      if (page.items) {
        page.items.forEach((item) => {
          if (keysInPageOrder.has(item.key)) {
            item.isAdded = true;
          }
        });
      }
    });
  };

  const sortAndFilterList = async (pageOrderArg) => {
    const sorted = [];
    const addListTemp = [];

    // Group items and update isAdded property
    [...pageListing].forEach((item) => {
      const shouldAddToSorted = item.isAdded;

      if (shouldAddToSorted) {
        if (item.items) {
          // Create a new object with the parent item's properties
          const nestedItem = { ...item, isAdded: false };
          nestedItem.items = []; // Add an empty array for sub-items

          // Iterate through sub-items and add them based on pageOrder
          item.items.forEach((subItem) => {
            if (subItem.isAdded) {
              nestedItem.items.push({ ...subItem, isAdded: true });
              nestedItem.isAdded = true;
            } else {
              addListTemp.push({
                ...item,
                isAdded: false,
                items: [{ ...subItem, key: item.key, isAdded: false }],
              });
            }
          });

          // Add the nested item with its sub-items (if any)
          if (nestedItem.items.length > 0) {
            sorted.push(nestedItem);
          } else {
            // No sub-items found in pageOrder, add to addList
            addListTemp.push(nestedItem);
          }
        } else {
          sorted.push({ ...item, isAdded: true });
        }
      } else {
        addListTemp.push({ ...item, isAdded: false });
      }
    });
    // Sort the sorted list according to pageOrder
    // sorted.sort((a, b) => pageOrder.indexOf(a.key) - pageOrder.indexOf(b.key));

    const orderMap = new Map(
      pageOrderArg.map((item, index) => [item.key, index]),
    );
    const sortedByPageOrder = sorted.slice().sort((a, b) => {
      const indexA = orderMap.has(a.key) ? orderMap.get(a.key) : Infinity;
      const indexB = orderMap.has(b.key) ? orderMap.get(b.key) : Infinity;
      return indexA - indexB;
    });
    setData(sortedByPageOrder);
    setAddList(addListTemp);
  };

  const addRemoveItem = (item, type, innerKey = "") => {
    if (type == "add") {
      if (!keyArray?.includes(item?.key)) {
        let index = pageListing?.findIndex((obj) => obj?.key == item?.key);
        pageListing[index].isAdded = true;
        sortAndFilterList(data);
      } else {
        let index = pageListing?.findIndex((obj) => obj?.key == item?.key);
        let innerIndex = pageListing[index].items?.findIndex(
          (item) => item?.title == innerKey,
        );
        pageListing[index].items[innerIndex].isAdded = true;
        pageListing[index].isAdded = true;
        sortAndFilterList(data);
      }
    } else {
      if (!keyArray?.includes(item?.key)) {
        let index = pageListing?.findIndex((obj) => obj?.key == item?.key);
        pageListing[index].isAdded = false;
        sortAndFilterList(data);
      } else {
        let index = pageListing?.findIndex((obj) => obj?.key == item?.key);
        let innerIndex = pageListing[index].items?.findIndex(
          (item) => item?.title == innerKey,
        );
        pageListing[index].items[innerIndex].isAdded = false;
        sortAndFilterList(data);
      }
    }
  };

  const resetHomePageListing = (listing) => {
    listing.forEach((item) => {
      if (item.items && Array.isArray(item.items)) {
        item.items.forEach((nestedItem) => {
          nestedItem.isAdded = false;
        });
      }
      if (item.hasOwnProperty("isAdded")) {
        item.isAdded = false;
      }
    });
  };

  // const handlePageSave = async payload => {
  //   let layoutTypes =
  //     layoutType?.id === 1
  //       ? "homepage"
  //       : layoutType?.id === 2
  //       ? "sportpage"
  //       : "racingpage";

  //   try {
  //     const { status, data } = await axiosInstance.post(
  //       `/user/customiseUserPages?type=${layoutTypes}`,
  //       payload
  //     );
  //     if (status === 200) {
  //       fetchGetLayoutData();
  //       setApiMessage("success", "Updated Successfully");
  //     } else {
  //     }
  //   } catch (err) {}
  // };

  // const fetchPageGetLayoutData = async () => {
  //   let layoutTypes =
  //     layoutType?.id === 1
  //       ? "homepage"
  //       : layoutType?.id === 2
  //       ? "sportpage"
  //       : "racingpage";
  //   try {
  //     const { status, data } = await axiosInstance.get(
  //       `user/customise/UserPagesGet?type=${layoutTypes}`
  //     );
  //     if (status === 200) {
  //       dispatch(fetchLayoutData(data?.result));
  //     } else {
  //       dispatch(fetchLayoutData(null));
  //     }
  //   } catch (err) {
  //     dispatch(fetchLayoutData(null));
  //   }
  // };
  // const handlePageReset = () => {
  //   handleSave({ pageOrder: [] });
  //   resetHomePageListing(pageListing);
  //   sortAndFilterList([]);
  // };

  return (
    <>
      {/* {isLayoutDataLoading ? (
        <>
          <Box
            style={{
              minHeight: "600px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          </Box>
        </>
      ) : ( */}
      {customiseTabValue === 0 ? (
        <Box className="filter-modal-wrap">
          {/* RACING */}
          {loading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : (
            <>
              {(layoutType?.id === 1 || layoutType?.id === 3) && (
                <>
                  <Box className="filter-header">
                    <RacingIcon />
                    <Typography className="modal-header-title">
                      {localesData?.sport?.racing}
                    </Typography>
                  </Box>
                  <Typography className="modal-header-sub-title">
                    {localesData?.CUSTOMISE_HOMEPAGE?.SELECT_COUNTRIES_STATE}
                  </Typography>
                  {countryListLoading ? (
                    <div className="allsport-loader-center ">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <Box className="customise-filter-dropdown-wrap">
                        {countryList?.map((item, index) => {
                          return (
                            <>
                              <CustomDropdown
                                mainOption={item}
                                options={item?.states?.sort((a, b) => {
                                  return a?.state
                                    ?.trim()
                                    .localeCompare(b?.state?.trim());
                                })}
                                indexOf={index}
                                isOpen={isOpen}
                                selectBoxIndex={selectBoxIndex}
                                toggleDropdown={toggleDropdown}
                                selectMainId={selectCountryId}
                                selectSubId={selectStateId}
                                toggleSelectAll={toggleSelectAll}
                                handleOptionClick={handleOptionClick}
                                type="racing"
                                dropdownCountryRef={(el) =>
                                  (dropdownCountryRef.current[index] = el)
                                }
                              />
                            </>
                          );
                        })}
                      </Box>
                    </>
                  )}
                  {/* OTHER RACING */}
                  <Box
                    className={`customise-other-filter-container ${
                      layoutType?.id === 3 || layoutType?.id === 1
                        ? "racing-customise-other-filter-container"
                        : ""
                    }`}
                  >
                    {otherRacingList?.map((item, i) => {
                      return (
                        <Box className="customise-other-filter-list">
                          <Typography className="other-filter-title">
                            {item?.title}{" "}
                            {item?.id === 5 ? (
                              <span className="sub-title">(Next to Jump)</span>
                            ) : (
                              ""
                            )}
                          </Typography>
                          {item?.subList?.map((obj) => {
                            return (
                              <FormControlLabel
                                className="other-filter-checkbox"
                                control={
                                  <Checkbox
                                    checked={obj?.isSelected}
                                    icon={<CheckBoxChecked />}
                                    checkedIcon={<CheckboxUnChecked />}
                                    onChange={() =>
                                      handleOtherRacingListOnChange(
                                        item?.id,
                                        obj?.id,
                                      )
                                    }
                                    disableRipple
                                  />
                                }
                                label={obj?.name}
                              />
                            );
                          })}
                        </Box>
                      );
                    })}
                  </Box>
                </>
              )}

              {(layoutType?.id === 1 || layoutType?.id === 2) && (
                <>
                  {/* SPORTS */}
                  <Box className="filter-header">
                    <SportIcon />
                    <Typography className="modal-header-title">
                      {localesData?.sport?.sports}
                    </Typography>
                  </Box>
                  {/* FEATURED NEWS */}
                  <Typography className="modal-header-sub-title">
                    {localesData?.HOME?.FEATURED_NEWS}
                  </Typography>
                  <Box className="customise-filter-dropdown-wrap">
                    {sportFeatureNewsData?.length !== 0 &&
                      sportFeatureNewsData?.map((item, index) => {
                        return (
                          <>
                            <SportCustomDropdown
                              mainOption={item}
                              sportId={item?.id}
                              isOpen={
                                selectSportIndex === `featured_news_${item?.id}`
                              }
                              toggleDropdown={toggleDropdown}
                              type="featured_news"
                              setMainOption={setSportFeatureNewsData}
                              dropdownRef={(el) =>
                                (dropdownRefs.current[
                                  `featured_news_${item?.id}`
                                ] = el)
                              }
                              id={`featured_news_${item?.id}`}
                            />
                          </>
                        );
                      })}
                  </Box>

                  {/* UPCOMING FIXTURES */}
                  <Typography className="modal-header-sub-title">
                    {localesData?.CUSTOMISE_HOMEPAGE?.UPCOMING_FIXTURES}
                  </Typography>
                  <Box className="customise-filter-dropdown-wrap">
                    {sportUpcomingFixturesData?.length !== 0 &&
                      sportUpcomingFixturesData?.map((item, index) => {
                        return (
                          <>
                            {/* <ClickAwayListener
                    onClickAway={handleToggleDropdownClose}
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                  > */}
                            <div>
                              <SportCustomDropdown
                                mainOption={item}
                                sportId={item?.id}
                                isOpen={
                                  selectSportIndex ===
                                  `upcoming_fixture_${item?.id}`
                                }
                                toggleDropdown={toggleDropdown}
                                type="upcoming_fixture"
                                setMainOption={setSportUpcomingFixturesData}
                                dropdownRef={(el) =>
                                  (dropdownRefs.current[
                                    `upcoming_fixture_${item?.id}`
                                  ] = el)
                                }
                                id={`upcoming_fixture_${item?.id}`}
                              />
                            </div>
                            {/* </ClickAwayListener> */}
                          </>
                        );
                      })}
                  </Box>

                  {/* LATEST RESULTS */}
                  <Typography className="modal-header-sub-title">
                    {localesData?.CUSTOMISE_HOMEPAGE?.LATEST_RESULTS}
                  </Typography>
                  <Box className="customise-filter-dropdown-wrap">
                    {sportLatestResultData?.length !== 0 &&
                      sportLatestResultData?.map((item, index) => {
                        return (
                          <>
                            <SportCustomDropdown
                              mainOption={item}
                              sportId={item?.id}
                              isOpen={
                                selectSportIndex ===
                                `latest_results_${item?.id}`
                              }
                              toggleDropdown={toggleDropdown}
                              type="latest_results"
                              setMainOption={setSportLatestResultData}
                              // dropdownRef={el => (dropdownRefs.current[index] = el)}
                              dropdownRef={(el) =>
                                (dropdownRefs.current[
                                  `latest_results_${item?.id}`
                                ] = el)
                              }
                              id={`latest_results_${item?.id}`}
                            />
                          </>
                        );
                      })}
                  </Box>

                  {/* FEATURED FIXTURES */}
                  <Typography className="modal-header-sub-title">
                    {localesData?.CUSTOMISE_HOMEPAGE?.FEATURED_FIXTURES}
                  </Typography>
                  <Box className="customise-filter-dropdown-wrap">
                    {sportFeatureFixtureData?.length !== 0 &&
                      sportFeatureFixtureData?.map((item, index) => {
                        return (
                          <>
                            <SportCustomDropdown
                              mainOption={item}
                              sportId={item?.id}
                              isOpen={
                                selectSportIndex ===
                                `featured_fixture_${item?.id}`
                              }
                              toggleDropdown={toggleDropdown}
                              type="featured_fixture"
                              setMainOption={setSportFeatureFixtureData}
                              dropdownRef={(el) =>
                                (dropdownRefs.current[
                                  `featured_fixture_${item?.id}`
                                ] = el)
                              }
                              id={`featured_fixture_${item?.id}`}
                            />
                          </>
                        );
                      })}
                  </Box>

                  {/* EXPERT TIPS */}
                  <Typography className="modal-header-sub-title">
                    {localesData?.CUSTOMISE_HOMEPAGE?.SPORT_EXPERT_TIPS}
                  </Typography>
                  <Box className="customise-filter-dropdown-wrap">
                    {sportExperTipsData?.length !== 0 &&
                      sportExperTipsData?.map((item, index) => {
                        return (
                          <>
                            <SportCustomDropdown
                              mainOption={item}
                              sportId={item?.id}
                              isOpen={
                                selectSportIndex === `expert_tips_${item?.id}`
                              }
                              toggleDropdown={toggleDropdown}
                              type="expert_tips"
                              setMainOption={setSportExperTipsData}
                              dropdownRef={(el) =>
                                (dropdownRefs.current[
                                  `expert_tips_${item?.id}`
                                ] = el)
                              }
                              id={`expert_tips_${item?.id}`}
                            />
                          </>
                        );
                      })}
                  </Box>
                </>
              )}
              {/* OTHER SPORTS */}
              <Box className="customise-other-filter-container comp-podcast-filter-container">
                {otherSportList
                  ?.filter((item) =>
                    layoutType?.id === 3 ? item?.id === 2 : item,
                  ) // filter for racing layout only show podcast
                  ?.map((item, i) => {
                    return (
                      <Box className="customise-other-filter-list ">
                        <Typography className="modal-header-title ">
                          {item?.title}
                        </Typography>
                        {item?.subList?.map((obj) => {
                          return (
                            <FormControlLabel
                              className="other-filter-checkbox"
                              control={
                                <Checkbox
                                  checked={obj?.isSelected}
                                  icon={<CheckBoxChecked />}
                                  checkedIcon={<CheckboxUnChecked />}
                                  onChange={() =>
                                    handleOtherSportListOnChange(
                                      item?.id,
                                      obj?.id,
                                    )
                                  }
                                  disableRipple
                                />
                              }
                              label={obj?.name}
                            />
                          );
                        })}
                      </Box>
                    );
                  })}
              </Box>
            </>
          )}
          {!loading && (
            <Box className="filter-modal-btn-wrap">
              <Button className="filter-save-btn" onClick={() => handleSave()}>
                Save changes
              </Button>
              <Box className="filter-reset-all" onClick={() => handleReset()}>
                <span>
                  <ResetAll />
                </span>
                <Typography className="reset-all">Reset all</Typography>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Box className="drag-drop-wrap">
          <Box className="scrollable-section">
            {data?.length > 0 ? (
              <>
                <Typography className="regular-font semibold-font scroll-header">
                  {localesData?.EDITPROFILE?.DRAG_OR_REORDER}{" "}
                </Typography>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="drag-drop-section"
                      >
                        <List>
                          {data.map((item, index) => (
                            <Draggable
                              key={item.key || item.id}
                              draggableId={item.key || item.id.toString()}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <ListItem
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{
                                    backgroundColor: snapshot.isDragging
                                      ? "lightgreen"
                                      : "white",
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <Box className="drag-item-wrap">
                                    <span className="regular-font semibold-font name-icon-container responsive-regular">
                                      {screenWidth > 799 ? (
                                        <DragAndDropIconDesktop />
                                      ) : (
                                        <CheckboxUnChecked
                                          className="responsive-icon"
                                          onClick={() =>
                                            addRemoveItem(item, "remove")
                                          }
                                        />
                                      )}
                                      {item?.title}
                                    </span>
                                    {screenWidth > 799 && (
                                      <Box className="ph-wrap">
                                        <img src={item.ph} alt="ph" />
                                      </Box>
                                    )}
                                    <span>
                                      {screenWidth > 799 ? (
                                        <Button
                                          disableRipple
                                          disableFocusRipple
                                          onClick={() =>
                                            addRemoveItem(item, "remove")
                                          }
                                          className={`drag-drop-btn regular-font ${
                                            item?.isAdded
                                              ? "remove-btn"
                                              : "add-btn"
                                          }`}
                                        >
                                          {item?.isAdded ? (
                                            <Minus className="icon" />
                                          ) : (
                                            <Plus className="icon" />
                                          )}

                                          {item?.isAdded ? "Remove" : "Add"}
                                        </Button>
                                      ) : (
                                        <DragAndDropIcon />
                                      )}
                                    </span>
                                  </Box>
                                </ListItem>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </List>
                      </Box>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            ) : (
              <></>
            )}
            {addList?.length > 0 ? (
              <>
                <Typography className="regular-font semibold-font scroll-header">
                  Add more{" "}
                </Typography>
                <Box className="drag-drop-section add-more-section">
                  {addList.map((item, index) => {
                    if (keyArray?.includes(item?.key)) {
                      return item?.items?.map((innerRow) => {
                        return (
                          <Box className="drag-item-wrap">
                            <span className="regular-font semibold-font name-icon-container responsive-regular">
                              {screenWidth < 800 && (
                                <CheckBoxChecked
                                  className="responsive-icon"
                                  onClick={() =>
                                    addRemoveItem(item, "add", innerRow?.title)
                                  }
                                />
                              )}
                              {innerRow?.title}
                            </span>
                            {screenWidth > 799 && (
                              <Box className="ph-wrap">
                                <img src={item.ph} alt="ph" />
                              </Box>
                            )}
                            <span>
                              {screenWidth > 799 ? (
                                <Button
                                  disableRipple
                                  disableFocusRipple
                                  onClick={() =>
                                    addRemoveItem(item, "add", innerRow?.title)
                                  }
                                  className={`drag-drop-btn regular-font ${
                                    innerRow?.isAdded ? "remove-btn" : "add-btn"
                                  }`}
                                >
                                  {innerRow?.isAdded ? (
                                    <Minus className="icon" />
                                  ) : (
                                    <Plus className="icon" />
                                  )}

                                  {innerRow?.isAdded ? "Remove" : "Add"}
                                </Button>
                              ) : (
                                <DragAndDropIcon />
                              )}
                            </span>
                          </Box>
                        );
                      });
                    } else {
                      return (
                        <Box className="drag-item-wrap">
                          <span className="regular-font semibold-font name-icon-container responsive-regular">
                            {screenWidth < 800 && (
                              <CheckBoxChecked
                                className="responsive-icon"
                                onClick={() => addRemoveItem(item, "add")}
                              />
                            )}
                            {item?.title}
                          </span>
                          {screenWidth > 799 && (
                            <Box className="ph-wrap">
                              <img src={item.ph} alt="ph" />
                            </Box>
                          )}
                          <span>
                            {screenWidth > 799 ? (
                              <Button
                                disableRipple
                                disableFocusRipple
                                onClick={() => addRemoveItem(item, "add")}
                                className={`drag-drop-btn regular-font ${
                                  item?.isAdded ? "remove-btn" : "add-btn"
                                }`}
                              >
                                {item?.isAdded ? (
                                  <Minus className="icon" />
                                ) : (
                                  <Plus className="icon" />
                                )}

                                {item?.isAdded ? "Remove" : "Add"}
                              </Button>
                            ) : (
                              <DragAndDropIcon />
                            )}
                          </span>
                        </Box>
                      );
                    }
                  })}
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>
          <Box className="filter-modal-btn-wrap">
            <Button
              className="filter-save-btn"
              onClick={() => handleSave({ pageOrder: data })}
            >
              Save changes
            </Button>
            <Box className="filter-reset-all" onClick={() => handleReset()}>
              <span>
                <ResetAll />
              </span>
              <Typography className="reset-all">Reset all</Typography>
            </Box>
          </Box>
        </Box>
      )}
      {/* )} */}
    </>
  );
};

export default CustomiseFilterModal;
