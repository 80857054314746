import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import MemberPersonalInfo from "./memberPersonalInfo";
import PaymentForm from "./paymentForm";
import CouponCodeDetails from "./couponCodeDetails";
import { Config, fetchFromStorage } from "src/helpers/context";
import useMembershipStore from "src/helpers/zustand/useMembershipStore";
import { setApiMessage } from "src/helpers/commonFunctions";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { fetchSubscriptionData } from "src/helpers/store/Actions/SubscriptionData";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import moment from "moment";
import "./membershipFrom.scss";
import { useSelector } from "react-redux";
import Loader from "src/components/Loader";
import { IntlContext } from "src/App";
import { ToastContainer, toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import DialogBox from "../../UI/dialogBox";
import { loginUser } from "src/helpers/store/Actions/auth";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";

const MembershipFromDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxGetUserData = useSelector(
    (state) => state?.reduxData?.SubscripitionData,
  );
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const { selectedPlanDetailsStore } = useMembershipStore();
  const formikRef1 = useRef(null); // Ref for first child form
  const formikRef2 = useRef(null); // Ref for second child form
  const [combinedData, setCombinedData] = useState(null); // State to store the combined form data
  const [couponCode, setCouponCode] = useState("");
  const [couponApply, setCouponApply] = useState(false);
  const [couponCodeDetails, setCouponCodeDetails] = useState({});
  const [paymentMode, setPaymentMode] = useState("");
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  const [cardsave, setCardSave] = useState(false);
  const [emailmsg, setEmailmsg] = useState("");
  const [getUserData, setGetUserData] = useState({});
  const [otpVerifyModal, setOtpVerifyModal] = useState(false);
  const [isOtpError, setOtpError] = useState(false);
  const [isOtp, setOtp] = useState(false);
  const [isToken, setIsToken] = useState("");
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (reduxGetUserData && reduxGetUserData !== undefined) {
      setGetUserData(reduxGetUserData);
    }
  }, [reduxGetUserData]);

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const isLogin = handleRestrictedUser();

  const fetchSubscriptionPaymentData = async () => {
    try {
      const { status, data } = await axiosInstance.get(`/user/get-user`);
      if (status === 200) {
        if (Config.release == "IN") {
          data["oddfixtures"] = false;
        }
        dispatch(fetchSubscriptionData(data));
      } else {
        dispatch(fetchSubscriptionData(undefined));
      }
    } catch (err) {
      dispatch(fetchSubscriptionData(undefined));
    }
  };

  // Function to handle submission of both forms
  const handleSubmit = async () => {
    // Collect both forms' data and wait for their submission
    const form1Promise = formikRef1.current.submitForm(); // Manually trigger form 1 submission
    const form2Promise = formikRef2.current.submitForm(); // Manually trigger form 2 submission

    // Wait for both forms to be submitted
    const [form1Data, form2Data] = await Promise.all([
      form1Promise.then(() => formikRef1.current), // Get form 1 values
      form2Promise.then(() => formikRef2.current), // Get form 2 values
    ]);

    // Combine the data from both forms
    const mergedData = { ...form1Data?.values, ...form2Data?.values };

    const expirationDate = mergedData?.expirationDate;

    if (expirationDate) {
      const parts = expirationDate.split("/");
      if (parts?.length === 2) {
        const month = parts[0].padStart(2, "0"); // Ensure month is two digits
        const year = parts[1];
        const formattedDate = `${year}-${month}`;

        // Update the expirationDate in the requestData object
        mergedData.expirationDate = formattedDate;
      }
    }

    if (form1Data?.isValid && form2Data?.isValid) {
      handlePaymentSubmit(mergedData);
      // You can now handle the combined data (e.g., send it to a backend API or further process it)
      setCombinedData(mergedData);
    }
  };

  const googleTagData = (transaction_id) => {
    return {
      transaction_id: transaction_id,
      value: couponApply
        ? (
            Number(selectedPlanDetailsStore?.amount ?? 0) -
            Number(couponCodeDetails?.discountAmountPrize ?? 0)
          )?.toFixed(2)
        : selectedPlanDetailsStore?.amount
          ? Number(selectedPlanDetailsStore?.amount)?.toFixed(2)
          : 0,
      currency: selectedPlanDetailsStore?.currency,
      coupon: couponApply ? couponCodeDetails?.data?.code : "",
      items: [
        {
          item_id: selectedPlanDetailsStore?.id,
          item_name: selectedPlanDetailsStore?.name,
          coupon: couponApply ? couponCodeDetails?.data?.code : "",
          discount: couponApply ? couponCodeDetails?.discountAmountPrize : 0,
          item_brand: "SmartB",
          price: couponApply
            ? (
                Number(selectedPlanDetailsStore?.amount ?? 0) -
                Number(couponCodeDetails?.discountAmountPrize ?? 0)
              )?.toFixed(2)
            : selectedPlanDetailsStore?.amount
              ? Number(selectedPlanDetailsStore?.amount)?.toFixed(2)
              : 0,
          quantity: 1,
        },
      ],
    };
  };

  const handlePaymentSubmit = async (paymentData) => {
    let withLoginDataID = {
      plan_id: selectedPlanDetailsStore?.id,
      plateform: "web",
      card_id: paymentMode,
      couponCode: couponCode ? couponCode : "",
      firstName: paymentData?.firstname,
      lastName: paymentData?.lastname,
      phone: paymentData?.phonenumber,
      username: paymentData?.email,
      phoneCountryId: paymentData?.countryPhone,
      campaign_type: "SOC",
    };
    let withLoginData = {
      plan_id: selectedPlanDetailsStore?.id,
      plateform: "web",
      isFeatured: cardsave,
      couponCode: couponCode ? couponCode : "",
      firstName: paymentData?.firstname,
      lastName: paymentData?.lastname,
      phone: paymentData?.phonenumber,
      username: paymentData?.email,
      phoneCountryId: paymentData?.countryPhone,
      card: {
        card_holder_name: paymentData?.cardname,
        card_number: paymentData?.cardNumber?.replace(/\s/g, ""),
        card_exp: paymentData?.expirationDate,
        cvv: paymentData?.cvv,
      },
      campaign_type: "SOC",
    };
    let withOutLoginData = {
      firstName: paymentData?.firstname,
      lastName: paymentData?.lastname,
      phone: paymentData?.phonenumber,
      address: {
        country: paymentData?.country,
        state: paymentData?.state,
      },
      username: paymentData?.email,
      password: paymentData?.password,
      dob: moment(paymentData?.dob).format("YYYY-MM-DD"),
      phoneCountryId: paymentData?.countryPhone,
      address: {
        country: paymentData?.country,
        state: paymentData?.state,
      },
      plan_id: 2,
      plateform: "web",
      card: {
        card_holder_name: paymentData?.cardname,
        card_number: paymentData?.cardNumber,
        card_exp: paymentData?.expirationDate,
        cvv: paymentData?.cvv,
      },
      campaign_type: "SOC",
    };

    try {
      let passApi = "/subscription/subscribe-plan";
      let method = "post";
      const { status, data } = await axiosInstance[method](
        passApi,
        isLogin
          ? paymentMode
            ? withLoginDataID
            : withLoginData
          : withOutLoginData,
      );
      if (status === 200) {
        //call gatag script data

        window.gtag(
          "event",
          "purchase",
          googleTagData(data?.result?.transactionId),
        );
        setApiMessage("success", data?.message);
        if (getUserData?.isVarify) {
          setCardSave(false);
          setSelectedCard("");
          fetchSubscriptionPaymentData();
          setOtpVerifyModal(false);
          navigate("/mySubscription");
        } else {
          setOtpVerifyModal(true);
          setSubscribeLoading(false);
        }
      } else {
        setSubscribeLoading(false);
        setCardSave(false);
        setSelectedCard("");
      }
    } catch (error) {
      setSubscribeLoading(false);
      setCardSave(false);
      setSelectedCard("");
      setApiMessage("error", error?.response?.data?.message);
    }
  };

  const handleOtpVerifyModalClose = () => {
    setOtpVerifyModal(false);
  };

  const handleVerifyOtp = async (value) => {
    setisLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        // `user/verifyOtp/${combinedData?.email}/${value?.otp}`
        `user/verifyOtp/${combinedData?.email}/${value?.otp}?isRegister=1`,
      );
      if (status === 200) {
        setisLoading(false);
        let { access_token } = data;
        setIsToken(access_token);
        let Type = localStorage.getItem("type");
        let Url = localStorage.getItem("url");
        if (Type && Url) {
          window.location.href = Url;
          localStorage.removeItem("type");
          localStorage.removeItem("url");
          localStorage.setItem("smartb_user", combinedData?.email);
          localStorage.setItem("auth_token", JSON.stringify(access_token));
          dispatch(loginUser(access_token));
          dispatch(fetchRestrictedRoute(false));
        } else {
          let prev_auth_route = localStorage.getItem("prev_authroute");
          localStorage.setItem("auth_token", JSON.stringify(access_token));
          dispatch(loginUser(access_token));
          // setIsFinish(true);
          setOtp(false);
          setOtpError("");
          setTimeout(async () => {
            setCardSave(false);
            setSelectedCard("");
            fetchSubscriptionPaymentData();
            setOtpVerifyModal(false);
            navigate("/mySubscription");
          }, 2000);
        }
      }
    } catch (err) {
      console.log("err", err?.response);
      setOtpError(err?.response?.data?.message);
      setisLoading(false);
    }
  };
  const handleResendOtp = async () => {
    setisLoading(true);
    setOtpError("");
    try {
      const { status, data } = await axiosInstance.get(
        `user/resendOtp/${combinedData?.email}`,
      );
      if (status === 200) {
        setisLoading(false);
        toast.success(localesData?.register?.OTP_SENT, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (err) {
      console.log("err", err?.response);
      setisLoading(false);
    }
  };

  return (
    <>
      <Box className="membership-from-details-wrap">
        <MemberPersonalInfo
          innerRef={formikRef1}
          emailmsg={emailmsg}
          setEmailmsg={setEmailmsg}
        />
        <CouponCodeDetails
          setCouponCode={setCouponCode}
          couponCode={couponCode}
          setCouponApply={setCouponApply}
          couponApply={couponApply}
          setCouponCodeDetails={setCouponCodeDetails}
          couponCodeDetails={couponCodeDetails}
        />
        <PaymentForm
          innerRef={formikRef2}
          setPaymentMode={setPaymentMode}
          paymentMode={paymentMode}
          setCardSave={setCardSave}
          cardsave={cardsave}
          setSelectedCard={setSelectedCard}
          selectedCard={selectedCard}
        />
        <Box className="membership-button-wrap">
          <Button
            onClick={handleSubmit}
            variant="contained"
            className="membership-get-btn"
            disabled={emailmsg}
          >
            {isLogin
              ? "Start free trial"
              : "Sign up and start your free trial now!"}
          </Button>
          <Typography className="subscription-warning">
            You will be charged after your free 14 days trial expires. You can
            change or cancel your subscription anytime.
          </Typography>
        </Box>
      </Box>
      <DialogBox
        open={otpVerifyModal}
        onClose={handleOtpVerifyModalClose}
        handleClose={handleOtpVerifyModalClose}
        title={false}
        className="my-subscription-otp-modal"
        content={
          <>
            <Box className="otp-msg-wrap">
              <Formik
                initialValues={{
                  otp: "",
                }}
                validationSchema={Yup.object().shape({
                  otp: Yup.string()
                    .required(localesData?.validation?.required_message)
                    .matches(/^\d{6}$/, "OTP must be a 6-digit number"),
                })}
                onSubmit={(values) => handleVerifyOtp(values)}
              >
                {({
                  errors,
                  resetForm,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                  ...formik
                }) => (
                  <form onSubmit={handleSubmit}>
                    {isLoading ? (
                      <div className="auth-loader">
                        <Loader />
                      </div>
                    ) : (
                      <></>
                    )}
                    <Typography className="login-title login-heading">
                      {localesData?.register?.enter_otp}
                    </Typography>
                    <Typography className="otp-content">
                      We have sent an OTP to{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {combinedData?.email}
                      </span>{" "}
                      Please check it and enter the OTP here. If you don't
                      receive an email in your inbox, please check your spam or
                      junk folder
                    </Typography>
                    <Box className="textfield-sec">
                      <Typography className="textfield-text">
                        {localesData?.register?.otp}
                      </Typography>
                      <TextField
                        placeholder={""}
                        variant="outlined"
                        type="text"
                        name="otp"
                        className="text-field"
                        error={Boolean(touched?.otp && errors?.otp)}
                        helperText={touched?.otp ? errors?.otp : ""}
                        onChange={handleChange}
                        value={values?.otp}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            maxLength: 6,
                          },
                        }}
                        onFocus={() => setOtpError("")}
                      />
                      <span className="text-danger">{isOtpError}</span>
                    </Box>
                    <Typography
                      className="forgot-password-link"
                      onClick={() => {
                        resetForm();
                        handleResendOtp();
                      }}
                    >
                      {localesData?.register?.RESEND_OTP}
                    </Typography>
                    <Button
                      type="submit"
                      className="submit-btn"
                      // disabled={customerUserLoader}
                    >
                      {localesData?.register?.VERIFY_OTP}
                    </Button>
                  </form>
                )}
              </Formik>
            </Box>
          </>
        }
        closeIcon={true}
      />
    </>
  );
};

export default MembershipFromDetails;
