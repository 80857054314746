import React, { useState, useRef } from "react";
import {
  Button,
  Popover,
  ListItemIcon,
  ListItemText,
  ListItem
} from "@mui/material";
import { ReactComponent as Arrowdown } from "src/assets/images/eventcalendar/arrowdown.svg";
import { ReactComponent as Arrowup } from "src/assets/images/eventcalendar/arrowup.svg";
import { ReactComponent as Month } from "src/assets/images/eventcalendar/month.svg";
import { ReactComponent as Day } from "src/assets/images/eventcalendar/day.svg";
import { ReactComponent as ListIcon } from "src/assets/images/eventcalendar/list.svg";
import { ReactComponent as SelectedMonth } from "src/assets/images/eventcalendar/selectedmonth.svg";
import { ReactComponent as SelectedDay } from "src/assets/images/eventcalendar/selectedday.svg";

const SelectList = ({ onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [select, setSelect] = useState("day");
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setDDArray(dropdownArray)
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Button
        endIcon={anchorEl ? <Arrowdown /> : <Arrowup />}
        startIcon={
          select === "month" ? (
            <SelectedMonth />
          ) : select === "day" ? (
            <SelectedDay />
          ) : select === "list" ? (
            <ListIcon />
          ) : (
            <></>
          )
        }
        className={
          select === "month" || select === "day" || select === "list"
            ? "calendar-list-button selected-calendar-list"
            : "calendar-list-button"
        }
        onClick={handleClick}
      >
        {select === "month"
          ? "Month"
          : select === "day"
          ? "Day"
          : select === "list"
          ? "List"
          : ""}
      </Button>
      <Popover
        className="calendar-list"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <ListItem
          className="list-item"
          onClick={() => {
            setSelect("month");
            onSelect("month");
            handleClose();
          }}
        >
          <ListItemIcon className="list-icon">
            <Month />
          </ListItemIcon>
          <ListItemText primary={"Month"} className="list-text" />
        </ListItem>
        <ListItem
          className="list-item"
          onClick={() => {
            setSelect("day");
            onSelect("day");
            handleClose();
          }}
        >
          <ListItemIcon className="list-icon">
            <Day />
          </ListItemIcon>
          <ListItemText primary={"Day"} className="list-text" />
        </ListItem>
        <ListItem
          className="list-item"
          onClick={() => {
            setSelect("list");
            onSelect("list");
            handleClose();
          }}
        >
          <ListItemIcon className="list-icon">
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary={"List"} className="list-text" />
        </ListItem>
      </Popover>
    </>
  );
};

export default SelectList;
