import React, { lazy, Suspense, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Navigate } from "react-router-dom";
import AdvertisingBannner from "../../advertisingBanner";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import pageHeaderIndia from "../../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import "./teamSports.scss";
// import TeamSportsLayouts from "./allTeamSports/teamSportsLayouts";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import AdBannner from "../../AdBanner/AdBanner";
import AboveOurPartner from "../../../../assets/images/ad-placeholder/sports2.webp";
import BelowOurPartner from "../../../../assets/images/ad-placeholder/sports5.webp";
import BannerIndia from "../../../../assets/images/ad-placeholder/IPL_Page_Banner_894x218_4.jpg";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import ScriptLoader from "../../scriptLoader/ScriptLoader";
import { Helmet } from "react-helmet";
import { Config } from "src/helpers/context";
import Loader from "src/components/Loader";

const OurPartner = lazy(() => import("../../ourPartners"));
const TeamSportsLayouts = lazy(
  () => import("./allTeamSports/teamSportLayoutsV2"),
);

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const TeamSports = () => {
  const { search, pathname, state } = useLocation();
  let teamSportType = pathname?.split?.("/")?.[2];
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    fetchAdsData();
  }, [teamSportType]);

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  // const handleAdsImression = async ids => {
  //   try {
  //     const payload = {
  //       ids: ids
  //     };
  //     const { status, data } = await axiosInstance.post(
  //       `addImpression`,
  //       payload
  //     );
  //     if (status === 200) {
  //     }
  //   } catch (err) {}
  // };

  const fetchAdsData = async () => {
    try {
      let pageId =
        teamSportType === "cricket"
          ? 6
          : teamSportType === "rugbyleague"
            ? 7
            : teamSportType === "rugbyunion"
              ? 8
              : teamSportType === "basketball"
                ? 12
                : teamSportType === "americanfootball"
                  ? 16
                  : teamSportType === "australianrules"
                    ? 17
                    : teamSportType === "baseball"
                      ? 18
                      : teamSportType === "boxing"
                        ? 19
                        : teamSportType === "icehockey"
                          ? 20
                          : teamSportType === "mma"
                            ? 21
                            : teamSportType === "soccer"
                              ? 22
                              : teamSportType === "tennis"
                                ? 23
                                : "";
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=${pageId}&type=web&timeZone=${timezone}`,
      );
      if (status === 200) {
        setAdData(data?.result);
        // let ids = [];
        // let listAds = data?.data?.[0]?.pageAdLists?.map(item => {
        //   ids.push(item?.id);
        // });
        // if (ids.length > 0) {
        //   handleAdsImression(ids);
        // }
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };
  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  const scriptCode = `
  rdt('track', 'ViewContent', {
    "products": [
      {
        "id": "product id 1",
        "name": "product name 1",
        "category": "product category 1"
      },
      // additional products can be added here
    ]
  });
`;
  return (
    <>
      {teamSportType === "cricket" ? (
        <Helmet>
          <title>SmartB | More on Team Sports Cricket | Learn More</title>
          <meta
            name="description"
            content="Cricket and other sports. Find out everything you need to know with smartb. Best Odds comparison platform on the web for cricket in all formats."
          />
        </Helmet>
      ) : teamSportType === "rugbyleague" ? (
        <Helmet>
          <title>SmartB | More On Team Sports | Rugby league Information</title>
          <meta
            name="description"
            content="Rugby league and other sports. Find out everything you need to know with smartb. Best Odds comparison platform on the web for all."
          />
        </Helmet>
      ) : teamSportType === "rugbyunion" ? (
        <Helmet>
          <title>SmartB | More On Team Sports | Rugby Union Information</title>
          <meta
            name="description"
            content="More on Rugby Union. Find out everything you need to know with Smartb. Best Odds comparison platform on the web for all."
          />
        </Helmet>
      ) : teamSportType === "basketball" ? (
        <Helmet>
          <title>SmartB | More on Team Sports Basketball | Learn More</title>
          <meta
            name="description"
            content="Unlock the latest news  on Basketball and other sports. Find out everything you need to know with the smartest sports ecosystem called SmartB. "
          />
        </Helmet>
      ) : teamSportType === "americanfootball" ? (
        <Helmet>
          <title>
            SmartB | More on Team Sports American Football | Learn More
          </title>
          <meta
            name="description"
            content="American Football and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats."
          />
        </Helmet>
      ) : teamSportType === "australianrules" ? (
        <Helmet>
          <title>
            SmartB | More on Team Sports Australian Rules | Learn More
          </title>
          <meta
            name="description"
            content="Australian Rules and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats."
          />
        </Helmet>
      ) : teamSportType === "baseball" ? (
        <Helmet>
          <title>SmartB | More on Team Sports Baseball | Learn More</title>
          <meta
            name="description"
            content="Baseball and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats."
          />
        </Helmet>
      ) : teamSportType === "boxing" ? (
        <Helmet>
          <title>SmartB | More on Team Sports Boxing | Learn More</title>
          <meta
            name="description"
            content="Boxing and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats."
          />
        </Helmet>
      ) : teamSportType === "icehockey" ? (
        <Helmet>
          <title>SmartB | More on Team Sports Ice Hockey | Learn More</title>
          <meta
            name="description"
            content="Ice Hockey and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats."
          />
        </Helmet>
      ) : teamSportType === "mma" ? (
        <Helmet>
          <title>
            SmartB | More on Team Sports Mixed Martial Arts | Learn More
          </title>
          <meta
            name="description"
            content="Mixed Martial Arts and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats."
          />
        </Helmet>
      ) : teamSportType === "soccer" ? (
        <Helmet>
          <title>SmartB | More on Team Sports Soccer | Learn More</title>
          <meta
            name="description"
            content="Soccer and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats."
          />
        </Helmet>
      ) : teamSportType === "tennis" ? (
        <Helmet>
          <title>SmartB | More on Team Sports Tennis | Learn More</title>
          <meta
            name="description"
            content="Tennis and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats."
          />
        </Helmet>
      ) : teamSportType === "golf" ? (
        <Helmet>
          <title>SmartB | More on Team Sports Golf | Learn More</title>
          <meta
            name="description"
            content="Golf and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats."
          />
        </Helmet>
      ) : (
        ""
      )}
      <ScriptLoader scriptCode={scriptCode} />
      <Box className="content-wrap">
        {/* <AdvertisingBannner src={banner} /> */}
        {/* {fetchAds(1, "218px", banner)} */}
        <Box className="banner-img-fix">
          {fetchPageHeaderAds(
            "218px",
            Config.release == "IN" ? pageHeaderIndia : banner,
          )}
        </Box>
        <Suspense
          fallback={
            <div className="allsport-loader-center homepage-loader">
              <Loader />
            </div>
          }
        >
          <TeamSportsLayouts
            teamSportType={teamSportType}
            fetchAds={fetchAds}
          />
        </Suspense>
        {fetchAds(
          2,
          "100%",
          Config.release == "IN" ? BannerIndia : AboveOurPartner,
        )}
        <Suspense
          fallback={
            <div className="allsport-loader-center homepage-loader">
              <Loader />
            </div>
          }
        >
          <OurPartner />
        </Suspense>
        {Config.release == "AU" && fetchAds(3, "102px", BelowOurPartner)}
      </Box>
    </>
  );
};

export default TeamSports;
