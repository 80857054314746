import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { Config } from "../../../../helpers/context/config";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import moment from "moment";

const SoccerCard = ({ theme, type, cardData, index }) => {
  const navigate = useNavigate();
  const handleNavigate = data => {
    const eventSlug = data?.eventName
      ? data?.eventName
          .trim()
          .toLowerCase()
          .replaceAll(" ", "-")
          .replaceAll("/", "-")
      : "event";

    navigate(
      `/teamsports/soccer/${
        data?.SoccerTournament?.Scd ? data?.SoccerTournament?.Scd : "tournament"
      }/${eventSlug}/info/${data?.SportId}/${data?.SoccerTournamentId}/${
        data?.id
      }`
    );
  };
  return (
    <>
      {type === "upcoming" ? (
        <Box
          className={`${theme} match-details-wrap cursor-pointer`}
          onClick={() => handleNavigate(cardData)}
          key={index}
        >
          <Box className="card-wrap">
            <Box className="d-flex align-center content-space-between mb-10">
              <Box className="d-flex align-center content-space-between left-section">
                <Box className="date-time-wrap">
                  <Typography className="date-time-text fs-16">
                    {cardData?.ScoreBoard
                      ? cardData?.ScoreBoard?.Esd &&
                        moment(cardData?.ScoreBoard?.Esd)?.format("DD MMM")
                      : moment(cardData?.startTime)?.format("DD MMM")}
                  </Typography>
                </Box>
                <Box className="sport-team-name d-flex align-center">
                  <Box className="sport-team-img">
                    <img
                      src={
                        cardData?.homeTeam?.flag
                          ? Config.mediaURL + cardData?.homeTeam?.flag
                          : Brisbane
                      }
                      alt="img"
                    />
                  </Box>
                  <Box>
                    <Typography className="sport-team-name-text fs-16">
                      {cardData?.homeTeam?.name ?? ""}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="right-section">
                <Typography className="team-score"></Typography>
              </Box>
            </Box>
            <Box className="d-flex align-center content-space-between">
              <Box className="d-flex align-center content-space-between left-section">
                <Box className="date-time-wrap">
                  <Typography className="date-time-text fs-16">
                    {cardData?.ScoreBoard
                      ? cardData?.ScoreBoard?.Esd &&
                        moment(cardData?.ScoreBoard?.Esd)?.format("HH:mm")
                      : moment(cardData?.startTime)?.format("HH:mm")}
                  </Typography>
                </Box>
                <Box className="sport-team-name d-flex align-center">
                  <Box className="sport-team-img">
                    <img
                      src={
                        cardData?.awayTeam?.flag
                          ? Config.mediaURL + cardData?.awayTeam?.flag
                          : Melbourne
                      }
                      alt="img"
                    />
                  </Box>
                  <Box>
                    <Typography className="sport-team-name-text fs-16">
                      {cardData?.awayTeam?.name ?? ""}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="right-section">
                <Typography className="team-score"></Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : type === "past" ? (
        <Box
          className={`${theme} match-details-wrap cursor-pointer`}
          onClick={() => handleNavigate(cardData)}
          key={index}
        >
          <Box className="card-wrap">
            <Box className="d-flex align-center content-space-between mb-10">
              <Box className="d-flex align-center content-space-between left-section">
                <Box className="date-time-wrap">
                  <Typography className="date-time-text fs-16">
                    {moment(cardData?.ScoreBoard?.Esd)?.format("DD MMM")}
                  </Typography>
                </Box>
                <Box className="sport-team-name d-flex align-center">
                  <Box className="sport-team-img">
                    <img
                      src={
                        cardData?.homeTeam?.flag
                          ? Config.mediaURL + cardData?.homeTeam?.flag
                          : Brisbane
                      }
                      alt="img"
                    />
                  </Box>
                  <Box>
                    <Typography className="sport-team-name-text fs-16">
                      {cardData?.homeTeam?.name ?? ""}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="right-section">
                <Typography className="team-score">
                  {cardData?.ScoreBoard?.Tr1}
                </Typography>
              </Box>
            </Box>
            <Box className="d-flex align-center content-space-between">
              <Box className="d-flex align-center content-space-between left-section">
                <Box className="date-time-wrap">
                  <Typography className="date-time-text text-align-center fs-16">
                    {cardData?.ScoreBoard?.Eps && cardData?.ScoreBoard?.Eps}
                  </Typography>
                </Box>
                <Box className="sport-team-name d-flex align-center">
                  <Box className="sport-team-img">
                    <img
                      src={
                        cardData?.awayTeam?.flag
                          ? Config.mediaURL + cardData?.awayTeam?.flag
                          : Melbourne
                      }
                      alt="img"
                    />
                  </Box>
                  <Box>
                    <Typography className="sport-team-name-text fs-16">
                      {cardData?.awayTeam?.name ?? ""}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="right-section">
                <Typography className="team-score">
                  {cardData?.ScoreBoard?.Tr2}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : type === "live" ? (
        <Box
          className={`${theme} match-details-wrap cursor-pointer`}
          onClick={() => handleNavigate(cardData)}
          key={index}
        >
          <Box className="divider-indicator"></Box>
          <Box className="card-wrap card-wrap-live d-flex align-center">
            <Box style={{ width: "65px" }}>
              {" "}
              <Typography className="date-time-text text-align-center fs-16">
                {cardData?.ScoreBoard?.Eps && cardData?.ScoreBoard?.Eps}
              </Typography>
            </Box>
            <Box style={{ width: "100%" }}>
              <Box className="d-flex align-center content-space-between mb-10">
                <Box className="d-flex align-center content-space-between left-section">
                  <Box className="sport-team-name d-flex align-center">
                    <Box className="sport-team-img">
                      <img
                        src={
                          cardData?.homeTeam?.flag
                            ? Config.mediaURL + cardData?.homeTeam?.flag
                            : Brisbane
                        }
                        alt="img"
                      />
                    </Box>
                    <Box>
                      <Typography className="sport-team-name-text fs-16">
                        {cardData?.homeTeam?.name ?? ""}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="right-section">
                  <Typography className="team-score">
                    {cardData?.ScoreBoard?.Tr1}
                  </Typography>
                </Box>
              </Box>
              <Box className="d-flex align-center content-space-between">
                <Box className="d-flex align-center content-space-between left-section">
                  <Box className="sport-team-name d-flex align-center">
                    <Box className="sport-team-img">
                      <img
                        src={
                          cardData?.awayTeam?.flag
                            ? Config.mediaURL + cardData?.awayTeam?.flag
                            : Melbourne
                        }
                        alt="img"
                      />
                    </Box>
                    <Box>
                      <Typography className="sport-team-name-text fs-16">
                        {cardData?.awayTeam?.name ?? ""}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="right-section">
                  <Typography className="team-score">
                    {cardData?.ScoreBoard?.Tr2}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default SoccerCard;
