import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button
} from "@mui/material";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "../../../helpers/context/config";
import Pagination from '@mui/material/Pagination';
import { ReactComponent as Delete } from "src/assets/images/icons/delete.svg";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import { ReactComponent as EditIcon } from "src/assets/images/icons/editIcon.svg";
import Bet365 from "../../../assets/images/bookmakers/bet365-thumb.svg";
import AUS from "../../../assets/images/flag/au.png";
import moment from "moment";
import OurPartner from "../ourPartners";
import Loader from "src/components/Loader";
import { useState } from "react";
import { useEffect } from "react";
import DialogBox from "../UI/dialogBox";
import { ToastContainer, toast } from "react-toastify";
import BlackBookModal from "../UI/blackBookModal";
import NoDataComp from "../UI/NoData";

const customData = [
  {
    id: 1,
    type: "runner"
  },
  {
    id: 2,
    type: "runner"
  },
  {
    id: 3,
    type: "runner"
  },
  {
    id: 4,
    type: "track"
  }
];

const BlackBook = ({
  allBBData,
  isAllBBLoading,
  fetchAllBlackBook,
  handlePaginationClick,
  allBBCount,
  allBBPage,
  pageNumbers,
  isToggle
}) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [editComment, setEditComment] = useState("");
  const [open, setOpen] = useState(false);
  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };
  const handleDelete = (type, id) => {
    setSelectedType(type);
    setSelectedId(id);
    handleDeleteModal();
  };
  const handleDeleteModal = () => {
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setSelectedType("");
    setSelectedId("");
  };
  const handleuserDelete = async (type, id) => {
    try {
      const { status, data } = await axiosInstance.delete(
        `blackbook/favoriteData/${id}?type=${type}`
      );
      if (status === 200) {
        toast.success("Deleted Successfully", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });

        handleDeleteClose();

        fetchAllBlackBook(allBBPage);
      } else {
      }
    } catch (error) {}
  };
  const handleEdit = async (type, id, item) => {
    setSelectedType(type);
    const typeId = item?.dataId;

    setTypeId(typeId);
    setSelectedId(id);
    const title = item?.name;
    const comment = item?.comment;
    setEditComment(comment);
    setSelectedTitle(title);
    handleModal();
  };
  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedType("");
    setSelectedId("");
  };

  const fetchRunnerData = item => {
    const runnerInfo = JSON.parse(item?.runnerInfo);
    return (
      <Box>
        <Typography className="details mr57">
          Career:{" "}
          <span>
            {runnerInfo.past_runner_performances?.overall?.starts
              ? runnerInfo.past_runner_performances?.overall?.starts
              : "0"}{" "}
            {runnerInfo.past_runner_performances?.overall?.wins
              ? runnerInfo.past_runner_performances?.overall?.wins
              : "0"}
            -
            {runnerInfo.past_runner_performances?.overall?.seconds
              ? runnerInfo.past_runner_performances?.overall?.seconds
              : "0"}
            -
            {runnerInfo.past_runner_performances?.overall?.thirds
              ? runnerInfo.past_runner_performances?.overall?.thirds
              : "0"}
          </span>
        </Typography>
        <Typography className="details">
          L{" "}
          {runnerInfo.runner_info?.last_starts
            ? runnerInfo.runner_info?.last_starts?.length
            : 10}
          :{" "}
          <span>
            {runnerInfo.runner_info?.last_starts
              ? runnerInfo.runner_info?.last_starts
              : "N/A"}{" "}
          </span>
        </Typography>
      </Box>
    );
  };
  return (
    <>
      <Box className="page-content-wrap-v2">
        {isAllBBLoading ? (
          <div className="allsport-loader-center ">
            <Loader />
          </div>
        ) : allBBData && allBBData?.Favorites?.length > 0 ? (
          allBBData?.Favorites?.map((item, index) => {
            return (
              <>
                <Box className="blackbook-runner-details" key={index}>
                  <Box className="runner-deatils blackbook-details-mobile">
                    {item?.type !== "Track" ? (
                      <>
                        <Box className="runner-info blackbook-ruuner-info">
                          <Typography className="runner-name">
                            {item?.name}
                          </Typography>
                          <Box className="blackbook-details">
                            {item?.type === "Runner" ? (
                              <>{fetchRunnerData(item)}</>
                            ) : (
                              <Typography className="details mr57">
                                LY:{" "}
                                <span>
                                  {item?.LY?.starts ? item?.LY?.starts : "0"}{" "}
                                  {item?.LY?.first ? item?.LY?.first : "0"}-
                                  {item?.LY?.second ? item?.LY?.second : "0"}-
                                  {item?.LY?.thirds ? item?.LY?.thirds : "0"}
                                </span>
                              </Typography>
                            )}
                            <Typography className="details">
                              ROI:<span>N/A</span>
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="top-header-details black-book-header">
                          <Box className="Updated-date">
                            <Typography className="date">
                              Updated:{" "}
                              {moment
                                .utc(item?.updatedAt)
                                .local()
                                .format("DD/MM/YYYY")}
                            </Typography>
                            <Box
                              className="delete-box cursor-pointer"
                              onClick={() => handleDelete(item?.type, item?.id)}
                            >
                              <Delete />
                            </Box>
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <>
                        {" "}
                        <Box className="runner-info track-blackbook-name">
                          <Typography className="runner-name track-flag">
                            {item?.flag ? (
                              <img
                                src={
                                  item?.flag?.includes("uploads")
                                    ? Config.mediaURL + item?.flag
                                    : item?.flag
                                }
                                alt="Race Contry"
                                className="flag"
                              />
                            ) : (
                              <></>
                            )}
                            {item?.name}
                          </Typography>
                        </Box>
                        <Box className="top-header-details black-book-header">
                          <Box className="Updated-date">
                            <Typography className="date">
                              Updated:{" "}
                              {moment
                                .utc(item?.updatedAt)
                                .local()
                                .format("DD/MM/YYYY")}
                            </Typography>
                            <Box
                              className="delete-box cursor-pointer"
                              onClick={() => handleDelete(item?.type, item?.id)}
                            >
                              <Delete />
                            </Box>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                  {isToggle ? (
                    <Box className="comment-section">
                      <Box className="runner-comment">
                        <Typography className="runner">
                          {" "}
                          {item?.type}:
                        </Typography>
                        <Typography className="runner comment">
                          {item?.comment}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          className="edit-comment cursor-pointer"
                          onClick={() => handleEdit(item?.type, item?.id, item)}
                        >
                          <EditIcon />
                          Edit comment
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </>
            );
          })
        ) : (
          <>
            <Box style={{ border: "transparent" }}>
              <Box
                style={{
                  textAlign: "center",
                  border: "transparent",
                  fontFamily: "sans-serif"
                }}
              >
                {/* No Data Available */}
                <NoDataComp />
              </Box>
            </Box>
          </>
        )}
        {!isAllBBLoading && allBBData && allBBData?.Favorites?.length > 0 ? (
          <Box className="pagination-section">
            {/* <Pagination count={1} /> */}
            <Pagination
              // hideNextButton
              // hidePrevButton
              disabled={allBBCount / 10 > 1 ? false : true}
              page={allBBPage}
              onChange={(e, value) => handlePaginationClick(e, value)}
              count={pageNumbers[pageNumbers?.length - 1]}
              siblingCount={2}
              boundaryCount={1}
              // count={10}
              size="small"
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <OurPartner />
      <DialogBox
        open={deleteOpen}
        onClose={handleDeleteClose}
        handleClose={handleDeleteClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="delete-title">
                Are you sure you want to delete?
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel blackbook-delete"
                  onClick={() => {
                    handleuserDelete(selectedType, selectedId);
                  }}
                >
                  Yes
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-save"
                  onClick={() => handleDeleteClose()}
                >
                  No
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />
      <BlackBookModal
        open={open}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        id={selectedId}
        typeId={typeId}
        editComment={editComment}
        isEdit={true}
        tabType="Blackbook"
        listingFunction={() => fetchAllBlackBook(allBBPage)}
      />
      {/* <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> */}
    </>
  );
};

export default BlackBook;
