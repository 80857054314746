import React, { useContext } from "react";
import { Box } from "@mui/material";
import { IntlContext } from "src/App";
import MySubscriptionPlan from "../../subscriptionAndPayment/mySubscriptionPlan";
import MembershipPage from "../../membershipPage";

const ProfileSubscription = ({ data, handleUserProfile }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  return (
    <>
      <Box>
        <Box className="useredit-details">
          <h4>{localesData?.EDITPROFILE?.MEMBERSHIP}</h4>
        </Box>
        {data?.subscription?.status === "active" ? (
          <Box>
            <MySubscriptionPlan profileModal="profile-modal" />
          </Box>
        ) : (
          <Box>
            <MembershipPage profileModal="profile-modal" />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProfileSubscription;
