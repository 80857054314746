import React, { useState, useContext, useEffect, useRef } from "react";
import { IntlContext } from "src/App";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from "react-redux";
import "./rightsidebar.scss";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "../../../components/Loader";
import adNews from "../../../assets/images/ad-placeholder/adnews.webp";
import AdBannner from "../AdBanner/AdBanner";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import News1 from "../../../assets/images/news1.png";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import DefaultImg from "../../../assets/images/smartb_default.png";
import DialogBox from "../UI/dialogBox";
import { ReactComponent as ArrowRightIcon } from "src/assets/images/eventcalendar/arrowRight.svg";
import moment from "moment-timezone";
import { Config } from "src/helpers/context";
import { release } from "src/helpers/context/release";

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 200,
    cursor: "pointer",
  },
  noMaxWidth: {
    maxWidth: "none",
  },
}));

const featuredVideoData = [
  {
    id: 1,
    img: News1,
    videoLink:
      "https://www.youtube.com/embed/videoseries?si=vA3hHmbWf1Kz7XZR&list=PLJGv8nkG93Wkr0vVF707lKWm3mx3zy7nF",
  },
  {
    id: 2,
    img: News1,
    videoLink:
      "https://www.youtube.com/embed/videoseries?si=rCFHMPiNv_f7vfGq&list=PLJGv8nkG93WnR08ZVu_jMOLk332l8s4rB",
  },
];
let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default function RightSidebar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [isLoading, setLoading] = useState(false);
  const [newsData, setnewsData] = useState([]);
  const [eventData, setEventData] = useState([{}, {}, {}, {}]);
  const [AdData, setAdData] = useState([]);
  const [TopStoryData, setTopStoryData] = useState([]);
  const [isTopStoryLoading, setIsTopStoryLoading] = useState(false);
  const [visibleAds, setVisibleAds] = useState([]);
  const [open, setOpen] = useState(false);
  const [videoURL, setVideoURL] = useState("");
  const [isEventLoading, setEventLoading] = useState(false);
  const [relatedEventData, setRelatedEventData] = useState();
  const [topBookMakersData, setTopBookMakersData] = useState([]);
  const [recommendedBookmakersData, setRecommendedBookmakersData] = useState(
    []
  );

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    // fetchNews();
    // fetchTopStoryData();
    if (release[Config.release]?.rightSideBar) {
      fetchTopBookmakers();
    }
  }, []);

  useEffect(() => {
    if (ReduxAdsData) {
      let AdvtData = ReduxAdsData?.filter((item) => item?.page_id === 40);

      setAdData(AdvtData);
    }
  }, [ReduxAdsData]);
  const fetchAds = (position, height, placeholder) => {
    // let ad = AdData?.filter((item) => {
    //   return item?.position_id === position;
    // });
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };

  const fetchNews = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/v2/news/feature/articles`
      );
      if (status === 200) {
        setLoading(false);
        const articalData = data?.result;
        setnewsData(articalData);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchTopBookmakers = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `apiProviders/bookKeepers`
      );
      if (status === 200) {
        setLoading(false);

        const bookMakersData = data?.result;
        const isFeaturedData = bookMakersData?.filter(
          (item) => item?.isFeatured === true
        );
        const recommendedBookmaker = bookMakersData?.filter(
          (item) => item?.isFeatured === false
        );
        const sortedData = isFeaturedData?.sort(
          (a, b) => a.featured_order - b.featured_order
        );
        const recommendedSortedData = recommendedBookmaker?.sort(
          (a, b) => a.featured_order - b.featured_order
        );
        setTopBookMakersData(sortedData);
        setRecommendedBookmakersData(recommendedSortedData);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchTopStoryData = async () => {
    try {
      setIsTopStoryLoading(true);
      const { status, data } = await axiosInstance.get(
        `v2/news/topstory/articles?limit=8&offset=0`
      );
      if (status === 200) {
        setTopStoryData(data?.result);
        setIsTopStoryLoading(false);
      } else {
        setIsTopStoryLoading(false);
      }
    } catch (err) {
      setIsTopStoryLoading(false);
    }
  };

  const handleOpenModal = (url) => {
    setOpen(true);
    setVideoURL(url);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getRelatedeventData = async (sId, tId) => {
    setEventLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/eventCalendar/reletedEvent?sportId=${sId}&tournamentid=${tId}`
      );
      if (status === 200) {
        setEventLoading(false);
        data &&
          data?.result &&
          data?.result.length > 0 &&
          setRelatedEventData(data?.result.slice(0, 6));
      }
    } catch (err) {
      setEventLoading(false);
    }
  };
  useEffect(() => {
    if (
      location.pathname?.includes(`event-calendar/details`) &&
      (params?.sportId || params?.tournamentId)
    ) {
      var Tid =
        params?.tournamentId && params?.tournamentId !== null
          ? params?.tournamentId
          : "";
      var sid =
        params?.SportId !== null && params?.SportId ? params?.SportId : "";
      getRelatedeventData(sid, Tid);
    }
  }, [params?.sportId, params?.tournamentId]);

  // useEffect(() => {
  //   const iframes = document.querySelectorAll("iframe");
  //   iframes.forEach((iframe) => {
  //     iframe.addEventListener("load", () => {
  //       console.log("Iframe loaded:", iframe);
  //       const iframeDocument = iframe.contentDocument;
  //       if (iframeDocument) {
  //         const htmlElement = iframeDocument.querySelector("html");
  //         if (htmlElement) {
  //           htmlElement.classList.add("custom-iframe-class");
  //         } else {
  //           console.log("HTML element not found in iframe content");
  //         }
  //       } else {
  //         console.log("Iframe contentDocument not accessible");
  //       }
  //     });
  //   });
  // }, [featuredVideoData]);

  const handleFeaturedBookmakersClick = async (data) => {
    const payload = {
      BookKeeperId: data?.id,
      type: "featured",
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
    window.open(data.affiliate_link, "_blank");
  };
  return (
    <>
      {/* <Box className="news-wrap">
        <h4>{localesData?.news_title}</h4>
        {isLoading && (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        )}
        {!isLoading && newsData?.length === 0 && <p>No Data Available</p>}
        {!isLoading &&
          newsData?.length > 0 &&
          newsData?.map((data, index) => {
            return (
              <>
                <Box className="news-content">
                  <Box className="news-meta">
                    <Tooltip
                      title={data?.title}
                      classes={{ tooltip: classes.customWidth }}
                    >
                      <h5>{data?.title}</h5>
                    </Tooltip>
                    <span>{data?.source?.name}</span>
                    <span> - </span>
                    <span>
                      {moment(data?.publishedAt).format("DD/MM/YYYY ")}
                    </span>
                  </Box>
                  <p
                    dangerouslySetInnerHTML={{ __html: data?.description }}
                  ></p>
                  <a href={data?.url} target="_blank" rel="noopener noreferrer">
                    Read More
                  </a>
                </Box>
                {index == 1 ? fetchAds(6, "187px", adNews) : <></>}
              </>
            );
          })}
      </Box> */}

      {/* event calender side bar */}
      {location && location?.pathname.includes("/event-calendar/details") ? (
        <Box className="News-wrap">
          <Typography variant="h4" className="news-heading">
            Related Events{" "}
            {/* <span className='right-arrow'>
              <RightArrow />
            </span> */}
          </Typography>
          {isEventLoading && (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          )}
          {/* {!isEventLoading && relatedEventData?.length === 0 && (
            <Box style={{ textAlign: 'center' }}>
              {' '}
              <Typography className='no-data-avail'>
                Event Data Available
              </Typography>{' '}
            </Box>
          )} */}
          {!isEventLoading &&
            (relatedEventData?.length > 0 ? (
              relatedEventData?.map((item, index) => {
                return (
                  <Box
                    key={index}
                    className="related-event cursor-pointer"
                    onClick={() =>
                      navigate(
                        `/${params?.sportType}/event-calendar/details/${item?.eventId}/${item?.sportId}/${item?.tournamentId}`
                      )
                    }
                  >
                    <Box className="event-data">
                      <Typography className="event-title">
                        {item?.eventName}
                      </Typography>
                      <Typography className="event-text">
                        {moment(item?.eventDate)
                          .tz(timezone)
                          .format("MMMM DD @h:mm A")}
                      </Typography>
                    </Box>
                    <ArrowRightIcon />
                  </Box>
                );
              })
            ) : (
              <Box style={{ textAlign: "center" }}>
                {" "}
                <Typography className="no-data-avail-event ">
                  No Event Data Available
                </Typography>{" "}
              </Box>
            ))}
        </Box>
      ) : // <Box className="News-wrap">
      //   <Typography
      //     variant="h4"
      //     className="news-heading"
      //     onClick={() => navigate(`/news/0`)}
      //   >
      //     Featured News{" "}
      //     <span className="right-arrow">
      //       <RightArrow />
      //     </span>
      //   </Typography>
      //   {isLoading && (
      //     <div className="allsport-loader-center">
      //       <Loader />
      //     </div>
      //   )}
      //   {!isLoading && newsData?.length === 0 && <p>No Data Available</p>}
      //   {!isLoading &&
      //     newsData?.length > 0 &&
      //     newsData?.slice(0, 2)?.map((item, index) => {
      //       const renderImage = item?.NewsArticle?.mainMedia?.gallery
      //         ? item?.NewsArticle?.mainMedia?.gallery?.url !== ""
      //           ? item?.NewsArticle?.mainMedia?.gallery?.url
      //           : DefaultImg
      //         : item?.NewsArticle?.mainMedia?.[0]?.gallery
      //           ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url !== ""
      //             ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url
      //             : DefaultImg
      //           : DefaultImg;
      //       const renderImageAlt = item?.NewsArticle?.mainMedia?.gallery
      //         ? item?.NewsArticle?.mainMedia?.gallery?.alt
      //         : item?.NewsArticle?.mainMedia?.[0]?.gallery
      //           ? item?.NewsArticle?.mainMedia?.[0]?.gallery.alt
      //           : "";
      //       return (
      //         <React.Fragment key={index}>
      //           <Box
      //             className="news-details"
      //             // style={{
      //             //   backgroundImage: `url(${item?.NewsArticle?.mainMedia?.gallery?.url})`,
      //             // }}
      //             onClick={() =>
      //               navigate(
      //                 `/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticleId}`
      //               )
      //             }
      //           >
      //             <Box className="news-img">
      //               <img src={renderImage} alt={renderImageAlt} />
      //             </Box>
      //             <Box className="news-text">
      //               <Link
      //                 to="#"
      //                 dangerouslySetInnerHTML={{
      //                   __html: item?.NewsArticle?.title,
      //                 }}
      //               ></Link>
      //             </Box>
      //           </Box>
      //           {index == 1 ? fetchAds(1, "187px", adNews) : <></>}
      //         </React.Fragment>
      //       );
      //     })}

      //   {/* Top Stories */}

      //   {/* <Typography
      //   variant="h4"
      //   className="news-heading"
      //   onClick={() => navigate(`/news/0`)}
      // >
      //   Top Stories
      //   <span className="right-arrow">
      //     <RightArrow />
      //   </span>
      // </Typography>
      // {isTopStoryLoading && (
      //   <div className="allsport-loader-center">
      //     <Loader />
      //   </div>
      // )}
      // {!isTopStoryLoading && TopStoryData?.length === 0 && (
      //   <p>No Data Available</p>
      // )}
      // {!isTopStoryLoading &&
      //   TopStoryData?.length > 0 &&
      //   TopStoryData?.slice(0, 8)?.map((item, index) => {
      //     const renderImage = item?.NewsArticle?.mainMedia?.gallery ? item?.NewsArticle?.mainMedia?.gallery?.url !== "" ? item?.NewsArticle?.mainMedia?.gallery?.url : DefaultImg : item?.NewsArticle?.mainMedia?.[0]?.gallery ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url !== "" ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url : DefaultImg : DefaultImg;
      //     const renderImageAlt = item?.NewsArticle?.mainMedia?.gallery ? item?.NewsArticle?.mainMedia?.gallery?.alt : item?.NewsArticle?.mainMedia?.[0]?.gallery ? item?.NewsArticle?.mainMedia?.[0]?.gallery.alt : ""
      //     return (
      //       <React.Fragment key={index}>
      //         <Box
      //           className="news-details"
      //           // style={{
      //           //   backgroundImage: `url(${item?.NewsArticle?.mainMedia?.gallery?.url})`,
      //           // }}
      //           onClick={() =>
      //             navigate(
      //               `/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticleId}`
      //             )
      //           }
      //         >
      //           <Box className="news-img">
      //             <img
      //               src={renderImage}
      //               alt={renderImageAlt}
      //             />
      //           </Box>
      //           <Box className="news-text">
      //             <Link to="#">{item?.NewsArticle?.title}</Link>
      //           </Box>
      //         </Box>
      //       </React.Fragment>
      //     );
      //   })} */}

      //   <a href="https://www.youtube.com/@smartbapp/videos" target="_blank">
      //     <Typography
      //       variant="h4"
      //       className="news-heading"
      //     // onClick={() => navigate(`/news/0`)}
      //     >
      //       Featured Videos
      //       <span className="right-arrow">
      //         <RightArrow />
      //       </span>
      //     </Typography>
      //   </a>
      //   {featuredVideoData?.map((item) => {
      //     return (
      //       <>
      //         <Box className="news-details">
      //           <Box
      //             className="news-img"
      //             onClick={() => handleOpenModal(item?.videoLink)}
      //           >
      //             <iframe
      //               width="192"
      //               height="114"
      //               src={`${item?.videoLink}&showinfo=0&controls=0&modestbranding=1`}
      //               title="YouTube video player"
      //               frameborder="0"
      //               allowfullscreen
      //               style={{ pointerEvents: "none" }}
      //             ></iframe>
      //           </Box>
      //         </Box>
      //       </>
      //     );
      //   })}
      // </Box>

      release[Config.release]?.rightSideBar ? (
        <Box className="News-wrap">
          <Typography variant="h4" className="news-heading">
            FEATURED BOOKMAKERS
            {/* <span className="right-arrow">
              <RightArrow />
            </span> */}
          </Typography>
          {isLoading && (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          )}
          {!isLoading && topBookMakersData?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Data Available</p>
          )}
          {!isLoading && topBookMakersData?.length > 0 && (
            <Box className="top-bookmaker-wrap">
              {/* <Typography className="top-bookmaker-heading">
                TOP BOOKMAKER
              </Typography> */}

              {topBookMakersData?.slice(0, 6)?.map((item, index) => {
                return (
                  <Box
                    className="top-bookmaker-detail-wrap  cursor-pointer"
                    onClick={() => handleFeaturedBookmakersClick(item)}
                    key={index}
                  >
                    <Box className="top-bookmaker-left">
                      <img
                        src={
                          item?.featured_logo
                            ? Config.mediaURL + item?.featured_logo
                            : DefaultImg
                        }
                        alt="bookmaker"
                      />
                    </Box>
                    <Box className="top-bookmaker-right">
                      <Box className="top-bookmaker-right-details-wrap">
                        <Typography className="top-bookmaker-description">
                          {" "}
                          {item?.featured_content}{" "}
                        </Typography>

                        <a
                          href={item?.affiliate_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="claim-btn"
                        >
                          claim now
                        </a>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}
          <Box className="gabling-wrap">
            <Typography className="gabling-header">
              what are you really gambling with?
            </Typography>
            <Box className="gabling-discription">
              For free and confidential support call{" "}
              <a
                href="tel: 1800 858 858 "
                className="responsible-gambling-helpline-no"
              >
                1800 858 858
              </a>{" "}
              or visit{" "}
              <a
                href="mailto:gamblinghelponline.org.au"
                className="responsible-gambling-logo"
              >
                gamblinghelponline.org.au
              </a>
            </Box>
          </Box>

          <Typography variant="h4" className="news-heading bookmakers-heading">
            Recommended Bookmakers
            {/* <span className="right-arrow">
              <RightArrow />
            </span> */}
          </Typography>
          {isLoading && (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          )}
          {!isLoading && recommendedBookmakersData?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Data Available</p>
          )}
          {!isLoading && recommendedBookmakersData?.length > 0 && (
            <Box className="top-bookmaker-wrap">
              {/* <Typography className="top-bookmaker-heading">
                TOP BOOKMAKER
              </Typography> */}

              {recommendedBookmakersData?.map((item, index) => {
                return (
                  <Box
                    className="top-bookmaker-detail-wrap  cursor-pointer"
                    onClick={() => handleFeaturedBookmakersClick(item)}
                    key={index}
                  >
                    <Box className="top-bookmaker-left">
                      <img
                        src={
                          item?.featured_logo
                            ? Config.mediaURL + item?.featured_logo
                            : DefaultImg
                        }
                        alt="bookmaker"
                      />
                    </Box>
                    <Box className="top-bookmaker-right">
                      <Box className="top-bookmaker-right-details-wrap">
                        <Typography className="top-bookmaker-description">
                          {" "}
                          {item?.featured_content}{" "}
                        </Typography>

                        <a
                          href={item?.affiliate_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="claim-btn"
                        >
                          claim now
                        </a>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}

          {/* Top Stories */}
          {/* <Typography
            variant="h4"
            className="news-heading"
            onClick={() => navigate(`/news/0`)}
          >
            Top Stories
            <span className="right-arrow">
              <RightArrow />
            </span>
          </Typography>
          {isTopStoryLoading && (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          )}
          {!isTopStoryLoading && TopStoryData?.length === 0 && (
            <p>No Data Available</p>
          )}
          {!isTopStoryLoading &&
            TopStoryData?.length > 0 &&
            TopStoryData?.slice(0, 8)?.map((item, index) => {
              const renderImage = item?.NewsArticle?.mainMedia?.gallery
                ? item?.NewsArticle?.mainMedia?.gallery?.url !== ""
                  ? item?.NewsArticle?.mainMedia?.gallery?.url
                  : DefaultImg
                : item?.NewsArticle?.mainMedia?.[0]?.gallery
                ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url !== ""
                  ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url
                  : DefaultImg
                : DefaultImg;
              const renderImageAlt = item?.NewsArticle?.mainMedia?.gallery
                ? item?.NewsArticle?.mainMedia?.gallery?.alt
                : item?.NewsArticle?.mainMedia?.[0]?.gallery
                ? item?.NewsArticle?.mainMedia?.[0]?.gallery.alt
                : "";
              return (
                <React.Fragment key={index}>
                  <Box
                    className="news-details"
                    // style={{
                    //   backgroundImage: `url(${item?.NewsArticle?.mainMedia?.gallery?.url})`,
                    // }}
                    onClick={() =>
                      navigate(
                        `/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticleId}`
                      )
                    }
                  >
                    <Box className="news-img">
                      <img src={renderImage} alt={renderImageAlt} />
                    </Box>
                    <Box className="news-text">
                      <Link to="#">{item?.NewsArticle?.title}</Link>
                    </Box>
                  </Box>
                </React.Fragment>
              );
            })} */}
          {/* <a href="https://www.youtube.com/@smartbapp/videos" target="_blank">
            <Typography
              variant="h4"
              className="news-heading"
            // onClick={() => navigate(`/news/0`)}
            >
              Featured Videos
              <span className="right-arrow">
                <RightArrow />
              </span>
            </Typography>
          </a> */}
          {/* {featuredVideoData?.map((item) => {
            return (
              <>
                <Box className="news-details">
                  <Box
                    className="news-img"
                    onClick={() => handleOpenModal(item?.videoLink)}
                  >
                    <iframe
                      width="192"
                      height="114"
                      src={`${item?.videoLink}&showinfo=0&controls=0&modestbranding=1`}
                      title="YouTube video player"
                      frameborder="0"
                      allowfullscreen
                      style={{ pointerEvents: "none" }}
                    ></iframe>
                  </Box>
                </Box>
              </>
            );
          })} */}
        </Box>
      ) : (
        <></>
      )}

      {/* <DialogBox
        open={open}
        onClose={handleClose}
        handleClose={handleClose}
        title={false}
        className="featured-video-modal"
        content={
          <>
            <Box className="video-secton">
              <iframe
                width="560"
                height="315"
                src={videoURL}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </Box>
          </>
        }
        closeIcon={true}
      /> */}
    </>
  );
}
