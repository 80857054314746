import React from "react";
import Page from "src/components/Page";
import SportExpertTipsDetailsPage from "src/views/component/SportExpertTips/SportExpertTipsDetails";

const SportExpertDetailsTips = () => {
  return (
    <Page title="Sports Expert Tips Details">
      <SportExpertTipsDetailsPage />
    </Page>
  );
};

export default SportExpertDetailsTips;
