import React from "react";
import Page from "src/components/Page";
import TipperProfilePage from "src/views/component/TipperProfile";

const TipperProfile = () => {
  return (
    <Page title="Tipper Profile">
      <TipperProfilePage />
    </Page>
  );
};

export default TipperProfile;
