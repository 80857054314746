import React from "react";
import { Box } from "@mui/material";
import Page from "src/components/Page";
import RunnersTablePage from "src/views/component/allsports/racing/runnerTable/runnerTablePage";

const RunnersTablePageView = () => {
  return (
    <Page title="Racing">
      <Box className="content-wrap">
        <RunnersTablePage />
      </Box>
    </Page>
  );
};

export default RunnersTablePageView;
