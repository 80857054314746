import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";

const AdvertisingBannner = ({ src }) => {
  return (
    <a href="mailto:info@smartb.com.au">
      <img className="smart-b-banner" src={src} alt="banner" />
    </a>
  );
};

export default AdvertisingBannner;
