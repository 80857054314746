import React from "react";
import Page from "src/components/Page";
import MyCompsPage from "src/views/component/myComps";

const MyComp = () => {
  return (
    <Page title="My Competitions">
      <MyCompsPage />
    </Page>
  );
};

export default MyComp;
