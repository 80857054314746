import React from "react";
import { Box } from "@mui/material";
import Page from "src/components/Page";
import Racing from "src/views/component/allsports/racing/racing";

const RacingView = () => {
  return (
    <Page
      title="SmartB | Check All The Latest Racing Information Here"
      description="SmartB is an application that will help you keep track of statistics and profiles of racing driver. Check out this page here for updated information."
    >
      <Box className="content-wrap">
        <Racing />
      </Box>
    </Page>
  );
};

export default RacingView;
