import React, { useState } from "react";
import {
  Box,
  Typography,
  TableRow,
  TableHead,
  Table,
  Button,
  TableCell,
  TableBody
} from "@mui/material";
import { ReactComponent as PreviousIcon } from "src/assets/images/eventcalendar/previous.svg";
import { ReactComponent as NextIcon } from "src/assets/images/eventcalendar/next.svg";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import Pagination from '@mui/material/Pagination';
import moment from "moment-timezone";
import "./eventdatetime.scss";
import NoDataComp from "../../UI/NoData";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const EventData = ({
  eventData,
  fetchEventData,
  searchValue,
  eventListData,
  limit,
  currentPage,
  setCurrentPage,
  selectedStartDate,
  selectedTeams,
  selectedVenues,
  selectedOrg,
  selectedEndDate,
  selectedSports,
  previousEvents,
  nextEvents,
  eventLoader
}) => {
  const navigate = useNavigate();
  const params = useParams();
  // for custom pagination
  //   const [totalRecord, setTotalRecords] = useState(50);
  //   const [limit] = useState(5);
  // const [tabledata, setTabledata] = useState([
  //   {},
  //   {},
  //   {},
  //   {},
  //   {},
  //   {},
  //   {},
  //   {},
  //   {},
  //   {}
  // ]);
  // for custom pagination
  //   const handlePaginationButtonClick = async navDirection => {
  //     if (navDirection === "prev") {
  //       if (currentPage > 1) {
  //         setCurrentPage(currentPage - 1);
  //         // getAllData(currentPage - 1); /// pagination api
  //       }
  //     } else if (currentPage < Math.ceil(totalRecord / limit)) {
  //       setCurrentPage(currentPage + 1);
  //       //   getAllData(currentPage + 1); /// pagination api
  //     }
  //   };
  //   const handlePaginationClick = async (event, page) => {
  //     if (currentPage !== page) {
  //       setCurrentPage(Number(page));
  //       //   getAllData(Number(page));/// pagination api
  //     }
  //   };
  const handlePaginationClick = (event, page) => {
    setCurrentPage(Number(page) - 1);
    fetchEventData(
      Number(page) - 1,
      searchValue,
      selectedTeams,
      selectedVenues,
      selectedOrg,
      selectedStartDate,
      selectedEndDate,
      selectedSports
    );
    // setoffset((Number(page) - 1) * rowPerPage);
    // fetchNewsCategoryData(
    //   params?.categoryId === "100" ? 0 : params?.categoryId,
    //   (Number(page) - 1) * rowPerPage,
    //   searchValue
    // );
  };
  return (
    <Box className="event-date-time">
      <Box className="event-date-header">
        {selectedStartDate !== "Invalid date" &&
          selectedStartDate !== null &&
          selectedEndDate !== "Invalid date" &&
          selectedEndDate !== null && (
            <Typography className="date-text">
              {moment().format("DD/MM/YYYY") ===
              moment(selectedStartDate && selectedStartDate).format(
                "DD/MM/YYYY"
              )
                ? "Today"
                : ""}{" "}
              {moment(selectedStartDate && selectedStartDate).format(
                "DD/MM/YYYY"
              ) +
                "-" +
                moment(selectedEndDate && selectedEndDate).format("DD/MM/YYYY")}
            </Typography>
          )}
        <Box className="button-section">
          <Button
            startIcon={<PreviousIcon />}
            className="previous-button"
            onClick={previousEvents}
          >
            Previous Events
          </Button>
          <Button
            endIcon={<NextIcon />}
            className="next-button"
            onClick={nextEvents}
          >
            Next Events
          </Button>
        </Box>
      </Box>
      {eventLoader ? (
        <Box style={{ textAlign: "center" }}>
          <Loader />
        </Box>
      ) : eventData && eventData?.length > 0 ? (
        <>
          <Box className="event-calendar-table-design">
            <Table aria-label="simple table" className="event-table">
              <TableHead className="event-table-head">
                <TableRow className="event-table-row">
                  <TableCell className="event-table-cell">Event</TableCell>
                  <TableCell className="event-table-cell">Date/time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="event-table-body">
                {eventData &&
                  eventData?.length > 0 &&
                  eventData?.map(item => {
                    return (
                      <TableRow className="event-table-row">
                        <TableCell className="event-table-cell">
                          <Typography
                            className="cell-text cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/${params?.sportType}/event-calendar/details/${item?.eventId}/${item?.sportId}/${item?.tournamentId}`
                              )
                            }
                          >
                            {item?.eventName}
                          </Typography>
                          {/* <Typography className="cell-text">
                            Melbourne
                          </Typography> */}
                          {item?.venues ? (
                            <Typography className="cell-text">
                              {"Venue -" + item?.venues}
                            </Typography>
                          ) : (
                            <Typography className="cell-text"></Typography>
                          )}
                        </TableCell>
                        <TableCell className="event-table-cell">
                          {item?.eventDate && (
                            <Typography className="cell-text">
                              {moment(item?.eventDate)
                                .tz(timezone)
                                .format("MMMM DD YYYY, h:mm:ss a")}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
          <Box className="news-Pagination">
            {/* // for custom pagination 
        <CustomPagination
          paginationArray={eventData}
          currentPage={currentPage}
          list={limit}
          handlePaginationButtonClick={handlePaginationButtonClick}
          handlePaginationClick={handlePaginationClick}
          totalRecord={totalRecord}
        /> */}
            <Box className="news-Pagination">
              <Pagination
                // hideNextButton
                // hidePrevButton
                // disabled={
                //   eventListData && eventListData?.count / limit > 1
                //     ? false
                //     : true
                // }
                disabled={
                  eventListData && eventListData?.count / limit > 1
                    ? false
                    : true
                }
                page={currentPage + 1}
                onChange={(e, value) => handlePaginationClick(e, value)}
                count={
                  eventListData &&
                  eventListData?.count &&
                  Math.ceil(eventListData?.count / limit)
                }
                siblingCount={2}
                boundaryCount={1}
                // count={10}
                size="small"
              />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box style={{ textAlign: "center" }}>
            {" "}
            <Typography className="no-data-available">
              {/* No Data Available */}
              <NoDataComp />
            </Typography>{" "}
          </Box>
        </>
      )}
    </Box>
  );
};

export default EventData;
