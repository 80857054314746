import React from "react";
import Page from "src/components/Page";
import InternationalRacingPage from "src/views/component/internationalRacingPage";

const InternationalRacing = () => {
  return (
    <>
      <Page title="InternationalRacing">
        <InternationalRacingPage />
      </Page>
    </>
  );
};

export default InternationalRacing;
