import React from "react";
import Page from "src/components/Page";
import TeamSports from "src/views/component/allsports/teamSpoorts/teamSports";

const TeamSportsPage = () => {
  return (
    <Page title="Team Sports">
      <TeamSports />
    </Page>
  );
};

export default TeamSportsPage;
