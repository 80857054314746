import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, Tab, Tabs } from "@mui/material";
import { ReactComponent as Bat } from "../../../../assets/images/SportIcon/bat.svg";
import { ReactComponent as Ball } from "../../../../assets/images/SportIcon/ball.svg";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "../../../../components/Loader";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { IntlContext } from "src/App";
import NoDataComp from "../../UI/NoData";
import SoccerCard from "./soccerCard";

const summaryTabData = [
  {
    id: 1,
    name: "Fixtures"
  },
  {
    id: 2,
    name: "Results"
  }
];

const MatchDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [tabvalue, setTabValue] = useState(1);
  const [matchData, setMatchData] = useState([]);
  const [matchLoading, setMatchLoading] = useState(false);
  const [matchCount, setMatchCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const rowPerPage = 20;
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    fetchMatchData(0);
  }, [params, tabvalue]);

  const fetchMatchData = async offset => {
    setMatchLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/soccer/event/tournament/${
          params?.tournamentId
        }?type=${
          tabvalue === 1 ? "fixtures" : "result"
        }&limit=${rowPerPage}&offset=${offset}`
      );
      if (status === 200) {
        let count = data?.result?.count / 20;
        setMatchCount(Math.ceil(count));
        setMatchData(data?.result?.result);
        setMatchLoading(false);
      } else {
        setMatchLoading(false);
      }
    } catch (err) {
      setMatchLoading(false);
    }
  };

  const handleOnScrollBottomMatch = async offset => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/soccer/event/tournament/${
          params?.tournamentId
        }?type=${
          tabvalue === 1 ? "fixtures" : "result"
        }&limit=${rowPerPage}&offset=${offset}`
      );
      if (status === 200) {
        setOffset(offset);
        setMatchData([...matchData, ...data?.result?.result]);
      } else {
      }
    } catch (err) {}
  };

  // const handleNavigate = data => {
  //   const eventSlug = data?.eventName
  //     ? data?.eventName
  //         .trim()
  //         .toLowerCase()
  //         .replaceAll(" ", "-")
  //     : "event";
  //   navigate(
  //     `/teamsports/cricket/${
  //       data?.CricketTournament?.Scd
  //         ? data?.CricketTournament?.Scd.trim()
  //             .toLowerCase()
  //             .replaceAll(" ", "-")
  //         : "tournament"
  //     }/${eventSlug}/info/${data?.SportId}/${data?.CricketTournamentId}/${
  //       data?.id
  //     }`
  //   );
  // };

  return (<>
    <Box className="match-wrap">
      <Tabs
        value={tabvalue}
        variant="scrollable"
        scrollButtons={false}
        indicatorColor="primary"
        textColor="primary"
        className="soccer-details-tab"
      >
        {summaryTabData?.map((item, index) => {
          return (
            <Box key={index}>
              <Tab
                label={
                  <Box className="d-flex align-center match-tab">
                    <span>{item?.name}</span>
                  </Box>
                }
                value={item?.id}
                className={item?.id === tabvalue ? "active " : ""}
                onChange={(event, newValue) =>
                  handleTabChange(event, item?.id)
                }
              />
            </Box>
          );
        })}
      </Tabs>
      {/* <Box className="mt-18">
        <SoccerCard theme="light-theme" />
      </Box> */}
      {tabvalue === 1 ? (
        matchLoading ? (
          <div className="allsport-loader-center mt-18">
            <Loader />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={matchData?.length}
            next={() => handleOnScrollBottomMatch(offset + 20)}
            hasMore={
              matchCount !== 0 && matchCount !== Math.ceil(offset / 20 + 1)
            }
            loader={
              <div className="allsport-loader-center ">
                <Loader />
              </div>
            }
          >
            <Box className="mt-18">
              {matchData?.length > 0 ? (
                matchData?.map((data, index) => {
                  return (
                    <>
                      <Box className="mt-18 plr-5">
                        <SoccerCard
                          theme="light-theme"
                          type={
                            data?.ScoreBoard?.Epr == 0
                              ? "upcoming"
                              : data?.ScoreBoard?.Epr == 2
                              ? "past"
                              : data?.ScoreBoard?.Epr == 1
                              ? "live"
                              : "upcoming"
                          }
                          cardData={data}
                          index={index}
                        />
                      </Box>
                    </>
                  );
                })
              ) : (
                <>
                  <Box className="no-match no-match-boxshadow">
                    {localesData?.NO_DATA?.NO_FIXTURES}
                  </Box>
                  {/* <NoDataComp /> */}
                </>
              )}
            </Box>
          </InfiniteScroll>
        )
      ) : matchLoading ? (
        <div className="allsport-loader-center mt-18">
          <Loader />
        </div>
      ) : (
        <InfiniteScroll
          dataLength={matchData?.length}
          next={() => handleOnScrollBottomMatch(offset + 20)}
          hasMore={
            matchCount !== 0 && matchCount !== Math.ceil(offset / 20 + 1)
          }
          loader={
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          }
        >
          <Box className="mt-18">
            {matchData?.length > 0 ? (
              matchData?.map((data, index) => {
                return (
                  <>
                    <Box className="mt-18 plr-5">
                      <SoccerCard
                        theme="light-theme"
                        type={
                          data?.ScoreBoard?.Epr == 0
                            ? "upcoming"
                            : data?.ScoreBoard?.Epr == 2
                            ? "past"
                            : data?.ScoreBoard?.Epr == 1
                            ? "live"
                            : "upcoming"
                        }
                        cardData={data}
                        index={index}
                      />
                    </Box>
                  </>
                );
              })
            ) : (
              <>
                <Box className="no-match no-match-boxshadow">
                  {" "}
                  {localesData?.NO_DATA?.NO_RESULTS}
                </Box>
                {/* <NoDataComp /> */}
              </>
            )}
          </Box>
        </InfiniteScroll>
      )}
    </Box>
  </>);
};

export default MatchDetails;
