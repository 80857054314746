import React from "react";
import {
  Box,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "../../../helpers/context/config";
import Tooltip from "@mui/material/Tooltip";
import Pagination from "@mui/material/Pagination";
import { ReactComponent as Delete } from "src/assets/images/icons/delete.svg";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import { ReactComponent as EditIcon } from "src/assets/images/icons/editIcon.svg";
import moment from "moment";
import TrackRaceResult from "../allsports/racing/racingList/trackRaceResult";
import TrackListCountdown from "../allsports/racing/racingList/trackListCountdown";
import OurPartner from "../ourPartners";
import Loader from "src/components/Loader";
import { useState } from "react";
import { useEffect } from "react";
import DialogBox from "../UI/dialogBox";
import { ToastContainer, toast } from "react-toastify";
import BlackBookModal from "../UI/blackBookModal";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NoDataComp from "../UI/NoData";
import { Routes } from "../../../helpers/constants/routeConstants";
import RaceOdds from "../raceOdds/RaceOdds";
import { useDispatch, useSelector } from "react-redux";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import DefaultImg from "src/assets/images/smartb_default.png";

const UpcomingRunnerV2 = ({
  upcomingData,
  isUpcomingLoading,
  fetchUpcomingBlackBook,
  countryId,
  stateId,
  handlePaginationClick,
  upcomingCount,
  upcomingPage,
  pageNumbers,
  isToggle,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxBookkeeperData = useSelector(
    (state) => state?.reduxData?.BookkeeperData,
  );
  const BetslipData = useSelector((state) => state?.reduxData?.BetSlipData);
  const localAuth = fetchFromStorage("auth_token");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [editComment, setEditComment] = useState("");
  const [open, setOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [raceMenuCollapse, setRaceMenuCollapse] = useState(-1);
  const fetchDayName = (date) => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };
  const handleTracklist = (ele) => {
    let data = ele?.race;
    let a = data.map((a) => a.raceNumber) || [];

    for (var i = 1; i <= 12; i++) {
      if (a.indexOf(i) == -1) {
        let dummy_ele = { startDate: null, raceNumber: i };
        data.push(dummy_ele);
      }
    }

    ele["race"] = data
      .sort((a, b) => {
        return a?.raceNumber - b?.raceNumber;
      })
      ?.filter((race) => race?.raceNumber !== 0);
    const track = ele?.race?.slice(0, 12)?.map((obj, i) => {
      if (
        obj?.startDate !== null &&
        moment(new Date(obj?.startDate)).isBefore(new Date())
      ) {
        return (
          <TrackRaceResult // if race is close
            race={ele}
            race_obj={obj}
            key={i}
            intl={
              ele?.countryId === 157 || ele?.countryId === 13 ? false : true
            }
            selectedDate={moment(obj?.startDate).format("YYYY-MM-DD")}
            // raceData={
            //   item?.RaceData
            //     ?.intlData
            // }
            isMobile={false}
            isBlackBook={true}
          />
        );
      } else {
        return (
          <TrackListCountdown // if race is upcoming
            race={ele}
            race_obj={obj}
            key={i}
            intl={
              ele?.countryId === 157 || ele?.countryId === 13 ? false : true
            }
            selectedDate={moment(obj?.startDate).format("YYYY-MM-DD")}
            // raceData={
            //   item?.RaceData
            //     ?.intlData
            // }
            expiryTimestamp={
              obj?.startDate !== null
                ? new Date(new Date(obj?.startDate).toUTCString()).getTime()
                : new Date().getTime()
            }
            checkRaceMinCell={checkRaceMinCell}
            isMobile={false}
            isBlackBook={true}
          />
        );
      }
    });
    return track;
  };
  const fetchNewMobileViewRacingData = (ele, intl, index) => {
    let haveRace = ele?.race
      ?.filter((data) => data?.startDate !== null)
      ?.filter((race) => race?.raceNumber !== 0);
    let upnextRaces = ele?.race
      ?.filter(
        (item) =>
          item?.startDate !== null &&
          moment(new Date()).isBefore(new Date(item?.startDate)),
      )
      ?.filter((race) => race?.raceNumber !== 0);
    let completedRaces = ele?.race
      ?.filter(
        (item) =>
          item?.startDate !== null &&
          moment(new Date(item?.startDate)).isBefore(new Date()),
      )
      ?.filter((race) => race?.raceNumber !== 0);
    return (
      <Box className="Mobile-racing-data">
        <React.Fragment>
          <Box
            className={`${
              raceMenuCollapse === index ? "racing-menu-collapse" : ""
            } racing-data-row`}
          >
            <Box
            // onClick={
            //   upnextRaces?.length > 0
            //     ? () => {
            //       navigate(
            //         // Redirect to Runner Details
            //         Routes.RunnerDetails(
            //           race?.sportId === 1
            //             ? "horse"
            //             : race?.sportId === 2
            //               ? "harness"
            //               : "greyhounds",
            //           race?.sportId,
            //           upnextRaces[0].id,
            //           race?.track?.id,
            //           race?.id,
            //           selectedDate,
            //           intl
            //         ),
            //         {
            //           state: {
            //             raceData: data,
            //             CurrentData: race,
            //             SelectedRaceid: haveRace[0].id,
            //           },
            //         }
            //       );
            //     }
            //     : haveRace[0]?.raceNumber !== undefined
            //       ? () => {
            //         navigate(
            //           Routes.RunnerDetails(
            //             race?.sportId === 1
            //               ? "horse"
            //               : race?.sportId === 2
            //                 ? "harness"
            //                 : "greyhounds",
            //             race?.sportId,
            //             haveRace[0].id,
            //             race?.track?.id,
            //             race?.id,
            //             selectedDate,
            //             intl
            //           ),
            //           {
            //             state: {
            //               raceData: data,
            //               CurrentData: race,
            //               SelectedRaceid: haveRace[0].id,
            //             },
            //           }
            //         );
            //       }
            //       : () => { }
            // }
            >
              <Typography className="track-title" variant="subtitle1">
                <Box className="track-country-wrap">
                  <span
                    className="track-name"
                    onClick={() => handleNavigate(ele)}
                  >
                    {ele?.trackName}
                  </span>
                  <span className="track-code">
                    {" "}
                    {ele?.countryCode ? `( ${ele?.countryCode}  )` : ""}
                  </span>
                </Box>
                <Box className="track-country"></Box>
              </Typography>
            </Box>
            <Box className="upcoming-list">
              {upnextRaces?.length > 0 ? (
                <Box className="collapse-menu">
                  {fetchMobileMenuTrackList(
                    [],
                    ele,
                    upnextRaces?.[0],
                    upnextRaces?.[0]?.raceNumber,
                    intl,
                  )}
                  <span className={`race-tag race-tag-upcoming`}>
                    Race {upnextRaces?.[0]?.raceNumber}
                  </span>
                </Box>
              ) : (
                <></>
              )}
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  return setRaceMenuCollapse(
                    raceMenuCollapse === index ? -1 : index,
                  );
                  // setDropDownSportId(race?.sportId),
                  // setIsIntl(intl)
                }}
              >
                {raceMenuCollapse === index ? (
                  // &&
                  //   dropDownSportId === race?.sportId &&
                  //   isintl === intl
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </Box>
          </Box>
          <Collapse
            in={raceMenuCollapse === index}
            timeout="auto"
            unmountOnExit
            className={`${
              raceMenuCollapse !== index ? "" : "collapse-section"
            }`}
          >
            {upnextRaces?.length > 0 ? (
              upnextRaces?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Box
                      className={`collapse-menu collapse-td ${fetchFixedOdds(
                        item,
                      )}`}
                    >
                      {fetchMobileMenuTrackList(
                        [],
                        ele,
                        item,
                        item?.raceNumber,
                        intl,
                      )}
                      <span
                        // className={
                        //   index === 0
                        //     ? `race-tag race-tag-close`
                        //     : `race-tag race-tag-secondary-close`
                        // }
                        className={`race-tag ${checkRaceMinCell(
                          item?.startDate,
                          "mobile",
                        )}`}
                      >
                        Race {item?.raceNumber}
                      </span>
                    </Box>
                  </React.Fragment>
                );
              })
            ) : (
              <></>
            )}
            {completedRaces?.length > 0 ? (
              completedRaces?.map((item, index) => {
                return (
                  <Box
                    className={`collapse-menu collapse-td ${fetchFixedOdds(
                      item,
                    )}`}
                    key={index}
                  >
                    {fetchMobileMenuTrackList(
                      [],
                      ele,
                      item,
                      item?.raceNumber,
                      intl,
                    )}
                    <span
                      className={`race-tag ${
                        item?.resultDisplay == null
                          ? "race-tag-interim"
                          : "race-tag-result"
                      }`}
                    >
                      Race {item?.raceNumber}
                    </span>
                  </Box>
                );
              })
            ) : (
              <></>
            )}
          </Collapse>
        </React.Fragment>
      </Box>
    );
  };
  const fetchFixedOdds = (race_obj) => {
    if (race_obj?.id) {
      if (race_obj?.isOdd) {
        return "fixed";
      } else {
        return "";
      }
    }
  };
  const fetchMobileMenuTrackList = (data, race, obj, racetrack, intl) => {
    if (
      obj?.startDate !== null &&
      moment(new Date(obj?.startDate)).isBefore(new Date())
    ) {
      return (
        <TrackRaceResult // if race is close
          race={race}
          race_obj={obj}
          selectedDate={moment(obj?.startDate).format("YYYY-MM-DD")}
          key={racetrack}
          raceData={data}
          intl={intl}
          isMobile={false}
          isBlackBook={true}
        />
      );
    } else {
      return (
        <TrackListCountdown // if race is upcoming
          race={race}
          race_obj={obj}
          selectedDate={moment(obj?.startDate).format("YYYY-MM-DD")}
          key={racetrack}
          intl={intl}
          raceData={data}
          expiryTimestamp={
            obj?.startDate !== null
              ? new Date(new Date(obj?.startDate).toUTCString()).getTime()
              : new Date().getTime()
          }
          checkRaceMinCell={checkRaceMinCell}
          isMobile={false}
          isBlackBook={true}
        />
      );
    }
  };
  const checkRaceMinCell = (time, type) => {
    let current_time_string = moment().format("YYYY/MM/DD HH:mm:ss");
    let end_time_string = moment(time).format("YYYY/MM/DD HH:mm:ss");
    let diff_sec = moment(end_time_string).diff(current_time_string, "second");
    let class_to_pass = "";
    if (type === "desktop") {
      class_to_pass = !isNaN(diff_sec)
        ? // ? diff_sec > 1800
          //   ? "interim"
          diff_sec <= 1800 && diff_sec > 300
          ? "close-secondary"
          : diff_sec <= 300 && diff_sec > 0
            ? "close"
            : diff_sec < 0
              ? "upcoming_race_cell_close"
              : "white"
        : "";
    } else {
      class_to_pass = !isNaN(diff_sec)
        ? diff_sec <= 1800 && diff_sec > 300
          ? "race-tag-close"
          : diff_sec <= 300 && diff_sec > 0
            ? "race-tag-green"
            : diff_sec < 0
              ? "race-tag-interim"
              : "race-tag-secondary-close"
        : "";
    }
    return class_to_pass;
  };

  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip,
    oddsType,
  ) => {
    const BookKeeperData = item?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId,
    );
    let icon = reduxBookkeeperData?.filter((obj) => obj?.id === BookKeeperId);
    let iconData = icon?.[0];

    let isAdded = BetslipData?.some((el) => {
      if (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId ==
          BookKeeperData?.[0]?.RaceOdds?.[0]?.MarketRelationId &&
        el?.eventId == Number(fulldata?.raceId)
      )
        return true;
      else return false;
    });
    // if (BookKeeperId === 8) {
    //   const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8)

    //   const Odds = Bet365Data?.[0]?.RaceOdds?.[0]
    //   return (
    //     Bet365Data && Bet365Data?.length > 0 ?
    //       (<a href={Odds?.providerMarketId && Odds?.providerParticipantId ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId
    //         }~${Odds?.intValue}~1&bet=1` : `${iconData?.affiliate_link}`} target="_blank" rel="noopener noreferrer" className="odds-link" >  {odds === "SP" ? "SP" : Number(odds).toFixed(2)} </a>
    //       ) : (<></>)
    //   )
    // } else {

    return (
      // <a
      //   href={iconData?.affiliate_link}
      //   target="_blank"
      //   rel="noopener noreferrer"
      //   className="odds-link"
      // >
      // </a>
      oddsType == "currentbest" ? (
        <Box
          className={`current-best-odds-value ${
            isAdded ? "active-odd-style" : ""
          }`}
        >
          {localAuth ? (
            <span
              className={
                isAdded && IsBetslip === "betslip"
                  ? "betslip-added odds-link"
                  : "odds-link"
              }
              onClick={() => {
                IsBetslip === "nobetslip"
                  ? handleBookkeeperCounter(BookKeeperId, type)
                  : isAdded
                    ? handleDeleteBetslip(BookKeeperId, item, fulldata)
                    : handleAddToBetSlip(BookKeeperId, type, item, fulldata);
              }}
            >
              {IsBetslip === "betslip" ? (
                <Tooltip title="Bet" className="bet-tooltip" placement="top">
                  <span className="bet-now">
                    {" "}
                    {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
                  </span>
                </Tooltip>
              ) : (
                <>{odds === "SP" ? "SP" : Number(odds).toFixed(2)}</>
              )}
            </span>
          ) : (
            <span></span>
          )}
        </Box>
      ) : (
        <Box
          className={`odds-style  ${isAdded ? "active-odd-style" : ""}`}
          onClick={() => {
            IsBetslip === "nobetslip"
              ? handleBookkeeperCounter(BookKeeperId, type)
              : isAdded
                ? handleDeleteBetslip(BookKeeperId, item, fulldata)
                : handleAddToBetSlip(BookKeeperId, type, item, fulldata);
          }}
        >
          {IsBetslip === "betslip" ? (
            <Tooltip title="Bet" className="bet-tooltip" placement="top">
              <Typography className="odds bet-now">
                {" "}
                {odds === "SP" ? "SP" : Number(odds).toFixed(2)}
              </Typography>
            </Tooltip>
          ) : (
            <>{odds === "SP" ? "SP" : Number(odds).toFixed(2)}</>
          )}
        </Box>
      )
    );
    // }
  };
  const handleAddToBetSlip = async (BookKeeperId, type, item, fulldata) => {
    handleBookkeeperCounter(BookKeeperId, type);
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    if (isLogin) {
      const BookKeeperData = item?.filter(
        (obj) => obj?.BookKeeperId === BookKeeperId,
      );

      let payload = {
        sportId: Number(fulldata?.sportId),
        eventId: Number(fulldata?.raceId),
        teamId: BookKeeperData?.[0]?.RacingParticipantId,
        bookKeeperId: BookKeeperId,
        betOfferId: BookKeeperData?.[0]?.RaceOdds?.[0]?.MarketRelationId,
      };
      try {
        const { status, data } = await axiosInstance.post(
          `betSlipCard/BetSlipCard`,
          payload,
        );
        if (status === 200) {
          toast.success(data?.message, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
          const addedData = [...BetslipData, data?.result];
          dispatch(fetchBetSlipData(addedData));
          dispatch(fetchBetSlipCount(addedData?.length));
        } else {
        }
      } catch (err) {
        console.log("error", err);
      }
    }
  };
  const handleDeleteBetslip = async (BookKeeperId, item, fulldata) => {
    const BookKeeperData = item?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId,
    );
    let delslip = BetslipData?.filter((el) => {
      return (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId ==
          BookKeeperData?.[0]?.RaceOdds?.[0]?.MarketRelationId &&
        el?.eventId == Number(fulldata?.raceId)
      );
    })?.[0];

    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?id=${delslip?.id}`,
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        let chageData = BetslipData?.filter((obj) => obj?.id !== delslip?.id);

        dispatch(fetchBetSlipData(chageData));
        dispatch(fetchBetSlipCount(chageData?.length));
        // setBetSlipData(chageData);
      } else {
      }
    } catch (err) {}
  };
  const handleBookkeeperCounter = async (BookKeeperId, type) => {
    let sportId = 1;
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(sportId),
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload,
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const oddsicon = (BookKeeperId, type, item, oddsType) => {
    const filteredData = reduxBookkeeperData?.filter?.(
      (obj) => obj?.id === BookKeeperId,
    );
    let icon = reduxBookkeeperData?.filter((obj) => obj?.id === BookKeeperId);
    let iconData = icon?.[0];
    let imageUrl =
      oddsType == "currentbest"
        ? iconData?.currentBest_logo
        : iconData?.long_logo;
    if (item && BookKeeperId === 8) {
      const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.[0]?.RaceOdds?.[0];
      return Bet365Data && Bet365Data?.length > 0 ? (
        <a
          href={
            Odds?.providerMarketId && Odds?.providerParticipantId
              ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId}~${Odds?.intValue}~1&bet=1`
              : `${iconData?.affiliate_link}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link"
        >
          {oddsType == "currentbest" ? (
            <img
              className="currentbest-bookmaker-thumb"
              src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
              alt="Odds Icon"
              onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
            />
          ) : (
            <Box className="odd-img">
              <img
                src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                alt="Odds Icon"
                onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
              />
            </Box>
          )}
        </a>
      ) : (
        <></>
      );
    } else if (BookKeeperId == 13) {
      const fetchUrlArray = filteredData?.[0]?.url?.split("/");
      const fetchMarketId = fetchUrlArray?.[fetchUrlArray?.length - 1];
      const isMobile = () => {
        const userAgent = navigator.userAgent;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent,
        );
      };
      const betFairUrl = isMobile()
        ? filteredData?.[0]?.url
          ? filteredData?.[0]?.url + `?Referrer=SmartB`
          : iconData?.affiliate_link
        : filteredData?.[0]?.url
          ? `https://www.betfair.com.au/exchange/plus/${
              item?.sportId == 3 ? "greyhound-racing" : "horse-racing"
            }/market/${fetchMarketId}`
          : iconData?.affiliate_link;
      return (
        <Box className="current-best-odds-icon">
          <a href={betFairUrl} target="_blank" rel="noopener noreferrer">
            {oddsType == "currentbest" ? (
              <img
                className="currentbest-bookmaker-thumb"
                src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                alt="Odds Icon"
                onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
              />
            ) : (
              <Box className="odd-img">
                <img
                  src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                  alt="Odds Icon"
                  onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
                />
              </Box>
            )}
          </a>
        </Box>
      );
    } else {
      return (
        <Box className="current-best-odds-icon">
          <a
            href={
              localAuth
                ? filteredData?.length > 0 && filteredData?.[0]?.url
                  ? filteredData?.[0]?.url + `?Referrer=SmartB`
                  : iconData?.affiliate_link
                : "#"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {oddsType == "currentbest" ? (
              <img
                className="currentbest-bookmaker-thumb"
                src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                alt="Odds Icon"
                onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
              />
            ) : (
              <Box className="odd-img">
                <img
                  src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                  alt="Odds Icon"
                  onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
                />
              </Box>
            )}
          </a>
        </Box>
      );
    }
  };

  const handleDelete = (type, id) => {
    setSelectedType(type);
    setSelectedId(id);
    handleDeleteModal();
  };
  const handleDeleteModal = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setSelectedType("");
    setSelectedId("");
  };
  const handleuserDelete = async (type, id) => {
    try {
      const { status, data } = await axiosInstance.delete(
        `blackbook/favoriteData/${id}?type=${type}`,
      );
      if (status === 200) {
        toast.success("Deleted Successfully", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });

        handleDeleteClose();

        fetchUpcomingBlackBook(countryId, stateId, upcomingPage);
      } else {
      }
    } catch (error) {}
  };
  const handleEdit = async (type, id, item) => {
    setSelectedType(type);
    const typeId =
      type === "Runner"
        ? item?.RunnerId
        : type === "Jockey"
          ? item?.JockeyId
          : type === "Trainer"
            ? item?.TrainerId
            : type === "Track"
              ? item?.trackId
              : "";
    setTypeId(typeId);
    setSelectedId(id);
    const title =
      type === "Runner"
        ? item?.runnerName
        : type === "Jockey"
          ? item?.jockeyName
          : type === "Trainer"
            ? item?.TrainerName
            : type === "Track"
              ? item?.trackName
              : "";

    const comment = item?.comment;
    setEditComment(comment);
    setSelectedTitle(title);
    handleModal();
  };
  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedType("");
    setSelectedId("");
  };

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  const handleNavigate = (race) => {
    let haveRace = race?.race?.filter((data) => data?.startDate !== null);
    let upnextRaces = race?.race?.filter(
      (item) =>
        item?.startDate !== null &&
        moment(new Date()).isBefore(new Date(item?.startDate)),
    );
    let completedRaces = race?.race?.filter(
      (item) =>
        item?.startDate !== null &&
        moment(new Date(item?.startDate)).isBefore(new Date()),
    );
    const selectedDate = race?.startDate
      ? moment(race?.startDate).format("YYYY-MM-DD")
      : null;
    const intl =
      race?.countryId === 157 || race?.countryId === 13 ? false : true;
    if (upnextRaces?.length > 0) {
      navigate(
        // Redirect to Runner Details
        Routes.RunnerDetails(
          race?.sportId === 1
            ? "horse"
            : race?.sportId === 2
              ? "harness"
              : "greyhounds",
          race?.sportId,
          upnextRaces[0].id,
          race?.trackId,
          race?.eventId,
          selectedDate,
          intl,
        ),
      );
    } else if (haveRace[0]?.raceNumber !== undefined) {
      navigate(
        Routes.RunnerDetails(
          race?.sportId === 1
            ? "horse"
            : race?.sportId === 2
              ? "harness"
              : "greyhounds",
          race?.sportId,
          haveRace[0].id,
          race?.trackId,
          race?.eventId,
          selectedDate,
          intl,
        ),
      );
    } else {
    }
  };
  const handleRunnerNavigate = (item) => {
    const selectedDate = item?.startTimeDate
      ? moment(item?.startTimeDate).format("YYYY-MM-DD")
      : null;
    const intl =
      item?.track?.countryId === 157 || item?.track?.countryId === 13
        ? false
        : true;
    navigate(
      // Redirect to Runner Details
      Routes.RunnerDetails(
        item?.sportId === 1
          ? "horse"
          : item?.sportId === 2
            ? "harness"
            : "greyhounds",
        item?.sportId,
        item?.id,
        item?.trackId,
        item?.eventId,
        selectedDate,
        intl,
      ),
    );
  };

  const fetchFeaturedOdds = (item, BookKeeperId, fulldata) => {
    let datas = item
      ?.map((obj) => {
        if (obj?.BookKeeperId === BookKeeperId) {
          return {
            odds:
              obj?.RaceOdds?.[0]?.intValue !== 0
                ? obj?.RaceOdds?.[0]?.intValue
                : "SP",
            provider: obj?.BookKeeperId,
          };
        }
      })
      .filter((x) => x !== undefined);

    if (datas?.length > 0 && datas?.[0]?.odds !== undefined) {
      return (
        <Box className="odds-box-style">
          {fetchClickableOdds(
            datas?.[0]?.odds,
            BookKeeperId,
            "header",
            item,
            fulldata,
            "betslip",
            "featuredOdds",
          )}
          {oddsicon(BookKeeperId, "header", item, "featuredOdds")}
        </Box>
      );
    } else {
      return null;
    }
  };

  const IsFeaturedData = (item) => {
    return reduxBookkeeperData
      ?.filter((item) => item?.isFeatured === true)
      ?.sort((a, b) => a.featured_order - b.featured_order)
      ?.slice(0, 6)
      ?.map((bookkeper, index) => {
        return fetchFeaturedOdds(item?.MarketRelations, bookkeper?.id, item);
      });
  };
  const handleRunnerInfoNavigate = (item) => {
    navigate(`/statistics/runnerprofiles/0/runner/0/${item?.RunnerId}`);
  };

  const handleJockeyNavigate = (item) => {
    navigate(`/statistics/jockeyprofiles/0/jockey/${item?.JockeyId}`);
  };
  const handleTrainnerNavigate = (item) => {
    navigate(`/statistics/trainerprofiles/0/trainer/${item?.TrainerId}`);
  };

  const handleRaceEventNavigate = (race) => {
    navigate(
      Routes.RunnerDetails(
        race?.sportId === 1
          ? "horse"
          : race?.sportId === 2
            ? "harness"
            : "greyhounds",
        race?.sportId,
        race?.raceId,
        race?.trackId,
        race?.eventId,
        race?.startDate ? moment(race?.startDate).format("YYYY-MM-DD") : null,
        race?.countryId === 13 || race?.countryId === 157 ? false : true,
      ),
    );
  };
  return (
    <>
      <Box className="page-content-wrap-v2">
        {isUpcomingLoading ? (
          <div className="allsport-loader-center ">
            <Loader />
          </div>
        ) : upcomingData?.length > 0 ? (
          upcomingData?.map((item, index) => {
            return (
              <>
                <Box className="blackbook-runner-details" key={index}>
                  <Box className="top-header-details">
                    <Box className="header-details">
                      <Typography variant="h6" className="day-time-details">
                        <span className="dark-color">
                          {item?.startDate ? fetchDayName(item?.startDate) : ""}{" "}
                          {moment
                            .utc(item?.startDate)
                            .local()
                            .format("DD/MM/YYYY")}{" "}
                        </span>
                        |{" "}
                        <span className="dark-color">
                          {item?.startDate
                            ? moment
                                .utc(item?.startDate)
                                .local()
                                .format("hh:mm A")
                            : item?.eventDate
                              ? moment
                                  .utc(item?.eventDate)
                                  .local()
                                  .format("hh:mm A")
                              : ""}{" "}
                        </span>
                        {item?.distance ? "|" : ""}
                        <span className="gray-color">
                          {" "}
                          {item?.distance ? item?.distance + "m" : ""}
                        </span>
                      </Typography>
                    </Box>
                    <Box className="Updated-date">
                      <Typography className="date">
                        Updated:{" "}
                        {moment
                          .utc(item?.updatedAt)
                          .local()
                          .format("DD/MM/YYYY")}
                      </Typography>
                      <Box
                        className="delete-box cursor-pointer"
                        onClick={() => handleDelete(item?.type, item?.id)}
                      >
                        <Delete />
                      </Box>
                    </Box>
                  </Box>

                  {item?.type !== "Track" ? (
                    <>
                      <Box className="runner-details-v2">
                        <Box className="runner-info-v2">
                          <Box
                            className={
                              screenWidth < 939 ? "mobile-runner-data-v2" : ""
                            }
                          >
                            <Box>
                              <Typography
                                className="runner-name cursor-pointer"
                                onClick={() => handleRunnerInfoNavigate(item)}
                              >
                                {item?.runnerNumber}
                                {"."} {item?.runnerName} ({item?.barrierNumber})
                              </Typography>
                              <Box className="info-details">
                                <Box>
                                  <Typography className="runner-info-details mb-5">
                                    {item?.jockeyWeight ? (
                                      <>
                                        <span className="bold">W:</span>
                                        <span>{`${
                                          Number(item?.jockeyWeight).toFixed(
                                            2,
                                          ) + "Kg"
                                        }`}</span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    className="runner-info-details mb-5 color-purple cursor-pointer"
                                    onClick={() => handleJockeyNavigate(item)}
                                  >
                                    {item?.jockeyName
                                      ? `J: ${item?.jockeyName}`
                                      : ""}
                                  </Typography>
                                  <Typography
                                    className="runner-info-details color-purple cursor-pointer"
                                    onClick={() => handleTrainnerNavigate(item)}
                                  >
                                    {item?.TrainerName
                                      ? `T: ${item?.TrainerName} `
                                      : ""}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            {screenWidth < 939 ? (
                              <Box>
                                <RaceOdds
                                  data={item?.MarketRelations}
                                  sportId={item?.sportId}
                                  isResult={false}
                                  raceId={item?.raceId}
                                />
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                          <Box
                            className="race-wrap cursor-pointer"
                            onClick={() => handleRaceEventNavigate(item)}
                          >
                            <Typography className="race-name">
                              {item?.raceNumber ? "R" + item?.raceNumber : ""}{" "}
                              {item?.raceName}
                            </Typography>
                            <Typography className="race-time">
                              {item?.startDate
                                ? moment
                                    .utc(item?.startDate)
                                    .local()
                                    .format("hh:mm A")
                                : ""}{" "}
                              <RightArrow />
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="runner-odds-info-v2">
                          <Table className="odds-table">
                            <TableHead>
                              <TableRow>
                                {screenWidth >= 939 && (
                                  <TableCell className="current-best">
                                    Current Best
                                  </TableCell>
                                )}
                                {!IsFeaturedData(item)?.every(
                                  (ele) => ele == null,
                                ) && (
                                  <TableCell className="featured-odds">
                                    Featured bookmaker Odds
                                  </TableCell>
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                {screenWidth >= 939 && (
                                  <TableCell className="current-best-cell">
                                    <RaceOdds
                                      data={item?.MarketRelations}
                                      sportId={item?.sportId}
                                      isResult={false}
                                      raceId={item?.raceId}
                                    />
                                  </TableCell>
                                )}
                                {!IsFeaturedData(item)?.every(
                                  (ele) => ele == null,
                                ) && (
                                  <TableCell>
                                    {reduxBookkeeperData?.length > 0 && (
                                      <Box className="hide-scroll max-w">
                                        <Box className="odds-flex">
                                          {reduxBookkeeperData
                                            ?.filter(
                                              (item) =>
                                                item?.isFeatured === true,
                                            )
                                            ?.sort(
                                              (a, b) =>
                                                a.featured_order -
                                                b.featured_order,
                                            )
                                            ?.slice(0, 6)
                                            ?.map((bookkeper, index) => {
                                              return fetchFeaturedOdds(
                                                item?.MarketRelations,
                                                bookkeper?.id,
                                                item,
                                              );
                                            })}
                                        </Box>
                                      </Box>
                                    )}
                                  </TableCell>
                                )}
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <Box className="runner-deatils track-details">
                      {" "}
                      {screenWidth > 899 ? (
                        <>
                          <Box className="runner-info">
                            <Typography
                              className="runner-name track-flag"
                              onClick={() => handleNavigate(item)}
                            >
                              {item?.flag?.includes("uploads") ? (
                                <img
                                  src={Config.mediaURL + item?.flag}
                                  alt="Race Contry"
                                  className="flag"
                                />
                              ) : (
                                <img
                                  src={item?.flag}
                                  alt="Race Contry"
                                  className="flag"
                                />
                              )}
                              {item?.trackName}
                            </Typography>
                            <Box>
                              <Box>
                                <Typography className="runner-info-details">
                                  Distance: N/A
                                </Typography>
                                <Typography className="runner-info-details">
                                  Track: N/A
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Table className="track-table">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Box className="track-runner">R1</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R2</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R3</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R4</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R5</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R6</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R7</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R8</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R9</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R10</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R11</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R12</Box>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>{handleTracklist(item)}</TableRow>
                            </TableBody>
                          </Table>
                        </>
                      ) : (
                        <>
                          {fetchNewMobileViewRacingData(
                            item,
                            item?.countryId === 157 || item?.countryId === 13
                              ? false
                              : true,
                            index,
                          )}
                          <Box className="runner-info">
                            <Box className="distance-track-wrap">
                              <Typography className="runner-info-details">
                                Distance: N/A
                              </Typography>
                              <Typography className="runner-info-details">
                                Track: N/A
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  )}

                  {isToggle ? (
                    <Box className="comment-section">
                      <Box className="runner-comment">
                        <Typography className="runner">
                          {item?.type}:
                        </Typography>
                        <Typography className="runner comment">
                          {item?.comment}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          className="edit-comment cursor-pointer"
                          onClick={() => handleEdit(item?.type, item?.id, item)}
                        >
                          <EditIcon />
                          Edit comment
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </>
            );
          })
        ) : (
          <>
            <Box style={{ border: "transparent" }}>
              <Box
                style={{
                  textAlign: "center",
                  border: "transparent",
                  fontFamily: "sans-serif",
                }}
              >
                {/* No Data Available */}
                <NoDataComp />
              </Box>
            </Box>
          </>
        )}
        {!isUpcomingLoading && upcomingData && upcomingData?.length > 0 ? (
          <Box className="pagination-section">
            {/* <Pagination count={1} /> */}
            <Pagination
              // hideNextButton
              // hidePrevButton
              disabled={upcomingCount / 10 > 1 ? false : true}
              page={upcomingPage}
              onChange={(e, value) => handlePaginationClick(e, value)}
              count={pageNumbers[pageNumbers?.length - 1]}
              siblingCount={2}
              boundaryCount={1}
              // count={10}
              size="small"
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <OurPartner />
      <DialogBox
        open={deleteOpen}
        onClose={handleDeleteClose}
        handleClose={handleDeleteClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="delete-title">
                Are you sure you want to delete?
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel blackbook-delete"
                  onClick={() => {
                    handleuserDelete(selectedType, selectedId);
                  }}
                >
                  Yes
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-save"
                  onClick={() => handleDeleteClose()}
                >
                  No
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />
      <BlackBookModal
        open={open}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        id={selectedId}
        typeId={typeId}
        editComment={editComment}
        isEdit={true}
        tabType="Upcoming"
        listingFunction={() =>
          fetchUpcomingBlackBook(countryId, stateId, upcomingPage)
        }
      />
      {/* <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> */}
    </>
  );
};

export default UpcomingRunnerV2;
