import React, { useEffect, useRef } from "react";
import postscribe from "postscribe";
import he from "he";

const DynamicNewsScript = ({ newsContent }) => {
  const newsscriptRef = useRef(null);
  const decodedHtml = he.decode(newsContent);
  useEffect(() => {
    if (newsscriptRef) {
      postscribe(newsscriptRef.current, decodedHtml);
    }
  }, []);
  return <div ref={newsscriptRef}></div>;
};
export default DynamicNewsScript;
