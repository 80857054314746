import React from "react";
import Page from "src/components/Page";
import IndividualCompetitionPage from "src/views/component/individualCompetition";

const IndividualCompetition = () => {
  return (
    <Page title="My Competition">
      <IndividualCompetitionPage />
    </Page>
  );
};

export default IndividualCompetition;
