import React from "react";
import Page from "src/components/Page";
import UserIndividualMessagePage from "src/views/component/userIndividualMessage";

const UserIndividualMessage = () => {
  return (
    <Page title="Messages">
      <UserIndividualMessagePage />
    </Page>
  );
};

export default UserIndividualMessage;
