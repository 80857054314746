import React, { useState, useEffect, useContext, useRef } from "react";
import { IntlContext } from "src/App";
import { useLocation } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  IconButton,
  TextareaAutosize,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  InputAdornment
} from "@mui/material";
import Select, { components } from "react-select";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import CancelIcon from "@mui/icons-material/Cancel";
import Horse from "../../../assets/images/dark_horse.svg";
import Greys from "../../../assets/images/dar-greyhound.svg";
import Harnes from "../../../assets/images/dark_harness.svg";
import _ from "lodash";
import "./smartbook.scss";
const newTabData = [
  {
    id: 0,
    name: "Tournament"
  },
  { id: 1, name: "Team" }
];

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const SBSearch = ({ handleClose, open, closeIcon, handleBlackBookModal }) => {
  const location = useLocation();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [isLoading, setisLoading] = useState(false);
  const [tabvalue, setTabValue] = useState(0);
  const [listData, setListData] = useState([]);
  const [EventPage, setEventPage] = useState(0);
  const [EventCount, setEventCount] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [selectedSports, setSelectedSports] = useState(15);
  const [teamsAll, setTeamsAll] = useState([]);
  const [OrgAll, setOrgAll] = useState([]);
  const [pageTeam, setpageTeam] = useState(0);
  const [OrgApiCount, setOrgApiCount] = useState(0);
  const [teamApiCount, setTeamApiCount] = useState(0);
  const [countOrg, setcountOrg] = useState(0);
  const [count, setcount] = useState(0);
  const [sports, setSports] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const textFieldRef = useRef(null);

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [tabvalue]);

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  const getCategoryName = (sportId, item) => {
    if (sportId === 15) {
      return (
        item?.AFLCategory &&
        item?.AFLCategory !== null &&
        item?.AFLCategory?.name
      );
    } else if (sportId === 9) {
      return (
        item?.ARCategory && item?.ARCategory !== null && item?.ARCategory?.name
      );
    } else if (sportId === 11) {
      return (
        item?.BaseballCategory &&
        item?.BaseballCategory !== null &&
        item?.BaseballCategory?.name
      );
    } else if (sportId === 10) {
      return (
        item?.NBACategory &&
        item?.NBACategory !== null &&
        item?.NBACategory?.name
      );
    } else if (sportId === 6) {
      return (
        item?.BoxingCategory &&
        item?.BoxingCategory !== null &&
        item?.BoxingCategory?.name
      );
    } else if (sportId === 4) {
      return (
        item?.CricketCategory &&
        item?.CricketCategory !== null &&
        item?.CricketCategory?.name
      );
    } else if (sportId === 16) {
      return (
        item?.GolfCategory &&
        item?.GolfCategory !== null &&
        item?.GolfCategory?.name
      );
    } else if (sportId === 17) {
      return (
        item?.IceHockeyCategory &&
        item?.IceHockeyCategory !== null &&
        item?.IceHockeyCategory?.name
      );
    } else if (sportId === 5) {
      return (
        item?.MMACategory &&
        item?.MMACategory !== null &&
        item?.MMACategory?.name
      );
    } else if (sportId === 12) {
      return (
        item?.RLCategory && item?.RLCategory !== null && item?.RLCategory?.name
      );
    } else if (sportId === 13) {
      return (
        item?.RLCategory && item?.RLCategory !== null && item?.RLCategory?.name
      );
    } else if (sportId === 8) {
      return (
        item?.SoccerCategory &&
        item?.SoccerCategory !== null &&
        item?.SoccerCategory?.name
      );
    } else if (sportId === 7) {
      return (
        item?.TennisCategory &&
        item?.TennisCategory !== null &&
        item?.TennisCategory?.name
      );
    }
  };

  const handleOnScrollBottomEvents = async EventPage => {
    if (EventCount !== (EventCount == 1 ? 1 : Math.ceil(EventPage / 50))) {
      try {
        const passApi =
          tabvalue === 1
            ? `/allsport/team?SportId=${selectedSports}&offset=${EventPage}&limit=50`
            : `/allsport/tournament?SportId=${selectedSports}&offset=${EventPage}&limit=50`;
        // tabvalue === 2
        //   ? `statistics/getTrainerList?name=${searchInput}&limit=50&offset=${EventPage}`
        //   : `statistics/getRunnerProfile?name=${searchInput}&limit=50&offset=${EventPage}`;
        const { status, data } = await axiosInstance.get(passApi);
        if (status === 200) {
          setEventPage(EventPage);

          let newdata = [];
          let track = data?.result?.rows?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id,
              sportId: item?.SportId,
              categoryName: getCategoryName(item?.SportId, item)
            });
          });
          let uniqbydata = _.uniqBy([...listData, ...newdata], function(e) {
            return e?.value;
          });
          const sortedData = uniqbydata?.sort((a, b) => {
            return a?.label?.trim().localeCompare(b?.label?.trim());
          });
          setListData(sortedData);
        } else {
        }
      } catch (err) {}
    }
  };

  useEffect(() => {
    if (open) {
      // fetchRunnerList("");
      fetchSportData();
      // fetchOrgData("", 15, OrgAll, "");
    }
  }, [open]);

  // useEffect(() => {
  //   fetchSportData();
  // }, []);

  useEffect(() => {
    setEventPage(0);
    setSearchInput("");
    if (tabvalue === 0) {
      fetchOrgData(pageTeam, selectedSports, OrgAll, "");
    } else if (tabvalue === 1) {
      fetchTeamData(pageTeam, selectedSports, teamsAll, "");
    }
  }, [tabvalue, selectedSports]);

  const getFields = (list, field) => {
    return list.reduce(function(carry, item) {
      if (typeof item === "object" && field in item) {
        carry.push(item[field]);
      }
      return carry;
    }, []);
  };

  const fetchSportData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/sport/?sportTypeId=${2}`
      );
      if (status === 200) {
        var sportsdata = data?.result.map(s => {
          return { ...s, label: s?.sportName, value: s?.id };
        });
        var filterSports = getFields(sportsdata, "id");
        setSelectedSports(filterSports[10]);
        var sdata = _.orderBy(sportsdata, ["label"], ["asc"]);
        // var mergedArray = _.concat(
        //   [{ label: "ALL", value: filterSports, isAll: true }],
        //   sdata
        // );
        setSports(sdata);
      }
    } catch (err) {}
  };

  const fetchOrgData = async (page, sID, OrgAll, search) => {
    setisLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/tournament?SportId=${sID}&offset=${page}&limit=50&search=${search}`
      );
      if (status === 200) {
        setisLoading(false);
        setOrgApiCount(teamApiCount + 1);
        setcountOrg(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.name,
            value: item?.id,
            sportId: item?.SportId,
            categoryName: getCategoryName(item?.SportId, item)
          });
        });
        let filterData = _.unionBy(OrgAll, newdata);
        setListData(
          _.uniqBy(filterData, function(e) {
            return e.value;
          })
        );
      } else {
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
    }
  };

  const fetchTeamData = async (page, sID, teamsAll, search) => {
    setisLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/team?SportId=${sID}&offset=${page}&limit=50&search=${search}`
      );
      if (status === 200) {
        setisLoading(false);
        setTeamApiCount(teamApiCount + 1);
        setcount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.name,
            value: item?.id,
            sportId: item?.SportId,
            categoryName: getCategoryName(item?.SportId, item)
          });
        });
        let filterData = _.unionBy(teamsAll, newdata);
        setListData(
          _.uniqBy(filterData, function(e) {
            return e.value;
          })
        );
      } else {
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
    }
  };

  const handleClearClick = () => {
    setSearchInput("");
    if (tabvalue === 0) {
      fetchOrgData(pageTeam, selectedSports, OrgAll, "");
    } else if (tabvalue === 1) {
      fetchTeamData(pageTeam, selectedSports, teamsAll, "");
    }
  };
  return (
    (<Dialog
        className="blackBook-dialog blackbook-wrap"
        // maxWidth="md"
        onClose={handleClose}
        open={open}
        TransitionComponent={Grow}
        scroll={"paper"}
      >
      <Box className="dialog-content-box">
        {closeIcon && (
          <IconButton className="dialog-close" onClick={() => handleClose()} size="large">
            <CloseIcon />
          </IconButton>
        )}
        <Box className="blackBook-content-wrap page-deatils-wrap">
          <h1>Search To Add Smartbook</h1>
          <Box className="tab-search-section modal-section">
            <Box className="filter-section">
              <Select
                className="React Period-select mw-none"
                value={sports?.find(item => {
                  return item?.value === selectedSports;
                })}
                onChange={e => {
                  setSelectedSports(e?.value == 0 ? "" : e?.value);
                  setCurrentPage(0);
                  if (tabvalue === 0) {
                    fetchOrgData(0, e?.value, [], "");
                  } else if (tabvalue === 1) {
                    fetchTeamData(0, e?.value, [], "");
                  }
                }}
                // onMenuScrollToBottom={e => handleOnScrollBottomSports(e)}
                options={sports}
                classNamePrefix="select"
                placeholder="Sports"
                // isSearchable={false}
                // onFocus={() => setTournamentSelect(true)}
                // onBlur={() => setTournamentSelect(false)}
                components={{ DropdownIndicator }}
              />
            </Box>
            <Box className="team-sports-tab" style={{ marginTop: "18px" }}>
              <Tabs
                value={tabvalue}
                variant="scrollable"
                scrollButtons={false}
                indicatorColor="primary"
                textColor="primary"
                className="teamsport-tab-detail"
              >
                {newTabData?.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Tab
                        label={item?.name}
                        value={item?.id}
                        className={item?.id === tabvalue ? "active " : ""}
                        onChange={(event, newValue) =>
                          handleTabChange(event, item?.id)
                        }
                      />
                    </Box>
                  );
                })}
              </Tabs>
            </Box>
            <Box>
              <TextField
                placeholder="Search to add to your Smartbook"
                variant="outlined"
                type="text"
                name="search"
                autoFocus
                inputRef={textFieldRef}
                className="search_input"
                value={searchInput}
                onChange={e => {
                  setSearchInput(e.target.value);
                  // if (tabvalue === 3) {
                  //   fetchTrackList(e.target.value);
                  // } else if (tabvalue === 1) {
                  //   fetchJockeyList(e.target.value);
                  // } else if (tabvalue === 2) {
                  //   fetchTrainerList(e.target.value);
                  // } else if (tabvalue === 0) {
                  //   fetchRunnerList(e.target.value);
                  // }

                  if (tabvalue === 0) {
                    fetchOrgData(pageTeam, selectedSports, [], e.target.value);
                  } else if (tabvalue === 1) {
                    fetchTeamData(pageTeam, selectedSports, [], e.target.value);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchInput && (
                        <IconButton
                          onClick={() => handleClearClick()}
                          edge="end"
                          style={{ minWidth: "unset" }}
                          size="large">
                          <CancelIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </Box>
          {isLoading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : (
            <Box className="smartsports-container">
              <ul className="trackprofilelist-wrap">
                {listData?.length > 0 ? (
                  <div
                    id="scrollableDiv"
                    style={{
                      height: "100vh",
                      overflow: "auto",
                      overflowX: "hidden !important"
                    }}
                    className="scrollableDiv"
                  >
                    <InfiniteScroll
                      dataLength={listData?.length}
                      next={() => handleOnScrollBottomEvents(EventPage + 50)}
                      hasMore={EventCount !== Math.ceil(EventPage / 50 + 1)}
                      loader={
                        <div className="allsport-loader-center ">
                          <Loader />
                        </div>
                      }
                      scrollableTarget="scrollableDiv"
                    >
                      {listData?.map(obj => {
                        return (
                          <>
                            <li className="trackprofile-list">
                              <span
                                onClick={() =>
                                  handleBlackBookModal(
                                    obj?.label,
                                    obj?.value,
                                    tabvalue === 0 ? "Tournament" : "Team",
                                    obj?.sportId
                                  )
                                }
                                style={{ width: "100%" }}
                              >
                                {obj?.categoryName
                                  ? obj?.label + " - " + obj?.categoryName
                                  : obj?.label}
                                {/* {obj?.name}
                                {obj?.State ? `, ${obj?.State?.state}` : ""}
                                {obj?.Country
                                  ? `, ${obj?.Country?.country}`
                                  : ""} */}
                              </span>
                              {/* {tabvalue === 3
                                ? fetchSportIcon(obj?.sportId)
                                : ""} */}
                            </li>
                          </>
                        );
                      })}
                    </InfiniteScroll>
                  </div>
                ) : (
                  <>
                    <Box style={{ border: "transparent" }}>
                      <Box
                        style={{
                          textAlign: "center",
                          border: "transparent"
                        }}
                      >
                        No Data Available
                      </Box>
                    </Box>
                  </>
                )}
              </ul>
            </Box>
          )}
        </Box>
      </Box>
      {/* <Box>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Box> */}
    </Dialog>)
  );
};

export default SBSearch;
